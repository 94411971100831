import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




const EdittypesGroups = () => {

    const EditTypes = [{
        id: '1',
        GroupName: "Amazon Listing",
        CreatedOn: '19 Aug 2022 12:01',
        EditTypes: 'Background removal, Retouching, Resi...' ,
        CreatedBy: 'Customer',
        Status: <p style={{ color: '#125ECB' }}>Active</p>     
    },

    

    {
        id: '1',
        GroupName: "Nike Products",
        CreatedOn: '19 Aug 2022 12:01',
        EditTypes: 'Background removal, Retouching, Resi...' ,
        CreatedBy: 'Customer',
        Status: <p style={{ color: '#125ECB' }}>Active</p>     
    },
    {
        id: '1',
        GroupName: "Target Listing",
        CreatedOn: '19 Aug 2022 12:01',
        EditTypes: 'Background removal, Retouching, Resi...' ,
        CreatedBy: 'Customer',
        Status: <p style={{ color: '#125ECB' }}>Active</p>     
    },
    {
        id: '1',
        GroupName: "Under Armour Products",
        CreatedOn: '19 Aug 2022 12:01',
        EditTypes: 'Background removal, Retouching, Resi...' ,
        CreatedBy: 'Customer',
        Status: <p style={{ color: '#125ECB' }}>Active</p>     
    },
    {
        id: '1',
        GroupName: "Walmart Listing",
        CreatedOn: '19 Aug 2022 12:01',
        EditTypes: 'Background removal, Retouching, Resi...' ,
        CreatedBy: 'Customer',
        Status: <p style={{ color: '#C71515' }}>Deactive</p>     
    }
]

const columns = [{
    dataField: 'GroupName',
    text: 'Group Name',
    number: '1',
    headerStyle: (colum, colIndex) => {
        return { width: '145px', textAlign: 'left' };
    }
}, {
    dataField: 'CreatedOn',
    text: 'Created On',
    headerStyle: (colum, colIndex) => {
        return { width: '145px', textAlign: 'left' };
    }
},
{
    dataField: 'EditTypes',
    text: 'Edit Type(s)',
    headerStyle: (colum, colIndex) => {
        return { width: '145px', textAlign: 'left' };
    }
},
{
    dataField: 'CreatedBy',
    text: 'Created By',
    headerStyle: (colum, colIndex) => {
        return { width: '145px', textAlign: 'left' };
    }
},
{
    dataField: 'Status',
    text: 'Status',
    headerStyle: (colum, colIndex) => {
        return { width: '145px', textAlign: 'left' };
    }
}

];

const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
}];


{/* <BootstrapTable
    keyField="id"
    data={EditTypes}
    columns={columns}
    striped
    hover
    condensed
    defaultSortDirection="asc"
/> */}
  return (
    <>
    
    <div className="table-responsive table-b mb-4">
                <BootstrapTable keyField='id' data={EditTypes} columns={columns} />
            </div>

            <Row className="mt-4">
                <Col md={{ span: 4, offset: 8 }}>
                    {
                        <Pagination>
                            <Pagination.Prev />
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Item>{5}</Pagination.Item>
                            <Pagination.Item>{6}</Pagination.Item>
                            <Pagination.Next />
                        </Pagination>
                    }
                </Col>
            </Row>
    
    </>
  )
}

export default EdittypesGroups