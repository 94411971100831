import React, { useEffect, useState } from "react";

//Importing the bootstrap components
import { Button, Row, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";

//Importing the react select components
import Select from "react-select";

//Importing the images
import CanceIcon from "./../../../../assets/Images/actionsIcons/cancel_Final.svg";

// Importing API related files and routes
import { client } from "./../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../../data/filepath";
import { CO_ORDINATOR_DUPLICATE_IMAGES } from "./../../../../config/apiRoutes.api";

const DuplicateEditType = (props) => {
	let {
		order_id,
		setDuplicateImageModal,
		setMultipleImagesId,
		setClickSelect,
		token,
		idSelected,
		customer_id,
	} = props;
	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];
	const selectBackgrondRemoval = [
		{ label: "Custom", value: 355 },
		{ label: "Transparent", value: 54 },
	];
	const selectedAmount = [
		{ label: "1", value: 355 },
		{ label: "2", value: 54 },
		{ label: "3", value: 54 },
	];
	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];
	const initial_data = [
		{
			id: "",
			file_format: "",
			height: "",
			width: "",
			dpi: "",
			dpi_type: "",
			color_mode: "",
			alignment: "",
			margin_format: "",
			margin_top: "",
			margin_bottom: "",
			margin_right: "",
			margin_left: "",
		},
	];
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);
	//Declaration of state
	const [error, setError] = useState("");
	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State
	const [amount, setAmount] = useState("");
	const [duplicate_amount, setDuplicateAmount] = useState(initial_data);
	const [file_format, setFileFormat] = useState([]);
	const [height, setHeight] = useState([]);
	const [newImageEditType, setNewImageEditType] = useState([]);

	//handle Input Changes of Amount
	const handleChange = (e) => {
		setAmount(e.target.value);
		console.log("e.target.value", e.target.value);
	}; // add an object with specific amount given
	useEffect(() => {
		console.log("amount", amount);
		if (amount !== "") {
			for (let i = 0; i < amount; i++) {
				setDuplicateAmount((prevState) => [
					...prevState,
					{
						id: i,
					},
				]);
			}
		} else {
			setDuplicateAmount([]);
		}
	}, [amount, setDuplicateAmount]);
	const getFilteredSet = (propertyName, dataArray) => {
		return (
			dataArray !== undefined &&
			dataArray
				.map((items) => items[propertyName])
				.filter((value) => value !== undefined)
		);
	};

	// ...

	const fileFormatSet = getFilteredSet("file_format", duplicate_amount);
	const heightSet = getFilteredSet("height", duplicate_amount);
	const widthSet = getFilteredSet("width", duplicate_amount);
	const dpiSet = getFilteredSet("dpi", duplicate_amount);
	const dpiTypeSet = getFilteredSet("dpi_type", duplicate_amount);
	const colorModeSet = getFilteredSet("color_mode", duplicate_amount);
	const alignmentSet = getFilteredSet("alignment", duplicate_amount);
	const marginFormatSet = getFilteredSet("margin_format", duplicate_amount);
	const marginTopSet = getFilteredSet("margin_top", duplicate_amount);
	const marginBottomSet = getFilteredSet("margin_bottom", duplicate_amount);
	const marginRightSet = getFilteredSet("margin_right", duplicate_amount);
	const marginLeftSet = getFilteredSet("margin_left", duplicate_amount);
	useEffect(() => {
		const newState = {
			file_format: fileFormatSet,
			height: heightSet,
			width: widthSet,
			dpi: dpiSet,
			dpi_type_Value: dpiTypeSet,
			color_mode_Value: colorModeSet,
			alignment_Value: alignmentSet,
			margin_format: marginFormatSet,
			margin_top: marginTopSet,
			margin_bottom: marginBottomSet,
			margin_right: marginRightSet,
			margin_left: marginLeftSet,
		};

		// Update all state variables at once

		setNewImageEditType(newState);
	}, [duplicate_amount]);
	//To format and append the value of input
	const formatAndAppendValue = (formData, property, value) => {
		if (value !== undefined) {
			const formattedValue =
				value !== undefined
					? value
							.filter((id) => id) // Filter out non-numeric values
							.map((id) => id)
							.join(",")
					: "";
			formData.append(property, formattedValue);
		}
	};
	// Function to validate individual fields
	const validateField = (field, value) => {
		let error = "";

		if (!value) {
			error = `${field} is required`;
		}

		return error;
	};
	// Function to validate the form fields

	const validate = () => {
		const fieldValidationRules = {
			file_format: "File Format is required",
			height: "Height is required",
			width: "Width is required",
			dpi: "Dpi is required",
			dpi_type_Value: "Dpi type is required",
			color_mode_Value: "Color Mode is required",
			alignment_Value: "Alignment is required",
			margin_format: "Margin format is required",
			margin_top: "Margin Top is required",
			margin_bottom: "Margin Bottom is required",
			margin_right: "Margin Right is required",
			margin_left: "Margin Left is required",
		};

		let newErrors = {};

		// Combine margin errors into a single error message
		const marginFields = [
			"margin_top",
			"margin_bottom",
			"margin_right",
			"margin_left",
		];
		if (
			marginFields.some(
				(field) =>
					newImageEditType[field] !== undefined &&
					newImageEditType[field].length !== duplicate_amount.length
			)
		) {
			newErrors.margin = "All margin fields are required";
		}

		// Validate and set errors for individual fields
		for (const field in fieldValidationRules) {
			if (
				newImageEditType[field] !== undefined &&
				newImageEditType[field].length !== duplicate_amount.length
			) {
				newErrors[field] = fieldValidationRules[field];
			}
		}

		// Check if there are any validation errors
		const missingFields = Object.keys(fieldValidationRules).filter(
			(field) =>
				newImageEditType[field] === undefined ||
				newImageEditType[field].length !== duplicate_amount.length
		);

		if (missingFields.length > 0) {
			newErrors.all = `Please fill the required details for ${missingFields
				.map((field) => field.replace(/_/g, " "))
				.join(", ")}`;
		}

		return newErrors;
	};

	const handleSubmitDuplicateImage = () => {
		if (token) {
			const newErrors = {};

			// Validate each field and update errors
			const validateAndSetError = (field, value) => {
				if (!value) {
					newErrors[field] = `${field} is required`;
				} else {
					delete newErrors[field];
				}
			};

			// Perform field validations
			validateAndSetError("file_format", newImageEditType.file_format);
			validateAndSetError("height", newImageEditType.height);
			validateAndSetError("width", newImageEditType.width);
			validateAndSetError("dpi", newImageEditType.dpi);
			validateAndSetError("dpi_type", newImageEditType.dpi_type_Value);
			validateAndSetError("color_mode", newImageEditType.color_mode_Value);
			validateAndSetError("alignment", newImageEditType.alignment_Value);
			validateAndSetError("margin_format", newImageEditType.margin_format);
			validateAndSetError("margin_top", newImageEditType.margin_top);
			validateAndSetError("margin_bottom", newImageEditType.margin_bottom);
			validateAndSetError("margin_right", newImageEditType.margin_right);
			validateAndSetError("margin_left", newImageEditType.margin_left);

			// Check if there are any validation errors
			if (Object.keys(newErrors).length > 0) {
				setErrors(newErrors);
				return;
			}

			// Clear any previous errors if there are no validation issues
			setErrors({});

			// Continue with API request
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("customer_id", customer_id);

			// Format and append values to bodyFormData
			formatAndAppendValue(
				bodyFormData,
				"file_format",
				newImageEditType.file_format
			);
			formatAndAppendValue(bodyFormData, "height", newImageEditType.height);
			formatAndAppendValue(bodyFormData, "width", newImageEditType.width);
			formatAndAppendValue(bodyFormData, "dpi", newImageEditType.dpi);
			formatAndAppendValue(
				bodyFormData,
				"dpi_type",
				newImageEditType.dpi_type_Value
			);
			formatAndAppendValue(
				bodyFormData,
				"color_mode",
				newImageEditType.color_mode_Value
			);
			formatAndAppendValue(
				bodyFormData,
				"alignment",
				newImageEditType.alignment_Value
			);
			formatAndAppendValue(
				bodyFormData,
				"margin_format",
				newImageEditType.margin_format
			);
			formatAndAppendValue(
				bodyFormData,
				"margin_top",
				newImageEditType.margin_top
			);
			formatAndAppendValue(
				bodyFormData,
				"margin_bottom",
				newImageEditType.margin_bottom
			);
			formatAndAppendValue(
				bodyFormData,
				"margin_right",
				newImageEditType.margin_right
			);
			formatAndAppendValue(
				bodyFormData,
				"margin_left",
				newImageEditType.margin_left
			);

			request
				.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGES, bodyFormData)
				.then((response) => {
					// Set the success from the response
					setStatus("Successfully Group updated");
					console.log("response", response);
					setLoader(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		const newErrors = validate();

		// Set errors and mark form as submitting
		setErrors(newErrors);
		setIsSubmitting(true);

		if (Object.keys(newErrors).length === 0) {
			// Perform form submission logic
			handleSubmitDuplicateImage();
		} else {
			setIsSubmitting(false);
		}
	};
	const handleClear = () => {
		setMultipleImagesId([]);
		setDuplicateImageModal(false);
		setClickSelect(false);
	};

	return (
		<div className="sidebar-image--duplicate d-flex">
			<div style={{ width: "100%" }}>
				<div
					className="d-flex"
					style={{
						// flexDirection: "row-reverse",
						marginBottom: "18px",
						marginTop: "16px",
					}}
				>
					<div
						style={{ display: "flex", marginLeft: "auto" }}
						onClick={() => {
							setMultipleImagesId([]);
							setDuplicateImageModal(false);
							setClickSelect(false);
						}}
					>
						<img src={CanceIcon} className="img-fluid" alt="mindreach images" />
					</div>
				</div>
				<div className="form-group">
					<Row className="align-items-center">
						<Form.Label className="col-sm-3" style={{ width: "auto" }}>
							Amount
						</Form.Label>
						<Col sm={6} style={{ marginLeft: "30px" }}>
							<Form.Control
								type="text"
								placeholder="Enter the amount"
								autoFocus
								onChange={(e) => handleChange(e)}
							/>
						</Col>
					</Row>
				</div>
				{duplicate_amount !== undefined &&
					duplicate_amount.map((data, index) => (
						<div className="form-group d-flex" key={index}>
							<div className="detailstab">
								<div>
									{/* Form groups for output specifications */}

									<div className="sub-head">Duplicate &nbsp;{index + 1}</div>

									<Form.Group
										style={{ margin: "2px" }}
										controlId="exampleForm.ControlTextarea1"
									>
										<div className="form-group">
											<Row className="align-items-center">
												<Form.Label className="col-sm-3">
													File Format
												</Form.Label>
												<Col sm={6}>
													<Select
														options={file}
														placeholder="Select file format"
														// onChange={(e) => {
														// 	data.file_format = e.label;

														// 	setDuplicateAmount([...duplicate_amount]);
														// }}
														onChange={(e) => {
															const error = validateField(
																"File Format",
																e.label
															);
															setErrors((prevErrors) => ({
																...prevErrors,
																[`file_format_${index}`]: error,
															}));

															if (!error) {
																data.file_format = e.label;
																setDuplicateAmount([...duplicate_amount]);
															}
														}}
													/>
												</Col>
											</Row>
										</div>
									</Form.Group>

									<Form.Group
										style={{ margin: "2px" }}
										controlId="exampleForm.ControlTextarea1"
									>
										<div className="form-group">
											<Row className="align-items-center">
												<Form.Label className="col-sm-3">Size</Form.Label>
												<Col sm={9}>
													<div className="d-sm-flex align-items-center col-gap-10">
														<div className="d-sm-flex col-gap-10 align-items-center">
															<span className="text-secondary f-sz-13">
																{" "}
																H:{" "}
															</span>
															<div className="flex-col">
																<Form.Control
																	type="text"
																	placeholder=""
																	autoFocus
																	onChange={(e) => {
																		data.height = e.target.value;
																		setDuplicateAmount([...duplicate_amount]);
																	}}
																	autoComplete="off"
																/>
																{/* {errors.height && (
																<div className="error">{errors.height}</div>
															)} */}
															</div>
														</div>
														<div className="d-sm-flex align-items-center"></div>
														<div className="d-sm-flex align-items-center col-gap-10">
															<span className="text-secondary f-sz-13">
																{" "}
																W:{" "}
															</span>
															<div className="flex-col">
																<Form.Control
																	type="text"
																	placeholder="2000"
																	autoFocus
																	onChange={(e) => {
																		data.width = e.target.value;
																		setDuplicateAmount([...duplicate_amount]);
																	}}
																	autoComplete="off"
																/>
																{/* {errors.width && (
																<div className="error">{errors.width}</div>
															)} */}
															</div>
														</div>
													</div>
												</Col>
											</Row>
										</div>
									</Form.Group>

									<Form.Group
										style={{ margin: "2px" }}
										controlId="exampleForm.ControlTextarea1"
									>
										<div className="form-group">
											<Row className="align-items-center">
												<Form.Label className="col-sm-3">DPI</Form.Label>
												<Col sm={9}>
													<div className="d-sm-flex col-gap-20">
														<div className="">
															<Form.Control
																type="text"
																placeholder="2000"
																autoFocus
																onChange={(e) => {
																	data.dpi = e.target.value;
																	setDuplicateAmount([...duplicate_amount]);
																}}
																autoComplete="off"
															/>
															{/* {errors.dpi && (
															<div className="error">{errors.dpi}</div>
														)} */}
														</div>
														<div className="flex-fill">
															<Select
																options={dpi}
																CO_ORDINATOR_MERGED_IMAGES
																placeholder="Select Image Dpi Type"
																onChange={(e) => {
																	data.dpi_type = e.label;
																	setDuplicateAmount([...duplicate_amount]);
																}}
															/>
															{/* {errors.dpi_type && (
															<div className="error">{errors.dpi_type}</div>
														)} */}
														</div>
													</div>
												</Col>
											</Row>
										</div>
									</Form.Group>

									<Form.Group
										style={{ margin: "2px" }}
										controlId="exampleForm.ControlTextarea1"
									>
										<div className="form-group">
											<Row className="align-items-center">
												<Form.Label className="col-sm-3">Color Mode</Form.Label>
												<Col sm={9}>
													<Select
														options={ColorMode}
														placeholder="Select Image Color Mode"
														onChange={(e) => {
															data.color_mode = e.label;
															setDuplicateAmount([...duplicate_amount]);
														}}
													/>
													{/* {errors.color_mode_Value && (
													<div className="error">{errors.color_mode_Value}</div>
												)} */}
												</Col>
											</Row>
										</div>
									</Form.Group>

									<Form.Group
										style={{ margin: "2px" }}
										controlId="exampleForm.ControlTextarea1"
									>
										<div className="form-group">
											<Row className="align-items-center">
												<Form.Label className="col-sm-3">Align</Form.Label>
												<Col sm={9}>
													<Select
														options={Align}
														placeholder="Align"
														onChange={(e) => {
															data.alignment = e.label;
															setDuplicateAmount([...duplicate_amount]);
														}}
													/>
													{/* {errors.alignment_Value && (
													<div className="error">{errors.alignment_Value}</div>
												)} */}
												</Col>
											</Row>
										</div>
									</Form.Group>

									<Form.Group
										style={{ margin: "2px" }}
										controlId="exampleForm.ControlTextarea1"
									>
										<div className="form-group">
											<Row className="align-items-center">
												<Form.Label className="col-sm-3">Margins</Form.Label>
												<Col sm={9}>
													<div className="d-sm-flex col-gap-20 flex-col">
														<div className="">
															<Select
																options={Margin}
																placeholder="Margin"
																onChange={(e) => {
																	data.margin_format = e.label;
																	setDuplicateAmount([...duplicate_amount]);
																}}
															/>
															{/* {errors.margin_format && (
															<div className="error">
																{errors.margin_format}
															</div>
														)} */}
														</div>
														<div className="mt-2">
															<ul className="margin-list">
																<li>
																	<Form.Control
																		type="text"
																		placeholder="T"
																		autoFocus
																		onChange={(e) => {
																			data.margin_top = e.target.value;
																			setDuplicateAmount([...duplicate_amount]);
																		}}
																		autoComplete="off"
																	/>
																</li>
																<li>
																	<Form.Control
																		type="text"
																		placeholder="B"
																		autoFocus
																		onChange={(e) => {
																			data.margin_bottom = e.target.value;
																			setDuplicateAmount([...duplicate_amount]);
																		}}
																		autoComplete="off"
																	/>
																</li>
																<li>
																	<Form.Control
																		type="text"
																		placeholder="R"
																		autoFocus
																		onChange={(e) => {
																			data.margin_right = e.target.value;
																			setDuplicateAmount([...duplicate_amount]);
																		}}
																		autoComplete="off"
																	/>
																</li>
																<li>
																	<Form.Control
																		type="text"
																		placeholder="L"
																		autoFocus
																		onChange={(e) => {
																			data.margin_left = e.target.value;
																			setDuplicateAmount([...duplicate_amount]);
																		}}
																		autoComplete="off"
																	/>
																</li>
															</ul>
															{/* {errors.margin && (
															<div className="error">{errors.margin}</div>
														)} */}
														</div>
													</div>
												</Col>

												<Col sm={6}></Col>
											</Row>
										</div>
									</Form.Group>
								</div>
							</div>
						</div>
					))}
				<div className="global-gap--btn sidebar-single--btn mt-2">
					<Button
						variant="primary"
						className="btns"
						onClick={(e) => handleSubmit(e)}
					>
						Submit
					</Button>
					<h2
						className="text-secondary f-sz-13"
						style={{
							color: "green!important",
							width: "auto",
							margin: "10px",
						}}
					>
						{status !== "" && status}
						{status === "Successfully Group updated" && handleClear()}
						{/* Display validation errors */}
						{errors.all && <div className="error">{errors.all}</div>}
					</h2>
				</div>
			</div>
		</div>
	);
};
export default DuplicateEditType;
