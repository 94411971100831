import React from "react";

const ClickableUrls = ({ text }) => {
	const renderTextWithClickableUrls = (text) => {
		if (!text) {
			return null; // Return null if text is undefined or null
		}

		const urlRegex = /(https?:\/\/[^\s]+)/g; // Regular expression to match URLs

		// Split the text into parts containing URLs and non-URLs
		const parts = text.split(urlRegex);

		// Wrap URLs in anchor tags
		const modifiedText = parts.map((part, index) => {
			if (part.match(urlRegex)) {
				return (
					<a key={index} href={part} target="_blank" rel="noopener noreferrer">
						{part}
					</a>
				);
			} else {
				return part;
			}
		});

		return modifiedText;
	};

	const modifiedText = renderTextWithClickableUrls(text);

	return <div>{modifiedText}</div>;
};

export default ClickableUrls;
