import React, { useState, useEffect } from "react";
import { Form, Row, Col, Pagination, Table } from "react-bootstrap";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";

import Select from "react-select";
import moment from "moment";
import DateRangePicker from "./../../components/DateRangePicker";
import Autocomplete from "./Components/AutoComplete";
import NoDataFound from "./Components/NoDataFound";

import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";

import { VIEW_STAFF_BATCH_HISTORY } from "../../config/apiRoutes.api";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";

const BatchHistory = ({ id }) => {
	const token = sessionStorage.getItem("token");
	const request = client(token);

	const [showDateRange, setShowDateRange] = useState(false);
	const [batchHistoryData, setBatchHistoryData] = useState({});
	const [pageNumber, setPageNumber] = useState(1); // Paginations state
	const [isShow, setIsShow] = useState(false);
	const [batchNumber, setBatchNumber] = useState([]); // To set the batch number
	const [search, setSearch] = useState("");
	const [selectedOption, setSelectedOption] = useState({
		label: "Today",
		value: "today",
	});
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDate2, setSelectedDate2] = useState(new Date());
	const [date1, setDate1] = useState("");
	const [date2, setDate2] = useState("");
	const [customSelected, setCustomSelected] = useState(false);
	const [isDateSelected, setIsDateSelected] = useState(false);

	const aryDateFilter = [
		{ label: "Custom", value: "custom" },
		{ label: "Today", value: "today" },
		{ label: "Current Month", value: "month" },
		{ label: "Current Year", value: "year" },
		{ label: "All", value: "all" },
	];
	const customSelectStyles = {
		menuList: (base) => ({
			...base,
			fontSize: "14px",
		}),
	};
	const handleDateChange = (date, setSelectedDate) => {
		setSelectedDate(date);
	};
	/**
	 * Handles the end date selection callback.
	 * @param {Date} start - The start Date object.
	 * @param {Date} end - The end Date object.
	 * @returns {} set the state.
	 */

	const setDateCallback = (start, end) => {
		handleDateChange(new Date(start.format("YYYY-MM-DD")), setSelectedDate);
		handleDateChange(new Date(end.format("YYYY-MM-DD")), setSelectedDate2);
		setDate1(start.format("YYYY-MM-DD"));
		setDate2(end.format("YYYY-MM-DD"));
		setCustomSelected(true);
		toggleCalendar("date_range", "none", true);
	};

	/**
	 * Handles the show calendar.
	 * @param {id} id - The id of element.
	 * @param {String} display - The show/hide property for element.
	 * @param {Boolean} hideCalendar - Hide/Show the calendar to control.
	 * @returns {} toggle the calendar.
	 */

	const toggleCalendar = (id, display, hideCalendar = false) => {
		const dateRangeElement = document.getElementById(id);
		if (dateRangeElement) {
			dateRangeElement.style.display = display;
			if (display === "block") {
				dateRangeElement.click();
				setIsDateSelected(hideCalendar);
			} else {
				setIsDateSelected(hideCalendar);
			}
			dateRangeElement.style.display = "none";
		}
	};

	useEffect(() => {
		if (
			selectedOption &&
			selectedOption.value &&
			selectedOption.value !== "custom"
		) {
			setIsDateSelected(false);
			setDate1("");
			setDate2("");
			setSelectedDate(new Date());
			setSelectedDate2(new Date());
		}
	}, [selectedOption.value]);
	useEffect(() => {
		let dateSelectionType =
			selectedOption && selectedOption.value ? selectedOption.value : "all";
		let sendRequest = true;
		const fromYear =
			dateSelectionType && dateSelectionType === "custom"
				? moment(selectedDate).format("YYYY-MM-DD")
				: "";
		const toYear =
			dateSelectionType === "custom"
				? moment(selectedDate2).format("YYYY-MM-DD")
				: "";
		if (dateSelectionType === "custom" && toYear !== "") {
			dateSelectionType = fromYear;
			if (!customSelected) {
				sendRequest = false;
			}
		}
		// Check if a token is available
		// Make an HTTP GET request to fetch the branch data with the specified page number
		if (sendRequest) {
			request
				.get(
					apiURL +
						VIEW_STAFF_BATCH_HISTORY +
						`/${id}` +
						`?date=${dateSelectionType}` +
						`&from_date=${fromYear}` +
						`&to_date=${toYear}`
				)
				.then((response) => {
					if (
						response &&
						response.data &&
						response.data.length > 0 &&
						response.data[0].BatchData
					) {
						setBatchHistoryData(response.data[0].BatchData);
						setBatchNumber(response.data[0].BatchNumberData);
					} else {
						// Handle the case where response or its properties are undefined
						console.error("Error: Response or its properties are undefined.");
					}
				})
				.catch((err) => console.log(err));
		}
	}, [selectedDate2, selectedOption, customSelected]);

	useEffect(() => {
		if (!token) {
			return;
		}
		request
			.get(apiURL + VIEW_STAFF_BATCH_HISTORY + `/${id}`)
			.then((response) => {
				if (
					response &&
					response.data &&
					response.data.length > 0 &&
					response.data[0].BatchData
				) {
					setBatchHistoryData(response.data[0].BatchData);
					setBatchNumber(response.data[0].BatchNumberData);
				} else {
					// Handle the case where response or its properties are undefined
					console.error("Error: Response or its properties are undefined.");
				}
			})
			.catch((err) => console.log(err));
	}, []);
	useEffect(() => {
		if (token && search !== "") {
			request
				.get(
					apiURL + VIEW_STAFF_BATCH_HISTORY + `/${id}` + `?search_key=${search}`
				)

				.then((response) => {
					if (
						response &&
						response.data &&
						response.data.length > 0 &&
						response.data[0].BatchData
					) {
						setBatchHistoryData(response.data[0].BatchData);
						setBatchNumber(response.data[0].BatchNumberData);
					} else {
						// Handle the case where response or its properties are undefined
						console.error("Error: Response or its properties are undefined.");
					}
				})
				.catch((err) => console.log(err));
		} else {
			request
				.get(apiURL + VIEW_STAFF_BATCH_HISTORY + `/${id}`)
				.then((response) => {
					if (
						response &&
						response.data &&
						response.data.length > 0 &&
						response.data[0].BatchData
					) {
						setBatchHistoryData(response.data[0].BatchData);
						setBatchNumber(response.data[0].BatchNumberData);
					} else {
						// Handle the case where response or its properties are undefined
						console.error("Error: Response or its properties are undefined.");
					}
				})
				.catch((err) => console.log(err));
		}
	}, [search]);
	// Paginations
	const PaginationItems = () => {
		const { total, per_page, current_page } = batchHistoryData;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};

	const handleNextPagination = () => {
		if (
			pageNumber !==
			Math.ceil(batchHistoryData.total / batchHistoryData.per_page)
		) {
			setPageNumber(pageNumber + 1);
		}
	};
	const handlePreviousPagination = () => {
		if (
			pageNumber !==
			Math.ceil(batchHistoryData.total / batchHistoryData.per_page)
		) {
			setPageNumber(pageNumber - 1);
		}
		setPageNumber(pageNumber - 1);
	}; //end of Paginations

	return (
		<>
			<div className="customer-search mt-4">
				<Form>
					<Row className="align-items-center">
						<Col lg={2} sm={6}>
							<div className="form-group">
								<div
									className="form-group"
									style={{ paddingBottom: "-1px" }}
									// styles={ width: "18rem!important" }
								>
									<Select
										value={selectedOption}
										onChange={(e) => {
											setSelectedOption(e);
											if (e.value === "custom") {
												setIsDateSelected(false);
												setTimeout(() => {
													toggleCalendar("date_range", "block");
												}, 100);
											}
										}}
										options={aryDateFilter}
										className="date_filter"
										styles={customSelectStyles}
									/>
									{id !== "" &&
										selectedOption.value === "custom" &&
										!isDateSelected && (
											<div
												style={{
													// width: "100%",
													overflow: "unset",
													height: "0px",
												}}
											>
												{/* Please follow https://projects.skratchdot.com/react-bootstrap-daterangepicker/?path=/story/daterangepicker--log-events for documentation */}
												<DateRangePicker dateCallback={setDateCallback} />
											</div>
										)}
								</div>
							</div>
						</Col>
						<Col lg={3} sm={6}>
							<div
								className={`form-group`}
								style={{
									marginTop: `${-1}px`,
								}}
							>
								<Autocomplete
									suggestions={batchNumber}
									setSearch={setSearch}
									isShow={isShow}
									setIsShow={setIsShow}
								/>
							</div>
						</Col>
					</Row>
					{showDateRange && (
						<Row className="align-items-center">
							<Col lg={5} sm={12}>
								<div className="form-group">
									<DateRangePicker
										initialSettings={{
											showDropdowns: true,
											locale: { format: "YYYY-MM-DD" },
											readonly: true,
											minYear: 2000,
											isCustomDate: true,
											// startDate: "2014-01-01",
										}}
									>
										<input type="text" className="form-control" readOnly />
									</DateRangePicker>
								</div>
							</Col>
						</Row>
					)}
				</Form>
			</div>

			<div
				className={`table-responsive overviewtables table-b`}
				style={{ marginTop: "3rem !important" }}
			>
				<Table hover>
					<thead>
						<tr>
							<>
								<th>Batch No</th>
								<th>Batch Date</th>
								<th>Customer</th>
								<th>Order No.</th>
								<th>Type</th>
								<th>Image Count</th>
								<th>Status</th>
							</>
						</tr>
					</thead>
					<tbody>
						{batchHistoryData.data !== undefined &&
							batchHistoryData.data.map((item, index) => {
								if (item) {
									const list = (
										<tr key={index}>
											<td>{item.batch_number}</td>
											<td>
												{item.created_at
													? new Date(item.created_at).toLocaleDateString()
													: ""}
											</td>
											<td>{item.customer && item.customer.full_name}</td>
											<td>{item.order && item.order.order_number}</td>
											<td>{item.is_reassign === "0" ? "New" : "Redo"}</td>
											<td>{item.image_count}</td>
											<td>
												<span
													className={
														item.order && item.order.status === "Pending"
															? "order-status"
															: item.order &&
															  item.order.status === "Successfull"
															? "order--success"
															: ""
													}
												>
													{item.order && item.order.status}
												</span>
											</td>
										</tr>
									);
									return list;
								} else {
									return null; // or any other handling you want for undefined items
								}
							})}
					</tbody>
				</Table>
				{batchHistoryData.data === undefined && <NoDataFound />}
			</div>

			<Row className="mt-4">
				<Col md={{ span: 4, offset: 8 }}>
					{
						<Pagination>
							<Pagination.Prev onClick={() => handlePreviousPagination()} />
							{PaginationItems()}
							<Pagination.Next onClick={() => handleNextPagination()} />
						</Pagination>
					}
				</Col>
			</Row>
		</>
	);
};

export default BatchHistory;
