import React from 'react'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'react-bootstrap/Pagination';
import Select from 'react-select';


const BatchHistory = () => {

  const choosedate = [
    { label: "Today", value: 355 },
    { label: "Custome", value: 54 }

  ];


  const products = [{
    BatchNo: "0000081",
    BatchDate: '22 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000081',
    Type: 'Redo',
    ImageCount: '65',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    BatchNo: "0000081",
    BatchDate: '22 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000081',
    Type: 'Redo',
    ImageCount: '65',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    BatchNo: "0000081",
    BatchDate: '22 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000081',
    Type: 'New',
    ImageCount: '65',
    Status: <p style={{ color: '#008E1A' }}>Completed</p>
  },
  {
    BatchNo: "0000081",
    BatchDate: '22 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000081',
    Type: 'New',
    ImageCount: '65',
    Status: <p style={{ color: '#008E1A' }}>Completed</p>
  },
  {
    BatchNo: "0000081",
    BatchDate: '22 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000081',
    Type: 'New',
    ImageCount: '65',
    Status: <p style={{ color: '#008E1A' }}>Completed</p>
  },
  {
    BatchNo: "0000081",
    BatchDate: '22 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000081',
    Type: 'New',
    ImageCount: '65',
    Status: <p style={{ color: '#008E1A' }}>Completed</p>
  }
  ]




  const columns = [{
    dataField: 'BatchNo',
    text: 'Batch No',
    number: '1',

    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }

  }, {
    dataField: 'BatchDate',
    text: 'Batch Date',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Customer',
    text: 'Customer',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'OrderNo',
    text: 'Order No.',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Type',
    text: 'Type',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'ImageCount',
    text: 'Image Count',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }

  },
  {
    dataField: 'Status',
    text: 'Status',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }

  },



  ];

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];


  // <BootstrapTable
  //   keyField="id"
  //   data={products}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />



  return (
    <>




      <div className="table-responsive table-b overviewtables">
        <BootstrapTable keyField='id' data={products} columns={columns} />

      </div>

      <Row className="mt-4">
        <Col md={{ span: 4, offset: 8 }}>
          {
            <Pagination>
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Item>{5}</Pagination.Item>
              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
          }
        </Col>
      </Row>





    </>
  )
}

export default BatchHistory