import React, { useState } from "react";
import QcStaffSidebar from "./../components/global/QcStaffSidebar";

/**
 * Main layout component that wraps the application content.
 * @param {React.ReactNode} children - The child components to be rendered.
 * @returns {React.ReactNode} - The rendered main layout.
 */
const QCLayout = ({ children }) => {
	const [isMenuOpen, setMenuOpen] = useState(true);
	return (
		<>
			<div className="container-fluid page-body-wrapper">
				<QcStaffSidebar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
				<div className={`${isMenuOpen ? `main-panel` : `main-panel--toggle`}`}>
					{children}
				</div>
			</div>
		</>
	);
};

export default QCLayout;
