import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { capitalizeEachWord } from "../../../utils/CapitaliseTheFirstLetterForRole";
// Importing components and images
import Loader from "../../../components/Loader";
import NoDataFound from "../../../components/NoDataFound";
import AddButton from "./../../../components/Button";
import user from "../../../assets/Images/users.svg";
import Delete from "../../../assets/Images/delete.svg";

import duplicate from "../../../assets/Images/actionsIcons/Duplicate _Final.svg";
import CancelIcon from "../../../assets/Images/actionsIcons/cancel_Final.svg";
import MoreIcon from "../../../assets/Images/actionsIcons/more-Final.svg";
import Sort from "../../../assets/Images/sort.svg";
import plus from "./../../../assets/Images/plus.svg";
import ClickableUrls from "./../../../utils/validateTextContainingUrl";
//Importing Confirmations Modal Components
import DeleteModalConfirmation from "../../../components/Modal/DeleteModal";
import DeActivateModalConfirmation from "../../../components/Modal/DeactivateModal";
import ActivateModalConfirmation from "../../../components/Modal/ActivateModal";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import {
	VIEW_STAFFS,
	ADMIN_STAFF_ROLES,
	DELETE_STAFF_BULK,
	DELETE_STAFF_BY_ID,
	DEACTIVATE_STAFF_BY_ID,
	DEACTIVATE_BULK_STAFF,
	ACTIVATE_BULK_STAFF,
	ACTIVATE_STAFF_BY_ID,
} from "../../../config/apiRoutes.api";
import { STAFF_ADD_PAGE, STAFF_DETAILS_PAGE } from "./../../../config/routes";
// import {detectURLInText} from "../../../utils/validateTextContainingUrl";
/**
 * User component to display user data with pagination and search functionality.
 * @returns {JSX.Element} - The rendered User component.
 */
const User = () => {
	/**
	 * Renders a tooltip element with the specified text.
	 *
	 * @param {string} text - The text content of the tooltip.
	 * @returns {Tooltip} - The rendered Tooltip component.
	 */
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

	// Retrieve the token from session storage
	const token = sessionStorage.getItem("token");

	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();

	// Create a request instance using the token
	const request = client(token);

	// Create a new FormData object for sending data in HTTP requests
	const bodyFormData = new FormData();

	//Declarations of state variables
	const [show, setShow] = useState(false);

	const [users_activate, setActivate] = useState("");
	const [users_activate_all, setActivateAll] = useState("");
	const [users_deactivate, setDeactivate] = useState("");
	const [users_deactivate_all, setDeactivateAll] = useState("");

	const [activate_id, setActivateById] = useState(false);
	const [deactivate_id, setDeactivateById] = useState(false);
	const [status_name, setStatusName] = useState("");
	const [id, setId] = useState("");
	const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);

	const [selectAllRow, setSelectAllRows] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowId, setSelectedRowsId] = useState([]);
	const [selectedRowName, setSelectedRowsName] = useState([]);
	const [dropDown, setDropDown] = useState(false);
	const [user_name, setUserName] = useState("");
	const [user_id, setUserId] = useState("");
	const [show_delete_by_id, setShowDeleteByID] = useState(false);

	const [selectAll, setSelectAll] = useState(false);
	const [users, setUsers] = useState([]);
	const [rows, setRows] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [roles, setRoles] = useState([]);
	const [selectRoles, setSelectRoles] = useState("");
	const [search, setSearch] = useState("");

	/**
	 * Converts an ISO date string to a specific date format.
	 * @param {string} dateString - The ISO date string to format.
	 * @returns {string} - The formatted date string.
	 */
	const toJSONLocal = (dateString) => {
		const date = new Date(dateString);

		const day = date.getDate();
		const month = date.toLocaleString("default", { month: "long" });
		const year = date.getFullYear();

		const formattedDate = `${day}` + ` ${month}` + ` ${year}`;
		return formattedDate;
	};
	/**
	 * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} branches - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (branches) =>
		branches.map(({ role: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]
	useEffect(() => {
		if (token) {
			// Fetching admin user's roles data from the API
			request
				.get(apiURL + ADMIN_STAFF_ROLES)
				.then((response) => {
					console.log(response);
					setRoles([
						{ label: "All", value: "All" },
						...response.data.data.roles,
					]);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	useEffect(() => {
		if (token && selectRoles !== "") {
			// Fetching user data based on selected roles from the API
			request
				.get(
					apiURL + VIEW_STAFFS + `?role=${selectRoles}` + `&page=${pageNumber}`
				)
				.then((response) => {
					console.log(response);
					setUsers(response.data.users);
					setRows(response.data.users.data);
				})
				.catch((err) => console.log(err));
		}
	}, [selectRoles, pageNumber]);
	useEffect(() => {
		if (token) {
			if (selectRoles === "All") {
				// Fetching user data from the API based on params
				request
					.get(apiURL + VIEW_STAFFS + `?page=${pageNumber}`)
					.then((response) => {
						console.log(response);
						setUsers(response.data.users);
						setRows(response.data.users.data);
					})
					.catch((err) => console.log(err));
			}
		}
	}, [selectRoles, pageNumber]);

	useEffect(() => {
		if (token && search !== "") {
			// Fetching user data based on search from the API
			request
				.get(
					apiURL + VIEW_STAFFS + `?search_key=${search}` + `&page=${pageNumber}`
				)
				.then((response) => {
					console.log(response);
					setUsers(response.data.users);
					setRows(response.data.users.data);
				})
				.catch((err) => console.log(err));
		}
	}, [search, pageNumber]);
	useEffect(() => {
		if (token && search !== "" && selectRoles !== "") {
			// Fetching user data based on selected roles & search from the API
			request
				.get(
					apiURL +
						VIEW_STAFFS +
						`?role=${selectRoles}` +
						`&search_key=${search}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log(response);
					setUsers(response.data.users);
					setRows(response.data.users.data);
				})
				.catch((err) => console.log(err));
		}
	}, [search, selectRoles, pageNumber]);
	useEffect(() => {
		if (token) {
			// Fetching user data from the API based on params
			request
				.get(apiURL + VIEW_STAFFS + `?page=${pageNumber}`)
				.then((response) => {
					console.log(response);
					setUsers(response.data.users);
					setRows(response.data.users.data);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumber]);

	///////Paginations//////////////
	/**
	 * Generates an array of Pagination.Items for rendering pagination links.
	 *
	 * @returns {React.ReactNode[]} - An array of Pagination.Item components.
	 */
	const PaginationItems = () => {
		const { total, per_page, current_page } = users;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};

	/**
	 * Handles the next pagination action.
	 */
	const handleNextPagination = () => {
		// Check if the current page is not the last page
		if (pageNumber !== Math.ceil(users.total / users.per_page)) {
			// Increment the page number
			setPageNumber(pageNumber + 1);
		}
	};

	/**
	 * Handles the previous pagination action.
	 */
	const handlePreviousPagination = () => {
		// Check if the current page is not the first page
		if (pageNumber !== 1) {
			// Decrement the page number
			setPageNumber(pageNumber - 1);
		}
	};
	//////////End of Paginations/////////////
	//Select the delete,active and inactive
	/**
	 * Handles the selection or deselection of a row.
	 *
	 * @param {Object} e - The event object.
	 * @param {Object} row - The data of the selected row.
	 */
	const handleSelectRow = React.useCallback(
		(e, row) => {
			if (e.target.checked) {
				// If the checkbox is checked, add the row to the selected rows
				setSelectedRows((prevSelectedRows) => [
					...prevSelectedRows,
					row.encrypted_id,
				]);
				setSelectedRowsId((prevSelectedRows) => [
					...prevSelectedRows,
					row.staff_name,
				]);
			} else {
				// If the checkbox is unchecked, remove the row from the selected rows
				setSelectedRows((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.encrypted_id
					)
				);
				setSelectedRowsId((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.staff_name
					)
				);
			}
		},
		[selectedRows]
	);

	/**
	 * Handles the selection or deselection of all rows.
	 *
	 * @param {Object} e - The event object.
	 */
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			// If the "Select All" checkbox is checked
			setSelectAll(true);

			// Update the rows with selected state and store the selected row IDs
			const updatedRows = users.data.map((row) => {
				return { ...row, selected: true };
			});
			setRows(updatedRows);

			// Get the IDs and names of all selected rows
			const updatedRowsIds = users.data.map((row) => {
				return row.encrypted_id;
			});
			const updatedRowsName = users.data.map((row) => {
				return row.staff_name;
			});

			// Set the selected row IDs and names
			setSelectAllRows((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsIds,
			]);
			setSelectedRowsName((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsName,
			]);
		} else {
			// If the "Select All" checkbox is unchecked
			setSelectAll(false);

			// Update the rows with deselected state and clear the selected row IDs
			const updatedRows = users.data.map((row) => {
				return { ...row, selected: false };
			});
			setRows(updatedRows);

			// Clear the selected row IDs
			setSelectAllRows([]);
		}
	};

	/**
	 * Handles the clearing of the select all functionality.
	 */
	const handleClearSelectAll = () => {
		// Set the select all state to false
		setSelectAll(false);

		// Update the rows to deselect all rows
		const updatedRows = users.data.map((row) => {
			return { ...row, selected: false };
		});
		setRows(updatedRows);

		// Clear the array of selected rows
		setSelectAllRows([]);
	};

	/**
	 * Handles the confirmation for bulk action.
	 *
	 * @param {function} setShow - The function to set the state variable for showing the confirmation.
	 */
	const handleBulkConfirmation = (setShow) => {
		// Set the state variable to show the confirmation
		setShow(true);
	};

	/**
	 * Handles the bulk deletion of selected entities.
	 */
	const handleBulkDelete = () => {
		// Check if a token is available
		if (token) {
			// Append the selected row IDs to the FormData object
			bodyFormData.append("staff_ids", JSON.stringify(selectedRows));

			// Send an HTTP POST request to bulk delete the entities
			request
				.post(apiURL + DELETE_STAFF_BULK, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reset the state variable for hiding the confirmation
					setShow(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk deletion of multiple entities.
	 */
	const handleBulkDeleteAll = () => {
		// Check if a token is available
		if (token) {
			// Append the selected row IDs to the FormData object
			bodyFormData.append("staff_ids", JSON.stringify(selectAllRow[0]));

			// Send an HTTP POST request to bulk delete the entities
			request
				.post(apiURL + DELETE_STAFF_BULK, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reset the state variable for showing the delete confirmation modal
					setShowModalDeleteAll(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk activation of selected rows.
	 */
	const handleBulkActivate = () => {
		if (token) {
			// Append the selected row IDs to the FormData object
			bodyFormData.append("staff_ids", JSON.stringify(selectedRows));

			// Send an HTTP POST request to bulk activate the entities
			request
				.post(apiURL + ACTIVATE_BULK_STAFF, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reset the state variable for bulk activation
					setActivate(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk activation of multiple entities.
	 */
	const handleBulkActivateAll = () => {
		// Check if a token is available
		if (token) {
			// Append the selected row IDs to the FormData object
			bodyFormData.append("staff_ids", JSON.stringify(selectAllRow[0]));

			// Send an HTTP POST request to bulk activate the entities
			request
				.post(apiURL + ACTIVATE_BULK_STAFF, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reset the state variable for bulk activation
					setActivateAll(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk deactivation of selected entities.
	 */
	const handleBulkDeactivate = () => {
		// Check if a token is available
		if (token) {
			// Append the selected rows' IDs to the FormData object
			bodyFormData.append("staff_ids", JSON.stringify(selectedRows));

			// Send an HTTP POST request to bulk deactivate the entities
			request
				.post(apiURL + DEACTIVATE_BULK_STAFF, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reset the state variable for bulk deactivation
					setDeactivate(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk deactivation of multiple entities.
	 */
	const handleBulkDeactivateAll = () => {
		// Check if a token is available
		if (token) {
			// Append the selected row IDs to the FormData object
			bodyFormData.append("staff_ids", JSON.stringify(selectAllRow[0]));

			// Send an HTTP POST request to bulk deactivate the entities
			request
				.post(apiURL + DEACTIVATE_BULK_STAFF, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reset the state variable for bulk deactivation
					setDeactivateAll(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the activation of an entity by its ID.
	 */
	const handleActivateById = () => {
		// Check if a token is available
		if (token) {
			// Send an HTTP GET request to activate the entity by its ID
			request
				.get(apiURL + ACTIVATE_STAFF_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reset the state variable for activation
					setActivateById(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the deactivation of an entity by its ID.
	 */
	const handleDeactivateById = () => {
		// Check if a token is available
		if (token) {
			// Send an HTTP GET request to deactivate the entity by its ID
			request
				.get(apiURL + DEACTIVATE_STAFF_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reset the state variable for deactivation
					setDeactivateById(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the activation or deactivation of an entity based on the given status.
	 *
	 * @param {string} status - The status of the entity ("1" for activation, other value for deactivation).
	 * @param {string} id - The ID of the entity.
	 * @param {string} name - The name of the entity.
	 */
	const handleActivateOrDeactive = (status, id, name) => {
		// Set the ID of the entity
		setId(id);

		// Set the status name of the entity
		setStatusName(name);

		// Check the status value to determine if the entity should be activated or deactivated
		if (status === "1") {
			// Set the state variable to activate the entity
			setDeactivateById(true);
		} else {
			// Set the state variable to deactivate the entity
			setActivateById(true);
		}
	};

	/**
	 * Deletes a customer by ID.
	 *
	 * @param {string} id - The ID of the customer to be deleted.
	 */
	const handleDeleteCustomer = (id) => {
		// Check if a token is available
		if (token) {
			// Send an HTTP GET request to delete the branch by ID
			request
				.get(apiURL + DELETE_STAFF_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Sets the state variables to display the delete confirmation modal.
	 *
	 * @param {string} id - The ID of the user to be deleted.
	 * @param {string} name - The name of the user to be deleted.
	 */
	const handleDeleteConfirmation = (id, name) => {
		// Sets the state variable to show the delete confirmation modal
		setShowDeleteByID(true);

		// Sets the state variable to store the name of the user to be deleted
		setUserName(name);

		// Sets the state variable to store the ID of the user to be deleted
		setUserId(id);
	};

	// Render the loader while data is being fetched
	if (!users.data) return <Loader />;
	else {
		return (
			<>
				<div className="d-sm-flex form-group">
					<div className="customer-search">
						<Form>
							{selectedRows.length !== 0 && (
								<Row className="align-items-center">
									<Col lg={4} sm={4} xs={4}>
										<div>
											<div className="row-multiple--select--customer">
												<>
													<div>
														<div>
															<div
																onClick={() => handleBulkConfirmation(setShow)}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	// margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
																// className="image-icons-holiday"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</div>

														{/* Dropdown*/}

														<div class="dropdown">
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",

																	height: "36px",

																	margin: "50px 0px 0px 179px",

																	padding: "7px 27px 6px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setActivate)
																	}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setDeactivate)
																	}
																>
																	Make as Inactive
																</a>
															</div>
														</div>

														<div
															style={{
																justifyContent: "center",
																margin: "8px 0px 0px 135px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{selectedRows.length} User(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "8px 0px 0px 299px",
																cursor: "pointer",
																position: "absolute",

																padding: "6px 9px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={() => setSelectedRows([])}
														>
															<img src={CancelIcon} alt="mindreach images" />
														</div>
													</div>
												</>
											</div>
										</div>
									</Col>
								</Row>
							)}
							{selectAllRow.length !== 0 && (
								<Row className="align-items-center">
									<Col lg={4} sm={4} xs={4}>
										<div>
											<div className="row-multiple--select--customer">
												<>
													<OverlayTrigger
														placement="right"
														delay={{ show: 250, hide: 400 }}
														overlay={renderTooltip("Delete")}
													>
														<div
															onClick={() =>
																handleBulkConfirmation(setShowModalDeleteAll)
															}
															style={{
																justifyContent: "center",
																marginTop: "9px",
																cursor: "pointer",
																padding: "6px 0px 11px 9px",
																position: "absolute",
															}}
															className="img-fluid--icons"
														>
															<img src={Delete} alt="mindreach images" />
														</div>
													</OverlayTrigger>
													{/* Dropdown*/}
													<div class="dropdown">
														<div
															onClick={() => setDropDown((val) => !val)}
															className="img-fluid--icons"
															style={{
																justifyContent: "center",
																cursor: "pointer",
																position: "absolute",
																height: "36px",
																margin: "18px 0px 0px 179px",
																padding: "7px 27px 6px 11px",
															}}
															id="dropdownMenuButton"
															data-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
														>
															<img src={MoreIcon} alt="mindreach images" />
														</div>
														<div
															class="dropdown-menu"
															aria-labelledby="dropdownMenuButton"
														>
															<a
																class="dropdown-item"
																style={{ color: "black" }}
																onClick={() =>
																	handleBulkConfirmation(setActivateAll)
																}
															>
																Make as Active
															</a>
															<a
																class="dropdown-item"
																style={{ color: "black" }}
																onClick={() =>
																	handleBulkConfirmation(setDeactivateAll)
																}
															>
																Make as Inactive
															</a>
														</div>
													</div>

													<div
														style={{
															justifyContent: "center",
															margin: "9px 0px 0px 135px",
															position: "absolute",
															padding: "6px 0px 11px 9px",
															cursor: "pointer",
														}}
													>
														<h3 className="row-multiple-select--h3">
															{" "}
															{rows.length} User(s) Selected
														</h3>
													</div>
													<div
														style={{
															margin: "9px 0px 0px 299px",
															cursor: "pointer",
															position: "absolute",
															padding: "6px 9px 9px 9px",
														}}
														className="img-fluid--icons"
														onClick={handleClearSelectAll}
													>
														<img src={CancelIcon} alt="mindreach images" />
													</div>
												</>
											</div>
										</div>
									</Col>
								</Row>
							)}

							{!selectedRows.length && !selectAllRow.length && (
								<Row className="align-items-center">
									<Col xs={2}>
										<Select
											options={selectLabel(roles)}
											placeholder="Select Role"
											onChange={(e) => setSelectRoles(e.label)}
										/>
									</Col>
									<Col>
										<Form.Control
											type="search"
											placeholder="Search"
											className="me-2"
											aria-label="Search"
											onChange={(e) => setSearch(e.target.value)}
										/>
									</Col>
									<Col>
										<p className="customer-count">
											<span>
												<img
													src={user}
													className="img-fluid"
													alt="mindreach images"
												/>
											</span>
											{users.total} User(s)
										</p>
									</Col>
								</Row>
							)}
						</Form>
					</div>
					<div className="">
						<AddButton
							img={plus}
							text="New User"
							url={STAFF_ADD_PAGE}
							variant="primary"
						/>
					</div>
				</div>
				<div
					className={
						`table-responsive table-b custom-new-table` +
						`  ` +
						`${selectedRows.length || selectAllRow.length ? `mt-5--table` : ""}`
					}
				>
					<Table hover>
						<thead>
							<tr>
								{typeof users.data !== "undefined" &&
									users.data.length !== 0 && (
										<>
											<th>
												{!selectedRows.length && (
													<input
														type="checkbox"
														checked={selectAll}
														onClick={handleSelectAll}
													/>
												)}
											</th>
											<th>
												Name{" "}
												<span style={{ marginLeft: "10px" }}>
													<img
														src={Sort}
														alt="sort"
														style={{ width: "16px", height: "16px" }}
													/>
												</span>
											</th>
											<th>Email</th>
											<th>Active Date</th>
											<th>Role</th>
											<th>Shift</th>
											<th>Status</th>
											<th></th>
										</>
									)}
							</tr>
						</thead>
						<tbody>
							{users.data !== undefined &&
								rows.map((item, index) => {
									const list = (
										<>
											<tr key={index}>
												<td className="checkbox-elements--table">
													<input
														type="checkbox"
														checked={
															selectedRows.length
																? selectedRows.find(
																		(selectedRow) => selectedRow.id === item
																  )
																: item.selected === true
														}
														onChange={(e) => handleSelectRow(e, item)}
													/>
												</td>
												<td
													onClick={() =>
														navigate(
															`${STAFF_DETAILS_PAGE}/${item.encrypted_id}`
														)
													}
												>
													<ClickableUrls text={item.staff_name} />
												</td>
												<td
													onClick={() =>
														navigate(
															`${STAFF_DETAILS_PAGE}/${item.encrypted_id}`
														)
													}
												>
													{item.email}
												</td>
												<td
													onClick={() =>
														navigate(
															`${STAFF_DETAILS_PAGE}/${item.encrypted_id}`
														)
													}
												>
													{toJSONLocal(item.created_at)}
												</td>
												<td
													onClick={() =>
														navigate(
															`${STAFF_DETAILS_PAGE}/${item.encrypted_id}`
														)
													}
												>
													{capitalizeEachWord(item.role)}
												</td>

												<td
													onClick={() =>
														navigate(
															`${STAFF_DETAILS_PAGE}/${item.encrypted_id}`
														)
													}
												>
													{item.shift}
												</td>
												<td
													onClick={() =>
														handleActivateOrDeactive(
															item.is_active,
															item.encrypted_id,
															item.staff_name
														)
													}
												>
													{item.is_active === "1" ? (
														<div className="active-staff">Active</div>
													) : (
														<div>Inactive</div>
													)}
													{console.log(item.is_active)}
												</td>
												<td className="edittd">
													<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
														<div
															className=""
															onClick={() =>
																handleDeleteConfirmation(
																	item.encrypted_id,
																	item.staff_name
																)
															}
														>
															<img
																src={Delete}
																className="img-fluid"
																alt="Delete"
															/>
														</div>
													</div>
												</td>
											</tr>
										</>
									);
									return list;
								})}
							{typeof users.data !== "undefined" && users.data.length === 0 && (
								<NoDataFound />
							)}
						</tbody>
					</Table>
				</div>

				<Row className="mt-4">
					<Col md={{ span: 4, offset: 8 }}>
						{
							<Pagination>
								<Pagination.Prev onClick={() => handlePreviousPagination()} />
								{PaginationItems()}
								<Pagination.Next onClick={() => handleNextPagination()} />
							</Pagination>
						}
					</Col>
				</Row>
				{/*** ActivateModalConfirmation for activating selected users***/}
				<ActivateModalConfirmation
					show={users_activate}
					onHide={() => {
						setActivate(false);
					}}
					branch={"user"}
					name={selectedRowId.toString()}
					title={"Activate Users"}
					handleConfirm={() => handleBulkActivate()}
				/>
				{/*** ActivateModalConfirmation for activating all users ***/}
				<ActivateModalConfirmation
					show={users_activate_all}
					onHide={() => {
						setActivateAll(false);
					}}
					branch={"users"}
					name={selectedRowName.toString()}
					title={"Activate Users"}
					handleConfirm={() => handleBulkActivateAll()}
				/>
				{/***DeActivateModalConfirmation for deactivating selected users***/}
				<DeActivateModalConfirmation
					show={users_deactivate}
					onHide={() => {
						setDeactivate(false);
					}}
					branch={"user"}
					name={selectedRowId.toString()}
					title={"Deactivate Users"}
					handleConfirm={() => handleBulkDeactivate()}
				/>
				{/***DeActivateModalConfirmation for deactivating all users***/}
				<DeActivateModalConfirmation
					show={users_deactivate_all}
					onHide={() => {
						setDeactivateAll(false);
					}}
					branch={"users"}
					name={selectedRowName.toString()}
					title={"Deactivate Users"}
					handleConfirm={() => handleBulkDeactivateAll()}
				/>
				{/*************Activate or deactivate by id */}
				<ActivateModalConfirmation
					show={activate_id}
					onHide={() => {
						setActivateById(false);
					}}
					branch={"users"}
					name={status_name}
					title={"Activate Users"}
					handleConfirm={() => handleActivateById()}
				/>
				<DeActivateModalConfirmation
					show={deactivate_id}
					onHide={() => {
						setDeactivateById(false);
					}}
					branch={"users"}
					name={status_name}
					title={"Deactivate Users"}
					handleConfirm={() => handleDeactivateById()}
				/>
				{/*************Delete as a bulk or delete by id */}
				<DeleteModalConfirmation
					show={show_delete_by_id}
					onHide={() => {
						setShowDeleteByID(false);
					}}
					branch={"user"}
					name={user_name}
					title={"Delete users"}
					handleConfirm={() => handleDeleteCustomer(user_id)}
				/>
				<DeleteModalConfirmation
					show={show}
					onHide={() => {
						setShow(false);
					}}
					branch={"user"}
					name={selectedRowId.toString()}
					title={"Delete Users"}
					handleConfirm={() => handleBulkDelete()}
				/>
				<DeleteModalConfirmation
					show={showModalDeleteAll}
					onHide={() => {
						setShowModalDeleteAll(false);
					}}
					branch={"user"}
					name={selectedRowName.toString()}
					title={"Delete Users"}
					handleConfirm={() => handleBulkDeleteAll()}
				/>
			</>
		);
	}
};

export default User;
