import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	Button,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";

// Importing components and images
import Loader from "../../components/Loader";
import DeleteModalConfirmation from "../../components/Modal/DeleteModal";
import ActivateModalConfirmation from "../../components/Modal/ActivateModal";
import DeActivateModalConfirmation from "../../components/Modal/DeactivateModal";
import DuplicateModalConfirmation from "../../components/Modal/DuplicateModal";
import NoDataFound from "../../components/NoDataFound";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import plus from "../../assets/Images/plus.svg";
import Delete from "../../assets/Images/delete.svg";

import duplicate from "./../../assets/Images/actionsIcons/Duplicate _Final.svg";
import CancelIcon from "./../../assets/Images/actionsIcons/cancel_Final.svg";
import MoreIcon from "./../../assets/Images/actionsIcons/more-Final.svg";

// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";

import {
	ADMIN_GET_BRANCHE,
	DELETE_BRANCHE,
	DELETE_BRANCHE_BULK,
	ACTIVATE_BRANCH_BY_ID,
	DEACTIVATE_BRANCH_BY_ID,
	ACTIVATE_BULK_BRANCH,
	DEACTIVATE_BULK_BRANCH,
	DUPLICATE_BULK_BRANCH,
} from "./../../config/apiRoutes.api";
import { EDIT_BRANCHE_PAGE, ADD_BRANCHE_PAGE } from "../../config/routes";
const Branche = () => {
	// Retrieve the token from the session storage
	const token = sessionStorage.getItem("token");

	// Create a request instance using the token
	const request = client(token);

	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();

	// Create a new instance of FormData
	const bodyFormData = new FormData();
	//Declarations of state variable
	const [branchesData, setBranchesData] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [show, setShow] = useState(false);
	const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);
	const [branchNameSeleted, setBranchNameSelected] = useState("");
	const [branchNameSeletedId, setBranchNameSelectedId] = useState("");
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowId, setSelectedRowsId] = useState([]);
	const [selectedRowName, setSelectedRowsName] = useState([]);
	const [selectAllRow, setSelectAllRows] = useState([]);

	const [activate_id, setActivateById] = useState(false);
	const [deactivate_id, setDeactivateById] = useState(false);
	const [status_name, setStatusName] = useState("");
	const [id, setId] = useState("");

	const [selectAll, setSelectAll] = useState(false);
	const [rows, setRows] = useState([]);

	const [branche_duplicate, setDuplicate] = useState(false);
	const [branche_duplicate_All, setDuplicateAll] = useState(false);

	const [branche_activate, setActivate] = useState(false);
	const [branche_activate_all, setActivateAll] = useState(false);

	const [branche_deactivate, setDeactivate] = useState(false);
	const [branche_deactivate_all, setDeactivateAll] = useState(false);

	/**
	 * Renders a Tooltip component with the specified text as its content.
	 * @param {string} text - The text to be displayed in the Tooltip.
	 * @returns {JSX.Element} The Tooltip component.
	 */
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

	/**
	 * Handles the selection of all rows in a table.
	 *
	 * @param {Object} e - The event object triggered by the checkbox.
	 */
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			setSelectAll(true);

			// Update all rows to be selected
			const updatedRows = branchesData[0].branches.data.map((row) => {
				return { ...row, selected: true };
			});
			setRows(updatedRows);

			// Update selected row IDs
			const updatedRowsIds = branchesData[0].branches.data.map((row) => {
				return row.encrypted_id;
			});
			setSelectAllRows((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsIds,
			]);

			// Update selected row names
			const updatedRowsName = branchesData[0].branches.data.map((row) => {
				return row.name;
			});
			setSelectedRowsId((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsName,
			]);
			setSelectedRowsName((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsName,
			]);
		} else {
			setSelectAll(false);

			// Update all rows to be unselected
			const updatedRows = branchesData[0].branches.data.map((row) => {
				return { ...row, selected: false };
			});
			setRows(updatedRows);

			// Clear selected row IDs
			setSelectAllRows([]);
		}
	};
	/**
	 * Handles the confirmation for deleting a branch.
	 *
	 * @param {string} id - The ID of the branch to be deleted.
	 * @param {string} name - The name of the branch to be deleted.
	 */
	const handleDeleteConfirmation = (id, name) => {
		// Show the delete confirmation modal
		setShow(true);

		// Set the selected branch name and ID
		setBranchNameSelected(name);
		setBranchNameSelectedId(id);
	};

	/**
	 * Fetches the branch data from the API when the pageNumber changes or the token is available.
	 */
	useEffect(() => {
		// Check if a token is available
		if (token) {
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(apiURL + ADMIN_GET_BRANCHE + `?page=${pageNumber}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);

					// Update the branchesData state with the response data
					setBranchesData(response.data);

					// Update the rows state with the branch data
					setRows(response.data[0].branches.data);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumber]);

	/**
	 * Renders the pagination items for navigating between pages.
	 * It generates the pagination items based on the total number of branches and the active page.
	 * @returns {JSX.Element[]} Array of pagination items.
	 */
	const PaginationItems = () => {
		const { total, per_page, current_page } = branchesData[0].branches;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};

	/**
	 * Handles the pagination to the next page of branches.
	 * It updates the page number state to the next page if it's not the last page.
	 * @returns {void}
	 */
	const NextPagination = () => {
		// Check if the current page number is not the last page
		if (
			pageNumber !==
			Math.ceil(
				branchesData[0].branches.total / branchesData[0].branches.per_page
			)
		) {
			// Update the page number state to the next page
			setPageNumber(pageNumber + 1);
		}
	};

	/**
	 * Handles the pagination to the previous page of branches.
	 * It updates the page number state to the previous page if it's not the first page.
	 * @returns {void}
	 */
	const PreviousPagination = () => {
		// Check if the current page number is not the first page
		if (
			pageNumber !==
			Math.ceil(
				branchesData[0].branches.total / branchesData[0].branches.per_page
			)
		) {
			// Update the page number state to the previous page
			setPageNumber(pageNumber - 1);
		}
		return setPageNumber(pageNumber - 1);
	};

	/**
	 * Handles the deletion of a branch by ID.
	 * @param {string} id - The ID of the branch to be deleted.
	 */
	const handleDeleteBranch = (id) => {
		// Check if a token is available
		if (token) {
			// Make a GET request to delete the branch by ID
			request
				.get(apiURL + DELETE_BRANCHE + `/${id}`)
				.then((response) => {
					console.log(response);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the selection/deselection of a row.
	 * @param {Object} e - The event object.
	 * @param {Object} row - The selected row object.
	 */
	const handleSelectRow = React.useCallback(
		(e, row) => {
			if (e.target.checked) {
				// If the checkbox is checked, add the row's encrypted_id and name to the selectedRows and selectedRowsId state respectively
				setSelectedRows((prevSelectedRows) => [
					...prevSelectedRows,
					row.encrypted_id,
				]);
				setSelectedRowsId((prevSelectedRows) => [
					...prevSelectedRows,
					row.name,
				]);
			} else {
				// If the checkbox is unchecked, remove the row's encrypted_id and name from the selectedRows and selectedRowsId state respectively
				setSelectedRows((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.encrypted_id
					)
				);
				setSelectedRowsId((prevSelectedRows) =>
					prevSelectedRows.filter((selectedRow) => selectedRow !== row.name)
				);
			}
		},
		[selectedRows]
	);

	/**
	 * Handles the bulk deletion of selected branches.
	 */
	const handleBulkDelete = () => {
		// Check if a token is available
		if (token) {
			// Append the selected branch IDs to the form data
			bodyFormData.append("branch_ids", JSON.stringify(selectedRows));

			// Make an HTTP POST request to delete the selected branches in bulk
			request
				.post(apiURL + DELETE_BRANCHE_BULK, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);

					// Close the delete confirmation modal
					setShow(false);

					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk deletion of all selected branches.
	 */
	const handleBulkDeleteAll = () => {
		// Check if a token is available
		if (token) {
			// Append the selected branch IDs to the form data
			bodyFormData.append("branch_ids", JSON.stringify(selectAllRow[0]));

			// Make an HTTP POST request to delete the selected branches in bulk
			request
				.post(apiURL + DELETE_BRANCHE_BULK, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);

					// Close the delete confirmation modal
					setShowModalDeleteAll(false);

					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the confirmation for bulk actions.
	 *
	 * @param {function} setShow - The function to set the visibility of the confirmation modal.
	 */
	const handleBulkConfirmation = (setShow) => {
		// Show the confirmation modal
		setShow(true);
	};

	/**
	 * Activates a branch by ID.
	 *
	 * This function makes an HTTP GET request to the API endpoint to activate a branche with the specified ID.
	 * If a valid token is available, the request is sent, and upon success, the function updates the state and reloads the page.
	 *
	 * @throws {Error} If the HTTP request fails or encounters an error.
	 */
	const handleActivateById = () => {
		// Check if a token is available
		if (token) {
			// Make an HTTP GET request to activate branche by ID
			request
				.get(apiURL + ACTIVATE_BRANCH_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Update the state to indicate successful activation
					setActivateById(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Deactivates a branch by ID.
	 *
	 * This function makes an HTTP GET request to the API endpoint to deactivate a branche with the specified ID.
	 * If a valid token is available, the request is sent, and upon success, the function updates the state and reloads the page.
	 *
	 * @throws {Error} If the HTTP request fails or encounters an error.
	 */
	const handleDeactivateById = () => {
		// Check if a token is available
		if (token) {
			// Make an HTTP GET request to deactivate branche by ID
			request
				.get(apiURL + DEACTIVATE_BRANCH_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Update the state to indicate successful deactivation
					setDeactivateById(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles the activation or deactivation of a branch.
	 *
	 * This function updates the necessary state variables based on the provided status.
	 * It sets the ID and status name, and depending on the status value, it either sets the 'deactivateById' state to true or the 'activateById' state to true.
	 *
	 * @param {string} status - The status of the branch ("1" for deactivation, any other value for activation).
	 * @param {string} id - The ID of the branche.
	 * @param {string} name - The name of the branche.
	 */
	const handleActivateOrDeactive = (status, id, name) => {
		// Set the ID and status name
		setId(id);
		setStatusName(name);
		// Check the status value and update the corresponding state
		if (status === "1") {
			// Set the 'deactivateById' state to true for deactivation
			setDeactivateById(true);
		} else {
			// Set the 'activateById' state to true for activation
			setActivateById(true);
		}
	};
	/**
	 * Handles the bulk duplication of selected branche.
	 *
	 * This function is called when the bulk duplicate button is clicked.
	 * It performs the duplication of the selected branche by sending a POST request to the corresponding API endpoint.
	 * The branche IDs of the selected branche are included in the request payload as an array of strings.
	 * After the duplication is complete, the function logs the response, updates the state to reflect the duplication status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectedRows`, `request`, `apiURL`, `setDuplicate` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDuplicate = () => {
		if (token) {
			// Append the branche IDs to the FormData object
			bodyFormData.append("branch_ids", JSON.stringify(selectedRows));

			// Send a POST request to duplicate the selected branche
			request
				.post(apiURL + DUPLICATE_BULK_BRANCH, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the duplication status
					setDuplicate(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk duplication of all selected branche.
	 *
	 * This function is called when the bulk duplicate button is clicked.
	 * It performs the duplication of all selected branche by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected branche are included in the request payload as an array of strings.
	 * After the duplication is complete, the function logs the response, updates the state to reflect the duplication status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectAllRow`, `request`, `apiURL`, `setDuplicateAll` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDuplicateAll = () => {
		if (token) {
			// Append the branche IDs to the FormData object
			bodyFormData.append("branch_ids", JSON.stringify(selectAllRow[0]));

			// Send a POST request to duplicate bulk branche
			request
				.post(apiURL + DUPLICATE_BULK_BRANCH, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the duplication status
					setDuplicateAll(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles the bulk activation of selected branche.
	 *
	 * This function is called when the bulk activate button is clicked.
	 * It performs the activation of the selected branches by sending a POST request to the corresponding API endpoint.
	 * The branch IDs of the selected branches are included in the request payload as an array of strings.
	 * After the activation is complete, the function logs the response, updates the state to reflect the activation status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectedRows`, `request`, `apiURL`, `setActivate` are assumed to be defined and accessible within the component.
	 */
	const handleBulkActivate = () => {
		if (token) {
			// Append the branch IDs to the FormData object
			bodyFormData.append("branch_ids", JSON.stringify(selectedRows));

			// Send a POST request to activate the selected branche
			request
				.post(apiURL + ACTIVATE_BULK_BRANCH, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the activation status
					setActivate(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk activation of all selected branche.
	 *
	 * This function is called when the bulk activate button is clicked.
	 * It performs the activation of all selected branche by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected branche are included in the request payload as an array of strings.
	 * After the activation is complete, the function logs the response, updates the state to reflect the activation status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectAllRow`, `request`, `apiURL`, `setActivateAll` are assumed to be defined and accessible within the component.
	 */
	const handleBulkActivateAll = () => {
		if (token) {
			// Append the branche IDs to the FormData object
			bodyFormData.append("branch_ids", JSON.stringify(selectAllRow[0]));

			// Send a POST request to activate bulk branche
			request
				.post(apiURL + ACTIVATE_BULK_BRANCH, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the activation status
					setActivateAll(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles the bulk deactivation of selected branche.
	 *
	 * This function is called when the bulk deactivate button is clicked.
	 * It performs the deactivation of the selected branche by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected branche are included in the request payload as an array of strings.
	 * After the deactivation is complete, the function logs the response, updates the state to reflect the deactivation status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectedRows`, `request`, `apiURL`, `setDeactivate` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDeactivate = () => {
		if (token) {
			// Append the branche IDs to the FormData object
			bodyFormData.append("branch_ids", JSON.stringify(selectedRows));

			// Send a POST request to deactivate the selected branche
			request
				.post(apiURL + DEACTIVATE_BULK_BRANCH, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the deactivation status
					setDeactivate(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk deactivation of all selected branche.
	 *
	 * This function is called when the bulk deactivate button is clicked.
	 * It performs the deactivation of all selected branche by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected branche are included in the request payload as an array of strings.
	 * After the deactivation is complete, the function logs the response, updates the state to reflect the deactivation status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectAllRow`, `request`, `apiURL`, `setDeactivateAll` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDeactivateAll = () => {
		if (token) {
			// Append the branche IDs to the FormData object
			bodyFormData.append("branch_ids", JSON.stringify(selectAllRow[0]));

			// Send a POST request to deactivate bulk branche
			request
				.post(apiURL + DEACTIVATE_BULK_BRANCH, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the deactivation status
					setDeactivateAll(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles the clearing of the "Select All" functionality.
	 *
	 * This function clears the "Select All" state and updates the rows and selected rows to their default values.
	 * It sets the "selectAll" state to false, sets each row's "selected" property to false, and clears the selected rows array.
	 */
	const handleClearSelectAll = () => {
		// Clear the "selectAll" state
		setSelectAll(false);
		// Update each row's "selected" property to false
		const updatedRows = branchesData[0].branches.data.map((row) => {
			return { ...row, selected: false };
		});
		setRows(updatedRows);
		// Clear the selected rows array
		setSelectAllRows([]);
	};
	if (!branchesData.length) return <Loader />;
	else {
		// Render the actual content once branchesData is available
		return (
			<>
				<div>
					<div className="rightbar pr-11">
						<div className="section-title">
							<h2>Branches</h2>
						</div>
						<div className="d-sm-flex flex-sm-wrap col-gap-30-sm mt-2-branch">
							<div className="customer-search">
								<Form>
									{selectedRows.length !== 0 && (
										<Row className="align-items-center">
											<Col lg={4} sm={4} xs={4}>
												<div>
													<div className="row-multiple--select--customer">
														<>
															<div>
																<div
																	onClick={() =>
																		handleBulkConfirmation(setShow)
																	}
																	style={{
																		justifyContent: "center",
																		marginTop: "9px",
																		cursor: "pointer",
																		margin: "9px",
																		padding: "6px 0px 11px 9px",
																		position: "absolute",
																	}}
																	className="img-fluid--icons"
																>
																	<img src={Delete} alt="mindreach images" />
																</div>
															</div>

															<OverlayTrigger
																placement="right"
																delay={{ show: 250, hide: 400 }}
																overlay={renderTooltip("Duplicate")}
															>
																<div
																	style={{
																		justifyContent: "center",
																		margin: "9px 0px 0px 79px",
																		cursor: "pointer",
																		position: "absolute",
																		zIndex: "5",
																		// margin: "9px",
																		padding: "6px 0px 11px 9px",
																		cursor: "pointer",
																		// transition:
																		// 	"background-color 0.2s ease-in-out",
																	}}
																	className="img-fluid--icons"
																	onClick={() =>
																		handleBulkConfirmation(setDuplicate)
																	}
																>
																	<img src={duplicate} alt="mindreach images" />
																</div>
															</OverlayTrigger>

															{/* Dropdown*/}

															<div class="dropdown">
																<div
																	className="img-fluid--icons"
																	style={{
																		justifyContent: "center",
																		cursor: "pointer",

																		cursor: "pointer",
																		position: "absolute",
																		margin: "18px 0px 0px 343px",
																		height: "36px",
																		padding: "6px 27px 11px 11px",
																	}}
																	id="dropdownMenuButton"
																	data-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false"
																>
																	<img src={MoreIcon} alt="mindreach images" />
																</div>
																<div
																	class="dropdown-menu"
																	aria-labelledby="dropdownMenuButton"
																>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		onClick={() =>
																			handleBulkConfirmation(setActivate)
																		}
																	>
																		Make as Active
																	</a>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		onClick={() =>
																			handleBulkConfirmation(setDeactivate)
																		}
																	>
																		Make as Inactive
																	</a>
																</div>
															</div>

															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 224px",
																	position: "absolute",
																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																}}
															>
																<h3 className="row-multiple-select--h3">
																	{" "}
																	{selectedRows.length} Branche(s) Selected
																</h3>
															</div>
															<div
																style={{
																	margin: "9px 0px 0px 386px",
																	cursor: "pointer",
																	position: "absolute",
																	// margin: "9px 9px 9px 12px",
																	padding: "6px 6px 9px 9px",
																}}
																className="img-fluid--icons"
																onClick={() => setSelectedRows([])}
															>
																<img src={CancelIcon} alt="mindreach images" />
															</div>
														</>
													</div>
												</div>
											</Col>
										</Row>
									)}
									{selectAllRow.length !== 0 && (
										<Row className="align-items-center">
											<Col lg={4} sm={4} xs={4}>
												<div>
													<div className="row-multiple--select--customer">
														<>
															<OverlayTrigger
																placement="right"
																delay={{ show: 250, hide: 400 }}
																overlay={renderTooltip("Delete")}
															>
																<div
																	onClick={() =>
																		handleBulkConfirmation(
																			setShowModalDeleteAll
																		)
																	}
																	style={{
																		justifyContent: "center",
																		marginTop: "9px",
																		cursor: "pointer",
																		margin: "9px",
																		padding: "6px 0px 11px 9px",
																		position: "absolute",
																	}}
																	className="img-fluid--icons"
																>
																	<img src={Delete} alt="mindreach images" />
																</div>
															</OverlayTrigger>
															<OverlayTrigger
																placement="right"
																delay={{ show: 250, hide: 400 }}
																overlay={renderTooltip("Duplicate")}
															>
																<div
																	style={{
																		justifyContent: "center",
																		margin: "9px 0px 0px 79px",
																		cursor: "pointer",
																		position: "absolute",
																		zIndex: "5",
																		// margin: "9px",
																		padding: "6px 0px 11px 9px",
																		cursor: "pointer",
																		// transition:
																		// 	"background-color 0.2s ease-in-out",
																	}}
																	className="img-fluid--icons"
																	onClick={() =>
																		handleBulkConfirmation(setDuplicateAll)
																	}
																>
																	<img src={duplicate} alt="mindreach images" />
																</div>
															</OverlayTrigger>

															{/* Dropdown*/}

															<div class="dropdown">
																<div
																	className="img-fluid--icons"
																	style={{
																		justifyContent: "center",
																		cursor: "pointer",

																		cursor: "pointer",
																		position: "absolute",
																		margin: "18px 0px 0px 343px",
																		height: "36px",
																		padding: "6px 27px 11px 11px",
																	}}
																	id="dropdownMenuButton"
																	data-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false"
																>
																	<img src={MoreIcon} alt="mindreach images" />
																</div>
																<div
																	class="dropdown-menu"
																	aria-labelledby="dropdownMenuButton"
																>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		onClick={() =>
																			handleBulkConfirmation(setActivateAll)
																		}
																	>
																		Make as Active
																	</a>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		onClick={() =>
																			handleBulkConfirmation(setDeactivateAll)
																		}
																	>
																		Make as Inactive
																	</a>
																</div>
															</div>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 227px",
																	position: "absolute",
																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																}}
															>
																<h3 className="row-multiple-select--h3">
																	{" "}
																	{rows.length} Branche(s) Selected
																</h3>
															</div>
															<div
																style={{
																	margin: "9px 0px 0px 386px",
																	cursor: "pointer",
																	position: "absolute",
																	// margin: "9px 9px 9px 12px",
																	padding: "6px 9px 9px 8px",
																}}
																className="img-fluid--icons"
																onClick={handleClearSelectAll}
															>
																<img src={CancelIcon} alt="mindreach images" />
															</div>
														</>
													</div>
												</div>
											</Col>
										</Row>
									)}
								</Form>
							</div>

							<div>
								<Button
									variant="primary"
									onClick={() => navigate(ADD_BRANCHE_PAGE)}
								>
									<span>
										<img src={plus} className="img-fluid mr-2" />
									</span>
									New Branch
								</Button>{" "}
							</div>
						</div>

						<>
							<div
								className={
									`table-responsive table-b custom-new-table ` +
									`  ` +
									`${
										selectedRows.length || selectAllRow.length
											? `mt-21px--table`
											: ""
									}`
								}
								style={{ marginTop: "3rem !important" }}
							>
								<Table hover>
									<thead>
										{typeof rows !== "undefined" && rows.length !== 0 && (
											<tr>
												<th>
													{!selectedRows.length && (
														<input
															type="checkbox"
															checked={selectAll}
															onClick={handleSelectAll}
														/>
													)}
												</th>
												<th>Name</th>
												<th>Location</th>
												<th>Description</th>
												<th>Timezone</th>
												<th>Status</th>
												<th></th>
											</tr>
										)}
									</thead>
									<tbody>
										{rows !== undefined &&
											rows.map((item) => {
												const list = (
													<>
														<tr>
															<td className="checkbox-elements--table">
																<input
																	type="checkbox"
																	checked={
																		selectedRows.length
																			? selectedRows.find(
																					(selectedRow) =>
																						selectedRow.id === item
																			  )
																			: item.selected === true
																	}
																	onChange={(e) => handleSelectRow(e, item)}
																/>
															</td>
															<td
																onClick={() =>
																	navigate(
																		`${EDIT_BRANCHE_PAGE}/${item.encrypted_id}`
																	)
																}
															>
																{item.name}
															</td>
															<td
																onClick={() =>
																	navigate(
																		`${EDIT_BRANCHE_PAGE}/${item.encrypted_id}`
																	)
																}
															>
																{item.location}
															</td>
															<td
																onClick={() =>
																	navigate(
																		`${EDIT_BRANCHE_PAGE}/${item.encrypted_id}`
																	)
																}
															>
																{item.description}
															</td>
															<td
																onClick={() =>
																	navigate(
																		`${EDIT_BRANCHE_PAGE}/${item.encrypted_id}`
																	)
																}
															>
																{item.time_zone}
															</td>
															<td
																onClick={() =>
																	handleActivateOrDeactive(
																		item.is_active,
																		item.encrypted_id,
																		item.shift_name
																	)
																}
															>
																{item.is_active === "1" ? (
																	<div className="active-staff">Active</div>
																) : (
																	<div>Inactive</div>
																)}
															</td>
															<td className="edittd">
																<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
																	<div
																		className=""
																		onClick={() =>
																			handleDeleteConfirmation(
																				item.encrypted_id,
																				item.name
																			)
																		}
																	>
																		<img
																			src={Delete}
																			className="img-fluid"
																			alt="Delete"
																		/>
																	</div>
																</div>
															</td>
														</tr>
													</>
												);
												return list;
											})}
										{typeof rows !== "undefined" && rows.length === 0 && (
											<NoDataFound />
										)}
									</tbody>
								</Table>
							</div>

							<Row className="mt-4">
								<Col
									md={{ span: 4, offset: 8 }}
									className="flex flex-md-row-reverse "
								>
									<Pagination>
										<Pagination.Prev onClick={() => PreviousPagination()} />
										{PaginationItems()}
										<Pagination.Next onClick={() => NextPagination()} />
									</Pagination>
								</Col>
							</Row>
						</>

						{typeof branchesData[0].branches === undefined &&
							branchesData[0].branches.length === 0 && <NoDataFound />}
					</div>
				</div>
				<DeleteModalConfirmation
					show={show}
					onHide={() => {
						setShow(false);
					}}
					branch={"branch"}
					name={branchNameSeleted}
					title={"Delete branch"}
					handleConfirm={() => handleDeleteBranch(branchNameSeletedId)}
				/>
				<DeleteModalConfirmation
					show={show}
					onHide={() => {
						setShow(false);
					}}
					branch={"branch"}
					name={selectedRowId.toString()}
					title={"Delete branch"}
					handleConfirm={() => handleBulkDelete()}
				/>
				<DeleteModalConfirmation
					show={showModalDeleteAll}
					onHide={() => {
						setShowModalDeleteAll(false);
					}}
					branch={"branch"}
					name={selectedRowId.toString()}
					title={"Delete branch"}
					handleConfirm={() => handleBulkDeleteAll()}
				/>
				{/*************Activate or deactivate by id */}
				<ActivateModalConfirmation
					show={activate_id}
					onHide={() => {
						setActivateById(false);
					}}
					branch={"branch"}
					name={status_name}
					title={"Activate Branch"}
					handleConfirm={() => handleActivateById()}
				/>
				<DeActivateModalConfirmation
					show={deactivate_id}
					onHide={() => {
						setDeactivateById(false);
					}}
					branch={"branch"}
					name={status_name}
					title={"Deactivate Branch"}
					handleConfirm={() => handleDeactivateById()}
				/>
				{/*************Duplicate branch based on select */}
				<DuplicateModalConfirmation
					show={branche_duplicate}
					onHide={() => {
						setDuplicate(false);
					}}
					branch={"branche"}
					name={selectedRowId.toString()}
					title={"Duplicate branche"}
					handleConfirm={() => handleBulkDuplicate()}
				/>
				<DuplicateModalConfirmation
					show={branche_duplicate_All}
					onHide={() => {
						setDuplicateAll(false);
					}}
					branch={"branche"}
					name={selectedRowName.toString()}
					title={"Duplicate branches"}
					handleConfirm={() => handleBulkDuplicateAll()}
				/>
				{/*************Activate branch based on select */}
				<ActivateModalConfirmation
					show={branche_activate}
					onHide={() => {
						setActivate(false);
					}}
					branch={selectedRowId.length === 1 ? "branche" : "branches"}
					name={selectedRowId.toString()}
					title={"Activate branche"}
					handleConfirm={() => handleBulkActivate()}
				/>
				<ActivateModalConfirmation
					show={branche_activate_all}
					onHide={() => {
						setActivateAll(false);
					}}
					branch={selectedRowName.length === 1 ? "branche" : "branches"}
					name={selectedRowName.toString()}
					title={"Activate branche"}
					handleConfirm={() => handleBulkActivateAll()}
				/>
				{/*************Deactivate branch based on select */}
				<DeActivateModalConfirmation
					show={branche_deactivate}
					onHide={() => {
						setDeactivate(false);
					}}
					branch={selectedRowId.length === 1 ? "branche" : "branches"}
					name={selectedRowId.toString()}
					title={"Deactivate branche"}
					handleConfirm={() => handleBulkDeactivate()}
				/>
				<DeActivateModalConfirmation
					show={branche_deactivate_all}
					onHide={() => {
						setDeactivateAll(false);
					}}
					branch={selectedRowName.length === 1 ? "branche" : "branches"}
					name={selectedRowName.toString()}
					title={"Deactivate branches"}
					handleConfirm={() => handleBulkDeactivateAll()}
				/>
			</>
		);
	}
};

export default Branche;
