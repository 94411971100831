import React, { useState } from "react";

/**
 * Main layout component that wraps the application content.
 * @param {React.ReactNode} children - The child components to be rendered.
 * @returns {React.ReactNode} - The rendered main layout.
 */
const MobileLayout = ({ children }) => {
	const [isMenuOpen, setMenuOpen] = useState(true);
	return (
		<>
			<div className="mobile-container">
				<div className="mobile--text">
					<h2>Browsers with resolution below 1024 x 1024 are not supported</h2>
				</div>
			</div>
		</>
	);
};

export default MobileLayout;
