import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";

import { apiURL } from "../../../../../../data/filepath";
import { client } from "../../../../../../data/API/GobalClientAxiosRequest";
import {
	VIEW_CUSTOMER_EDIT_TYPE_BY_ID,
    UPDATE_CUSTOMER_EDIT_TYPE,
	EDIT_TYPE_EDIT_BY_ID,
    EDIT_TYPE_VIEW_ID,
} from "../../../../../../config/apiRoutes.api";

/**
 * Reusable modal component.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.heading - The title or heading of the modal.
 * @param {string} props.message - The content or message of the modal.
 * @returns {JSX.Element} - The rendered modal component.
 */
const EditTypeModal = (props) => {
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	//Declarations of state variable
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [promoted_service, setPromotedServices] = useState(0);
	const [isMultiplesImages, setIsMultipleImages] = useState("");
	const [price, setPrice] = useState("");
	const [editor_point, setEditorPoint] = useState("");
	const [qc_point, setQcPoint] = useState("");
	const [status, setStatus] = useState("");
	const [data, setData] = useState("");
	const [priceCustom, setPriceCustom] = useState(0);
	const [editor_point_custom, setEditorPointCustom] = useState(0);
	const [qc_point_custom, setQcPointCustom] = useState(0);

	useEffect(() => {
		if (token && props.edit_id !== "") {
			request
				.get(apiURL + VIEW_CUSTOMER_EDIT_TYPE_BY_ID + `/${props.id}` + `/${props.edit_id}`)
				.then((response) => {
					console.log("response call", response);
                    setData(response.data[0].editType);
                    setName(response.data[0].editType.edit_type);
                    setDescription(response.data[0].editType.description);
                    setPromotedServices(response.data[0].editType.is_for_enterprise);
                    setIsMultipleImages(response.data[0].editType.is_multiple);
                    setPrice(response.data[0].editType.standard_enterprise_price);
                    setQcPoint(response.data[0].editType.qc_point);
                    setEditorPoint(response.data[0].editType.editor_point);
                    if(response.data[0].edit_custom_price != null)
                    {
                        setPriceCustom(response.data[0].edit_custom_price.price);
                        setEditorPointCustom(response.data[0].edit_custom_price.editor_point);
                        setQcPointCustom(response.data[0].edit_custom_price.qc_point);
                    }
                    else
                    {
                        setPriceCustom(0);
                        setEditorPointCustom(0);
                        setQcPointCustom(0);
                    }
				})
				.catch((err) => console.log(err));
		}
	}, [props.edit_id]);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (token) {
			const request = client(token);

			bodyFormData.append("custom_price", priceCustom);
			bodyFormData.append("editor_point", editor_point_custom);
			bodyFormData.append("qc_point", qc_point_custom);
			request
				.post(apiURL + UPDATE_CUSTOMER_EDIT_TYPE + `/${props.id}` + `/${props.edit_id}`, bodyFormData)
				.then((response) => {
					console.log("add", response);
					props.setStatus("Success");
					props.setEditTypeEditModal(false);
					props.setSubmitStatusMessage(response.data.message);
					props.setModalShowEdit(true);

					props.handleEditTypeApiFetchOnSubmit();
				})
				.catch((err) => {
					setStatus("Error");
					props.setSubmitStatusMessage(err);
				});
		}
	};
	return (
		<Modal
			{...props}
			// backdrop="static"
			// keyboard={false}

			size="lg"
			// aria-labelledby="contained-modal-title-vcenter"
			aria-labelledby="example-modal-sizes-title-lg"
			contentClassName="modal-content-edit--type "
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title
					id="example-modal-sizes-title-lg"
					className="section-title fonts"
					style={{ marginLeft: "10px" }}
				>
					{props.heading}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className="section-title fonts"
				style={{ marginLeft: "10px" }}
			>
				{" "}
				<div className="edit-type-modal--title ">
					<div>
						Edit Type Id:
						<span className="edit-type-modal-span--edit_id">{data.id}</span>
					</div>
					<div>
						Status:
						{data.is_active === "1" ? (
							<span className="edit-type-modal-span--active">Active</span>
						) : (
							<span className="edit-type-modal-span--in-active">Inactive</span>
						)}
					</div>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Name</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="text"
								placeholder="Background Removal"
								autoFocus
								autoComplete="off"
								value={name}
                                readOnly
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Description</Form.Label>
						<Col sm={11}>
							<Form.Control
								as="textarea"
								placeholder="Write Description..."
								style={{ height: "148px" }}
								autoFocus
								autoComplete="off"
								value={description}
                                readOnly
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4 edit-type-modal-standard-type">
					{/* <Row> */}
					<div>
						<Form.Label>Standard Price</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="text"
							placeholder="$10"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							value={price}
							readOnly
						/>
						{/* </Col> */}
					</div>
					<div>
						<Form.Label>Standard QC Score</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="number"
							placeholder="500"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							readOnly
							value={qc_point}
						/>
						{/* </Col> */}
					</div>
					<div>
						<Form.Label>Standard Editor Score</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="number"
							placeholder="10,000"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							readOnly
							value={editor_point}
						/>
						{/* </Col> */}
					</div>
					{/* </Row> */}
				</div>
				<div className="form-group mt-3 mb-4 edit-type-modal-standard-type">
					{/* <Row> */}
					<div>
						<Form.Label>Custom Price</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="text"
							placeholder="$10"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							value={priceCustom}
							onChange={(e) => setPriceCustom(e.target.value)}
						/>
						{/* </Col> */}
					</div>
					<div>
						<Form.Label>Custom QC Score</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="number"
							placeholder="500"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							value={qc_point_custom}
                            onChange={(e) => setQcPointCustom(e.target.value)}
						/>
						{/* </Col> */}
					</div>
					<div>
						<Form.Label>Custom Editor Score</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="number"
							placeholder="10,000"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							value={editor_point_custom}
                            onChange={(e) => setEditorPointCustom(e.target.value)}
						/>
						{/* </Col> */}
					</div>
					{/* </Row> */}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="form-group">
					<div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
						<div className="">
							<Button
								variant=""
								className="puple btns mr-3"
								type="submit"
								onClick={props.onHide}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								className="btns"
								type="submit"
								onClick={(e) => handleSubmit(e)}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default EditTypeModal;
