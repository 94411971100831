import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import loginimage from "../../assets/Images/login-img.png";
import facebook from "../../assets/Images/facebook.png";
import linkedin from "../../assets/Images/linkedin.png";
import amazon from "../../assets/Images/amazon.png";
import microsoft from "../../assets/Images/microsoft.png";
import google from "../../assets/Images/google.png";
import { useNavigate } from "react-router-dom";
const Signup = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <Row className="align-items-center justify-content-center">
          {/* <Col sm={6}>
            <img src={loginimage} className="img-fluid" alt="mindreach images" alt="" />
      </Col> */}
          <Col sm={5}>
            <form className="Auth-form">
              <div className="Auth-form-content">
                <h3 className="Auth-form-title">Sign Up</h3>
                <div className="text-center">
                  Already registered?{" "}
                  <span
                    className="link-primary"
                    onClick={() => navigate("/login")}
                  >
                    Sign In
                  </span>
                </div>
                <div className="form-group mt-3">
                  <label>Full Name</label>
                  <input
                    type="email"
                    className="form-control mt-1"
                    placeholder="e.g Jane Doe"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control mt-1"
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control mt-1"
                    placeholder="Password"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    className="form-control mt-1"
                    placeholder="Password"
                  />
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
                <p className="text-center mt-3">
                  Forgot <a href="#">password?</a>
                </p>

                <p className="text-center mt-3">Sign up with</p>

                <ul className="sociallink mt-4 text-center">
                  <li>
                    {" "}
                    <img
                      src={facebook}
                      className="img-fluid"
                      alt="mindreach images"
                    />
                  </li>
                  <li>
                    {" "}
                    <img
                      src={linkedin}
                      className="img-fluid"
                      alt="mindreach images"
                    />
                  </li>
                  <li>
                    {" "}
                    <img
                      src={amazon}
                      className="img-fluid"
                      alt="mindreach images"
                    />
                  </li>
                  <li>
                    {" "}
                    <img
                      src={microsoft}
                      className="img-fluid"
                      alt="mindreach images"
                    />
                  </li>
                  <li>
                    {" "}
                    <img
                      src={google}
                      className="img-fluid"
                      alt="mindreach images"
                    />
                  </li>
                </ul>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Signup;
