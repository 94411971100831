import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MyVerticallyCenteredModal from "../../components/Modal";
import DeleteModalConfirmation from "../../components/Modal/DeleteModal";
import TimePicker from '../../components/TimePicker'; // import the time picker js from the custom component
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Delete from "../../assets/Images/delete.svg";
import PlusIcon from "../../assets/Images/settings/plus-icon.svg";
import MinusIcon from "../../assets/Images/settings/minus-icons.svg";
import { uuid } from "uuidv4";
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
	WORK_SHIFTS_VIEW_ID,
	BRANCHES_LIST,
	WORK_SHIFTS_EDIT_BY_ID,
	DELETE_WORK_SHIFT_BY_ID,
} from "./../../config/apiRoutes.api";
import { WORK_SHIFTS_LISTS_PAGE } from "../../config/routes";
const AddWorkShift = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [status, setStatus] = useState("");
	const [row, setRow] = useState([]);
	const [branches, setBranchesData] = useState([]);
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const [show, setShow] = useState(false);
	const [modalShowTimeZone, setModalShowTimeZone] = useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [branchNameSeleted, setBranchNameSelected] = useState("");
	const [shiftNameSeletedId, setShiftNameSelectedId] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [brancheName, setBranchesName] = useState(0);
	const [modalShow, setModalShow] = React.useState(false);
	const [addResponse, setAddResponse] = useState("");
	const [inputFields, setInputFields] = useState([
		{
			shift_start_day: "",
			shift_start_time: "",
			shift_end_day: "",
			shift_end_time: "",
		},
		{
			shift_start_day: "",
			shift_start_time: "",
			shift_end_day: "",
			shift_end_time: "",
		},
		{
			shift_start_day: "",
			shift_start_time: "",
			shift_end_day: "",
			shift_end_time: "",
		},
	]);
	const startDate = [
		{ label: "Monday", value: 1 },
		{ label: "Tuesday", value: 2 },
		{ label: "Wednesday", value: 3 },
		{ label: "Thursday", value: 4 },
		{ label: "Friday", value: 5 },
		{ label: "Saturday", value: 6 },
	];
	
	const handleAddRow = () => {
		if (row.length <= 2) {
			setRow((prevState) => [
				...prevState,
				{
					id: uuid(),
					shift_start_day: "Monday",
					shift_start_time: "08:00",
					shift_end_day: "Monday",
					shift_end_time: "13:00",
				},
			]);
		}
	}; // add an object with specific uuid
	const removeItem = (removeId,index = -1) => {
		setRow((prevState) => prevState.filter(({ id }) => id !== removeId));
		if(index >= 0){
			const inputFieldArray = [...inputFields];
			inputFieldArray.splice(index, 1);
			setInputFields(inputFieldArray);
		}
		
	}; // remove the object from previous array of object based on id
	useEffect(() => {
		if (token !== null) {
			request
				.get(apiURL + BRANCHES_LIST)
				.then((response) => {
					setBranchesData(response.data[0].branches);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	/**
	 * Transforms an string into first letter.
	 * @param {String} string - An string of text passed.
	 * @returns {String} - An string with the first letter in caps.
	 */
		
	const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).replace("_"," ");

	const selectLabel = (branches) =>
		branches.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	const handleSelectedDeleted = (id, name) => {
		setShow(true);
		setBranchNameSelected(name);
		setShiftNameSelectedId(id);
	};
	const handleDeleteBranch = (id) => {
		if (token !== null) {
			request
				.get(apiURL + DELETE_WORK_SHIFT_BY_ID + `/${id}`)
				.then((response) => {
					navigate(WORK_SHIFTS_LISTS_PAGE);
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Set the state for fields .
	 * @param {String} field - An field name of input.
	 * @returns {Number} index - An index where we are updating the time picker from the list.
	 * @returns {String} value - value of input field.
	 */

	const handleTimeChange = (field,index, value) =>{
		const inputFieldArray = [...inputFields];
		const tempFieldArray = {...inputFieldArray[index]};
		tempFieldArray[field] =   value;
		inputFieldArray[index] = tempFieldArray;
		setInputFields(inputFieldArray);
	}

	const filter = (data) => {
		if (Array.isArray(data)) {
			const temp = data.reduce((r, v) => {
				v = filter(v);
				if (v !== "") r.push(v);
				return r;
			}, []);
			return temp.length ? temp : "";
		}
		if (data && typeof data === "object") {
			const temp = Object.entries(data).reduce((r, [k, v]) => {
				v = filter(v);
				if (v !== "") r.push([k, v]);
				return r;
			}, []);
			return temp.length ? Object.fromEntries(temp) : "";
		}
		return data;
	}; // get only the properties with values unequal to ''.

	const fiterBranchSet = branches.filter(
		(itemInArray) => itemInArray.id === brancheName
	);

	const newArr = (data) =>
		data.map(
			({
				created_at,
				created_by,
				id,
				shift_id,
				updated_at,
				updated_by,

				...rest
			}) => {
				return rest;
			}
		);

	const handleSubmit = (event) => {
		if (token !== null) {
			// let dataNewRow = filter(newArr(inputFields));
			const request = client(token);
			//   event.preventDefault();
			bodyFormData.append("shift_name", name);
			bodyFormData.append("branch_id", brancheName);
			bodyFormData.append("description", description);
			bodyFormData.append("moreFields", JSON.stringify(filter(inputFields)));
			request
				.post(apiURL + WORK_SHIFTS_EDIT_BY_ID + `/${id}`, bodyFormData)
				.then((response) => {
					let errorMessage = "";
					setModalShow(true);
					if(response.data && response.data.success === false) {
						const errorResponse = Object.entries(response.data.data)
						errorMessage+= "Form validation error:\n\n\n";
						for (const [key, value] of errorResponse) {
							errorMessage+=`${capitalizeFirstLetter(key)}, `;
						}
						setAddResponse(errorMessage.replace(/, \s*$/, "") + (errorMessage.indexOf("Error occured") >=0 ? "" :" are required"));
						setStatus("Error");
					} else {
						setStatus("Success");
						setAddResponse(response.data.message);
					}
				})
				.catch((err) => console.log(err));
		}
	};
	useEffect(() => {
		if (token !== null) {
			request
				.get(apiURL + WORK_SHIFTS_VIEW_ID + `/${id}`)
				.then((response) => {
					setBranchNameSelected(response.data[0].shift.shift_name);
					setName(response.data[0].shift.shift_name);
					setDescription(response.data[0].shift.description);
					setBranchesName(response.data[0].shift.branch_id);
					if (response.data[0].shift.shift_detail.length > 0) {
						setRow(response.data[0].shift.shift_detail);
						setInputFields((response.data[0].shift.shift_detail));
					} else {
						setRow([
							{
								id: uuid(),
								shift_start_day: "Monday",
								shift_start_time: "08:00",
								shift_end_day: "Monday",
								shift_end_time: "13:00",
							},
						])
					}
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (brancheName !== 0 && selectLabel(fiterBranchSet)[0]) {
			setSubmitStatusMessage(
				selectLabel(fiterBranchSet)[0] !== undefined &&
					selectLabel(fiterBranchSet)[0].time_zone
			);
		}
	}, [brancheName, branches]);
   
	return (
		<>
			<div>
				<div className="rightbar pr-11">
					<div className="section-title">
						<h2>Edit/View Work Shift</h2>
					</div>
					<Row>
						<Col sm={9} lg={6}>
							<Form>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Name</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="text"
													value={name}
													autoFocus
													onChange={(e) => setName(e.target.value)}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Description </Form.Label>
											<Col sm={9}>
												<Form.Control
													as="textarea"
													//   placeholder={description}
													value={description}
													style={{ height: "120px" }}
													onChange={(e) => setDescription(e.target.value)}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Branch</Form.Label>
											<Col sm={9}>
												<Select
													onChange={(e) => {
														setBranchesName(e.value);
														setSubmitStatusMessage(e.time_zone);
														setModalShowTimeZone(true);
													}}
													options={selectLabel(branches)}
													maxMenuHeight="120px"
													value={selectLabel(fiterBranchSet)[0]}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Timezone</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="text"
													placeholder=""
													autoFocus
													value={submitStatusMessage}
													disabled
												/>
											</Col>
										</Row>
									</div>
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col sm={9} lg={9}>
							<Form>
								<Form.Group className="mb-4" controlId="formBasicEmail">
									<div
										className="section-title add-work--title"
										style={{ marginTop: "40px" }}
									>
										<Form.Label>Schedule</Form.Label>
									</div>
									{typeof typeof row !== "undefined" && row.length === 0  ?
										<Row className="align-items-center">
											<Col xs={2} className="add-work--content ">
												<Form.Label className="label-div ">
													Start Day
												</Form.Label>
												<Select
													onClick={(e) => {
														handleTimeChange("shift_start_day",0, e.label)
													}}
													options={selectLabel(startDate)}
													placeholder="Start Day"
													name="shift_start_day"
												/>
											</Col>
											<Col xs={2} className="add-work--content time" style={{paddingLeft:"-5px"}}>
												<Form.Label className="label-div ml-2">
													Start Time
												</Form.Label>
												<TimePicker 
													onChange={handleTimeChange} 
													value={inputFields[0].shift_start_time}
													id={0}
													inputName={"shift_start_time"}
												/>
											</Col>
											<Col xs={2} className="add-work--content ">
												<Form.Label className="label-div ml-2">
													End Day
												</Form.Label>
												<Select
													options={selectLabel(startDate)}
													onClick={(e) => {
														handleTimeChange("shift_end_day",0, e.label)
													}}
													placeholder="End Day"
													name="shift_end_day"
												/>
											</Col>
											<Col xs={2} className="add-work--content time">
												<Form.Label className="label-div ml-2">
													End Time
												</Form.Label>
												<TimePicker 
													onChange={handleTimeChange} 
													value={inputFields[0].shift_end_time}
													id={0}
													inputName={"shift_end_time"}
												/>
											</Col>
											<Col xs={2}>
												<div className="d-flex">
													<div
														className="sm-1 mt-4 mr-2"
														onClick={() => handleAddRow()}
														style={{
															marginTop: "27px"
														}}
													>
														<img src={PlusIcon} alt=""></img>
													</div>
													<div
														className="sm-1 mt-4"
														onClick={() =>
															row && row[0] !== undefined && row.length > 0 
																? removeItem(row[0].id)
																: null
														}
														style={{
															marginTop: "27px"
														}}
													>
														<img src={MinusIcon} alt=""></img>
													</div>
												</div>
											</Col>
										</Row>
									: 
										<Row className="align-items-center" >
											<Col xs={2} className="add-work--content ">
												<Form.Label className="label-div ">
													Start Day
												</Form.Label>
											</Col>
											<Col xs={2} className="add-work--content time" style={{marginLeft:"-5px"}}>
												<Form.Label className="label-div ml-2">
													Start Time
												</Form.Label>
											</Col>
											<Col xs={2} className="add-work--content " style={{marginLeft:"-1px"}}>
												<Form.Label className="label-div ml-2">
													End Day
												</Form.Label>
											</Col>
											<Col xs={2} className="add-work--content time">
												<Form.Label className="label-div ml-2">
													End Time
												</Form.Label>
											</Col>
											<Col xs={2}>
												
											</Col>
										</Row>				
									}
									
									{row.map((items, id) => {
										return (
											<Row className="align-items-center" key={id} style={{
												margin :"2px -14px 14px -14px"
											}}>
												<Col
													xs={2}
													className="add-work--content "
													key={items.id}
												>
													<Select
														onChange={(e) => {
															handleTimeChange("shift_start_day",id, e.label)
														}}
														options={selectLabel(startDate)}
														name="shift_start_day"
														placeholder="Start Day"
														defaultValue={{
															label: items.shift_start_day,
															value: 1,
														}}
														// defaultValue={{ label: "Monday", value: 1 }}
													/>
												</Col>
												<Col xs={2} className="add-work--content time">
													<TimePicker 
														onChange={handleTimeChange} 
														value={inputFields[id] && inputFields[id].shift_start_time ? inputFields[id].shift_start_time.replace(".",":").replace("am","").replace("pm",":") :""}
														id={id}
														inputName={"shift_start_time"}
													/>
												</Col>
												<Col xs={2} className="add-work--content">
													<Select
														onChange={(e) => {
															handleTimeChange("shift_end_day",id, e.label)
														}}
														options={selectLabel(startDate)}
														defaultValue={{
															label: items.shift_end_day,
															value: 1,
														}}
														name="shift_end_day"
														placeholder="End Day"
														// defaultValue={{ label: "Monday", value: 1 }}
													/>
												</Col>
												<Col xs={2} className="add-work--content time">
													<TimePicker 
														onChange={handleTimeChange} 
														value={inputFields[id] && inputFields[id].shift_end_time ? inputFields[id].shift_end_time.replace(".",":").replace("am","").replace("pm",":") :""}
														id={id}
														inputName={"shift_end_time"}
													/>
												</Col>
												<Col xs={2}>
													<div className="d-flex">
														<div
															className="sm-1 mr-2"
															onClick={() => handleAddRow()}
															style={{marginTop :"-2px"}}
														>
															<img src={PlusIcon} alt=""></img>
														</div>
														<div
															className="sm-1"
															onClick={() => {
																if(row.length > 1 ) {
																	removeItem(items.id,id);
																}
															}}
															style={{marginTop :"-2px"}}
														>
															<img src={MinusIcon} alt=""></img>
														</div>
													</div>
												</Col>
											</Row>
										);
									})}
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col sm={9} lg={6}>
							<Form>
								<Form.Group className="mb-3">
									<div className="d-sm-flex justify-content-between">
										<div className="">
											<Button
												variant=""
												className="btn-light-border grey"
												// type="submit"
												onClick={() => {
													handleSelectedDeleted(id, branchNameSeleted);
													setShow(true);
												}}
											>
												<img src={Delete} className="" img-fluid />
											</Button>
										</div>
										<div className="">
											<Button
												variant=""
												className="puple btns mr-3"
												type="submit"
												onClick={() => {
													setBranchesName(0);
													navigate(WORK_SHIFTS_LISTS_PAGE);
												}}
											>
												Cancel
											</Button>
											<Button
												variant="primary"
												className="btns"
												onClick={(e) => handleSubmit(e)}
											>
												Save
											</Button>
										</div>
									</div>
								</Form.Group>
							</Form>
						</Col>
					</Row>
				</div>
			</div>
			<MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
					if(status === "Success") {
						navigate(WORK_SHIFTS_LISTS_PAGE);
					}
				}}
				heading={status}
				message={addResponse}
				className="modal-body-body--success"
				modalHeight={"modalHeight"}
				errorMessage={status === "Error" ? status :""} 
			/>
			<DeleteModalConfirmation
				show={show}
				onHide={() => {
					setShow(false);
				}}
				branch={"work shift"}
				name={branchNameSeleted}
				title={"Delete Work Shift"}
				handleConfirm={() => handleDeleteBranch(shiftNameSeletedId)}
			/>
		</>
	);
};
export default AddWorkShift;
