import React, { useState, useEffect, useRef } from "react";

//Importing the bootstrap components
import { Button, Row, Col, Form, Tab, Tabs, Dropdown } from "react-bootstrap";
//Importing the react select components
import Select from "react-select";
import { SketchPicker } from "react-color";
import Marker from "./Marker";
import Loader from "./../../../../../components/Loader";
import Slider from "./Components/Slider";

//Importing the images

import PreviousIcon from "./../../../../../assets/Images/coordinator_actions_icon/prev-icons.svg";
// import EditImageOriginal from "./../../../../assets/Images/coordinator_actions_icon/Edit_Original.svg";
import NextIcon from "./../../../../../assets/Images/coordinator_actions_icon/next-icons.svg";

import ChatIcons from "./../../../../../assets/Images/coordinator_actions_icon/Chat.svg";
import BlueChatIcon from "./../../../../../assets/Images/coordinator_actions_icon/BlueChatIcon.svg";

import CancelIcons from "./../../../../../assets/Images/coordinator_actions_icon/cancel_image.svg";

import CrossIcon from "./../../../../../assets/Images/coordinator_actions_icon/cross.svg";
import CheckIcon from "./../../../../../assets/Images/coordinator_actions_icon/checkMark.svg";

import PencilIcons from "./../../../../../assets/Images/coordinator_actions_icon/Pencil.svg";
import ArrowIcons from "./../../../../../assets/Images/coordinator_actions_icon/Arrow.svg";
import ArrowDown from "./../../../../../assets/Images/coordinator_actions_icon/White_Arrow_Down.svg";
import Send from "./../../../../../assets/Images/coordinator_actions_icon/Send.svg";

// Importing API related files and routes

import { client } from "./../../../../../data/API/GobalClientAxiosRequest";
import { imageURL, apiURL } from "./../../../../../data/filepath";

import {
	CO_ORDINATOR_VIEW_EDIT_GROUP,
	CO_ORDINATOR_UPDATE_EDIT_GROUP,
	QC_BACKROUND_REMOVAL,
	QC_REVISIONS_BY_IMAGE_ID,
	QC_BULK_APPROVED,
	CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID,
} from "./../../../../../config/apiRoutes.api";

const Modal = ({
	showModal,
	setShowModal,
	image_details_view,
	setImageDetailsView,
	editGroups,
	editTypes,
	customer_id,
	setMultipleImagesId,
	selectImageName,
	token,
	id,
	idSelected,
	setClickSelect,
	customer_status,
	images,
	modalRef,
	setImageID,
	imageID,
	qcId,
	getEditorImages,
	setting_profile,
	handleFetchProfileSettings,
}) => {
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);

	const modalRefBackgroundRemoval = useRef(null);
	const canvasRef = useRef(null);
	const snapShotRef = useRef(null);

	//Declarations of state
	// Add a state variable to track whether drawing should start
	const [toggleColorBoxBackground, setToggleColorBoxBackground] =
		useState(false);

	const [showCommentList, setCommentsList] = useState(false);
	const [annotationsComments, setAnnotationsComments] = useState([]);
	const [duplicateImageId, setDuplicateImageId] = useState("");
	const [base64DataImg, setBase64DataImg] = useState("");
	const [isDragging, setIsDragging] = useState(false);
	const [startDrawing, setStartDrawing] = useState(false);
	const [rectangleDrawing, setRectangleDrawing] = useState(false);
	const [selectedColor, setSelectedColor] = useState("#ffffff"); // Initial color is white
	const [toggleColorBox, setColorBox] = useState(false);
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State
	const [groupSelect, setGroupSelect] = useState(false);
	const [checked, setChecked] = useState(false);
	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [checked3, setChecked3] = useState(false);
	const [selected, setSelected] = useState(false);
	const [selected1, setSelected1] = useState(false);
	const [selected2, setSelected2] = useState(false);
	const [selected3, setSelected3] = useState(false);
	const [edit_select, setEditorType] = useState("");
	const [edit_id, setEditId] = useState([]);
	const [currentAnnotation, setCurrentAnnotation] = useState(null);
	const [annotations, setAnnotations] = useState([]);
	const [edit_type_toggle, setToggleEditType] = useState(false);
	const [image_url, setImageUrl] = useState("");
	const [backgroundRemovalColorCode, setBackgroundRemoval] =
		useState("#ffffff");
	//To update the select group
	const [group_name_select, setEditGroupNameSelect] = useState("");
	// To set the state for add
	const [background_removal_color_type, setBackgroundRemovalColorType] =
		useState("");
	const [background_removal_color_code, setBackgroundRemovalColorCode] =
		useState("");
	const [background_adjustment_color_code, setBackgroundAdjustmentColorCode] =
		useState("");
	const [background_adjustment_color_type, setBackgroundAdjustmentColorType] =
		useState("");
	const [view_edit_group, setViewEditGroup] = useState({});
	const [group_name, setGroupName] = useState("");
	const [new_edit_group_name, setNewEditGroupName] = useState("");
	const [toggleMessage, setToggleMessage] = useState(false);
	// const [edit_select, setEditorType] = useState("");
	// const [edit_group_name_select, setEditGroupNameSelect] = useState("");
	const [resizing_file_format, setResizingFileFormat] = useState("");
	const [resizing_height, setResizingHeight] = useState("");
	const [resizing_width, setResizingWidth] = useState("");
	const [resizing_dpi, setResizingDpi] = useState("");
	const [resizing_dpi_type, setResizingDpiType] = useState("");
	const [resizing_color_mode, setResizingColorMode] = useState("");
	const [resizing_alignment, setResizingAlignment] = useState("");
	const [resizing_margin_format, setResizingMarginFormat] = useState("");
	const [resizing_margin_top, setResizingMarginTop] = useState("");
	const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
	const [resizing_margin_right, setResizingMarginRight] = useState("");
	const [resizing_margin_left, setResizingMarginLeft] = useState("");
	const [edit_group_name, setEditGroupName] = useState("");
	const [edit_type_ids, setEditTypeId] = useState("");
	const [image_file_format, setImageFileFormat] = useState("");
	const [image_height, setImageHeight] = useState("");
	const [image_width, setImageWidth] = useState("");
	const [image_dpi, setImageDpi] = useState("");
	const [image_dpi_type, setImageDpiType] = useState("");
	const [image_color_mode, setImageColorMode] = useState("");
	const [image_alignment, setImageAlignment] = useState("");
	const [image_margin_format, setImageMarginFormat] = useState("");
	const [image_margin_top, setImageMarginTop] = useState("");
	const [image_margin_bottom, setImageMarginBottom] = useState("");
	const [image_margin_right, setImageMarginRight] = useState("");
	const [image_margin_left, setImageMarginLeft] = useState("");
	const [group, setGroup] = useState([]);
	const [message, setMessage] = useState("");
	const [comments, setComments] = useState([]);
	const [imageName, setImageName] = useState("");
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [imageExtensions, setImageExtensions] = useState("");
	const [renameId, setRenameId] = useState("");
	const [hoveredItemId, setHoveredItemId] = useState(null);
	const [viewDuplicateSettingsById, setViewDuplicateSettings] = useState({});
	const [viewDuplicateModal, setViewDuplicateModal] = useState(false);
	const [fileFormatDuplicate, setFileFormatDuplicate] = useState("");
	const [dpiTypeDuplicate, setDpiTypeDuplicate] = useState("");
	const [colorModeDuplicate, setColorModeDuplicate] = useState("");
	const [alignModeDuplicate, setAlignModeDuplicate] = useState("");
	const [marginFormatDuplicate, setMarginFormatDuplicate] = useState("");
	const [heightDuplicate, setHeightDuplicate] = useState("");
	const [widthDuplicate, setWidthDuplicate] = useState("");
	const [dpiDuplicate, setDpiDuplicate] = useState("");
	const [marginTopDuplicate, setMarginTopDuplicate] = useState("");
	const [marginBottomDuplicate, setMarginBottomDuplicate] = useState("");
	const [marginRightDuplicate, setMarginRightDuplicate] = useState("");
	const [marginLeftDuplicate, setMarginLeftDuplicate] = useState("");

	const svgCode = (count) => {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white"/>
		<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10ZM9 4.25V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H4.25L9 4.25Z" fill="white"/>
		<!-- Use a <style> element to set the font size -->
			<style>
			  text {
				font-size: 9px;
				font-weight:500;
			  }
			</style>
			<!-- Adjust the y attribute to add margin on the top -->
			<text x="6" y="18" fill="green">${count}</text>
	  </svg>`;
	};

	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];
	const handleBackGroundRemovalWhite = () => {
		setBackgroundRemoval("#FFFFFF");
	};
	const handleBackGroundRemovalBlack = () => {
		setBackgroundRemoval("#000000");
	};
	const handleBackGroundRemovalGreen = () => {
		setBackgroundRemoval("#7cfc00");
	};

	const handleTextChange = (updatedText) => {
		if (currentAnnotation) {
			setCurrentAnnotation({
				...currentAnnotation,
				text: updatedText,
			});

			// Create a copy of annotations with the updated text
			const updatedAnnotations = annotations.map((annotation) =>
				annotation === currentAnnotation
					? { ...annotation, text: updatedText }
					: annotation
			);

			setAnnotations(updatedAnnotations);
		}
	};

	useEffect(() => {
		if (editGroups !== undefined) {
			// Create a new array by combining existing group values and the new object
			const updatedGroup = [
				...editGroups,
				{ id: "", group_name: "New Settings" },
			];
			setGroup(updatedGroup);
			setSelected(false);
			setSelected1(false);
			setSelected3(false);
		}
	}, [editGroups]);

	useEffect(() => {
		// Fetch data from the API endpoint
		if (edit_select !== 0) {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_EDIT_GROUP + `/${edit_select}`)
				.then((response) => {
					//Set View  for edit group from the response
					let data = response.data;
					console.log("call the edit type", data);
					setViewEditGroup(data);
					setBackgroundRemovalColorCode(
						data.edit_group.background_settings[0].color_code
					);
					setBackgroundAdjustmentColorCode(
						data.edit_group.background_settings[1].color_code
					);
					setBackgroundRemovalColorType(
						data.edit_group.background_settings[0] !== undefined
							? data.edit_group.background_settings[0].color_type
							: "Select Color Type"
					);
					setBackgroundAdjustmentColorType(
						data.edit_group.background_settings[1] !== undefined
							? data.edit_group.background_settings[1].color_type
							: "Select color type"
					);
					setResizingFileFormat(
						data.edit_group.resizing_settings[0].file_format !== undefined
							? data.edit_group.resizing_settings[0].file_format
							: "Select File Format"
					);
					setResizingDpiType(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].dpi_type
							: "Select File Format"
					);
					setResizingColorMode(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].color_mode
							: "Select File Format"
					);
					setResizingAlignment(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].alignment
							: "Select File Format"
					);
					setResizingMarginFormat(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].margin_format
							: "Select File Format"
					);
					setImageFileFormat(
						data.edit_group.setting.file_format !== undefined
							? data.edit_group.setting.file_format
							: "Select file format"
					);
					setImageDpiType(
						data.edit_group.setting.dpi_type !== null
							? data.edit_group.setting.dpi_type
							: "Select Dpi type"
					);
					setImageColorMode(
						data.edit_group.setting !== null
							? data.edit_group.setting.color_mode
							: "Select color mode"
					);
					setImageAlignment(
						data.edit_group.setting !== null
							? data.edit_group.setting.alignment
							: "Select Align"
					);
					setImageMarginFormat(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_format
							: "Margin"
					);
					if (data.edit_group.background_settings.length) {
						setSelected(true);
						setSelected1(true);
					}
					if (data.edit_group.resizing_settings.length) {
						setSelected2(true);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		} else {
			setViewEditGroup({});
			setBackgroundRemovalColorCode("");
			setBackgroundAdjustmentColorCode("");
			setBackgroundRemovalColorType("");
			setBackgroundAdjustmentColorType("");
			setResizingFileFormat("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setImageFileFormat("");
			setImageDpiType("");
			// setImageColorMode(
			// 	data.edit_group.setting !== null
			// 		? data.edit_group.setting.color_mode
			// 		: "Select color mode"
			// );
			// setImageAlignment(
			// 	[]
			// );
			// setImageMarginFormat(
			// 	[]
			// );
		}
	}, [edit_select]);
	console.log("edit select useEffect", image_details_view);
	useEffect(() => {
		if (
			image_details_view !== undefined &&
			image_details_view.status !== undefined &&
			// image_details_view.status === "assign_to_editor" &&
			image_details_view.edit_group_id !== null
			// images !== undefined
		) {
			setEditorType(image_details_view.edit_group_id);
		} else {
			setViewEditGroup({});
			setBackgroundRemovalColorCode("");
			setBackgroundAdjustmentColorCode("");
			setBackgroundRemovalColorType("");
			setBackgroundAdjustmentColorType("");
			setResizingFileFormat("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setImageFileFormat("");
			setImageDpiType("");
			// setImageColorMode(
			// 	data.edit_group.setting !== null
			// 		? data.edit_group.setting.color_mode
			// 		: "Select color mode"
			// );
			// setImageAlignment(
			// 	[]
			// );
			// setImageMarginFormat(
			// 	[]
			// );
		}
	}, [image_details_view, setEditorType, setViewEditGroup]);

	// Listen for the "keydown" event
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.keyCode === 27) {
				// Escape key is pressed
				// Update state variables here
				localStorage.setItem("clickSelect", false);
				document.body.classList.remove("modal-open");
				setShowModal(false);

				setDropdownOpen(false);
				// setImageDetailsView({});
			}
		};

		// Add event listener when the component mounts
		window.addEventListener("keydown", handleKeyDown);

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []); // Empty dependency array means this effect runs once, like componentDidMount
	// Function to close the modal
	const closeModal = () => {
		// Update state variables here
		localStorage.setItem("clickSelect", false);
		document.body.classList.remove("modal-open");
		setShowModal(false);
		setDropdownOpen(false);
	};

	const handleOverlayClick = (e) => {
		if (e.target.classList.contains("modal-container--image")) {
			closeModal();
		} else if (e.target.classList.contains("close-icons-order--image")) {
			closeModal();
		}
	};
	const handleColorChange = (color) => {
		setSelectedColor(color.hex);
		setIsDragging(true);
	};
	const handleColorChangeBackgroundRemoval = (color) => {
		setBackgroundRemoval(color.hex);
	};

	const handleOutsideClick = (e) => {
		if (e.target.classList.contains("modal-bg---image")) {
			setToggleColorBoxBackground(false);
		}
	};
	const handleOutsideClickBox = (e) => {
		if (e.target.classList.contains("modal-bg---image")) {
			setColorBox(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);
	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClickBox);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClickBox);
		};
	}, []);

	//handled next item
	function getNextPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: 1;

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the beginning.
			startIndex = 0;
		} else {
			startIndex += 1;
		}

		const endIndex = startIndex + itemsPerPage;
		const nextPage = data.slice(startIndex, endIndex);

		// Determine the next ID or wrap around to the first ID if necessary.
		let nextPageId = null;

		if (nextPage.length > 0) {
			nextPageId =
				nextPage[
					data.findIndex((item) => item.id === currentId) !== 0
						? data.findIndex((item) => item.id === currentId) - 1
						: 0
				].id;
		} else if (data.length > 0) {
			nextPageId = data[0].id;
		}

		return { nextPage, nextPageId };
	}

	function getPreviousPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: 1;
		// setCommentsList(false);

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the last item.
			startIndex = data.length - itemsPerPage;
		} else {
			startIndex = Math.max(0, startIndex - itemsPerPage);
		}

		const endIndex = startIndex + itemsPerPage;
		const previousPage = data.slice(startIndex, endIndex);

		// Determine the previous ID or wrap around to the last ID if necessary.
		let previousPageId = null;

		if (previousPage.length > 0) {
			previousPageId =
				previousPage[
					data.findIndex((item) => item.id === currentId) !== 0
						? data.findIndex((item) => item.id === currentId) - 1
						: 0
				].id;
		} else if (data.length > 0) {
			previousPageId = data[0].id;
		}

		return { previousPage, previousPageId };
	}

	function showNextPage() {
		const { nextPage, nextPageId } = getNextPage(
			images,
			imageID,
			images.length
		);

		if (nextPage.length > 0) {
			console.log("Next Page:", nextPage);
			console.log("Next Page ID:", nextPageId);

			setImageID(nextPageId);
			const filterImages = images.find((obj) => obj.id === nextPageId);
			console.log("Filtered Image:", filterImages);

			setImageDetailsView(filterImages);
		} else {
			console.log("No more data to display.");
		}
	}

	function showPreviousPage() {
		const { previousPage, previousPageId } = getPreviousPage(
			images,
			imageID,
			images.length
		);

		if (previousPage.length > 0) {
			console.log("Previous Page:", previousPage);
			console.log("Previous Page ID:", previousPageId);

			setImageID(previousPageId);
			const filterImages = images.find((obj) => obj.id === previousPageId);
			console.log("Filtered Image:", filterImages);

			setImageDetailsView(filterImages);
		} else {
			console.log("No more data to display.");
		}
	}
	const saveCanvasAsBase64 = () => {
		const canvas = canvasRef.current;
		const dataURL = canvas.toDataURL("image/png");

		// Extract the base64 part from the dataURL
		const base64String = dataURL.split(",")[1];

		// Create a data URI with base64 content
		const base64DataURI = base64String;

		// set the base64DataURI to the local state
		return base64DataURI;
	};

	const handleMouseEnter = (itemId) => {
		// Set the ID of the item to be hovered
		setHoveredItemId(itemId);
	};

	const handleMouseLeave = () => {
		// Clear the hovered item when leaving the element
		setHoveredItemId(null);
	};

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
		setViewDuplicateModal(false);
	};
	const onClickDuplicateImage = (id, imageName, imageExtensions) => {
		setRenameId(id);
		setImageName(imageName);
		setImageExtensions(imageExtensions);
		// setRenameModalShow((val) => !val);
	};
	useEffect(() => {
		if (token && duplicateImageId) {
			request
				.get(
					apiURL +
						CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID +
						`/${duplicateImageId}`
				)
				.then((response) => {
					//Set View  for edit group from the response
					console.log("setting", response);
					setViewDuplicateSettings(response.data.setting);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	}, [duplicateImageId, setViewDuplicateSettings]);

	const customStyles = {
		placeholder: (provided) => ({
			// Custom styles for the placeholder text
			...provided,
			color: "#212529", // Change the placeholder text color to red
		}),
	};
	function getSelectComponent(options, setState, file) {
		return (
			<Select
				options={file}
				placeholder={options}
				styles={customStyles}
				onChange={(e) => setState(e.label)}
				isDisabled={true}
			/>
		);
	}
	console.log("image_details_view", image_details_view);
	if (image_details_view.length === null) return <Loader />;
	else {
		return (
			<div>
				{showModal && (
					<div className="modal-overlay--order">
						<div
							className="close-icons-order--image"
							onClick={handleOverlayClick}
						>
							<div style={{ marginBottom: "10px" }}>
								<div
									className={`dropdown-duplicate-image--modal  ${
										isDropdownOpen ? "open" : ""
									}`}
								>
									<div
										className="dropdown-button--duplicate"
										onClick={toggleDropdown}
									>
										<span>
											<div
												dangerouslySetInnerHTML={{
													__html: svgCode(
														setting_profile !== null &&
															setting_profile !== undefined &&
															setting_profile.duplication_settings !==
																undefined &&
															setting_profile.duplication_settings !== null &&
															setting_profile.duplication_settings.length !==
																undefined
															? parseInt(
																	setting_profile.duplication_settings.length
																		.toString()
																		.substr(0, 2)
															  )
															: "0"
													),
												}}
												style={{ marginLeft: "8px" }}
											/>
										</span>
										<span className="order-text--outlined">
											{setting_profile.batch_number}
										</span>
										<span
											className="order-text--outlined"
											style={{ marginLeft: "42px" }}
										>
											{setting_profile.path}
										</span>
										<span style={{ marginLeft: "auto" }}>
											<div
												style={{ marginRight: "18px" }}
												onClick={() =>
													onClickDuplicateImage(
														setting_profile.id,
														setting_profile.path.substring(
															0,
															setting_profile.path.lastIndexOf(".")
														)
													)
												}
											></div>
										</span>
										<span className="mr-25px">
											<img src={ArrowDown} alt="Arrow Down" />{" "}
										</span>
									</div>
									{isDropdownOpen && (
										<div className="dropdown-content-duplicate-image--modal">
											{setting_profile !== undefined &&
												setting_profile.duplication_settings !== undefined &&
												setting_profile.duplication_settings.map(
													(_items, index) => {
														return (
															<span
																key={_items.id}
																onMouseEnter={() => handleMouseEnter(_items.id)}
																onMouseLeave={handleMouseLeave}
																onClick={() => {
																	setDuplicateImageId(_items.id);
																	setViewDuplicateModal(true);
																	setDropdownOpen(false);
																}}
															>
																<h2
																	className="dropdown-duplicate-image--text"
																	onClick={() =>
																		onClickDuplicateImage(
																			_items.id,
																			_items.image_name.substring(
																				0,
																				_items.image_name.lastIndexOf(".")
																			)
																		)
																	}
																>
																	{_items.image_name}
																</h2>
																<div className="d-flex"></div>
															</span>
														);
													}
												)}
										</div>
									)}
								</div>
							</div>

							<div className="dot-gap--qc">
								<span
									className="dot"
									onClick={() => handleBackGroundRemovalWhite()}
								></span>
								<span
									className="dot_1"
									onClick={() => handleBackGroundRemovalBlack()}
								></span>
								<span
									className="dot_2"
									onClick={() => handleBackGroundRemovalGreen()}
								></span>
								<span
									className="dot_3"
									onClick={() => setToggleColorBoxBackground((val) => !val)}
								></span>
								{toggleColorBoxBackground && (
									<div
										className="d-flex position--absolute background"
										style={{ marginTop: "3em" }}
									>
										<h2 style={{ fontSize: "18px", fontWeight: "600" }}>
											Selected Color: {backgroundRemovalColorCode}
										</h2>
										<div className="color-picker">
											<SketchPicker
												color={backgroundRemovalColorCode}
												onChange={handleColorChangeBackgroundRemoval}
											/>
										</div>
									</div>
								)}
							</div>
							<>
								<div style={{ marginLeft: "5.8rem" }}>
									<Button
										variant="outline-secondary"
										className="btn-image-order-outlined--qc"
										disabled={
											image_details_view.status === "reassigned" ? false : true
										}
									>
										<span className="reject-cross--btn">
											<img
												src={CrossIcon}
												className="img-fluid"
												alt="mindreach images"
												style={{ marginRight: "-4px", height: "20px" }}
											/>
											<span style={{ marginTop: "-4px" }}>Revision</span>
										</span>
									</Button>
								</div>
								<div style={{ marginLeft: "1rem" }}>
									<Button
										variant="outline-secondary"
										className="btn-image-order-outlined--qc"
										disabled={
											image_details_view.status === "completed" ? false : true
										}
									>
										{/* Approve */}
										<span className="accept-tick--btn">
											<img
												src={CheckIcon}
												className="img-fluid"
												alt="mindreach images"
											/>
											<span style={{ marginTop: "0px" }}>Approve</span>
										</span>
									</Button>
								</div>
								<div
									className="modal-tab"
									onClick={() => {
										localStorage.setItem("clickSelect", false);
										document.body.classList.remove("modal-open");
										setShowModal(false);
										setDropdownOpen(false);
									}}
								>
									<img
										src={CancelIcons}
										className="img-fluid"
										alt="mindreach images"
									/>
								</div>
							</>
						</div>
						<div
							className="modal-container--image"
							onClick={handleOverlayClick}
						>
							<div
								className="modal-image-icons"
								onClick={() => {
									setToggleMessage((val) => !val);
									setCommentsList((val) => !val);
									if (!showCommentList) {
										setAnnotationsComments([]);
									}
								}}
								style={{ background: showCommentList ? "#f5f5f5" : "#fff" }}
							>
								<span
									onClick={() => {
										setRectangleDrawing((val) => !val);
										setStartDrawing(false);
										setColorBox(false);
									}}
								>
									<img
										src={showCommentList ? BlueChatIcon : ChatIcons}
										className="img-fluid"
										alt="mindreach images"
									/>
								</span>
							</div>

							<div className="modal--image">
								{toggleColorBox && (
									<div className="d-flex position--absolute">
										<h2 style={{ fontSize: "18px", fontWeight: "600" }}>
											Selected Color: {selectedColor}
										</h2>
										<div className="color-picker">
											<SketchPicker
												color={selectedColor}
												onChange={handleColorChange}
											/>
										</div>
									</div>
								)}
								<div className="prev--icons" onClick={() => showPreviousPage()}>
									<img
										src={PreviousIcon}
										className="img-fluid"
										alt="mindreach images"
									/>
								</div>
								<div className="modal-container">
									<Marker
										imageURL={`${imageURL}/${image_details_view.customer.email}/${image_details_view.batch_number}/${image_details_view.order.order_number}/images/${image_details_view.image_path}`}
										currentAnnotation={currentAnnotation}
										setCurrentAnnotation={setCurrentAnnotation}
										handleTextChange={handleTextChange}
										annotations={annotations}
										setAnnotations={setAnnotations}
										setToggleMessage={setToggleMessage}
										toggleMessage={toggleMessage}
										idSelected={image_details_view.id}
										setStartDrawing={setStartDrawing}
										startDrawing={startDrawing}
										rectangleDrawing={rectangleDrawing}
										selectedColor={selectedColor}
										image_url={image_url}
										setImageUrl={setImageUrl}
										backgroundRemovalColorCode={backgroundRemovalColorCode}
										setBackgroundRemoval={setBackgroundRemoval}
										canvasRef={canvasRef}
										snapShotRef={snapShotRef}
										isDragging={isDragging}
										setIsDragging={setIsDragging}
										showCommentList={showCommentList}
										setCommentsList={setCommentsList}
										annotationsComments={annotationsComments}
										setAnnotationsComments={setAnnotationsComments}
										token={token}
									/>
								</div>
								<div className="next--icons" onClick={() => showNextPage()}>
									<img
										src={NextIcon}
										className="img-fluid"
										alt="mindreach images"
									/>
								</div>
							</div>
							<div className="modal-image--order">
								<div className="image-modal--details-text">
									<div className="sidebar-image-name--image-modal  d-flex">
										<div style={{ width: "100%", marginLeft: "23px" }}>
											<div className="d-flex">
												<h2
													className="side-bar-single-select--h2"
													style={{ marginLeft: "83px" }}
												>
													{selectImageName}
												</h2>
												<div
													style={{ display: "flex", marginLeft: "auto" }}
													onClick={() => {
														setMultipleImagesId([]);
														setClickSelect(false);
													}}
												>
													<img
														src={CancelIcons}
														className="img-fluid"
														alt="mindreach images"
													/>
												</div>
											</div>
											<div className="form-group edit-profile-setting-container--single">
												<Row
													className="align-items-center edit-setting--select "
													style={{ position: "absolute" }}
												>
													<Form.Label
														className="col-sm-3 "
														style={{ fontSize: "20px" }}
													>
														Details
													</Form.Label>
												</Row>
											</div>
											<div>
												<Slider
													imgaeURLOriginal={`${imageURL}/${image_details_view.customer.email}/${image_details_view.batch_number}/${image_details_view.order.order_number}/images/${image_details_view.image_path}`}
												/>
											</div>
											<div className="scroll--edit-group">
												<div className="sub-head" style={{ marginTop: "30px" }}>
													Edit Type
												</div>
												<div>
													{view_edit_group !== undefined &&
														view_edit_group.edit_group !== undefined &&
														view_edit_group.edit_group.edit_types !==
															undefined &&
														view_edit_group.edit_group.edit_types.map(
															(item) => {
																return (
																	<h3 className="edit-type--text">
																		{item.edit_type}
																	</h3>
																);
															}
														)}
												</div>
												<>
													{viewDuplicateModal ? (
														<div
															style={{
																// marginLeft: "5%",
																height: "29.5rem",
																overflow: "scroll",
																width: "100%",
															}}
														>
															{viewDuplicateSettingsById !== undefined && (
																<>
																	<div className="sub-head">
																		Output Specifications
																	</div>
																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Form.Label className="col-sm-3">
																					File Format
																				</Form.Label>
																				<Col sm={6}>
																					{getSelectComponent(
																						viewDuplicateSettingsById.file_format,
																						setFileFormatDuplicate,
																						file
																					)}
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>

																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Form.Label className="col-sm-3">
																					Size
																				</Form.Label>
																				<Col sm={9}>
																					<div className="d-sm-flex align-items-center col-gap-10">
																						<div className="d-sm-flex col-gap-10 align-items-center">
																							<span className="text-secondary f-sz-13">
																								{" "}
																								H:{" "}
																							</span>
																							<div className="flex-col">
																								<Form.Control
																									type="text"
																									placeholder=""
																									autoFocus
																									onChange={(e) =>
																										setHeightDuplicate(
																											e.target.value
																										)
																									}
																									autoComplete="off"
																									defaultValue={
																										viewDuplicateSettingsById.height
																									}
																									disabled={true}
																								/>
																							</div>
																						</div>
																						<div className="d-sm-flex align-items-center"></div>
																						<div className="d-sm-flex align-items-center col-gap-10">
																							<span className="text-secondary f-sz-13">
																								{" "}
																								W:{" "}
																							</span>
																							<div className="flex-col">
																								<Form.Control
																									type="text"
																									placeholder="2000"
																									autoFocus
																									onChange={(e) =>
																										setWidthDuplicate(
																											e.target.value
																										)
																									}
																									autoComplete="off"
																									defaultValue={
																										viewDuplicateSettingsById.width
																									}
																									disabled={true}
																								/>
																							</div>
																						</div>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>

																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Form.Label className="col-sm-3">
																					DPI
																				</Form.Label>
																				<Col sm={9}>
																					<div className="d-sm-flex col-gap-20">
																						<div className="">
																							<Form.Control
																								type="text"
																								placeholder="2000"
																								autoFocus
																								onChange={(e) =>
																									setDpiDuplicate(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																								defaultValue={
																									viewDuplicateSettingsById.dpi
																								}
																								disabled={true}
																							/>
																						</div>
																						<div className="flex-fill">
																							{getSelectComponent(
																								viewDuplicateSettingsById.dpi_type,
																								setDpiTypeDuplicate,
																								dpi
																							)}
																						</div>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>

																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Form.Label className="col-sm-3">
																					Color Mode
																				</Form.Label>
																				<Col sm={9}>
																					{getSelectComponent(
																						viewDuplicateSettingsById.color_mode,
																						setColorModeDuplicate,
																						ColorMode
																					)}
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>

																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Form.Label className="col-sm-3">
																					Align
																				</Form.Label>
																				<Col sm={9}>
																					{getSelectComponent(
																						viewDuplicateSettingsById.alignment,
																						setAlignModeDuplicate,
																						Align
																					)}
																				</Col>
																			</Row>
																		</div>
																	</Form.Group>

																	<Form.Group
																		style={{ margin: "2px" }}
																		controlId="exampleForm.ControlTextarea1"
																	>
																		<div className="form-group">
																			<Row className="align-items-center">
																				<Form.Label className="col-sm-3">
																					Margins
																				</Form.Label>
																				<Col
																					sm={9}
																					style={{
																						display: "contents",
																					}}
																				>
																					<div className="d-sm-flex col-gap-20 flex-col">
																						<div className="">
																							{getSelectComponent(
																								viewDuplicateSettingsById.margin_format,
																								setMarginFormatDuplicate,
																								Margin
																							)}
																						</div>
																						<div className="">
																							<ul className="margin-list">
																								<li>
																									<Form.Control
																										type="text"
																										placeholder="T"
																										autoFocus
																										onChange={(e) =>
																											setMarginTopDuplicate(
																												e.target.value
																											)
																										}
																										autoComplete="off"
																										defaultValue={
																											viewDuplicateSettingsById.margin_top
																										}
																										disabled={true}
																									/>
																								</li>
																								<li>
																									<Form.Control
																										type="text"
																										placeholder="B"
																										autoFocus
																										onChange={(e) =>
																											setMarginBottomDuplicate(
																												e.target.value
																											)
																										}
																										autoComplete="off"
																										defaultValue={
																											viewDuplicateSettingsById.margin_bottom
																										}
																										disabled={true}
																									/>
																								</li>
																								<li>
																									<Form.Control
																										type="text"
																										placeholder="R"
																										autoFocus
																										onChange={(e) =>
																											setMarginRightDuplicate(
																												e.target.value
																											)
																										}
																										autoComplete="off"
																										defaultValue={
																											viewDuplicateSettingsById.margin_right
																										}
																										disabled={true}
																									/>
																								</li>
																								<li>
																									<Form.Control
																										type="text"
																										placeholder="L"
																										autoFocus
																										onChange={(e) =>
																											setMarginLeftDuplicate(
																												e.target.value
																											)
																										}
																										autoComplete="off"
																										defaultValue={
																											viewDuplicateSettingsById.margin_left
																										}
																										disabled={true}
																									/>
																								</li>
																							</ul>
																						</div>
																					</div>
																				</Col>

																				<Col sm={6}></Col>
																			</Row>
																		</div>
																	</Form.Group>
																</>
															)}
														</div>
													) : (
														<div>
															{view_edit_group !== undefined &&
																view_edit_group.edit_group !== undefined &&
																view_edit_group.edit_group.resizing_settings !==
																	undefined &&
																view_edit_group.edit_group.resizing_settings.map(
																	(items, index) => {
																		return (
																			<>
																				<div className="sub-head">
																					Output Specifications
																				</div>
																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Form.Label className="col-sm-3">
																								File Format
																							</Form.Label>
																							<Col sm={6}>
																								<Form.Label className="col-sm-3">
																									{items.file_format}
																								</Form.Label>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>

																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Form.Label className="col-sm-3">
																								Size
																							</Form.Label>
																							<Col sm={9}>
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<div className="d-sm-flex col-gap-10 align-items-center">
																										<span className="text-secondary f-sz-13">
																											{" "}
																											H:{" "}
																										</span>
																										<span className="text-secondary f-sz-13">
																											{items.height}
																										</span>
																									</div>
																									<div className="d-sm-flex align-items-center"></div>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<span className="text-secondary f-sz-13">
																											{" "}
																											W:{" "}
																										</span>
																										<span className="text-secondary f-sz-13">
																											{items.width}
																										</span>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>

																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Form.Label className="col-sm-3">
																								DPI
																							</Form.Label>
																							<Col sm={9}>
																								<div className="d-sm-flex col-gap-20">
																									<div className="">
																										<Form.Label className="col-sm-3">
																											{items.dpi}
																										</Form.Label>
																									</div>
																									<div className="flex-fill">
																										<Form.Label className="col-sm-3">
																											{items.dpi_type}
																										</Form.Label>
																									</div>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>

																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Form.Label className="col-sm-3">
																								Color Mode
																							</Form.Label>
																							<Col sm={9}>
																								<Form.Label className="col-sm-3">
																									{items.color_mode}
																								</Form.Label>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>

																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Form.Label className="col-sm-3">
																								Align
																							</Form.Label>
																							<Col sm={9}>
																								<Form.Label className="col-sm-3">
																									{items.alignment}
																								</Form.Label>
																							</Col>
																						</Row>
																					</div>
																				</Form.Group>

																				<Form.Group
																					style={{ margin: "2px" }}
																					controlId="exampleForm.ControlTextarea1"
																				>
																					<div className="form-group">
																						<Row className="align-items-center">
																							<Form.Label className="col-sm-3">
																								Margins
																							</Form.Label>
																							<Col sm={9}>
																								<div className="d-sm-flex col-gap-20">
																									<div className="image-details-margin--type ">
																										<span className="text-secondary f-sz-13">
																											{items.margin_format}
																										</span>
																									</div>
																									<div className="">
																										<ul className="margin-list">
																											<li>
																												<Form.Label className="col-sm-3">
																													{items.margin_top}
																												</Form.Label>
																											</li>
																											<li>
																												<Form.Label className="col-sm-3">
																													{items.margin_bottom}
																												</Form.Label>
																											</li>
																											<li>
																												<Form.Label className="col-sm-3">
																													{items.margin_right}
																												</Form.Label>
																											</li>
																											<li>
																												<Form.Label className="col-sm-3">
																													{items.margin_left}
																												</Form.Label>
																											</li>
																										</ul>
																									</div>
																								</div>
																							</Col>

																							<Col sm={6}></Col>
																						</Row>
																					</div>
																				</Form.Group>
																			</>
																		);
																	}
																)}
														</div>
													)}
												</>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
};

export default Modal;
