import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

const Autocomplete = (props) => {
	const {
		suggestions,
		setSearch,
		setImageDetailsView,
		setImageDetailsViewId,
		setImageDetailsModal,
		isShow,
		setIsShow,
	} = props;
	const [active, setActive] = useState(-1);
	const [filtered, setFiltered] = useState([]);
	const [input, setInput] = useState("");
	const overlayRef = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (overlayRef.current && !overlayRef.current.contains(e.target)) {
				setIsShow(false); // Close autocomplete when clicking outside
			}
		};

		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, []);

	const onChange = (e) => {
		const input = e.currentTarget.value;
		setInput(input);
		function filterSuggestions(suggestions, input) {
			const filteredSuggestions =
				suggestions !== null &&
				suggestions.filter(
					(suggestion) =>
						suggestion !== null &&
						suggestion.toLowerCase().includes(input.toLowerCase())
				);
			return filteredSuggestions;
		}

		const newFilteredSuggestions = filterSuggestions(suggestions, input);

		setActive(-1);
		setFiltered(newFilteredSuggestions);
		setIsShow(true);
	};

	const onClick = (e, suggestion) => {
		e.preventDefault();

		setActive(-1);
		setInput(suggestion);
		setSearch(suggestion);
		setIsShow(false);
	};
	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (active >= 0 && active < filtered.length) {
				setInput(filtered[active].batch_number);
				setSearch(filtered[active].batch_number);
			} else {
				setInput(input);
				setSearch(input);
			}
			setActive(-1);
			setIsShow(false);
		} else if (e.key === "ArrowUp") {
			// up arrow
			e.preventDefault();
			setActive((prev) => (prev > 0 ? prev - 1 : filtered.length - 1));
		} else if (e.key === "ArrowDown") {
			// down arrow
			e.preventDefault();
			setActive((prev) => (prev < filtered.length - 1 ? prev + 1 : 0));
		}
	};

	const onClear = () => {
		setInput("");
		setSearch("");
		setFiltered([]);
		setIsShow(false);
	};

	const RenderAutocomplete = () => {
		// Ensure that isShow, input, filtered, overlayRef, active, onClick, and setIsShow are defined

		if (isShow && input !== "") {
			if (filtered.length && filtered !== undefined) {
				return (
					<ul className="autocomplete" ref={overlayRef}>
						{filtered.map((suggestion, index) => {
							return (
								<li
									onClick={(e) => {
										onClick(e, suggestion);
										setIsShow(false);
									}}
								>
									{suggestion}
								</li>
							);
						})}
					</ul>
				);
			} else {
				return (
					<div className="no-autocomplete">
						<em>No recent items match for search</em>
					</div>
				);
			}
		}
		return null;
	};

	return (
		<>
			<div className="search--box ">
				<Form.Control
					type="text"
					placeholder="Search By Batch Number"
					className="me-2 search-icon form-control date_filter"
					aria-label="Search"
					onChange={onChange}
					onKeyDown={(e) => onKeyDown(e, suggestions)}
					value={input}
					autoComplete="off"
					style={{ height: "42.3px" }}
				/>
				{input && (
					<span className="clear-icons" onClick={onClear}>
						&times;
					</span>
				)}
			</div>
			{RenderAutocomplete()}
		</>
	);
};

export default Autocomplete;
