import React, { useEffect, useState } from "react";

import { client } from "./../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
const events = [
	"load",
	"mousemove",
	"mousedown",
	"click",
	"scroll",
	"keypress",
];

const AppLogout = ({ children }) => {
	let timer;
	let logoutTime; // Variable to capture the logout time

	const token = sessionStorage.getItem("token");
	let tokenString = token ? token !== null : "";
	let tokenSubString = tokenString;

	const tokenDestroy = sessionStorage.getItem("token");
	const roles = sessionStorage.getItem("role");
	let data = JSON.parse(roles);

	const handleClick = () => {
		if (tokenDestroy !== null) {
			const request = client(tokenDestroy);
			request
				.post(apiURL + "/logout")
				.then((response) => {
					console.log(response);
					// alert(response.data.message);
					sessionStorage.clear();
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	// This function sets the timer that logs out the user after 5 minutes
	const handleLogoutTimer = () => {
		timer = setTimeout(() => {
			// Capturing the logout time
			logoutTime = new Date();
			// Clears any pending timer.
			resetTimer();
			// Listener clean up. Removes the existing event listener from the window
			Object.values(events).forEach((item) => {
				window.removeEventListener(item, resetTimer);
			});
			sessionStorage.setItem("auto-logout", true);
			// Logs out user
			logoutAction();
		}, 3600000); // 3,600,000ms = 1 hour
	};

	// This resets the timer if it exists.
	const resetTimer = () => {
		if (timer) clearTimeout(timer);
	};

	// When the component mounts, it adds event listeners to the window.
	// Each time any of the events is triggered (e.g., on mouse move, click, scroll, keypress, etc.), the timer to logout the user after 10 seconds of inactivity resets.
	// However, if none of the events is triggered within 10 seconds, the app is inactive, and the app automatically logs out.
	useEffect(() => {
		Object.values(events).forEach((item) => {
			window.addEventListener(item, () => {
				resetTimer();
				handleLogoutTimer();
			});
		});
	}, []);

	// Logs out the user by clearing out the auth token in sessionStorage and redirecting the URL to the /signin page.
	const logoutAction = () => {
		// Logging the logout time

		const date = new Date(logoutTime);
		const timeString = date.toLocaleTimeString("en-US", { hour12: true });

		console.log("Logout Time:", timeString);
		handleClick();
		sessionStorage.setItem("auto-logout", false);
	};

	return children;
};

export default AppLogout;
