import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import CustomerEditorChat from './CustomerEditorChat';
import InputGroup from 'react-bootstrap/InputGroup';
import message from '../../assets/Images/message.svg'


const CustomerChat = () => {
  return (
    <>


      <Row className="custom-chat-row">
        <Col>
          <Tabs
            defaultActiveKey="EditingTeam"
            id="justify-tab-example"
            className="customertab"
            justify
          >
            <Tab eventKey="EditingTeam" title="Editing Team">
              <CustomerEditorChat />
            </Tab>
            <Tab eventKey="SalesTeam" title="Sales Team">
              <InputGroup className="mb-3 chatmessage">
                <Form.Control
                  placeholder="Message sales team"
                  aria-label="Message sales team"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2"><img src={message} className="img-fluid" alt="mindreach images" /></InputGroup.Text>
              </InputGroup>
            </Tab>
          </Tabs>
        </Col>
        {/* <Col sm={4}>
       <Form>
                   
                            <Form.Control
                                type="search"
                                placeholder="Search chat"
                                className="me-2"
                                aria-label="Search"
                            />
                </Form>
       </Col> */}

      </Row>


      <div className="seacrhbox">
        <Form>

          <Form.Control
            type="search"
            placeholder="Search chat"
            className="me-2"
            aria-label="Search"
          />
        </Form>
      </div>





    </>
  )
}

export default CustomerChat