import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';
import pending from '../../../../assets/Images/pending.svg'
import editing from '../../../../assets/Images/editing.svg'
import revision from '../../../../assets/Images/revision.svg'
import total from '../../../../assets/Images/total.svg'
import bgtotal from '../../../../assets/Images/bg-total.svg'
import coordinating from '../../../../assets/Images/coordinating.svg'
import quality from '../../../../assets/Images/quality.svg'

// import profile from '.../../assets/Images/profile.svg';
import nike from '../../../../assets/Images/nike.svg'
import iconedit from '../../../../assets/Images/icon-edit.svg'

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// import "../../../css/style.css";
// import '../../../css/responsive.css'

import { Link } from 'react-router-dom';

import Select from 'react-select';



const Customeroverview = () => {

  const choosedate = [
    { label: "Today", value: 355 },
    { label: "Custome", value: 54 }

  ];

  const products = [{
    id: '1',
    OrderNo: "0000054",
    OrderDate: '19 Aug 2022',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '2',
    OrderNo: "0000054",
    OrderDate: '19 Aug 2022',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Editing: '105',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '3',
    OrderNo: "0000054",
    OrderDate: '19 Aug 2022',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#C71515' }}>Pending</p>
  },
  {
    id: '4',
    OrderNo: "0000054",
    OrderDate: '19 Aug 2022',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#C71515' }}>Pending</p>
  },
  {
    id: '5',
    OrderNo: "0000054",
    OrderDate: '19 Aug 2022',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#C71515' }}>Pending</p>
  }
  ]

  const columns = [{
    dataField: 'OrderNo',
    text: 'Order No.',
    number: '1',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }, {
    dataField: 'OrderDate',
    text: 'Order Date',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'TotalPipeline',
    text: 'Total in Pipeline',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Pending',
    text: 'Pending',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Coordinating',
    text: 'Coordinating',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Revision',
    text: 'Revision',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Status',
    text: 'Status',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }

  ];

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];


  // <BootstrapTable
  //   keyField="id"
  //   data={products}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />
  return (
    <>

      <Row>
        <Col sm={3} className="custom-scroll-bar">

          <div className="customerdetailtable">
            <div className="uploadimg mt-4">
              <img src={nike} className="img-fluid d-block mx-auto" />
              <p className="headname">Corporana Marketing LLC</p>
              <p className="idname">ID68453</p>
              <div className="edit">
                <img src={iconedit} />
              </div>
            </div>

            <div className="profile-table mb-4 mt-4">
              <ul className="profile-details-list">

                <li>
                  <div className="titletext">
                    Customer ID:
                  </div>
                  <div className="titlecontent">
                    3
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Customer
                  </div>
                  <div className="titlecontent">
                    Test
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Address
                  </div>
                  <div className="titlecontent">
                    Test
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Phone No.
                  </div>
                  <div className="titlecontent">
                    90982347981
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Email
                  </div>
                  <div className="titlecontent">
                    amarnath@capitalnumbers.com
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Status
                  </div>
                  <div className="titlecontent">
                    Inactive
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Staff
                  </div>
                  <div className="titlecontent">
                    Lebron James(staff)
                  </div>
                </li>



                {/* <li>Customer</li>
              <li>Test</li>


              <li>Address</li>
              <li>Test</li>


              <li>Phone No.</li>
              <li>90982347981</li>


              <li>Email</li>
              <li>amarnath@capitalnumbers.com</li>


              <li>Member Plan</li>
              <li>Non-enterprice</li>


              <li>Status</li>
              <li>Inactive</li>


              <li>Staff</li>
              <li>Lebron James(staff)</li> */}

              </ul>
            </div>
            <div className="text-right">

              <Link to="">
                <Button variant="primary btn-edit"><span>
                  <img src={iconedit} /> </span>
                  Edit</Button>{' '}
              </Link>

            </div>
          </div>


        </Col>
        <Col sm={9} className="custom-scroll-bar">
          <h4 className="mb-4 mt-4">Current Production Pipeline</h4>
          <div className="d-sm-flex flex-sm-wrap overviewtab mb-4">

            <Card className="bg-card">
              <Card.Img src={bgtotal} alt="Card image" />

              <Card.ImgOverlay>

                <Card.Img variant="top" src={total} />
                <Card.Body>
                  <Card.Title>Total in Pipeline</Card.Title>
                  <Card.Text>
                    10,999
                  </Card.Text>
                </Card.Body>
              </Card.ImgOverlay>
            </Card>

            <Card>
              <Card.Img variant="top" src={pending} />
              <Card.Body>
                <Card.Title>Pending</Card.Title>
                <Card.Text>
                  6,000
                </Card.Text>

              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={coordinating} />
              <Card.Body>
                <Card.Title>Coordinating</Card.Title>
                <Card.Text>
                  1,197
                </Card.Text>

              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={editing} />
              <Card.Body>
                <Card.Title>Editing</Card.Title>
                <Card.Text>
                  2,313
                </Card.Text>

              </Card.Body>
            </Card>

            <Card>
              <Card.Img variant="top" src={quality} />
              <Card.Body>
                <Card.Title>Quality Control</Card.Title>
                <Card.Text>
                  1,291
                </Card.Text>

              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={revision} />
              <Card.Body>
                <Card.Title>Revision</Card.Title>
                <Card.Text>
                  198
                </Card.Text>

              </Card.Body>
            </Card>
          </div>


          <div className="table-responsive overviewtables table-b">
            <BootstrapTable keyField='id' data={products} columns={columns} />

          </div>
          <Row className="mt-4">
            <Col md={{ span: 4, offset: 8 }}>
              {
                <Pagination>
                  <Pagination.Prev />
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item>{4}</Pagination.Item>
                  <Pagination.Item>{5}</Pagination.Item>
                  <Pagination.Item>{6}</Pagination.Item>
                  <Pagination.Next />
                </Pagination>
              }
            </Col>
          </Row>



        </Col>
      </Row>



    </>
  )
}

export default Customeroverview