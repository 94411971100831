// Holiday Management Pages
export const HOIDAY_LIST_PAGE = "/settings-holiday-lists"; // Page to display the list of holidays
export const ADD_HOLIDAY_PAGE = "/settings-add-holiday"; // Page to add a new holiday
export const EDIT_HOLIDAY_PAGE = "/settings-edit-holiday"; // Page to edit an existing holiday

// Work Shift Management Pages
export const WORK_SHIFTS_LISTS_PAGE = "/settings-work-shift-lists"; // Page to display the list of work shifts
export const WORK_SHIFT_EDIT_PAGE = "/settings-edit-work-shift"; // Page to edit an existing work shift
export const WORK_SHIFT_ADD_PAGE = "/settings-add-work-shift"; // Page to add a new work shift

// Branch Management Pages
export const VIEW_BRANCHE_PAGE = "/settings-view-branch"; // Page to view details of a branch
export const EDIT_BRANCHE_PAGE = "/settings-edit-branch"; // Page to edit an existing branch
export const ADD_BRANCHE_PAGE = "/settings-add-branch"; // Page to add a new branch

// Staff Pages
export const STAFF_PAGE = "/staff"; // Page to display the list of staff members
export const STAFF_VIEW_PAGE = "/staff-details"; // Page to view details of a staff member
export const STAFF_ADD_PAGE = "/staff-new-user"; // Page to add a new staff member
export const EDIT_STAFF_USER_PAGE = "/edit-staff-user"; //Page to View/Edit Staff User
export const STAFF_DETAILS_PAGE = "/staff-details"; // Page to view Staff Details

// Customer Pages
export const VIEW_CUSTOMER_PAGE = "/customers"; // Page to add a new customer
export const CUSTOMER_ADD_PAGE = "/new-customer"; // Page to add a new customer
export const EDIT_CUSTOMER_PAGE = "/edit-customer"; // Page to View/Edit customer
export const CUSTOMERS_DETAILS = "/customers-details"; // Page to view Customer Details

// Edit Type Management Pages
export const EDIT_TYPES_LISTS_PAGE = "/settings-edit-type-lists"; // Page to display the list of edit types
export const EDIT_TYPE_EDIT_PAGE = "/settings-edit-edit-type"; // Page to edit an existing edit type
export const EDIT_TYPE_ADD_PAGE = "/settings-add-edit-type"; // Page to add a new edit type

// Enterprise Fields Pages
export const VIEW_ENTERPRISE_FIELD = "/settings-view-field-enterprise"; // Page to view details of a Enterprise Field
export const EDIT_ENTERPRISE_FIELD = "/settings-edit-field-enterprise"; // Page to edit an existing Enterprise Field
export const ADD_ENTERPRISE_FIELD = "/settings-add-field-enterprise"; // Page to add a new Enterprise Field
export const PREVIEW_ENTERPRISE_FIELD = "/settings-preview-fields-enterprise"; // Page to preview Enterprise Fields
export const VIEW_ENTERPRISE_REQUESTS = "/settings-get-enterprise-request"; // Page to get list of Enterprise requests
export const VIEW_ENTERPRISE_REQUEST_DETAILS =
	"/settings-view-enterprise-request-details"; // Page to get details of Enterprise request

// Super Admin QC Workspace Pages
export const VIEW_QC_WORKSPACE = "/qc-workspace";

//Coordinator Workspace Pages
export const VIEW_COORDINATOR_WORKSPACE = "/coordinator-workspace"; // Page to view coodinating workspace

// Super Admin Editor Workspace Pages
export const VIEW_EDITOR_WORKSPACE = "/editor-workspace"; //Page to View the Editor Workspace

//Image Coodinator Workspace Page
export const VIEW_IMAGE_COORDINATOR_WORKSPACE = "/image-coordinator-workspace"; // Page to view the Image coordinator workspace

//Login Routes
export const LOGIN = "/login"; // Login page

//QC Supervisor Routes
export const QC_SUPERVISOR_WORKSPACE = "/qc-supervisor";

// Super Admin Dashboard
export const DASHBOARD = "/dashboard";

//My Workspace
export const MY_WORKSPACE = "/my-workspace";

//Editor Workspace

export const MY_EDITOR_WORKSPACE = "/editor/workspace";

//QC Workspace
export const QC_MY_WORKSPACE = "/qc-worspace";
