import React, { useCallback, useState, useRef, useEffect } from "react";
import { Card } from "react-bootstrap";
import { isEqual } from "lodash";

//Importing drag and select Module
import {
	Box,
	boxesIntersect,
	useSelectionContainer,
} from "@air/react-drag-to-select";

//Importing the components
import DeleteModalConfirmation from "../../../components/Modal/DeleteModal";

import assigned from "../../../assets/Images/assigned-to-editor.svg";
import checkMarkBlue from "./../../../assets/Images/check-mark-blue-small.svg";

import { useOutsideAlerter } from "../../../utils/OutSideDivDeselect/CoordinatorOutsideClick";
import ImageModal from "./Components/ImageModal";
//Importing the images
import folderThumnail from "./../../../assets/Images/folder_thumbnail.svg";
import { imageURL } from "../../../data/filepath";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import { CO_ORDINATOR_DELETED_IMAGE_BY_ID } from "../../../config/apiRoutes.api";

const CoordinatorOrderDetails = ({
	selectImageName,
	editTypeId,
	idSelected,
	token,
	images,
	folder_path,
	folder_list,
	handleFolderClick,
	multiple_images_id,
	setMultipleImagesId,
	setFoldersPath,
	folders_path,
	folder_name,
	setFolderListName,
	setDuplicateImageModal,
	// setReassignedModal,
	setSelectAll,
	wrapper,
	folderPathSelected,
	setMergedImageModal,
	setStatusNew,
	statusNew,
	editTypes,
	editGroups,
	customer_id,
	setEditorType,
	setDuplicateAmount,
	setAmount,
	setImageID,
	setting_profile,
	handleFetchProfileSettings,
	setSelectedImageName,
	setOriginalImageId,
	imageID,
	show_delete_by_id,
	setShowDeleteByID,
	fetchOrder,
	setDisplaySub,
	setParentFolderDetails,
	setFolderName,
	setBatchNumber,
	setImages,
	setFolderList,
	setFolder,
	setImageMainFolder,
	setFolderMainFolder,
	setMainImageCount,
	setMainImageSettingsCount,
	setOrderNumber,
	setSubFolderOpen,
	setDisplay,
	setClickSelectParent,
	setImageEditorResponse,
	setStatusModal,
	batchId,
}) => {
	// Create a request instance using the token
	const request = client(token);

	const [error, setError] = useState("");
	const [clickSelect, setClickSelect] = useState(false);
	const [ctrlClicked, setCtrlClicked] = useState(true);
	const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
	const [firstSelectedIndex, setFirstSelectedIndex] = useState(0);
	const [secondSelectedIndex, setSecondSelectedIndex] = useState(0);
	const [dragAndSelectEnabled, setDragAndSelectEnabled] = useState(false);
	const [showModal, setShowModal] = useState(false); // Image Modal
	const [image_details_view, setImageDetailsView] = useState({});
	const [image_details_view_id, setImageDetailsViewId] = useState("");
	const [folderSelect, setFolderSelect] = useState(false);
	const [customer_status, setCustomerStatus] = useState("");
	const [duplicateIconShow, setDuplicateIcon] = useState(false);
	const [folder_name_first, setFolderNameFirst] = useState("");
	const [selectionBox, setSelectionBox] = useState();
	const [selectedIndexes, setSelectedIndexes] = useState([]);
	const selectableItems = useRef([]);
	const elementsContainerRef = useRef(null);

	const dsRef = useRef(null);
	const modalRef = useRef(null);

	useOutsideAlerter(
		wrapper,
		setMultipleImagesId,
		setFolderNameFirst,
		setFolderListName,
		setFolderSelect,
		setFoldersPath,
		setClickSelect,
		images,
		folder_list,
		// setReassignedModal,
		setSelectAll,
		setMergedImageModal,
		setDuplicateImageModal,
		setDuplicateAmount,
		setAmount,
		setDragAndSelectEnabled,
		setSelectedIndexes
	); //Outside clickable wrapper

	// Define the logic  for drag and select
	const { DragSelection } = useSelectionContainer({
		eventsElement: document.getElementById("root"),
		onSelectionChange: (box) => {
			/**
			 * Here we make sure to adjust the box's left and top with the scroll position of the window
			 * @see https://github.com/AirLabsTeam/react-drag-to-select/#scrolling
			 */
			const scrollAwareBox = {
				...box,
				top: box.top + window.scrollY,
				left: box.left + window.scrollX,
			};

			setSelectionBox(scrollAwareBox);
			const indexesToSelect = [];
			selectableItems.current.forEach((item, index) => {
				if (boxesIntersect(scrollAwareBox, item)) {
					indexesToSelect.push(Number(index));
				}
			});

			setSelectedIndexes(indexesToSelect);
		},
		onSelectionStart: () => {},
		onSelectionEnd: () => {
			const imagesIds = [];
			const folderIds = [];
			const intersected = document.querySelectorAll(".selected");
			intersected.forEach((element) => {
				if (element.classList.contains("order_images")) {
					imagesIds.push(String(element.getAttribute("id")));
				} else {
					if (
						element.classList.contains("folder") &&
						element.hasAttribute("id")
					) {
						folderIds.push(element.getAttribute("id"));
					}
				}
			});
			setMultipleImagesId(imagesIds);
		},
		selectionProps: {
			style: {
				border: "1px solid #c1c1c1",
				borderRadius: 2,
				// backgroundColor: "rgba(20, 137, 189, 0.5)",
				backgroundColor: "#c1c1c1",
				opacity: 0.6,
				zIndex: 99,
			},
		},
		isEnabled: dragAndSelectEnabled,
	});

	// Define the logic to set the images in ref
	useEffect(() => {
		// if (
		//   elementsContainerRef.current &&
		//   (images.length > 0 || folder_list.length > 0)
		// )
		if (elementsContainerRef.current && images.length > 0) {
			Array.from(elementsContainerRef.current.children).forEach((item) => {
				if (item.innerHTML) {
					const { left, top, width, height } = item.getBoundingClientRect();
					selectableItems.current.push({
						left,
						top,
						width,
						height,
					});
				}
			});
		}
		// }, [images, folder_list]);
	}, [images]);

	/**
	 * Handles the handle Click to set  The image details.
	 * @param {Object} items - The event object of dragged element.
	 * @param {Number} id - The id of the element.
	 * @returns {} set the images.
	 */
	const handleClickImageDetails = (items, id) => {
		setImageDetailsViewId(id);
		setImageDetailsView(items);
		setShowModal(true);
		let order_images = document.querySelectorAll(".order_images");
		for (var i = 0; i < order_images.length; i++) {
			order_images[i].style.zIndex = 1;
			document.body.classList.add("modal-open");
		}

		// setImageDetailsModal(true);
	};
	const seledtedClass = (items_Selected, id) => {
		return items_Selected.length &&
			items_Selected.find((selectedRow) => selectedRow == id)
			? `box exclude-click border-image--card layer-image--selector `
			: `box exclude-click`;
	};
	const selectedLayer = useCallback(
		(items_Selected, id) => {
			return items_Selected.length &&
				items_Selected.find((selectedRow) => selectedRow == id)
				? ` card-button--selected`
				: ``;
		},
		[images]
	);
	const seledtedClassCheckbox = (items_Selected, id) => {
		return items_Selected.length &&
			items_Selected.find((selectedRow) => selectedRow == id)
			? true
			: false;
	};
	const selectClassFolderSelect = (folder_name) => {
		const isSelected = folders_path.includes(folder_name);
		if (isSelected) {
			return "box exclude-click border-image--card "; // Add your selected class here
		}
		return "box exclude-click";
	};

	const checkForFolder = (folder_name) => {
		const isSelected = folders_path.includes(folder_name);
		return isSelected;
	};

	const handleKeyUp = useCallback(
		(event) => {
			if (event.key === "Control" || event.key === "Meta") {
				setCtrlClicked(true);
			}
		},
		[ctrlClicked]
	);
	const handleChangeSelected = useCallback(
		(changeEvent, item, id, index, itemList) => {
			setDragAndSelectEnabled(false);
			setFolderNameFirst([]);
			setFolderListName([]);

			setFolderSelect([]);

			setFoldersPath([]);
			setEditorType(item.edit_group_id);
			setOriginalImageId(item.id);
			let itemId = [];
			const selectedItemCheck = changeEvent.currentTarget.id;

			// if (changeEvent.type === "click") {
			// switch (true) {
			if (
				!changeEvent.ctrlKey ||
				!changeEvent.metaKey ||
				!changeEvent.shiftKey
			) {
				setDragAndSelectEnabled(true);
			}
			const allSelected =
				multiple_images_id.length === images.length ? true : false;
			if ((allSelected && changeEvent.ctrlKey) || changeEvent.metaKey) {
				// Deselect all if all are currently selected
				setDragAndSelectEnabled(false);
				setCtrlClicked(true);
				localStorage.setItem("ctrlClicked", true);
				changeEvent.persist();
				const selectedItemsArray = Array.from(multiple_images_id);

				setMultipleImagesId((prevSelectedItems) => {
					const isItemAlreadySelected = selectedItemsArray.some((item) =>
						isEqual(item, selectedItemCheck)
					);

					const updatedItems = isItemAlreadySelected
						? prevSelectedItems.filter(
								(item) => !isEqual(item, selectedItemCheck)
						  )
						: Array.from(new Set([...prevSelectedItems, selectedItemCheck]));

					return updatedItems;
				});
			} else if (changeEvent.ctrlKey || changeEvent.metaKey) {
				changeEvent.persist();
				// Ctrl key is pressed
				setCtrlClicked(true);
				setDragAndSelectEnabled(false);
				localStorage.setItem("ctrlClicked", true);

				const selectedItemsArray = Array.from(multiple_images_id);
				const customEquality = (item1, item2) => {
					// Assuming items are objects and have an 'id' property
					return item1 === item2;
				};
				setMultipleImagesId((prevSelectedItems) => {
					const isItemAlreadySelected = selectedItemsArray.some((item) =>
						customEquality(item, selectedItemCheck)
					);

					const updatedItems = isItemAlreadySelected
						? prevSelectedItems.filter(
								(item) => !customEquality(item, selectedItemCheck)
						  )
						: Array.from(new Set([...prevSelectedItems, selectedItemCheck]));

					return updatedItems;
				});
			} else if (changeEvent.shiftKey) {
				itemId.push(...selectedItemCheck);
				setDragAndSelectEnabled(false);
				if (itemId.length === 0) {
					setMultipleImagesId([id.toString()]);
				}
				// Shift key is pressed
				else {
					const selectedItemsArray = Array.from(multiple_images_id);
					const selectedIndicesArray = selectedItemsArray.map((itemId) =>
						itemList.findIndex((item) => item.id.toString() === itemId)
					);

					const currentIndex = index;
					const minSelectedIndex = Math.min(...selectedIndicesArray);
					const maxSelectedIndex = Math.max(...selectedIndicesArray);

					const start = Math.min(minSelectedIndex, currentIndex);
					const end = Math.max(maxSelectedIndex, currentIndex);

					const rangeIndices = Array.from(
						{ length: end - start + 1 },
						(_, i) => start + i
					);
					const rangeIds = rangeIndices.map((idx) =>
						itemList[idx].id.toString()
					);

					setMultipleImagesId(rangeIds);
				}
			} else {
				// ... (other parts of the code)
				if (changeEvent.type === "click") {
					// ... (other parts of the code)
					if (changeEvent.type === "click") {
						setSelectedIndexes([]);
						setMultipleImagesId([]);

						const newClickSelect = true;
						if (
							// changeEvent.currentTarget.classList.contains("ds-selectable") ||
							!changeEvent.currentTarget.classList.contains(
								"border-image--card"
							)
						) {
							setMultipleImagesId([String(id)]);
							setImageID(id);
							setDragAndSelectEnabled(false);
						}
						setClickSelect(newClickSelect);
						localStorage.setItem("clickSelect", true);
					}
				}
			}
		},
		[
			multiple_images_id,
			images,
			setMultipleImagesId,
			clickSelect,
			wrapper,
			ctrlClicked,
			setDragAndSelectEnabled,
		]
	);
	const handleSingleClickFolder = (changeEvent) => {
		const folder_name_key = changeEvent.currentTarget.id;
		setMultipleImagesId([]);

		if (changeEvent.ctrlKey || changeEvent.metaKey) {
			// Ctrl key is pressed
			setCtrlClicked(true);
			localStorage.setItem("ctrlClicked", true);

			setFolderListName((prevFoldersPath) => {
				const isSelected = prevFoldersPath.includes(folder_name_key);
				if (isSelected) {
					// Deselect the folder by removing it from the path
					return prevFoldersPath.filter((path) => path !== folder_name_key);
				} else {
					// Select the folder by adding it to the path
					return [...prevFoldersPath, folder_name_key];
				}
			});

			setFolderSelect((prev) =>
				prev === folder_name_key ? null : folder_name_key
			);

			setFoldersPath((prevFoldersPath) => {
				const isSelected = prevFoldersPath.includes(folder_name_key);
				if (isSelected) {
					// Deselect the folder by removing it from the path
					return prevFoldersPath.filter((path) => path !== folder_name_key);
				} else {
					// Select the folder by adding it to the path
					return [...prevFoldersPath, folder_name_key];
				}
			});
		} else if (changeEvent.shiftKey) {
			setFolderListName((prevFoldersPath) => {
				const isSelected = prevFoldersPath.includes(folder_name_key);
				if (isSelected) {
					// Deselect the folder by removing it from the path
					return prevFoldersPath.filter((path) => path !== folder_name_key);
				} else {
					// Select the folder by adding it to the path
					return [...prevFoldersPath, folder_name_key];
				}
			});

			setFolderSelect((prev) =>
				prev === folder_name_key ? null : folder_name_key
			);

			setFoldersPath((prevFoldersPath) => {
				const isSelected = prevFoldersPath.includes(folder_name_key);
				if (isSelected) {
					// Deselect the folder by removing it from the path
					return prevFoldersPath.filter((path) => path !== folder_name_key);
				} else {
					// Select the folder by adding it to the path
					return [...prevFoldersPath, folder_name_key];
				}
			});
		} else {
			if (changeEvent.type === "click") {
				setFolderListName([]);
				setFolderSelect("");
				setFoldersPath([]);
				if (
					!changeEvent.currentTarget.classList.contains("border-image--card")
				) {
					// // Unselect all previously selected folders
					setFolderListName([folder_name_key]);

					// Set the currently clicked folder as the selected folder
					setFolderSelect(folder_name_key);

					// Update the path with the selected folder
					setFoldersPath([folder_name_key]);
				}
			}
		}
	};

	const svgCode = (count) => {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white"/>
		<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10ZM9 4.25V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H4.25L9 4.25Z" fill="white"/>
		<!-- Use a <style> element to set the font size -->
			<style>
			  text {
				font-size: 9px;
				font-weight:500;
			  }
			</style>
			<!-- Adjust the y attribute to add margin on the top -->
			<text x="6" y="18" fill="green">${count}</text>
	  </svg>`;
	};
	const renderContent = useCallback(
		(item) => {
			return (
				<div key={item.id}>
					{item.duplication_settings.length !== 0 && (
						<span className="checkbox-order--duplicate">
							<div
								dangerouslySetInnerHTML={{
									__html: svgCode(
										(item.duplication_settings || []).length.toString()
									),
								}}
							/>
						</span>
					)}
				</div>
			);
		},
		[images]
	);
	const handleDelete = () => {
		if (token) {
			request
				.post(apiURL + CO_ORDINATOR_DELETED_IMAGE_BY_ID + `/${imageID}`)
				.then((response) => {
					setShowDeleteByID(false);
					fetchOrder(customer_id);
				})
				.catch((err) => console.log(err));
		}
	};
	useEffect(() => {
		if (multiple_images_id.length === 0) {
			setDragAndSelectEnabled(true);
		}
	}, [multiple_images_id]);
	return (
		<>
			<DragSelection />
			<div className="editororderdetails editororderdetails-new">
				<div className="detailslist coordibox" ref={elementsContainerRef}>
					{folder_list.map((item, index) => {
						return (
							<div
								className="cardsContainer"
								data-folder-path={item.path}
								key={index}
								id={item.path}
							>
								<Card
									onDoubleClick={(e) => {
										handleFolderClick(item.path);
										setSelectAll(false);
										if (
											!e.currentTarget.classList.contains("border-image--card")
										) {
											// // Unselect all previously selected folders
											setFolderListName([e.currentTarget.id]);

											// Set the currently clicked folder as the selected folder
											setFolderSelect(e.currentTarget.id);

											// Update the path with the selected folder
											setFoldersPath([e.currentTarget.id]);
										}
									}}
									onClick={(e) => {
										handleSingleClickFolder(e);
									}}
									// onClick={(e) => handleSingleClickFolder(e)}
									id={item.path}
									key={index}
									className={
										`box exclude-click folder ${selectClassFolderSelect(
											item.path
										)}`
										//  +
										// (selectedIndexes.indexOf(Number(index)) >= 0
										//   ? " selected"
										//   : "")
									}
								>
									<div
										style={{
											minHeight: "233px",
											background: "#ffff",
											overflow: "hidden",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "167px",
											marginLeft: "auto",
											marginRight: "auto",
										}}
									>
										<img
											// id={item.id}
											variant="top"
											width={200}
											height={100}
											className="image-rendering"
											loading="lazy"
											alt="Mindreach"
											// layout="fill"
											src={folderThumnail}
										/>
									</div>
									<input
										type="checkbox"
										className="checkbox-order--sub"
										// value={item.id}
										// id={item.id}
										checked={checkForFolder(item.path)}
									/>

									<Card.Body>
										<Card.Text>{item.path}</Card.Text>
										<span className="image-settings-folder--select">
											({item.settings_count}/{item.image_count})
										</span>
									</Card.Body>
								</Card>
							</div>
						);
					})}

					{images.map((items, index) => {
						return (
							<div
								className={
									"cardsContainer order_images" +
									(selectedIndexes.indexOf(Number(index)) >= 0
										? " selected"
										: "")
								}
								id={items.id}
								custom-id={items.id}
							>
								<Card
									id={items.id}
									key={index}
									className={seledtedClass(multiple_images_id, items.id)}
									// ref={items.customer_status === "new" ? wrapper : null}
									ref={wrapper}
									onDoubleClick={() => handleClickImageDetails(items, items.id)}
									onClick={(event) => {
										if (items.status === "new") {
											handleChangeSelected(
												event,
												items,
												items.id,
												index,
												images
											);
											multiple_images_id.length === 0
												? setFirstSelectedIndex(items.id)
												: setSecondSelectedIndex(items.id);
											setStatusNew(true);
											setEditorType(items.edit_group_id);
											localStorage.setItem("click_type", "image");
										} else {
											setMultipleImagesId([]);
											setClickSelect(false);
											setStatusNew(false);
											setCustomerStatus(items.status);
											localStorage.setItem("click_type", "image");
										}
									}}
									disabled={items.customer_status !== "new"}
								>
									<div
										style={{
											height: "auto",
											background: "#ffff",
											overflow: "hidden",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										id={items.id}
									>
										<img
											id={items.id}
											variant="top"
											width={200}
											height={100}
											className="image-rendering"
											loading="lazy"
											alt="Mindreach"
											// layout="fill"
											src={`${imageURL}/${items.customer.email}/${items.batch_number}/${items.order.order_number}/images/${items.image_path}`}
										/>
									</div>
									{items.status !== "assign_to_editor" && (
										<input
											type="checkbox"
											className="checkbox-order--sub"
											value={items.id}
											id={items.id}
											checked={seledtedClassCheckbox(
												multiple_images_id,
												items.id
											)}
											key={index}
											disabled={items.customer_status !== "new"}
										/>
									)}
									{renderContent(items)}

									<Card.Body>
										<Card.Text>{items.path}</Card.Text>

										<div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
											<div
												className={`bothbtn-order btns ${selectedLayer(
													multiple_images_id,
													items.id
												)} `}
												key={index}
											>
												{items.status === "assign_to_editor" ? (
													<>
														<span>
															<img
																style={{ width: "17px", height: "17px" }}
																src={assigned}
																className="img-fluid "
																alt="mindreach images"
															/>
														</span>
														<span>Sent</span>
													</>
												) : items.edit_group_id === null ? (
													<>
														<span style={{ width: "6.7rem" }}>Pending</span>
													</>
												) : (
													<>
														<span>
															<img
																style={{ width: "20px", height: "20px" }}
																src={checkMarkBlue}
																className="img-fluid "
																alt="mindreach images"
															/>
														</span>
														<span className="settings_selected">
															Settings Selected
														</span>
													</>
												)}
											</div>
										</div>
									</Card.Body>
								</Card>
							</div>
						);
					})}

					{/* End of Card Container  */}
					{/*********************************Image Modal******************************/}
					<ImageModal
						setShowModal={setShowModal}
						showModal={showModal}
						image_details_view={image_details_view}
						setImageDetailsView={setImageDetailsView}
						setImageDetailsViewId={setImageDetailsViewId}
						editTypes={editTypes}
						editGroups={editGroups}
						setMultipleImagesId={setMultipleImagesId}
						selectImageName={selectImageName}
						token={token}
						idSelected={idSelected}
						setClickSelect={setClickSelect}
						setFolderNameFirst={setFolderNameFirst}
						setFolderListName={setFolderListName}
						setFolderSelect={setFolderSelect}
						setFoldersPath={setFoldersPath}
						setSelectAll={setSelectAll}
						setDuplicateImageModal={setDuplicateImageModal}
						setDuplicateAmount={setDuplicateAmount}
						setAmount={setAmount}
						customer_id={customer_id}
						customer_status={customer_status}
						images={images}
						modalRef={modalRef}
						setting_profile={setting_profile}
						handleFetchProfileSettings={handleFetchProfileSettings}
						setImageID={setImageID}
						imageID={imageID}
						setDisplaySub={setDisplaySub}
						setParentFolderDetails={setParentFolderDetails}
						setFolderName={setFolderName}
						setBatchNumber={setBatchNumber}
						setImages={setImages}
						setFolderList={setFolderList}
						setFolder={setFolder}
						setImageMainFolder={setImageMainFolder}
						setFolderMainFolder={setFolderMainFolder}
						setMainImageCount={setMainImageCount}
						setMainImageSettingsCount={setMainImageSettingsCount}
						setOrderNumber={setOrderNumber}
						setSubFolderOpen={setSubFolderOpen}
						setDisplay={setDisplay}
						setClickSelectParent={setClickSelectParent}
						multiple_images_id={multiple_images_id}
						statusNew={statusNew}
						setImageEditorResponse={setImageEditorResponse}
						setStatusModal={setStatusModal}
						batchId={batchId}
						setStatusNew={setStatusNew}
					/>
					{/******To display the delete confirmation Modal */}
					<DeleteModalConfirmation
						show={show_delete_by_id}
						onHide={() => {
							setShowDeleteByID(false);
						}}
						branch={"order"}
						name={selectImageName}
						title={"Delete Image"}
						handleConfirm={() => handleDelete(imageID)}
					/>
				</div>
			</div>
		</>
	);
};

export default CoordinatorOrderDetails;
