import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  Form,
  Row,
  Col,
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Select from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Importing components and images
import Loader from "../../../components/Loader";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import MyVerticallyCenteredModal from "../../../components/Modal";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import {
  ADMIN_PREVIEW_ENTERPRISE_FIELD,
  ADMIN_SET_ENTERPRISE_FIELD_PRIORITY,
} from "../../../config/apiRoutes.api";
import { VIEW_ENTERPRISE_FIELD } from "../../../config/routes";
import { clone } from "underscore";
const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: [],
};

const PreviewFields = () => {
  // Retrieve the token from the session storage
  const token = sessionStorage.getItem("token");
  // Create a request instance using the token
  const request = client(token);
  const bodyFormData = new FormData();
  // Get the navigation function from the useNavigate hook
  const navigate = useNavigate();
  //Declarations of state variable
  const [fieldsData, setEnterpriseFieldsData] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowChanged, setRowChanged] = useState(false);
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  const [message, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const dragItem = useRef();
  const dragOverItem = useRef();
  /**
   * Renders a Tooltip component with the specified text as its content.
   * @param {string} text - The text to be displayed in the Tooltip.
   * @returns {JSX.Element} The Tooltip component.
   */
  const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

  /**
   * Fetches the fields data from the API when the pageNumber changes or the token is available.
   */
  useEffect(() => {
    // Check if a token is available
    if (token) {
      fetchFields();
    }
  }, []);

  /**
   * Handles the confirmation for deleting a Field.
   *
   */
  const fetchFields = () => {
    // Make an HTTP GET request to fetch the fields data with the specified page number
    request
      .get(apiURL + ADMIN_PREVIEW_ENTERPRISE_FIELD)
      .then((response) => {
        // Log the response to the console
        console.log(" response =>", response.data);

        // Update the fieldsData state with the response data
        setEnterpriseFieldsData(response.data);

        // Update the rows state with the fields data
        setRows(response.data.formFields);
      })
      .catch((err) => console.log(err));
  };

  /**
   * Handles the function to retun id and name.
   * It handles and accepts object array and return id and name,
   *  * @param {Array} obj - Object array.
   * @returns {array}
   */

  const findIds = (obj) => {
    return obj.map((item) => {
      return item.id;
    });
  };

  /**
   * Generate input based on  field array.
   * It generates the form input field type passed.
   * @param {Array} obj - The Hold the array of object.
   */

  const generateInputField = (obj) => {
    switch (obj.field_type) {
      case "select":
        return (
          <Select
            options={generateSelectValues(obj.values || [])}
            placeholder={""}
            onChange={(e) => {
              console.log(e.value);
            }}
            className="w-50"
          />
        );
      case "radio":
        return generateInputRadio(obj);
      case "checkbox":
        return generateInputCheckbox(obj);
      case "document":
        return (
          <input
            type="file"
            id="file"
            onChange={(e) => {}}
            style={{ fontSize: "small" }}
          />
        );
      default:
        return (
          <Form.Control
            as="textarea"
            autoFocus={true}
            autoComplete="off"
            style={{ height: "100px" }}
          />
        );
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    console.log(" result ", result.destination.index);
    console.log(" result 2 ", result.source.index);
    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    if (result.source.index !== result.destination.index) {
      setRowChanged(true);
    }
    setRows(items);
  };

  /**
   * Generate multiple checkbox input based on  field array.
   * It generates the form  multiple checkbox input field type passed.
   * @param {Array} obj - The Hold the array of object.
   */

  const generateInputCheckbox = (obj) => {
    return obj.values.map((item, indx) => {
      return (
        // <Col sm={5} key={"rand_" + indx}>
        <div
          className="form-group2 "
          key={"rand_" + indx}
          style={{ marginBottom: "5px" }}
        >
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            onChange={(e) => {}}
            id={`multi_${item.id}`}
            placeholder={item.field_placeholder}
          />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Form.Label
            className="align-text-top"
            htmlFor={`multi_${item.id}`}
            style={{
              cursor: "pointer",
              marginTop: "1px",
              fontWeight: "400",
            }}
          >
            {item.field_value_text}
          </Form.Label>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        // </Col>
      );
    });
  };

  /**
   * Generate multiple radio input based on  field array.
   * It generates the form  multiple radio input field type passed.
   * @param {Array} obj - The Hold the array of object.
   */

  const generateInputRadio = (obj) => {
    return obj.values.map((item, indx) => {
      return (
        <div
          className="form-group2"
          key={"rand_" + indx}
          style={{ marginBottom: "5px" }}
        >
          <span>
            <input
              style={{ cursor: "pointer" }}
              type="radio"
              name={generateID(obj.field_label)}
              className=""
              onChange={(e) => {}}
              id={`${item.id}`}
              placeholder={item.field_placeholder}
            />
          </span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Form.Label
            className="align-top align-text-top"
            htmlFor={`${item.id}`}
            style={{
              cursor: "pointer",
              marginTop: "1px",
              fontWeight: "400",
            }}
          >
            {item.field_value_text}
          </Form.Label>
        </div>
      );
    });
  };

  /**
   * Generate input id based on  field array.
   * It generates the form input field type passed.
   */

  const generateID = (input) => {
    return input ? input.replace(/ /g, "_").toLowerCase() : "";
  };

  /**
   * Generate input label based on field name.
   * It generates the form input label based on field name.
   */

  const generateLabel = (input) => {
    return input
      ? input.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()).replace(/_/g, " ")
      : "";
  };

  /**
   * Generate select options based on  field array.
   * It generates the form select options based on array input array passed.
   * @param {Array} obj - The Hold the array of object.
   *
   */

  const generateSelectValues = (obj) => {
    let ary_select = [];
    obj.forEach((obj) => {
      ary_select.push({
        label: obj.field_value_text,
        value: obj.field_value,
      });
    });
    return ary_select;
  };

  /**
   * Check if any position has changed.
   * It calls the API based on changed index.
   */

  useEffect(() => {
    if (rowChanged) {
      // call the API to change the position
      resetFieldPosition();
    }
  }, [rows]);

  /**
   * Reset the fields position with API call.
   */
  const resetFieldPosition = () => {
    let ids = findIds(rows);
    console.log(" ids =>", ids);
    bodyFormData.append("field_ids", ids ? ids.join(",") : "");
    request
      .post(apiURL + ADMIN_SET_ENTERPRISE_FIELD_PRIORITY, bodyFormData)
      .then((response) => {
        setSuccessMessage(response.data.message);
      })
      .catch((err) => {
        setSuccessMessage("");
        setErrorMessage("Something went wrong!!");
        console.log(err);
      });
    setRowChanged(false);
  };

  // console.log("fieldsData =>", fieldsData);
  if (rows.length == 0) return <Loader />;
  else {
    // Render the actual content once fieldsData is available
    return (
      <>
        <div>
          <div className="rightbar pr-11">
            <div className="section-title">
              <h2 style={{ display: "inline-block" }}>
                Enterprise Signup Form (Preview)
              </h2>
              <Button
                variant=""
                className="puple btns mr-3 pull-right"
                type="submit"
                onClick={() => navigate(VIEW_ENTERPRISE_FIELD)}
              >
                Cancel
              </Button>
            </div>
            <Row>
              <Col
                sm={12}
                lg={8}
                style={
                  {
                    // overflowY: "auto",
                    // height: "600px",
                  }
                }
              >
                <Form className="sp_form">
                  <Form.Group className="mb-3 ">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="tasks">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {rows.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <div
                                      style={{
                                        margin: "18px",
                                        padding: "7px",
                                      }}
                                    >
                                      <Row>
                                        <Form.Label
                                          className="col-sm-12"
                                          htmlFor={generateID(item.label)}
                                          style={{
                                            cursor: "all-scroll",
                                            padding: "20px",
                                          }}
                                        >
                                          {item.field_label
                                            ? item.field_label
                                            : generateLabel(item.label)}
                                          {Number(item.field_required) ===
                                            1 && (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          )}
                                        </Form.Label>

                                        <Col
                                          sm={10}
                                          style={{
                                            cursor: "all-scroll",
                                            marginLeft: "2%",
                                          }}
                                        >
                                          {generateInputField(item)}
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <Row>
                      <Form.Label
                        className="col-sm-12"
                        style={{
                          cursor: "all-scroll",
                          padding: "20px",
                        }}
                      ></Form.Label>

                      <Col
                        sm={10}
                        style={{
                          cursor: "all-scroll",
                          marginLeft: "2%",
                        }}
                      >
                        <Button
                          variant=""
                          className="puple btns mr-3"
                          type="submit"
                          onClick={() => navigate(VIEW_ENTERPRISE_FIELD)}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                  {/* <Form.Group className="mb-3 ">
                    {rows.map((item, index) => {
                      return (
                        <div
                          style={{
                            cursor: "all-scroll",
                            margin: "20px",
                            padding: "10px",
                            touchAction: "pan-y",
                          }}
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={drop}
                          key={index}
                          draggable
                        >
                          <Row>
                            <Form.Label
                              className="col-sm-5"
                              htmlFor={generateID(item.label)}
                              style={{
                                cursor: "all-scroll",
                              }}
                            >
                              {item.field_label
                                ? item.field_label
                                : generateLabel(item.label)}
                              {Number(item.field_required) === 1 && (
                                <span style={{ color: "red" }}> (*)</span>
                              )}
                            </Form.Label>

                            <Col sm={6}>{generateInputField(item)}</Col>
                          </Row>
                        </div>
                      );
                    })}
                  </Form.Group> */}
                </Form>
              </Col>
            </Row>
          </div>
        </div>
        <MyVerticallyCenteredModal
          show={message || errorMessage ? true : false}
          onHide={() => {
            setSuccessMessage("");
            setErrorMessage("");
          }}
          heading={message ? "Success" : "Error"}
          message={message ? message : errorMessage}
          className="modal-body-body--success"
        />
      </>
    );
  }
};

export default PreviewFields;
