import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import plusblue from '../../assets/Images/plus-blue.svg'
import Select from 'react-select';



const AddUserGroupChat = () => {
    const adduser = [
        { label: "Kobe Bryant", value: 355 },
        { label: "Stephen Curry", value: 54 },
        { label: "Ja Morant", value: 54 }

    ];

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <div variant=""
                onClick={handleShow}>
                <img src={plusblue} className="img-fluid mr-2" alt="plusblue" />
            </div>{' '}

            <Modal show={show}
                className="addtypegroup"
                size="lg"
                centered
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Group Chat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Group Name</Form.Label>

                            <Form.Control
                                type="text"
                                placeholder="Type Group Name"
                                autoFocus
                            />


                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Add User</Form.Label>
                            {/* <Form.Control
                    type="search"
                    placeholder="Search by Name"
                    className="me-2"
                    aria-label="Search"
                /> */}<Select isMulti
                                menuIsOpen options={adduser} defaultValue={{ label: "Kobe Bryant", value: 355 }} />

                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <div className="d-sm-flex justify-content-end">
                        <div>
                            <Button variant="primary" className="btns" onClick={handleClose}>
                                Create
                            </Button> </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddUserGroupChat