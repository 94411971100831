import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FloatingLabel, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import reportedittypecount from '../../../assets/Images/customer-total-image-count.svg';
import reporttotalrevenue from '../../../assets/Images/customer-total-error-count.svg';
import errorrate from '../../../assets/Images/error-rate.svg';
import reporttotalaverageprice from '../../../assets/Images/average-turnaround-time.svg';
import reporttotalaverageeditingtime from '../../../assets/Images/editing-time-icon.svg';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'react-bootstrap/Pagination';

const SupervisorReportsOrderHistoryReport = () => {

    const thismonth = [
        { label: "This Month", value: 1 },
        { label: "Demo", value: 2 }
    ];
    const products = [{
        OrderNo: "00000234",
        Customer: 'Nike, Inc.',
        BatchName: 'Model - Shoes - Nike',
        OrderDate: '19 Aug 2022 ',
        ImageCount: '1,000',
        ErrorCount: '41',
        ErrorRate: '0.02%',
        TotalTime: '06:03:37',
        AverageTimePerImage: '00:30:17'
    },
    {
        OrderNo: "00000234",
        Customer: 'Nike, Inc.',
        BatchName: 'Model - Shoes - Nike',
        OrderDate: '19 Aug 2022 ',
        ImageCount: '1,000',
        ErrorCount: '41',
        ErrorRate: '0.02%',
        TotalTime: '06:03:37',
        AverageTimePerImage: '00:30:17'
    },
    {
        OrderNo: "00000234",
        Customer: 'Nike, Inc.',
        BatchName: 'Model - Shoes - Nike',
        OrderDate: '19 Aug 2022 ',
        ImageCount: '1,000',
        ErrorCount: '41',
        ErrorRate: '0.02%',
        TotalTime: '06:03:37',
        AverageTimePerImage: '00:30:17'
    },
    {
        OrderNo: "00000234",
        Customer: 'Under Armour Corp.',
        BatchName: 'Model - Shoes - Nike',
        OrderDate: '19 Aug 2022 ',
        ImageCount: '1,000',
        ErrorCount: '41',
        ErrorRate: '0.02%',
        TotalTime: '06:03:37',
        AverageTimePerImage: '00:30:17'
    },
    {
        OrderNo: "00000234",
        Customer: 'Reebok International',
        BatchName: 'Model - Shoes - Nike',
        OrderDate: '19 Aug 2022 ',
        ImageCount: '1,000',
        ErrorCount: '41',
        ErrorRate: '0.02%',
        TotalTime: '06:03:37',
        AverageTimePerImage: '00:30:17'
    },
    {
        OrderNo: "00000234",
        Customer: 'Reebok International',
        BatchName: 'Model - Shoes - Nike',
        OrderDate: '19 Aug 2022 ',
        ImageCount: '1,000',
        ErrorCount: '41',
        ErrorRate: '0.02%',
        TotalTime: '06:03:37',
        AverageTimePerImage: '00:30:17'
    }
    ]

    const columns = [{
        dataField: 'OrderNo',
        text: 'Order No.',
        number: '1',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }

    }, {
        dataField: 'Customer',
        text: 'Customer',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'BatchName',
        text: 'Batch Name',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'OrderDate',
        text: 'Order Date',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ImageCount',
        text: 'Image Count',

        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ErrorCount',
        text: 'Error Count',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }

    },
    {
        dataField: 'ErrorRate',
        text: 'Error Rate',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }

    },
    {
        dataField: 'TotalTime',
        text: 'Total Time',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }

    },
    {
        dataField: 'AverageTimePerImage',
        text: 'Average Time Per Image',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '150px', textAlign: 'left' };
        }

    },


    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    // <BootstrapTable
    //     keyField="id"
    //     data={products}
    //     columns={columns}
    //     striped
    //     hover
    //     condensed
    //     defaultSortDirection="asc"
    // />

    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel ">
                    <div className="rightbar pr-11">
                        <div className="section-title">
                            <h2>Coordinating Order History</h2>

                        </div>
                        <div className="customer-search mb-4">
                            <Form>
                                <Row className="align-items-center">

                                    <Col xs={2}>
                                        {/* <Form.Label className='label-div'>Image Count</Form.Label> */}
                                        <Select options={thismonth}
                                            defaultValue={{ label: "This Month", value: 1 }} />
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control
                                            type="search"
                                            placeholder="Search Order No./Customer"
                                            className="me-2 search-icon"
                                            aria-label="Search"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className="d-sm-flex mb-4 report-edit supervisor-report">
                            <Card className="bg-card">
                                {/* <Card.Img variant="top" src={reportedittypecount} /> */}
                                <Card.ImgOverlay>
                                    <Card.Img variant="top" src={reportedittypecount} />
                                    <Card.Body>
                                        <Card.Title>Image Count</Card.Title>
                                        <Card.Text>
                                            81,937
                                        </Card.Text>
                                    </Card.Body>
                                </Card.ImgOverlay>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={reporttotalrevenue} />
                                <Card.Body>
                                    <Card.Title>Error Count</Card.Title>
                                    <Card.Text>
                                        75
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={errorrate} />
                                <Card.Body>
                                    <Card.Title>Error Rate</Card.Title>
                                    <Card.Text>
                                        0.02%
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={reporttotalaverageprice} />
                                <Card.Body>
                                    <Card.Title>Total Time</Card.Title>
                                    <Card.Text>
                                        48:15:45
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Img variant="top" src={reporttotalaverageeditingtime} />
                                <Card.Body>
                                    <Card.Title>Average Time Per Image</Card.Title>
                                    <Card.Text>
                                        00:00:10
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="table-responsive table-b supervisor-report-table">
                            <BootstrapTable keyField='id' data={products} columns={columns} />

                        </div>
                        <Row className="mt-4">
                            <Col md={{ span: 4, offset: 8 }}>
                                {
                                    <Pagination>
                                        <Pagination.Prev />
                                        <Pagination.Item active>{1}</Pagination.Item>
                                        <Pagination.Item>{2}</Pagination.Item>
                                        <Pagination.Item>{3}</Pagination.Item>
                                        <Pagination.Item>{4}</Pagination.Item>
                                        <Pagination.Item>{5}</Pagination.Item>
                                        <Pagination.Item>{6}</Pagination.Item>
                                        <Pagination.Next />
                                    </Pagination>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupervisorReportsOrderHistoryReport