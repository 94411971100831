import React, { useEffect, useState } from "react";

// Importing components
import Form from "react-bootstrap/Form";

// Importing API related files and routes
import {
	GOOGLE_MAP_SEARCH,
	GOOGLE_MAP_PLACE_ID,
} from "../../config/apiRoutes.api";
import { client } from "./../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../data/filepath";

/**
 * Component for geocoding location using the Google Maps Geocoding API.
 * @param {string} location - The user-entered location.
 * @param {Function} setLocation - Callback function to set the location.
 * @param {Function} setTimeZone - Callback function to set the timezone.
 * @returns {JSX.Element} - The rendered GeoCodeApi component.
 */
const GeoCodeApi = ({ location, setLocation, setTimeZone }) => {
	const [data, setData] = React.useState([]);
	const [options, setOptions] = useState(true);
	const [place_id, setPlaceId] = useState("");
	const token = sessionStorage.getItem("token");
	const request = client(token);

	useEffect(() => {
		if (location !== "") {
			request
				.get(apiURL + GOOGLE_MAP_SEARCH + `?search_key=${location}`)
				.then((response) => {
					console.log(response);
					setData(response.data.predictions);
				})
				.catch((err) => console.log(err));
		} else {
			setOptions(false);
		}
	}, [location]);

	useEffect(() => {
		if (place_id !== "") {
			request
				.get(apiURL + GOOGLE_MAP_PLACE_ID + `?place_id=${place_id}`)
				.then((response) => {
					console.log("place_id", response.data);
					setTimeZone(response.data.gmt);
				})
				.catch((err) => console.log(err));
		}
	}, [data]);

	return (
		<div
			className={`${data.length !== 0 && options === true ? "datalist" : ""}`}
		>
			<Form.Control
				type="text"
				value={location}
				autoComplete="off"
				onChange={(e) => {
					setLocation(e.target.value);
					setOptions(true);
				}}
			/>
			<>
				{data !== undefined &&
					options === true &&
					data.map((item, id) => {
						return (
							<div
								key={id}
								id="city"
								onClick={(e) => {
									setLocation(item.description);
									setOptions(false);
									setPlaceId(item.place_id);
								}}
								className="m-2"
							>
								<options className="" value={item.description}>
									{item.description}
								</options>
							</div>
						);
					})}
			</>
		</div>
	);
};

export default GeoCodeApi;
