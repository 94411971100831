import React from 'react'
import folder from '../../../assets/Images/folder.svg'
import folderblue from '../../../assets/Images/folder-blue.svg'

const Treeview = () => {
    return (

        <div className="editororderdetails editororderdetails-scroll">
            {/* <ul className="treeviewlist">
                <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                    <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>Main Folder
                    <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                        <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>General Products (60)</li>

                    <li>
                        <div className="treelist">
                            <i className="fa fa-angle-down" aria-hidden="true"></i>
                            <span><img src={folderblue} className="img-fluid" alt="mindreach images" /></span>Model Images (20)
                        </div>

                        <ul>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                                <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>Womens Model (20)</li>
                        </ul>


                    </li>
                    <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                        <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>Lifestlye Images</li>
                    <li><i className="fa fa-angle-down" aria-hidden="true"></i>
                        <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>Tops Images (20)


                        <ul>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                                <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>Dress Shirts</li>
                            <li><i className="fa fa-angle-down" aria-hidden="true"></i>
                                <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>T-Shirts


                                <ul>
                                    <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>Blue Shirts</li>
                                    <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                                        <span><img src={folder} className="img-fluid" alt="mindreach images" /></span>White Shirts</li>
                                </ul>


                            </li>
                        </ul>


                    </li>

                </li>
            </ul> */}
            <ul className="treeviewlist custom-viewlist">
                <li className='pt-0'><i className="fa fa-angle-down" aria-hidden="true"></i>
                    <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>Main Folder</span>
                    <ul className='first-treeview'>
                        <li>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                            <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>General Products</span> (60)
                        </li>
                        <li><i className="fa fa-angle-down" aria-hidden="true"></i>

                            <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>Model Images</span> (20)
                            <ul className='second-treeview'>
                                <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                                    <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>Womens Model</span> (20)</li>
                            </ul>
                        </li>
                        <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                            <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>Lifestlye Images</span></li>
                        <li><i className="fa fa-angle-down" aria-hidden="true"></i>
                            <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>Tops Images</span> (20)
                            <ul className='second-treeview'>
                                <li className="hoverlist"><i className="fa fa-angle-right" aria-hidden="true"></i>
                                    <span><img src={folderblue} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>Dress Shirts</span>(60)</li>
                                <li><i className="fa fa-angle-down" aria-hidden="true"></i>
                                    <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>T-Shirts</span>(60)

                                    <ul className='second-treeview'>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                                            <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>Blue Shirts</span></li>
                                        <li><i className="fa fa-angle-right" aria-hidden="true"></i>
                                            <span><img src={folder} className="img-fluid" alt="mindreach images" /></span><span className='catgory-name'>White Shirts</span></li>
                                    </ul>

                                </li>
                            </ul>
                        </li>
                    </ul>

                </li>
            </ul>
        </div >
    )
}





export default Treeview