import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

/**
 * Reusable modal component.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.heading - The title or heading of the modal.
 * @param {string} props.message - The content or message of the modal.
 * @returns {JSX.Element} - The rendered modal component.
 */
const MyVerticallyCenteredModal = (props) => {
	const handleOutsideClick = (e) => {
		if (e.target.classList.contains("modal")) {
			// Check if the click occurred on an element with the "modal" class
			props.onHide();
		}
	};
	useEffect(() => {
		// Add event listener to detect clicks outside the modal
		window.addEventListener("mousedown", handleOutsideClick);

		return () => {
			// Remove the event listener when the component unmounts
			window.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);
	return (
		<Modal
			{...props}
			backdrop="static"
			keyboard={false}
			centered
			contentClassName={`${props.modalHeight && props.modalHeight !== "" ? props.modalHeight :"my-modal"}`}
			style={{ zIndex: 9999999999999999999 }}
		>
			<Modal.Header closeButton>
				<Modal.Title
					id="contained-modal-title-vcenter"
					className="section-title fonts"
				>
					{props.heading}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="section-title fonts" style={{ width: "30vw" }}>
				<p
					className={`${
						props.errorMessage &&
						props.errorMessage !== undefined &&
						props.errorMessage.length > 1
							? "err"
							: "success"
					}`}
				>
					{" "}
					{props.message}
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" className="btns" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default MyVerticallyCenteredModal;
