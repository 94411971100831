import React from "react";
import Sidebar from "../../../components/global/Sidebar/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Right from "../../../assets/Images/right.png";
import ProgressBar from "./Components/ProgressBar";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Delete from "../../../assets/Images/delete2.png";
import ConfirmBox from "./Components/ConfirmBox";

const ConfirmOrder = () => {
  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel ">
          <div className="rightbar">
            <ProgressBar role="order" />

            <div className="border-box">
              <h4 className="mt-3">Total Images: 4,720</h4>

              <div className="pr-11">
                <div className="table-responsive custom-new-table table-b mt-4">
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Role</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Background Removal</td>
                        <td>500 </td>
                        <td>$5.00</td>
                        <td>$2,5000.00</td>
                      </tr>
                      <tr>
                        <td>Retouching</td>
                        <td>500 </td>
                        <td>$5.00</td>
                        <td>$2,5000.00</td>
                      </tr>

                      <tr>
                        <td>Resizing</td>
                        <td>500 </td>
                        <td>$5.00</td>
                        <td>$2,5000.00</td>
                      </tr>

                      <tr>
                        <td>Cropping</td>
                        <td>500 </td>
                        <td>$5.00</td>
                        <td>$2,5000.00</td>
                      </tr>

                      <tr>
                        <td>Ghost Mannequin</td>
                        <td>500 </td>
                        <td>$5.00</td>
                        <td>$2,5000.00</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>

          <ConfirmBox />
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;
