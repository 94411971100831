import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Delete2 from '../../../assets/Images/delete2.png'
import Uploadimg from '../../../assets/Images/upload-img.png'
import Delete from '../../../assets/Images/delete.svg'


const Uploadfile = () => {
    return (
        <>
            <div className="container-fluid page-body-wrapper">

                <Sidebar />
                <div className="main-panel ">
                    <div className="rightbar pr-11">
                        <div className="section-title mb-5">
                            <h2>Create Order</h2>
                        </div>

                        <Row>
                            <Col sm={12} lg={9}>
                                <Form className="">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <div className="form-group">
                                            <div class="formbold-mb-5 formbold-file-input">
                                                <input type="file" name="file" id="file" />
                                                <label for="file">
                                                    <div>
                                                        <img src={Uploadimg} className="img-fluid mx-auto d-block" alt="Uploadimg"/>
                                                        <span class="formbold-drop-file"> Drop and Drag to Upload </span>
                                                        <span class="formbold-or"> Or </span>
                                                        <span class="formbold-browse"> Select File </span>
                                                        <p>* Images can be uploaded individually or within folder
                                                        structures</p>
                                                    </div>
                                                    
                                                </label>
                                              
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="d-sm-flex justify-content-between btn-cls">
                                                <div className="">                                                   
                                                    <Button variant="outline-primary" className="btn-light-border"><img src={Delete} className="img-fluid" alt="mindreach images" /></Button>{' '}

                                                </div>

                                                <div className="">
                                                <Button  variant="" className="puple btns mr-3" type="submit">
                                                Save Order
                                               </Button>
                                                    <Button variant="primary" className="btns" type="submit">
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>



                                </Form>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Uploadfile