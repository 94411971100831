import React, { useState, useEffect } from "react";
import "./../../../css/sidebar.css";
import "./../../../css/style.css";

import "./../../../css/responsive.css";

import workspace from "./../../../assets/Images/workspace.svg";
import workspaceBlueIcon from "./../../../assets/Images/workspace_blue.svg";
import dashboard from "./../../../assets/Images/dashboard.svg";
import dashboardBlack from "./../../../assets/Images/dashboardIconBlack.svg";
import { Link } from "react-router-dom";
import togglerRight from "./../../../assets/Images/toggler-menu.svg";
import toggleLeft from "./../../../assets/Images/workspace-toggle.svg";
import {
	VIEW_EDITOR_WORKSPACE,
	MY_WORKSPACE,
	DASHBOARD,
} from "../../../config/routes";
/**
 * Sidebar component that renders a navigation menu.
 * @returns {JSX.Element} The rendered sidebar component.
 */
const EditorSidebar = ({ isMenuOpen, setMenuOpen }) => {
	const [active, setActive] = useState("");
	const [activeTab, setActiveTab] = useState(""); // State to manage active tab
	const [activeSubTab, setActiveSubTab] = useState("");
	const [activeLink, setactiveLink] = useState("");
	const [expandedItems, setExpandedItems] = useState([]);
	const currentRoute = window.location.pathname;

	useEffect(() => {
		const routeTabMapping = {
			[DASHBOARD]: "dashboard",
			[VIEW_EDITOR_WORKSPACE]: "workspace",
			[MY_WORKSPACE]: "workspace",
		};

		const findActiveTab = () => {
			for (const route of Object.keys(routeTabMapping)) {
				if (currentRoute.includes(route)) {
					return routeTabMapping[route];
				}
			}
			return "dashboard"; // Define a default tab if no match is found
		};
		const dropDowncloseonOtherTabClick = () => {
			for (const route of Object.keys(routeTabMapping)) {
				if (currentRoute.includes(DASHBOARD)) {
					document
						.querySelectorAll("#sidebar .nav .nav-item")
						.forEach((element) => {
							const ths = element;
							if (ths.querySelectorAll(".collapse").length > 0) {
								setTimeout(() => {
									ths
										.querySelector(".nav-links")
										.setAttribute("aria-expanded", "false");
									ths.querySelector(".collapse").classList.remove("show");
								}, 10);
							}
						});
					setActiveSubTab("");
					setActive("");
				}
			}
		};
		dropDowncloseonOtherTabClick();

		setActiveTab(findActiveTab());
	}, [currentRoute]);

	useEffect(() => {
		document.querySelectorAll("#sidebar .nav .nav-item").forEach((element) => {
			const ths = element;
			if (ths.querySelectorAll(".collapse").length > 0) {
				setTimeout(() => {
					if (!expandedItems.includes(ths)) {
						ths
							.querySelector(".nav-links")
							.setAttribute("aria-expanded", "false");
						ths.querySelector(".collapse").classList.remove("show");
					}
				}, 10);
			}
		});
	}, [activeLink, expandedItems]);

	const toggleSubMenu = (element) => {
		if (expandedItems.includes(element)) {
			setExpandedItems(expandedItems.filter((item) => item !== element));
		} else {
			setExpandedItems([...expandedItems, element]);
		}
	};

	const toggleMenu = () => {
		// Toggle the menu state
		setMenuOpen(!isMenuOpen);
	};
	useEffect(() => {
		document.querySelectorAll("#sidebar .nav .nav-item").forEach((element) => {
			const ths = element;
			if (ths.querySelectorAll(".collapse").length > 0) {
				setTimeout(() => {
					ths
						.querySelector(".nav-links")
						.setAttribute("aria-expanded", "false");
					ths.querySelector(".collapse").classList.remove("show");
				}, 10);
			}
		});
	}, [activeLink]);
	useEffect(() => {
		// Check current route is includes settings

		if (currentRoute.includes(VIEW_EDITOR_WORKSPACE)) {
			setActive("tab");
		} else if (currentRoute.includes(MY_WORKSPACE)) {
			setActive("tab1");
		} else if (currentRoute.includes(DASHBOARD)) {
			setActive("dashboard");
		}
	}, [currentRoute]);
	return (
		<>
			<nav
				className={`${
					isMenuOpen ? `sidebar` : `sidebar sidebar--close`
				} sidebar-offcanvas`}
				id="sidebar"
			>
				<ul className="nav">
					<li
						className={`nav-item ${activeTab === "dashboard" ? "active" : ""}`}
					>
						<Link
							className="nav-links"
							to={DASHBOARD}
							onClick={() => setActive("tab1")}
						>
							<img
								src={
									activeTab === "dashboard" // If activeTab is "dashboard"
										? dashboard // Render the dashboard variable
										: dashboardBlack
								}
								className="img-fluid menu-icon"
								alt="dashboard"
							/>
							<span
								className={`${isMenuOpen ? `menu-title` : `display--none`}`}
							>
								Dashboard
							</span>
						</Link>
					</li>

					<li
						className={`nav-item ${
							active === "workspace" ? "bg-highlighter " : ""
						}`}
					>
						<Link
							className="nav-links"
							data-bs-toggle="collapse"
							to="#ui-basic"
							aria-expanded="false"
							aria-controls="ui-basic"
							onClick={() => toggleSubMenu("Workspace")}
						>
							<img
								src={activeTab === "workspace" ? workspaceBlueIcon : workspace}
								className="img-fluid menu-icon"
								alt="workspace"
							/>
							<div className={`${isMenuOpen ? `menu-title` : `display--none`}`}>
								<span
									className={
										activeTab === "workspace"
											? "menu-title sub-menu--blue"
											: "menu-title"
									}
								>
									Workspace
								</span>
							</div>
							{isMenuOpen && (
								<i
									className={
										(activeTab === "workspace"
											? "fa  menu-arrow--highlight"
											: "fa menu-arrow") +
										(active === "tab3" ? " fa-angle-up" : " fa-angle-down")
									}
								></i>
							)}
							<i className="mdi mdi-crosshairs-gps menu-icon"></i>
						</Link>
						<div className="collapse" id="ui-basic">
							<ul
								className="nav flex-column sub-menu"
								style={{
									background: "#fff",
								}}
							>
								<li className="nav-item">
									{" "}
									<Link
										className={`nav-links ${active === "tab" ? "active" : ""}`}
										to={VIEW_EDITOR_WORKSPACE}
									>
										Workspace
									</Link>
								</li>
								<li className="nav-item">
									{" "}
									<Link
										className={`nav-links ${active === "tab1" ? "active" : ""}`}
										to={MY_WORKSPACE}
									>
										My Workspace
									</Link>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</nav>
			<div
				className={`${isMenuOpen ? `togler-menu` : `togler-menu--close`}`}
				onClick={() => toggleMenu()}
			>
				<img
					src={isMenuOpen ? togglerRight : toggleLeft}
					className="img-fluid"
					alt="mindreach images"
				/>
			</div>
		</>
	);
};

export default EditorSidebar;
