import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
	Row,
	OverlayTrigger,
	Tooltip,
	Col,
	Form,
	Button,
} from "react-bootstrap";

import EditorOrderDetailsidebar from "./EditorOrderDetailsidebar";
import CoordinatorOrderDetails from "./CoordinatorOrderDetails";
import Autocomplete from "./Components/AutoComplete";
import MergedEditType from "./Components/MergedModal";
import DuplicateImageSidebar from "./Components/DupicateImageModal";
import DuplicateSettings from "./Components/DuplicateSettings";
import DuplicateModalCount from "./Components/DuplicateModalCount";
import StatusModal from "./../../../components/Modal/StatusModal";
import SingleSelectorRightSideBar from "./Components/SingleRightSelector";
import RightSidebarMultiSelect from "./Components/MultipleSelectRightSideBar";
import RenameModal from "./Components/RenameModal";
import DeleteModalConfirmation from "../../../components/Modal/DeleteModal";
import UpdateDuplicateSettingsModal from "./Components/DuplicateSettings/UpdateDuplicateSettings";
import RightSidebar from "./RightSidebar";
import Select from "react-select";
import moment from "moment";

//Importing the images
import Delete from "./../../../assets/Images/delete.svg";
import duplicate from "./../../../assets/Images/coordinator_actions_icon/Duplicate _Final.svg";

import CancelIcon from "./../../../assets/Images/coordinator_actions_icon/cancel_Final.svg";
import MoreIcon from "./../../../assets/Images/coordinator_actions_icon/more-Final.svg";
import LinkIcon from "./../../../assets/Images/coordinator_actions_icon/Link.svg";
import DocsIcon from "./../../../assets/Images/coordinator_actions_icon/Doc.svg";
import Add from "./../../../assets/Images/coordinator_actions_icon/Plus.svg";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import {
	GET_ALL_COORDINATORS,
	ADMIN_GET_COORDINATORS_DETAILS,
	CO_ORDINATOR_LOGGED_TIME,
	CO_ORDINATOR_ASSIGNED_TO_EDITOR,
	CO_ORDINATOR_VIEW_PROFILE_SETTINGS,
	CO_ORDINATOR_DUPLICATE_IMAGE_RENAME,
	CO_ORDINATOR_DELETE_DUPLICATE_IMAGE,
	CO_ORDINATOR_ORIGINAL_IMAGE_RENAME,
	CO_ORDINATOR_FETCH_DUPLICATE_IMAGE,
	IMAGE_COORDINATOR_SUPERVISOR_STATE,
	IMAGE_COORDINATOR_SUPERVISOR_TO_COORDINATOR,
} from "../../../config/apiRoutes.api";
let imgAry = [];
const CoordinatorWorkspace = () => {
	const initial_data = [
		{
			id: "",
			file_format: "",
			height: "",
			width: "",
			dpi: "",
			dpi_type: "",
			color_mode: "",
			alignment: "",
			margin_format: "",
			margin_top: "",
			margin_bottom: "",
			margin_right: "",
			margin_left: "",
		},
	];
	const selectdays = [
		{ label: "Today", value: 355 },
		{ label: "Custom", value: 54 },
	];
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
	// Retrieve the token from session storage
	const token = sessionStorage.getItem("token");
	//Role Checking
	const roleJson = sessionStorage.getItem("role");

	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserRoles = [role !== null ? role.role : "login"];
	const currentUserId = [role !== null ? role.pivot.user_id : ""];

	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();

	// Create a request instance using the token
	const request = client(token);

	// Create a new FormData object for sending data in HTTP requests
	const bodyFormData = new FormData();
	const wrapper = useRef(null);
	//Declarations of state
	const [image_details_view, setImageDetailsView] = useState({});
	const [image_details_view_id, setImageDetailsViewId] = useState("");
	const [image_details_modal, setImageDetailsModal] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [multiple_images_id, setMultipleImagesId] = useState([]);
	const [folders, setFolder] = useState([]);
	const [parentFolderDetails, setParentFolderDetails] = useState(null);
	const [imagesMainFolder, setImageMainFolder] = useState([]);
	const [folderMainFolder, setFolderMainFolder] = useState([]);
	const [folderSubFolder, setFolderSubFolder] = useState([]);
	const [folder_files_sub, setFolderListSub] = useState([]);
	const [list_display, setDisplay] = useState(false);
	const [list_sub_display, setDisplaySub] = useState(false);
	const [folder_list, setFolderList] = useState([]);
	const [folder_name, setFolderName] = useState("");
	const [folder_list_menu, setFolderListMenu] = useState([]);
	const [folder_list_name, setFolderListName] = useState([]);
	const [loader_sub_menu, setLoaderSubMenu] = useState(false);
	const [folders_path, setFoldersPath] = useState([]);
	const [duplicate_image_modal, setDuplicateImageModal] = useState(false);
	const [merged_image_modal, setMergedImageModal] = useState(false);
	const [linked_image_modal, setLinkedImageModal] = useState(false);
	const [loader, setLoader] = useState(false);
	const [clickSelect, setClickSelect] = useState(false);
	const [options, setOptions] = useState([]);
	const [edit_select, setEditorType] = useState("");

	const [batchNumber, setBatchNumber] = useState([]); // To set the batch number
	const [editGroups, setEditGroups] = useState([]); // To set the edit groups
	const [editTypes, setEditTypes] = useState([]); // To set the edit types
	const [images, setImages] = useState([]); // To set the images
	const [folder_path, setFolderPath] = useState([]); // To set the folder
	const [batchId, setBatchID] = useState(""); // To set the batch id
	const [error, setError] = useState("");
	const [sub_folder_open, setSubFolderOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [selectedOption, setSelectedOption] = useState("custom");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDate2, setSelectedDate2] = useState(new Date());
	const [selectImageName, setSelectedImageName] = useState("");
	// const [reassignedModal, setReassignedModal] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [mainImageCount, setMainImageCount] = useState(0);
	const [mainImageSettingsCount, setMainImageSettingsCount] = useState(0);
	const [order_number, setOrderNumber] = useState("");
	//Timer logic
	const [timer, setTimer] = useState(null);
	const [elapsedTime, setElapsedTime] = useState(0);
	const [startTime, setStartTime] = useState(null);
	const [statusNew, setStatusNew] = useState(false);
	const [statusModal, setStatusModal] = useState(false);
	const [image_editor_response, setImageEditorResponse] = useState("");
	const [duplicate_amount, setDuplicateAmount] = useState(initial_data);
	const [amount, setAmount] = useState("");
	const [duplicate_settings, setDuplicateSettings] = useState(false);
	const [imageID, setImageID] = useState("");
	const [setting_profile, setSettingsProfile] = useState([]);
	const [copyAmounts, setCopyAmounts] = useState([]);
	const [renameModalShow, setRenameModalShow] = useState(false);
	const [renameTextDuplicate, setRenameTextDuplicate] = useState("");
	const [imageExtensions, setImageExtensions] = useState("");
	const [imageName, setImageName] = useState("");
	const [renameId, setRenameId] = useState("");
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [originalImageId, setOriginalImageId] = useState("");
	const [updateSettingsModalShow, setShowupdateSettingsModal] = useState(false);
	const [viewDuplicateSettings, setViewDuplicateSettings] = useState([]);
	const [viewUpdateSettingsButton, setUpdateSettingsButtons] = useState(false);
	const [show_delete_by_id, setShowDeleteByID] = useState(false);
	const [hide_list_menu, setHideListMenu] = useState(false);
	const [showBatchLoader, setBatchLoader] = useState(true);
	const [activeBatch, setActiveBatch] = useState(0);
	const [switchState, setSwitchState] = useState(false);
	const [statusState, setStatusState] = useState("");
	const [activeBatchNumber, setActiveBatchNumber] = useState(0);

	const [skipImagetLoad, setSkipImageCall] = useState(true);
	const [selectedImagesID, setSelectedImagesID] = useState([]);

	/**
	 * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} options - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (options) =>
		options.map(({ first_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]
	const getFormattedDate = (dateString) => {
		// Create a new Date object from the date string
		const dateObject = new Date(dateString);
		// Format the date as "day Month year" (e.g., "25 July 2023")
		const options = { day: "numeric", month: "long", year: "numeric" };
		const formattedDate = dateObject.toLocaleDateString(undefined, options);
		return formattedDate;
	};
	// Determine if the button should be disabled
	const isButtonDisabled =
		multiple_images_id.length > 0 || folder_list_name.length > 0;
	const handleDateChange = (date, setSelectedDate) => {
		setSelectedDate(date);
	};
	const date = [
		{ label: "Custom", value: "custom" },
		{ label: "Today", value: "today" },
		{ label: "Current Month", value: "month" },
		{ label: "Current Year", value: "year" },
		{ label: "All", value: "all" },
	];
	let editTypeId = images.map(function (currentObject) {
		return currentObject.id;
	}, {});
	let folderPathSet = folder_list.map(function (currentObject) {
		return currentObject.path;
	}, {});
	let idSelected =
		multiple_images_id !== undefined
			? multiple_images_id
					.filter((id) => !isNaN(id)) // Filter out non-numeric values
					.map((id) => parseInt(id)) // Convert to integers
					.join(",")
			: "";
	let folderPathSelected =
		folderPathSet !== undefined &&
		folderPathSet
			.slice(0, folderPathSet.length)
			.toString()
			.split(/(?=(?:\d{3})+(?:\.|$))/g)
			.join(",")
			.replace(/"/g, "");

	// useEffect(() => {
	// 	if (token) {
	// 		console.log("call the user id ",currentUserId)
	// 		// Fetching coordinators user's  data from the API
	// 		setSelectOptions(currentUserId);
	// 	}
	// }, []);
	const fetchOrder = () => {
		if (token) {
			request
				.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${currentUserId}`)
				.then((response) => {
					setParentFolderDetails(null);
					setFolderName("");
					setBatchNumber(response.data.data.batchNumbers);
					setActiveBatch(response.data.data.batchNumbers[0].id);
					setActiveBatchNumber(response.data.data.batchNumbers[0].batch_number);

					setImages(response.data.data.images);
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					// setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setOrderNumber(response.data.data.order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
					// setFolderName("");
				})
				.catch((err) => console.log(err));
		}
	};
	useEffect(() => {
		if (token && currentUserId !== "") {
			fetchOrder();
		}
	}, [currentUserId !== ""]);
	useEffect(() => {
		if (token && batchId !== "") {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?batch_number=${batchId}`
				)
				.then((response) => {
					setParentFolderDetails(null);
					setFolderName("");

					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setBatchNumber(response.data.data.batchNumbers);
					setImages(response.data.data.images);
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		}
	}, [batchId]);
	useEffect(() => {
		if (token && search !== "") {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?search_key=${search}`
				)

				.then((response) => {
					setParentFolderDetails(null);
					setFolderName("");
					setBatchNumber(response.data.data.batchNumbers);
					const batchNumbers = response.data.data.batchNumbers;
					const filteredBatch = batchNumbers.find(
						(entry) => entry.batch_number === search
					);

					if (filteredBatch) {
						const { id } = filteredBatch;
						setActiveBatch(id);
						setActiveBatchNumber(id);

						setBatchID(id);
						setEditGroups(response.data.data.editGroups);
						setEditTypes(response.data.data.editTypes);
						setImages(response.data.data.images);
						setFolderList(response.data.data.folders);
						setFolder(response.data.data.folders);
						setImageMainFolder(response.data.data.images);
						setFolderMainFolder(response.data.data.folders);
						setMainImageCount(response.data.data.mainImageCount);
						setMainImageSettingsCount(
							response.data.data.mainImageSettingsCount
						);
						setOrderNumber(response.data.data.batchNumbers[0].order_id);
						setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
						setDisplay(false);
						setMultipleImagesId([]);
						setClickSelect(false);
					} else {
						console.log("Batch not found");
						// Handle the case when the batch is not found based on the search criteria
					}
				})
				.catch((err) => console.log(err));
		} else {
			request
				.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${currentUserId}`)
				.then((response) => {
					setParentFolderDetails(null);
					setFolderName("");
					setBatchNumber(response.data.data.batchNumbers);
					setActiveBatch(response.data.data.batchNumbers[0].id);
					setActiveBatchNumber(response.data.data.batchNumbers[0].batch_number);

					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setImages(response.data.data.images);
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
					// setSelectAll(false);
				})
				.catch((err) => console.log(err));
		}
	}, [search, clickSelect]);

	const selectAllImages = () => {
		const allImageIds =
			images !== undefined && images.map((item) => String(item.id));

		const allFolderNames =
			folder_list !== undefined && folder_list.map((items) => items.path);
		// Set the folderListName state to contain all folder names.
		setFolderListName(allFolderNames);

		// Set the foldersPath state to contain all folder names.
		setFoldersPath(allFolderNames);

		setMultipleImagesId(allImageIds);
	};

	// Use useEffect to handle "Select All" checkbox changes
	useEffect(() => {
		if (selectAll) {
			selectAllImages();
		} else {
			setMultipleImagesId([]);
			setFolderListName([]);
			setFoldersPath([]);
			setFolderName("");
			setParentFolderDetails(null);
		}
	}, [selectAll]);

	const handleFolderClick = useCallback(
		(item) => {
			setMultipleImagesId([]);
			setFolderName(item);
			let allImages = document.querySelectorAll(".allimages");
			for (var i = 0; i < allImages.length; i++) {
				allImages[i].style.zIndex = 1;
			}
			// setParentFolderDetails(null);
			if (token) {
				if (parentFolderDetails === null && folder_name === "") {
					request
						.get(
							apiURL +
								ADMIN_GET_COORDINATORS_DETAILS +
								`/${currentUserId}` +
								`?batch_number=${batchId}` +
								`&folder_path=${item}`
						)
						.then((response) => {
							setParentFolderDetails(null);
							setFolderList(response.data.data.folders);
							setFolderListMenu(response.data.data.folders);
							setFolderName(item);
							// setItemList(response.data.data.images);
							setImages(response.data.data.images);
							setFoldersPath([]);
							setEditGroups(response.data.data.editGroups);
							setEditTypes(response.data.data.editTypes);

							setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
							setDisplay(true);

							setDisplaySub(true);
							setMultipleImagesId([]);
							setClickSelect(false);
							setHideListMenu(true);
						})
						.catch((err) => {
							let message =
								typeof err.response !== "undefined"
									? err.response.data.message
									: err.message;
							setError(message);
						});
				} else {
					request
						.get(
							apiURL +
								ADMIN_GET_COORDINATORS_DETAILS +
								`/${currentUserId}` +
								`?batch_number=${batchId}` +
								`&folder_path=${folder_name}/${item}`
						)
						.then((response) => {
							// setSubFolderOpen(true);
							// setFolderListMenu(response.data.data.folders);
							// setFolder(response.data.data.folders)
							setFolderList(response.data.data.folders);
							//  setFolderSubFolder(response.data.data.folders);
							setDisplay(true);

							setImages(response.data.data.images);
							setEditGroups(response.data.data.editGroups);
							setEditTypes(response.data.data.editTypes);

							setParentFolderDetails(null);
							setFolderName(item);
							setMultipleImagesId([]);
							setClickSelect(false);
							setDisplaySub(true);
							setFoldersPath([]);

							// setLoaderSubMenu(true);
						})
						.catch((err) => {
							let message =
								typeof err.response !== "undefined"
									? err.response.data.message
									: err.message;
							setError(message);
						});
				}
			}
		},
		[folder_list, parentFolderDetails, folder_name]
	);

	useEffect(() => {
		// Check if a token is available
		if (token && selectedOption.valueOf === "custom") {
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?date=${moment(selectedDate).format("YYYY-MM-DD")}` +
						`&from_date=${moment(selectedDate).format("YYYY-MM-DD")}` +
						`&to_date=${moment(selectedDate2).format("YYYY-MM-DD")}`
				)
				.then((response) => {
					// Log the response to the console
					setParentFolderDetails(null);
					setFolderList(response.data.data.folders);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);

					setImages(response.data.data.images);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					setFolderName("");
					setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		} else if (token && selectedOption.valueOf === "today") {
			let today = new Date();
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?date=today`
				)
				.then((response) => {
					// Log the response to the console
					setParentFolderDetails(null);
					setFolderName("");
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					// setFolderList(response.data.data.folders);

					setImages(response.data.data.images);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					// setFolderName(item);
					setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		} else if (token && selectedOption.valueOf === "year") {
			let today = new Date();
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?date=year`
				)
				.then((response) => {
					// Log the response to the console

					setParentFolderDetails(null);
					setFolderList(response.data.data.folders);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setImages(response.data.data.images);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					setFolderName("");
					setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		} else if (token && selectedOption.valueOf === "month") {
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?date=month`
				)
				.then((response) => {
					// Log the response to the console
					setParentFolderDetails(null);
					setFolderList(response.data.data.folders);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setImages(response.data.data.images);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					setFolderName("");
					setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		} else if (token && selectedOption.valueOf === "all") {
			if (token) {
				// Make an HTTP GET request to fetch the branch data with the specified page number
				request
					.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${currentUserId}`)
					.then((response) => {
						// Log the response to the console
						setParentFolderDetails(null);
						setFolderName("");
						setFolderList(response.data.data.folders);
						setEditGroups(response.data.data.editGroups);
						setEditTypes(response.data.data.editTypes);
						setImages(response.data.data.images);

						setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
						setDisplay(true);
						// setFolderName(item);
						setFolderListMenu(response.data.data.folders);
						setMultipleImagesId([]);
						setClickSelect(false);
					})
					.catch((err) => console.log(err));
			}
		} else {
			if (token) {
				// Make an HTTP GET request to fetch the branch data with the specified page number
				request
					.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${currentUserId}`)
					.then((response) => {
						// Log the response to the console
						setParentFolderDetails(null);
						setFolderName("");
						setFolderList(response.data.data.folders);
						setEditGroups(response.data.data.editGroups);
						setEditTypes(response.data.data.editTypes);
						setImages(response.data.data.images);

						setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
						setDisplay(true);
						// setFolderName(item);
						setFolderListMenu(response.data.data.folders);
						setMultipleImagesId([]);
						setClickSelect(false);
					})
					.catch((err) => console.log(err));
			}
		}
	}, [selectedDate, selectedDate2, selectedOption, clickSelect]);
	let folderPath = folder_list.map(function (currentObject) {
		return currentObject.path;
	}, {});
	const foldersPathSelected =
		multiple_images_id !== undefined &&
		folders_path
			.slice(0, folderPath.length)
			.toString()
			.split(/(?=(?:\d{3})+(?:\.|$))/g)
			.join(",")
			.replace(/"/g, "");
	const startTimer = () => {
		setTimer(
			setInterval(() => {
				// Increment elapsed time every second
				setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
			}, 1000)
		);
	};

	const stopTimer = () => {
		clearInterval(timer);
	};

	const formatTimeInSeconds = (timeInSeconds) => {
		return timeInSeconds;
	}; // return the time in seconds
	const formatStartTime = (startTime) => {
		const date = new Date(startTime);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const day = date.getDate().toString().padStart(2, "0");
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		const seconds = date.getSeconds().toString().padStart(2, "0");

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};
	const handleCustomerLogged = () => {
		if (token) {
			// Continue with API request
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("batch_number", batchId);
			bodyFormData.append("start_time", formatStartTime(startTime));
			bodyFormData.append("time_spend", formatTimeInSeconds(elapsedTime));

			request
				.post(apiURL + CO_ORDINATOR_LOGGED_TIME, bodyFormData)
				.then((response) => {
					// Set the success from the response
					// setStatus("Successfully Group updated");
					// setLoader(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleAssignedImagesToEditor = (idSelected) => {
		if (token) {
			bodyFormData.append("image_ids", idSelected);
			request
				.post(apiURL + CO_ORDINATOR_ASSIGNED_TO_EDITOR, bodyFormData)
				.then((response) => {
					setStatusModal(response.data.status);
					setImageEditorResponse(
						response.data.status
							? "Image has been assigned for editing."
							: "Oops something went wrong."
					);
					setTimeout(() => {
						setStatusModal(false);
					}, 2000);
					if (response.data.status) {
						request
							.get(
								apiURL +
									ADMIN_GET_COORDINATORS_DETAILS +
									`/${currentUserId}` +
									`?batch_number=${batchId}`
							)
							.then((response) => {
								setParentFolderDetails(null);
								setFolderName("");
								// setEditGroups(response.data.data.editGroups);
								// setEditTypes(response.data.data.editTypes);
								setBatchNumber(response.data.data.batchNumbers);
								setImages(response.data.data.images);
								setFolderList(response.data.data.folders);
								setFolder(response.data.data.folders);
								setImageMainFolder(response.data.data.images);
								setFolderMainFolder(response.data.data.folders);
								setMainImageCount(response.data.data.mainImageCount);
								setMainImageSettingsCount(
									response.data.data.mainImageSettingsCount
								);
								setOrderNumber(response.data.data.batchNumbers[0].order_id);
								setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
								setDisplay(false);
								setMultipleImagesId([]);
								setClickSelect(false);
							})
							.catch((err) => console.log(err));
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		if (!token) {
			return null;
		}
		if (imageID === setting_profile.id && duplicate_image_modal) {
			request
				.get(apiURL + CO_ORDINATOR_FETCH_DUPLICATE_IMAGE + `/${imageID}`)
				.then((response) => {
					setAmount(Number(response.data.duplicationSettingCount));
					setViewDuplicateSettings(response.data.image.duplication_settings);
					setUpdateSettingsButtons(true);
				})
				.catch((err) => console.log(err));
		} else {
			setAmount(0);
			setDuplicateAmount([]);
			// setCopyAmounts([]);
		}
	}, [imageID, duplicate_image_modal, setAmount]);
	const handleChangeDuplicateAmount = (e) => {
		setAmount(e.target.value);
		setUpdateSettingsButtons(false);
	}; // add an object with specific amount given
	/************************Auto logout Logic*****/
	const autoLogout = sessionStorage.getItem("auto-logout");
	useEffect(() => {
		if (autoLogout) {
			handleCustomerLogged();
			stopTimer(); // Stop the timer for the previous card
		}
	}, [autoLogout]);
	/***************End of Auto logout */
	useEffect(() => {
		if (amount !== "") {
			for (let i = 0; i < amount; i++) {
				setDuplicateAmount((prevState) => [
					...prevState,
					{
						id: i,
					},
				]);
			}
		} else {
			setDuplicateAmount([]);
		}
	}, [amount, setDuplicateAmount]);
	const handleFetchProfileSettings = (imageID) => {
		if (token && imageID !== "") {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_PROFILE_SETTINGS + `/${imageID}`)
				.then((response) => {
					setSettingsProfile(response.data.image);
					setEditorType(response.data.image.edit_group_id);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		handleFetchProfileSettings(imageID);
	}, [imageID]);
	const onSubmitDublicateImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", renameId);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					handleFetchProfileSettings(setting_profile.id);
					setRenameModalShow(false);
					//Set the success from the response
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const onClickDuplicateImage = (id, imageName) => {
		setRenameId(id);
		setImageName(imageName);
		// setImageExtensions(imageExtensions);
		setRenameModalShow((val) => !val);
		let allImages = document.querySelectorAll(".allimages");
		for (var i = 0; i < allImages.length; i++) {
			allImages[i].style.zIndex = 1;
		}
	};
	const getImageExtension = (imageName) => {
		const lastDotIndex = imageName.lastIndexOf(".");
		if (lastDotIndex !== -1) {
			const extension = imageName.substring(lastDotIndex + 1);
			return extension;
		}
		return "No extension found";
	};
	const handleDelete = () => {
		if (!token) {
			return null;
		}
		// Make an HTTP POST request to delete the selected duplicate image
		request
			.delete(apiURL + CO_ORDINATOR_DELETE_DUPLICATE_IMAGE + `/${renameId}`)
			.then((response) => {
				// Log the response to the console
				handleFetchProfileSettings(setting_profile.id);

				// Close the delete confirmation modal
				setShowModalDelete(false);
			})
			.catch((err) => console.log(err));
	};
	const handleRenameOriginalImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", originalImageId);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_ORIGINAL_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					handleFetchProfileSettings(originalImageId);
					setRenameModalShow(false);
					//Set the success from the response
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleRenameImage = () => {
		if (renameId === originalImageId) {
			return handleRenameOriginalImage();
		} else {
			onSubmitDublicateImage();
		}
	};

	/**
	 * Handle the select all images state.
	 * @param {Object} e - event Object.
	 */

	const setSelectAllImages = (e) => {
		e.preventDefault();
		const allImageIds = imgAry.map((item) => item.id);
		setMultipleImagesId(allImageIds);
	};

	/**
	 * Handle the keydown event.
	 * @param {Object} e - event Object.
	 */

	const keydownHandler = (e) => {
		if (
			(e.ctrlKey || e.metaKey) &&
			e.which === 65 &&
			!e.target.classList.contains("skip")
		) {
			setSelectAllImages(e);
		}
		return false;
	};
	useEffect(() => {
		window.document.addEventListener("keydown", keydownHandler);
		return () => {
			window.document.removeEventListener("keydown", keydownHandler);
		};
	}, []);

	/**
	 * Handle the keydown event and assign the images to imgAry from state.
	 */
	useEffect(() => {
		imgAry = images;
	}, [images]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			// Check if the Ctrl (for Windows/Linux) or Cmd (for macOS) key is pressed
			const isCtrlKey = event.ctrlKey || event.metaKey;

			// Check if the "A" key is pressed
			if (isCtrlKey && event.key === "a") {
				// Prevent the default browser behavior (e.g., selecting all text)
				event.preventDefault();

				// Toggle the selectAll state
				setSelectAll((prevSelectAll) => !prevSelectAll);
			}
		};

		// Attach the event listener when the component mounts
		document.addEventListener("keydown", handleKeyDown);

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []); // Empty dependency array means this effect runs once when the component mounts

	useEffect(() => {
		if (selectAll) {
			selectAllImages();
		} else {
			// setMultipleImagesId([]);
			setClickSelect(false);
		}
	}, [selectAll]);
	/**
	 * Handles to change the active index
	 */
	const handleResetActiveIndex = useCallback((item, id, batch_number) => {
		setBatchID(item.id);
		setOrderNumber(item.order_id);
		setActiveBatch(id);
		setActiveBatchNumber(batch_number);
		setSkipImageCall(false);
	}, []);

	useEffect(() => {
		if (!token) {
			return; // No token, exit early
		}

		const fetchData = async () => {
			try {
				const response = await request.get(
					apiURL + IMAGE_COORDINATOR_SUPERVISOR_STATE
				);

				if (Number(response.data.status) === 0) {
					setSwitchState(false);
				} else {
					setSwitchState(true);
				}

				setStatusState(response.data.state);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData(); // Call the fetchData function
	}, [token]); // Added token to the dependency array

	useEffect(() => {
		if (!token) {
			return null;
		}
		request
			.post(apiURL + IMAGE_COORDINATOR_SUPERVISOR_TO_COORDINATOR)
			.then((response) => {
				console.log("call the state", response.data);
				// setStatusState(response.data.state);
				// setSwitchState(response.data.status);
			});
	}, [switchState]);
	const statusText = {
		1: "On",
		2: "Pending",
		// Add more status texts as needed
	};

	const getStatusText = (statusState) => {
		return statusText[Number(statusState)] || "Unknown";
	};

	return (
		<>
			<div
				className="container-fluid page-body-wrapper"
				style={{ display: "block" }}
			>
				<div>
					<div className="rightbar">
						<div className="section-title workspace--switch">
							<h2>My Workspace</h2>
							<div className="workspace-form--switch">
								{" "}
								<button
									type="button"
									className={`btn-sm btn-toggle ${switchState ? "active" : ""}`}
									onClick={() => setSwitchState((prevState) => !prevState)}
									aria-pressed={switchState}
									autoComplete="off"
									style={{
										backgroundColor:
											Number(statusState) === 2 && switchState
												? "#bfbf16a6"
												: statusState === 1 && switchState
												? "#49cc90"
												: "red",
									}}
								>
									<div className="handle"></div>
								</button>
								<style>
									{`
										.btn-toggle.btn-sm:after {
											content: "${getStatusText(statusState)}";
											left: 1.88rem;
											opacity: 0.5;
										}
									`}
								</style>
							</div>
							<div className="mr-auto">
								{" "}
								{/* {getStatusButton(Number(statusState))} */}
							</div>
						</div>

						{/* <Row>
							<Col lg={6} sm={12}>
								<Form className="workspaceform">
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
										style={{ width: "37rem" }}
									>
										<div className="col-lg-6">
											<div className="d-sm-flex align-itemd-center  col-gap-14 align-items-center">
												<div className="form-group">
													<Form.Label className="label-div">
														Choose Staff
													</Form.Label>
												</div>

												<div className="form-group flex-grow-1">
													<Select
														options={selectLabel(options)}
														onChange={(e) => setSelectOptions(e.value)}
													/>
												</div>
											</div>
										</div>
									</Form.Group>
								</Form>
							</Col>
						</Row> */}

						<div className="workbody">
							<Form className="mb-3">
								{/* Search and filter form */}

								{folders_path.length !== 0 &&
									folders_path.length &&
									!multiple_images_id.length && (
										<Row className="align-items-center mb-3 exclude-click">
											<Col lg={4} sm={4} xs={4}>
												<div className="exclude-click">
													<div className="row-multiple-select--coordinator">
														<>
															<div>
																<div
																	// onClick={() => handleBulkConfirmation(setShow)}
																	// onClick={() => handleFolderDelete()}
																	style={{
																		justifyContent: "center",
																		marginTop: "9px",
																		cursor: "pointer",
																		margin: "9px",
																		padding: "6px 0px 9px 9px",
																		position: "absolute",
																	}}
																	className="img-fluid--icons"
																	// className="image-icons-holiday"
																>
																	<img src={Delete} alt="mindreach images" />
																</div>
															</div>

															<OverlayTrigger
																placement="right"
																delay={{ show: 250, hide: 400 }}
																overlay={renderTooltip("Duplicate")}
															>
																<div
																	style={{
																		justifyContent: "center",
																		margin: "9px 0px 0px 79px",
																		cursor: "pointer",
																		position: "absolute",
																		zIndex: "5",
																		// margin: "9px",
																		padding: "6px 0px 9px 9px",
																		cursor: "pointer",
																		// transition:
																		// 	"background-color 0.2s ease-in-out",
																	}}
																	className="img-fluid--icons"
																	// onClick={() => handleFolderDuplicate()}
																	// onClick={() => handleBulkConfirmation(setDuplicate)}
																>
																	<img
																		src={duplicate}
																		// className="img-fluid--icons"
																		alt="mindreach images"
																	/>
																	{/* </div> */}
																</div>
															</OverlayTrigger>

															{/* <Dropdown>
														<div
															onClick={() => setDropDown((val) => !val)}
															className="img-fluid--icons"
															style={{
																justifyContent: "center",
																cursor: "pointer",

																cursor: "pointer",
																position: "absolute",
																margin: "18px 0px 0px 343px",
																height: "36px",
																padding: "6px 27px 11px 11px",
															}}
														>
															<img src={MoreIcon} alt="mindreach images" />
														</div>
														{dropDown && (
															<div className="dropdown">
																<div className="dropdown-list">
																	<ul>
																		<li class="menunews">
																			<a>
																				<span>Make as Inactive</span>
																			</a>
																			<a>
																				<span>Make as Active</span>
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														)}
													</Dropdown> */}
															{/* Dropdown*/}

															<div class="dropdown">
																<div
																	// onClick={() => setDropDown((val) => !val)}
																	className="img-fluid--icons"
																	style={{
																		justifyContent: "center",
																		cursor: "pointer",

																		cursor: "pointer",
																		position: "absolute",
																		margin: "12px 0px 0px 350px",
																		height: "36px",
																		padding: "6px 27px 9px 11px",
																	}}
																	id="dropdownMenuButton"
																	data-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false"
																>
																	<img src={MoreIcon} alt="mindreach images" />
																</div>
																<div
																	class="dropdown-menu"
																	aria-labelledby="dropdownMenuButton"
																>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		// onClick={() => handleBulkConfirmation(setActivate)}
																	>
																		Make as Active
																	</a>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		// onClick={() =>
																		// 	handleBulkConfirmation(setDeactivate)
																		// }
																	>
																		Make as Inactive
																	</a>
																</div>
															</div>

															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 311px",
																	position: "absolute",
																	padding: "6px 0px 9px 9px",
																	cursor: "pointer",
																}}
															>
																<h3 className="row-multiple-select--h3">
																	{" "}
																	{folders_path.length} Folder(s) Selected
																</h3>
															</div>
															<div
																style={{
																	margin: "9px 0px 0px 450px",
																	cursor: "pointer",
																	position: "absolute",
																	// margin: "9px 9px 9px 12px",
																	padding: "6px 6px 9px 9px",
																}}
																className="img-fluid--icons"
																onClick={() => {
																	setMultipleImagesId([]);
																	// setFolderNameFirst([]);
																	setFolderListName([]);

																	// setFolderSelect([]);

																	setFoldersPath([]);
																	setClickSelect(false);
																	// setReassignedModal(false);
																	setSelectAll(false);
																}}
															>
																<img
																	src={CancelIcon}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
															</div>
														</>
													</div>
												</div>
											</Col>
										</Row>
									)}
								{multiple_images_id.length === 1 && (
									<Row className="align-items-center mb-3 exclude-click">
										<Col lg={4} sm={4} xs={4}>
											<div className="exclude-click">
												<div className="row-multiple-select--coordinator">
													<>
														<div>
															<div
																onClick={() => {
																	setShowDeleteByID(true);
																	let allImages =
																		document.querySelectorAll(".allimages");
																	for (var i = 0; i < allImages.length; i++) {
																		allImages[i].style.zIndex = 1;
																	}
																}}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 9px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
																// className="image-icons-holiday"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</div>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",
																	// margin: "9px",
																	padding: "6px 0px 9px 9px",
																	cursor: "pointer",
																	// transition:
																	// 	"background-color 0.2s ease-in-out",
																}}
																className="img-fluid--icons"
																onClick={() => {
																	setDuplicateImageModal(true);
																	let allImages =
																		document.querySelectorAll(".allimages");
																	for (var i = 0; i < allImages.length; i++) {
																		allImages[i].style.zIndex = 1;
																	}
																}}
																// onClick={() => handleBulkConfirmation(setDuplicate)}
															>
																<img
																	src={duplicate}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
																{/* </div> */}
															</div>
														</OverlayTrigger>

														{/* <Dropdown>
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															{dropDown && (
																<div className="dropdown">
																	<div className="dropdown-list">
																		<ul>
																			<li class="menunews">
																				<a>
																					<span>Make as Inactive</span>
																				</a>
																				<a>
																					<span>Make as Active</span>
																				</a>
																			</li>
																		</ul>
																	</div>
																</div>
															)}
														</Dropdown> */}
														{/* Dropdown*/}
														<div class="dropdown">
															<div
																// onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "12px 0px 0px 350px",
																	height: "36px",
																	padding: "6px 27px 9px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	// onClick={() => handleBulkConfirmation(setActivate)}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	// onClick={() =>
																	// 	handleBulkConfirmation(setDeactivate)
																	// }
																>
																	Make as Inactive
																</a>
															</div>
														</div>
														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 311px",
																position: "absolute",
																padding: "6px 0px 9px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{multiple_images_id.length} File(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 450px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 6px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={() => {
																setMultipleImagesId([]);
																setFolderListName([]);
																setFoldersPath([]);
																setClickSelect(false);
																setDuplicateImageModal(false);
																setMergedImageModal(false);
																setLinkedImageModal(false);
															}}
														>
															<img
																src={CancelIcon}
																// className="img-fluid--icons"
																alt="mindreach images"
															/>
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{multiple_images_id.length > 1 && (
									<Row className="align-items-center mb-3 exclude-click">
										<Col lg={4} sm={4} xs={4}>
											<div className="exclude-click">
												<div className="row-multiple-select--coordinator">
													<>
														<div
														//  onClick={() => handleBulkDelete()}
														>
															<div
																// onClick={() => handleBulkConfirmation(setShow)}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 9px 9px",
																	zIndex: "5",
																	position: "absolute",
																	cursor: "pointer",
																}}
																className="img-fluid--icons"
																// className="image-icons-holiday"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</div>

														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",
																	// margin: "9px",
																	padding: "6px 0px 9px 9px",
																	cursor: "pointer",
																	// transition:
																	// 	"background-color 0.2s ease-in-out",
																}}
																className="img-fluid--icons"
																onClick={() => {
																	setDuplicateImageModal(true);
																	setMergedImageModal(false);
																	setLinkedImageModal(false);
																	let allImages =
																		document.querySelectorAll(".allimages");
																	for (var i = 0; i < allImages.length; i++) {
																		allImages[i].style.zIndex = 1;
																	}
																}}
															>
																<img
																	src={duplicate}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
																{/* </div> */}
															</div>
														</OverlayTrigger>

														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Merged")}
														>
															<div
																onClick={() => {
																	setDuplicateImageModal(false);
																	setMergedImageModal(true);
																	setLinkedImageModal(false);
																}}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",
																	position: "absolute",
																	margin: "10px 0px 0px 152px",
																	height: "36px",
																	padding: "6px 27px 9px 7px",
																}}
																// className="image-icons-holiday"
															>
																<img src={DocsIcon} alt="mindreach images" />
															</div>
														</OverlayTrigger>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Linked")}
														>
															<div
																onClick={() => {
																	setDuplicateImageModal(false);
																	setMergedImageModal(false);
																	setLinkedImageModal(true);
																}}
																// onClick={() => handleBulkConfirmation(setShow)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",
																	position: "absolute",
																	margin: "10px 0px 0px 228px",
																	height: "36px",
																	padding: "6px 27px 9px 11px",
																}}
															>
																<img src={LinkIcon} alt="mindreach images" />
															</div>
														</OverlayTrigger>

														{/* Dropdown*/}

														<div class="dropdown">
															<div
																// onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "10px 0px 0px 645px",
																	height: "36px",
																	padding: "6px 27px 9px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	// onClick={() => handleBulkConfirmation(setActivate)}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	// onClick={() =>
																	// 	handleBulkConfirmation(setDeactivate)
																	// }
																>
																	Make as Inactive
																</a>
															</div>
														</div>

														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 378px",
																position: "absolute",
																padding: "6px 0px 9px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{multiple_images_id.length} File(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 508px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 6px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={() => {
																setMultipleImagesId([]);
																// setFolderNameFirst([]);
																setFolderListName([]);
																// setFolderSelect([]);
																setFoldersPath([]);
																setClickSelect(false);
																setMergedImageModal(false);
																setLinkedImageModal(false);
																setDuplicateImageModal(false);
															}}
														>
															<img
																src={CancelIcon}
																// className="img-fluid--icons"
																alt="mindreach images"
															/>
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{/* Image settings and sidebar */}
								{!multiple_images_id.length && !folders_path.length && (
									<Row className="align-items-center mb-3">
										<Col lg={6} sm={12}>
											<div className="d-sm-flex flex-sm-wrap col-gap-14 align-items-center">
												<div className="flex-fill">
													<div
														className="form-group"
														// style={{ width: "12rem" }}
														// styles={ width: "18rem!important" }
													>
														{/* <Select
															options={selectdays}
															defaultValue={{ label: "Today", value: 355 }}
														/> */}
														<Select
															value={selectedOption}
															onChange={(e) => setSelectedOption(e)}
															options={date}
															// onClick={() => {
															// 	setSelectOptions("");
															// }}
														/>
														{currentUserId !== "" &&
															selectedOption.value === "custom" && (
																<div className="d-flex mt-2 date-picker--container ">
																	<div>
																		<Form.Control
																			type="date"
																			value={moment(selectedDate).format(
																				"YYYY-MM-DD"
																			)}
																			onChange={(e) =>
																				handleDateChange(
																					new Date(e.target.value),
																					setSelectedDate
																				)
																			}
																		/>
																	</div>

																	<div style={{ marginTop: "5px" }}>
																		<Form.Control
																			type="date"
																			value={moment(selectedDate2).format(
																				"YYYY-MM-DD"
																			)}
																			onChange={(e) =>
																				handleDateChange(
																					new Date(e.target.value),
																					setSelectedDate2
																				)
																			}
																		/>
																	</div>
																</div>
															)}
													</div>
												</div>
												<div
													className={
														selectedOption.value === "custom"
															? `flex-grow-1 negative-margin--container`
															: `flex-grow-1 `
													}
												>
													<div className="form-group">
														<Autocomplete
															suggestions={batchNumber}
															setSearch={setSearch}
															setImageDetailsView={setImageDetailsView}
															setImageDetailsViewId={setImageDetailsViewId}
															setImageDetailsModal={setImageDetailsModal}
															isShow={isShow}
															setIsShow={setIsShow}
														/>
													</div>
												</div>
											</div>
										</Col>
										<Col lg={6} sm={12}>
											<Form.Group
												className="ml-4"
												controlId="formBasicCheckbox"
											>
												<div
													className={
														selectedOption.value === "custom"
															? `d-sm-flex col-gap-20 align-items-center negative-margin--container`
															: `d-sm-flex col-gap-20 align-items-center`
													}
												>
													<div
														className={
															selectedOption.value === "custom"
																? `form-group mt--10px`
																: `form-group `
														}
													>
														<div className="d-flex">
															<input
																type="checkbox"
																label="Select All"
																checked={!!selectAll}
																onClick={() => {
																	setSelectAll((val) => !val);
																	selectAllImages();
																}}
															/>
															<span className="co-ordinator-super-admin--selectAll">
																Select All
															</span>
														</div>
													</div>
													<div
														className={
															selectedOption.value === "custom"
																? `w-25 mt--10px`
																: `w-25 `
														}
													>
														{/* <div className="form-group ml-2 box exclude-click">
														<Button
															variant="primary"
															className="btns"
															disabled={isButtonDisabled ? false : true}
															onClick={() => setReassignedModal((val) => !val)}
														>
															Reassign
														</Button>{" "}
													</div> */}
													</div>
												</div>
											</Form.Group>
										</Col>

										<Col sm={2}></Col>
									</Row>
								)}

								{multiple_images_id.length !== 0 && statusNew && (
									<div className="co-ordinating--assigned-to-editor ">
										<div className="form-group ml-2 box exclude-click">
											<Button
												variant="primary"
												className="btns"
												onClick={() => handleAssignedImagesToEditor(idSelected)}
											>
												Assigned to Editor
											</Button>{" "}
										</div>
									</div>
								)}
							</Form>
							<div className="container">
								<Row className="custom-row-workspace " ref={wrapper}>
									<Col sm={2} className="customcol-2 pl-0">
										<div className="editororderdetail">
											<EditorOrderDetailsidebar
												batchNumber={batchNumber}
												setBatchID={setBatchID}
												setOrderNumber={setOrderNumber}
												setFolderName={setFolderName}
												timer={timer}
												setTimer={setTimer}
												elapsedTime={elapsedTime}
												setElapsedTime={setElapsedTime}
												startTimer={startTimer}
												stopTimer={stopTimer}
												startTime={startTime}
												setStartTime={setStartTime}
												handleCustomerLogged={handleCustomerLogged}
												show={showBatchLoader}
												setSelectedImagesID={setImageID}
												resetActiveIndex={handleResetActiveIndex}
												activeIndex={activeBatch}
											/>
										</div>
									</Col>

									<Col sm={7} className="pl-0">
										<CoordinatorOrderDetails
											mainImageCount={mainImageCount}
											mainImageSettingsCount={mainImageSettingsCount}
											idSelected={idSelected}
											selectImageName={selectImageName}
											editTypeId={editTypeId}
											token={token}
											images={images}
											folder_path={folder_path}
											folder_list={folder_list}
											handleFolderClick={handleFolderClick}
											multiple_images_id={multiple_images_id}
											setMultipleImagesId={setMultipleImagesId}
											setFoldersPath={setFoldersPath}
											folders_path={folders_path}
											folder_name={folder_name}
											setFolderListName={setFolderListName}
											// setReassignedModal={setReassignedModal}
											setSelectAll={setSelectAll}
											wrapper={wrapper}
											folderPathSelected={folderPathSelected}
											setMergedImageModal={setMergedImageModal}
											setDuplicateImageModal={setDuplicateImageModal}
											setStatusNew={setStatusNew}
											statusNew={statusNew}
											editTypes={editTypes}
											editGroups={editGroups}
											setClickSelect={setClickSelect}
											customer_id={currentUserId}
											setEditorType={setEditorType}
											setDuplicateAmount={setDuplicateAmount}
											setAmount={setAmount}
											setImageID={setImageID}
											imageID={imageID}
											setting_profile={setting_profile}
											handleFetchProfileSettings={handleFetchProfileSettings}
											setSelectedImageName={setSelectedImageName}
											setOriginalImageId={setOriginalImageId}
											show_delete_by_id={show_delete_by_id}
											setShowDeleteByID={setShowDeleteByID}
											fetchOrder={fetchOrder}
											setDisplaySub={setDisplaySub}
											setParentFolderDetails={setParentFolderDetails}
											setFolderName={setFolderName}
											setBatchNumber={setBatchNumber}
											setImages={setImages}
											setFolderList={setFolderList}
											setFolder={setFolder}
											setImageMainFolder={setImageMainFolder}
											setFolderMainFolder={setFolderMainFolder}
											setMainImageCount={setMainImageCount}
											setMainImageSettingsCount={setMainImageSettingsCount}
											setOrderNumber={setOrderNumber}
											setSubFolderOpen={setSubFolderOpen}
											setDisplay={setDisplay}
											setClickSelectParent={setClickSelect}
											setImageEditorResponse={setImageEditorResponse}
											setStatusModal={setStatusModal}
											batchId={batchId}
										/>
									</Col>
									<Col sm={2} className="exclude-click">
										{!merged_image_modal &&
											!duplicate_image_modal &&
											multiple_images_id.length === 0 && (
												<RightSidebar
													mainImageCount={mainImageCount}
													mainImageSettingsCount={mainImageSettingsCount}
													folder_path={folder_path}
													setImages={setImages}
													imagesMainFolder={imagesMainFolder}
													folderMainFolder={folderMainFolder}
													folderSubFolder={folderSubFolder}
													setFolder={setFolder}
													setParentFolderDetails={setParentFolderDetails}
													parentFolderDetails={parentFolderDetails}
													setFolderListSub={setFolderListSub}
													folder_files_sub={folder_files_sub}
													setDisplay={setDisplay}
													list_display={list_display}
													folder_list={folder_list}
													folders={folders}
													setFolderList={setFolderList}
													folder_name={folder_name}
													setFolderName={setFolderName}
													setDisplaySub={setDisplaySub}
													list_sub_display={list_sub_display}
													setFolderListMenu={setFolderListMenu}
													folder_list_menu={folder_list_menu}
													loader_sub_menu={loader_sub_menu}
													setLoaderSubMenu={setLoaderSubMenu}
													clickSelect={clickSelect}
													setClickSelect={setClickSelect}
													selectOptions={currentUserId}
													token={token}
													batchId={batchId}
													setEditGroups={setEditGroups}
													setEditTypes={setEditTypes}
													hide_list_menu={hide_list_menu}
													setHideListMenu={setHideListMenu}
													// list_sub_display={list_sub_display}
													// setDisplaySub={setDisplaySub}
												/>
											)}
										{multiple_images_id.length > 1 &&
											merged_image_modal &&
											!duplicate_image_modal && (
												<MergedEditType
													// setDuplicateImageModal={setDuplicateImageModal}
													setMultipleImagesId={setMultipleImagesId}
													setClickSelect={setClickSelect}
													token={token}
													idSelected={idSelected}
													setMergedImageModal={setMergedImageModal}
													images={images}
													multiple_images_id={multiple_images_id}
													setLoader={setLoader}
													loader={loader}
													customer_id={currentUserId}
												/>
											)}
										{multiple_images_id.length !== 0 &&
											duplicate_image_modal &&
											!merged_image_modal && (
												<DuplicateModalCount
													show={duplicate_image_modal}
													onHide={() => {
														setDuplicateImageModal(false);
													}}
													setDuplicateSettings={setDuplicateSettings}
													setDuplicateImageModal={setDuplicateImageModal}
													handleChange={handleChangeDuplicateAmount}
													duplicate_amount={duplicate_amount}
													setting_profile={setting_profile}
													token={token}
													customer_id={currentUserId}
													idSelected={idSelected}
													handleFetchProfileSettings={
														handleFetchProfileSettings
													}
													copyAmounts={copyAmounts}
													setCopyAmounts={setCopyAmounts}
													amount={amount}
													setShowupdateSettingsModal={
														setShowupdateSettingsModal
													}
													viewUpdateSettingsButton={viewUpdateSettingsButton}
												/>
											)}
										{duplicate_settings &&
											!merged_image_modal &&
											!updateSettingsModalShow && (
												<DuplicateSettings
													show={duplicate_settings}
													onHide={() => {
														setDuplicateSettings(false);
													}}
													duplicate_amount={duplicate_amount}
													setting_profile={setting_profile}
													selectImageName={selectImageName}
													token={token}
													customer_id={currentUserId}
													setEditorType={setEditorType}
													edit_select={edit_select}
													editTypes={editTypes}
													editGroups={editGroups}
													idSelected={idSelected}
													setMultipleImagesId={setMultipleImagesId}
													setClickSelect={setClickSelect}
													setSelectAll={setSelectAll}
													copyAmounts={copyAmounts}
													setCopyAmounts={setCopyAmounts}
													setDuplicateAmount={setDuplicateAmount}
													setDuplicateSettings={setDuplicateSettings}
													imageID={imageID}
												/>
											)}
										{updateSettingsModalShow && !merged_image_modal && (
											<UpdateDuplicateSettingsModal
												show={updateSettingsModalShow}
												onHide={() => {
													setShowupdateSettingsModal(false);
												}}
												duplicate_amount={duplicate_amount}
												setting_profile={setting_profile}
												selectImageName={selectImageName}
												token={token}
												customer_id={currentUserId}
												setEditorType={setEditorType}
												edit_select={edit_select}
												editTypes={editTypes}
												editGroups={editGroups}
												idSelected={idSelected}
												setMultipleImagesId={setMultipleImagesId}
												setClickSelect={setClickSelect}
												setSelectAll={setSelectAll}
												copyAmounts={copyAmounts}
												setCopyAmounts={setCopyAmounts}
												setDuplicateAmount={setDuplicateAmount}
												setDuplicateSettings={setDuplicateSettings}
												viewDuplicateSettings={viewDuplicateSettings}
												setViewDuplicateSettings={setViewDuplicateSettings}
											/>
										)}

										{multiple_images_id.length === 1 && !merged_image_modal && (
											<SingleSelectorRightSideBar
												images={images}
												editTypes={editTypes}
												editGroups={editGroups}
												setMultipleImagesId={setMultipleImagesId}
												selectImageName={selectImageName}
												token={token}
												idSelected={idSelected}
												setClickSelect={setClickSelect}
												setSelectAll={setSelectAll}
												setFolderListName={setFolderListName}
												setFoldersPath={setFolderPath}
												setMergedImageModal={setMergedImageModal}
												setDuplicateImageModal={setDuplicateImageModal}
												customer_id={currentUserId}
												setEditorType={setEditorType}
												edit_select={edit_select}
												setting_profile={setting_profile}
												setRenameModalShow={setRenameModalShow}
												renameModalShow={renameModalShow}
												renameTextDuplicate={renameTextDuplicate}
												setRenameTextDuplicate={setRenameTextDuplicate}
												setImageExtensions={setImageExtensions}
												imageExtensions={imageExtensions}
												setImageName={setImageName}
												imageName={imageName}
												setRenameId={setRenameId}
												renameId={renameId}
												onClickDuplicateImage={onClickDuplicateImage}
												setShowModalDelete={setShowModalDelete}
												showModalDelete={showModalDelete}
												getImageExtension={getImageExtension}
												handleFetchProfileSettings={handleFetchProfileSettings}
												imageID={imageID}

												// setFolderSelect={setFolderSelect}
												// fetchCheckoutPrice={fetchCheckoutPrice}
											/>
										)}
										{multiple_images_id.length > 1 &&
											!!!duplicate_image_modal &&
											!merged_image_modal &&
											!linked_image_modal && (
												<RightSidebarMultiSelect
													multiple_images_id={multiple_images_id}
													editTypes={editTypes}
													editGroups={editGroups}
													setMultipleImagesId={setMultipleImagesId}
													selectImageName={selectImageName}
													token={token}
													idSelected={idSelected}
													setClickSelect={setClickSelect}
													customer_id={currentUserId}
													setSelectAll={setSelectAll}

													// fetchCheckoutPrice={fetchCheckoutPrice}
												/>
											)}
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</div>
				<StatusModal
					show={statusModal}
					onHide={() => {
						setStatusModal(false);
					}}
					status={image_editor_response}
					title={"Assigned Image To Editor"}
					hideCancel={true}
				/>
				<RenameModal
					show={renameModalShow}
					onHide={() => {
						setRenameModalShow(false);
					}}
					title="Rename"
					text={renameTextDuplicate}
					setRenameTextDuplicate={setRenameTextDuplicate}
					imageName={imageName}
					setImageName={setImageName}
					imageExtensions={imageExtensions}
					handleRename={handleRenameImage}
				/>
				<DeleteModalConfirmation
					show={showModalDelete}
					onHide={() => {
						setShowModalDelete(false);
					}}
					branch={"image"}
					name={imageName}
					title={"Delete Image"}
					handleConfirm={() => handleDelete()}
				/>
			</div>
		</>
	);
};

export default CoordinatorWorkspace;
