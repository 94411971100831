import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import User from '../Staff/User';



const SupervisorStaff = () => {

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="rightbar pr-11">
            <div className="section-title">
              <h2>Staffs - Coordinator</h2>
            </div>
            <div className="d-flex justify-content-end col-gap12 align-items-center">
              <div className="">

              </div>

            </div>
            <User />


          </div>
        </div>
      </div>
    </>
  )
}

export default SupervisorStaff