import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";

import { apiURL } from "../../../data/filepath";
import { client } from "../../../data/API/GobalClientAxiosRequest";
import {
	BRANCHES_LIST,
	BRANCHES_SHIFT_BYBRANCHID,
	ADD_HOLIDAY,
} from "../../../config/apiRoutes.api";
import { HOIDAY_LIST_PAGE } from "../../../config/routes";

import MyVerticallyCenteredModal from "../../../components/Modal";

const AddHoliday = () => {
	const navigate = useNavigate();
	const [branches, setBranchesData] = useState([]);
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const [modalShow, setModalShow] = React.useState(false);
	const [modalShowTimeZone, setModalShowTimeZone] = useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [shift, setShift] = useState([]);
	const [selectBranch, setBranch] = useState(0);
	const [selectShiftId, setSelectShiftLabel] = useState(0);
	const [status, setStatus] = useState("");
	const [date, setDate] = useState("");
	const [end_date, setEndDate] = useState("");
	const selectLabel = (branches) =>
		branches.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	const selectShiftLabel = (shift) =>
		shift.map(({ shift_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of shift array as label and value return a new array of object like [{value:"",label:""}]

	const handleSubmit = (event) => {
		event.preventDefault();
		if (token) {
			const request = client(token);

			bodyFormData.append("holiday_name", name);
			bodyFormData.append("branch_id", selectBranch);
			bodyFormData.append("description", description);
			bodyFormData.append("shift_id", selectShiftId);
			bodyFormData.append("from_date", date);
			bodyFormData.append("end_date", end_date);
			request
				.post(apiURL + ADD_HOLIDAY, bodyFormData)
				.then((response) => {
					console.log("add", response);
					setStatus("Success");
					setSubmitStatusMessage(response.data.message);
					setModalShow(true);
				})
				.catch((err) => {
					setStatus("Error");
					setSubmitStatusMessage(err);
				});
		}
	};
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_LIST)
				.then((response) => {
					console.log(response);
					setBranchesData(response.data[0].branches);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_SHIFT_BYBRANCHID + `/${selectBranch}`)
				.then((response) => {
					console.log("shift", response);
					setShift(response.data.shifts);
				})
				.catch((err) => console.log(err));
		}
	}, [selectBranch]);
	console.log("brancheName", selectLabel(branches));
	return (
		<div>
			<div className="rightbar pr-11">
				<div className="section-title">
					<h2>Add Holiday</h2>
				</div>
				<Row>
					<Col sm={9} lg={5}>
						<Form>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Name</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="text"
												placeholder=""
												autoFocus
												autoComplete="off"
												onChange={(e) => setName(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group ">
									<Row>
										<Form.Label className="col-sm-3">Description </Form.Label>
										<Col sm={9}>
											<Form.Control
												as="textarea"
												placeholder="Write Description..."
												style={{ height: "148px" }}
												onChange={(e) => setDescription(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Branch</Form.Label>
										<Col sm={9}>
											<Select
												onChange={(e) => {
													setBranch(e.value);
													setSubmitStatusMessage(e.time_zone);
													setModalShowTimeZone(true);
													setStatus("Time Zone");
												}}
												options={selectLabel(branches)}
												placeholder={"Select Branch"}
												maxMenuHeight="120px"
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Timezone</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="text"
												placeholder=""
												autoFocus
												value={submitStatusMessage}
												disabled
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Shift(s)</Form.Label>
										<Col sm={9}>
											<Select
												onChange={(e) => setSelectShiftLabel(e.value)}
												options={selectShiftLabel(shift)}
												placeholder={"Select Shifts"}
												maxMenuHeight="120px"
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">From Date</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="date"
												placeholder=""
												autoFocus
												onChange={(e) => setDate(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">To Date</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="date"
												placeholder=""
												autoFocus
												onChange={(e) => setEndDate(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
										<div className="">
											<Button
												variant=""
												className="puple btns mr-3"
												type="submit"
												onClick={() => navigate(HOIDAY_LIST_PAGE)}
											>
												Cancel
											</Button>
											<Button
												variant="primary"
												className="btns"
												type="submit"
												onClick={(e) => handleSubmit(e)}
											>
												Save
											</Button>
										</div>
									</div>
								</div>
							</Form.Group>
						</Form>
					</Col>
				</Row>
			</div>
			<MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
					navigate(HOIDAY_LIST_PAGE);
				}}
				heading={status}
				message={submitStatusMessage}
				className="modal-body-body--success"
			/>
			{/* <MyVerticallyCenteredModal
				show={modalShowTimeZone}
				onHide={() => {
					setModalShowTimeZone(false);
				}}
				heading={status}
				message={submitStatusMessage}
				className="modal-body-body--success"
			/> */}
		</div>
	);
};
export default AddHoliday;
