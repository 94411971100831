import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import Alert from "../../../components/Alert";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import MyVerticallyCenteredModal from "../../../components/Modal";
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import { VIEW_CUSTOMER, UPDATE_CUSTOMER } from "../../../config/apiRoutes.api";
import { CUSTOMERS_DETAILS } from "../../../config/routes";

const AddBranch = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [customerId, setCustomerId] = useState("");
	const [name, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [companyAddress, setCompanyAddress] = useState("");
	const [userName, setUserName] = useState("");
	const [companyWesite, setCompanyWebsite] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [customerType, setCustomerType] = useState("");
	const [modalShow, setModalShow] = React.useState(false);
	const [addResponse, setAddResponse] = useState("");
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const customerTypes = [
		{ role: "Non Enterprice", id: "non-enterprice" },
		{ role: "Enterprice", id: "enterprice" },
	];
	/**
	 * fiterSet- This function find the selected branch together.
	 * @param {array} x - The array of collection to search.
	 * @param {id} y - The id of selected element.
	 */
	const fiterSet = (array, id) => {
		return array.filter((itemInArray) => itemInArray.id === id);
	};
	/**
	 * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} customerTypes - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (customerTypes) =>
		customerTypes.map(({ role: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]

	const validation = (email) => {
		const result =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return result.test(String(email).toLowerCase());
	}; //validated email using regex

	const validateMobile = (mobilenumber) => {
		let regmm = "^([0|+[0-9]{1,5})?([7-9][0-9]{9})$";
		let regmob = new RegExp(regmm);
		if (regmob.test(mobilenumber)) {
			return true;
		} else {
			return false;
		}
	}; //validated phone using regex

	const handleSubmit = (event) => {
		if (token) {
			const request = client(token);
			event.preventDefault();
			bodyFormData.append("id", customerId);
			bodyFormData.append("full_name", name);
			bodyFormData.append("email", email);
			bodyFormData.append("company_name", companyName);
			bodyFormData.append("company_address", companyAddress);
			bodyFormData.append("company_website", companyWesite);
			bodyFormData.append("phone_number", phoneNumber);
			bodyFormData.append("customer_type", customerType);
			request
				.post(apiURL + UPDATE_CUSTOMER + `/${id}`, bodyFormData)
				.then((response) => {
					console.log(response);
					setAddResponse(response.data.message);
					setModalShow(true);
				})
				.catch((err) => console.log(err));
		}
	};
	useEffect(() => {
		if (token !== null) {
			request
				.get(apiURL + VIEW_CUSTOMER + `/${id}`)
				.then((response) => {
					setCustomerId(response.data.customer.id);
					setUserName(response.data.customer.company.username);
					setFullName(response.data.customer.full_name);
					setEmail(response.data.customer.email);
					setCompanyName(response.data.customer.company_name);
					setCompanyAddress(response.data.customer.company_address);
					setCompanyWebsite(response.data.customer.company_website);
					setPhoneNumber(response.data.customer.phone);
					setCustomerType(response.data.customer.customer_type);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	return (
		<>
			<div>
				<div className="rightbar pr-11">
					<div className="section-title">
						<h2>Edit Customer</h2>
					</div>
					<Row>
						<Col sm={9} lg={5}>
							<Form>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Full Name</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="text"
													placeholder=""
													autoFocus
													autoComplete="off"
													name="name"
													value={name}
													onChange={(e) => setFullName(e.target.value)}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Email</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="email"
													placeholder=""
													autoComplete="off"
													name="email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
												{validation(email) === false && email !== "" && (
													<Alert
														variant={"danger"}
														messages={"Not a valid email address"}
														className="email-validated"
													/>
												)}
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Username</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="username"
													placeholder=""
													autoComplete="off"
													name="username"
													value={userName}
													disabled={true}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Company Name</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="text"
													placeholder=""
													autoComplete="off"
													name="company_name"
													value={companyName}
													onChange={(e) => setCompanyName(e.target.value)}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">
												Company Address
											</Form.Label>
											<Col sm={9}>
												<Form.Control
													as="textarea"
													placeholder=""
													name="company_address"
													value={companyAddress}
													style={{ height: "148px" }}
													onChange={(e) => setCompanyAddress(e.target.value)}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">
												Company Website
											</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="text"
													placeholder=""
													autoComplete="off"
													name="company_website"
													value={companyWesite}
													onChange={(e) => setCompanyWebsite(e.target.value)}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Phone number</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="text"
													placeholder="Phone Number"
													onChange={(e) => setPhoneNumber(e.target.value)}
													value={phoneNumber}
												/>
												{validateMobile(phoneNumber) === false &&
													phoneNumber !== "" && (
														<Alert
															variant={"danger"}
															messages={"Not a valid phone number"}
															className="email-validated"
														/>
													)}
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row className="align-items-center">
											<Form.Label className="col-sm-3">
												Customer Type
											</Form.Label>
											<Col sm={9}>
												<Select
													options={selectLabel(customerTypes)}
													placeholder="Select Customer Type"
													value={selectLabel(
														fiterSet(customerTypes, customerType)
													)}
													onChange={(e) => setCustomerType(e.value)}
													isDisabled={customerType === "enterprice" && true}
												/>
											</Col>
										</Row>
									</div>

									<div className="form-group">
										<div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
											<div className="">
												<Button
													variant=""
													className="puple btns mr-3"
													type="submit"
													onClick={() => navigate(CUSTOMERS_DETAILS + `/${id}`)}
												>
													Cancel
												</Button>
												<Button
													variant="primary"
													className="btns"
													type="submit"
													onClick={(e) => handleSubmit(e)}
												>
													Save
												</Button>
											</div>
										</div>
									</div>
								</Form.Group>
							</Form>
						</Col>
					</Row>
				</div>
			</div>
			<MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
					navigate(CUSTOMERS_DETAILS + `/${id}`);
				}}
				heading={"Success"}
				message={addResponse}
				className="modal-body-body--success"
			/>
		</>
	);
};

export default AddBranch;
