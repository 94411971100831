import React, { useState, useEffect } from "react";

// Importing components and images
import Loader from "../../../components/Loader";
import { Pagination, Row, Col, Table } from "react-bootstrap";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import { ADMIN_STAFF_ROLE_LIST } from "../../../config/apiRoutes.api";
import { capitalizeEachWord } from "../../../utils/CapitaliseTheFirstLetterForRole";

/**
 * Role component to display Role data with pagination.
 * @returns {JSX.Element} - The rendered Role component.
 */
const Role = () => {
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const [roles, setRoles] = useState([]);
	const [rows, setRows] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);

	useEffect(() => {
		if (token) {
			// Fetching user data based on selected roles from the API
			request
				.get(apiURL + ADMIN_STAFF_ROLE_LIST + `?page=${pageNumber}`)
				.then((response) => {
					console.log(response);
					setRoles(response.data.roles);
					setRows(response.data.roles.data);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumber]);

	//Paginations
	const PaginationItems = () => {
		const { total, per_page, current_page } = roles;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};
	const handleNextPagination = () => {
		if (pageNumber !== Math.ceil(roles.total / roles.per_page)) {
			setPageNumber(pageNumber + 1);
		}
	};
	const handlePreviousPagination = () => {
		if (pageNumber !== Math.ceil(roles.total / roles.per_page)) {
			setPageNumber(pageNumber - 1);
		}
		setPageNumber(pageNumber - 1);
	}; //end of Paginations

	// Render the loader while data is being fetched
	if (!roles.data) return <Loader />;
	else {
		return (
			<>
				<div className="d-sm-flex form-group">
					<div className="customer-search"></div>
				</div>
				<div className="table-responsive table-b custom-new-table">
					<Table hover>
						<thead>
							<tr>
								<th>Role Name</th>
								<th>Description</th>
							</tr>
						</thead>
						<tbody>
							{roles.data !== undefined &&
								rows.map((item, index) => {
									const list = (
										<>
											<tr key={index}>
												<td>{capitalizeEachWord(item.role)}</td>
												<td>{item.description}</td>
											</tr>
										</>
									);
									return list;
								})}
						</tbody>
					</Table>
				</div>

				<Row className="mt-4">
					<Col md={{ span: 4, offset: 8 }}>
						{
							<Pagination>
								<Pagination.Prev onClick={() => handlePreviousPagination()} />
								{PaginationItems()}
								<Pagination.Next onClick={() => handleNextPagination()} />
							</Pagination>
						}
					</Col>
				</Row>
			</>
		);
	}
};

export default Role;
