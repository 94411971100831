import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

/**
 * Confirmation modal for deactivating an item.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.title - The title of the modal.
 * @param {string} props.name - The name of the item to be deactivate.
 * @param {string} props.branch - The branch associated with the item.
 * @param {function} props.handleConfirm - Callback function to handle deactivate confirmation.
 * @returns {JSX.Element} - The rendered delete confirmation modal component.
 */
const DeActivateModalConfirmation = (props) => {
	return (
		<>
			<Modal
				{...props}
				backdrop="static"
				keyboard={false}
				centered
				contentClassName="my-modal"
			>
				<Modal.Header closeButton>
					<Modal.Title
						id="contained-modal-title-vcenter"
						className="section-title fonts"
					>
						{props.title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="section-title fonts" style={{ width: "30vw" }}>
					<p>
						Are you sure you want to deactivate the{" "}
						<span style={{ color: "Red" }}>{props.name}</span> {props.branch}?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="" className="puple btns mr-2" onClick={props.onHide}>
						Cancel
					</Button>
					<Button
						variant="primary"
						className="btns"
						onClick={props.handleConfirm}
					>
						Deactivate
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default DeActivateModalConfirmation;
