import React from 'react'
import Form from 'react-bootstrap/Form';
import customer1 from '../../../assets/Images/customer1.svg'
import customer2 from '../../../assets/Images/customer2.svg'
import customer3 from '../../../assets/Images/customer3.svg'
import customer4 from '../../../assets/Images/customer4.svg'
import customer5 from '../../../assets/Images/customer5.svg'
import customer6 from '../../../assets/Images/customer6.svg'
import customer7 from '../../../assets/Images/customer7.svg'
import customer8 from '../../../assets/Images/customer8.svg'


const CustomerSearchsidebar = () => {
    return (
        <>


            <div className="">
                <h4 className="mb-3 mt-3">Customers</h4>

                <Form>

                    <Form.Control
                        type="search"
                        placeholder="Search Customer "
                        className="me-2 search-icon"
                        aria-label="Search"
                    />

                </Form>

                <div className="customernamelist mt-3 supervisor-list">
                    <ul>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={customer4} alt="" />
                        </div>
                            <span>Reebok International</span>

                        </li>
                        <li className='online-active'><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={customer1} alt="" />
                        </div> <span>Nike, Inc.</span>


                        </li>


                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={customer5} alt="" />
                        </div>
                            <span>Addidas</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={customer2} alt="" />
                        </div>

                            <span>Under Armour, Inc.</span>



                        </li>

                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={customer3} alt="" />
                        </div>
                            <span>Puma</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={customer6} alt="" />
                        </div>
                            <span>United Colors of Benetton</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={customer7} alt="" />
                        </div>
                            <span>Vans</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={customer8} alt="" />
                        </div>
                            <span>Converse</span>
                        </li>
                    </ul>
                </div>
            </div>


        </>
    )
}

export default CustomerSearchsidebar