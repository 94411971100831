import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { uuid } from "uuidv4";

import MyVerticallyCenteredModal from "../../../components/Modal";
import Loader from "../../../components/Loader";

import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import {
  UPDATE_ENTERPRISE_FIELD,
  ADMIN_VIEW_ENTERPRISE_FIELD,
} from "./../../../config/apiRoutes.api";
import { VIEW_ENTERPRISE_FIELD } from "../../../config/routes";
import Alert from "../../../components/AlertCommon";

import PlusIcon from "../../../assets/Images/settings/plus-icon.svg";
import MinusIcon from "../../../assets/Images/settings/minus-icons.svg";
import SelectIcon from "../../../assets/Images/settings/select-icon.svg";
import RadioIcon from "../../../assets/Images/settings/radio-icon.svg";
import CheckBoxIcon from "../../../assets/Images/settings/checkbox-icon.svg";

const EditField = () => {
  const navigate = useNavigate();
  const [fieldName, setFieldName] = useState("");
  const [fieldLabel, setFieldLabel] = useState("");
  const [fieldType, setFieldType] = useState("");

  const [showAdditionalInputs, setAdditionalInputs] = useState("");
  const [fieldRequired, setRequiredField] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [responseMessage, setResponse] = useState("");
  const [fieldNameError, setErrorfieldName] = useState("");
  const [fieldLabelError, setErrorfieldLabel] = useState("");
  const [fieldTypeError, setErrorfieldType] = useState("");
  const [requiredFieldError, setErrorrequiredField] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [row, setRow] = useState([]);
  const [dataEdit, setData] = useState([]);
  const { id } = useParams();
  const [rowCol1, setRowCol1] = useState([
    {
      id: uuid(),
      field_values: "",
      field_keys: "",
      is_field_values_ubmitted: false,
      is_field_keys_submitted: false,
      system_id: "",
    },
  ]);

  const bodyFormData = new FormData();
  const token = sessionStorage.getItem("token");
  const request = client(token);

  /**
   * Handle the set  the state rows for key nd values .
   *  @param {String} value - String of user input.
   *  @param {String} field - input type.
   *  @param {Number} index - position of array.
   *  @param {String} eventFrom - check which row is clicked.
   * @returns {Array} - Set the state and return new state.
   */

  const setRows = (value, field, index, eventFrom) => {
    if (eventFrom === "main") {
      var rows = [...rowCol1];
    } else {
      var rows = [...row];
    }
    let temp_row = { ...rows[index] };
    if (field === "field_values") {
      temp_row.field_values = ltrim(value);
      temp_row.is_field_values_ubmitted = true;
    }
    if (field === "field_keys") {
      temp_row.field_keys = ltrim(value);
      temp_row.is_field_keys_submitted = true;
    }
    rows[index] = temp_row;
    if (eventFrom === "main") {
      setRowCol1(rows);
    } else {
      setRow(rows);
    }
  };

  /**
   * Handle the validation errors before submission of the form.
   */
  const validateForm = () => {
    // decare empty value fore dit type checked by default
    let is_error = false;
    // if (!fieldName) {
    //   is_error = true;
    //   setErrorfieldName("Please enter field name");
    // } else {
    //   setErrorfieldName("");
    // }

    if (!fieldLabel) {
      is_error = true;
      setErrorfieldLabel("Please enter field label");
    } else {
      setErrorfieldLabel("");
    }

    if (!fieldType) {
      is_error = true;
      setErrorfieldType("Please select field type");
    } else {
      setErrorfieldType("");
      if (
        fieldType === "select" ||
        fieldType === "checkbox" ||
        fieldType === "radio"
      ) {
        let row1 = [...rowCol1];
        let rown = [...row];
        let new_row1 = [];
        let new_rown = [];
        row1.forEach((obj) => {
          if (
            fieldType === "select" ||
            fieldType === "checkbox" ||
            fieldType === "radio"
          ) {
            // if (!obj.field_keys || !obj.field_values) {
            if (!obj.field_keys) {
              is_error = true;
            }
          }
          new_row1.push({
            id: obj.id,
            field_values: obj.field_values,
            field_keys: obj.field_keys,
            is_field_values_ubmitted: true,
            is_field_keys_submitted: true,
            system_id: obj.system_id,
          });
        });

        rown.forEach((obj) => {
          if (
            fieldType === "select" ||
            fieldType === "checkbox" ||
            fieldType === "radio"
          ) {
            if (
              fieldType === "select" ||
              fieldType === "checkbox" ||
              fieldType === "radio"
            ) {
              // if (!obj.field_keys || !obj.field_values) {
              if (!obj.field_keys) {
                is_error = true;
              }
            }
          }

          new_rown.push({
            id: obj.id,
            field_values: obj.field_values,
            field_keys: obj.field_keys,
            is_field_values_ubmitted: true,
            is_field_keys_submitted: true,
            system_id: obj.system_id,
          });
        });
        setRowCol1(new_row1);
        setRow(new_rown);
      }
    }

    if (fieldRequired >= 0 && fieldRequired !== "") {
      setErrorrequiredField("");
    } else {
      is_error = true;
      setErrorrequiredField("Please select required field type");
    }

    console.log(" is_error =>", is_error);
    return is_error;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (token && !validateForm()) {
      event.preventDefault();
      const request = client(token);
      let field_values = getSelelectedValues(rowCol1, row);
      let field_keys = getSelelectedKeys(rowCol1, row);
      let field_value_ids = getSystemidsValues(rowCol1, row);

      bodyFormData.append("field_name", fieldLabel);
      // bodyFormData.append("field_label", fieldLabel);
      bodyFormData.append("field_type", fieldType);
      bodyFormData.append("field_required", fieldRequired);
      bodyFormData.append(
        "field_values",
        field_keys ? field_keys.join(",") : ""
      );
      bodyFormData.append(
        "field_keys",
        field_values ? field_values.join(",") : ""
      );

      bodyFormData.append(
        "field_value_ids",
        field_value_ids ? field_value_ids.join(",") : ""
      );

      console.log(" field_values =>", field_values);
      console.log(" field_keys =>", field_keys);
      console.log(" field_value_ids =>", field_value_ids);

      // return false;
      setDisabled(true);

      request
        .post(apiURL + `${UPDATE_ENTERPRISE_FIELD}/${id}`, bodyFormData)
        .then((response) => {
          console.log(response);
          setResponse(response.data.message ? response.data.message : "");
          setModalShow(true);
          setDisabled(false);
        })
        .catch((err) => {
          console.log(err);
          setDisabled(false);
        });
    }
  };

  /**
   *  Creates an option array.
   * @returns {Array} - Creates array of objects with label, value,.
   */

  const fieldTypeOptions = [
    {
      label: "Multiple Choice",
      value: "radio",
    },
    {
      label: "Checkbox",
      value: "checkbox",
    },
    {
      label: "Dropdown",
      value: "select",
    },
    {
      label: "Short Answer",
      value: "text",
    },
    {
      label: "File Upload",
      value: "document",
    },
    // {
    //   label: "Image",
    //   value: "image",
    // },

    // {
    //   label: "Number",
    //   value: "number",
    // },
    // {
    //   label: "Textarea",
    //   value: "textbox",
    // },
  ];

  const requiredFieldOptions = [
    {
      label: "No",
      value: 0,
    },
    {
      label: "Yes",
      value: 1,
    },
  ];

  /**
   * Generate input id based on  field array.
   * It generates the form input field type passed.
   */

  const generateKey = (input) => {
    return input.replace(/ /g, "_").toLowerCase();
  };

  /**
   * Handle the remove unwanted space from the string from the start.
   *  @param {String} str - String of user input.
   * @returns {String} - Transformed removed space from the begining.
   */
  const ltrim = (str) => {
    if (!str) return str;
    return str.replace(/^\s+/g, "");
  };

  /**
   *  Handles Input type field change.
   * @returns {Array} - Set state based on selected field.
   */
  useEffect(() => {
    if (fieldType) {
      setAdditionalInputs(true);
    } else {
      setAdditionalInputs(false);
    }
  }, [fieldType]);

  /**
   *  Handles add row .
   * @returns {Array} - Set state based on selected field.
   */
  const handleAddRow = () => {
    // if (row.length <= 1) {
    setRow((prevState) => [
      ...prevState,
      {
        id: uuid(),
        field_values: "",
        field_keys: "",
        is_field_values_ubmitted: false,
        is_field_keys_submitted: false,
        system_id: "",
      },
    ]);
    // }
  };

  const removeItem = (removeId) => {
    setRow((prevState) => prevState.filter(({ id }) => id !== removeId));
  }; // remove the object from previous array of object based on id

  /**
   * Handles the function to retun ids.
   * It handles and accepts object array and return ids,
   *  * @param {Array} obj1 - Object array.
   *  * @param {Array} obj2 - Object array.
   *    @returns {Array}
   */

  const getSelelectedValues = (obj1, obj2) => {
    let ids1 = obj1.map((item) => {
      return isNaN(item.field_values) && item.field_values
        ? item.field_values
        : Number(item.field_values);
    });
    let idsn = obj2.map((item) => {
      return isNaN(item.field_values) && item.field_values
        ? item.field_values
        : Number(item.field_values);
    });
    return [...ids1, ...idsn];
  };

  /**
   * Handles the function to retun text.
   * It handles and accepts object array and return text,
   *  * @param {Array} obj1 - Object array.
   *  * @param {Array} obj2 - Object array.
   * @returns {Array}
   */

  const getSelelectedKeys = (obj1, obj2) => {
    let txt1 = obj1.map((item) => {
      return isNaN(item.field_keys) && item.field_keys
        ? item.field_keys
        : item.field_keys && item.field_keys >= 0
        ? Number(item.field_keys)
        : generateKey(item.field_values);
    });
    let txtn = obj2.map((item) => {
      return isNaN(item.field_keys) && item.field_keys
        ? item.field_keys
        : item.field_keys && item.field_keys >= 0
        ? Number(item.field_keys)
        : generateKey(item.field_values);
    });
    return [...txt1, ...txtn];
  };

  /**
   * Handles the function to retun ids.
   * It handles and accepts object array and return ids,
   *  * @param {Array} obj1 - Object array.
   *  * @param {Array} obj2 - Object array.
   *    @returns {Array}
   */

  const getSystemidsValues = (obj1, obj2) => {
    let ids1 = obj1.map((item) => {
      return item.system_id || "";
    });
    let idsn = obj2.map((item) => {
      return item.system_id || "";
    });
    console.log(" idsn ", idsn);
    return [...ids1, ...idsn];
  };

  useEffect(() => {
    request
      .get(apiURL + `${ADMIN_VIEW_ENTERPRISE_FIELD}/${id}`)
      .then((response) => {
        console.log("response ==>", response.data.data.formField);
        if (response.data.status) {
          setFieldName(response.data.data.formField.field_name);
          setFieldLabel(response.data.data.formField.field_label);
          setFieldType(response.data.data.formField.field_type);
          setRequiredField(Number(response.data.data.formField.field_required));
          setData(response.data.data.formField);
          if (
            response.data.data.formField.field_type === "select" ||
            response.data.data.formField.field_type === "checkbox" ||
            response.data.data.formField.field_type === "radio"
          ) {
            if (response.data.data.formField.values.length > 0) {
              setRowCol1([
                {
                  id: response.data.data.formField.values[0].id,
                  field_values:
                    response.data.data.formField.values[0].field_value,
                  field_keys:
                    response.data.data.formField.values[0].field_value_text,
                  is_field_values_ubmitted: false,
                  is_field_keys_submitted: false,
                  system_id: response.data.data.formField.values[0].id,
                },
              ]);
            }
            let new_rown = [];
            if (response.data.data.formField.values.length > 1) {
              response.data.data.formField.values.forEach((obj, indx) => {
                if (indx > 0) {
                  new_rown.push({
                    id: obj.id,
                    field_values: obj.field_value,
                    field_keys: obj.field_value_text,
                    is_field_values_ubmitted: false,
                    is_field_keys_submitted: false,
                    system_id: obj.id,
                  });
                }
              });
              setRow(new_rown);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const resetFields = (event) => {
    setFieldType(event.value);
    event.value
      ? setErrorfieldType("")
      : setErrorfieldType("Please select field type");

    setRowCol1([
      {
        id: uuid(),
        field_values: "",
        field_keys: "",
        is_field_values_ubmitted: false,
        is_field_keys_submitted: false,
        system_id: "",
      },
    ]);
    setRow([]);
  };

  console.log(" fieldType=> ", fieldType);
  // console.log(!!dataEdit.length);
  if (!Object.keys(dataEdit).length) return <Loader />;
  return (
    <>
      <div>
        <div className="rightbar pr-11">
          <div className="section-title">
            <h2>Update Enterprise Signup Question</h2>
          </div>
          <Row>
            <Col sm={9} lg={5}>
              <Form className="sp_form">
                <Form.Group className="mb-3">
                  <div className="form-group">
                    <Row>
                      {/* <Form.Label className="col-sm-3" htmlFor="fieldLabel">
                        Field Label
                      </Form.Label> */}
                      <Col sm={12}>
                        <Form.Control
                          as="textarea"
                          placeholder="Question"
                          id="fieldLabel"
                          autoFocus
                          autoComplete="off"
                          value={fieldLabel}
                          onChange={(e) => {
                            setFieldLabel(ltrim(e.target.value));
                            e.target.value.trimStart()
                              ? setErrorfieldLabel("")
                              : setErrorfieldLabel("Please enter field label");
                          }}
                          style={{ height: "124px" }}
                        />
                        {fieldLabelError && (
                          <Alert
                            variant={"danger"}
                            messages={fieldLabelError}
                            className=""
                          />
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group" style={{ marginTop: "-16px" }}>
                    <Row>
                      <Col sm={9}>
                        <input
                          className="selctType"
                          type="checkbox"
                          style={{
                            cursor: "pointer",
                          }}
                          checked={fieldRequired === 1}
                          onChange={(e) => {
                            setRequiredField(e.target.checked ? 1 : 0);
                          }}
                          id="requiredField"
                        />
                        <Form.Label
                          className="col-sm-3"
                          htmlFor="requiredField"
                          style={{
                            cursor: "pointer",
                            marginLeft: "-5px",
                            top: "-1px",
                          }}
                        >
                          Required
                        </Form.Label>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group">
                    <Row>
                      <Col sm={9}>
                        <Select
                          placeholder={"Please select"}
                          id="fieldType"
                          options={fieldTypeOptions}
                          onChange={(e) => {
                            resetFields(e);
                          }}
                          value={
                            fieldType
                              ? fieldTypeOptions.find(
                                  ({ value }) => value === fieldType
                                )
                              : ""
                          }
                        />
                        {fieldTypeError && (
                          <Alert
                            variant={"danger"}
                            messages={fieldTypeError}
                            className=""
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          {showAdditionalInputs && (
            <Row>
              <Col sm={8} lg={10}>
                <Form className="sp_form">
                  <Form.Group className="mb-4">
                    {rowCol1.map((items, id) => {
                      return (
                        <div
                          className="form-group"
                          style={{
                            marginLeft:
                              fieldType === "checkbox" ||
                              fieldType === "select" ||
                              fieldType === "radio"
                                ? "1%"
                                : "-2%",
                          }}
                          key={`field_${id}`}
                        >
                          <Row className="align-items-center">
                            {fieldType === "checkbox" ||
                            fieldType === "select" ||
                            fieldType === "radio" ? (
                              <>
                                <Col xs={1}>
                                  <img
                                    src={
                                      fieldType === "select"
                                        ? SelectIcon
                                        : fieldType === "checkbox"
                                        ? CheckBoxIcon
                                        : RadioIcon
                                    }
                                    alt="mindreach images"
                                  ></img>
                                </Col>
                                <Col xs={4} style={{ marginLeft: "-2%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder="Option 1"
                                    autoFocus
                                    autoComplete="off"
                                    value={rowCol1[0]["field_keys"]}
                                    onChange={(e) => {
                                      setRows(
                                        e.target.value,
                                        "field_keys",
                                        id,
                                        "main"
                                      );
                                    }}
                                  />
                                  {!rowCol1[0]["field_keys"] &&
                                    rowCol1[0]["is_field_keys_submitted"] && (
                                      <Alert
                                        variant={"danger"}
                                        messages={"Please enter option"}
                                        className=""
                                      />
                                    )}
                                </Col>
                              </>
                            ) : fieldType === "text" ||
                              fieldType === "document" ? (
                              <>
                                <Col xs={1}></Col>
                                <Col xs={4} style={{ marginLeft: "-2%" }}>
                                  <Form.Control
                                    type="text"
                                    placeholder={
                                      fieldType === "text"
                                        ? "Short Answer"
                                        : "File Upload"
                                    }
                                    disabled={true}
                                    value=""
                                  />
                                </Col>{" "}
                              </>
                            ) : null}
                            {(fieldType === "checkbox" ||
                              fieldType === "select" ||
                              fieldType === "radio") && (
                              <Col xs={1}>
                                <div className="d-flex">
                                  {row.length === 0 && (
                                    <>
                                      <div
                                        className="sm-1"
                                        onClick={() => handleAddRow()}
                                      >
                                        <img
                                          src={PlusIcon}
                                          alt="mindreach images"
                                        ></img>
                                      </div>
                                      <div className="sm-1">
                                        {/* <img src={MinusIcon} alt="mindreach images"></img> */}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            )}
                          </Row>
                        </div>
                      );
                    })}

                    {row.map((items, id) => {
                      return (
                        <div
                          className="form-group"
                          style={{
                            marginLeft: "1%",
                          }}
                          key={`fieldn_${id}`}
                        >
                          <Row className="align-items-center">
                            <Col xs={1} className="mt-4">
                              <img
                                src={
                                  fieldType === "select"
                                    ? SelectIcon
                                    : fieldType === "checkbox"
                                    ? CheckBoxIcon
                                    : RadioIcon
                                }
                                alt="mindreach images"
                              ></img>
                            </Col>
                            <Col xs={4} style={{ marginLeft: "-2%" }}>
                              <Form.Label className=""></Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={`Option ${id + 2}`}
                                autoFocus
                                autoComplete="off"
                                onChange={(e) => {
                                  setRows(
                                    e.target.value,
                                    "field_keys",
                                    id,
                                    "sub"
                                  );
                                }}
                                value={row[id]["field_keys"]}
                              />
                              {!row[id]["field_keys"] &&
                                row[id]["is_field_keys_submitted"] && (
                                  <Alert
                                    variant={"danger"}
                                    messages={"Please enter option"}
                                    className=""
                                  />
                                )}
                            </Col>

                            <Col xs={2}>
                              <div className="d-flex">
                                {row.length === id + 1 && (
                                  <div
                                    className="sm-1 mt-4 mr-2"
                                    onClick={() => handleAddRow()}
                                  >
                                    <img
                                      src={PlusIcon}
                                      alt="mindreach images"
                                    ></img>
                                  </div>
                                )}
                                <div
                                  className="sm-1 mt-4"
                                  onClick={() =>
                                    row && row[0] !== undefined
                                      ? removeItem(row[id].id)
                                      : setRow([])
                                  }
                                >
                                  <img
                                    src={MinusIcon}
                                    alt="mindreach images"
                                  ></img>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={9} lg={5}>
              <Form>
                <Form.Group className="mb-3">
                  <div className="form-group">
                    <div className=" justify-content-between btn-cls flex-row--reverse">
                      <div className="">
                        <Button
                          variant=""
                          className="puple btns mr-3"
                          type="submit"
                          onClick={() => navigate(VIEW_ENTERPRISE_FIELD)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="btns"
                          type="submit"
                          disabled={disabled}
                          onClick={(e) => handleSubmit(e)}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          navigate(VIEW_ENTERPRISE_FIELD);
        }}
        heading={"Success"}
        message={responseMessage}
        className="modal-body-body--success"
      />
    </>
  );
};

export default EditField;
