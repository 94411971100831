import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { uuid } from "uuidv4";

// Importing components and images
import { Row, Col, Form, Button, Tab, Tabs } from "react-bootstrap";
import Select from "react-select";

import MyVerticallyCenteredModal from "../../../components/Modal";
import Alert from "../../../components/Alert";
import Loader from "../../../components/Loader";

import profile from "../.././../assets/Images/user/no-profile.png";
import Invite from "./../../../assets/Images/invite.svg";
import Delete from "./../../../assets/Images/delete.svg";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import {
	ADMIN_STAFF_ROLES,
	BRANCHES_LIST,
	BRANCHES_SHIFT_BYBRANCHID,
	UPDATE_STAFF,
	VIEW_EDIT_STAFF,
} from "../../../config/apiRoutes.api";
import { STAFF_PAGE } from "../../../config/routes";
import { array } from "prop-types";

const Staffnewuser = () => {
	const [loader, setLoader] = useState(false);
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const navigate = useNavigate();
	const { id } = useParams();
	const bodyFormData = new FormData();
	const [imgfile, uploadimg] = useState([]);
	const [base64String, setBasr64String] = useState(""); // need to remove
	const [fileName, setFile] = useState();
	const [selectRoles, setSelectRoles] = useState("");
	const [roleId, setRoleId] = useState(0);
	const [roles, setRoles] = useState([]);
	const [branches, setBranches] = useState([]);

	const [brancheName, setBranchesName] = useState(0);
	const [shift, setShift] = useState([]);
	const [selectShiftId, setSelectShiftLabel] = useState([]);
	const [selectShiftIdSecond, setSelectShiftLabelSecond] = useState([]);
	const [error, setError] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address, setAddress] = useState("");
	const [modalShow, setModalShow] = React.useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [status, setStatus] = useState("");
	const [row, setRow] = useState([]);

	// Function to get the user's role from session storage
	function getUserRole() {
		const roleJson = sessionStorage.getItem("role");
		return roleJson ? JSON.parse(roleJson) : null;
	}

	// Function to check if the user has a specific role for a given route
	function hasUserRole(route, targetRole) {
		const role = getUserRole();
		const currentUserRoles = [role !== null ? role.role : "login"];
		return currentUserRoles.includes(targetRole);
	}

	// Example usage:
	const route = { role: "department-manager" };

	const imgFilehandler = (event) => {
		setFile(event.target.files[0]);
		if (event.target.files.length !== null) {
			uploadimg([URL.createObjectURL(event.target.files[0])]);
		}
	};

	const onImageError = (e) => {
		e.target.src = profile;
	};
	const ImageUrl = (imgfile) =>
		imgfile.map((elem) => {
			{
				console.log("img", elem);
			}
			return (
				<span key={elem}>
					<img
						src={elem.length !== undefined ? elem : profile}
						className="img-fluid d-block mx-auto"
						alt="user"
						onError={onImageError}
					/>
				</span>
			);
		});
	/**
	 * fiterSet- This function find the selected branch together.
	 * @param {array} x - The array of collection to search.
	 * @param {id} y - The id of selected element.
	 */
	const fiterSet = (array, id) => {
		return array.filter((itemInArray) => itemInArray.id === id);
	};
	const fiterSetRoles = (array, id) => {
		return array.filter((item) => {
			if (item.role === id) {
				return item.role;
			}
		});
	};

	/**
	 * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} branches - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (branches) =>
		branches.map(({ role: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]

	const selectLabelBranches = (branches) =>
		branches.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	const selectShiftLabel = (shift) =>
		shift.map(({ shift_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of shift array as label and value return a new array of object like [{value:"",label:""}]

	useEffect(() => {
		if (token !== null) {
			setLoader(true);
			request
				.get(apiURL + VIEW_EDIT_STAFF + `/${id}`)
				.then((response) => {
					console.log("respone", response.data.staff.roles[0].id);
					setLoader(false);
					uploadimg([response.data.image_url]);
					setFirstName(response.data.staff.first_name);
					setLastName(response.data.staff.last_name);
					setAddress(response.data.staff.address);
					setEmail(response.data.staff.email);
					setPhone(response.data.staff.phone_number);
					setBranchesName(response.data.staff.branch_id);
					setSelectRoles(response.data.staff.user_type);
					setRoleId(response.data.staff.roles[0].id);
					setSelectShiftLabel([response.data.staff.shifts[0].id]);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token) {
			// Fetching admin user's roles data from the API
			request
				.get(apiURL + ADMIN_STAFF_ROLES)
				.then((response) => {
					console.log(response);
					setRoles(response.data.data.roles);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_LIST)
				.then((response) => {
					console.log(response);
					setBranches(response.data[0].branches);
				})
				.catch((err) => {
					setError(err.message);
				});
		}
	}, []);
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_SHIFT_BYBRANCHID + `/${brancheName}`)
				.then((response) => {
					console.log("shift", response);
					setShift(response.data.shifts);
				})
				.catch((err) => console.log(err));
		}
	}, [brancheName]);
	const handleSubmit = (event) => {
		event.preventDefault();
		if (token) {
			const request = client(token);

			bodyFormData.append("first_name", firstName);
			bodyFormData.append("last_name", lastName);
			bodyFormData.append("address", address);
			bodyFormData.append("role_id", roleId);
			bodyFormData.append("user_type", selectRoles);
			//
			bodyFormData.append("email", email);
			bodyFormData.append("branch_id", brancheName);
			bodyFormData.append(
				"shift_ids",
				JSON.stringify(selectShiftId).slice(1, -1)
			);
			if (fileName !== undefined) {
				bodyFormData.append("profile_image", fileName);
			}

			bodyFormData.append("phone_number", phone);
			request
				.post(apiURL + UPDATE_STAFF + `/${id}`, bodyFormData)
				.then((response) => {
					console.log("add", response);
					setSubmitStatusMessage(response.data.message);
					setModalShow(true);
					setStatus("Success");
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	console.log("roleId", roleId);
	const handleShifts = React.useCallback(
		(e) => {
			if (selectShiftId.length < 3) {
				setSelectShiftLabel((prevSelectedRows) => [
					...prevSelectedRows,
					e.value,
				]);
			} else {
				setSelectShiftLabel((prevSelectedRows) =>
					prevSelectedRows.filter((selectedRow) => selectedRow !== e.value)
				);
			}
		},
		[selectShiftId]
	);
	const handleAddRow = () => {
		if (row.length <= 1) {
			setRow((prevState) => [
				...prevState,
				{
					id: uuid(),
				},
			]);
		}
	}; // add an object with specific uuid
	const validation = (email) => {
		const result =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return result.test(String(email).toLowerCase());
	}; //validated email using regex
	const validateMobile = (mobilenumber) => {
		let regmm = "^([0|+[0-9]{1,5})?([7-9][0-9]{9})$";
		let regmob = new RegExp(regmm);
		if (regmob.test(mobilenumber)) {
			return true;
		} else {
			return false;
		}
	}; //validated phone using regex
	const shiftid = (selectShiftId) => {
		return (
			selectShiftId !== undefined &&
			selectShiftId.map((item) => {
				return item.id;
			})
		);
	};

	let shiftName = selectShiftLabel(
		fiterSet(shift, Number(selectShiftId[0]))
	)[0];
	const Label = () => {
		if (shiftName !== undefined) {
			let { label } = shiftName;
			return label;
		}
	};
	const filerRoles = (roles) => {
		return roles.filter(
			(item) =>
				item.role !== "super-admin" && item.role !== "department-manager"
		);
	};

	if (loader) {
		return <Loader />;
	} else {
		return (
			<>
				<div className="rightbar pr-11">
					<Tabs
						defaultActiveKey="User"
						id="justify-tab-example"
						className="stafftab"
						justify
					>
						<Tab eventKey="User" title="User"></Tab>
					</Tabs>
					<div className="section-title">
						<h2>Edit/View User</h2>
						{error !== "" && <Alert variant={"danger"} messages={error} />}
					</div>

					<Form className="mb-5">
						<Row>
							<Col sm={9} lg={5}>
								<Form>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Image</Form.Label>
												<Col sm={9}>
													<div className="uploadimg">
														{ImageUrl(imgfile)}
														<div className="edit">
															<label htmlFor="icon-button-file">
																<i className="fa fa-edit"></i>
															</label>
															<Form.Control
																id="icon-button-file"
																type="file"
																file={true}
																accept="image/*"
																style={{ visibility: "hidden" }}
																onChange={(e) =>
																	e !== null && imgFilehandler(e)
																}
																webkitRelativePath
															/>
														</div>
													</div>
												</Col>
											</Row>
										</div>
										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">First Name</Form.Label>
												<Col sm={9}>
													<Form.Control
														type="text"
														placeholder="Michael"
														autoFocus
														onChange={(e) => setFirstName(e.target.value)}
														value={firstName}
													/>
												</Col>
											</Row>
										</div>
										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Last Name</Form.Label>
												<Col sm={9}>
													<Form.Control
														type="text"
														placeholder="Jordan"
														autoFocus
														onChange={(e) => setLastName(e.target.value)}
														value={lastName}
													/>
												</Col>
											</Row>
										</div>
										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Address</Form.Label>
												<Col sm={9}>
													<Form.Control
														type="text"
														placeholder="Add address"
														onChange={(e) => setAddress(e.target.value)}
														value={address}
													/>
												</Col>
											</Row>
										</div>

										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">
													Phone number
												</Form.Label>
												<Col sm={9}>
													<Form.Control
														type="text"
														placeholder="Phone Number"
														onChange={(e) => setPhone(e.target.value)}
														value={phone}
													/>
													{validateMobile(phone) === false && phone !== "" && (
														<Alert
															variant={"danger"}
															messages={"Not a valid phone number"}
															className="email-validated"
														/>
													)}
												</Col>
											</Row>
										</div>

										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Email</Form.Label>
												<Col sm={9}>
													<Form.Control
														type="email"
														placeholder="Add email"
														onChange={(e) => setEmail(e.target.value)}
														value={email}
													/>
													{validation(email) === false && email !== "" && (
														<Alert
															variant={"danger"}
															messages={"Not a valid email address"}
															className="email-validated"
														/>
													)}
												</Col>
											</Row>
										</div>

										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Branch</Form.Label>
												<Col sm={9}>
													<Select
														onChange={(e) => {
															e !== null && setBranchesName(e.value);
														}}
														options={selectLabelBranches(branches)}
														placeholder="Select Branch"
														isSearchable
														value={selectLabelBranches(
															fiterSet(branches, brancheName)
														)}
													/>
												</Col>
											</Row>
										</div>

										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Role</Form.Label>
												<Col sm={9}>
													<Select
														options={selectLabel(
															hasUserRole(route, "department-manager")
																? filerRoles(roles)
																: roles
														)}
														placeholder="Select Role"
														onChange={(e) => {
															setSelectRoles(e.label);
															setRoleId(e.value);
														}}
														value={selectLabel(
															fiterSetRoles(roles, selectRoles)
														)}
														isDisabled={
															(hasUserRole(route, "department-manager") &&
																selectRoles === "super-admin") ||
															selectRoles === "department-manager"
																? true
																: false
														}
													/>
												</Col>
											</Row>
										</div>
										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Shift(s)</Form.Label>
												<Col sm={9}>
													<Select
														onChange={(e) => handleShifts(e)}
														options={selectShiftLabel(shift)}
														placeholder={[`${Label()}`]}
													/>
												</Col>
											</Row>
										</div>
										{row.map((items) => {
											return (
												<div className="form-group">
													<Row key={items.id}>
														<Form.Label className="col-sm-3"></Form.Label>
														<Col sm={9}>
															<Select
																onChange={(e) => {
																	handleShifts(e);
																}}
																options={selectShiftLabel(shift)}
																placeholder="Select Shift"
																isSearchable
															/>
														</Col>
													</Row>
												</div>
											);
										})}
										<div className="form-group">
											<Row>
												{row.length < 2 && (
													<Col>
														<p className="addfield" onClick={handleAddRow}>
															Click to add more
														</p>
													</Col>
												)}{" "}
											</Row>
										</div>
									</Form.Group>
									<div className="d-sm-flex justify-content-between ">
										<div className="">
											<Button
												variant=""
												className="btn-light-border grey"
												type="submit"
											>
												<img src={Delete} className="" img-fluid />
											</Button>
										</div>
										<div className="">
											<Button
												variant=""
												className="puple btns mr-3"
												onClick={() => navigate(STAFF_PAGE)}
											>
												Cancel
											</Button>
											<Button
												variant="primary"
												className="btns"
												onClick={handleSubmit}
											>
												<span>
													<img
														src={Invite}
														className="img-fluid"
														alt="mindreach images"
													/>
												</span>{" "}
												Update{" "}
											</Button>
										</div>
									</div>
								</Form>
							</Col>
						</Row>
					</Form>
					<MyVerticallyCenteredModal
						show={modalShow}
						onHide={() => {
							setModalShow(false);
							navigate(STAFF_PAGE);
						}}
						heading={status}
						message={submitStatusMessage}
						className="modal-body-body--success"
					/>
				</div>
			</>
		);
	}
};

export default Staffnewuser;
