import React, { useState, useEffect, useCallback } from "react";
// import Sidebar from "../../../components/global/Sidebar/Sidebar";

import {
	Row,
	OverlayTrigger,
	Tooltip,
	Col,
	Form,
	Button,
} from "react-bootstrap";
import axios from "axios";
import { saveAs } from "file-saver";
// import workspace from '../../../css/workspace.css'
import EditorOrderDetailsidebar from "./EditorOrderDetailsidebar";
import EditorOrderDetails from "./EditorOrderDetails";
import Select from "react-select";
import moment from "moment";
import Autocomplete from "../Components/AutoComplete";
import ReAssignModal from "./Modal/ReAssign";
import MyVerticallyCenteredModal from "../../../components/Modal";
import ConfirmBulkUploadModal from "../../../components/Modal/ConfirmBulkUploadModal";

import Delete from "./../../../assets/Images/delete.svg";
import duplicate from "./../../../assets/Images/coordinator_actions_icon/Duplicate _Final.svg";

import CancelIcon from "./../../../assets/Images/coordinator_actions_icon/cancel_Final.svg";
import MoreIcon from "./../../../assets/Images/coordinator_actions_icon/more-Final.svg";
import LinkIcon from "./../../../assets/Images/coordinator_actions_icon/Link.svg";
import DocsIcon from "./../../../assets/Images/coordinator_actions_icon/Doc.svg";
import download_bulk from "../../../assets/Images/download-bulk.svg";
import download_bulk_disabled from "../../../assets/Images/download-bulk-disabled.svg";
import upload_bulk from "../../../assets/Images/upload-bulk.svg";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import {
	GET_ALL_EDITORS,
	GET_EDITOR_WORK_SPACE,
	GET_EDITOR_BATCH_IMAGES,
	EDITOR_DOWNLOAD_IMAGE,
	EDITOR_UPLOAD_BULK_IMAGE,
	EDITOR_ASSIGN_QC_IMAGE,
	CO_ORDINATOR_VIEW_PROFILE_SETTINGS,
} from "../../../config/apiRoutes.api";
import Loader from "../../../components/Loader";

let imagesObj = [];

// Initialize an empty array to store messages
let msg = [];
// Initialize an empty string to store concatenated messages
let responseMSG = "";

const EditorWorkspace = () => {
	const date = [
		{ label: "Custom", value: "custom" },
		{ label: "Today", value: "today" },
		{ label: "Current Month", value: "month" },
		{ label: "Current Year", value: "year" },
		{ label: "All", value: "" },
	];

	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
	// Create a FormData object for request payload
	const form = new FormData();
	// Retrieve the token from session storage
	const token = sessionStorage.getItem("token");
	// Create a request instance using the token
	const request = client(token);

	//Declarations of state
	const [options, setOptions] = useState([]);
	const [selectedStaff, setStaffOptions] = useState("");
	const [selectedStaffObject, setStaffEvent] = useState("");
	const [selectedFilter, setFilterSelection] = useState("");
	// const [selectedDate, setSelectedDate] = useState(new Date());
	// const [selectedDate2, setSelectedDate2] = useState(new Date());
	const [selectedDate, setSelectedDate] = useState("");
	const [selectedDate2, setSelectedDate2] = useState("");
	const [search, setSearch] = useState("");
	const [activeBatch, setActiveBatch] = useState(0);
	const [activeBatchNumber, setActiveBatchNumber] = useState(0);
	const [batchData, setBatchData] = useState([]);
	const [batchImages, setBatchImages] = useState([]);
	const [showBatchLoader, setBatchLoader] = useState(true);
	const [showBatchImagesLoader, setBatchImagesLoader] = useState(false);
	const [skipImagetLoad, setSkipImageCall] = useState(true);
	const [selectedImagesID, setSelectedImagesID] = useState([]);
	const [selectedAssigntoQCImagesId, setSelectedAssigntoQCImagesID] = useState(
		[]
	);
	const [isShow, setIsShow] = useState(false);
	const [batchNumber, setBatchNumber] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [showReassign, setshowReassign] = useState(false);
	const [reAssignOptions, setReAssignOptions] = useState([]);
	const [loader, setLoader] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [responseHeading, setResponseHeading] = useState("Error");
	const [responseMessage, setResponseMessage] = useState("");
	const [reassign, reassignSubmit] = useState(false);
	const [imageID, setImageID] = useState("");
	const [bulkDownload, setBulkDownload] = useState(false);
	const [bulkUploading, setBulkUploading] = useState(false);
	const [submitQC, setsubmitQC] = useState(false);
	const [isPSDError, setPSDError] = useState(false);
	const [uploaderrorMessage, setErrorMessage] = useState([]);
	const [continuePSD, continuePSDUpload] = useState(false);
	const [resubmitForm, setReSubmitForm] = useState([]);
	const [notreadyImages, setNotReadyImages] = useState([]);
	const [confirmMessage, setConfirmMessage] = useState("");
	const [reloadBatchImages, setReloadBatchImages] = useState(false);
	const [closePopup, setClosePopup] = useState(false);
	const [setting_profile, setSettingsProfile] = useState([]);

	const roleJson = sessionStorage.getItem("role");
	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserId = [role !== null ? role.id : "login"];
	const LoginUserId = role !== null && role.pivot ? role.pivot.user_id : "";

	const handleFetchProfileSettings = (imageID) => {
		if (token && imageID !== "") {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_PROFILE_SETTINGS + `/${imageID}`)
				.then((response) => {
					setSettingsProfile(response.data.image);
					// setEditorType(response.data.image.edit_group_id);
					// console.log(response.data.image);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					// setError(message);
				});
		}
	};

	// Function to check and push non-empty messages to the 'msg' array and concatenate to 'responseMSG'
	const processMessage = (message, array) => {
		if (message && message !== "") {
			array.push(message);
			responseMSG += `\n\n${message}`;
		}
	};

	/**
	 * Transforms an array of staff objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} options - An array of staff objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (options) =>
		options.map(({ full_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of staff array as label and value return a new array of object like [{value:"",label:""}]
	useEffect(() => {
		if (token) {
			// Fetching editors user's  data from the API
			request
				.get(apiURL + GET_ALL_EDITORS)
				.then((response) => {
					setOptions([{ label: "None", value: "" }, ...response.data.editors]);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	const getFormattedDate = (dateString) => {
		// Create a new Date object from the date string
		const dateObject = new Date(dateString);
		// Format the date as "day Month year" (e.g., "25 July 2023")
		const options = { day: "numeric", month: "long", year: "numeric" };
		const formattedDate = dateObject.toLocaleDateString(undefined, options);
		return formattedDate;
	};

	/**
	 * Handles to change the active index
	 */
	const handleResetActiveIndex = useCallback((id, batch_number) => {
		setActiveBatch(id);
		setActiveBatchNumber(batch_number);
		setSkipImageCall(false);
	}, []);

	// Handle date selection change
	const handleDateChange = (date, setSelectedDate) => {
		setSelectedDate(date);
	};

	/**
	 * Handles on change btach id only after click.
	 */
	useEffect(() => {
		if (activeBatch > 0 && !skipImagetLoad) {
			getEditorImages(activeBatch);
		}
	}, [activeBatch]);
	/**
	 * Handles to on change event of staff.
	 */
	useEffect(() => {
		if (selectedStaff) {
			resetState(selectedStaffObject);
			getBatch(selectedStaffObject);
		} else {
			setStaffEvent("");
		}
	}, [selectedStaff]);

	/**
	 * Handles on change event of date filter.
	 */
	useEffect(() => {
		if (
			selectedFilter &&
			selectedFilter.value !== "custom" &&
			selectedStaffObject
		) {
			setSelectedDate("");
			setSelectedDate2("");
			getBatch(selectedStaffObject);
		}
	}, [selectedFilter]);

	/**
	 * Handles on change event of from date and to date and search
	 */
	useEffect(() => {
		getBatch(selectedStaffObject);
	}, [selectedDate, selectedDate2, search]);

	useEffect(() => {
		handleFetchProfileSettings(imageID);
	}, [imageID]);

	/**
	 * Handles to get the batch list based on staff selection, search,date filter.
	 * @param {Event} e - An event of staff objects.
	 * @returns {Array} - An array of objects having batch data .
	 */
	const getBatch = (e, value = "") => {
		if (e && e.value) {
			let editor_id = e.value;
			if (editor_id) {
				setBatchData([]);
				setBatchImages([]);
				setBatchLoader(true);
				setSkipImageCall(true);
				setSelectedImagesID([]);
				localStorage.removeItem("clickSelect");
				localStorage.removeItem("ctrlClicked");
				localStorage.removeItem("selected_all");
				let requestString = `?date=${
					selectedFilter ? selectedFilter.value : ""
				}`;
				if (selectedDate) {
					requestString += `&from_date=${moment(selectedDate).format(
						"YYYY-MM-DD"
					)}`;
				}
				if (selectedDate2) {
					requestString += `&to_date=${moment(selectedDate2).format(
						"YYYY-MM-DD"
					)}`;
				}
				if (search) {
					requestString += `&search_key=${search}`;
				}
				request
					.get(
						apiURL +
							GET_EDITOR_WORK_SPACE +
							`/${editor_id}` +
							`${requestString}`
					)
					.then((response) => {
						setBatchLoader(false);
						setBatchData(response.data.data.batches);
						setBatchNumber(response.data.data.batches);
						setBatchImages(response.data.data.images);
						imagesObj = response.data.data.images;
						if (response.data.data.batches.length > 0) {
							setActiveBatch(response.data.data.batches[0].id);
							setActiveBatchNumber(response.data.data.batches[0].batch_number);
						}
					})
					.catch((err) => {
						console.log("err ", err);
						setBatchLoader(false);
					});
			}
		}
	};

	let editTypeId = batchImages.map(function (currentObject) {
		return currentObject.id;
	}, {});

	let idSelected =
		selectedImagesID !== undefined &&
		selectedImagesID
			.slice(0, editTypeId.length)
			.toString()
			.split(/(?=(?:\d{3})+(?:\.|$))/g)
			.join(",")
			.replace(/"/g, "");

	/**
	 * Handles to get the images list based on batch id , search,date filter.
	 * @param {Number} e - An batch id.
	 * @returns {Array} - An array of objects having batch data .
	 */
	const getEditorImages = (batch_id) => {
		setBatchImages([]);
		setSelectedImagesID([]);
		setBatchImagesLoader(true);
		let requestString = `?date=${selectedFilter ? selectedFilter.value : ""}`;
		if (selectedDate) {
			requestString += `&from_date=${moment(selectedDate).format(
				"YYYY-MM-DD"
			)}`;
		}
		if (selectedDate2) {
			requestString += `&to_date=${moment(selectedDate2).format("YYYY-MM-DD")}`;
		}
		if (search) {
			requestString += `&search_key=${search}`;
		}
		request
			.get(apiURL + GET_EDITOR_BATCH_IMAGES + `/${selectedStaff}/${batch_id}`)
			.then((response) => {
				setBatchImagesLoader(false);
				setBatchImages(response.data.data);
				setReloadBatchImages(false);
				setResponseHeading("");
				setClosePopup(false);
				imagesObj = response.data.data;
			})
			.catch((err) => {
				setBatchImagesLoader(false);
			});
	};

	/**
	 * Handles on click of image selection event of images to select/unselect.
	 * @param {Number} imageId - An image id for select/un select.
	 */
	const setSelected = useCallback((imageId) => {
		var index = selectedImagesID.indexOf(imageId);
		if (index !== -1) {
			setSelectedImagesID(selectedImagesID.filter((i) => i !== imageId));
		} else {
			setSelectedImagesID([...selectedImagesID, imageId]);
		}
	});

	/**
	 * Handles to refresh the image batch id.
	 * @param {Number} imageId - An image id for select/un select.
	 */
	const setEditorImages = useCallback((imageId) => {
		getEditorImages(imageId);
	});

	/**
	 * Handles to reset the states.
	 * @param {Event} e - An event of staff objects.
	 * @returns {Array} - An array of objects having batch data .
	 */
	const resetState = (e) => {
		setFilterSelection("");
		setStaffOptions(e.value);
		setSearch("");
		setSelectedDate("");
		setSelectedDate2("");
		setBatchData([]);
		setBatchImages([]);
		setSelectedImagesID([]);
	};

	/**
	 * Handles to check/uncheck for all s.
	 * @returns {Boolean} - true false based on selecte images .
	 */
	useEffect(() => {
		getSelected();
	}, [selectedImagesID]);

	/**
	 * Handles to check/uncheck select all.
	 * @returns {Boolean} - true false based on selected images .
	 */
	const getSelected = () => {
		setSelectAll(
			selectedImagesID.length === batchImages.length &&
				batchImages.length > 0 &&
				selectedImagesID.length > 0
				? true
				: false
		);
	};

	/**
	 * Handles to check/uncheck for all images.
	 * @returns {Boolean} - true false based on selected images .
	 */
	const SelectAll = (e) => {
		if (e.currentTarget.checked) {
			let ary = [];
			batchImages.forEach((obj) => {
				ary.push(obj.id);
			});
			setSelectedImagesID(ary);
			// setSelectAll(true);
			localStorage.setItem("selected_all", true);
		} else {
			setSelectedImagesID([]);
			localStorage.removeItem("selected_all");
			// setSelectAll(false);
		}
	};

	/**
	 * Handles to reassign the editor .
	 * @param {Event} event - An event of staff objects.
	 * @returns {Array} - An array of objects having staff data .
	 */

	const setReassign = (event) => {
		setLoader(true);
		setshowReassign(true);
		event.preventDefault();
		if (token) {
			// Fetching editors user's  data from the API
			request
				.get(apiURL + `${GET_ALL_EDITORS}?editor_skip=${selectedStaff}`)
				.then((response) => {
					setLoader(false);
					setReAssignOptions([
						// { label: "", value: "" },
						...response.data.editors,
					]);
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles to reload the current editor request after reassign editor
	 * @param {Event} selectedStaffObject - An event of selected staff object.
	 */
	const reloadEditorRequest = useCallback((selectedStaffObject) => {
		setTimeout(() => {
			getBatch(selectedStaffObject);
		}, 1000);
	}, []);

	/**
	 * Handles to reset the image selection
	 */
	const resetSelectedImages = useCallback(() => {
		setSelectedImagesID([]);
	});

	/**
	 * Function to be called after download click.
	 *  * @param {Object} event -  Event Object.
	 *  * @param {String} download -  download all or specific.
	 *
	 */
	const downloadDocument = (event, download_type = "all") => {
		event.preventDefault();
		setBulkDownload(true);
		selectedImagesID.forEach((imageId, index) => {
			let imageIndex = batchImages.findIndex((d) => d.id == imageId);
			downloadImage(imageId, batchImages[imageIndex].image_path, index);
		});
	};

	/**
	 * Function to download an image by ID
	 *  * @param {Object} imageId -  image id .
	 *  * @param {String} imageName -  image name with extension.
	 *  * @param {Number} index -  index of calling number to show the button back based on
	 *     index and selected image length.
	 */
	const downloadImage = async (imageId, imageName, index) => {
		try {
			const formData = new FormData();
			const headers = {
				Authorization: token
					? "Bearer " + `${token.slice(1, token.length - 1)}`
					: "",
			};
			formData.append("editor_id", selectedStaffObject.value);
			formData.append("image_ids", imageId);
			axios({
				url: apiURL + EDITOR_DOWNLOAD_IMAGE,
				method: "POST",
				headers: headers,
				data: formData,
				responseType: "blob", // Important: Ensure the API response is Blob
			})
				.then((response) => {
					saveAs(response.data, imageName);
					if (index + 1 === selectedImagesID.length) {
						setBulkDownload(false);
						setSelectedImagesID([]);
					}
				})
				.catch((error) => {
					console.error("Error downloading:", error);
				});
		} catch (error) {
			setBulkDownload(false);
			console.error("Error downloading:", error);
		}
	};

	/**
	 * Handle the select all images state.
	 * @param {Object} e - event Object.
	 */

	const setSelectAllImages = (e) => {
		let ary = [];
		e.preventDefault();
		imagesObj.forEach((obj) => {
			ary.push(obj.id);
		});
		setSelectedImagesID(ary);
	};

	/**
	 * Handle the keydown event after keypress.
	 * @param {Object} e - event Object.
	 */

	const keydownHandler = (e) => {
		if (
			(e.ctrlKey || e.metaKey) &&
			e.which === 65 &&
			!e.target.classList.contains("skip")
		) {
			setSelectAllImages(e);
		}
		return false;
	};
	useEffect(() => {
		window.document.addEventListener("keydown", keydownHandler);
		return () => {
			window.document.removeEventListener("keydown", keydownHandler);
		};
	}, []);

	useEffect(() => {
		if (continuePSD) {
			uploadBulkImage();
		}
	}, [continuePSD]);

	/**
	 * Handle to refresh the batch list.
	 */
	useEffect(() => {
		if (closePopup && responseHeading === "Success" && reloadBatchImages) {
			getEditorImages(activeBatch);
		}
	}, [closePopup]);

	/**
	 * Handle the bulk image upload.
	 * @param {Object} event - event Object.
	 */

	const uploadBulkImage = (event) => {
		event = event || window.event;
		event.preventDefault();
		const bulkForm = new FormData();
		responseMSG = "";
		msg = [];

		let files = event.target;
		if ((files.files && files.files.length > 0) || continuePSD) {
			setBulkUploading(true);
			if (!continuePSD) {
				let formArray = [];
				bulkForm.append("editor_id", selectedStaffObject.value);
				bulkForm.append("batch_number", activeBatchNumber);
				for (let i = 0; i < files.files.length; i++) {
					bulkForm.append("order_images[]", files.files[i]);
					formArray.push(files.files[i]);
				}
				setReSubmitForm(formArray);
			} else {
				bulkForm.append("editor_id", selectedStaffObject.value);
				bulkForm.append("batch_number", activeBatchNumber);
				for (let i = 0; i < resubmitForm.length; i++) {
					bulkForm.append("order_images[]", resubmitForm[i]);
				}
				for (let i = 0; i < notreadyImages.length; i++) {
					bulkForm.append("not_ready[]", notreadyImages[i]);
				}
			}

			request
				.post(apiURL + EDITOR_UPLOAD_BULK_IMAGE, bulkForm)
				.then((response) => {
					const responseData = response.data;
					// Check and process the main message
					processMessage(responseData.message, msg);

					// Check and process the failed message
					processMessage(responseData.failed_msg, msg);

					// Check and process the missing PSD file message
					processMessage(responseData.missing_psd_msg, msg);

					// Check and process the missing required file message
					processMessage(responseData.missing_required_file_msg, msg);

					setResponseMessage(responseMSG);
					setResponseHeading(responseData.status ? "Success" : "Error");
					setBulkUploading(false);
					setReloadBatchImages(responseData.status);
					setPSDError(responseData.missing_psd);
					setConfirmMessage("");
					if (responseData.button) {
						setConfirmMessage(responseData.button);
					}

					if (responseData.missing_psd) {
						setModalShow(false);
						setNotReadyImages(responseData.not_ready);
					} else {
						setModalShow(true);
						setNotReadyImages([]);
					}
					setErrorMessage(msg);
					if (continuePSD) {
						setReSubmitForm([]);
						setNotReadyImages([]);
						continuePSDUpload(false);
						setConfirmMessage("");
					}
					const fileInput = document.getElementById(`file`);
					if (fileInput) {
						fileInput.value = null;
					}
				})
				.catch((err) => {
					console.log("error response", err);
					setBulkUploading(false);
					setModalShow(true);
					setResponseHeading("Error");
					setResponseMessage("Oops! something went wrong!");
					setPSDError(false);
					setReSubmitForm([]);
					setNotReadyImages([]);
					continuePSDUpload(false);
					setConfirmMessage("");
					const fileInput = document.getElementById(`file`);
					if (fileInput) {
						fileInput.value = null;
					}
				});
		} else {
			setBulkUploading(false);
		}
	};

	/**
	 * Handle the post request for submit to QC request.
	 * @param {Object} form - form Object.
	 */

	const handlePostRequest = async (form) => {
		try {
			const response = await request.post(
				apiURL + EDITOR_ASSIGN_QC_IMAGE,
				form
			);

			setResponseMessage(response.data.message);
			setResponseHeading(response.data.status ? "Success" : "Error");
			setsubmitQC(false);
			setModalShow(true);

			form.forEach((value, key) => form.delete(key));

			setTimeout(() => {
				if (response.data.status) {
					getEditorImages(activeBatch);
				}
				setModalShow(false);
				setSelectedAssigntoQCImagesID([]);
			}, 2000);
		} catch (err) {
			console.error("Error:", err.message);
			setsubmitQC(false);
			setModalShow(true);
			setResponseHeading("Error");
			setResponseMessage("Oops! Something went wrong! Please try again.");
			form.forEach((value, key) => form.delete(key));
		}
	};

	/**
	 * Handle the submit to QC event.
	 * @param {Object} event - event Object.
	 */

	const submitToQC = (event) => {
		event.preventDefault();
		setsubmitQC(true);
		form.append("editor_id", selectedStaffObject.value);
		form.append("batch_number", activeBatchNumber);
		form.append("image_ids", selectedAssigntoQCImagesId.toString());
		// Call the function with the necessary parameters
		handlePostRequest(form);
	};

	if (options.length === 0) return <Loader />;
	else {
		return (
			<>
				<div
					className="container-fluid page-body-wrapper"
					style={{ display: "block" }}
				>
					{/* <Sidebar /> */}
					<div className="main-panel editor_special">
						<div className="rightbar">
							<div className="section-title">
								<h2>Editor Workspace</h2>
							</div>
							<Row>
								<Col lg={6} sm={12}>
									<Form className="workspaceform">
										<Form.Group className="mb-3" controlId="formBasicEmail">
											<div className="col-lg-8">
												<div className="d-sm-flex align-itemd-center  align-items-center">
													<div className="form-group">
														<Form.Label className="label-div">
															Choose Staff{" "}
														</Form.Label>
													</div>

													<div
														className="form-group flex-grow-1"
														style={{ marginLeft: "5%" }}
													>
														<Select
															className="select-custom-width"
															id="select_editor"
															options={selectLabel(options)}
															onChange={(e) => {
																setStaffOptions(e.value);
																setStaffEvent(e);
															}}
														/>
													</div>
												</div>
											</div>
										</Form.Group>
									</Form>
								</Col>
							</Row>
							{selectedStaff && (
								<div className="workbody">
									<Form className="mb-3">
										<Row className="align-items-center">
											<Col lg={12} sm={12}>
												<div className="d-sm-flex editor flex-sm-wrap col-gap-14 align-items-center">
													<div
														className="flexxxxxx-fill"
														style={{ width: "34%" }}
													>
														<div
															className="form-group"
															style={{ width: "55%" }}
														>
															<Select
																options={date}
																onChange={(e) => setFilterSelection(e)}
																value={date.filter(
																	(option) =>
																		option.value ===
																		(selectedFilter
																			? selectedFilter.value
																			: null)
																)}
																className="w-100"
															/>
														</div>
													</div>
													<div
														className="flex-grow-1111 "
														style={{ marginLeft: "-14%" }}
													>
														<div
															className={"form-group"}
															style={{ width: "10%" }}
															onClick={(e) => {
																if (e.target.classList.contains("focused")) {
																	e.target.classList.remove("focused");
																} else {
																	e.target.classList.add("focused");
																}
															}}
															// onBlur={(e) => {
															//   e.target.classList.remove("focused");
															// }}
														>
															<Autocomplete
																suggestions={batchNumber}
																setSearch={setSearch}
																setImageDetailsView={[]}
																setImageDetailsViewId={[]}
																setImageDetailsModal={[]}
																isShow={isShow}
																setIsShow={setIsShow}
																className={"editor"}
															/>
														</div>
													</div>

													<div className="flex-grow-1111 exclude-click">
														<div
															className={"form-group exclude-click"}
															style={{
																marginLeft: (!bulkDownload ? 0 : 30) + "px",
																marginTop: (!bulkDownload ? 0 : 6) + "px",
															}}
														>
															{!bulkDownload ? (
																<Button
																	variant=""
																	className={
																		"exclude-click editor " +
																		(selectedImagesID.length === 0
																			? "disabled"
																			: "upload_image")
																	}
																	// className="editor disabled"
																	id=""
																	disabled={selectedImagesID.length === 0}
																	style={
																		{
																			// background: "#f6f3f4",
																			// color: "#d0d0d0",
																		}
																	}
																	onClick={(e) => {
																		if (batchImages.length > 0) {
																			downloadDocument(
																				e,
																				selectedImagesID.length ===
																					batchImages.length
																					? "all"
																					: "selected"
																			);
																		}
																	}}
																>
																	<img
																		src={
																			selectedImagesID.length === 0
																				? download_bulk_disabled
																				: download_bulk
																		}
																		alt="mindreach images"
																		style={{ width: "16px", height: "16px" }}
																	/>{" "}
																	Download
																</Button>
															) : (
																<div
																	style={{
																		display: "inline-block",
																	}}
																>
																	<Loader className={"loader_download"} />
																</div>
															)}
														</div>
													</div>
													<div className="flex-grow-1111 exclude-click">
														<div
															className={"form-group exclude-click"}
															style={{
																marginLeft:
																	(!bulkDownload
																		? !bulkUploading
																			? "-12"
																			: "-12"
																		: 10) + "px",
															}}
														>
															<>
																<Button
																	variant=""
																	className="editor download_image ml-3"
																	id=""
																	disabled={true}
																	style={
																		{
																			// border: "1px solid #3c79d3",
																		}
																	}
																	// Please ignore this line for code review
																	// onClick={(e) => {
																	//   document.getElementById(`file`).click();
																	// }}
																>
																	<img
																		src={upload_bulk}
																		alt="mindreach images"
																		style={{ width: "21px", height: "21px" }}
																	/>{" "}
																	Bulk Upload
																</Button>
																<input
																	type="file"
																	style={{ display: "none" }}
																	accept="image/png, image/gif, image/jpeg, .psd"
																	id={`file`}
																	multiple={true}
																	onChange={(e) => {
																		if (e.target.files.length > 0) {
																			uploadBulkImage(e);
																		}
																	}}
																/>
															</>
														</div>
													</div>
													<div
														className="flex-grow-1 "
														style={{
															marginLeft: "2%",
														}}
													>
														<div className={"form-group exclude-click"}>
															{!submitQC ? (
																<>
																	<Button
																		variant="primary"
																		className="btns"
																		id=""
																		disabled={true}
																		// onClick={(e) => {
																		//   submitToQC(e);
																		// }}
																	>
																		Submit to QC
																	</Button>
																</>
															) : (
																<div
																	style={{
																		display: "inline-block",
																	}}
																>
																	<Loader className={"assgin_qc"} />
																</div>
															)}
														</div>
													</div>
													<div
														className="flex-grow-1 exclude-click"
														style={{
															marginLeft: "-8%",
														}}
													>
														<div
															className={"form-group"}
															// style={{ width: "10%" }}
														>
															<>
																<Button
																	variant="primary"
																	className="btns"
																	id="reassign"
																	disabled={selectedImagesID.length === 0}
																	onClick={(e) => {
																		setReassign(e);
																	}}
																>
																	Reassign
																</Button>{" "}
															</>
														</div>
													</div>
												</div>
											</Col>
										</Row>
										<Row
											className="align-items-center"
											style={{ width: "20.1%" }}
										>
											{selectedFilter && selectedFilter.value === "custom" && (
												<div className="d-flex mt-2 date-picker--container">
													<div>
														<Form.Control
															type="date"
															value={moment(selectedDate).format("YYYY-MM-DD")}
															onChange={(e) =>
																handleDateChange(
																	new Date(e.target.value),
																	setSelectedDate
																)
															}
														/>
													</div>

													<div
													// style={{ marginLeft: "12px" }}
													>
														<Form.Control
															type="date"
															value={moment(selectedDate2).format("YYYY-MM-DD")}
															onChange={(e) =>
																handleDateChange(
																	new Date(e.target.value),
																	setSelectedDate2
																)
															}
														/>
													</div>
												</div>
											)}
										</Row>
									</Form>
									<div className="">
										<Row className="custom-row-workspace">
											{!showBatchLoader ? (
												<>
													<Col lg={2} sm={2} className="customcol-2 pl-0">
														<div className="editororderdetail-new">
															<EditorOrderDetailsidebar
																data={batchData}
																show={showBatchLoader}
																setSelectedImagesID={setSelectedImagesID}
																resetActiveIndex={handleResetActiveIndex}
																selectedStaff={selectedStaffObject}
																activeIndex={activeBatch}
																getEditorImages={getEditorImages}
															/>
														</div>
													</Col>
													<Col lg={9} sm={10} className="editor_ws_image pr-0">
														<EditorOrderDetails
															images={batchImages}
															show={showBatchImagesLoader}
															selectedImagesId={selectedImagesID}
															setSelectedImagesID={setSelectedImagesID}
															setImageSelection={setSelected}
															setSelectAll={setSelectAll}
															selectAll={selectAll}
															reassign={reassign}
															reassignSubmit={reassignSubmit}
															token={token}
															idSelected={idSelected}
															selectedStaff={selectedStaffObject}
															activeIndex={activeBatch}
															getEditorImages={getEditorImages}
															setImageID={setImageID}
															imageID={imageID}
															selectedAssigntoQCImagesId={
																selectedAssigntoQCImagesId
															}
															setSelectedAssigntoQCImagesID={
																setSelectedAssigntoQCImagesID
															}
															reloadBatchImages={reloadBatchImages}
															setReloadBatchImages={setReloadBatchImages}
															setClosePopup={setClosePopup}
															setResponseHeading={setResponseHeading}
															responseHeading={responseHeading}
															handleFetchProfileSettings={
																handleFetchProfileSettings
															}
															setting_profile={setting_profile}
														/>
													</Col>
												</>
											) : (
												<Col
													sm={12}
													style={{ marginTop: "5%" }}
													className="editor_ws"
												>
													<Loader />
												</Col>
											)}
										</Row>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				{showReassign && loader ? (
					<Loader style={{ zIndex: 9999999999 }} />
				) : (
					<ReAssignModal
						show={showReassign}
						onHide={() => {
							setshowReassign(false);
						}}
						heading={
							`Reassign Batch Image` + (selectedImagesID.length > 1 ? "s" : "")
						}
						options={reAssignOptions}
						selectedStaff={selectedStaffObject}
						selectedImagesID={selectedImagesID}
						setModalShow={setModalShow}
						reloadEditorRequest={reloadEditorRequest}
						setshowReassign={setshowReassign}
						setResponseHeading={setResponseHeading}
						setResponseMessage={setResponseMessage}
						setSelectAll={setSelectAll}
						resetSelectedImages={resetSelectedImages}
						reassignSubmit={reassignSubmit}
						reassign={reassign}
					/>
				)}
				<MyVerticallyCenteredModal
					style={{ zIndex: 999 }}
					show={modalShow}
					errorMessage={uploaderrorMessage}
					onHide={() => {
						setModalShow(false);
						if (reloadBatchImages) {
							setClosePopup(true);
						} else {
							setClosePopup(false);
						}
					}}
					heading={responseHeading}
					message={responseMessage}
					className="modal-body-body--success"
				/>
				<ConfirmBulkUploadModal
					show={isPSDError}
					uploaderrorMessage={uploaderrorMessage}
					onHide={() => {
						setPSDError(false);
						setErrorMessage([]);
						setBulkUploading(false);
						setModalShow(false);
						setResponseHeading("");
						setResponseMessage("");
						setPSDError(false);
						setReSubmitForm([]);
						setNotReadyImages([]);
						continuePSDUpload(false);
						setConfirmMessage("");
					}}
					title={"Error"}
					message={confirmMessage}
					handleConfirm={() => continuePSDUpload(true)}
				/>
			</>
		);
	}
};

export default EditorWorkspace;
