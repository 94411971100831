import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	Dropdown,
	Button,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Select from "react-select";

// import React from 'react'
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import DistributionBatchtable from "./DistributionBatchtable";
import EditTypes from "./EditTypes";
import EdittypesGroups from "./EdittypesGroups";
import AddEditytypesGroups from "./AddEditytypesGroups";

// Importing components and images
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";
import AddButton from "../../components/Button";
import user from "../../assets/Images/users.svg";
import Delete from "./../../assets/Images/delete.svg";
import plus from "./../../assets/Images/plus.svg";

import FtpAddModal from "./Components/Modal/Ftp/Add";
import FtpEditModal from "./Components/Modal/Ftp/Edit";
import DistributionBatchModal from "./Components/Modal/DistributionBatch";
import EditTypeEditModal from "./Components/Modal/EditType/Edit";
import AssignTeamAddModal from "./Components/Modal/AssignTeam/Add";
import StatusModal from "./../../components/Modal/StatusModal";

//Importing Status Modal
import MyVerticallyCenteredModal from "../../components/Modal";
import DeleteModalConfirmation from "../../components/Modal/DeleteModal";
import ActiveModalConfirmation from "../../components/Modal/ActivateModal";
import DeactiveModalConfirmation from "../../components/Modal/DeactivateModal";
import DuplicateModalConfirmation from "../../components/Modal/DuplicateModal";

// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
	VIEW_CUSTOMER_SETTINGS,
	VIEW_CUSTOMER_FTP,
	VIEW_CUSTOMER_EDIT_TYPE,
	VIEW_CUSTOMER_EDIT_GROUP,
	VIEW_CUSTOMER_ASSIGN_TEAM,
	DELETE_CUSTOMER_FTP,
	CUSTOMERS_DELETE_GROUP_TYPE_BY_ID,
	DELETE_TEAM_MEMBER,
	GET_TEAM_ROLE_LIST,
} from "../../config/apiRoutes.api";

/**
 * User component to display user data with pagination and search functionality.
 * @returns {JSX.Element} - The rendered User component.
 */
const Customersettings = () => {
	const token = sessionStorage.getItem("token");
	const navigate = useNavigate();
	const request = client(token);
	const bodyFormData = new FormData();
	const [ftps, setFtps] = useState([]);
	const [ftpRows, setFtpRows] = useState([]);
	const [deleteFtpShowByID, setDeleteFtpShowBYID] = useState(false);
	const [ftp, setFtp] = useState("");
	const [ftpId, setFtpId] = useState("");
	const [coordinatorBatchImageNo, setCoordinatorBatchImageNo] = useState([]);
	const [editorBatchImageNo, setEditorBatchImageNo] = useState([]);
	const [qcBatchImageNo, setQcBatchImageNo] = useState([]);
	const [companyId, setCompanyId] = useState([]);
	const [customerType, setCustomerType] = useState("");
	const [editTypes, setEditTypes] = useState([]);
	const [editTypeRows, setEditTypeRows] = useState([]);
	const [pageNumberEditType, setPageNumberEditType] = useState(1);
	const [editTypeId, setEditTypeId] = useState("");
	const [editGroups, setEditGroups] = useState([]);
	const [editGroupRows, setEditGroupRows] = useState([]);
	const [pageNumberEditGroup, setPageNumberEditGroup] = useState(1);
	const [deleteEditGroupShowByID, setDeleteEditGroupShowBYID] = useState(false);
	const [deleteGroupName, setDeleteGroupName] = useState("");
	const [deleteGroupId, setDeleteGroupId] = useState("");
	const [assignTeams, setAssignTeams] = useState([]);
	const [assignTeamRows, setAssignTeamRows] = useState([]);
	const [pageNumberAssignTeam, setPageNumberAssignTeam] = useState(1);
	const [deleteAssignMemberShowByID, setDeleteAssignMemberShowBYID] =
		useState(false);
	const [assignMember, setAssignMember] = useState("");
	const [assignMemberId, setAssignMemberId] = useState("");
	const [roles, setRolesData] = useState([]);
	const [selectRole, setRole] = useState("");
	const [searchMember, setSearchMember] = useState("");
	const { id } = useParams();

	// Modal
	const [ftp_add_modal, setFtpAddModal] = useState(false);
	const [ftp_edit_modal, setFtpEditModal] = useState(false);
	const [distributtion_batch_modal, setDistributionBatchModal] =
		useState(false);
	const [edit_type_add_modal, setEditTypeEditModal] = useState(false);
	const [assign_team_add_modal, setAssignTeamAddModal] = useState(false);
	const [status, setStatus] = useState("");
	const [modalShow, setModalShow] = React.useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [modalShowEdit, setModalShowEdit] = useState(false);
	const [modalShowDelete, setEditGroupModalShowDelete] = useState(false);
	// const [editGroupModal, setEditGroupModal] = useState(true);
	const [editGroupId, setEditGroupId] = useState("");
	const [statusModal, setStatusModal] = useState(false);

	/**
	 * Handles to remove edit group id
	 */
	const handleRemoveEditGroupId = useCallback(() => {
		setEditGroupId("");
	}, []);

	/**
	 * Handles to reset the edit group page to 1
	 */
	const handleResetEditGroupPage = useCallback(() => {
		setPageNumberEditGroup(1);
	}, []);

	const selectRolesLabel = (roles) =>
		roles.map(({ role: label, user_type: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of roles array as label and value return a new array of object like [{value:"",label:""}]

	useEffect(() => {
		if (token) {
			request
				.get(apiURL + GET_TEAM_ROLE_LIST)
				.then((response) => {
					console.log(response);
					setRolesData(response.data[0].roles);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	// If new page
	useEffect(() => {
		if (token) {
			// Fetching user data from the API based on params
			request
				.get(apiURL + VIEW_CUSTOMER_SETTINGS + `/${id}`)
				.then((response) => {
					console.log(response);
					setCoordinatorBatchImageNo(
						response.data[0].customer.company.batch_coordinator_image_no
					);
					setEditorBatchImageNo(
						response.data[0].customer.company.batch_editor_image_no
					);
					setQcBatchImageNo(
						response.data[0].customer.company.batch_qc_image_no
					);
					setCompanyId(response.data[0].customer.company.id);
					setCustomerType(response.data[0].customer.customer_type);
					/* setFtps(response.data[0].ftps);
					setFtpRows(response.data[0].ftps); */
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token) {
			// Fetching user data from the API based on params
			request
				.get(apiURL + VIEW_CUSTOMER_FTP + `/${id}`)
				.then((response) => {
					console.log(response);
					setFtps(response.data[0].customer_ftp_details);
					setFtpRows(response.data[0].customer_ftp_details);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token) {
			// Fetching user data from the API based on params
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_EDIT_TYPE +
						`/${id}` +
						`?page=${pageNumberEditType}`
				)
				.then((response) => {
					console.log(response);
					setEditTypes(response.data[0].edit_types);
					setEditTypeRows(response.data[0].edit_types.data);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumberEditType]);
	useEffect(() => {
		if (token) {
			// Fetching user data from the API based on params
			fetchEditGroups();
		}
	}, [pageNumberEditGroup]);

	useEffect(() => {
		if (token) {
			// Fetching user data from the API based on params
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_ASSIGN_TEAM +
						`/${id}` +
						`?page=${pageNumberAssignTeam}`
				)
				.then((response) => {
					console.log(response);
					setAssignTeams(response.data[0].assign_teams);
					setAssignTeamRows(response.data[0].assign_teams.data);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumberAssignTeam]);
	useEffect(() => {
		if (token) {
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_ASSIGN_TEAM +
						`/${id}` +
						`?page=${pageNumberAssignTeam}`
				)
				.then((response) => {
					console.log(response);
					setAssignTeams(response.data[0].assign_teams);
					setAssignTeamRows(response.data[0].assign_teams.data);
				})
				.catch((err) => console.log(err));
		}
	}, [!searchMember, !selectRole, pageNumberAssignTeam]);
	useEffect(() => {
		if (token && selectRole != "") {
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_ASSIGN_TEAM +
						`/${id}` +
						`?page=${pageNumberAssignTeam}&user_type=${selectRole}`
				)
				.then((response) => {
					console.log(response);
					setAssignTeams(response.data[0].assign_teams);
					setAssignTeamRows(response.data[0].assign_teams.data);
				})
				.catch((err) => console.log(err));
		}
	}, [!searchMember, selectRole, pageNumberAssignTeam]);
	useEffect(() => {
		if (token && searchMember != "") {
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_ASSIGN_TEAM +
						`/${id}` +
						`?page=${pageNumberAssignTeam}&search_key=${searchMember}`
				)
				.then((response) => {
					console.log(response);
					setAssignTeams(response.data[0].assign_teams);
					setAssignTeamRows(response.data[0].assign_teams.data);
				})
				.catch((err) => console.log(err));
		}
	}, [searchMember, !selectRole, pageNumberAssignTeam]);
	useEffect(() => {
		if (token && searchMember != "" && selectRole != "") {
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_ASSIGN_TEAM +
						`/${id}` +
						`?page=${pageNumberAssignTeam}&search_key=${searchMember}&user_type=${selectRole}`
				)
				.then((response) => {
					console.log(response);
					setAssignTeams(response.data[0].assign_teams);
					setAssignTeamRows(response.data[0].assign_teams.data);
				})
				.catch((err) => console.log(err));
		}
	}, [searchMember, selectRole, pageNumberAssignTeam]);

	// function to fetch the edit groups
	const fetchEditGroups = () => {
		request
			.get(
				apiURL +
					VIEW_CUSTOMER_EDIT_GROUP +
					`/${id}` +
					`?page=${pageNumberEditGroup}`
			)
			.then((response) => {
				console.log(" response ===>", response);
				setEditGroups(response.data[0].edit_groups);
				setEditGroupRows(response.data[0].edit_groups.data);
			})
			.catch((err) => console.log(err));
	};

	/**
	 * Handles reload of fetch edit group
	 */
	const handleReloadEditGroups = useCallback((newPageNumber) => {
		fetchEditGroups();
	}, []);

	//Paginations for Edit Type

	const PaginationEditTypeItems = () => {
		const { total, per_page, current_page } = editTypes;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumberEditType(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};
	const handleNextPaginationEditType = () => {
		if (
			pageNumberEditType !== Math.ceil(editTypes.total / editTypes.per_page)
		) {
			setPageNumberEditType(pageNumberEditType + 1);
		}
	};
	const handlePreviousPaginationEditType = () => {
		if (
			pageNumberEditType !== Math.ceil(editTypes.total / editTypes.per_page)
		) {
			setPageNumberEditType(pageNumberEditType - 1);
		}
		setPageNumberEditType(pageNumberEditType - 1);
	}; //end of Paginations Edit Type

	//Paginations for Edit Group
	const PaginationEditGroupItems = () => {
		const { total, per_page, current_page } = editGroups;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumberEditGroup(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};
	const handleNextPaginationEditGroup = () => {
		if (
			pageNumberEditGroup !== Math.ceil(editGroups.total / editGroups.per_page)
		) {
			setPageNumberEditGroup(pageNumberEditGroup + 1);
		}
	};
	const handlePreviousPaginationEditGroup = () => {
		if (
			pageNumberEditGroup !== Math.ceil(editGroups.total / editGroups.per_page)
		) {
			setPageNumberEditGroup(pageNumberEditGroup - 1);
		}
		setPageNumberEditGroup(pageNumberEditGroup - 1);
	}; //end of Paginations Edit Group

	//Paginations for Assign Team
	const PaginationAssignTeamItems = () => {
		const { total, per_page, current_page } = assignTeams;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumberAssignTeam(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};
	const handleNextPaginationAssignTeam = () => {
		if (
			pageNumberAssignTeam !==
			Math.ceil(assignTeams.total / assignTeams.per_page)
		) {
			setPageNumberAssignTeam(pageNumberAssignTeam + 1);
		}
	};
	const handlePreviousPaginationAssignTeam = () => {
		if (
			pageNumberAssignTeam !==
			Math.ceil(assignTeams.total / assignTeams.per_page)
		) {
			setPageNumberAssignTeam(pageNumberAssignTeam - 1);
		}
		setPageNumberAssignTeam(pageNumberAssignTeam - 1);
	}; //end of Paginations Assign Team

	/**
		Sets the deleteFtpShowBYID flag to true and updates the editType and editTypeId.
		@param {number} id - The ID of the row to be deleted.
		@param {string} name - The name of the row to be deleted.
	*/
	const handleFTPDeleteConfirmation = (id, name) => {
		setDeleteFtpShowBYID(true);
		setFtp(name);
		setFtpId(id);
	};

	/**
		Sets the deleteFtpShowBYID flag to true and updates the editType and editTypeId.
		@param {number} id - The ID of the row to be deleted.
		@param {string} name - The name of the row to be deleted.
	*/
	const handleAssignMemberDeleteConfirmation = (id, name) => {
		setDeleteAssignMemberShowBYID(true);
		setAssignMember(name);
		setAssignMemberId(id);
	};

	/**
		1.Deletes a row by ID.
		@param {number} id - The ID of the row to be deleted.
	*/
	const handleDelete = (id) => {
		if (token !== null) {
			request
				.get(apiURL + DELETE_CUSTOMER_FTP + `/${id}`)
				.then((response) => {
					console.log(response);
					handleFTPApiFetchOnSubmit();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
		1.Deletes a row by ID.
		@param {number} id - The ID of the row to be deleted.
	*/
	const handleAssignMemberDelete = (staff_id) => {
		if (token !== null) {
			const request = client(token);

			bodyFormData.append("user_id", staff_id);
			request
				.post(apiURL + DELETE_TEAM_MEMBER + `/${id}`, bodyFormData)
				.then((response) => {
					console.log("delete", response);
					setDeleteAssignMemberShowBYID(false);
					setStatus("Success");
					setSubmitStatusMessage(response.data.message);
					setEditGroupModalShowDelete(true);
					handleTeamMemberApiFetchOnSubmit();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
		1.Deletes a row by ID.
		@param {number} id - The ID of the row to be deleted.
	*/
	const handleEditGroupDelete = (id) => {
		if (token !== null) {
			request
				.get(apiURL + `${CUSTOMERS_DELETE_GROUP_TYPE_BY_ID}/${id}`)
				.then((response) => {
					console.log(" response delete ===>", response);
					setDeleteEditGroupShowBYID(false);
					setStatus("Success");
					setSubmitStatusMessage("Edit type group has been deleted!.");
					setEditGroupModalShowDelete(true);
					fetchEditGroups();
				})
				.catch((err) => console.log(err));
		}
	};

	const handleApiFetchOnSubmit = async (
		apiUrl,
		setData,
		setRowData,
		responseDataString,
		responseDataRowString
	) => {
		// Convert responseDataString back to an object if needed
		const responseData = JSON.parse(responseDataString);
		const responseDataRow = JSON.parse(responseDataRowString);

		// Refetch the data after successful form submission
		if (token) {
			request
				.get(apiURL + apiUrl + `/${id}`)
				.then((response) => {
					console.log(response);
					setData(responseData);
					setRowData(responseDataRow);
				})
				.catch((err) => console.log(err));
		}
	};

	const handleFTPApiFetchOnSubmit = () => {
		// Refetch the data after successful form submission
		if (token) {
			request
				.get(apiURL + VIEW_CUSTOMER_FTP + `/${id}`)
				.then((response) => {
					console.log(response);
					setFtps(response.data[0].customer_ftp_details);
					setFtpRows(response.data[0].customer_ftp_details);
				})
				.catch((err) => console.log(err));
		}
	};

	const handleTeamMemberApiFetchOnSubmit = () => {
		// Refetch the data after successful form submission
		if (token) {
			// Fetching user data from the API based on params
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_ASSIGN_TEAM +
						`/${id}` +
						`?page=${pageNumberAssignTeam}`
				)
				.then((response) => {
					console.log(response);
					setAssignTeams(response.data[0].assign_teams);
					setAssignTeamRows(response.data[0].assign_teams.data);
				})
				.catch((err) => console.log(err));
		}
	};

	const handleEditTypeApiFetchOnSubmit = () => {
		// Refetch the data after successful form submission
		if (token) {
			// Fetching user data from the API based on params
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_EDIT_TYPE +
						`/${id}` +
						`?page=${pageNumberEditType}`
				)
				.then((response) => {
					console.log(response);
					setEditTypes(response.data[0].edit_types);
					setEditTypeRows(response.data[0].edit_types.data);
				})
				.catch((err) => console.log(err));
		}
	};

	const defaultSorted = [
		{
			dataField: "name",
			order: "desc",
		},
	];

	/**
	 *    Change the date format in dd MM YY format.
	 *  * @param {date} inputDate - Date Object.
	 */
	const formatDate = (inputDate) => {
		inputDate = inputDate ? inputDate.substring(0, 10) : "";
		if (!inputDate) return false;
		const date = new Date(inputDate); // 2009-11-10
		const shortMonth = date.toLocaleString("default", { month: "short" });
		let d = new Date(inputDate);
		return [pad(d.getDate()), shortMonth, d.getFullYear()].join(" ");
	};

	/**
	 *    Append the zero to numbers less than 2 digits.
	 *  * @param {number} s - Date Object.
	 */
	let pad = (s) => {
		return s < 10 ? "0" + s : s;
	};

	/**
		Sets the deleteFtpShowBYID flag to true and updates the editType and editTypeId.
		@param {number} id - The ID of the row to be deleted.
		@param {string} name - The name of the row to be deleted.
	*/
	const handleGroupTypeDeleteConfirmation = (e, id, name) => {
		e.stopPropagation();
		setDeleteEditGroupShowBYID(true);
		setDeleteGroupName(name);
		setDeleteGroupId(id);
	};

	// <BootstrapTable
	//     keyField="id"
	//     data={ftp}
	//     columns={columns}
	//     striped
	//     hover
	//     condensed
	//     defaultSortDirection="asc"
	// />

	return (
		<>
			<div className="d-sm-flex justify-content-between align-items-center mb-4">
				<div className="">
					<h4>FTP Accounts</h4>
				</div>
				<div className="">
					<Button
						variant="primary"
						onClick={() => {
							setFtpAddModal(true);
						}}
						// onClick={() => navigate(EDIT_TYPE_ADD_PAGE)}
					>
						<span>
							<img src={plus} className="img-fluid mr-2" alt="plus icon" />
						</span>
						New FTP
					</Button>{" "}
				</div>
			</div>
			<div
				className={`table-responsive table-b custom-new-table mb-4`}
				style={{ marginTop: "3rem !important" }}
			>
				<Table hover>
					<thead>
						<tr>
							{typeof ftps !== "undefined" && ftps.length !== 0 && (
								<>
									<th>Id</th>
									<th>Host</th>
									<th>Port</th>
									<th>User Name</th>
									<th>Password</th>
									<th>Status</th>
									<th></th>
								</>
							)}
						</tr>
					</thead>
					<tbody>
						{ftps !== undefined &&
							ftpRows.map((item, index) => {
								const list = (
									<>
										<tr key={item.id + item.host}>
											<td
												onClick={() => {
													setFtpEditModal(true);
													// console.log("clicked",item.encrypted_id)
													setFtpId(item.id);
												}}
											>
												{item.id}
											</td>
											<td
												onClick={() => {
													setFtpEditModal(true);
													// console.log("clicked",item.encrypted_id)
													setFtpId(item.id);
												}}
											>
												{item.host}
											</td>
											<td
												onClick={() => {
													setFtpEditModal(true);
													// console.log("clicked",item.encrypted_id)
													setFtpId(item.id);
												}}
											>
												{item.port}
											</td>
											<td
												onClick={() => {
													setFtpEditModal(true);
													// console.log("clicked",item.encrypted_id)
													setFtpId(item.id);
												}}
											>
												{item.user_name}
											</td>
											<td
												onClick={() => {
													setFtpEditModal(true);
													// console.log("clicked",item.encrypted_id)
													setFtpId(item.id);
												}}
											>
												{item.user_name}
											</td>

											<td
												onClick={() => {
													setFtpEditModal(true);
													// console.log("clicked",item.encrypted_id)
													setFtpId(item.id);
												}}
											>
												{item.status}
											</td>
											<td className="edittd">
												<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
													<div
														className=""
														onClick={(e) =>
															handleFTPDeleteConfirmation(item.id, item.host)
														}
													>
														<img
															src={Delete}
															className="img-fluid"
															alt="Delete"
														/>
													</div>
												</div>
											</td>
										</tr>
									</>
								);
								return list;
							})}
						{typeof ftps !== "undefined" && ftps.length === 0 && (
							<NoDataFound />
						)}
					</tbody>
				</Table>
			</div>

			<h4 className="mb-4">Distribution Batch Settings</h4>

			<div
				className={`table-responsive table-b custom-new-table mb-4`}
				style={{ marginTop: "3rem !important" }}
			>
				<Table hover>
					<thead>
						<tr>
							<th>Coordinator Batch Size</th>
							<th>Editor Batch Size</th>
							<th>QC Batch Size</th>
						</tr>
					</thead>
					<tbody>
						<tr key={companyId}>
							<td
								onClick={() => {
									setDistributionBatchModal(true);
									// console.log("clicked",item.encrypted_id)
								}}
							>
								{coordinatorBatchImageNo} Image(s)
							</td>
							<td
								onClick={() => {
									setDistributionBatchModal(true);
									// console.log("clicked",item.encrypted_id)
								}}
							>
								{editorBatchImageNo} Image(s)
							</td>
							<td
								onClick={() => {
									setDistributionBatchModal(true);
									// console.log("clicked",item.encrypted_id)
								}}
							>
								{qcBatchImageNo} Image(s)
							</td>
						</tr>
					</tbody>
				</Table>
			</div>

			<div className="d-sm-flex justify-content-between align-items-center mb-4">
				<div className="">
					<h4>Edit Types</h4>
				</div>
				<div className="">
					{/* <div className="">
                    <Button
                        variant="primary"
                        onClick={() => {
                            setEditTypeEditModal(true);
                        }}
                        // onClick={() => navigate(EDIT_TYPE_ADD_PAGE)}
                    >
                        <span>
                            <img src={plus} className="img-fluid mr-2" alt="plus icon" />
                        </span>
                        New FTP
                    </Button>{" "}
                </div> */}
				</div>
			</div>

			<div
				className={`table-responsive table-b custom-new-table mb-4`}
				style={{ marginTop: "3rem !important" }}
			>
				<Table hover>
					<thead>
						<tr>
							{typeof editTypes.data !== "undefined" &&
								editTypes.data.length !== 0 && (
									<>
										<th>Id</th>
										<th>Name</th>
										<th>Non Enterprise Price</th>
										<th>Custom Price</th>
										<th>Editor Score</th>
										<th>QC Score</th>
										<th>Status</th>
									</>
								)}
						</tr>
					</thead>
					<tbody>
						{editTypes.data !== undefined &&
							editTypeRows.map((item, index) => {
								const list = (
									<>
										<tr key={index}>
											<td
												onClick={() => {
													if (customerType === "enterprice") {
														setEditTypeEditModal(true);
														setEditTypeId(item.id);
													} else {
														setStatusModal(true);
													}
												}}
											>
												{item.id}
											</td>
											<td
												onClick={() => {
													if (customerType === "enterprice") {
														setEditTypeEditModal(true);
														setEditTypeId(item.id);
													} else {
														setStatusModal(true);
													}
												}}
											>
												{item.edit_type}
											</td>
											<td
												onClick={() => {
													if (customerType === "enterprice") {
														setEditTypeEditModal(true);
														setEditTypeId(item.id);
													} else {
														setStatusModal(true);
													}
												}}
											>
												{item.standard_non_enterprise_price}
											</td>
											<td
												onClick={() => {
													if (customerType === "enterprice") {
														setEditTypeEditModal(true);
														setEditTypeId(item.id);
													} else {
														setStatusModal(true);
													}
												}}
											>
												{item.price}
											</td>
											<td
												onClick={() => {
													if (customerType === "enterprice") {
														setEditTypeEditModal(true);
														setEditTypeId(item.id);
													} else {
														setStatusModal(true);
													}
												}}
											>
												{item.editor_point}
											</td>
											<td
												onClick={() => {
													if (customerType === "enterprice") {
														setEditTypeEditModal(true);
														setEditTypeId(item.id);
													} else {
														setStatusModal(true);
													}
												}}
											>
												{item.qc_point}
											</td>

											<td
												onClick={() => {
													console.log(customerType);
													if (customerType == "enterprice") {
														setEditTypeEditModal(true);
														setEditTypeId(item.id);
													} else {
														setStatusModal(true);
													}
												}}
											>
												{item.is_active === "1" ? (
													<div className="active-staff">Active</div>
												) : (
													<div>Inactive</div>
												)}
												{console.log(item.is_active)}
											</td>
										</tr>
									</>
								);
								return list;
							})}
						{typeof editTypes.data !== "undefined" &&
							editTypes.data.length === 0 && <NoDataFound />}
					</tbody>
				</Table>
			</div>

			<Row className="mt-4">
				<Col md={{ span: 4, offset: 8 }}>
					{
						<Pagination>
							<Pagination.Prev
								onClick={() => handlePreviousPaginationEditType()}
							/>
							{PaginationEditTypeItems()}
							<Pagination.Next onClick={() => handleNextPaginationEditType()} />
						</Pagination>
					}
				</Col>
			</Row>

			<div className="d-sm-flex justify-content-between align-items-center mb-4">
				<div className="">
					<h4>Edit Type Groups</h4>
				</div>
				<div className="">
					<AddEditytypesGroups
						reloadEditGroup={handleReloadEditGroups}
						groupID={editGroupId}
						removeEditGroupId={handleRemoveEditGroupId}
						resetEditGroupPage={handleResetEditGroupPage}
					/>
				</div>
			</div>

			<div
				className={`table-responsive table-b custom-new-table mb-4`}
				style={{ marginTop: "3rem !important" }}
			>
				<Table hover>
					<thead>
						<tr>
							{typeof editGroups.data !== "undefined" &&
								editGroups.data.length !== 0 && (
									<>
										<th>Group Name</th>
										<th>Created On</th>
										<th>Status</th>
										<th></th>
									</>
								)}
						</tr>
					</thead>
					<tbody>
						{editGroups.data !== undefined &&
							editGroupRows.map((item, index) => {
								const list = (
									<>
										<tr
											key={index}
											onClick={() => {
												setEditGroupId(item.id);
											}}
										>
											<td>{item.group_name}</td>
											<td> {formatDate(item.created_at)}</td>
											<td>
												{item.is_active === "1" ? (
													<div className="active-staff">Active</div>
												) : (
													<div>Inactive</div>
												)}
											</td>
											<td className="edittd">
												<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
													<div
														className=""
														onClick={(e) =>
															handleGroupTypeDeleteConfirmation(
																e,
																item.id,
																item.group_name
															)
														}
													>
														<img
															src={Delete}
															className="img-fluid"
															alt="Delete"
														/>
													</div>
												</div>
											</td>
										</tr>
									</>
								);
								return list;
							})}
						{typeof editGroups.data !== "undefined" &&
							editGroups.data.length === 0 && <NoDataFound />}
					</tbody>
				</Table>
			</div>

			<Row className="mt-4">
				<Col md={{ span: 4, offset: 8 }}>
					{
						<Pagination>
							<Pagination.Prev
								onClick={() => handlePreviousPaginationEditGroup()}
							/>
							{PaginationEditGroupItems()}
							<Pagination.Next
								onClick={() => handleNextPaginationEditGroup()}
							/>
						</Pagination>
					}
				</Col>
			</Row>

			<h4 className="mb-4">Assigned Team</h4>
			<div className="d-sm-flex mb-3">
				<div className="customer-search">
					<Form>
						<Row className="align-items-center">
							<Col xs={2}>
								<Select
									onChange={(e) => {
										setRole(e.value);
									}}
									options={selectRolesLabel(roles)}
									placeholder={"Select Roles"}
									maxMenuHeight="120px"
								/>
							</Col>
							<Col>
								<Form.Control
									type="search"
									name="search"
									placeholder="Search Staff"
									aria-label="Search"
									className="me-2 search-icon"
									onChange={(e) => setSearchMember(e.target.value)}
								/>
							</Col>
							<Col>
								<p className="customer-count">
									<span>
										<img
											src={user}
											className="img-fluid"
											alt="mindreach images"
										/>
									</span>
									{assignTeams === null ? 0 : assignTeams.total} User(s)
								</p>
							</Col>
						</Row>
					</Form>
				</div>
				<div>
					<Button
						variant="primary"
						onClick={() => {
							setAssignTeamAddModal(true);
						}}
					>
						<span>
							<img src={plus} className="img-fluid mr-2" alt="plus icon" />
						</span>
						Add New Assign Team
					</Button>{" "}
				</div>
			</div>

			<div
				className={`table-responsive table-b custom-new-table mb-4`}
				style={{ marginTop: "3rem !important" }}
			>
				<Table hover>
					<thead>
						<tr>
							{typeof assignTeams.data !== "undefined" &&
								assignTeams.data.length !== 0 && (
									<>
										<th>Id</th>
										<th>Name</th>
										<th>Role</th>
										<th>Shift</th>
										<th>Status</th>
										<th></th>
									</>
								)}
						</tr>
					</thead>
					<tbody>
						{assignTeams.data !== undefined &&
							assignTeamRows.map((item, index) => {
								const list = (
									<>
										<tr key={index}>
											<td>{item.id}</td>
											<td>{item.full_name}</td>
											<td>{item.user_type}</td>
											<td>{item.shift_name}</td>
											<td>
												{item.is_active === "1" ? (
													<div className="active-staff">Active</div>
												) : (
													<div>Inactive</div>
												)}
												{console.log(item.is_active)}
											</td>
											<td className="edittd">
												<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
													<div
														className=""
														onClick={(e) =>
															handleAssignMemberDeleteConfirmation(
																item.id,
																item.full_name
															)
														}
													>
														<img
															src={Delete}
															className="img-fluid"
															alt="Delete"
														/>
													</div>
												</div>
											</td>
										</tr>
									</>
								);
								return list;
							})}
						{typeof assignTeams.data !== "undefined" &&
							assignTeams.data.length === 0 && <NoDataFound />}
					</tbody>
				</Table>
			</div>

			<Row className="mt-4">
				<Col md={{ span: 4, offset: 8 }}>
					{
						<Pagination>
							<Pagination.Prev
								onClick={() => handlePreviousPaginationAssignTeam()}
							/>
							{PaginationAssignTeamItems()}
							<Pagination.Next
								onClick={() => handleNextPaginationAssignTeam()}
							/>
						</Pagination>
					}
				</Col>
			</Row>

			<MyVerticallyCenteredModal
				show={modalShowEdit}
				onHide={() => {
					setModalShowEdit(false);
				}}
				heading={status}
				message={submitStatusMessage}
				className="modal-body-body--success"
			/>
			<DeleteModalConfirmation
				show={deleteFtpShowByID}
				onHide={() => {
					setDeleteFtpShowBYID(false);
				}}
				branch={"ftp"}
				name={ftp}
				title={"Delete FTP"}
				handleConfirm={() => handleDelete(ftpId)}
			/>
			<FtpAddModal
				show={ftp_add_modal}
				onHide={() => {
					setFtpAddModal(false);
				}}
				heading={"Add FTP Details"}
				id={id}
				setFtpAddModal={setFtpAddModal}
				setStatus={setStatus}
				setModalShow={setModalShow}
				setSubmitStatusMessage={setSubmitStatusMessage}
				setModalShowEdit={setModalShowEdit}
				handleFTPApiFetchOnSubmit={handleFTPApiFetchOnSubmit}
			/>
			<FtpEditModal
				show={ftp_edit_modal}
				onHide={() => {
					setFtpEditModal(false);
				}}
				heading={"Update FTP Details"}
				id={id}
				ftpId={ftpId}
				setFtpEditModal={setFtpEditModal}
				setStatus={setStatus}
				setModalShow={setModalShow}
				setSubmitStatusMessage={setSubmitStatusMessage}
				setModalShowEdit={setModalShowEdit}
				setFtps={setFtps}
				setFtpRows={setFtpRows}
				handleFTPApiFetchOnSubmit={handleFTPApiFetchOnSubmit}
			/>
			<DistributionBatchModal
				show={distributtion_batch_modal}
				onHide={() => {
					setDistributionBatchModal(false);
				}}
				heading={"Update Distribution Batch Details"}
				id={id}
				coordinatorBatchImageNo={coordinatorBatchImageNo}
				editorBatchImageNo={editorBatchImageNo}
				qcBatchImageNo={qcBatchImageNo}
				setCoordinatorBatchImageNo={setCoordinatorBatchImageNo}
				setEditorBatchImageNo={setEditorBatchImageNo}
				setQcBatchImageNo={setQcBatchImageNo}
				setDistributionBatchModal={setDistributionBatchModal}
				setStatus={setStatus}
				setModalShow={setModalShow}
				setSubmitStatusMessage={setSubmitStatusMessage}
				setModalShowEdit={setModalShowEdit}
			/>
			<EditTypeEditModal
				show={edit_type_add_modal}
				onHide={() => {
					setEditTypeEditModal(false);
				}}
				id={id}
				heading={"Edit Standard Edit Type"}
				setEditTypeEditModal={setEditTypeEditModal}
				setStatus={setStatus}
				setModalShow={setModalShow}
				setSubmitStatusMessage={setSubmitStatusMessage}
				setModalShowEdit={setModalShowEdit}
				edit_id={editTypeId}
				handleEditTypeApiFetchOnSubmit={handleEditTypeApiFetchOnSubmit}
			/>

			<AssignTeamAddModal
				show={assign_team_add_modal}
				onHide={() => {
					setAssignTeamAddModal(false);
				}}
				heading={"Add Assign Team Details"}
				id={id}
				setAssignTeamAddModal={setAssignTeamAddModal}
				setStatus={setStatus}
				setModalShow={setModalShow}
				setSubmitStatusMessage={setSubmitStatusMessage}
				setModalShowEdit={setModalShowEdit}
				handleTeamMemberApiFetchOnSubmit={handleTeamMemberApiFetchOnSubmit}
			/>

			<DeleteModalConfirmation
				show={deleteAssignMemberShowByID}
				onHide={() => {
					setDeleteAssignMemberShowBYID(false);
				}}
				branch={"assignMember"}
				name={assignMember}
				title={"Delete Assign Team Member"}
				handleConfirm={() => handleAssignMemberDelete(assignMemberId)}
			/>

			<DeleteModalConfirmation
				show={deleteEditGroupShowByID}
				onHide={() => {
					setDeleteEditGroupShowBYID(false);
				}}
				branch={"Edit Group"}
				name={deleteGroupName}
				title={"Delete Edit Group"}
				handleConfirm={() => handleEditGroupDelete(deleteGroupId)}
			/>
			<MyVerticallyCenteredModal
				show={modalShowDelete}
				onHide={() => {
					setEditGroupModalShowDelete(false);
					setStatus("");
					setSubmitStatusMessage("");
				}}
				heading={status}
				message={submitStatusMessage}
				className="modal-body-body--success"
			/>
			<StatusModal
				show={statusModal}
				onHide={() => {
					setStatusModal(false);
				}}
				title={`Uneditable Settings`}
				status={`This is a Non-Enterprise customer which follows a standard Price, Editor Score, and QC score, and thus the settings cannot be changed.`}
				className="modal-body-body--success"
				btnText={"Okay"}
			/>
		</>
	);
};

export default Customersettings;
