import React from 'react'
import Sidebar from '../../components/global/Sidebar/Sidebar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import UserChat from './UserChat';
import UserChatSearchsidebar from './UserChatSearchsidebar'
import UserGroupChatSearchsidebar from './UserGroupChatSearchsidebar'
import CustomerSearchsidebar from './CustomerSearchsidebar'
import CustomerChat from './CustomerChat'





const Chats = () => {
    return (
        <>


            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="rightbar  supervisor-chat-wrap">
                        <div className="section-title">
                            <h2>Chat</h2>
                        </div>

                        <div className="detailstab">


                            <Col className="chattabcontent s-left-0">
                                <Tabs
                                    defaultActiveKey="Customers"
                                    id="justify-tab-example"
                                    className="customertab"
                                    justify
                                >
                                    <Tab eventKey="Mindreach" title="Mindreach">
                                        <Row>
                                            <Col lg={3} sm={4} className="pad0 u-list">
                                                <div className="chatsidebar">
                                                    <UserChatSearchsidebar />
                                                    <UserGroupChatSearchsidebar />
                                                </div>
                                            </Col>
                                            <Col lg={9} sm={8} className="supervisor-chat p-0">

                                                <UserChat />

                                            </Col>
                                        </Row>

                                    </Tab>
                                    <Tab eventKey="Customers" title="Customers" >
                                        <Row>
                                            <Col lg={3} sm={4} className="pad0 s-list">
                                                <div className="chatsidebar">
                                                    <CustomerSearchsidebar />
                                                </div>
                                            </Col>
                                            <Col lg={9} sm={8} className="p-0">

                                                <CustomerChat />

                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>


                            </Col>


                            <div className="seacrhbox">
                                <Form>

                                    <Form.Control
                                        type="search"
                                        placeholder="Search in Customer "
                                        className="me-2 search-icon"
                                        aria-label="Search"
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>










        </>
    )
}

export default Chats