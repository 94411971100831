import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
	Row,
	OverlayTrigger,
	Tooltip,
	Col,
	Form,
	Button,
} from "react-bootstrap";

import EditorOrderDetailsidebar from "./EditorOrderDetailsidebar";
import CoordinatorOrderDetails from "./CoordinatorOrderDetails";
import Autocomplete from "./Components/AutoComplete";
import ReassignsModal from "./Components/ReassignedModal";
import Treeview from "./Treeview";
import Select from "react-select";
import moment from "moment";

//Importing the images
import Delete from "./../../assets/Images/delete.svg";
import duplicate from "./../../assets/Images/coordinator_actions_icon/Duplicate _Final.svg";

import CancelIcon from "./../../assets/Images/coordinator_actions_icon/cancel_Final.svg";
import MoreIcon from "./../../assets/Images/coordinator_actions_icon/more-Final.svg";
import LinkIcon from "./../../assets/Images/coordinator_actions_icon/Link.svg";
import DocsIcon from "./../../assets/Images/coordinator_actions_icon/Doc.svg";
import Add from "./../../assets/Images/coordinator_actions_icon/Plus.svg";

// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
	GET_ALL_COORDINATORS,
	ADMIN_GET_COORDINATORS_DETAILS,
	CO_ORDINATOR_VIEW_PROFILE_SETTINGS,
} from "../../config/apiRoutes.api";

const CoordinatorWorkspace = () => {
	const selectdays = [
		{ label: "Today", value: 355 },
		{ label: "Custom", value: 54 },
	];
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
	// Retrieve the token from session storage
	const token = sessionStorage.getItem("token");
	//Role Checking
	const roleJson = sessionStorage.getItem("role");
	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserRoles = [role !== null ? role.role : "login"];

	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();

	// Create a request instance using the token
	const request = client(token);

	// Create a new FormData object for sending data in HTTP requests
	const bodyFormData = new FormData();
	const wrapper = useRef(null);
	//Declarations of state
	const [editGroups, setEditGroups] = useState([]); // To set the edit groups
	const [editTypes, setEditTypes] = useState([]); // To set the edit types
	const [image_details_view, setImageDetailsView] = useState({});
	const [image_details_view_id, setImageDetailsViewId] = useState("");
	const [image_details_modal, setImageDetailsModal] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [multiple_images_id, setMultipleImagesId] = useState([]);
	const [folders, setFolder] = useState([]);
	const [parentFolderDetails, setParentFolderDetails] = useState(null);
	const [imagesMainFolder, setImageMainFolder] = useState([]);
	const [folderMainFolder, setFolderMainFolder] = useState([]);
	const [folderSubFolder, setFolderSubFolder] = useState([]);
	const [folder_files_sub, setFolderListSub] = useState([]);
	const [list_display, setDisplay] = useState(false);
	const [list_sub_display, setDisplaySub] = useState(false);
	const [folder_list, setFolderList] = useState([]);
	const [folder_name, setFolderName] = useState("");
	const [folder_list_menu, setFolderListMenu] = useState([]);
	const [folder_list_name, setFolderListName] = useState([]);
	const [loader_sub_menu, setLoaderSubMenu] = useState(false);
	const [folders_path, setFoldersPath] = useState([]);

	const [clickSelect, setClickSelect] = useState(false);
	const [options, setOptions] = useState([]);
	const [selectOptions, setSelectOptions] = useState("");
	const [batchNumber, setBatchNumber] = useState([]);
	const [images, setImages] = useState([]);
	const [folder_path, setFolderPath] = useState([]);
	const [batchId, setBatchID] = useState("");
	const [error, setError] = useState("");
	const [sub_folder_open, setSubFolderOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [selectedOption, setSelectedOption] = useState("custom");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDate2, setSelectedDate2] = useState(new Date());
	const [selectImageName, setSelectedImageName] = useState("");
	const [reassignedModal, setReassignedModal] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [mainImageCount, setMainImageCount] = useState(0);
	const [mainImageSettingsCount, setMainImageSettingsCount] = useState(0);
	const [order_number, setOrderNumber] = useState("");
	const [imageID, setImageID] = useState("");
	const [setting_profile, setSettingsProfile] = useState([]);
	const [edit_select, setEditorType] = useState("");
	const [showBatchLoader, setBatchLoader] = useState(true);
	const [activeBatch, setActiveBatch] = useState(0);
	const [activeBatchNumber, setActiveBatchNumber] = useState(0);

	const [hide_list_menu, setHideListMenu] = useState(false);
	/**
	 * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} options - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (options) =>
		options.map(({ first_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]
	const getFormattedDate = (dateString) => {
		// Create a new Date object from the date string
		const dateObject = new Date(dateString);
		// Format the date as "day Month year" (e.g., "25 July 2023")
		const options = { day: "numeric", month: "long", year: "numeric" };
		const formattedDate = dateObject.toLocaleDateString(undefined, options);
		return formattedDate;
	};
	// Determine if the button should be disabled
	const isButtonDisabled =
		multiple_images_id.length > 0 || folder_list_name.length > 0;
	const handleDateChange = (date, setSelectedDate) => {
		setSelectedDate(date);
	};
	const date = [
		{ label: "Custom", value: "custom" },
		{ label: "Today", value: "today" },
		{ label: "Current Month", value: "month" },
		{ label: "Current Year", value: "year" },
		{ label: "All", value: "all" },
	];
	let editTypeId = images.map(function (currentObject) {
		return currentObject.id;
	}, {});
	let folderPathSet = folder_list.map(function (currentObject) {
		return currentObject.path;
	}, {});
	let idSelected =
		multiple_images_id !== undefined
			? multiple_images_id
					.filter((id) => !isNaN(id)) // Filter out non-numeric values
					.map((id) => parseInt(id)) // Convert to integers
					.join(",")
			: "";
	let folderPathSelected =
		folder_list !== undefined
			? folder_list
					.filter((item) => item.path !== undefined) // Filter out objects without a 'path' property
					.map((item) => item.path) // Extract the 'path' property from each object
					.join(",")
			: "";
	useEffect(() => {
		if (token) {
			// Fetching coordinators user's  data from the API
			request
				.get(
					apiURL +
						GET_ALL_COORDINATORS +
						`?customer_type=${role.role}` +
						`&search_key=''`
				)
				.then((response) => {
					setOptions([
						{ label: "None", value: "" },
						...response.data.coordinators,
					]);
				})
				.catch((err) => console.log(err));
		}
	}, [selectOptions]);
	const fetchOrder = () => {
		if (token && selectOptions !== "") {
			request
				.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${selectOptions}`)
				.then((response) => {
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setBatchNumber(response.data.data.batchNumbers);
					setActiveBatch(response.data.data.batchNumbers[0].id);
					setActiveBatchNumber(response.data.data.batchNumbers[0].batch_number);
					setImages(response.data.data.images);
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		}
	};
	useEffect(() => {
		if (token && selectOptions !== "") {
			request
				.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${selectOptions}`)
				.then((response) => {
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setFolderName("");
					setParentFolderDetails(null);
					setBatchNumber(response.data.data.batchNumbers);
					setActiveBatch(response.data.data.batchNumbers[0].id);
					setActiveBatchNumber(response.data.data.batchNumbers[0].batch_number);
					setImages(response.data.data.images);
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		}
	}, [selectOptions]);
	useEffect(() => {
		if (token && batchId !== "" && search === "") {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${selectOptions}` +
						`?batch_number=${batchId}`
				)
				.then((response) => {
					// setImages(response.data.data.images);
					// setFolderList(response.data.data.folders);
					// setFolder(response.data.data.folders);
					// setMultipleImagesId([]);
					// // setClickSelect(false);
					setParentFolderDetails(null);
					setFolderName("");
					// setClickSelect(false);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					// setBatchNumber(response.data.data.batchNumbers);
					setImages(response.data.data.images);
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					setFolderListMenu(response.data.data.folders);
					setHideListMenu(false);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);

					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);

					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		}
	}, [batchId]);
	useEffect(() => {
		if (token && search !== "") {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${selectOptions}` +
						`?search_key=${search}`
				)

				.then((response) => {
					const batchNumbers = response.data.data.batchNumbers;
					const filteredBatch = batchNumbers.find(
						(entry) => entry.batch_number === search
					);

					if (filteredBatch) {
						const { id } = filteredBatch;
						setActiveBatch(id);
						setActiveBatchNumber(id);
						console.log("batch id call", response.data.data.batchNumbers[0].id);
						setEditGroups(response.data.data.editGroups);
						setEditTypes(response.data.data.editTypes);
						setBatchID(response.data.data.batchNumbers[0].id);
						setBatchNumber(response.data.data.batchNumbers);
						setImages(response.data.data.images);
						setFolderList(response.data.data.folders);
						setFolderListMenu(response.data.data.folders);
						setHideListMenu(false);
						setFolder(response.data.data.folders);
						setMainImageCount(response.data.data.mainImageCount);
						setParentFolderDetails(null);
						setFolderName("");
						setMainImageSettingsCount(
							response.data.data.mainImageSettingsCount
						);
					}
				})
				.catch((err) => console.log(err));
		} else {
			request
				.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${selectOptions}`)
				.then((response) => {
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setBatchNumber(response.data.data.batchNumbers);
					setBatchID(response.data.data.batchNumbers[0].id);
					setActiveBatch(response.data.data.batchNumbers[0].id);
					setActiveBatchNumber(response.data.data.batchNumbers[0].batch_number);
					setImages(response.data.data.images);
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					setMultipleImagesId([]);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);

					setParentFolderDetails(null);
					setFolderName("");
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		}
	}, [search]);
	const selectAllImages = (status) => {
		if (status) {
			const allImageIds =
				images !== undefined && images.map((item) => String(item.id));
			const allFolderNames =
				folder_list !== undefined && folder_list.map((items) => items.path);
			// Set the folderListName state to contain all folder names.
			setFolderListName(allFolderNames);

			// Set the foldersPath state to contain all folder names.
			setFoldersPath(allFolderNames);
			// console.log("allFolderPath", allFolderNames);
			setMultipleImagesId(allImageIds);
		} else {
			setMultipleImagesId([]);
			setFolderListName([]);
			setFoldersPath([]);
		}
	};

	// Use useEffect to handle "Select All" checkbox changes
	useEffect(() => {
		const handleKeyDown = (event) => {
			// Check if the Ctrl (for Windows/Linux) or Cmd (for macOS) key is pressed
			const isCtrlKey = event.ctrlKey || event.metaKey;

			// Check if the "A" key is pressed
			if (isCtrlKey && event.key === "a") {
				// Prevent the default browser behavior (e.g., selecting all text)
				event.preventDefault();

				// Toggle the selectAll state
				setSelectAll((prevSelectAll) => !prevSelectAll);
			}
		};

		// Attach the event listener when the component mounts
		document.addEventListener("keydown", handleKeyDown);

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []); // Empty dependency array means this effect runs once when the component mounts

	useEffect(() => {
		if (selectAll) {
			selectAllImages(true);
		} else {
			// setMultipleImagesId([]);
			setClickSelect(false);
		}
	}, [selectAll]);
	// useEffect(() => {
	// 	if (selectAll) {
	// 		selectAllImages(true);
	// 	} else {
	// 		setMultipleImagesId([]);
	// 		setFolderListName([]);
	// 		setFoldersPath([]);
	// 	}
	// }, [selectAll]);

	const handleFolderClick = useCallback(
		(item) => {
			setMultipleImagesId([]);
			setFolderName(item);
			console.log("batch click call", item);
			// setBatchID("");
			if (token) {
				if (parentFolderDetails === null && folder_name === "") {
					request
						.get(
							apiURL +
								ADMIN_GET_COORDINATORS_DETAILS +
								`/${selectOptions}` +
								`?batch_number=${batchId}` +
								`&folder_path=${item}`
						)
						.then((response) => {
							console.log(
								"setBatchID(response.data.data.batchNumbers[0].id)",
								response.data.data
							);
							setParentFolderDetails(null);
							setFolderList(response.data.data.folders);
							setFolderListMenu(response.data.data.folders);
							setEditGroups(response.data.data.editGroups);
							setEditTypes(response.data.data.editTypes);
							setFolderName(item);
							// setBatchID(response.data.data.batchNumbers[0].id);
							// setBatchNumber(response.data.data.batchNumbers);

							setImages(response.data.data.images);

							setFoldersPath([]);

							setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
							setDisplay(true);

							setDisplaySub(true);
							setMultipleImagesId([]);
							setClickSelect(false);
							setHideListMenu(true);
						})
						.catch((err) => {
							let message =
								typeof err.response !== "undefined"
									? err.response.data.message
									: err.message;
							setError(message);
						});
				} else {
					request
						.get(
							apiURL +
								ADMIN_GET_COORDINATORS_DETAILS +
								`/${selectOptions}` +
								`?batch_number=${batchId}` +
								`&folder_path=${folder_name}/${item}`
						)
						.then((response) => {
							setFolderList(response.data.data.folders);

							setDisplay(true);

							setImages(response.data.data.images);
							setEditGroups(response.data.data.editGroups);
							setEditTypes(response.data.data.editTypes);

							setParentFolderDetails(null);
							setFolderName(item);
							setMultipleImagesId([]);
							setClickSelect(false);
							setDisplaySub(true);
							setFoldersPath([]);
						})
						.catch((err) => {
							let message =
								typeof err.response !== "undefined"
									? err.response.data.message
									: err.message;
							setError(message);
						});
				}
			}
		},
		[folder_list]
	);
	// console.log("folder list", folder_list, folder_list_menu);

	useEffect(() => {
		// Check if a token is available
		if (token && selectedOption.value === "custom") {
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${selectOptions}` +
						`?date=${moment(selectedDate).format("YYYY-MM-DD")}` +
						`&from_date=${moment(selectedDate).format("YYYY-MM-DD")}` +
						`&to_date=${moment(selectedDate2).format("YYYY-MM-DD")}`
				)
				.then((response) => {
					// Log the response to the console
					setParentFolderDetails(null);
					setFolderList(response.data.data.folders);

					setImages(response.data.data.images);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					setFolderName("");
					// setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		} else if (token && selectedOption.value === "today") {
			let today = new Date();
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${selectOptions}` +
						`?date=today`
				)
				.then((response) => {
					// Log the response to the console
					setParentFolderDetails(null);
					// setFolderList(response.data.data.folders);

					setImages(response.data.data.images);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					setFolderName("");
					setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		} else if (token && selectedOption.value === "year") {
			let today = new Date();
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${selectOptions}` +
						`?date=year`
				)
				.then((response) => {
					// Log the response to the console

					setParentFolderDetails(null);
					setFolderList(response.data.data.folders);

					setImages(response.data.data.images);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					setFolderName("");
					// setFolderName(item);
					setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		} else if (token && selectedOption.value === "month") {
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${selectOptions}` +
						`?date=month`
				)
				.then((response) => {
					// Log the response to the console
					setParentFolderDetails(null);
					setFolderList(response.data.data.folders);

					setImages(response.data.data.images);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					setFolderName("");
					// setFolderName(item);
					setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		} else if (token && selectedOption.value === "all") {
			if (token) {
				// Make an HTTP GET request to fetch the branch data with the specified page number
				request
					.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${selectOptions}`)
					.then((response) => {
						// Log the response to the console
						setParentFolderDetails(null);
						setFolderList(response.data.data.folders);

						setImages(response.data.data.images);

						setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
						setDisplay(true);
						setFolderName("");
						// setFolderName(item);
						setFolderListMenu(response.data.data.folders);
						setMultipleImagesId([]);
						setClickSelect(false);
					})
					.catch((err) => console.log(err));
			}
		} else {
			if (token) {
				// Make an HTTP GET request to fetch the branch data with the specified page number
				request
					.get(apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${selectOptions}`)
					.then((response) => {
						// Log the response to the console
						setParentFolderDetails(null);
						setFolderList(response.data.data.folders);

						setImages(response.data.data.images);

						setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
						setDisplay(true);
						setFolderName("");
						// setFolderName(item);
						setFolderListMenu(response.data.data.folders);
						setMultipleImagesId([]);
						setClickSelect(false);
					})
					.catch((err) => console.log(err));
			}
		}
	}, [selectedDate, selectedDate2, selectedOption]);
	let folderPath = folder_list.map(function (currentObject) {
		return currentObject.path;
	}, {});
	const foldersPathSelected =
		multiple_images_id !== undefined &&
		folders_path
			.slice(0, folderPath.length)
			.toString()
			.split(/(?=(?:\d{3})+(?:\.|$))/g)
			.join(",")
			.replace(/"/g, "");

	const handleFetchProfileSettings = (imageID) => {
		if (token && imageID !== "") {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_PROFILE_SETTINGS + `/${imageID}`)
				.then((response) => {
					setSettingsProfile(response.data.image);
					setEditorType(response.data.image.edit_group_id);
					// console.log(response.data.image);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		handleFetchProfileSettings(imageID);
	}, [imageID]);

	/**
	 * Handles to change the active index
	 */
	const handleResetActiveIndex = useCallback((item, id, batch_number) => {
		setBatchID(item.id);
		setMultipleImagesId([]);
		setOrderNumber(item.order_id);
		setClickSelect(false);
		setActiveBatch(id);
		setActiveBatchNumber(batch_number);
	}, []);
	console.log("active batch", activeBatch);
	return (
		<>
			<div
				className="container-fluid page-body-wrapper"
				style={{ display: "block" }}
			>
				<div>
					<div className="rightbar">
						<div className="section-title">
							<h2>Coordinator Workspace</h2>
						</div>

						<Row>
							<Col lg={6} sm={12}>
								<Form className="workspaceform">
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
										style={{ width: "37rem" }}
									>
										<div className="col-lg-6">
											<div className="d-sm-flex align-itemd-center  col-gap-14 align-items-center">
												<div className="form-group">
													<Form.Label className="label-div">
														Choose Staff
													</Form.Label>
												</div>

												<div className="form-group flex-grow-1">
													<Select
														options={selectLabel(options)}
														onChange={(e) => setSelectOptions(e.value)}
													/>
												</div>
											</div>
										</div>
									</Form.Group>
								</Form>
							</Col>
						</Row>

						{selectOptions === "" ? (
							<div></div>
						) : (
							<div className="workbody">
								<Form className="mb-3">
									<Row className="align-items-center">
										<Col lg={6} sm={12}>
											<div className="d-sm-flex flex-sm-wrap col-gap-14 align-items-center">
												<div className="flex-fill">
													<div
														className="form-group"
														// style={{ width: "12rem" }}
														// styles={ width: "18rem!important" }
													>
														{/* <Select
															options={selectdays}
															defaultValue={{ label: "Today", value: 355 }}
														/> */}
														<Select
															value={selectedOption}
															onChange={(e) => setSelectedOption(e)}
															options={date}
															onClick={() => {
																setSelectOptions("");
															}}
														/>
														{selectedOption !== null &&
															selectedOption.value === "custom" && (
																<div className="d-flex mt-2 date-picker--container ">
																	<div>
																		<Form.Control
																			type="date"
																			value={moment(selectedDate).format(
																				"YYYY-MM-DD"
																			)}
																			onChange={(e) =>
																				handleDateChange(
																					new Date(e.target.value),
																					setSelectedDate
																				)
																			}
																		/>
																	</div>

																	<div style={{ marginTop: "5px" }}>
																		<Form.Control
																			type="date"
																			value={moment(selectedDate2).format(
																				"YYYY-MM-DD"
																			)}
																			onChange={(e) =>
																				handleDateChange(
																					new Date(e.target.value),
																					setSelectedDate2
																				)
																			}
																		/>
																	</div>
																</div>
															)}
													</div>
												</div>
												<div
													className={
														selectedOption.value === "custom"
															? `flex-grow-1 negative-margin--container`
															: `flex-grow-1 `
													}
												>
													<div className="form-group">
														<Autocomplete
															suggestions={batchNumber}
															setSearch={setSearch}
															setImageDetailsView={setImageDetailsView}
															setImageDetailsViewId={setImageDetailsViewId}
															setImageDetailsModal={setImageDetailsModal}
															isShow={isShow}
															setIsShow={setIsShow}
															setParentFolderDetails={setParentFolderDetails}
															setFolderName={setFolderName}
														/>
													</div>
												</div>
											</div>
										</Col>
										<Col lg={6} sm={12}>
											<Form.Group
												className="ml-4"
												controlId="formBasicCheckbox"
											>
												<div
													className={
														selectedOption.value === "custom"
															? `d-sm-flex col-gap-20 align-items-center negative-margin--container`
															: `d-sm-flex col-gap-20 align-items-center`
													}
												>
													<div
														className={
															selectedOption.value === "custom"
																? `form-group mt--10px`
																: `form-group `
														}
													>
														<div className="d-flex">
															<input
																id="select_all"
																type="checkbox"
																label="Select All"
																checked={selectAll}
																onChange={(e) => {
																	setSelectAll(e.currentTarget.checked);
																	selectAllImages(e.currentTarget.checked);
																}}
																className={"exclude-click"}
															/>
															<Form.Label
																htmlFor="select_all"
																id="select_all_label"
																className={"form-check-label exclude-click"}
																style={{ cursor: "pointer" }}
															>
																{" "}
																&nbsp; Select All
															</Form.Label>
														</div>
													</div>
													<div
														className={
															selectedOption.value === "custom"
																? `w-25 mt--10px`
																: `w-25 `
														}
													>
														<div className="form-group ml-2 box exclude-click">
															<Button
																variant="primary"
																className="btns box exclude-click"
																disabled={isButtonDisabled ? false : true}
																onClick={() =>
																	setReassignedModal((val) => !val)
																}
															>
																Reassign
															</Button>{" "}
														</div>
													</div>
												</div>
											</Form.Group>
										</Col>

										<Col sm={2}></Col>
									</Row>
								</Form>
								<div className="container">
									<Row className="custom-row-workspace" ref={wrapper}>
										<Col sm={3} className="customcol-2 pl-0">
											<div className="editororderdetail">
												<EditorOrderDetailsidebar
													batchNumber={batchNumber}
													setBatchID={setBatchID}
													setOrderNumber={setOrderNumber}
													setFolderName={setFolderName}
													show={showBatchLoader}
													setSelectedImagesID={setImageID}
													resetActiveIndex={handleResetActiveIndex}
													activeIndex={activeBatch}
												/>
											</div>
										</Col>

										<Col sm={7} className="pl-0 ">
											<CoordinatorOrderDetails
												mainImageCount={mainImageCount}
												mainImageSettingsCount={mainImageSettingsCount}
												idSelected={idSelected}
												selectImageName={selectImageName}
												editTypeId={editTypeId}
												token={token}
												images={images}
												folder_path={folder_path}
												folder_list={folder_list}
												handleFolderClick={handleFolderClick}
												multiple_images_id={multiple_images_id}
												setMultipleImagesId={setMultipleImagesId}
												setFoldersPath={setFoldersPath}
												folders_path={folders_path}
												folder_name={folder_name}
												setFolderListName={setFolderListName}
												folder_list_name={folder_list_name}
												setReassignedModal={setReassignedModal}
												setSelectAll={setSelectAll}
												selectAll={selectAll}
												wrapper={wrapper}
												folderPathSelected={folderPathSelected}
												imageID={imageID}
												setImageID={setImageID}
												handleFetchProfileSettings={handleFetchProfileSettings}
												setting_profile={setting_profile}
												editTypes={editTypes}
												editGroups={editGroups}
											/>
										</Col>
										<Col sm={2} className="exclude-click">
											{!reassignedModal ? (
												<Treeview
													mainImageCount={mainImageCount}
													mainImageSettingsCount={mainImageSettingsCount}
													folder_path={folder_path}
													setImages={setImages}
													imagesMainFolder={imagesMainFolder}
													folderMainFolder={folderMainFolder}
													folderSubFolder={folderSubFolder}
													setFolder={setFolder}
													setParentFolderDetails={setParentFolderDetails}
													parentFolderDetails={parentFolderDetails}
													setFolderListSub={setFolderListSub}
													folder_files_sub={folder_files_sub}
													setDisplay={setDisplay}
													list_display={list_display}
													folder_list={folder_list}
													folders={folders}
													setFolderList={setFolderList}
													folder_name={folder_name}
													setFolderName={setFolderName}
													setDisplaySub={setDisplaySub}
													list_sub_display={list_sub_display}
													setFolderListMenu={setFolderListMenu}
													folder_list_menu={folder_list_menu}
													loader_sub_menu={loader_sub_menu}
													setLoaderSubMenu={setLoaderSubMenu}
													clickSelect={clickSelect}
													setClickSelect={setClickSelect}
													selectOptions={selectOptions}
													token={token}
													batchId={batchId}
													hide_list_menu={hide_list_menu}
													setHideListMenu={setHideListMenu}
												/>
											) : (
												<ReassignsModal
													token={token}
													role={role.role}
													idUserSelected={selectOptions}
													order_number={order_number}
													idSelected={idSelected}
													selectOptions={selectOptions}
													foldersPathSelected={foldersPathSelected}
													fetchOrder={fetchOrder}
													setReassignedModal={setReassignedModal}
													folderPathSelected={folderPathSelected}
												/>
											)}
										</Col>
									</Row>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default CoordinatorWorkspace;
