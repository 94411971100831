import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//Importing the react select components

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import plus from "../../assets/Images/plus.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "../Customers/Components/Alert";
// import Alert from "../../components/Alert";
import MyVerticallyCenteredModal from "../../components/Modal";
import Loader from "../../components/Loader";

import Select from "react-select";

import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";

// import { components } from "react-select";
// import makeAnimated from "react-select/animated";
// import cross from '../../../Images/cross.png';
import {
  CUSTOMERS_STORE_GROUP_TYPE,
  CUSTOMERS_UPDATE_GROUP_TYPE,
  CUSTOMERS_VIEW_GROUP_TYPE,
  CUSTOMERS_ALL_EDIT_TYPE_LIST,
} from "../../config/apiRoutes.api";
const AddEditytypesGroups = (props) => {
  const { id } = useParams();
  // Create a FormData object for request payload
  const bodyFormData = new FormData();
  const token = sessionStorage.getItem("token");
  const request = client(token);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    props.removeEditGroupId();
    setShow(false);
  };
  const handleShow = () => {
    resetErrors();
    resetStates();
    setShow(true);
    props.removeEditGroupId();
  };
  const [modalShow, setModalShow] = useState(false);
  const [addResponse, setAddResponse] = useState("");
  const [dataEditTypes, setEditTypesData] = useState([]);
  const [dataEdit, setData] = useState([]);
  const [groupNameError, setErrorGroupName] = useState("");
  const [groupEditTypesError, setErrorGroupEditTypes] = useState("");
  const [groupSpecFileFormatError, setErrorFileFormat] = useState("");
  const [groupSpecFileSizeHeightError, setErrorSpecFileSizeHeight] =
    useState("");
  const [groupSpecFileSizeWidthError, setErrorSpecFileSizeWidth] = useState("");
  const [groupSpecFileDPIError, setErrorSpecFileDPI] = useState("");
  const [groupSpecFileDPITypeError, setErrorSpecFileDPIType] = useState("");
  const [groupSpecColorModeError, setErrorSpecColorMode] = useState("");
  const [groupSpecAlignError, setErrorSpecAlign] = useState("");
  const [groupSpecMarginFormatError, setErrorSpecMarginFormat] = useState("");
  const [groupSpecMarginTopError, setErrorSpecMarginTop] = useState("");
  const [groupSpecMarginBottomError, setErrorSpecMarginBottom] = useState("");
  const [groupSpecMarginLeftError, setErrorSpecMarginLeft] = useState("");
  const [groupSpecMarginRightError, setErrorSpecMarginRight] = useState("");

  //Declarations of state variable
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [loader, setLoader] = useState(false); //Loader State
  const [edit_type_checked, setEditTypeChecked] = useState(false);
  const [edit_exiting_group_checked, setGroupChecked] = useState(false);
  const [edit_type, setEditType] = useState(false);
  const [edit_select, setEditorType] = useState("");
  const [add_edit_type, setAddEditType] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [edit_id, setEditId] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [set_post_url, setPostURL] = useState("");
  const [edit_type_toggle, setToggleEditType] = useState(false);
  //To update the select group
  const [background_removal_color_type, setBackgroundRemovalColorType] =
    useState("");
  const [background_removal_color_code, setBackgroundRemovalColorCode] =
    useState("");
  const [background_adjustment_color_code, setBackgroundAdjustmentColorCode] =
    useState("");
  const [background_adjustment_color_type, setBackgroundAdjustmentColorType] =
    useState("");

  const [popup_heading, setPopupHeading] = useState("Update");
  const [resizing_file_format, setResizingFileFormat] = useState("");
  const [resizing_height, setResizingHeight] = useState("");
  const [resizing_width, setResizingWidth] = useState("");
  const [resizing_dpi, setResizingDpi] = useState("");
  const [resizing_dpi_type, setResizingDpiType] = useState("");
  const [resizing_color_mode, setResizingColorMode] = useState("");
  const [resizing_alignment, setResizingAlignment] = useState("");
  const [resizing_margin_format, setResizingMarginFormat] = useState("");
  const [resizing_margin_top, setResizingMarginTop] = useState("");
  const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
  const [resizing_margin_right, setResizingMarginRight] = useState("");
  const [resizing_margin_left, setResizingMarginLeft] = useState("");
  const [edit_group_name, setEditGroupName] = useState("");
  const [edit_type_ids, setEditTypeId] = useState("");
  const [image_file_format, setImageFileFormat] = useState("");
  const [image_height, setImageHeight] = useState("");
  const [image_width, setImageWidth] = useState("");
  const [image_dpi, setImageDpi] = useState("");
  const [image_dpi_type, setImageDpiType] = useState("");
  const [image_color_mode, setImageColorMode] = useState("");
  const [image_alignment, setImageAlignment] = useState("");
  const [image_margin_format, setImageMarginFormat] = useState("");
  const [image_margin_top, setImageMarginTop] = useState("");
  const [image_margin_bottom, setImageMarginBottom] = useState("");
  const [image_margin_right, setImageMarginRight] = useState("");
  const [image_margin_left, setImageMarginLeft] = useState("");
  const [selected, setSelected] = useState(undefined);
  const [selected1, setSelected1] = useState(undefined);
  const [selected2, setSelected2] = useState(undefined);
  const [selected3, setSelected3] = useState(undefined);
  const [disabled, setDisabled] = useState(false);
  const [is_form_submit, setFormSubmit] = useState(false);

  /**
   * Transform an array of customer edit types into an array of objects with label, value, and additional properties.
   * @param {Array} customerEditTypes - Array of customer edit types.
   * @returns {Array} - Transformed array of objects with label, value, and additional properties.
   */

  const file = [
    { label: "JPEG", value: 355 },
    { label: "PNG", value: 54 },
    { label: "JPG", value: 54 },
  ];
  const selectBackgrondRemoval = [
    { label: "Custom", value: 355 },
    { label: "Transparent", value: 54 },
  ];
  const selectedAmount = [
    { label: "1", value: 355 },
    { label: "2", value: 54 },
    { label: "3", value: 54 },
  ];
  const dpi = [
    { label: "JPEG", value: 355 },
    { label: "PNG", value: 54 },
    { label: "JPG", value: 54 },
  ];

  const ColorMode = [
    { label: "Original", value: 355 },
    { label: "Edited", value: 54 },
  ];

  const Align = [
    { label: "center", value: 355 },
    { label: "start", value: 54 },
    { label: "end", value: 55 },
  ];

  const Margin = [
    { label: "pixel", value: 355 },
    { label: "em", value: 54 },
    { label: "rem", value: 55 },
    { label: "%", value: 56 },
  ];

  const executeScroll = () => {
    setTimeout(() => {
      if (document.getElementById("head")) {
        document.getElementById("head").scrollIntoView(true);
      }
    }, 0);
  };

  useEffect(() => {
    if (props.groupID > 0) {
      resetErrors();
      setShow(true);
      getGroupTypeData(props.groupID);
      setPostURL(CUSTOMERS_UPDATE_GROUP_TYPE);
    } else {
      setPostURL(CUSTOMERS_STORE_GROUP_TYPE);
    }
    setFormSubmit(false);
  }, [props.groupID]);

  useEffect(() => {
    request
      .get(apiURL + `${CUSTOMERS_ALL_EDIT_TYPE_LIST}/${id}`)
      .then((response) => {
        // setData(response.data[0].editTypes.data);
        let editTypesAry = response.data.data.editTypes || [];
        // console.log(" editTypesAry > ", editTypesAry);
        let editTypes = [];
        if (editTypesAry) {
          editTypesAry &&
            editTypesAry.map((obj) => {
              // console.log(" inside obj ", obj);
              if (
                obj.id === 1 ||
                obj.id === 2 ||
                obj.id === 3 ||
                obj.id === 15
              ) {
                editTypes.push({ edit_type: obj.edit_type, id: obj.id });
              }
            });
          setEditTypesData(editTypes);
        }
      })
      .catch((err) => console.log(err));
  }, [dataEdit]);

  const resetStates = () => {
    setSelected(undefined);
    setSelected1(undefined);
    setSelected2(undefined);
    setSelected3(undefined);
    setEditGroupName(undefined);
    setBackgroundRemovalColorType(undefined);
    setBackgroundRemovalColorCode(undefined);
    setBackgroundAdjustmentColorType(undefined);
    setResizingFileFormat(undefined);
    setResizingHeight(undefined);
    setResizingWidth(undefined);
    setResizingDpi(undefined);
    setResizingDpiType(undefined);
    setResizingColorMode(undefined);
    setResizingAlignment(undefined);
    setResizingMarginFormat(undefined);
    setResizingMarginTop(undefined);
    setResizingMarginBottom(undefined);
    setResizingMarginRight(undefined);
    setResizingMarginLeft(undefined);
    setImageFileFormat(undefined);
    setImageHeight(undefined);
    setImageWidth(undefined);
    setImageDpi(undefined);
    setImageDpiType(undefined);
    setImageColorMode(undefined);
    setImageAlignment(undefined);
    setImageMarginFormat(undefined);
    setImageMarginTop(undefined);
    setImageMarginBottom(undefined);
    setImageMarginRight(undefined);
    setImageMarginLeft(undefined);
    setBackgroundAdjustmentColorCode(undefined);
  };

  const resetErrors = () => {
    setErrorGroupName("");
    setErrorGroupEditTypes("");
    setErrorFileFormat("");
    setErrorSpecFileSizeHeight("");
    setErrorSpecFileSizeWidth("");
    setErrorSpecFileDPI("");
    setErrorSpecFileDPIType("");
    setErrorSpecColorMode("");
    setErrorSpecAlign("");
    setErrorSpecMarginFormat("");
    setErrorSpecMarginTop("");
    setErrorSpecMarginBottom("");
    setErrorSpecMarginLeft("");
    setErrorSpecMarginRight("");
    setFormSubmit(false);
  };

  /**
   * Fetch  the Edit Groups from the DB.
   * @param {arr} id - The Hold the id of edit group type id.
   * It return the clicked row group data from the API .
   * @returns {JSX.Element[]} Array of pagination items.
   */
  const getGroupTypeData = (id) => {
    // display loader after click
    setLoader(true);
    // Make an HTTP GET request to fetch the Edit Groups data with the specified page number
    request
      .get(apiURL + CUSTOMERS_VIEW_GROUP_TYPE + `/${id}`)
      .then((response) => {
        setLoader(false);
        // Log the response to the console
        console.log(" View response  =>", response.data.edit_group.group_name);
        setGroupData(response.data);
        let setChecked = findEditTypeData(
          response.data.edit_group.edit_types || [],
          "id",
          1
        );
        let setChecked1 = findEditTypeData(
          response.data.edit_group.edit_types || [],
          "id",
          2
        );
        let setChecked2 = findEditTypeData(
          response.data.edit_group.edit_types || [],
          "id",
          3
        );
        let setChecked3 = findEditTypeData(
          response.data.edit_group.edit_types || [],
          "id",
          15
        );
        console.log("before  setChecked =", setChecked);
        if (setChecked && setChecked !== undefined) {
          setSelected(true);
          let background_removal_details = findEditTypeData(
            response.data.edit_group.background_settings || [],
            "edit_type_id",
            1
          );
          console.log(
            "inside setChecked =",
            setChecked,
            " , background_removal_details =>",
            background_removal_details
          );
          if (
            background_removal_details &&
            background_removal_details !== undefined
          ) {
            setBackgroundRemovalColorType(
              background_removal_details.color_type
            );
            setBackgroundRemovalColorCode(
              background_removal_details.color_code
            );
          } else {
            setBackgroundRemovalColorType("");
            setBackgroundRemovalColorCode("");
          }
        } else {
          setSelected(undefined);
          setBackgroundRemovalColorType("");
          setBackgroundRemovalColorCode("");
        }
        if (setChecked1 && setChecked1 !== undefined) {
          setSelected1(true);
          let background_color_adjustment_details = findEditTypeData(
            response.data.edit_group.background_settings || [],
            "edit_type_id",
            2
          );
          if (
            background_color_adjustment_details &&
            background_color_adjustment_details !== undefined
          ) {
            setBackgroundAdjustmentColorType(
              background_color_adjustment_details.color_type
            );
            setBackgroundAdjustmentColorCode(
              background_color_adjustment_details.color_code
            );
          } else {
            setBackgroundAdjustmentColorType("");
            setBackgroundAdjustmentColorCode("");
          }
        } else {
          setSelected1(undefined);
          setBackgroundAdjustmentColorType("");
          setBackgroundAdjustmentColorCode("");
        }
        if (setChecked2 && setChecked2 !== undefined) {
          setSelected2(true);

          let resize_file_format_details = findEditTypeData(
            response.data.edit_group.resizing_settings || [],
            "edit_type_id",
            3
          );
          if (
            resize_file_format_details &&
            resize_file_format_details !== undefined
          ) {
            setResizingFileFormat(resize_file_format_details.file_format);
            setResizingHeight(resize_file_format_details.height);
            setResizingWidth(resize_file_format_details.width);
            setResizingDpi(resize_file_format_details.dpi);
            setResizingDpiType(resize_file_format_details.dpi_type);
            setResizingColorMode(resize_file_format_details.color_mode);
            setResizingAlignment(resize_file_format_details.alignment);
            setResizingMarginFormat(resize_file_format_details.margin_format);
            setResizingMarginTop(resize_file_format_details.margin_top);
            setResizingMarginBottom(resize_file_format_details.margin_bottom);
            setResizingMarginRight(resize_file_format_details.margin_right);
            setResizingMarginLeft(resize_file_format_details.margin_left);
          } else {
            resetResizing();
          }
        } else {
          setSelected2(false);
          resetResizing();
        }
        if (setChecked3 && setChecked3 !== undefined) {
          setSelected3(true);
        } else {
          setSelected3(undefined);
        }
        setEditGroupName(response.data.edit_group.group_name || "");
        setImageFileFormat(response.data.edit_group.setting.file_format);
        setImageHeight(response.data.edit_group.setting.height);
        setImageWidth(response.data.edit_group.setting.width);
        setImageDpi(response.data.edit_group.setting.dpi);
        setImageDpiType(response.data.edit_group.setting.dpi_type);
        setImageColorMode(response.data.edit_group.setting.color_mode);
        setImageAlignment(response.data.edit_group.setting.alignment);
        setImageMarginFormat(response.data.edit_group.setting.margin_format);
        setImageMarginTop(response.data.edit_group.setting.margin_top);
        setImageMarginBottom(response.data.edit_group.setting.margin_bottom);
        setImageMarginRight(response.data.edit_group.setting.margin_right);
        setImageMarginLeft(response.data.edit_group.setting.margin_left);
        executeScroll();
      })
      .catch((err) => console.log(err));
  };

  /**
   * Reset the resizing fields.
   * It return the unset states for resizing.
   */

  const resetResizing = () => {
    setResizingFileFormat("");
    setResizingHeight("");
    setResizingWidth("");
    setResizingDpi("");
    setResizingDpiType("");
    setResizingColorMode("");
    setResizingAlignment("");
    setResizingMarginFormat("");
    setResizingMarginTop("");
    setResizingMarginBottom("");
    setResizingMarginRight("");
    setResizingMarginLeft("");
  };

  /**
   * Find the Edit Group Edit types based on array.
   * It return the matched element id if exist.
   * @param {arr} arr - The Hold the array of object.
   * @param {find_key} find_key - find_key which we needs to find from the array.
   * @param {id} id - id which we needs to find from the array.
   * @returns {JSX.Element[]} Array of items.
   */
  const findEditTypeData = (arr, find_key, id) => {
    if (find_key === "id") {
      return arr.find((o) => o.id === id);
    } else {
      return arr.find((o) => o.edit_type_id === id);
    }
  };

  /**
   * Handle the selection of checkboxes and update the `editId` state accordingly.
   * @param {ChangeEvent} changeEvent - The change event object.
   */
  useEffect(() => {
    if (!selected && !selected1 && !selected2 && !selected3 && is_form_submit) {
      setErrorGroupEditTypes("Please select at least one edit type");
    } else {
      setErrorGroupEditTypes("");
    }
  }, [selected, selected1, selected2, selected3]);

  /**
   * Handle the checkbox state and perform actions based on the given id.
   * @param {number} id - The id to determine the checkbox action.
   */
  const handleBackGroundRemovalCheckbox = (id) => {
    if (id === 1) {
      setSelected((val) => !val);
      setChecked((val) => !val);
    }
    if (id === 2) {
      setSelected1((val) => !val);
      setChecked1((val) => !val);
    }
    if (id === 3) {
      setSelected2((val) => !val);
      setChecked2((val) => !val);
    }
    if (id === 15) {
      setSelected3((val) => !val);
      setChecked3((val) => !val);
    }
  };

  /**
   * Handle the selection of checkboxes and update the `editId` state accordingly.
   * @param {ChangeEvent} changeEvent - The change event object.
   */
  const handleAddCheckboxSelect = (changeEvent) => {
    console.log("id", changeEvent);
    changeEvent.persist();
    setFormSubmit(true);
    setEditId((prevState) => {
      const editItem = changeEvent.target.value;
      console.log("changeEvent", changeEvent.target.value);

      if (prevState.includes(editItem)) {
        // If the selected editItem is already in the prevState array,
        // remove it from the array and return the updated array.
        return prevState.filter((el) => el !== editItem);
      }
      // If the selected editItem is not in the prevState array,
      // add it to the array and return the updated array.
      return [...prevState, editItem];
    });
  };

  /**
   * Handle the submission of the form to create/edit group edit type.
   */
  const handleSubmitCreate = () => {
    setFormSubmit(true);
    // decare empty value fore dit type checked by default
    let is_error = false;
    let edit_type_ids = "";
    if (!selected && !selected1 && !selected2 && !selected3) {
      is_error = true;
      setErrorGroupEditTypes("Please select at least one edit type");
    } else {
      setErrorGroupEditTypes("");
      if (selected) {
        if (edit_type_ids) {
          edit_type_ids += `,1`;
        } else {
          edit_type_ids += `1`;
        }
      }
      if (selected1) {
        if (edit_type_ids) {
          edit_type_ids += `,2`;
        } else {
          edit_type_ids += `2`;
        }
      }
      if (selected2) {
        if (edit_type_ids) {
          edit_type_ids += `,3`;
        } else {
          edit_type_ids += `3`;
        }
      }
      if (selected3) {
        if (edit_type_ids) {
          edit_type_ids += `,15`;
        } else {
          edit_type_ids += `15`;
        }
      }
    }

    if (!edit_group_name) {
      is_error = true;
      setErrorGroupName("Required field");
    } else {
      setErrorGroupName("");
    }

    if (!image_file_format) {
      is_error = true;
      setErrorFileFormat("Required field");
    } else {
      setErrorFileFormat("");
    }
    if (!image_height) {
      is_error = true;
      setErrorSpecFileSizeHeight("Required field");
    } else {
      setErrorSpecFileSizeHeight("");
    }
    if (!image_width) {
      is_error = true;
      setErrorSpecFileSizeWidth("Required field");
    } else {
      setErrorSpecFileSizeWidth("");
    }

    if (!image_dpi) {
      is_error = true;
      setErrorSpecFileDPI("Required field");
    } else {
      setErrorSpecFileDPI("");
    }
    if (!image_dpi_type) {
      is_error = true;
      setErrorSpecFileDPIType("Required field");
    } else {
      setErrorSpecFileDPIType("");
    }
    if (!image_color_mode) {
      is_error = true;
      setErrorSpecColorMode("Required field");
    } else {
      setErrorSpecColorMode("");
    }
    if (!image_alignment) {
      is_error = true;
      setErrorSpecAlign("Required field");
    } else {
      setErrorSpecAlign("");
    }
    if (!image_margin_format) {
      is_error = true;
      setErrorSpecMarginFormat("Required field");
    } else {
      setErrorSpecMarginFormat("");
    }

    if (!image_margin_top) {
      is_error = true;
      setErrorSpecMarginTop("Required field");
    } else {
      setErrorSpecMarginTop("");
    }

    if (!image_margin_bottom) {
      is_error = true;
      setErrorSpecMarginBottom("Required field");
    } else {
      setErrorSpecMarginBottom("");
    }
    if (!image_margin_right) {
      is_error = true;
      setErrorSpecMarginRight("Required field");
    } else {
      setErrorSpecMarginRight("");
    }
    if (!image_margin_left) {
      is_error = true;
      setErrorSpecMarginLeft("Required field");
    } else {
      setErrorSpecMarginLeft("");
    }
    console.log(
      `selected = ${selected}, selected1 = ${selected1}, selected2 = ${selected2}, selected3 = ${selected3}, 
      edit_group_name = ${edit_group_name}`
    );

    if (!is_error) {
      console.log(" success");
      setDisabled(true);
      // Append form data to the bodyFormData object
      bodyFormData.append("edit_group_name", edit_group_name);

      bodyFormData.append("edit_type_ids", edit_type_ids);

      // if (props?.groupid) {
      //   bodyFormData.append("edit_group_id", props.groupid);
      // }
      bodyFormData.append(
        "background_removal_color_type",
        selected ? background_removal_color_type : ""
      );
      bodyFormData.append(
        "background_removal_color_code",
        selected ? background_removal_color_code : ""
      );
      bodyFormData.append(
        "background_adjustment_color_type",
        selected1 ? background_adjustment_color_type : ""
      );
      bodyFormData.append(
        "background_adjustment_color_code",
        selected1 ? background_adjustment_color_code : ""
      );
      bodyFormData.append(
        "resizing_file_format",
        selected2 ? resizing_file_format : ""
      );
      bodyFormData.append("resizing_height", selected2 ? resizing_height : "");
      bodyFormData.append("resizing_width", selected2 ? resizing_width : "");
      bodyFormData.append("resizing_dpi", selected2 ? resizing_dpi : "");
      bodyFormData.append(
        "resizing_dpi_type",
        selected2 ? resizing_dpi_type : ""
      );
      bodyFormData.append(
        "resizing_color_mode",
        selected2 ? resizing_color_mode : ""
      );
      bodyFormData.append(
        "resizing_alignment",
        selected2 ? resizing_alignment : ""
      );
      bodyFormData.append(
        "resizing_margin_format",
        selected2 ? resizing_margin_format : ""
      );
      bodyFormData.append(
        "resizing_margin_top",
        selected2 ? resizing_margin_top : ""
      );
      bodyFormData.append(
        "resizing_margin_bottom",
        selected2 ? resizing_margin_bottom : ""
      );
      bodyFormData.append(
        "resizing_margin_right",
        selected2 ? resizing_margin_right : ""
      );
      bodyFormData.append(
        "resizing_margin_left",
        selected2 ? resizing_margin_left : ""
      );
      bodyFormData.append("image_file_format", image_file_format);
      bodyFormData.append("image_height", image_height);
      bodyFormData.append("image_width", image_width);
      bodyFormData.append("image_dpi", image_dpi);
      bodyFormData.append("image_dpi_type", image_dpi_type);
      bodyFormData.append("image_color_mode", image_color_mode);
      bodyFormData.append("image_alignment", image_alignment);
      bodyFormData.append("image_margin_format", image_margin_format);
      bodyFormData.append("image_margin_top", image_margin_top);
      bodyFormData.append("image_margin_bottom", image_margin_bottom);
      bodyFormData.append("image_margin_right", image_margin_right);
      bodyFormData.append("image_margin_left", image_margin_left);
      request
        .post(
          apiURL +
            `${set_post_url}/${id}` +
            (props.groupID > 0 ? `/${props.groupID}` : ""),
          bodyFormData
        )
        .then((response) => {
          setDisabled(false);
          //Console the  response
          console.log("response", response.data.status);
          //Set the success/error response
          if (response.data.status) {
            setAddResponse(response.data.message);
            setShow(false);
            setModalShow(true);
          }
          setLoader(false);
          props.reloadEditGroup();
          props.removeEditGroupId();
          if (props.groupID > 0) {
            props.resetEditGroupPage();
          }
          setTimeout(() => {
            if (props.groupID < 1) {
              // setChecked(false);
              // setChecked1(false);
              // setChecked2(false);
              // setChecked3(false);
            }
          }, 3000);
        })
        .catch((err) => {
          let message =
            typeof err.response !== "undefined"
              ? err.response.data.message
              : err.message;
          setError(message);
          setDisabled(false);
          setLoader(false);
        });
    }
  };

  /**
   * Handle the remove unwanted space from the string from the start.
   *  @param {String} str - String of user input.
   * @returns {String} - Transformed removed space from the begining.
   */
  const ltrim = (str) => {
    if (!str) return str;
    return str.replace(/^\s+/g, "");
  };

  // if (loader) return <Loader style={{ zIndex: "100", marginTop: "70%" }} />;
  // console.log(" groupSpecMarginRight ==>", groupSpecMarginRight);
  // console.log(" show ==>", show);
  return (
    <>
      <>
        <Button variant="primary" onClick={handleShow}>
          <span>
            <img src={plus} className="img-fluid mr-2" />
          </span>
          Add New
        </Button>{" "}
      </>
      <Modal
        show={show}
        className={"addtypegroup modal-xl" + (loader ? " set_left" : "")}
        centered
        onHide={handleClose}
      >
        {!loader && (
          <Modal.Header closeButton id={"head"}>
            <Modal.Title>
              {props.groupID > 0 ? "Update" : "Add"} Group Type
            </Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>
          <>
            {!loader ? (
              <div className="form-group d-flex" id={"group_holder"}>
                <div className="detailstab">
                  <div className="container">
                    <Form className="justify-content-center sp_form">
                      <>
                        <div className="">
                          <Form.Group style={{ margin: "2px" }}>
                            <div
                              className="form-group"
                              style={{ marginBottom: "30px" }}
                            >
                              <Form.Group
                                style={{ margin: "2px" }}
                                controlId="edit_group_name"
                              >
                                <div className="form-group">
                                  <Row className="align-items-center">
                                    <Form.Label className="col-sm-3">
                                      Group Name (*)
                                    </Form.Label>
                                    <Col sm={6} style={{ marginLeft: "0px" }}>
                                      <Form.Control
                                        type="text"
                                        placeholder="Group Name"
                                        autoFocus
                                        onChange={(e) => {
                                          setEditGroupName(
                                            ltrim(e.target.value)
                                          );
                                          e.target.value.trimStart()
                                            ? setErrorGroupName("")
                                            : setErrorGroupName(
                                                "Required field"
                                              );
                                        }}
                                        value={
                                          edit_group_name ? edit_group_name : ""
                                        }
                                        className={"skip_placeholder"}
                                      />
                                      {groupNameError && (
                                        <Alert
                                          variant={"danger"}
                                          messages={groupNameError}
                                          className=""
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Form.Group>
                            </div>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-3">
                                  Edit Types (*)
                                </Form.Label>
                                <Col sm={9}>
                                  <div
                                    className="d-sm-flex col-gap-20 "
                                    style={{ marginLeft: "0px" }}
                                  >
                                    <div className="">
                                      {dataEditTypes &&
                                        dataEditTypes.map((item, index) => {
                                          // console.log(`Item ${index} =>>>>>`, item); // Log each item with its index
                                          return (
                                            <div key={index}>
                                              <Form.Group className="">
                                                {" "}
                                                <Row
                                                  className="form-group  flex-grow-1"
                                                  sm={3}
                                                >
                                                  <Col sm={1}>
                                                    <input
                                                      className="selctType"
                                                      type="checkbox"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      checked={
                                                        (selected &&
                                                          item.id === 1) ||
                                                        (selected1 === true &&
                                                          item.id === 2) ||
                                                        (selected2 === true &&
                                                          item.id === 3) ||
                                                        (selected3 === true &&
                                                          item.id === 15)
                                                      }
                                                      id={`ck_group_${item.id}`}
                                                      onChange={(e) => {
                                                        handleBackGroundRemovalCheckbox(
                                                          item.id
                                                        );
                                                        handleAddCheckboxSelect(
                                                          e
                                                        );
                                                      }}
                                                      value={item.id}
                                                    />
                                                  </Col>
                                                  <Form.Label
                                                    className="col-sm-4 form-label form-check-label"
                                                    style={{
                                                      width: "auto",
                                                      cursor: "pointer",
                                                    }}
                                                    htmlFor={`ck_group_${item.id}`}
                                                  >
                                                    {item.edit_type}
                                                  </Form.Label>
                                                </Row>
                                                {item.id === 1 ? (
                                                  selected ? (
                                                    <Row
                                                      className="align-items-center"
                                                      style={{
                                                        marginTop: "-2px",
                                                        marginBottom: "20px",
                                                        width: "auto",
                                                        marginLeft: "-15px",
                                                      }}
                                                    >
                                                      <Form.Label className="col-sm-0">
                                                        {/* Background Removal */}
                                                      </Form.Label>
                                                      <Col sm={5}>
                                                        <Select
                                                          options={
                                                            selectBackgrondRemoval
                                                          }
                                                          onChange={(e) =>
                                                            setBackgroundRemovalColorType(
                                                              e.label
                                                            )
                                                          }
                                                          value={
                                                            background_removal_color_type
                                                              ? selectBackgrondRemoval.find(
                                                                  ({ label }) =>
                                                                    label ===
                                                                    background_removal_color_type
                                                                )
                                                              : ""
                                                          }
                                                        />
                                                      </Col>
                                                      <Col sm={4}>
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="#ffff"
                                                          autoFocus
                                                          onChange={(e) =>
                                                            setBackgroundRemovalColorCode(
                                                              ltrim(
                                                                e.target.value
                                                              )
                                                            )
                                                          }
                                                          value={
                                                            background_removal_color_code
                                                              ? background_removal_color_code
                                                              : ""
                                                          }
                                                          className={"sp_input"}
                                                        />
                                                      </Col>
                                                    </Row>
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  ""
                                                )}
                                                {item.id === 2 ? (
                                                  selected1 ? (
                                                    <Row
                                                      className="align-items-center"
                                                      style={{
                                                        marginTop: "-2px",
                                                        marginBottom: "20px",
                                                        width: "auto",
                                                        marginLeft: "-15px",
                                                      }}
                                                    >
                                                      <Form.Label className="col-sm-0">
                                                        {/* Background Color
                                                        Adjustment */}
                                                      </Form.Label>
                                                      <Col sm={5}>
                                                        <Select
                                                          options={
                                                            selectBackgrondRemoval
                                                          }
                                                          onChange={(e) =>
                                                            setBackgroundAdjustmentColorType(
                                                              e.label
                                                            )
                                                          }
                                                          value={
                                                            background_adjustment_color_type
                                                              ? selectBackgrondRemoval.find(
                                                                  ({ label }) =>
                                                                    label ===
                                                                    background_adjustment_color_type
                                                                )
                                                              : ""
                                                          }
                                                        />
                                                      </Col>
                                                      <Col sm={4}>
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="#ffff"
                                                          autoFocus
                                                          onChange={(e) =>
                                                            setBackgroundAdjustmentColorCode(
                                                              ltrim(
                                                                e.target.value
                                                              )
                                                            )
                                                          }
                                                          value={
                                                            background_adjustment_color_code
                                                              ? background_adjustment_color_code
                                                              : ""
                                                          }
                                                          className={"sp_input"}
                                                        />
                                                      </Col>
                                                    </Row>
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  ""
                                                )}
                                                {item.id === 3 ? (
                                                  selected2 ? (
                                                    <Row
                                                      className="align-items-center sp_focus"
                                                      style={{
                                                        marginTop: "-2px",
                                                        marginBottom: "20px",
                                                        width: "98%",
                                                        // marginLeft: "-38px",
                                                      }}
                                                    >
                                                      <Form.Group
                                                        style={{
                                                          margin: "2px",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <Row className="align-items-center">
                                                            <Form.Label className="col-sm-3">
                                                              File Format
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                              <Select
                                                                options={file}
                                                                onChange={(e) =>
                                                                  setResizingFileFormat(
                                                                    e.label
                                                                  )
                                                                }
                                                                value={
                                                                  resizing_file_format
                                                                    ? file.find(
                                                                        ({
                                                                          label,
                                                                        }) =>
                                                                          label ===
                                                                          resizing_file_format
                                                                      )
                                                                    : ""
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Form.Group>

                                                      <Form.Group
                                                        style={{
                                                          margin: "2px",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <Row className="align-items-center">
                                                            <Form.Label className="col-sm-3">
                                                              Size
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                              <div className="d-sm-flex align-items-center col-gap-10">
                                                                <div className="d-sm-flex col-gap-10 align-items-center">
                                                                  {/* <span className="text-secondary f-sz-13">
                                                                    {" "}
                                                                    H:{" "}
                                                                  </span> */}
                                                                  <Form.Control
                                                                    type="text"
                                                                    placeholder="Height"
                                                                    autoFocus
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setResizingHeight(
                                                                        ltrim(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      )
                                                                    }
                                                                    value={
                                                                      resizing_height
                                                                        ? resizing_height
                                                                        : ""
                                                                    }
                                                                    className={
                                                                      "sp_input"
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="d-sm-flex align-items-center">
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                  >
                                                                    X
                                                                  </span>
                                                                </div>
                                                                <div className="d-sm-flex align-items-center col-gap-10">
                                                                  {/* <span className="text-secondary f-sz-13">
                                                                    {" "}
                                                                    W:{" "}
                                                                  </span> */}
                                                                  <Form.Control
                                                                    type="text"
                                                                    placeholder="Width"
                                                                    autoFocus
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setResizingWidth(
                                                                        ltrim(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      )
                                                                    }
                                                                    value={
                                                                      resizing_width
                                                                        ? resizing_width
                                                                        : ""
                                                                    }
                                                                    className={
                                                                      "sp_input"
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Form.Group>

                                                      <Form.Group
                                                        style={{
                                                          margin: "2px",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <Row className="align-items-center">
                                                            <Form.Label className="col-sm-3">
                                                              DPI
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                              <div className="d-sm-flex col-gap-20">
                                                                <div className="">
                                                                  <Form.Control
                                                                    type="text"
                                                                    placeholder="2000"
                                                                    autoFocus
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setResizingDpi(
                                                                        ltrim(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      )
                                                                    }
                                                                    value={
                                                                      resizing_dpi
                                                                        ? resizing_dpi
                                                                        : ""
                                                                    }
                                                                    className={
                                                                      "skip_placeholder"
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="flex-fill">
                                                                  <Select
                                                                    options={
                                                                      dpi
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setResizingDpiType(
                                                                        e.label
                                                                      )
                                                                    }
                                                                    value={
                                                                      resizing_dpi_type
                                                                        ? dpi.find(
                                                                            ({
                                                                              label,
                                                                            }) =>
                                                                              label ===
                                                                              resizing_dpi_type
                                                                          )
                                                                        : ""
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Form.Group>

                                                      <Form.Group
                                                        style={{
                                                          margin: "2px",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <Row className="align-items-center">
                                                            <Form.Label className="col-sm-3">
                                                              Color Mode
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                              <Select
                                                                options={
                                                                  ColorMode
                                                                }
                                                                onChange={(e) =>
                                                                  setResizingColorMode(
                                                                    e.label
                                                                  )
                                                                }
                                                                value={
                                                                  resizing_color_mode
                                                                    ? ColorMode.find(
                                                                        ({
                                                                          label,
                                                                        }) =>
                                                                          label ===
                                                                          resizing_color_mode
                                                                      )
                                                                    : ""
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Form.Group>

                                                      <Form.Group
                                                        style={{
                                                          margin: "2px",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <Row className="align-items-center">
                                                            <Form.Label className="col-sm-3">
                                                              Align
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                              <Select
                                                                options={Align}
                                                                onChange={(e) =>
                                                                  setResizingAlignment(
                                                                    e.label
                                                                  )
                                                                }
                                                                value={
                                                                  resizing_alignment
                                                                    ? Align.find(
                                                                        ({
                                                                          label,
                                                                        }) =>
                                                                          label ===
                                                                          resizing_alignment
                                                                      )
                                                                    : ""
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Form.Group>

                                                      <Form.Group
                                                        style={{
                                                          margin: "2px",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <Row className="align-items-center">
                                                            <Form.Label className="col-sm-3">
                                                              Margins
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                              <Select
                                                                options={Margin}
                                                                placeholder={
                                                                  "Select"
                                                                }
                                                                onChange={(e) =>
                                                                  setResizingMarginFormat(
                                                                    e.label
                                                                  )
                                                                }
                                                                value={
                                                                  resizing_margin_format
                                                                    ? Margin.find(
                                                                        ({
                                                                          label,
                                                                        }) =>
                                                                          label ===
                                                                          resizing_margin_format
                                                                      )
                                                                    : ""
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Form.Group>

                                                      <Form.Group
                                                        style={{
                                                          margin: "2px",
                                                          marginLeft: "25.5%",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <Row className="align-items-center">
                                                            <Form.Label className="col-sm-3"></Form.Label>
                                                            <Col sm={12}>
                                                              <div className="d-sm-flex col-gap-20">
                                                                <div className="">
                                                                  <ul className="margin-list">
                                                                    <li
                                                                      style={{
                                                                        width:
                                                                          "63px",
                                                                      }}
                                                                    >
                                                                      <Form.Control
                                                                        type="text"
                                                                        placeholder="T"
                                                                        autoFocus
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          setResizingMarginTop(
                                                                            ltrim(
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          )
                                                                        }
                                                                        value={
                                                                          resizing_margin_top
                                                                            ? resizing_margin_top
                                                                            : ""
                                                                        }
                                                                        className={
                                                                          "sp_input"
                                                                        }
                                                                      />
                                                                    </li>
                                                                    <li
                                                                      style={{
                                                                        width:
                                                                          "63px",
                                                                      }}
                                                                    >
                                                                      <Form.Control
                                                                        type="text"
                                                                        placeholder="B"
                                                                        autoFocus
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          setResizingMarginBottom(
                                                                            e
                                                                              .target
                                                                              .value
                                                                          )
                                                                        }
                                                                        value={
                                                                          resizing_margin_bottom
                                                                            ? resizing_margin_bottom
                                                                            : ""
                                                                        }
                                                                        className={
                                                                          "sp_input"
                                                                        }
                                                                      />
                                                                    </li>
                                                                    <li
                                                                      style={{
                                                                        width:
                                                                          "63px",
                                                                      }}
                                                                    >
                                                                      <Form.Control
                                                                        type="text"
                                                                        placeholder="L"
                                                                        autoFocus
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          setResizingMarginLeft(
                                                                            e
                                                                              .target
                                                                              .value
                                                                          )
                                                                        }
                                                                        value={
                                                                          resizing_margin_left
                                                                            ? resizing_margin_left
                                                                            : ""
                                                                        }
                                                                        className={
                                                                          "sp_input"
                                                                        }
                                                                      />
                                                                    </li>
                                                                    <li
                                                                      style={{
                                                                        width:
                                                                          "63px",
                                                                      }}
                                                                    >
                                                                      <Form.Control
                                                                        type="text"
                                                                        placeholder="R"
                                                                        autoFocus
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          setResizingMarginRight(
                                                                            e
                                                                              .target
                                                                              .value
                                                                          )
                                                                        }
                                                                        value={
                                                                          resizing_margin_right
                                                                            ? resizing_margin_right
                                                                            : ""
                                                                        }
                                                                        className={
                                                                          "sp_input"
                                                                        }
                                                                      />
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </Col>

                                                            <Col sm={6}></Col>
                                                          </Row>
                                                        </div>
                                                      </Form.Group>
                                                    </Row>
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  ""
                                                )}
                                              </Form.Group>
                                            </div>
                                          );
                                        })}
                                      {groupEditTypesError && (
                                        <Alert
                                          variant={"danger"}
                                          messages={groupEditTypesError}
                                          className=""
                                        />
                                      )}
                                    </div>
                                    <div className="flex-fill"></div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>
                        </div>
                        {/* Form groups for output specifications */}
                        <div className="editGroupSpecifications justify-content-center sp_focus">
                          <div className="sub-head">Choose Specifications</div>

                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-3">
                                  File Format (*)
                                </Form.Label>
                                <Col sm={6}>
                                  <Select
                                    options={file}
                                    onChange={(e) => {
                                      setImageFileFormat(e.label);
                                      e.label
                                        ? setErrorFileFormat("")
                                        : setErrorFileFormat("Required field");
                                    }}
                                    value={
                                      image_file_format
                                        ? file.find(
                                            ({ label }) =>
                                              label === image_file_format
                                          )
                                        : ""
                                    }
                                  />
                                  {groupSpecFileFormatError && (
                                    <Alert
                                      variant={"danger"}
                                      messages={groupSpecFileFormatError}
                                      className=""
                                    />
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>

                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-3">
                                  Size (*)
                                </Form.Label>
                                <Col sm={9}>
                                  <div className="d-sm-flex align-items-center col-gap-10">
                                    <div className="d-sm-flex col-gap-10 align-items-center">
                                      {/* <span className="text-secondary f-sz-13">
                                        {" "}
                                        H:{" "}
                                      </span> */}
                                      <Form.Control
                                        type="text"
                                        placeholder="Height"
                                        autoFocus
                                        onChange={(e) => {
                                          setImageHeight(ltrim(e.target.value));
                                          e.target.value.trimStart()
                                            ? setErrorSpecFileSizeHeight("")
                                            : setErrorSpecFileSizeHeight(
                                                "Required field"
                                              );
                                        }}
                                        value={image_height ? image_height : ""}
                                        className={"sp_input"}
                                      />
                                    </div>
                                    <div className="d-sm-flex align-items-center">
                                      {/* <Image src={Cross} className="img-fluid" alt="cross" /> */}
                                      <span style={{ fontSize: "12px" }}>
                                        X
                                      </span>
                                    </div>
                                    <div className="d-sm-flex align-items-center col-gap-10">
                                      {/* <span className="text-secondary f-sz-13">
                                        {" "}
                                        W:{" "}
                                      </span> */}
                                      <Form.Control
                                        type="text"
                                        placeholder="Width"
                                        autoFocus
                                        onChange={(e) => {
                                          setImageWidth(ltrim(e.target.value));
                                          e.target.value.trimStart()
                                            ? setErrorSpecFileSizeWidth("")
                                            : setErrorSpecFileSizeWidth(
                                                "Required field"
                                              );
                                        }}
                                        value={image_width ? image_width : ""}
                                        className={"sp_input"}
                                      />
                                    </div>
                                  </div>
                                  {groupSpecFileSizeWidthError ? (
                                    <div className="error">
                                      <Alert
                                        variant={"danger"}
                                        messages={groupSpecFileSizeWidthError}
                                        className=""
                                      />
                                    </div>
                                  ) : groupSpecFileSizeHeightError ? (
                                    <div className="error">
                                      <Alert
                                        variant={"danger"}
                                        messages={groupSpecFileSizeHeightError}
                                        className=""
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>

                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-3">
                                  DPI (*)
                                </Form.Label>
                                <Col sm={9}>
                                  <div className="d-sm-flex col-gap-20">
                                    <div className="">
                                      <Form.Control
                                        type="text"
                                        placeholder="2000"
                                        autoFocus
                                        onChange={(e) => {
                                          setImageDpi(ltrim(e.target.value));
                                          e.target.value.trimStart()
                                            ? setErrorSpecFileDPI("")
                                            : setErrorSpecFileDPI(
                                                "Required field"
                                              );
                                        }}
                                        value={image_dpi ? image_dpi : ""}
                                        className={"skip_placeholder"}
                                      />
                                      {groupSpecFileDPIError && (
                                        <Alert
                                          variant={"danger"}
                                          messages={groupSpecFileDPIError}
                                          className=""
                                        />
                                      )}
                                    </div>
                                    <div className="flex-fill">
                                      <Select
                                        options={dpi}
                                        onChange={(e) => {
                                          setImageDpiType(e.label);
                                          e.label
                                            ? setErrorSpecFileDPIType("")
                                            : setErrorSpecFileDPIType(
                                                "Required field"
                                              );
                                        }}
                                        value={
                                          image_dpi_type
                                            ? dpi.find(
                                                ({ label }) =>
                                                  label === image_dpi_type
                                              )
                                            : ""
                                        }
                                      />
                                      {groupSpecFileDPITypeError && (
                                        <Alert
                                          variant={"danger"}
                                          messages={groupSpecFileDPITypeError}
                                          className=""
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>

                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-3">
                                  Color Mode (*)
                                </Form.Label>
                                <Col sm={9}>
                                  <Select
                                    options={ColorMode}
                                    onChange={(e) => {
                                      setImageColorMode(e.label);
                                      e.label
                                        ? setErrorSpecColorMode("")
                                        : setErrorSpecColorMode(
                                            "Required field"
                                          );
                                    }}
                                    value={
                                      image_color_mode
                                        ? ColorMode.find(
                                            ({ label }) =>
                                              label === image_color_mode
                                          )
                                        : ""
                                    }
                                  />
                                  {groupSpecColorModeError && (
                                    <Alert
                                      variant={"danger"}
                                      messages={groupSpecColorModeError}
                                      className=""
                                    />
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>

                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-3">
                                  Align (*)
                                </Form.Label>
                                <Col sm={9}>
                                  <Select
                                    options={Align}
                                    onChange={(e) => {
                                      setImageAlignment(e.label);
                                      e.label
                                        ? setErrorSpecAlign("")
                                        : setErrorSpecAlign("Required field");
                                    }}
                                    value={
                                      image_alignment
                                        ? Align.find(
                                            ({ label }) =>
                                              label === image_alignment
                                          )
                                        : ""
                                    }
                                  />
                                  {groupSpecAlignError && (
                                    <Alert
                                      variant={"danger"}
                                      messages={groupSpecAlignError}
                                      className=""
                                    />
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>

                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-3">
                                  Margins (*)
                                </Form.Label>
                                <Col sm={9}>
                                  <div className="d-sm-flex col-gap-20">
                                    <div
                                      className=""
                                      style={{
                                        width: "42%",
                                      }}
                                    >
                                      <Select
                                        options={Margin}
                                        placeholder={"Select"}
                                        onChange={(e) => {
                                          setImageMarginFormat(e.label);
                                          e.label
                                            ? setErrorSpecMarginFormat("")
                                            : setErrorSpecMarginFormat(
                                                "Required field"
                                              );
                                        }}
                                        value={
                                          image_margin_format
                                            ? Margin.find(
                                                ({ label }) =>
                                                  label === image_margin_format
                                              )
                                            : ""
                                        }
                                      />
                                    </div>
                                    <div className="">
                                      <ul className="margin-list">
                                        <li
                                          style={{
                                            width: "18%",
                                          }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="T"
                                            autoFocus
                                            onChange={(e) => {
                                              setImageMarginTop(
                                                ltrim(e.target.value)
                                              );
                                              e.target.value.trimStart()
                                                ? setErrorSpecMarginTop("")
                                                : setErrorSpecMarginTop(
                                                    "Required field"
                                                  );
                                            }}
                                            value={
                                              image_margin_top
                                                ? image_margin_top
                                                : ""
                                            }
                                            className={"sp_input"}
                                          />
                                        </li>
                                        <li
                                          style={{
                                            width: "18%",
                                          }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="B"
                                            autoFocus
                                            onChange={(e) => {
                                              setImageMarginBottom(
                                                ltrim(e.target.value)
                                              );
                                              e.target.value.trimStart()
                                                ? setErrorSpecMarginBottom("")
                                                : setErrorSpecMarginBottom(
                                                    "Required field"
                                                  );
                                            }}
                                            value={
                                              image_margin_bottom
                                                ? image_margin_bottom
                                                : ""
                                            }
                                            className={"sp_input"}
                                          />
                                        </li>
                                        <li
                                          style={{
                                            width: "18%",
                                          }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="L"
                                            autoFocus
                                            onChange={(e) => {
                                              setImageMarginLeft(
                                                ltrim(e.target.value)
                                              );
                                              e.target.value.trimStart()
                                                ? setErrorSpecMarginLeft("")
                                                : setErrorSpecMarginLeft(
                                                    "Required field"
                                                  );
                                            }}
                                            value={
                                              image_margin_left
                                                ? image_margin_left
                                                : ""
                                            }
                                            className={"sp_input"}
                                          />
                                        </li>
                                        <li
                                          style={{
                                            width: "18%",
                                          }}
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="R"
                                            autoFocus
                                            onChange={(e) => {
                                              setImageMarginRight(
                                                ltrim(e.target.value)
                                              );
                                              e.target.value.trimStart()
                                                ? setErrorSpecMarginRight("")
                                                : setErrorSpecMarginRight(
                                                    "Required field"
                                                  );
                                            }}
                                            value={
                                              image_margin_right
                                                ? image_margin_right
                                                : ""
                                            }
                                            className={"sp_input"}
                                          />
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>

                                <Col sm={12}>
                                  {groupSpecMarginFormatError ||
                                  groupSpecMarginTopError ||
                                  groupSpecMarginBottomError ||
                                  groupSpecMarginLeftError ||
                                  groupSpecMarginRightError ? (
                                    <div className="margin-error">
                                      <Alert
                                        variant={"danger"}
                                        messages={
                                          groupSpecMarginFormatError
                                            ? groupSpecMarginFormatError
                                            : groupSpecMarginTopError
                                            ? groupSpecMarginTopError
                                            : groupSpecMarginBottomError
                                            ? groupSpecMarginBottomError
                                            : groupSpecMarginLeftError
                                            ? groupSpecMarginLeftError
                                            : groupSpecMarginRightError
                                            ? groupSpecMarginRightError
                                            : ""
                                        }
                                        className=""
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>
                          {/* <Row
          className="edit-type--modal"
          sm={4}
          style={{
            marginLeft: " auto",
            // marginTop: "-18px",
            columnGap: "21px",
            marginBottom: "15px",
            justifyContent: "inherit",
          }}
        >
          <Button
            variant="primary"
            className="btns"
            onClick={handleSubmitCreate}
            disabled={disabled}
          >
            Submit
          </Button>
          <h2
            className="text-secondary f-sz-13"
            style={{
              color: "green !important",
              width: "auto",
              margin: "10px",
            }}
          ></h2>
        </Row> */}
                        </div>
                      </>
                    </Form>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}{" "}
          </>
        </Modal.Body>
        {!loader && (
          <Modal.Footer>
            <div className="d-sm-flex justify-content-end">
              <div>
                <Button
                  onClick={() => {
                    setShow(false);
                  }}
                  variant=""
                  className="puple btns ml-6 "
                >
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button
                  variant="primary"
                  className="btns"
                  // onClick={handleClose}
                  onClick={handleSubmitCreate}
                  disabled={disabled}
                >
                  {props.groupID > 0 ? "Save" : "Add"}
                </Button>{" "}
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        heading={"Success"}
        message={addResponse}
        className="modal-body-body--success"
      />
    </>
  );
};

export default AddEditytypesGroups;
