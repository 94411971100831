import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Right from '../../../assets/Images/right.png';
import ProgressBar from './Components/ProgressBar';
import ConfirmBox from './Components/ConfirmBox';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import RightSidebar from './Components/RightSidebar';
import ImageSettingDetails from './ImageSettingDetails';




const ImageSettings = () => {

  const choosestaff = [
    { label: "Kobe Bryant", value: 355 },
    { label: "ArgeKobe Bryantntina", value: 54 },
    { label: "Kobe Bryant", value: 43 },
    { label: "ArgeKobe Bryantntina", value: 61 },
    { label: "Kobe Bryant", value: 965 },
    { label: "ArgeKobe Bryantntina", value: 46 },
    { label: "Kobe Bryant", value: 58 }
  ];

  const selectactions = [
    { label: "Select Settings", value: 355 },
    { label: "Select Settings", value: 54 }      
  ];

  const selectdays = [
    { label: "Today", value: 355 },
    { label: "Custom", value: 54 }      
  ];
  return (
    <>
      <div className="container-fluid page-body-wrapper">

        <Sidebar />
        <div className="main-panel ">
          <div className="rightbar pr-11">

          <ProgressBar role="image"/>
                    


                        <div className="workbody">
                            <Form className="mb-3">
                                <Row className="align-items-center">
                                    <Col lg={4} sm={12}>
                                        <div className="d-sm-flex flex-sm-wrap col-gap-14 align-items-center">
                                           
                                            <div className="flex-grow-1 ">
                                                <div className="form-group">
                                                    <Form.Control
                                                        type="search"
                                                        placeholder="Search Order / Image"
                                                        className="me-2"
                                                        aria-label="Search"
                                                    /> </div>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={6} sm={12}>
                                        <Form.Group className="ml-4" controlId="formBasicCheckbox">
                                            <div className="d-sm-flex col-gap-20 align-items-center">
                                                <div className="form-group">
                                                    <Form.Check type="checkbox" label="Select All" />
                                                </div>
                                                <div className="">
                                                    <div className="form-group">
                                                        <Select options={selectactions}
                                                            defaultValue={{ label: "Select Settings", value: 355 }} />
                                                    </div>
                                                    {/* <Form.Select>
                                                        <option>Actions</option>
                                                        <option>Actions</option>
                                                    </Form.Select> */}

                                                </div>
                                                <div className="form-group">

                                                    <Button variant="primary" className="btns">Apply to All</Button>{' '}
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col sm={2}>
                                        <Form.Select>
                                            <option>Actions</option>
                                            <option>Actions</option>
                                        </Form.Select>
                                    </Col> */}
                                    <Col sm={2}>
                                      
                                    </Col>
                                </Row>
                            </Form>



                            <div className="d-sm-flex">
                            <div className="rightsidebar">
                            <RightSidebar/>
                                  </div>
                                  <div className="midpart">
                                  <ImageSettingDetails
                                  />
                                  </div>
                            </div>


                            {/* <Row>                               
                                <Col lg={10} sm={10} className="pl-0">
                                  
                                </Col>
                                <Col lg={2} sm={3} className="pl-0">
                                
                                </Col>
                            </Row> */}
                        </div>


           


            


          </div>

          <ConfirmBox/>
        </div>
      </div>
    </>
  )
}

export default ImageSettings