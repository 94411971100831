import React, { useState } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";

import { apiURL } from "./../../../../../data/filepath";
import { client } from "./../../../../../data/API/GobalClientAxiosRequest";
import { ADD_EDIT_TYPE } from "./../../../../../config/apiRoutes.api";

/**
 * Reusable modal component.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.heading - The title or heading of the modal.
 * @param {string} props.message - The content or message of the modal.
 * @returns {JSX.Element} - The rendered modal component.
 */
const EditTypeModal = (props) => {
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	//Declarations of state variable
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [promoted_service, setPromotedServices] = useState(0);
	const [isMultiplesImages, setIsMultipleImages] = useState("");
	const [checked, setChecked] = useState(false);
	const [price, setPrice] = useState("");
	const [editor_point, setEditorPoint] = useState("");
	const [qc_point, setQcPoint] = useState("");
	const [status, setStatus] = useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		if (token) {
			const request = client(token);

			bodyFormData.append("edit_type", name);
			bodyFormData.append("description", description);
			bodyFormData.append("price", price);
			bodyFormData.append("is_for_enterprise", promoted_service);
			bodyFormData.append("is_multiple", isMultiplesImages);
			bodyFormData.append("editor_point", editor_point);
			bodyFormData.append("qc_point", qc_point);
			request
				.post(apiURL + ADD_EDIT_TYPE, bodyFormData)
				.then((response) => {
					console.log("add", response);
					props.setStatus("Success");
					props.setEditTypeModal(false);
					props.setSubmitStatusMessage(response.data.message);
					props.setModalShowEdit(true);
				})
				.catch((err) => {
					setStatus("Error");
					props.setSubmitStatusMessage(err);
				});
		}
	};
	console.log("isMultiPle", isMultiplesImages, promoted_service, status);
	return (
		<Modal
			{...props}
			// backdrop="static"
			// keyboard={false}

			size="lg"
			// aria-labelledby="contained-modal-title-vcenter"
			aria-labelledby="example-modal-sizes-title-lg"
			contentClassName="modal-content-edit--type "
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title
					id="example-modal-sizes-title-lg"
					className="section-title fonts"
					style={{ marginLeft: "10px" }}
				>
					{props.heading}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className="section-title fonts"
				style={{ marginLeft: "10px" }}
			>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Name</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="text"
								placeholder="Background Removal"
								autoFocus
								autoComplete="off"
								onChange={(e) => setName(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Description</Form.Label>
						<Col sm={11}>
							<Form.Control
								as="textarea"
								placeholder="Write Description..."
								style={{ height: "148px" }}
								autoFocus
								autoComplete="off"
								onChange={(e) => setDescription(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-5">Promoted Service</Form.Label>
						<Col sm={5} style={{ marginLeft: "-23px" }}>
							<input
								type="checkbox"
								value={!!checked ? 0 : 1}
								onClick={(e) => {
									setPromotedServices(e.target.value);
									setChecked((val) => !val);
								}}
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-4">Is Mulitple Image</Form.Label>
						<div className="edit-type-modal-isMultpile--type">
							<Col sm={12}>
								<Form.Label className="col-sm-4">Yes</Form.Label>
								<input
									type="radio"
									name="yes_no"
									value="1"
									onChange={(e) => setIsMultipleImages(e.target.value)}
								/>
								<Form.Label className="col-sm-4">No</Form.Label>
								<input
									type="radio"
									name="yes_no"
									value="0"
									onChange={(e) => setIsMultipleImages(e.target.value)}
								/>
							</Col>

							{/* <Form.Label className="col-sm-4">No</Form.Label>
							<Col sm={4}> */}

							{/* </Col> */}
						</div>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4 edit-type-modal-standard-type">
					{/* <Row> */}
					<div>
						<Form.Label>Standard Price</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="text"
							placeholder="$10"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							onChange={(e) => setPrice(e.target.value)}
						/>
						{/* </Col> */}
					</div>
					<div>
						<Form.Label>Standard QC Score</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="number"
							placeholder="500"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							onChange={(e) => setQcPoint(e.target.value)}
						/>
						{/* </Col> */}
					</div>
					<div>
						<Form.Label>Standard Editor Score</Form.Label>
						{/* <Col sm={2}> */}
						<Form.Control
							type="number"
							placeholder="10,000"
							autoFocus
							autoComplete="off"
							style={{ width: "100%" }}
							onChange={(e) => setEditorPoint(e.target.value)}
						/>
						{/* </Col> */}
					</div>
					{/* </Row> */}
				</div>
			</Modal.Body>
			<Modal.Footer style={{ marginTop: "-14px" }}>
				<div className="form-group">
					<div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
						<div className="">
							<Button
								variant=""
								className="puple btns mr-3"
								type="submit"
								onClick={props.onHide}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								className="btns"
								type="submit"
								onClick={(e) => handleSubmit(e)}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default EditTypeModal;
