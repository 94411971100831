import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppLogout from "./utils/AutoLogout";
import "./main.css";
import "./App.css";
import "./css/globalfonts.css";

import reportWebVitals from "./reportWebVitals";
export const AuthContext = React.createContext();
ReactDOM.render(
	<AuthContext.Provider>
		<React.StrictMode>
			<AppLogout>
				<App />
			</AppLogout>
		</React.StrictMode>
	</AuthContext.Provider>,

	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
