import React from 'react'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import pending from '../../assets/Images/pending.svg'
import editing from '../../assets/Images/editing.svg'
import revision from '../../assets/Images/revision.svg'
import total from '../../assets/Images/total.svg'
// import bgtotal from '../../../Images/bg-total.svg';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'react-bootstrap/Pagination';
import Select from 'react-select';




const Invoices = () => {

  const chooseyear = [
    { label: "This Year", value: 355 },
    { label: "This Year", value: 54 },
    { label: "This Year", value: 58 }
  ];
  const chooseoverdue = [
    { label: "Over Due", value: 355 },
    { label: "Over Due", value: 54 },
    { label: "Over Due", value: 58 }
  ];

  const invoices = [{
    id: '1',
    InvoiceNo: "INV-000001",
    IssueDate: '22 Aug 2022',
    DueDate: '22 Aug 2022',
    Amount: '$500.00 ',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#125ECB' }}>Due in 13 days</p>
  },
  {
    id: '1',
    InvoiceNo: "INV-000001",
    IssueDate: '22 Aug 2022',
    DueDate: '22 Aug 2022',
    Amount: '$500.00 ',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#125ECB' }}>Due in 13 days</p>
  },
  {
    id: '1',
    InvoiceNo: "INV-000001",
    IssueDate: '22 Aug 2022',
    DueDate: '22 Aug 2022',
    Amount: '$500.00 ',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#125ECB' }}>Partially Paid</p>
  },
  {
    id: '1',
    InvoiceNo: "INV-000001",
    IssueDate: '22 Aug 2022',
    DueDate: '22 Aug 2022',
    Amount: '$500.00 ',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#125ECB' }}>Overdue by 10 days</p>
  },
  {
    id: '1',
    InvoiceNo: "INV-000001",
    IssueDate: '22 Aug 2022',
    DueDate: '22 Aug 2022',
    Amount: '$500.00 ',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#125ECB' }}>Paid</p>
  },
  {
    id: '1',
    InvoiceNo: "INV-000001",
    IssueDate: '22 Aug 2022',
    DueDate: '22 Aug 2022',
    Amount: '$500.00 ',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#125ECB' }}>Paid</p>
  }
  ]
  const columns = [{
    dataField: 'InvoiceNo',
    text: 'Invoice No.',
    number: '1',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }, {
    dataField: 'IssueDate',
    text: 'Issue Date',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'DueDate',
    text: 'Due Date',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Amount',
    text: 'Amount',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'BalanceDue',
    text: 'Balance Due',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Status',
    text: 'Status',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }

  ];

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];


  // <BootstrapTable
  //   keyField="id"
  //   data={invoices}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />


  return (
    <>


      <div className="customer-search">
        <Form>
          <Row className="align-items-center">
            <Col lg={2} sm={4}>
              <div className="form-group">
                <Select options={chooseyear} defaultValue={{ label: "This Year", value: 355 }} />
              </div>
            </Col>
            <Col lg={2} sm={4}>
              <div className="form-group">
                <Select options={chooseoverdue} defaultValue={{ label: "Over Due", value: 355 }} /></div>
            </Col>
            <Col lg={5} sm={4}>
              <div className="form-group">
                <Form.Control
                  type="search"
                  placeholder="Search Invoice No."
                  className="me-2"
                  aria-label="Search"
                /></div>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="d-sm-flex invoicecard mb-4">

        <Card>
          <Card.Img variant="top" src={total} />
          <Card.Body>
            <Card.Title>Total Invoice Count</Card.Title>
            <Card.Text>
              12
            </Card.Text>
          </Card.Body>

        </Card>

        <Card>
          <Card.Img variant="top" src={pending} />
          <Card.Body>
            <Card.Title>Total Overdue Invoice Count</Card.Title>
            <Card.Text>
              5
            </Card.Text>

          </Card.Body>
        </Card>
        <Card>
          <Card.Img variant="top" src={editing} />
          <Card.Body>
            <Card.Title>Total Amount</Card.Title>
            <Card.Text>
              $350,000
            </Card.Text>

          </Card.Body>
        </Card>
        <Card>
          <Card.Img variant="top" src={revision} />
          <Card.Body>
            <Card.Title>Total Balance Due</Card.Title>
            <Card.Text>
              $35,000
            </Card.Text>

          </Card.Body>
        </Card>
      </div>



      <div className="table-responsive table-b">
        <BootstrapTable keyField='id' data={invoices} columns={columns} />

      </div>
      <Row className="mt-4">
        <Col md={{ span: 4, offset: 8 }}>
          {
            <Pagination>
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Item>{5}</Pagination.Item>
              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
          }
        </Col>
      </Row>




    </>
  )
}

export default Invoices