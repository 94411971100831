import React, { Component } from "react";
import "./ImageSlider.css";
//Importing the images
import PreviousIcon from "./../../../../../../assets/Images/prev-icon.svg";
import NextIcon from "./../../../../../../assets/Images/next-icon.svg";

class ImageSlider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			images: [
				`${props.imgaeURLOriginal}`,
				"https://picsum.photos/200/300/?blur",

				// Add more image URLs here
			],
			currentIndex: 0,
		};
	}

	// Function to handle next slide
	nextSlide = () => {
		this.setState((prevState) => ({
			currentIndex: (prevState.currentIndex + 1) % this.state.images.length,
		}));
	};

	// Function to handle previous slide
	prevSlide = () => {
		this.setState((prevState) => ({
			currentIndex:
				prevState.currentIndex === 0
					? this.state.images.length - 1
					: prevState.currentIndex - 1,
		}));
	};

	render() {
		const { images, currentIndex } = this.state;

		// Determine the indexes of the three images to display
		const prevIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
		const nextIndex = (currentIndex + 1) % images.length;

		return (
			<div className="image-slider">
				{/* <div className="prev" onClick={this.prevSlide}>
					<img
						src={PreviousIcon}
						className="img-fluid"
						alt="mindreach images"
					/>
				</div> */}

				<div className="slide-container">
					<div style={{ width: "100px", marginBottom: "3px" }}>
						<img
							className="slide"
							src={this.props.imgaeURLOriginal}
							alt={`Slide ${prevIndex}`}
						/>
						<h3
							className="form-label"
							style={{
								textAlign: "center",
								marginTop: "3px",
								marginBottom: "3px",
							}}
						>
							After
						</h3>
					</div>
					{/* <img
						className="slide"
						src={images[currentIndex]}
						alt={`Slide ${currentIndex}`}
					/> */}
					<div
						style={{ width: "100px", marginLeft: "18px", marginBottom: "3px" }}
					>
						<img
							className="slide"
							src={this.props.imgaeURLOriginal}
							alt={`Slide ${prevIndex}`}
						/>
						<h3
							className="form-label"
							style={{
								textAlign: "center",
								marginTop: "3px",
								marginBottom: "3px",
							}}
						>
							Original
						</h3>
					</div>
				</div>

				{/* <div className="next" onClick={this.nextSlide}>
					<img src={NextIcon} className="img-fluid" alt="mindreach images" />
				</div> */}
			</div>
		);
	}
}

export default ImageSlider;
