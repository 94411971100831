import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const EditTypes = () => {

    const EditTypes = [{
        id: '1',
        Name: "Resizing",
        EditorScore: "2, 500",
        Status: < p style={{ color: '#125ECB' }}> Active</p >
    },

    {
        id: '1',
        Name: "Resolution",
        EditorScore: "250",
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    },
    {
        id: '1',
        Name: "Blue Background",
        EditorScore: "2, 7000",
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    },
    {
        id: '1',
        Name: "Test edit type 7",
        EditorScore: "2, 7000",
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    }

    ]

    const columns = [{
        dataField: 'Name',
        text: 'Name',
        number: '1',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'EditorScore',
        text: 'Editor Score',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'Status',
        text: 'Status',
        headerStyle: (colum, colIndex) => {
            return { width: '90px', textAlign: 'left' };
        }
    }

    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    // <BootstrapTable
    //     keyField="id"
    //     data={EditTypes}
    //     columns={columns}
    //     striped
    //     hover
    //     condensed
    //     defaultSortDirection="asc"
    // />


    return (
        <>

            <div className="table-responsive table-b mb-4">
                <BootstrapTable keyField='id' data={EditTypes} columns={columns} />
            </div>

            <Row className="mt-4">
                <Col md={{ span: 4, offset: 8 }}>
                    {
                        <Pagination>
                            <Pagination.Prev />
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Item>{5}</Pagination.Item>
                            <Pagination.Item>{6}</Pagination.Item>
                            <Pagination.Next />
                        </Pagination>
                    }
                </Col>
            </Row>



        </>
    )
}

export default EditTypes