import React from 'react'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Delete from '../../../assets/Images/delete.svg'
import Select from 'react-select';



const Settings = () => {

    const role = [
        { label: "Editor", value: 355 },
        { label: "Editor 2", value: 54 }
    ];
    const shiftone = [
        { label: "Day Shift - Weekdays", value: 355 },
        { label: "Day Shift - Weekdays 2", value: 54 }

    ];

    const shiftwo = [
        { label: "Weekend Shift - Weekdays", value: 355 },
        { label: "Weekend Shift - Weekdays 2", value: 54 }

    ];

    return (
        <>
            <h4 className="mb-4">Edit User</h4>

            <Row>
                <Col sm={9} lg={6}>
                    <Form className="">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <div className="form-group">
                                <Row>

                                    <Form.Label className='col-sm-3'>Batch Size</Form.Label>
                                    <Col sm={7}>
                                        <Form.Control type="email" placeholder="Enter email" /></Col>
                                    <Col sm={2}>
                                        <Form.Text className="text-muted">
                                            Images
                                        </Form.Text>
                                    </Col>

                                </Row>
                            </div>
                            <div className="form-group">
                                <Row>
                                    <Form.Label className='col-sm-3'>Role</Form.Label>
                                    <Col sm={7}>
                                        <Select options={role} defaultValue={{ label: "Editor", value: 355 }} /></Col>
                                    <Col sm={2}>
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group">
                                <Row>
                                    <Form.Label className='col-sm-3'>Shift(s)</Form.Label>
                                    <Col sm={7}>
                                        <Select options={shiftone} defaultValue={{ label: "Day Shift - Weekdays", value: 355 }} />

                                        <Select className="mt-3" options={shiftwo} defaultValue={{ label: "Weekend Shift - Weekdays", value: 355 }} />
                                    </Col>
                                    <Col sm={2}>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col sm={10}>
                                    <p className="addfield">Add shift</p>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Col sm={10}>

                            <div className="d-sm-flex justify-content-between custom-flex-btn">
                                <div className="">
                                    <Button className="btn-light-border grey" type="submit" variant="">
                                        <img src={Delete} className="img-fluid" alt="mindreach images" />
                                    </Button>
                                </div>
                                <div className="">
                                    <Button className="puple btns mr-3" type="submit" variant="">
                                        Cancel
                                    </Button>
                                    <Button className="btns" type="submit" variant="primary">
                                        Save
                                    </Button>
                                </div>
                            </div></Col>

                    </Form>

                </Col>
            </Row>
        </>
    )
}

export default Settings