import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import '../../../css/report.css'
import chartstaff from '../../../assets/Images/chart-staff.jpg'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'react-bootstrap/Pagination';
import { FloatingLabel, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
const SupervisorReportStaffComparisonReport = () => {

    const selectimagecount = [
        { label: "Image Count", value: 1 },
        { label: "Demo", value: 2 }
    ];

    const selectdayshift = [
        { label: "Weekday Dayshift", value: 1 },
        { label: "Demo", value: 2 }
    ];

    const selectyear = [
        { label: "Today", value: 1 },
        { label: "Demo", value: 2 }
    ];

    const productionscore = [
        { label: "Production Score", value: 1 },
        { label: "Demo", value: 2 }
    ];

    const invoices = [{
        id: '1',
        StaffName: "Kobe Bryant",
        Role: 'Editor',
        Shift: 'Weekdays, Weekend',
        ImageCount: '65',
        ErrorCount: '1',
        ErrorRate: '1.41%',
        ProcessingTime: '1:15:43',
        ProductionScore: '5.17',
        EfficiencyScore: '3.24',
        PVSEarned: '105,918',
        Status: <p className="font-text-13" style={{ color: '#F1A100' }}>Processing</p>
    },
    {
        id: '1',
        StaffName: "Kobe Bryant",
        Role: 'Editor',
        Shift: 'Weekdays, Weekend',
        ImageCount: '65',
        ErrorCount: '1',
        ErrorRate: '1.41%',
        ProcessingTime: '1:15:43',
        ProductionScore: '5.17',
        EfficiencyScore: '3.24',
        PVSEarned: '105,918',
        Status: <p className="font-text-13" style={{ color: '#F1A100' }}>Processing</p>
    },
    {
        id: '1',
        StaffName: "Kobe Bryant",
        Role: 'Editor',
        Shift: 'Weekdays, Weekend',
        ImageCount: '65',
        ErrorCount: '0',
        ErrorRate: '1.41%',
        ProcessingTime: '1:15:43',
        ProductionScore: '5.17',
        EfficiencyScore: '3.24',
        PVSEarned: '105,918',
        Status: <p className="font-text-13" style={{ color: '#F1A100' }}>Processing</p>
    },
    {
        id: '1',
        StaffName: "Kobe Bryant",
        Role: 'Editor',
        Shift: 'Weekend Night',
        ImageCount: '65',
        ErrorCount: '0',
        ErrorRate: '1.41%',
        ProcessingTime: '1:15:43',
        ProductionScore: '5.17',
        EfficiencyScore: '3.24',
        PVSEarned: '105,918',
        Status: <p className="font-text-13" style={{ color: '#008E1A' }}>Completed</p>
    },
    {
        id: '1',
        StaffName: "Kobe Bryant",
        Role: 'Editor',
        Shift: 'Weekend Night',
        ImageCount: '65',
        ErrorCount: '$1.25',
        ErrorRate: '45,918',
        ProcessingTime: '1:15:43',
        ProductionScore: '5.17',
        EfficiencyScore: '3.24',
        PVSEarned: '105,918',
        Status: <p className="font-text-13" style={{ color: '#008E1A' }}>Completed</p>
    },
    {
        id: '1',
        StaffName: "Kobe Bryant",
        Role: 'Editor',
        Shift: 'Weekend Night',
        ImageCount: '65',
        ErrorCount: '$1.25',
        ErrorRate: '45,918',
        ProcessingTime: '1:15:43',
        ProductionScore: '5.17',
        EfficiencyScore: '3.24',
        PVSEarned: '105,918',
        Status: <p className="font-text-13" style={{ color: '#008E1A' }}>Completed</p>
    }
    ]
    const columns = [{
        dataField: 'StaffName',
        text: 'Staff Name',
        number: '1',
        sort: false,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    }, {
        dataField: 'Role',
        text: 'Role',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'Shift',
        text: 'Shift',

        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ImageCount',
        text: 'Image Count',

        headerStyle: (colum, colIndex) => {
            return { width: '183px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ErrorCount',
        text: 'Error Count',

        headerStyle: (colum, colIndex) => {
            return { width: '165px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ErrorRate',
        text: 'Error Rate',

        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ProcessingTime',
        text: 'Processing Time',

        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ProductionScore',
        text: 'Production Score',

        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'EfficiencyScore',
        text: 'Efficiency Score',

        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'PVSEarned',
        text: 'PVS Earned',


        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'Status',
        text: 'Status',

        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    }

    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    // <BootstrapTable
    //     keyField="id"
    //     data={invoices}
    //     columns={columns}
    //     striped
    //     hover
    //     condensed
    //     defaultSortDirection="asc"
    // />

    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel ">
                    <div className="rightbar pr-11">
                        <div className="section-title">
                            <h2>Editor Performance Comparison Report</h2>

                        </div>
                        <div className="customer-search mb-4 customer-report-search">
                            <Form>
                                <Row className="align-items-center">

                                    <Col xs={2}>
                                        {/* <Form.Label className='label-div'>Image Count</Form.Label> */}
                                        <Select options={selectimagecount}
                                            defaultValue={{ label: "Image Count", value: 1 }} />
                                    </Col>
                                    <Col xs={2}>
                                        {/* <Form.Label className='label-div'>Weekday Dayshift</Form.Label> */}
                                        <Select options={selectdayshift}
                                            defaultValue={{ label: "Weekday Dayshift", value: 1 }} />
                                    </Col>
                                    <Col xs={2}>
                                        {/* <Form.Label className='label-div'>This Year</Form.Label> */}
                                        <Select options={selectyear}
                                            defaultValue={{ label: "Today", value: 1 }} />
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Control
                                            type="search"
                                            placeholder="Search Staff"
                                            className="me-2 search-icon"
                                            aria-label="Search"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                        <div className="customer-chart">
                            <div className="mb-2">
                                <Form>
                                    <Row className="chart-filter">
                                        <Col xs={2} className="pr-0">
                                            <FormLabel>View</FormLabel>
                                        </Col>
                                        <Col xs={2}>
                                            {/* <Form.Label className='label-div'>Error Rate</Form.Label> */}
                                            <Select options={productionscore}
                                                defaultValue={{ label: "Production Score", value: 1 }} />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="chart-graph">
                                <div>
                                    <Row className="chart-graph-row">
                                        <Col xs={11}>
                                            <img src={chartstaff} />
                                        </Col>
                                        <Col xs={1} className="chart-btn text-center">
                                            <a href="#"><i className="fa fa-angle-left"></i></a>
                                            <a href="#"><i className="fa fa-angle-right"></i></a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive table-b supervisor-report-table">
                            <BootstrapTable keyField='id' data={invoices} columns={columns} />

                        </div>
                        <Row className="mt-4">
                            <Col md={{ span: 4, offset: 8 }}>
                                {
                                    <Pagination>
                                        <Pagination.Prev />
                                        <Pagination.Item active>{1}</Pagination.Item>
                                        <Pagination.Item>{2}</Pagination.Item>
                                        <Pagination.Item>{3}</Pagination.Item>
                                        <Pagination.Item>{4}</Pagination.Item>
                                        <Pagination.Item>{5}</Pagination.Item>
                                        <Pagination.Item>{6}</Pagination.Item>
                                        <Pagination.Next />
                                    </Pagination>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupervisorReportStaffComparisonReport