import React from 'react'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Card from 'react-bootstrap/Card';
import efficiencyscore from '../../../assets/Images/efficiency-score.svg'
import errorrate from '../../../assets/Images/error-rate.svg'
import errorcount from '../../../assets/Images/error-count.svg'
import imagecount from '../../../assets/Images/image-count.svg'
import processingtime from '../../../assets/Images/processing-time.svg'
import productionscore from '../../../assets/Images/production-score.svg'
import pvsearned from '../../../assets/Images/pvs-earned.svg'
import Select from 'react-select';

const Performance = () => {

  const choosedate = [
    { label: "Today", value: 355 },
    { label: "Custome", value: 54 }

  ];



  const products = [{
    id: '1',
    OderNo: "0000076",
    OrderDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    ImageCount: '65',
    ErrorCount: '1',
    ErrorRate: '1.41%',
    ProcessingTime: '1:15:43',
    ProductionScore: '5.17',
    EficiencyScore: '3.24',
    PVSEarned: '1,05,918',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '1',
    OderNo: "0000076",
    OrderDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    ImageCount: '65',
    ErrorCount: '1',
    ErrorRate: '1.41%',
    ProcessingTime: '1:15:43',
    ProductionScore: '5.17',
    EficiencyScore: '3.24',
    PVSEarned: '1,05,918',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '1',
    OderNo: "0000076",
    OrderDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    ImageCount: '65',
    ErrorCount: '1',
    ErrorRate: '1.41%',
    ProcessingTime: '1:15:43',
    ProductionScore: '5.17',
    EficiencyScore: '3.24',
    PVSEarned: '1,05,918',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '1',
    OderNo: "0000076",
    OrderDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    ImageCount: '65',
    ErrorCount: '1',
    ErrorRate: '1.41%',
    ProcessingTime: '1:15:43',
    ProductionScore: '5.17',
    EficiencyScore: '3.24',
    PVSEarned: '1,05,918',
    Status: <p style={{ color: '#008E1A' }}>Completed</p>
  },
  {
    id: '1',
    OderNo: "0000076",
    OrderDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    ImageCount: '65',
    ErrorCount: '1',
    ErrorRate: '1.41%',
    ProcessingTime: '1:15:43',
    ProductionScore: '5.17',
    EficiencyScore: '3.24',
    PVSEarned: '1,05,918',
    Status: <p style={{ color: '#008E1A' }}>Completed</p>
  },
  {
    id: '1',
    OderNo: "0000076",
    OrderDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    ImageCount: '65',
    ErrorCount: '1',
    ErrorRate: '1.41%',
    ProcessingTime: '1:15:43',
    ProductionScore: '5.17',
    EficiencyScore: '3.24',
    PVSEarned: '1,05,918',
    Status: <p style={{ color: '#008E1A' }}>Completed</p>
  }
  ]

  const columns = [{
    dataField: 'OderNo',
    text: 'Oder No.',
    number: '1',

    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }, {
    dataField: 'OrderDate',
    text: 'Order Date',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Customer',
    text: 'Customer',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'ImageCount',
    text: 'Image Count',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'ErrorCount',
    text: 'Error Count',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'ErrorRate',
    text: 'Error Rate',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'ProcessingTime',
    text: 'Processing Time',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'ProductionScore',
    text: 'Production Score',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'EficiencyScore',
    text: 'Eficiency Score',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'PVSEarned',
    text: 'PVS Earned',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Status',
    text: 'Status',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }


  ];

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];


  // <BootstrapTable
  //   keyField="id"
  //   data={products}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />
  return (
    <>



      <div className="mb-4">
        <Form>
          <Row className="align-items-center">
            <Col lg={2} sm={6}>
              <div className="form-group">
                <Select options={choosedate} defaultValue={{ label: "Today", value: 355 }} />
              </div></Col>
            <Col lg={4} sm={6}>
              <div className="form-group">
                <Form.Control
                  type="search"
                  placeholder="Search batch"
                  className="me-2 search-icon"
                  aria-label="Search"
                /></div>
            </Col>
          </Row>
        </Form>
      </div>



      <div className="d-sm-flex overviewtab supervisor-performance-tab mb-4">

        <Card>
          <Card.Img variant="top" src={imagecount} />
          <Card.Body>
            <Card.Title>Image Count</Card.Title>
            <Card.Text>
              2,313
            </Card.Text>


          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={errorcount} />
          <Card.Body>
            <Card.Title>Error Count</Card.Title>
            <Card.Text>
              198
            </Card.Text>

          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={errorrate} />
          <Card.Body>
            <Card.Title>Total Error Rate</Card.Title>
            <Card.Text>
              1.29%
            </Card.Text>

          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={processingtime} />
          <Card.Body>
            <Card.Title>Processing Time</Card.Title>
            <Card.Text>
              24:01:45
            </Card.Text>

          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={productionscore} />
          <Card.Body>
            <Card.Title>Production Score</Card.Title>
            <Card.Text>
              198
            </Card.Text>

          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={efficiencyscore} />
          <Card.Body>
            <Card.Title>Efficiency Score</Card.Title>
            <Card.Text>
              84.3
            </Card.Text>

          </Card.Body>
        </Card>
        <Card>
          <Card.Img variant="top" src={pvsearned} />
          <Card.Body>
            <Card.Title>Total PVS Earned</Card.Title>
            <Card.Text>
              4,400,123
            </Card.Text>
          </Card.Body>
        </Card>
      </div>




      <div className="table-responsive table-b overviewtables">
        <BootstrapTable keyField='id' data={products} columns={columns} />

      </div>
      <Row className="mt-4">
        <Col md={{ span: 4, offset: 8 }}>
          {
            <Pagination>
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Item>{5}</Pagination.Item>
              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
          }
        </Col>
      </Row>

    </>
  )
}

export default Performance