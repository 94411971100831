import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";

/**
 * Date range picker component to generate the date range picker.
 * @param {Function} dateCallback - The callback function which we need to call.
 * @param {String} format - The date format which we need to display and default is "YYYY-MM-DD".
 * @param {String} id - The id of the input which are passing and default is "date_range" .
 * @param {String} className - the class which we need to append for the date input
 * @returns {JSX.Element} - The rendered date range picker component.
 */
const DateRangePickr = ({
  dateCallback,
  format = "YYYY-MM-DD",
  id = "date_range",
  className = "",
}) => {
  {
    /* Please follow https://projects.skratchdot.com/react-bootstrap-daterangepicker/?path=/story/daterangepicker--log-events for documentation */
  }
  return (
    <DateRangePicker
      initialSettings={{
        open: true,
        locale: { format: format },
        readonly: true,
        autoApply: true,
        // linkedCalendars: false,  to select any date range
        showDropdowns: true,
      }}
      onCallback={(start, end) => {
        dateCallback(start, end);
      }}
      className={className}
    >
      <input type="text" readOnly id={id} />
    </DateRangePicker>
  );
};
export default DateRangePickr;
