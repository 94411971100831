import React from 'react'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Sidebar from '../../../components/global/Sidebar/Sidebar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import menudot from '../../../assets/Images/menu-dot.svg';
import BatchHistory from './BatchHistory';
import Performance from './Performance'
import Overview from './Overview';

const SupervisorStaffEditOverview = () => {
    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="rightbar pr-11 customrightbar">
                        <div className="detailstab">
                            <Tabs
                                defaultActiveKey="Settings"
                                id="justify-tab-example"
                                className="stafftab"
                                justify
                            >
                                <Tab eventKey="Overview" title="Overview">
                                    <Overview />
                                </Tab>
                                <Tab eventKey="BatchHistory" title="Batch History">
                                    <BatchHistory />
                                </Tab>
                                <Tab eventKey="Performance" title="Performance">
                                    <Performance />
                                </Tab>
                                <Tab eventKey="Settings" title="Settings">
                                    <h4 className="mb-4">Edit User</h4>

                                    <Row>
                                        <Col sm={9} lg={5}>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <div className="form-group">
                                                        <Row>

                                                            <Form.Label className='col-sm-3'>Name</Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control type="email" placeholder="Kobe Bryant" /></Col>

                                                        </Row>
                                                    </div>
                                                    <div className="form-group">
                                                        <Row>

                                                            <Form.Label className='col-sm-3'>Address</Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    placeholder="123 Mahogony Dr. Komplex Greenville Jakarta, DKI Jakarta Indonesia"
                                                                    style={{ height: '100px' }}
                                                                /></Col>

                                                        </Row>
                                                    </div>

                                                    <div className="form-group">
                                                        <Row>

                                                            <Form.Label className='col-sm-3'>Phone number</Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control type="email" placeholder="081234567" /></Col>

                                                        </Row>
                                                    </div>

                                                    <div className="form-group">
                                                        <Row>

                                                            <Form.Label className='col-sm-3'>Email</Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control type="email" placeholder="kbryant@mindreach.com" /></Col>

                                                        </Row>
                                                    </div>


                                                    <div className="form-group">
                                                        <Row>
                                                            <Form.Label className='col-sm-3'>Role</Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Select aria-label="Default select example">
                                                                    <option>Editor</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </Form.Select></Col>

                                                        </Row>
                                                    </div>
                                                    <div className="form-group">
                                                        <Row>
                                                            <Form.Label className='col-sm-3'>Status</Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Select aria-label="Default select example">
                                                                    <option>Inactive</option>
                                                                    <option value="1">Active</option>
                                                                </Form.Select>
                                                            </Col>

                                                        </Row>
                                                    </div>
                                                </Form.Group>
                                                <div className="d-sm-flex text-sm-center flex-sm-wrap justify-content-between custom-btn-flex">
                                                    <div className="mb-3">
                                                        <Button variant="" className="btn-light-border grey" type="submit">
                                                            <i className="fa fa-trash-o"></i>
                                                        </Button>
                                                    </div>
                                                    <div className="mb-3">
                                                        <Button variant="" className="puple black border-0 mr-3" type="submit">
                                                            Cancel
                                                        </Button>
                                                        <Button variant="primary" type="submit">
                                                            Save
                                                        </Button>
                                                    </div>
                                                </div>

                                            </Form>

                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                            <div className="doticon">
                                <img src={menudot} className="img-fluid" alt="mindreach images" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SupervisorStaffEditOverview