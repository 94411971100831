import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Col, Button } from "react-bootstrap";
import Select from "react-select";
import MyVerticallyCenteredModal from "./../../../../components/Modal";
// Importing API related files and routes
import { client } from "../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../../data/filepath";
import {
	GET_ALL_COORDINATORS,
	ADMIN_REASSIGNED_COORDINATOR,
} from "../../../../config/apiRoutes.api";

import { VIEW_COORDINATOR_WORKSPACE } from "../../../../config/routes";

const ReassignsModal = ({
	token,
	role,
	idUserSelected,
	order_number,
	idSelected,
	selectOptions,
	foldersPathSelected,
	fetchOrder,
	setReassignedModal,
	folderPathSelected,
}) => {
	// Create an HTTP client with the token
	const request = client(token);
	const navigate = useNavigate();
	const bodyFormData = new FormData();
	//Declarations of state variable
	const [options, setOptions] = useState([]);
	const [reassign_coordinator_id, setReassignCoordinatorId] = useState("");
	// const [selectOptions, setSelectOptions] = useState("");
	const [modalShow, setModalShow] = React.useState(false);
	const [addResponse, setAddResponse] = useState("");

	/**
	 * Transforms an array of  objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} options - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (options) =>
		options.map(({ first_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]

	useEffect(() => {
		if (token) {
			// Fetching coordinators user's  data from the API
			request
				.get(
					apiURL +
						GET_ALL_COORDINATORS +
						`?customer_type=${role}` +
						`&search_key=''`
				)
				.then((response) => {
					setOptions([...response.data.coordinators]);
				})
				.catch((err) => console.log(err));
		}
	}, [selectOptions]);
	const filterArrayById = (arr, targetId) => {
		return arr.filter((item) => item.id !== targetId);
	}; // filter the data if item.id is not equal too targetId
	const handledReassignedCoodinator = (event) => {
		if (token) {
			event.preventDefault();
			bodyFormData.append("coordinator_id", selectOptions);
			bodyFormData.append("reassign_coordinator_id", reassign_coordinator_id);
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("folder_paths", folderPathSelected);
			bodyFormData.append("order_id", order_number);
			request
				.post(apiURL + ADMIN_REASSIGNED_COORDINATOR, bodyFormData)
				.then((response) => {
					console.log(response);
					fetchOrder();
					setReassignedModal(false);
				})
				.catch((err) => console.log(err));
		}
	};
	console.log("folder Select Reassiged", foldersPathSelected);
	console.log("image select Reassigned", idSelected);
	return (
		<div className="editororderdetails exclude-click">
			<ul className="treeviewlist custom-viewlist">
				<div className="merged-image-text--container mt-2 d-flex">
					<Col sm={8}>
						<h3 className="reassigned--text">Reassigned the Coordinator</h3>
						<Form style={{ width: "12rem" }}>
							<Form.Group>
								<Select
									options={selectLabel(
										filterArrayById(options, idUserSelected)
									)}
									onChange={(e) => setReassignCoordinatorId(e.value)}
								/>
							</Form.Group>
						</Form>
						<div className="form-group mt-4">
							<Button
								variant="primary"
								className="btns"
								onClick={(e) => handledReassignedCoodinator(e)}
							>
								Save
							</Button>{" "}
						</div>
					</Col>
				</div>
			</ul>
			{/* <MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
					navigate(VIEW_COORDINATOR_WORKSPACE);
				}}
				heading={"Success"}
				message={addResponse}
				className="modal-body-body--success"
			/> */}
		</div>
	);
};

export default ReassignsModal;
