import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

/**
 * Confirmation modal for activating an item.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.title - The title of the modal.
 * @param {string} props.name - The name of the item to be activate.
 * @param {string} props.text - The branch associated with the item.
 * @param {function} props.handleConfirm - Callback function to handle activate confirmation.
 * @returns {JSX.Element} - The rendered delete confirmation modal component.
 */
const ActivateModalConfirmation = (props) => {
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal")) {
      // Check if the click occurred on an element with the "modal" class
      props.onHide();
    }
  };
  useEffect(() => {
    // Add event listener to detect clicks outside the modal
    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="my-modal"
        style={{ zIndex: 9999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="section-title fonts"
          >
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="section-title fonts" style={{ width: "30vw" }}>
          <p>{props.status}</p>
        </Modal.Body>
        {!props.hideCancel && (
          <Modal.Footer>
            <Button
              variant=""
              className="puple btns mr-2"
              onClick={props.onHide}
              style={{ display: "flex", marginLeft: "auto" }}
            >
              {props.btnText || "Cancel"}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default ActivateModalConfirmation;
