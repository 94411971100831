import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Editt from '../../../../assets/Images/editt.svg';




const DistributionBatchtable = () => {
    const DistributionBatchtable = [{
        id: '1',
        CoordinatorBatchSize: "100 image(s)",
        '': <img src={Editt} className="img-fluid ftp-table-img" alt="edit" />
    }

    ]

    const columns = [{
        dataField: 'CoordinatorBatchSize',
        text: 'Coordinator Batch Size',
        number: '1',
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'left' };
        }
    },

    {
        dataField: '',
        text: '',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }

    }

    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    // <BootstrapTable
    //     keyField="id"
    //     data={DistributionBatchtable}
    //     columns={columns}
    //     striped
    //     hover
    //     condensed
    //     defaultSortDirection="asc"
    // />
    return (
        <>

            <div className="table-responsive table-b mb-4">
                <BootstrapTable keyField='id' data={DistributionBatchtable} columns={columns} />
            </div>



        </>
    )
}

export default DistributionBatchtable