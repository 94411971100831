import React, { useCallback, useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { saveAs } from "file-saver";

//Importing drag and select Module
import {
	Box,
	boxesIntersect,
	useSelectionContainer,
} from "@air/react-drag-to-select";

import Loader from "../../../components/Loader";
import { imageURL } from "../../../data/filepath";
import DragSelect from "dragselect";
import ImageModal from "./Modal/ImageModal";
import MyVerticallyCenteredModal from "../../../components/Modal";
import RemoveModalConfirmation from "../../../components/Modal/ConfirmBox";

// import uploaded from "../../../assets/Images/uploaded.svg";
// import download from "../../../assets/Images/download.svg";

import submitted from "../../../assets/Images/checkmark-round.svg";
import uploaded from "../../../assets/Images/checkmark-round-blue.svg";
import upload from "../../../assets/Images/upload-round.svg";
import psd_final_upload from "../../../assets/Images/psd-final-upload.svg";
import cross from "../../../assets/Images/cros.svg";

import psd from "../../../assets/Images/psd.svg";
import { useOutsideAlerter } from "../../../utils/OutSideDivDeselect/EditorOutsideSelect";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import {
	EDITOR_DOWNLOAD_IMAGE,
	EDITOR_UPLOAD_IMAGE,
} from "../../../config/apiRoutes.api";
// import { forEach } from "core-js/core/array";

const EditorOrderDetails = ({
	show,
	images,
	selectedImagesId,
	setSelectedImagesID,
	setSelectAll,
	selectAll,
	reassign,
	token,
	idSelected,
	selectedStaff,
	activeIndex,
	getEditorImages,
	setImageID,
	imageID,
	selectedAssigntoQCImagesId,
	setSelectedAssigntoQCImagesID,
}) => {
	// Create a FormData object for request payload
	const form = new FormData();
	// Create a FormData object for request payload
	const bodyFormData = new FormData();
	const request = client(token);
	const [clickSelect, setClickSelect] = useState(false);
	const [ctrlClicked, setCtrlClicked] = useState(false);
	const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
	const [currentDownloadId, setCurrentDownloadId] = useState(0);
	const [currentUploadId, setCurrentUploadId] = useState(0);

	const [dragAndSelectEnabled, setDragAndSelectEnabled] = useState(false);
	const [firstSelectedIndex, setFirstSelectedIndex] = useState(0);
	const [secondSelectedIndex, setSecondSelectedIndex] = useState(0);
	const [showModal, setShowModal] = useState(false); // Image Modal
	const [image_details_view, setImageDetailsView] = useState({});
	const [image_details_view_id, setImageDetailsViewId] = useState("");
	const dsRef = useRef(null);
	const wrapper = useRef(null);
	const [selectionBox, setSelectionBox] = useState();
	const [selectedIndexes, setSelectedIndexes] = useState([]);
	const selectableItems = useRef([]);
	const elementsContainerRef = useRef(null);
	const [responseHeading, setResponseHeading] = useState("Error");
	const [responseMessage, setResponseMessage] = useState("Error");
	const [showUploadModal, setShowUploadModal] = useState(false); // Upload Modal
	const [removePSD, setRemovePSD] = useState(false); // Confirm Remove PSD Modal
	const [removePSDid, setRemovePSDID] = useState(""); // Image id to remove PSD

	useOutsideAlerter(
		wrapper,
		selectedImagesId,
		setClickSelect,
		setCtrlClicked,
		images,
		setSelectAll,
		setSelectedImagesID,
		setImageDetailsViewId,
		setImageDetailsView,
		setShowModal,

		setSelectedAssigntoQCImagesID,
		setSelectedIndexes
	); //Outside clickable wrapper
	/**
	 * Handles set class after select/un select of box.
	 * @param {Object} selected_items - An object array.
	 * @param {Number} id - An image id.
	 */
	const seledtedClass = (selected_items, id) => {
		return selected_items.length &&
			selected_items.find((selectedRow) => selectedRow == id)
			? "box exclude-click border-image--card layer-image--selector "
			: "box exclude-click";
	};

	/**
	 * Handles set class after select/un select of box.
	 * @param {Object} selected_items - An object array.
	 * @param {Number} id - An image id.
	 */
	const setCheckboxSelected = (selected_items, id) => {
		return selected_items.length &&
			selected_items.find((selectedRow) => selectedRow == id)
			? true
			: false;
	};

	// Define the logic  for drag and select
	const { DragSelection } = useSelectionContainer({
		eventsElement: document.getElementById("root"),
		onSelectionChange: (box) => {
			/**
			 * Here we make sure to adjust the box's left and top with the scroll position of the window
			 * @see https://github.com/AirLabsTeam/react-drag-to-select/#scrolling
			 */
			const scrollAwareBox = {
				...box,
				top: box.top + window.scrollY,
				left: box.left + window.scrollX,
			};

			setSelectionBox(scrollAwareBox);
			const indexesToSelect = [];
			selectableItems.current.forEach((item, index) => {
				if (boxesIntersect(scrollAwareBox, item)) {
					indexesToSelect.push(Number(index));
				}
			});

			setSelectedIndexes(indexesToSelect);
		},
		onSelectionStart: () => {
			console.log("OnSelectionStart");
		},
		onSelectionEnd: () => {
			console.log("OnSelectionEnd");
			const imagesIds = [];
			const folderIds = [];
			const intersected = document.querySelectorAll(".selected");
			intersected.forEach((element) => {
				console.log(element.classList);
				if (element.classList.contains("order_images")) {
					imagesIds.push(Number(element.getAttribute("id")));
				} else {
					if (
						element.classList.contains("folder") &&
						element.hasAttribute("id")
					) {
						folderIds.push(element.getAttribute("id"));
					}
				}
			});
			setSelectedImagesID(imagesIds);
			setAssgintoQCIds(imagesIds);
		},
		selectionProps: {
			style: {
				border: "1px solid #c1c1c1",
				borderRadius: 2,
				// backgroundColor: "rgba(20, 137, 189, 0.5)",
				backgroundColor: "#c1c1c1",
				opacity: 0.6,
				zIndex: 99,
			},
		},
		isEnabled: dragAndSelectEnabled,
	});

	// Define the logic to set the images in ref
	useEffect(() => {
		if (elementsContainerRef.current && images.length > 0) {
			Array.from(elementsContainerRef.current.children).forEach((item) => {
				if (item.innerHTML) {
					console.log(" item = ", item);
					const { left, top, width, height } = item.getBoundingClientRect();
					selectableItems.current.push({
						left,
						top,
						width,
						height,
					});
				}
			});
		}
	}, [images]);
	console.log("selectedImagesId", selectedImagesId);
	useEffect(() => {
		if (!selectedImagesId.length) {
			setDragAndSelectEnabled(true);
		}
	}, [selectedAssigntoQCImagesId]);
	// Function to handle the mousedown event
	const handleMouseDown = (event) => {
		setDragAndSelectEnabled(true);
	};
	// Function to handle the mouseup event
	const handleMouseUp = (event) => {
		setDragAndSelectEnabled(false);
	};

	const handleChangeSelected = useCallback(
		(changeEvent, item, id, index, itemList) => {
			setDragAndSelectEnabled(false);
			setCtrlClicked(false);
			setClickSelect(false);

			let itemId = [];
			const selectedItemCheck = changeEvent.currentTarget.id;
			if (changeEvent.ctrlKey || changeEvent.metaKey) {
				console.log("metaKey =>", changeEvent.metaKey);
				setSelectedIndexes([]);
				// Ctrl key is pressed
				setCtrlClicked(true);
				localStorage.setItem("ctrlClicked", true);

				const selectedItemsArray = [...selectedImagesId];
				const selectedQCmages = [...selectedAssigntoQCImagesId];
				if (selectedQCmages.indexOf(item.id) > -1) {
					setAssgintoQCIds(selectedItemsArray.filter((id) => id !== item.id));
				} else {
					setAssgintoQCIds([...selectedQCmages, id]);
				}

				if (selectedItemsArray.indexOf(item.id) > -1) {
					// Item is already selected
					setSelectedImagesID((prevSelectedItems) =>
						prevSelectedItems.filter((itemId) => itemId !== id)
					);
				} else {
					setSelectedImagesID([...selectedImagesId, id]);
				}
				// break;
			} else if (changeEvent.shiftKey) {
				setSelectedIndexes([]);
				setAssgintoQCIds([]);
				itemId.push(...selectedItemCheck);
				if (itemId.length === 0) {
					setSelectedImagesID([id]);
					setAssgintoQCIds([id]);
				}
				// Shift key is pressed
				else {
					const selectedItemsArray = Array.from(selectedImagesId);
					const selectedIndicesArray = selectedItemsArray.map((itemId) =>
						itemList.findIndex((item) => item.id === itemId)
					);

					const currentIndex = index;
					const minSelectedIndex = Math.min(...selectedIndicesArray);
					const maxSelectedIndex = Math.max(...selectedIndicesArray);

					const start = Math.min(minSelectedIndex, currentIndex);
					const end = Math.max(maxSelectedIndex, currentIndex);

					const rangeIndices = Array.from(
						{ length: end - start + 1 },
						(_, i) => start + i
					);
					const rangeIds = rangeIndices.map((idx) => itemList[idx].id);

					setSelectedImagesID(rangeIds);
					setAssgintoQCIds(rangeIds);
				}
			} else {
				// ... (other parts of the code)
				if (changeEvent.type === "click") {
					setSelectedIndexes([]);
					setSelectedImagesID([]);
					setAssgintoQCIds([]);

					// const newClickSelect = !clickSelect;
					const newClickSelect = true;
					if (
						// changeEvent.currentTarget.classList.contains("ds-selectable") ||
						!changeEvent.currentTarget.classList.contains("border-image--card")
					) {
						setSelectedImagesID([id]);
						setImageID(id);
						setAssgintoQCIds([id]);
					}
					setClickSelect(newClickSelect);
					localStorage.setItem("clickSelect", true);
				}
			}
			// }
		},
		[
			firstSelectedIndex,
			lastSelectedIndex,
			selectedImagesId,
			// handleKeyUp,
			// activeSelected,
			selectedImagesId,

			images,
			// selectedItemId,
			setClickSelect,
			clickSelect,
			wrapper,

			// ref,
		]
	);

	//Set The image details
	const handleClickImageDetails = (items, id) => {
		setImageDetailsViewId(id);
		setImageDetailsView(items);
		setShowModal(true);
		let order_images = document.querySelectorAll(".order_images");
		for (var i = 0; i < order_images.length; i++) {
			order_images[i].style.zIndex = 1;
			document.body.classList.add("modal-open");
		}
		// setImageDetailsModal(true);
	};

	/**
	 * Function to be called after download click.
	 *  * @param {Object} event -  Event Object.
	 *  * @param {Object} item -  item Object.
	 *
	 */
	const downloadDocument = (event, item) => {
		event.preventDefault();
		setCurrentDownloadId(item.id);
		let fileName = item.image_path;
		const headers = {
			Authorization: token
				? "Bearer " + `${token.slice(1, token.length - 1)}`
				: "",
		};

		form.append("editor_id", selectedStaff.value);
		form.append("image_ids", item.id);
		axios({
			url: apiURL + EDITOR_DOWNLOAD_IMAGE,
			method: "POST",
			headers: headers,
			data: form,
			responseType: "blob", // Important: Ensure the API response is Blob
		})
			.then((response) => {
				saveAs(response.data, fileName);
				setCurrentDownloadId(0);
			})
			.catch((error) => {
				setCurrentDownloadId(0);
				console.error("Error downloading file:", error);
			});
	};

	/**
	 * Handle the show the comment status icon.
	 * @param {Object} item - item Object.
	 */

	const getStatusImage = (item) => {
		return item.editor_status === "upload"
			? psd_final_upload
			: item.editor_status === "uploaded"
			? uploaded
			: submitted;
	};

	/**
	 * Handle the image upload.
	 * @param {Object} event - event Object.
	 * @param {Number} image_id - An image id.
	 */

	const uploadImage = (event, image_id) => {
		event.preventDefault();
		if (event.target.files && event.target.files.length > 0) {
			setCurrentUploadId(image_id);
			bodyFormData.append("editor_id", selectedStaff.value);
			bodyFormData.append("image_id", image_id);
			for (let i = 0; i < event.target.files.length; i++) {
				bodyFormData.append("order_images[]", event.target.files[i]);
			}
			// return false;
			request
				.post(apiURL + EDITOR_UPLOAD_IMAGE, bodyFormData)
				.then((response) => {
					//Console the  response
					console.log("response", response.data);
					setResponseMessage(response.data.message);
					if (response.data.status) {
						setResponseHeading("Success");
						setTimeout(() => {
							getEditorImages(activeIndex);
							setTimeout(() => {
								setShowUploadModal(true);
							}, 100);
						}, 700);
					} else {
						setShowUploadModal(true);
						setResponseHeading("Error");
					}
					setCurrentUploadId(0);
					for (let key of bodyFormData.keys()) {
						bodyFormData.delete(key);
					}
					const fileInput = document.getElementById(`file_${image_id}`);
					if (fileInput) {
						fileInput.value = null;
					}
					//Set the success/error response
				})
				.catch((err) => {
					console.log("error response", err);
					setCurrentUploadId(0);
					setResponseHeading("Error");
					setResponseMessage("Oops! something went wrong!");
					setShowUploadModal(true);
					for (let key of bodyFormData.keys()) {
						bodyFormData.delete(key);
					}
					const fileInput = document.getElementById(`file_${image_id}`);
					if (fileInput) {
						fileInput.value = null;
					}
				});
		}
	};

	/**
	 * Handles to set the Assign to QC state from the object.
	 * @param {Object} object - An array.
	 */
	const setAssgintoQCIds = (object) => {
		let ids = [];
		if (object && object.length > 0) {
			object.forEach((id) => {
				let imageIndex = images.findIndex((d) => d.id == id);
				if (images[imageIndex].editor_status !== "submitted") {
					ids.push(id);
				}
			});
		}
		setSelectedAssigntoQCImagesID(ids);
	};

	/**
	 * Handles to make the http call for delete the final PSD and image.
	 * @param {Object} e - An array.
	 */

	const handlePsdDelete = (e) => {
		e.preventDefault();
		setRemovePSD(false);
	};

	// check if to show the loader
	if (show) return <Loader />;
	return (
		<>
			<DragSelection />
			<div
				className="editororderdetails editor editororderdetails-new image noselect"
				// ref={wrapper}
				style={{
					overflowY: "auto",
				}}
			>
				<div className="detailslist coordibox" ref={elementsContainerRef}>
					{images &&
						images.map((items, index) => {
							return (
								<div
									// onKeyUp={(e) => handleKeyUp(e)}
									className={
										"cardsContainer order_images" +
										// (selectedImagesId.indexOf(Number(items.id)) >= 0
										(selectedIndexes.indexOf(Number(index)) >= 0
											? " selected"
											: "")
									}
									data-data="111"
									style={{ zIndex: 1 }}
									id={items.id}
									key={items.id}
									// style={{ zIndex: "unset!important" }}
								>
									<Card
										id={items.id}
										key={index}
										className={seledtedClass(selectedImagesId, items.id)}
										ref={wrapper}
										onDoubleClick={(e) => {
											const isInsideTaskStatus =
												e.target.closest(".not-clickable");
											if (!isInsideTaskStatus) {
												handleClickImageDetails(items, items.id);
											}
										}}
										onClick={(e) => {
											const isInsideTaskStatus =
												e.target.closest(".not-clickable");

											if (!isInsideTaskStatus) {
												handleChangeSelected(e, items, items.id, index, images);

												selectedImagesId.length === 0
													? setFirstSelectedIndex(items.id)
													: setSecondSelectedIndex(items.id);
											}
										}}
									>
										<div
											style={{
												height: "auto",
												background: "#ffff",
												overflow: "hidden",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
											id={items.id}
										>
											<img
												id={items.id}
												variant="top"
												width={200}
												height={100}
												className="image-rendering"
												loading="lazy"
												alt="Mindreach"
												// layout="fill"
												src={`${imageURL}/${items.customer.email}/${items.batch_number}/${items.order.order_number}/images/${items.image_path}`}
											/>
										</div>
										<input
											type="checkbox"
											className="checkbox-order--sub"
											value={items.id}
											id={items.id}
											checked={setCheckboxSelected(selectedImagesId, items.id)}
											key={index}
										/>

										<Card.Body>
											<Card.Text>{items.image_path}</Card.Text>

											<div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap ">
												<div
													className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap not-clickable"
													style={{ marginTop: "15px" }}
												>
													<div
														className={
															currentDownloadId !== items.id ? "bothbtn" : ""
														}
														style={{
															marginRight:
																(currentDownloadId === items.id ? "7" : "0") +
																"px",
														}}
														onClick={(e) => {
															e.stopPropagation();
															downloadDocument(e, items);
														}}
													>
														{currentDownloadId !== items.id ? (
															<>
																<span>
																	<img
																		src={psd}
																		className="img-fluid"
																		alt="mindreach images"
																		style={{
																			margin: "0px 5px 0px 5px",
																			width: "17px",
																		}}
																	/>
																</span>
																{/* PSD */}
															</>
														) : (
															<span>
																<Loader
																	id={`loader_${items.id}`}
																	className={"image_loader"}
																/>
															</span>
														)}
													</div>
													{/* upload uploaded submitted*/}

													<div
														className={
															" task_status disabled " +
															`${items.editor_status}` +
															(currentUploadId !== items.id ? " bothbtn" : "")
														}
														onClick={(e) => {
															e.stopPropagation();
														}}
													>
														{currentUploadId !== items.id ? (
															<>
																{items.editor_status === "upload" ? (
																	<div
																		className="ml-2"
																		style={{ fontWeight: "bold" }}
																	>
																		{"PSD + Final"}
																		<span className="ml-2">
																			{/* <img
																				src={getStatusImage(items)}
																				className="img-fluid"
																				alt="mindreach images"
																				style={{
																					width: 17,
																				}}
																			/> */}
																		</span>
																	</div>
																) : (
																	<>
																		<span>
																			<img
																				src={getStatusImage(items)}
																				className="img-fluid"
																				alt="mindreach images"
																				style={{
																					width: 17,
																				}}
																			/>
																		</span>
																		{items.editor_status}
																	</>
																)}
															</>
														) : (
															<span>
																<Loader
																	id={`loader_${items.id}`}
																	className={"upload_loader"}
																/>
															</span>
														)}
													</div>
												</div>
											</div>
										</Card.Body>
									</Card>
								</div>
							);
						})}

					{/*********************************Image Modal******************************/}
					<ImageModal
						setShowModal={setShowModal}
						showModal={showModal}
						image_details_view={image_details_view}
						setImageDetailsView={setImageDetailsView}
						setImageDetailsViewId={setImageDetailsViewId}
						// editTypes={editTypes}
						// editGroups={editGroups}
						setSelectedImagesID={setSelectedImagesID}
						selectImageName={""}
						token={token}
						idSelected={idSelected}
						setClickSelect={setClickSelect}
						images={images}
						setImageID={setImageID}
						imageID={imageID}
					/>
					<RemoveModalConfirmation
						show={removePSD}
						onHide={() => {
							setRemovePSD(false);
						}}
						title={"Remove uploaded image"}
						message={"Are you sure wants to remove uploaded image?"}
						handleConfirm={(e) => handlePsdDelete(e)}
					/>
					<MyVerticallyCenteredModal
						show={showUploadModal}
						onHide={() => {
							setShowUploadModal(false);
						}}
						heading={responseHeading}
						message={responseMessage}
						className="modal-body-body--success"
					/>
				</div>
			</div>
		</>
	);
};

export default EditorOrderDetails;
