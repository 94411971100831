import React from 'react'
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomerEditingchat from './CustomerEditingchat';
import CustomerSaleschat from './CustomerSaleschat'




const CustomerChat = () => {
    return (
        <>

            <div className="d-sm-flex justify-content-between mt-3 align-items-center">
                <div className="ml-3 mb-3">
                    <h4 className="">Nike, Inc.</h4>
                </div>
                <div className="online mr-4 mb-3">
                    <p><i className="fa fa-circle mr-2"></i>Online</p>
                </div>
            </div>

            <div className="innercustomertab supervisor-chat">
                <Tabs
                    defaultActiveKey="EditingTeam"
                    id="justify-tab-example"
                    className="customertab"
                    justify
                >
                    <Tab eventKey="EditingTeam" title="Editing Team">
                        <CustomerEditingchat />
                    </Tab>
                    {/* <Tab eventKey="SalesTeam" title="Sales Team">
                        <CustomerSaleschat />
                    </Tab> */}
                </Tabs>

            </div>

        </>
    )
}

export default CustomerChat