import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/Login/Auth.context";

import facebook from "../../assets/Images/facebook.png";
import linkedin from "../../assets/Images/linkedin.png";
import amazon from "../../assets/Images/amazon.png";
import microsoft from "../../assets/Images/microsoft.png";
import google from "../../assets/Images/google.png";
import { apiURL } from "../../data/filepath";
import Loader from "../../components/Loader";

/**
 * Login component for handling user authentication.
 * @returns {JSX.Element} - The rendered login form.
 */
const Login = () => {
	const navigate = useNavigate();
	const bodyFormData = new FormData();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [loader, setLoader] = useState(false);

	const { dispatch } = React.useContext(AuthContext);

	/**
	 * Handles the form submission.
	 * @param {React.FormEvent} event - The form submit event.
	 */
	const handleSubmit = (event) => {
		event.preventDefault();
		bodyFormData.append("email", email);
		bodyFormData.append("password", password);
		setLoader(true);

		return axios({
			method: "post",
			url: apiURL + "/login",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (response) {
				// Handle success
				sessionStorage.setItem("role", JSON.stringify(response.data.role));
				dispatch({
					type: "LOGIN",
					payload: response.data,
				});
				setLoader(false);
				if (JSON.parse(sessionStorage.getItem("customer")).id) {
					navigate("/dashboard");
				}
			})
			.catch(function (response) {
				// Handle error
				setError("Invalid Credentials");
				console.log(response.message);
				setLoader(false);
			});
	};

	return (
		<>
			<div className="container">
				<Row className="align-items-center justify-content-center">
					<Col sm={5}>
						<form className="Auth-form">
							<div className="Auth-form-content">
								<h3 className="Auth-form-title">Sign In</h3>

								<div className="form-group mt-3">
									<label>Email address</label>
									<input
										type="email"
										className="form-control mt-1"
										placeholder="Enter email"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								<div className="form-group mt-3">
									<label>Password</label>
									<input
										type="password"
										className="form-control mt-1"
										placeholder="Enter password"
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<div className="d-grid gap-2 mt-3">
									<button
										type="submit"
										className={!loader ? `btn btn-primary` : `btn btn-outline`}
										onClick={handleSubmit}
									>
										{loader ? (
											<div>
												<Loader className="loader-login " />
											</div>
										) : (
											"Submit"
										)}
									</button>

									{error !== "" && (
										<h2 className="form-group mt-3 loginError">{error}</h2>
									)}
								</div>
								<p className="forgot-password text-center mt-3">
									Forgot <a href="#">password?</a>
								</p>
								<p className="text-center mt-3">Sign in with</p>

								<ul className="sociallink mt-4 text-center">
									<li>
										{" "}
										<img
											src={facebook}
											className="img-fluid"
											alt="mindreach images"
											//   alt="facebook"
										/>
									</li>
									<li>
										{" "}
										<img
											src={linkedin}
											className="img-fluid"
											alt="mindreach images"
											//   alt="linkedin"
										/>
									</li>
									<li>
										{" "}
										<img
											src={amazon}
											className="img-fluid"
											alt="mindreach images"
											//   alt="amazon"
										/>
									</li>
									<li>
										{" "}
										<img
											src={microsoft}
											className="img-fluid"
											alt="mindreach images"
											//   alt="microsoft"
										/>
									</li>
									<li>
										{" "}
										<img
											src={google}
											className="img-fluid"
											alt="mindreach images"
											//   alt="google"
										/>
									</li>
								</ul>
							</div>
						</form>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default Login;
