import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from './Components/ProgressBar';
import Button from 'react-bootstrap/Button';
import Thankyou from '../../../assets/Images/thankyou-right.png'
import plus from '../../../assets/Images/plus.svg';

const OrderReceived = () => {
  return (
    <>
      <div className="container-fluid page-body-wrapper">

        <Sidebar />
        <div className="main-panel ">
          <div className="rightbar rightbar-marign-r">

            <ProgressBar role="received" />







            <Row className="justify-content-center">
              <Col sm={8} md={8} lg={6}>
                <div className="thankyoubox">
                  <h4>Your order has been received</h4>
                  <img src={Thankyou} className="img-fluid mt-3" alt="Your order has" />
                  <div className="thankyoubox-sub">Thank you for order submission!</div>
                  <div className="thankyoubox-order">Your order number is:
                    <span> 0009385</span>
                  </div>

                  <p>You will receive an order confirmation in your email with
                    details of your order. You can also go to the orders page in your
                    account and click into this order number to see
                    the same details including the real time status of your order.</p>

                  <div className="d-sm-flex justify-content-center mt-4">
                    <div className="mr-3">

                      <Button variant="primary" className="" type="submit">
                        <span><img src={plus} className="img-fluid mr-2" /></span> New Order
                      </Button>
                    </div>
                    <div className="">
                      <Button variant="" className="puple " type="submit">
                        Return to Orders Page
                      </Button>

                    </div>


                  </div>
                </div>
              </Col>
            </Row>




          </div>
        </div>
      </div>
    </>
  )
}

export default OrderReceived