import React from "react";
import Sidebar from "../../components/global/Sidebar/Sidebar";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import '../../../css/report.css'
// import chart from '../../../Images/chart.svg'
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "react-bootstrap/Pagination";
import { FloatingLabel, FormLabel } from "react-bootstrap";
import Select from "react-select";
import reportedittypecount from "../../assets/Images/customer-total-image-count.svg";
import reporttotalrevenue from "../../assets/Images/customer-total-error-count.svg";
import reporttotalpvscost from "../../assets/Images/revenue.svg";
import reporttotalaverageprice from "../../assets/Images/average-turnaround-time.svg";
import reporttotalaveragepvs from "../../assets/Images/pvs-icon.svg";
import reporttotalaverageeditingtime from "../../assets/Images/editing-time-icon.svg";

const report = () => {
  const selectenterprise = [
    { label: "Enterprise", value: 1 },
    { label: "Demo", value: 2 },
  ];

  const selectcustomers = [
    { label: "All Customers", value: 1 },
    { label: "Demo", value: 2 },
  ];

  const selectedittype = [
    { label: "Edit Type", value: 1 },
    { label: "Demo", value: 2 },
  ];

  const selectyear = [
    { label: "This Year", value: 1 },
    { label: "Demo", value: 2 },
  ];

  const invoices = [
    {
      id: "1",
      Customer: "Nike, Inc.",
      EditTypeName: "Background Removal",
      EditTypeCount: "1,000",
      PricePerEditType: "$2.00",
      PVSperImage: "1,000",
      TotalRevenue: "$750",
      TotalPVS: "25,000",
      AverageEditingTime: "00:30:17",
    },
    {
      id: "1",
      Customer: "Nike, Inc.",
      EditTypeName: "Retouching",
      EditTypeCount: "1,000",
      PricePerEditType: "$2.00",
      PVSperImage: "1,000",
      TotalRevenue: "$750",
      TotalPVS: "25,000",
      AverageEditingTime: "00:30:17",
    },
    {
      id: "1",
      Customer: "Nike, Inc.",
      EditTypeName: "Resizing",
      EditTypeCount: "1,000",
      PricePerEditType: "$2.00",
      PVSperImage: "1,000",
      TotalRevenue: "$750",
      TotalPVS: "25,000",
      AverageEditingTime: "00:30:17",
    },
    {
      id: "1",
      Customer: "Nike, Inc.",
      EditTypeName: "Cropping",
      EditTypeCount: "1,000",
      PricePerEditType: "$2.00",
      PVSperImage: "1,000",
      TotalRevenue: "$750",
      TotalPVS: "25,000",
      AverageEditingTime: "00:30:17",
    },
    {
      id: "1",
      Customer: "Reebok International",
      EditTypeName: "Background Removal",
      EditTypeCount: "1,000",
      PricePerEditType: "$2.00",
      PVSperImage: "1,000",
      TotalRevenue: "$750",
      TotalPVS: "25,000",
      AverageEditingTime: "00:30:17",
    },
    {
      id: "1",
      Customer: "Reebok International",
      EditTypeName: "Retouching",
      EditTypeCount: "1,000",
      PricePerEditType: "$2.00",
      PVSperImage: "1,000",
      TotalRevenue: "$750",
      TotalPVS: "25,000",
      AverageEditingTime: "00:30:17",
    },
  ];
  const columns = [
    {
      dataField: "Customer",
      text: "Customer",
      number: "1",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "EditTypeName",
      text: "Edit Type Name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "EditTypeCount",
      text: "Edit Type Count",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "PricePerEditType",
      text: "Price Per Edit Type",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "PVSperImage",
      text: "PVS per Image",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "TotalRevenue",
      text: "Total Revenue",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "TotalPVS",
      text: "Total PVS",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "AverageEditingTime",
      text: "Average Editing Time",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  // <BootstrapTable
  //   keyField="id"
  //   data={invoices}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />;

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="rightbar pr-30">
            <div className="section-title">
              <h2>Edit Type Performance</h2>
            </div>
            <div className="customer-search mb-4">
              <Form>
                <Row className="align-items-center">
                  <Col xs={2}>
                    {/* <Form.Label className='label-div'>Enterprise</Form.Label> */}
                    <Select
                      options={selectenterprise}
                      defaultValue={{ label: "Enterprise", value: 1 }}
                    />
                  </Col>
                  <Col xs={2}>
                    {/* <Form.Label className='label-div'>Customers</Form.Label> */}
                    <Select
                      options={selectcustomers}
                      defaultValue={{ label: "All Customers", value: 1 }}
                    />
                  </Col>
                  <Col xs={2}>
                    {/* <Form.Label className='label-div'>Edit Type</Form.Label> */}
                    <Select
                      options={selectedittype}
                      defaultValue={{ label: "Edit Type", value: 1 }}
                    />
                  </Col>
                  <Col xs={2}>
                    {/* <Form.Label className='label-div'>This Year</Form.Label> */}
                    <Select
                      options={selectyear}
                      defaultValue={{ label: "This Year", value: 1 }}
                    />
                  </Col>
                </Row>
              </Form>
            </div>

            <div className="d-sm-flex mb-4 report-edit">
              <Card className="bg-card">
                {/* <Card.Img variant="top" src={reportedittypecount} /> */}
                <Card.ImgOverlay>
                  <Card.Img variant="top" src={reportedittypecount} />
                  <Card.Body>
                    <Card.Title>Edit Type Count</Card.Title>
                    <Card.Text>81,937</Card.Text>
                  </Card.Body>
                </Card.ImgOverlay>
              </Card>
              <Card>
                <Card.Img variant="top" src={reporttotalrevenue} />
                <Card.Body>
                  <Card.Title>Total Revenue</Card.Title>
                  <Card.Text>$100,000</Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={reporttotalpvscost} />
                <Card.Body>
                  <Card.Title>Total PVS Cost</Card.Title>
                  <Card.Text>100,250</Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={reporttotalaverageprice} />
                <Card.Body>
                  <Card.Title>Total Average Price</Card.Title>
                  <Card.Text>$5.14</Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={reporttotalaveragepvs} />
                <Card.Body>
                  <Card.Title>Total Average PVS</Card.Title>
                  <Card.Text>$2,500</Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={reporttotalaverageeditingtime} />
                <Card.Body>
                  <Card.Title>Total Average Editing Time</Card.Title>
                  <Card.Text>00:01:10</Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="table-responsive table-b">
              <BootstrapTable keyField="id" data={invoices} columns={columns} />
            </div>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 8 }}>
                {
                  <Pagination>
                    <Pagination.Prev />
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Item>{6}</Pagination.Item>
                    <Pagination.Next />
                  </Pagination>
                }
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default report;
