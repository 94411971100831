import React from "react";
import Forbidden from "./../../assets/Images/actionsIcons/forbidden.svg";
const Unauthorized = () => {
	return (
		<div>
			<div id="container">
				<div className="unauthorised--page">
					<h3>FORBIDDEN</h3>
					<h4 className="number">403</h4>
					<h3 className="icon">
						<img src={Forbidden} className="img-fluid" alt="unauthorised" />
					</h3>
                    <h3 className="access">Access Denied</h3>
				</div>
			</div>
		</div>
	);
};
export default Unauthorized;
