import React, { useRef, useEffect, useState } from "react";

export function useOutsideAlerter(
	ref,
	selectedImagesId,
	setClickSelect,
	setCtrlClicked,
	images,
	setSelectAll,
	setSelectedImagesID,
	setImageDetailsViewId,
	setImageDetailsView,
	setShowModal,
	setSelectedAssigntoQCImagesID,
	setSelectedIndexes
) {
	// const [isExcluded, setIsExcluded] = useState(false);
	useEffect(() => {
		function handleClickOutside(event) {
			if (event.target.classList.contains("css-qbdosj-Input")) {
				if (
					document.getElementsByClassName("cardsContainer") &&
					document.getElementsByClassName("cardsContainer").length > 0
				) {
					document.getElementsByClassName("cardsContainer")[0].style.zIndex =
						-1;
				}
			} else {
				if (
					document.getElementsByClassName("cardsContainer") &&
					document.getElementsByClassName("cardsContainer").length > 0
				) {
					// document.getElementsByClassName(
					//   "cardsContainer"
					// )[0].style.zIndex = 99999999;
				}
			}
			if (
				ref.current &&
				!ref.current.contains(event.target) &&
				images.length &&
				event.target.id !== "select_all" &&
				event.target.id !== "select_all_label" &&
				event.target.id !== "reassign" &&
				!excludeClass(event.target, "modal-dialog") &&
				!excludeClass(event.target, "modal-overlay--order")
			) {
				// Exclude specific div elements from triggering the action
				const excludedDivs = document.querySelectorAll(".exclude-click");
				let isExcluded = false;

				excludedDivs.forEach((div) => {
					if (div.contains(event.target)) {
						isExcluded = true;
					}
				});
				if (!isExcluded) {
					setSelectedImagesID([]);
					setSelectedAssigntoQCImagesID([]);
					setSelectAll(false);
					setCtrlClicked(false);
					setClickSelect(false);
					localStorage.removeItem("clickSelect");
					localStorage.removeItem("ctrlClicked");
					localStorage.removeItem("selected_all");
					localStorage.setItem("clickSelect", false);
					setShowModal(false);
					setImageDetailsViewId("");
					setImageDetailsView([]);
					setSelectedIndexes([]);
				}
			} else {
				if (
					!excludeClass(event.target, "modal--image") &&
					!excludeClass(event.target, "modal--dialog") &&
					!excludeClass(event.target, "modal-image-icons") &&
					!excludeClass(event.target, "sidebar-image--name") &&
					!excludeClass(event.target, "close") &&
					!excludeClass(event.target, "exclude-click") &&
					!excludeClass(event.target, "btn")
				) {
					setShowModal(false);
					setImageDetailsViewId("");
					setImageDetailsView([]);
					setSelectedIndexes([]);
				}
			}
		}

		const excludeClass = (element, className) => {
			var regex = new RegExp("\\b" + className + "\\b");
			do {
				if (regex.exec(element.className)) {
					return true;
				}
				element = element.parentNode;
			} while (element);
			return false;
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, images, selectedImagesId]);
}
