import React from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import details1 from '../../../assets/Images/details1.svg'
import details2 from '../../../assets/Images/details2.svg'
import details3 from '../../../assets/Images/details3.svg'
import details4 from '../../../assets/Images/details4.svg'
import uploaded from '../../../assets/Images/uploaded.svg'
import Arrowdown from '../../../assets/Images/arrow-down.svg'
import cross from '../../../assets/Images/cross-3.svg'
import cancel from '../../../assets/Images/cancel.svg'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';



const ImageSettingDetails = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const file = [
        { label: "JPEG", value: 355 },
        { label: "PNG", value: 54 }
    ];


    const dpi = [
        { label: "JPEG", value: 355 },
        { label: "PNG", value: 54 }
    ];

    const ColorMode    = [
        { label: "Original", value: 355 },
        { label: "Original", value: 54 }
    ];
    const Align = [
        { label: "Center", value: 355 },
        { label: "Center", value: 54 }
    ];

    const Margin = [
        { label: "Pixel", value: 355 },
        { label: "Pixel", value: 54 }
    ];


    return (
        <>
            <div className="editororderdetails">

                <div className="detailslist">


                    <Card>
                        <Card.Img variant="top" src={details1} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">

                                <div className="btns puple" onClick={handleShow}>
                                    Select Image<span className="ml-3"><img src={Arrowdown} className="img-fluid" alt="mindreach images" /></span>
                                </div>

                                <Modal show={show}
                                    className="imagesetting"
                                    centered
                                    onHide={handleClose}>

                                    <Modal.Body>
                                        <Form>
                                            <div className="sub-head">
                                                Edit Type(s)
                                            </div>

                                            <Form.Group className="" controlId="exampleForm.ControlInput1">

                                                <div className="form-group custom-check flex-grow-1">
                                                    <Form.Group  controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox" label="Background Removal" />
                                                    </Form.Group>
                                                </div>
                                            </Form.Group>

                                            <Form.Group className="" controlId="exampleForm.ControlInput1">

                                                <div className="form-group custom-check flex-grow-1">
                                                    <Form.Group  controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox" label="Retouching" />
                                                    </Form.Group>
                                                </div>
                                            </Form.Group>

                                            <Form.Group className="" controlId="exampleForm.ControlInput1">

                                                <div className="form-group custom-check flex-grow-1">
                                                    <Form.Group  controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox" label="Duplicate" />
                                                    </Form.Group>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="" controlId="exampleForm.ControlInput1">

                                                <div className="form-group custom-check flex-grow-1">
                                                    <Form.Group  controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox" label="Renaming" />
                                                    </Form.Group>
                                                </div>
                                            </Form.Group>

                                            <div className="sub-head">
                                                Output Specifications
                                            </div>

                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlTextarea1"
                                            >

                                                <div className="form-group">
                                                    <Row className="align-items-center">

                                                        <Form.Label className='col-sm-3'>File Format</Form.Label>
                                                        <Col sm={6}>

                                                            <Select options={file}
                                                                defaultValue={{ label: "JPEG", value: 355 }} />
                                                        </Col>

                                                    </Row>
                                                </div>

                                            </Form.Group>

                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <div className="form-group">
                                                    <Row className="align-items-center">

                                                        <Form.Label className='col-sm-3'>Size</Form.Label>
                                                        <Col sm={8}>
                                                            <div className="d-sm-flex align-items-center col-gap-10">
                                                                <div className="d-sm-flex col-gap-10 align-items-center">
                                                                   <span className="text-secondary f-sz-13"> H: </span><Form.Control
                                                                        type="text"
                                                                        placeholder="2000"
                                                                        autoFocus
                                                                    />
                                                                </div>
                                                                <div className="d-sm-flex align-items-center"><img src={cross} className="img-fluid" alt="cross" />
                                                                </div>
                                                                <div className="d-sm-flex align-items-center col-gap-10">
                                                                <span className="text-secondary f-sz-13"> W: </span><Form.Control
                                                                        type="text"
                                                                        placeholder="2000"
                                                                        autoFocus
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>

                                            </Form.Group>

                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <div className="form-group">
                                                    <Row className="align-items-center">

                                                        <Form.Label className='col-sm-3'>DPI</Form.Label>
                                                        <Col sm={8}>
                                                            <div className="d-sm-flex col-gap-20">
                                                                <div className="">
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="2000"
                                                                        autoFocus
                                                                    />
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <Select options={dpi}
                                                                        defaultValue={{ label: "JPEG", value: 355 }} />
                                                                </div>
                                                            </div>

                                                        </Col>

                                                    </Row>
                                                </div>

                                            </Form.Group>

                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <div className="form-group">
                                                    <Row className="align-items-center">

                                                        <Form.Label className='col-sm-3'>Color Mode</Form.Label>
                                                        <Col sm={8}>
                                                        <Select options={ColorMode}
                                                                defaultValue={{ label: "Original", value: 355 }} /></Col>

                                                    </Row>
                                                </div>

                                            </Form.Group>

                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <div className="form-group">
                                                    <Row className="align-items-center">

                                                        <Form.Label className='col-sm-3'>Align</Form.Label>
                                                        <Col sm={8}>
                                                        <Select options={Align}
                                                                defaultValue={{ label: "Center", value: 355 }} /></Col>

                                                    </Row>
                                                </div>

                                            </Form.Group>

                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <div className="form-group">
                                                    <Row className="align-items-center">

                                                        <Form.Label className='col-sm-3'>Margins</Form.Label>
                                                        <Col sm={9}>
                                                            <div className="d-sm-flex col-gap-20">
                                                                <div className="">
                                                                <Select options={Margin}
                                                                defaultValue={{ label: "Pixel", value: 355 }} />
                                                                </div>
                                                                <div className="">
                                                                    <ul className="margin-list">
                                                                        <li>5</li>
                                                                        <li>5</li>
                                                                        <li>5</li>
                                                                        <li>5</li>
                                                                    </ul>
                                                                </div>
                                                            </div>


                                                        </Col>

                                                        <Col sm={6}>

                                                        </Col>

                                                    </Row>
                                                </div>

                                            </Form.Group>







                                        </Form>
                                    </Modal.Body>
                                  
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>



                    <Card>
                        <Card.Img variant="top" src={details4} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                                <div className="bothbtn btns  ">
                                    <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                                </div>
                            </div>
                        </Card.Body>
                    </Card>



                    <Card>
                        <Card.Img variant="top" src={details3} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                                <div className="bothbtn btns  ">
                                    <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                                </div>
                            </div>
                        </Card.Body>
                    </Card>




                    <Card>
                        <Card.Img variant="top" src={details2} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                                <div className="bothbtn btns  ">
                                    <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                                </div>
                            </div>
                        </Card.Body>
                    </Card>



                    <Card>
                        <Card.Img variant="top" src={details4} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                                <div className="bothbtn btns  ">
                                    <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                                </div>
                            </div>
                        </Card.Body>
                    </Card>



                    <Card>
                        <Card.Img variant="top" src={details3} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                                <div className="bothbtn btns  ">
                                    <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={details1} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                                <div className="bothbtn btns  ">
                                    <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                                </div>
                            </div>
                        </Card.Body>
                    </Card>




                    <Card>
                        <Card.Img variant="top" src={details2} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                                <div className="bothbtn btns  ">
                                    <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default ImageSettingDetails