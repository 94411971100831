import React from "react";
import Sidebar from "../../components/global/Sidebar/Sidebar";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import '../../../css/report.css'
import chart from "../../assets/Images/chart.svg";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "react-bootstrap/Pagination";
import { FloatingLabel, FormLabel } from "react-bootstrap";
import Select from "react-select";

const report = () => {
  const selectenterprise = [
    { label: "Enterprise", value: 1 },
    { label: "Demo", value: 2 },
  ];

  const selectcustomers = [
    { label: "All Customers", value: 1 },
    { label: "Demo", value: 2 },
  ];

  const selectyear = [
    { label: "This Year", value: 1 },
    { label: "Demo", value: 2 },
  ];

  const selectrevenue = [
    { label: "Total Revenue", value: 1 },
    { label: "Demo", value: 2 },
  ];

  const invoices = [
    {
      id: "1",
      CustomerName: "Nike",
      Type: "Enterprise",
      ImageCount: "65",
      TotalRevenue: "$5,000",
      AverageRevenuePerImage: "105,918",
      TotalPVSCost: "45,918",
      AveragePVSPerImage: "3,250",
    },
    {
      id: "1",
      CustomerName: "Reebok",
      Type: "Enterprise",
      ImageCount: "65",
      TotalRevenue: "$5,000",
      AverageRevenuePerImage: "$1.25",
      TotalPVSCost: "45,918",
      AveragePVSPerImage: "3,250",
    },
    {
      id: "1",
      CustomerName: "Adidas",
      Type: "Enterprise",
      ImageCount: "65",
      TotalRevenue: "$5,000",
      AverageRevenuePerImage: "$1.25",
      TotalPVSCost: "45,918",
      AveragePVSPerImage: "3,250",
    },
    {
      id: "1",
      CustomerName: "Under Armour",
      Type: "Enterprise",
      ImageCount: "65",
      TotalRevenue: "$5,000",
      AverageRevenuePerImage: "$1.25",
      TotalPVSCost: "45,918",
      AveragePVSPerImage: "3,250",
    },
  ];
  const columns = [
    {
      dataField: "CustomerName",
      text: "Customer Name",
      number: "1",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "Type",
      text: "Type",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "ImageCount",
      text: "Image Count",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "TotalRevenue",
      text: "Total Revenue",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "AverageRevenuePerImage",
      text: "Average Revenue Per Image",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "TotalPVSCost",
      text: "Total PVS Cost",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "AveragePVSPerImage",
      text: "Average PVS Per Image",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  // <BootstrapTable
  //   keyField="id"
  //   data={invoices}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />;

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="rightbar pr-30">
            <div className="section-title">
              <h2>Customers Monetary Comparison</h2>
            </div>
            <div className="customer-search mb-4">
              <Form>
                <Row className="align-items-center">
                  <Col xs={2}>
                    {/* <Form.Label className='label-div'>Enterprise</Form.Label> */}
                    <Select
                      options={selectenterprise}
                      defaultValue={{ label: "Enterprise", value: 1 }}
                    />
                  </Col>
                  <Col xs={2}>
                    {/* <Form.Label className='label-div'>Customers</Form.Label> */}
                    <Select
                      options={selectcustomers}
                      defaultValue={{ label: "All Customers", value: 1 }}
                    />
                  </Col>
                  <Col xs={2}>
                    {/* <Form.Label className='label-div'>This Year</Form.Label> */}
                    <Select
                      options={selectyear}
                      defaultValue={{ label: "This Year", value: 1 }}
                    />
                  </Col>
                  <Col xs={5}>
                    <Form.Control
                      type="search"
                      placeholder="Search customer"
                      className="me-2"
                      aria-label="Search"
                    />
                  </Col>
                </Row>
              </Form>
            </div>

            <div className="customer-chart">
              <div className="mb-2">
                <Form>
                  <Row className="chart-filter">
                    <Col xs={2} className="pr-0">
                      <FormLabel>View</FormLabel>
                    </Col>
                    <Col xs={2}>
                      {/* <Form.Label className='label-div'>Total Revenue</Form.Label> */}
                      <Select
                        options={selectrevenue}
                        defaultValue={{ label: "Total Revenue", value: 1 }}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="chart-graph">
                <div>
                  <Row className="chart-graph-row">
                    <Col xs={11}>
                      <img src={chart} />
                    </Col>
                    <Col xs={1} className="chart-btn text-center">
                      <a href="#">
                        <i className="fa fa-angle-left"></i>
                      </a>
                      <a href="#">
                        <i className="fa fa-angle-right"></i>
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div className="table-responsive table-b">
              <BootstrapTable keyField="id" data={invoices} columns={columns} />
            </div>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 8 }}>
                {
                  <Pagination>
                    <Pagination.Prev />
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Item>{6}</Pagination.Item>
                    <Pagination.Next />
                  </Pagination>
                }
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default report;
