import React, { useRef, useEffect, useState } from "react";

export function useOutsideAlerter(
	ref,
	setMultipleImagesId,
	setFolderNameFirst,
	setFolderListName,
	setFolderSelect,
	setFoldersPath,
	setClickSelect,
	images,
	setReassignedModal,
	setSelectAll,
	setSelectedIndexes,
	setDragAndSelectEnabled
) {
	// const [isExcluded, setIsExcluded] = useState(false);
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target) && images.length) {
				// if (ref.current && !ref.current.contains(event.target)) {
				// Exclude specific div elements from triggering the action
				const excludedDivs = document.querySelectorAll(".exclude-click");
				let isExcluded = false;

				excludedDivs.forEach((div) => {
					if (div.contains(event.target)) {
						// setIsExcluded(true);
						isExcluded = true;
					}
				});

				if (!isExcluded) {
					console.log("Outside clicked");
					setMultipleImagesId([]);
					setFolderNameFirst([]);
					setFolderListName([]);
					setSelectedIndexes([]);
					setFolderSelect([]);
					setFoldersPath([]);
					setClickSelect(false);
					setReassignedModal(false);
					setSelectAll(false);
					setDragAndSelectEnabled(true);
					localStorage.removeItem("clickSelect");
					localStorage.removeItem("ctrlClicked");
					localStorage.removeItem("draggedCalled");
					localStorage.removeItem("select_all");
				} else {
					localStorage.removeItem("click_type");
					setDragAndSelectEnabled(true);
				}
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, images, setMultipleImagesId]);
}
