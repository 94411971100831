import React from "react";
import MainLayout from "../layout/MainLayout";
import QCLayout from "../layout/QcSupervisorLayout";
// Role-to-routes mapping
import Newdashboard from "./../features/Dashboard/NewDashboard/Newdashboard";
import Customers from "./../features/Customers/List/Customers";
import CustomerAdd from "./../features/Customers/List/CustomerAdd";
import CustomerEdit from "./../features/Customers/List/CustomerEdit";

import DashboardTables from "./../features/Dashboard/Dashboard-tables/DashboardTables";
import CustomerDetails from "./../features/Customers/CustomerDetails";
import CustomerChat from "./../features/Customers/CustomerChat";

import Staff from "./../features/Staff";
import StaffDetails from "./../features/Staff/StaffDetails";
import EditOverview from "./../features/Staff/EditOverview";
import Staffnewuser from "./../features/Staff/Staffnewuser";
import EditStaffUser from "./../features/Staff/EditUser";
import MannagerStaffEditUser from "./../features/Staff/DepartmentEditUser";
import StaffDetailsEdit from "./../features/Staff/StaffDetailsEdit";

import CustomersMonetaryComparison from "./../features/Reports/CustomersMonetaryComparison";
import CustomersPerformanceComparison from "./../features/Reports/CustomersPerformanceComparison";
import StaffPerformanceComparison from "./../features/Reports/StaffPerformanceComparison";
import EditTypePerformance from "./../features/Reports/EditTypePerformance";
import InvoiceView from "./../features/Customers/InvoiceView";
import Chats from "./../features/Chats/Chats";
/**************Editor  Components****/

import EditorWorkspace from "./../features/editor-workspace/EditorWorkspace";
import SuperAdminEditorWorkspace from "./../features/Workspace/Editor/EditorWorkspace";
import EditorSupervisorWorkspace from "./../features/editor-supervisor-worspace/Editor/EditorWorkspace";
import EditorSupervisorMyWorkspace from "./../features/editor-supervisor-worspace/editor-workspace/EditorWorkspace";
/***************End of Editor  Components *****/

import CoordinatorWorkspace from "./../features/Workspace/CoordinatorWorkspace";
import ImageCoordinatorSupervisorWorkspace from "./../features/image-coordinator-supervisor/CoordinatorWorkspace";

/**************Coordinating as Image coodinator  Components****/
import ImageCoordinatorWorkspace from "../features/Coorinating-Workspace/CoordinatorWorkspace";
import ImageCoordinatorSupervisorMyWorkspace from "../features/image-coordinator-supervisor/Coorinating-Workspace/CoordinatorWorkspace";
/***************End of Coordinating as Image coodinator Components *****/

import QcWorkspace from "./../features/Workspace/QcWorkspace";
import StaffCoordinatoreditorDashboard from "./../features/StaffCoordinatoreditor/Dashboard/StaffCoordinatoreditorDashboard";
import QcStaffCoordinatorEditorDashboard from "./../features/StaffCoordinatoreditor/Dashboard/QcSuperversiorEditorDashboard";
import QCDashBoard from "./../features/qc-workspace/Dashboard/QCDashboard";
import QC_Workspace from "../features/qc-workspace/QC/QCWorkspace";
import Super_Admin_QC_Workspace from "../features/Workspace/qc-workspace/QC/QCWorkspace";
import QC_Supervisor_Workspace from "../features/qc-supervisor/QC/QCWorkspace";
import Order from "./../features/EnterpriseCustomerLogin/Order/Order";
import Uploadfile from "./../features/EnterpriseCustomerLogin/Order/Uploadfile";
import Ftp from "./../features/EnterpriseCustomerLogin/Order/Ftp";
import AddRole from "./../features/EnterpriseCustomerLogin/Settings/AddRole";
import AddUser from "./../features/EnterpriseCustomerLogin/Settings/AddUser";
import SettingTable from "./../features/EnterpriseCustomerLogin/Settings/Setting-table/Index";
import SuperVisorCustomerList from "./../features/SupervisorCustomerLogin/Customer/SuperVisorCustomerList";
import SuperVisorCoordinatordashboard from "./../features/SupervisorCustomerLogin/dashboard/SuperVisorCoordinatordashboard";
import EnterPriseCustomerInvoice from "./../features/EnterpriseCustomerLogin/Invoices/EnterPriseCustomerInvoice";
import ImageSettings from "./../features/EnterpriseCustomerLogin/Order/ImageSettings";
import ConfirmOrder from "./../features/EnterpriseCustomerLogin/Order/ConfirmOrder";
import OrderReceived from "./../features/EnterpriseCustomerLogin/Order/OrderReceived";
import CreateOrder from "./../features/EnterpriseCustomerLogin/Order/CreateOrder";
import OrderDetails from "./../features/EnterpriseCustomerLogin/Order/OrderDetails";
import SupervisorDashboardCoordinator from "./../features/SupervisorLogin/Dashboard/SupervisorDashboardCoordinator";
import SupervisorDashboardEditor from "./../features/SupervisorLogin/Dashboard/SupervisorDashboardEditor";
import SupervisorDashboardQc from "./../features/SupervisorLogin/Dashboard/SupervisorDashboardQc";
import SupervisorReportsOrderHistoryReport from "./../features/SupervisorLogin/Report/SupervisorReportsOrderHistoryReport";
import SupervisorReportStaffComparisonReport from "./../features/SupervisorLogin/Report/SupervisorReportStaffComparisonReport";
import SupervisorCustomerListCo from "./../features/SupervisorLogin/Customer/SupervisorCustomerListCo";
import CoordinatorSupervisorOrderHistory from "./../features/SupervisorLogin/Customer/CoordinatorSupervisorOrderHistory";
import EditorSupervisorOrderHistory from "./../features/SupervisorLogin/Customer/Editor/EditorSupervisorOrderHistory";
import QcSupervisorOrderHistory from "./../features/SupervisorLogin/Customer/QC/QcSupervisorOrderHistory";
import SupervisorStaff from "./../features/SupervisorLogin/Staff/SupervisorStaff";
import SupervisorStaffnewuser from "./../features/SupervisorLogin/Staff/SupervisorStaffnewuser";
import SupervisorStaffDetails from "./../features/SupervisorLogin/Staff/SupervisorStaffDetails";
import SupervisorCoordinatorWorkspace from "./../features/SupervisorLogin/Workspace/SupervisorCoordinatorWorkspace";
import SupervisorEditorWorkspace from "./../features/SupervisorLogin/Workspace/SupervisorEditorWorkspace";
import SupervisorCoordinatorMyWorkspace from "./../features/SupervisorLogin/Workspace/SupervisorCoordinatorMyWorkspace";
import SupervisorEditorMyWorkspace from "./../features/SupervisorLogin/Workspace/SupervisorEditorMyWorkspace";
import SupervisorQcWorkspace from "./../features/SupervisorLogin/Workspace/SupervisorQcWorkspace";
import SupervisorQcMyWorkspace from "./../features/SupervisorLogin/Workspace/SupervisorQcMyWorkspace";
import SupervisorQcMyCanvas from "./../features/SupervisorLogin/Workspace/SupervisorQcMyCanvas";
import SupervisorQcCanvas from "./../features/SupervisorLogin/Workspace/SupervisorQcCanvas";
import SupervisorChat from "./../features/SupervisorLogin/Chat/SupervisorChat";
import SupervisorStaffEditOverview from "./../features/SupervisorLogin/Staff/SupervisorStaffEditOverview";
import AddBranch from "./../features/Settings/AddBranch";
import ViewBranch from "./../features/Settings/ViewBranch";
import EditBranch from "./../features/Settings/EditBranch";
import AddWorkShift from "./../features/Settings/AddWorkShift";
import WorkShiftList from "./../features/Settings/WorkShiftList";
import EditWorkShift from "./../features/Settings/EditWorkShift";
import HolidaysLists from "./../features/Settings/Holiday/ListHoliday";
import AddHoliday from "./../features/Settings/Holiday/AddHoliday";
import EditHoliday from "./../features/Settings/Holiday/EditHoliday";

import EditTypeLists from "./../features/Settings/EditType/ListEditType";
import AddEditType from "./../features/Settings/EditType/AddEditType";
import EditEditType from "./../features/Settings/EditType/EditEditType";

import ViewEnterpriseFields from "./../features/Settings/EnterpriseFields/ViewFields";
import AddEnterpriseFields from "./../features/Settings/EnterpriseFields/AddField";
import EditEnterpriseFields from "./../features/Settings/EnterpriseFields/EditField";
import PreviewEnterpriseFields from "./../features/Settings/EnterpriseFields/PreviewFields";

import EnterpriseRequests from "./../features/Settings/EnterpriseRequests/";
import EnterpriseRequestDetails from "./../features/Settings/EnterpriseRequests/Details";
import Dashboard from "./../features/Dashboard/Dashboard-tables/DashboardTables";
import Unauthorized from "./../features/unauthorised";
import Login from "../features/authentication/Login";

/**************QC Supervisoe Workspace Components****/
import QcWorkspaceMYWorkspace from "./../features/qc-supervisor/qc-workspace/QC/QCWorkspace";
/**************End of  QC Supervisoe Workspace  Components****/
// Importing routes files
import {
	HOIDAY_LIST_PAGE,
	WORK_SHIFTS_LISTS_PAGE,
	WORK_SHIFT_EDIT_PAGE,
	WORK_SHIFT_ADD_PAGE,
	VIEW_BRANCHE_PAGE,
	EDIT_BRANCHE_PAGE,
	ADD_BRANCHE_PAGE,
	ADD_HOLIDAY_PAGE,
	EDIT_HOLIDAY_PAGE,
	STAFF_PAGE,
	STAFF_VIEW_PAGE,
	STAFF_ADD_PAGE,
	EDIT_STAFF_USER_PAGE,
	VIEW_CUSTOMER_PAGE,
	CUSTOMER_ADD_PAGE,
	EDIT_CUSTOMER_PAGE,
	CUSTOMERS_DETAILS,
	STAFF_DETAILS_PAGE,
	EDIT_TYPES_LISTS_PAGE,
	EDIT_TYPE_EDIT_PAGE,
	EDIT_TYPE_ADD_PAGE,
	VIEW_ENTERPRISE_FIELD,
	ADD_ENTERPRISE_FIELD,
	EDIT_ENTERPRISE_FIELD,
	PREVIEW_ENTERPRISE_FIELD,
	VIEW_ENTERPRISE_REQUESTS,
	VIEW_ENTERPRISE_REQUEST_DETAILS,
	VIEW_COORDINATOR_WORKSPACE,
	VIEW_EDITOR_WORKSPACE,
	VIEW_IMAGE_COORDINATOR_WORKSPACE,
	VIEW_QC_WORKSPACE,
	LOGIN,
	QC_SUPERVISOR_WORKSPACE,
	DASHBOARD,
	MY_WORKSPACE,
} from "./../config/routes";
export const roleRoutes = {
	"super-admin": [
		{
			path: DASHBOARD,
			component: Dashboard,
		},
		{
			path: VIEW_COORDINATOR_WORKSPACE,
			component: CoordinatorWorkspace,
		},
		{
			path: VIEW_EDITOR_WORKSPACE,
			component: SuperAdminEditorWorkspace,
		},
		{
			path: HOIDAY_LIST_PAGE,
			component: HolidaysLists,
		},
		{
			path: ADD_HOLIDAY_PAGE,
			component: AddHoliday,
		},
		{
			path: `${EDIT_HOLIDAY_PAGE}/:id`,
			component: EditHoliday,
		},
		{
			path: WORK_SHIFT_ADD_PAGE,
			component: AddWorkShift,
		},
		{
			path: `${WORK_SHIFT_EDIT_PAGE}/:id`,
			component: EditWorkShift,
		},
		{
			path: WORK_SHIFTS_LISTS_PAGE,
			component: WorkShiftList,
		},
		{
			path: ADD_BRANCHE_PAGE,
			component: AddBranch,
		},
		{
			path: `${EDIT_BRANCHE_PAGE}/:id`,
			component: EditBranch,
		},
		{
			path: VIEW_BRANCHE_PAGE,
			component: ViewBranch,
		},
		{
			path: VIEW_CUSTOMER_PAGE,
			component: Customers,
		},
		{
			path: CUSTOMER_ADD_PAGE,
			component: CustomerAdd,
		},
		{
			path: `${EDIT_CUSTOMER_PAGE}/:id`,
			component: CustomerEdit,
		},
		{
			path: `${CUSTOMERS_DETAILS}/:id`,
			component: CustomerDetails,
		},
		{
			path: STAFF_ADD_PAGE,
			component: Staffnewuser,
		},
		{
			path: STAFF_PAGE,
			component: Staff,
		},
		{
			path: `${EDIT_STAFF_USER_PAGE}/:id`,
			component: EditStaffUser,
		},
		{
			path: STAFF_VIEW_PAGE,
			component: StaffDetails,
		},
		{
			path: `${STAFF_DETAILS_PAGE}/:id`,
			component: StaffDetailsEdit,
		},
		{
			path: VIEW_ENTERPRISE_FIELD,
			component: ViewEnterpriseFields,
		},
		{
			path: ADD_ENTERPRISE_FIELD,
			component: AddEnterpriseFields,
		},
		{
			path: `${EDIT_ENTERPRISE_FIELD}/:id`,
			component: EditEnterpriseFields,
		},
		{
			path: `${PREVIEW_ENTERPRISE_FIELD}`,
			component: PreviewEnterpriseFields,
		},
		{
			path: VIEW_ENTERPRISE_REQUESTS,
			component: EnterpriseRequests,
		},
		{
			path: `${VIEW_ENTERPRISE_REQUEST_DETAILS}/:id`,
			component: EnterpriseRequestDetails,
		},
		{
			path: EDIT_TYPE_ADD_PAGE,
			component: AddEditType,
		},
		{
			path: `${EDIT_TYPE_EDIT_PAGE}/:id`,
			component: EditEditType,
		},
		{
			path: EDIT_TYPES_LISTS_PAGE,
			component: EditTypeLists,
		},
		{
			path: VIEW_QC_WORKSPACE,
			component: Super_Admin_QC_Workspace,
		},
		{
			path: LOGIN,
			component: Login,
		},

		// Add more routes for the super-admin role
	],
	"department-manager": [
		{
			path: DASHBOARD,
			component: Dashboard,
		},
		{
			path: VIEW_COORDINATOR_WORKSPACE,
			component: CoordinatorWorkspace,
		},
		{
			path: VIEW_EDITOR_WORKSPACE,
			component: EditorWorkspace,
		},
		{
			path: HOIDAY_LIST_PAGE,
			component: HolidaysLists,
		},
		{
			path: ADD_HOLIDAY_PAGE,
			component: AddHoliday,
		},
		{
			path: `${EDIT_HOLIDAY_PAGE}/:id`,
			component: EditHoliday,
		},
		{
			path: WORK_SHIFT_ADD_PAGE,
			component: AddWorkShift,
		},
		{
			path: `${WORK_SHIFT_EDIT_PAGE}/:id`,
			component: EditWorkShift,
		},
		{
			path: WORK_SHIFTS_LISTS_PAGE,
			component: WorkShiftList,
		},
		{
			path: ADD_BRANCHE_PAGE,
			component: AddBranch,
		},
		{
			path: `${EDIT_BRANCHE_PAGE}/:id`,
			component: EditBranch,
		},
		{
			path: VIEW_BRANCHE_PAGE,
			component: ViewBranch,
		},
		{
			path: VIEW_CUSTOMER_PAGE,
			component: Customers,
		},
		{
			path: CUSTOMER_ADD_PAGE,
			component: CustomerAdd,
		},
		{
			path: `${EDIT_CUSTOMER_PAGE}/:id`,
			component: CustomerEdit,
		},
		{
			path: `${CUSTOMERS_DETAILS}/:id`,
			component: CustomerDetails,
		},
		{
			path: STAFF_ADD_PAGE,
			component: Staffnewuser,
		},
		{
			path: STAFF_PAGE,
			component: Staff,
		},
		{
			path: `${EDIT_STAFF_USER_PAGE}/:id`,
			component: MannagerStaffEditUser,
		},
		{
			path: STAFF_VIEW_PAGE,
			component: StaffDetails,
		},
		{
			path: `${STAFF_DETAILS_PAGE}/:id`,
			component: StaffDetailsEdit,
		},
		{
			path: VIEW_ENTERPRISE_FIELD,
			component: ViewEnterpriseFields,
		},
		{
			path: ADD_ENTERPRISE_FIELD,
			component: AddEnterpriseFields,
		},
		{
			path: `${EDIT_ENTERPRISE_FIELD}/:id`,
			component: EditEnterpriseFields,
		},
		{
			path: `${PREVIEW_ENTERPRISE_FIELD}`,
			component: PreviewEnterpriseFields,
		},
		{
			path: VIEW_ENTERPRISE_REQUESTS,
			component: EnterpriseRequests,
		},
		{
			path: `${VIEW_ENTERPRISE_REQUEST_DETAILS}/:id`,
			component: EnterpriseRequestDetails,
		},
		{
			path: EDIT_TYPE_ADD_PAGE,
			component: AddEditType,
		},
		{
			path: `${EDIT_TYPE_EDIT_PAGE}/:id`,
			component: EditEditType,
		},
		{
			path: EDIT_TYPES_LISTS_PAGE,
			component: EditTypeLists,
		},
		{
			path: VIEW_QC_WORKSPACE,
			component: Super_Admin_QC_Workspace,
		},
		{
			path: LOGIN,
			component: Login,
		},

		// Add more routes for the super-admin role
	],
	"qc-supervisor": [
		{
			path: DASHBOARD,
			component: StaffCoordinatoreditorDashboard,
			role: "qc-supervisor",
		},
		{
			path: "/unauthorized",
			component: Unauthorized,
			role: "qc-supervisor",
		},
		{
			path: MY_WORKSPACE,
			component: QcWorkspaceMYWorkspace,
			role: "qc-supervisor",
		},
		{
			path: LOGIN,
			component: Login,
			role: "qc-supervisor",
		},
		{
			path: QC_SUPERVISOR_WORKSPACE,
			component: QC_Supervisor_Workspace,
			role: "qc-supervisor",
		},
		// Add more routes for the qc-supervisor role
	],
	editor: [
		{
			path: DASHBOARD,
			component: SupervisorDashboardCoordinator,
			role: "editor",
		},
		{
			path: "/editor/workspace",
			component: EditorWorkspace,
			role: "editor",
		},
		{
			path: "/unauthorized",
			component: Unauthorized,
			role: "editor",
		},
		{
			path: LOGIN,
			component: Login,
			role: "editor",
		},
	],
	"editor-supervisor": [
		{
			path: DASHBOARD,
			component: SupervisorDashboardCoordinator,
			role: "editor-supervisor",
		},
		{
			path: MY_WORKSPACE,
			component: EditorSupervisorMyWorkspace,
			role: "editor-supervisor",
		},
		{
			path: VIEW_EDITOR_WORKSPACE,
			component: EditorSupervisorWorkspace,
			role: "editor-supervisor",
		},
		{
			path: "/unauthorized",
			component: Unauthorized,
			role: "editor-supervisor",
		},
		{
			path: LOGIN,
			component: Login,
			role: "editor-supervisor",
		},
	],
	qc: [
		{
			path: DASHBOARD,
			component: QCDashBoard,
			role: "qc",
		},
		{
			path: "/unauthorized",
			component: Unauthorized,
			role: "qc",
		},
		{
			path: "/qc-worspace",
			component: QC_Workspace,
			role: "qc",
		},
		{
			path: LOGIN,
			component: Login,
			role: "qc",
		},
		// Add more routes for the qc-supervisor role
	],
	"image-coordinator": [
		{
			path: DASHBOARD,
			component: QcStaffCoordinatorEditorDashboard,
			role: "image-coordinator",
		},
		{
			path: "/unauthorized",
			component: Unauthorized,
			role: "image-coordinator",
		},
		{
			path: `${VIEW_IMAGE_COORDINATOR_WORKSPACE}/:id/:batch_id_query/:folder_id_query`,
			component: ImageCoordinatorWorkspace,
			role: "image-coordinator",
		},
		{
			path: `${VIEW_IMAGE_COORDINATOR_WORKSPACE}/:id`,
			component: ImageCoordinatorWorkspace,
			role: "image-coordinator",
		},
		{
			path: `${VIEW_IMAGE_COORDINATOR_WORKSPACE}/:id/:batch_id_query`,
			component: ImageCoordinatorWorkspace,
			role: "image-coordinator",
		},
		{
			path: `${VIEW_IMAGE_COORDINATOR_WORKSPACE}`,
			component: ImageCoordinatorWorkspace,
			role: "image-coordinator",
		},
		{
			path: LOGIN,
			component: Login,
			role: "image-coordinator",
		},
		// Add more routes for the qc-supervisor role
	],
	"image-coordinator-supervisor": [
		{
			path: DASHBOARD,
			component: QcStaffCoordinatorEditorDashboard,
			role: "image-coordinator-supervisor",
		},
		{
			path: "/unauthorized",
			component: Unauthorized,
			role: "image-coordinator-supervisor",
		},
		{
			path: "/workspace",
			component: ImageCoordinatorSupervisorWorkspace,
			role: "image-coordinator-supervisor",
		},
		{
			path: MY_WORKSPACE,
			component: ImageCoordinatorSupervisorMyWorkspace,
			role: "image-coordinator-supervisor",
		},
		{
			path: LOGIN,
			component: Login,
			role: "image-coordinator-supervisor",
		},
		// Add more routes for the role
	],
};
