import React, { useState } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";

import { apiURL } from "../../../../../../data/filepath";
import { client } from "../../../../../../data/API/GobalClientAxiosRequest";
import { ADD_CUSTOMER_FTP } from "../../../../../../config/apiRoutes.api";

/**
 * Reusable modal component.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.heading - The title or heading of the modal.
 * @param {string} props.message - The content or message of the modal.
 * @returns {JSX.Element} - The rendered modal component.
 */
const FtpModal = (props) => {
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	//Declarations of state variable
	const [host, setHost] = useState("");
	const [port, setPort] = useState("");
	const [userName, setUserName] = useState(0);
	const [password, setPassword] = useState("");
	const [status, setStatus] = useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		if (token) {
			const request = client(token);

			bodyFormData.append("host", host);
			bodyFormData.append("port", port);
			bodyFormData.append("user_name", userName);
			bodyFormData.append("password", password);
			request
				.post(apiURL + ADD_CUSTOMER_FTP + `/${props.id}`, bodyFormData)
				.then((response) => {
					console.log("add", response);
					props.setStatus("Success");
					props.setFtpAddModal(false);
					props.setSubmitStatusMessage("FTP added successfully");
					props.setModalShowEdit(true);

					props.handleFTPApiFetchOnSubmit();
				})
				.catch((err) => {
					setStatus("Error");
					props.setSubmitStatusMessage(err);
				});
		}
	};
	return (
		<Modal
			{...props}
			// backdrop="static"
			// keyboard={false}

			size="lg"
			// aria-labelledby="contained-modal-title-vcenter"
			aria-labelledby="example-modal-sizes-title-lg"
			contentClassName="modal-content-edit--type "
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title
					id="example-modal-sizes-title-lg"
					className="section-title fonts"
					style={{ marginLeft: "10px" }}
				>
					{props.heading}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className="section-title fonts"
				style={{ marginLeft: "10px" }}
			>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Host</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="text"
								placeholder="12.54.98.1"
								autoFocus
								autoComplete="off"
								onChange={(e) => setHost(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Port</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="text"
								placeholder="26"
								autoComplete="off"
								onChange={(e) => setPort(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">User Name</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="text"
								placeholder="Username1"
								autoComplete="off"
								onChange={(e) => setUserName(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Password</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="password"
								placeholder="**********"
								autoComplete="off"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="form-group">
					<div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
						<div className="">
							<Button
								variant=""
								className="puple btns mr-3"
								type="submit"
								onClick={props.onHide}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								className="btns"
								type="submit"
								onClick={(e) => handleSubmit(e)}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default FtpModal;
