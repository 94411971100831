import React from "react";
import TimePicker from 'react-time-picker'; // import the time picker js from the library
import 'react-time-picker/dist/TimePicker.css';  // import the time CSS picker from the library
import 'react-clock/dist/Clock.css'; // import the time CSS picker from the library

/**
 * Component for generating an Time Picker.
 * @param {Date Time} format - The time format in which we wants to show the time picker.
 * @param {String} className - className which we need to append to the input field.
 * @param {String} inputName - inputName which we need to show to the input field.
 * @param {String} id - id which we need to add to the input field.
 * @param {Function} onChange - on change function which we need to call after changing value from the time picker.
 * @param {String} value - value which we need to show to the input field.
 * @returns {JSX.Element} - The rendered Time Picker component.
 */
const TimePickr = ({ format="hh:mm a", className="form-control" , inputName = "", id ="" ,  onChange , value= "" , openClockOnFocus=true}) => {
	return (
		<TimePicker 
			onChange={(val)=>{
				onChange(inputName,id, val)
			}} 
			value={value}
			format={format}
			className={className}
			name={inputName}
			clockIcon={false}
			openClockOnFocus={openClockOnFocus}
		/>
	);
};

export default TimePickr;
