import React from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import details1 from '../../../assets/Images/details1.svg'
import details2 from '../../../assets/Images/details2.svg'
import details3 from '../../../assets/Images/details3.svg'
import details4 from '../../../assets/Images/details4.svg'
import uploaded from '../../../assets/Images/uploaded.svg'
import Redcheck from '../../../assets/Images/red-check.svg'
import Arrowdown from '../../../assets/Images/arrow-down.svg'
import psd from '../../../assets/Images/psd.svg'
import cancel from '../../../assets/Images/cancel.svg'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';



const OrderDetailscontent = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



  return (
    <>
      <div className="editororderdetails">

<div className="detailslist">


    <Card>
        <Card.Img variant="top" src={details1} />
        <div className="checkbox"></div>
        <Card.Body>
            <Card.Text>
                983794768.jpg
            </Card.Text>
            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                            <div className="bothbtn">
                                <span><img src={psd} className="img-fluid" alt="mindreach images" /></span>PSD
                            </div>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Approved
                            </div>
                        </div>
        </Card.Body>
    </Card>



    <Card>
        <Card.Img variant="top" src={details4} />
        <div className="checkbox"></div>
        <Card.Body>
            <Card.Text>
                983794768.jpg
            </Card.Text>
            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                            <div className="bothbtn">
                                <span><img src={psd} className="img-fluid" alt="mindreach images" /></span>PSD
                            </div>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Approved
                            </div>
                        </div>
        </Card.Body>
    </Card>



    <Card>
        <Card.Img variant="top" src={details3} />
        <div className="checkbox"></div>
        <Card.Body>
            <Card.Text>
                983794768.jpg
            </Card.Text>
            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                            <div className="bothbtn">
                                <span><img src={psd} className="img-fluid" alt="mindreach images" /></span>PSD
                            </div>
                            <div className="bothbtn">
                                <span><img src={Redcheck} className="img-fluid" 
                                alt="mindreach images" /></span>Revision req
                            </div>
                        </div>
        </Card.Body>
    </Card>




    <Card>
        <Card.Img variant="top" src={details2} />
        <div className="checkbox"></div>
        <Card.Body>
            <Card.Text>
                983794768.jpg
            </Card.Text>
            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                            <div className="bothbtn">
                                <span><img src={psd} className="img-fluid" alt="mindreach images" /></span>PSD
                            </div>
                            <div className="bothbtn">
                               Review Ready
                            </div>
                        </div>
        </Card.Body>
    </Card>



    <Card>
        <Card.Img variant="top" src={details4} />
        <div className="checkbox"></div>
        <Card.Body>
            <Card.Text>
                983794768.jpg
            </Card.Text>
            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                            <div className="bothbtn">
                                <span><img src={psd} className="img-fluid" alt="mindreach images" /></span>PSD
                            </div>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Approved
                            </div>
                        </div>
        </Card.Body>
    </Card>



    <Card>
        <Card.Img variant="top" src={details3} />
        <div className="checkbox"></div>
        <Card.Body>
            <Card.Text>
                983794768.jpg
            </Card.Text>
            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                            <div className="bothbtn">
                                <span><img src={psd} className="img-fluid" alt="mindreach images" /></span>PSD
                            </div>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Approved
                            </div>
                        </div>
        </Card.Body>
    </Card>
    <Card>
        <Card.Img variant="top" src={details1} />
        <div className="checkbox"></div>
        <Card.Body>
            <Card.Text>
                983794768.jpg
            </Card.Text>
            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                <div className="bothbtn btns  ">
                    <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                </div>
            </div>
        </Card.Body>
    </Card>




    <Card>
        <Card.Img variant="top" src={details2} />
        <div className="checkbox"></div>
        <Card.Body>
            <Card.Text>
                983794768.jpg
            </Card.Text>
            <div className="d-sm-flex flex-sm-wrap align-items-center justify-content-center btn-gap">
                            <div className="bothbtn">
                                <span><img src={psd} className="img-fluid" alt="mindreach images" /></span>PSD
                            </div>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Approved
                            </div>
                        </div>
        </Card.Body>
    </Card>
</div>
</div>
    </>
  )
}

export default OrderDetailscontent