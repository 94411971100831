import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// Importing Layout components
import MainLayout from "./layout/MainLayout";
import QCSupervisorLayout from "./layout/QcSupervisorLayout";
import EditorLayout from "./layout/EditorLayout";
import QcStaffLayout from "./layout/QcstaffLayout";
import ImageCoordinator from "./layout/ImageCordinatorLayout";
import ImageCordinatorLayout from "./layout/ImageCoordinatorSupervisorLayout";
import EditorSupervisorLayout from "./layout/EditorSupervisorLayout";

//Importing for Mobile Screen
import MobileLayout from "./layout/MobileScreen/index";
// Importing components and features
import MainNav from "./components/global/Header/MainNav";

import Login from "./features/authentication/Login";
import Signup from "./features/authentication/Signup";

import Newdashboard from "./features/Dashboard/NewDashboard/Newdashboard";
import DashboardTables from "./features/Dashboard/Dashboard-tables/DashboardTables";

import CustomerChat from "./features/Customers/CustomerChat";

import EditOverview from "./features/Staff/EditOverview";
import Unauthorized from "./features/unauthorised";

import CustomersMonetaryComparison from "./features/Reports/CustomersMonetaryComparison";
import CustomersPerformanceComparison from "./features/Reports/CustomersPerformanceComparison";
import StaffPerformanceComparison from "./features/Reports/StaffPerformanceComparison";
import EditTypePerformance from "./features/Reports/EditTypePerformance";
import InvoiceView from "./features/Customers/InvoiceView";
import Chats from "./features/Chats/Chats";
// import CoordinatorWorkspace from "./features/Workspace/CoordinatorWorkspace";
// import EditorWorkspace from "./features/Workspace/EditorWorkspace";
import QcWorkspace from "./features/Workspace/QcWorkspace";
import StaffCoordinatoreditorDashboard from "./features/StaffCoordinatoreditor/Dashboard/StaffCoordinatoreditorDashboard";
import Order from "./features/EnterpriseCustomerLogin/Order/Order";
import Uploadfile from "./features/EnterpriseCustomerLogin/Order/Uploadfile";
import Ftp from "./features/EnterpriseCustomerLogin/Order/Ftp";
import AddRole from "./features/EnterpriseCustomerLogin/Settings/AddRole";
import AddUser from "./features/EnterpriseCustomerLogin/Settings/AddUser";
import SettingTable from "./features/EnterpriseCustomerLogin/Settings/Setting-table/Index";
import SuperVisorCustomerList from "./features/SupervisorCustomerLogin/Customer/SuperVisorCustomerList";
import SuperVisorCoordinatordashboard from "./features/SupervisorCustomerLogin/dashboard/SuperVisorCoordinatordashboard";
import EnterPriseCustomerInvoice from "./features/EnterpriseCustomerLogin/Invoices/EnterPriseCustomerInvoice";
import ImageSettings from "./features/EnterpriseCustomerLogin/Order/ImageSettings";
import ConfirmOrder from "./features/EnterpriseCustomerLogin/Order/ConfirmOrder";
import OrderReceived from "./features/EnterpriseCustomerLogin/Order/OrderReceived";
import CreateOrder from "./features/EnterpriseCustomerLogin/Order/CreateOrder";
import OrderDetails from "./features/EnterpriseCustomerLogin/Order/OrderDetails";
import SupervisorDashboardCoordinator from "./features/SupervisorLogin/Dashboard/SupervisorDashboardCoordinator";
import SupervisorDashboardEditor from "./features/SupervisorLogin/Dashboard/SupervisorDashboardEditor";
import SupervisorDashboardQc from "./features/SupervisorLogin/Dashboard/SupervisorDashboardQc";
import SupervisorReportsOrderHistoryReport from "./features/SupervisorLogin/Report/SupervisorReportsOrderHistoryReport";
import SupervisorReportStaffComparisonReport from "./features/SupervisorLogin/Report/SupervisorReportStaffComparisonReport";
import SupervisorCustomerListCo from "./features/SupervisorLogin/Customer/SupervisorCustomerListCo";
import CoordinatorSupervisorOrderHistory from "./features/SupervisorLogin/Customer/CoordinatorSupervisorOrderHistory";
import EditorSupervisorOrderHistory from "./features/SupervisorLogin/Customer/Editor/EditorSupervisorOrderHistory";
import QcSupervisorOrderHistory from "./features/SupervisorLogin/Customer/QC/QcSupervisorOrderHistory";
import SupervisorStaff from "./features/SupervisorLogin/Staff/SupervisorStaff";
import SupervisorStaffnewuser from "./features/SupervisorLogin/Staff/SupervisorStaffnewuser";
import SupervisorStaffDetails from "./features/SupervisorLogin/Staff/SupervisorStaffDetails";
import SupervisorCoordinatorWorkspace from "./features/SupervisorLogin/Workspace/SupervisorCoordinatorWorkspace";
import SupervisorEditorWorkspace from "./features/SupervisorLogin/Workspace/SupervisorEditorWorkspace";
import SupervisorCoordinatorMyWorkspace from "./features/SupervisorLogin/Workspace/SupervisorCoordinatorMyWorkspace";
import SupervisorEditorMyWorkspace from "./features/SupervisorLogin/Workspace/SupervisorEditorMyWorkspace";
import SupervisorQcWorkspace from "./features/SupervisorLogin/Workspace/SupervisorQcWorkspace";
import SupervisorQcMyWorkspace from "./features/SupervisorLogin/Workspace/SupervisorQcMyWorkspace";
import SupervisorQcMyCanvas from "./features/SupervisorLogin/Workspace/SupervisorQcMyCanvas";
import SupervisorQcCanvas from "./features/SupervisorLogin/Workspace/SupervisorQcCanvas";
import SupervisorChat from "./features/SupervisorLogin/Chat/SupervisorChat";
import SupervisorStaffEditOverview from "./features/SupervisorLogin/Staff/SupervisorStaffEditOverview";

import { roleRoutes } from "./config/routeshandle";
//Import Redux file
import {
	reducer,
	initialState,
	AuthContext,
} from "./context/Login/Auth.context";
function App() {
	const [state, dispatch] = React.useReducer(reducer, initialState);

	const isLoggedIn = !state.access_token;
	const token = sessionStorage.getItem("token");
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const userAgent = navigator.userAgent;
		setIsMobile(
			/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				userAgent
			)
		);
	}, []);
	// Role checking logic...

	const roleJson = sessionStorage.getItem("role");
	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserRoles = [role !== null ? role.role : "login"];
	const roleBasedLayout = (route) => {
		if (isMobile) {
			return (
				<MobileLayout>
					<route.component />
				</MobileLayout>
			);
		} else {
			if (route.role === "qc-supervisor") {
				return (
					<QCSupervisorLayout>
						<route.component />
					</QCSupervisorLayout>
				);
			} else if (route.role === "editor") {
				return (
					<EditorLayout>
						<route.component />
					</EditorLayout>
				);
			} else if (route.role === "qc") {
				return (
					<QcStaffLayout>
						<route.component />
					</QcStaffLayout>
				);
			} else if (route.role === "image-coordinator") {
				return (
					<ImageCoordinator>
						<route.component />
					</ImageCoordinator>
				);
			} else if (route.role === "image-coordinator-supervisor") {
				return (
					<ImageCordinatorLayout>
						<route.component />
					</ImageCordinatorLayout>
				);
			} else if (route.role === "editor-supervisor") {
				return (
					<EditorSupervisorLayout>
						<route.component />
					</EditorSupervisorLayout>
				);
			} else {
				return (
					<MainLayout>
						<route.component />
					</MainLayout>
				);
			}
		}
	};
	return (
		<>
			<BrowserRouter>
				<AuthContext.Provider
					value={{
						state,
						dispatch,
					}}
				>
					<MainNav token={!state.access_token} tokenAccess={token} />
					<Routes>
						{isLoggedIn && !token && !isMobile && (
							<Route
								path="*"
								exact={true}
								element={<Navigate to="/login" replace />}
							/>
						)}
						{!state.isAuthenticated &&
							!state.access_token &&
							!token &&
							isMobile && <Route path="/login" element={<MobileLayout />} />}
						{!state.isAuthenticated &&
						!state.access_token &&
						!token &&
						!isMobile ? (
							<>
								<Route path="/" element={<Navigate to="/login" replace />} />

								<Route path="/signup" element={<Signup />} />
								<Route path="/login" element={<Login />} />
							</>
						) : (
							<>
								{/* Private routes based on roles */}

								{Object.entries(roleRoutes).map(
									([role, routes]) =>
										currentUserRoles.includes(role) &&
										routes.map((route) => (
											<Route
												key={route.path}
												path={route.path}
												element={roleBasedLayout(route)}
											/>
										))
								)}
								{/* Fallback for unauthorized access */}
								{Object.entries(roleRoutes).map(
									([role, routes]) =>
										currentUserRoles.includes(role) &&
										routes.map((route) => (
											<>
												<Route
													key={route.path}
													path="/unauthorized"
													element={roleBasedLayout(route)}
												/>
												<Route
													path="/unauthorized"
													element={<Unauthorized />}
												/>
												<Route
													path="*"
													element={<Navigate to="/unauthorized" />}
												/>
											</>
										))
								)}

								{/* <Route path="/dashboard" element={<DashboardTables />} /> */}
								<Route path="/dashboard-two" element={<Newdashboard />} />

								<Route path="/customers-chat" element={<CustomerChat />} />
								<Route path="/edit-overview" element={<EditOverview />} />

								<Route
									path="/customers-monetary-comparison"
									element={<CustomersMonetaryComparison />}
								/>
								<Route
									path="/customers-performance-comparison"
									element={<CustomersPerformanceComparison />}
								/>
								<Route
									path="/staff-performance-comparison"
									element={<StaffPerformanceComparison />}
								/>
								<Route
									path="/edit-type-performance"
									element={<EditTypePerformance />}
								/>
								<Route path="/view-invoice" element={<InvoiceView />} />
								<Route path="/chats" element={<Chats />} />
								{/* <Route
									path="/coordinator-workspace"
									element={<CoordinatorWorkspace />}
								/>
								<Route path="/editor-workspace" element={<EditorWorkspace />} /> */}
								{/* <Route path="/qc-workspace" element={<QcWorkspace />} /> */}
								{/* <Route
                  path="/staff-coordinator-dashboard"
                  element={<StaffCoordinatoreditorDashboard />}
                />
                <Route path="/enterprise-customer-order" element={<Order />} />
                <Route
                  path="/enterprise-customer-create-order"
                  element={<CreateOrder />}
                />
                <Route
                  path="/enterprise-upload-file"
                  element={<Uploadfile />}
                />
                <Route path="/enterprise-ftp" element={<Ftp />} />
                <Route path="/enterprise-add-role" element={<AddRole />} />
                <Route path="/enterprise-add-user" element={<AddUser />} />
                <Route path="/enterprise-setting" element={<SettingTable />} />
                <Route
                  path="/enterprise-customer-invoice"
                  element={<EnterPriseCustomerInvoice />}
                />
                <Route
                  path="/enterprise-customer-order-image-setting"
                  element={<ImageSettings />}
                />
                <Route
                  path="/enterprise-customer-order-confirm"
                  element={<ConfirmOrder />}
                />
                <Route
                  path="/enterprise-customer-order-received"
                  element={<OrderReceived />}
                />
                <Route
                  path="/supervisor-customer-list"
                  element={<SuperVisorCustomerList />}
                />
                <Route
                  path="/supervisor-customer-dashboard"
                  element={<SuperVisorCoordinatordashboard />}
                />
                <Route
                  path="/enterprise-customer-order-details"
                  element={<OrderDetails />}
                />
                <Route
                  path="/Supervisor-dashboard-coordinator"
                  element={<SupervisorDashboardCoordinator />}
                />
                <Route
                  path="/Supervisor-dashboard-editor"
                  element={<SupervisorDashboardEditor />}
                />
                <Route
                  path="/Supervisor-dashboard-qc"
                  element={<SupervisorDashboardQc />}
                />
                <Route
                  path="/supervisor-reports-order-history-report"
                  element={<SupervisorReportsOrderHistoryReport />}
                />
                <Route
                  path="/supervisor-report-staff-comparison-report"
                  element={<SupervisorReportStaffComparisonReport />}
                />
                <Route
                  path="/supervisor-customer-list-co"
                  element={<SupervisorCustomerListCo />}
                />
                <Route
                  path="/coordinator-supervisor-order-history"
                  element={<CoordinatorSupervisorOrderHistory />}
                />
                <Route
                  path="/editor-supervisor-order-history"
                  element={<EditorSupervisorOrderHistory />}
                />
                <Route
                  path="/qc-supervisor-order-history"
                  element={<QcSupervisorOrderHistory />}
                />
                <Route path="/supervisor-staff" element={<SupervisorStaff />} />
                <Route
                  path="/supervisor-staff-new-user"
                  element={<SupervisorStaffnewuser />}
                />
                <Route
                  path="/supervisor-staff-details"
                  element={<SupervisorStaffDetails />}
                />
                <Route
                  path="/supervisor-coordinator-workspace"
                  element={<SupervisorCoordinatorWorkspace />}
                />
                <Route
                  path="/supervisor-editor-workspace"
                  element={<SupervisorEditorWorkspace />}
                />
                <Route
                  path="/supervisor-coordinator-my-workspace"
                  element={<SupervisorCoordinatorMyWorkspace />}
                />
                <Route
                  path="/supervisor-editor-my-workspace"
                  element={<SupervisorEditorMyWorkspace />}
                />
                <Route
                  path="/supervisor-qc-workspace"
                  element={<SupervisorQcWorkspace />}
                />
                <Route
                  path="/supervisor-qc-my-workspace"
                  element={<SupervisorQcMyWorkspace />}
                />
                <Route
                  path="/supervisor-qc-my-canvas"
                  element={<SupervisorQcMyCanvas />}
                />
                <Route
                  path="/supervisor-qc-canvas"
                  element={<SupervisorQcCanvas />}
                />
                <Route path="/supervisor-chat" element={<SupervisorChat />} />
                <Route
                  path="/supervisor-staff-edit-overview"
                  element={<SupervisorStaffEditOverview />}
                /> */}

								{/****************End of  Page******/}
							</>
						)}
					</Routes>
				</AuthContext.Provider>
			</BrowserRouter>
		</>
	);
}

export default App;
