import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';




const DistributionBatchtable = () => {
    const DistributionBatchtable = [{
        id: '1',
        CoordinatorBatchSize: "100 image(s)",
        EditorBatchSize: '10 image(s)',
        QCBatchSize: '100 image(s)'      
    }

    ]

    const columns = [{
        dataField: 'CoordinatorBatchSize',
        text: 'Coordinator Batch Size',
        number: '1',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    }, {
        dataField: 'EditorBatchSize',
        text: 'Editor Batch Size',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'QCBatchSize',
        text: 'QC Batch Size',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    }

    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    // <BootstrapTable
    //     keyField="id"
    //     data={DistributionBatchtable}
    //     columns={columns}
    //     striped
    //     hover
    //     condensed
    //     defaultSortDirection="asc"
    // />
    return (
        <>

            <div className="table-responsive table-b mb-4">
                <BootstrapTable keyField='id' data={DistributionBatchtable} columns={columns} />
            </div>



        </>
    )
}

export default DistributionBatchtable