// Importing toastify module
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

/**
 * Toast message component for success/error.
 * @param {String} message - The message body of the popup.
 * @param {Number} autoClose - The number of milliseconds where it gets closed and default is zero to disable auto close.
 * @param {String} messageType - The Success/Error for now its supporting only for success  (e.g., "success", "error").
 * @returns {JSX.Element} - The rendered Toast alert component.
 */
const ToastAlert = ({ message, autoClose = 0, messageType = "success" }) => {
  const notify = () => {
    // Calling toast method
    if (messageType !== "success") {
      toast.error(message, {
        toastId: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: autoClose > 0 ? autoClose : false,
        closeButton: false,
        theme: "colored",
        hideProgressBar: true,
      });
    } else {
      toast.success(message, {
        toastId: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: autoClose > 0 ? autoClose : false,
        closeButton: false,
        theme: "colored",
        hideProgressBar: true,
      });
    }
  };
  return notify();
};
export default ToastAlert;
