import React, { useState, useRef, useEffect, useCallback } from "react";

import { Button, Row, Col, Form, Modal } from "react-bootstrap";

const RenameModal = ({ imageName, imageExtensions, images, ...props }) => {
	const handleOutsideClick = (e) => {
		if (e.target.classList.contains("modal")) {
			// Check if the click occurred on an element with the "modal" class
			props.onHide();
		}
	};
	useEffect(() => {
		// Add event listener to detect clicks outside the modal
		window.addEventListener("mousedown", handleOutsideClick);

		return () => {
			// Remove the event listener when the component unmounts
			window.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	return (
		<>
			<Modal
				{...props}
				backdrop="static"
				keyboard={false}
				centered
				contentClassName="my-modal--rename box exclude-click"
			>
				{" "}
				<Modal.Header closeButton>
					<Modal.Title
						id="contained-modal-title-vcenter"
						className="section-title fonts"
					>
						{props.title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="section-title fonts" style={{ width: "30vw" }}>
					<Col sm={12}>
						<div className="d-sm-flex">
							<div className="width-100">
								<Form.Control
									type="text"
									autoFocus
									onChange={(e) => {
										// Extract the current value of imageExtensions
										const currentExtensions = imageExtensions;

										// Combine the new value for imageName with the currentExtensions
										const newValue = e.target.value;
										console.log("new value", newValue);
										props.setImageName(e.target.value);
										// Call the onChange function with the new value
										props.setRenameTextDuplicate(newValue);
									}}
									value={imageName} // Display only the imageName part
								/>
							</div>
						</div>
					</Col>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant=""
						className="puple btns mr-2"
						onClick={props.onHide}
						style={{ display: "flex", marginLeft: "auto" }}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						className="btns"
						onClick={props.handleRename}
					>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default RenameModal;
