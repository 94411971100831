import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination, Form, Row, Col, Table } from "react-bootstrap";

import Select from "react-select";

// Importing components and images
import Loader from "../../../components/Loader";
import staffdashboard from "../../../assets/Images/staff-dashboard.jpg";
import Card from "react-bootstrap/Card";
import errorcount from "../../../assets/Images/error-count1.png";
import productionscore from "../../../assets/Images/production-score1.png";
import efficiencyscore from "../../../assets/Images/efficiency-score1.png";
import errorrate from "../../../assets/Images/error-rate1.png";
import imagecount from "../../../assets/Images/image-count1.png";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import { QC_DASHBOARD_WORSPACE, QC_VIEW } from "../../../config/apiRoutes.api";

const StaffCoordinatoreditorDashboard = () => {
	// Retrieve the token from the session storage
	const token = sessionStorage.getItem("token");

	// Create a request instance using the token
	const request = client(token);

	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();

	// Create a new instance of FormData
	const bodyFormData = new FormData();

	//Declarations of state variable
	const [data, setData] = useState([]);
	const [rows, setRows] = useState([]);
	const [qcId, setQCId] = useState("");
	const [pageNumber, setPageNumber] = useState(1);
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowId, setSelectedRowsId] = useState([]);
	const [selectedRowName, setSelectedRowsName] = useState([]);
	const [selectAllRow, setSelectAllRows] = useState([]);
	const [selectAll, setSelectAll] = useState(false);

	const getQCDetails = async () => {
		try {
			if (token) {
				const response = await request.get(apiURL + QC_VIEW);
				setQCId(response.data.qcs[0].id);
			}
		} catch (err) {
			console.error("Error inside batch: ", err);
		}
	};

	useEffect(() => {
		getQCDetails();
		// Check if a token is available
		if (token && qcId !== "") {
			// Make an HTTP GET request to fetch the branch data with the specified page number
			request
				.get(
					apiURL + QC_DASHBOARD_WORSPACE + `/${qcId}` + `?page=${pageNumber}`
				)
				.then((response) => {
					// Log the response to the console
					console.log(response);

					// Update the branchesData state with the response data
					setData(response.data.data);

					// Update the rows state with the branch data
					setRows(response.data.data.data);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumber, qcId]);
	/**
	 * Renders the pagination items for navigating between pages.
	 * It generates the pagination items based on the total number of branches and the active page.
	 * @returns {JSX.Element[]} Array of pagination items.
	 */
	const PaginationItems = () => {
		// Get the number of branches per page
		let active = data.per_page;

		// Initialize an empty array to store the pagination items
		let items = [];

		// Generate pagination items based on the total number of branches and the active page
		for (let number = 1; number <= Math.ceil(data.total / active); number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === data.current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		// Return the array of pagination items
		return items;
	};

	/**
	 * Handles the pagination to the next page of data.
	 * It updates the page number state to the next page if it's not the last page.
	 * @returns {void}
	 */
	const NextPagination = () => {
		// Check if the current page number is not the last page
		if (pageNumber !== Math.ceil(data.total / data.per_page)) {
			// Update the page number state to the next page
			setPageNumber(pageNumber + 1);
		}
	};

	/**
	 * Handles the pagination to the previous page of data.
	 * It updates the page number state to the previous page if it's not the first page.
	 * @returns {void}
	 */
	const PreviousPagination = () => {
		// Check if the current page number is not the first page
		if (pageNumber !== Math.ceil(data.total / data.per_page)) {
			// Update the page number state to the previous page
			setPageNumber(pageNumber - 1);
		}
		return setPageNumber(pageNumber - 1);
	};

	const choosetype = [
		{ label: "This Month", value: 355 },
		{ label: "This Month", value: 54 },
	];
	const EfficiencyScore = [
		{ label: "Efficiency Score", value: 355 },
		{ label: "Efficiency Score", value: 54 },
	];

	/**
	 * Handles the selection/deselection of a row.
	 * @param {Object} e - The event object.
	 * @param {Object} row - The selected row object.
	 */
	const handleSelectRow = React.useCallback(
		(e, row) => {
			if (e.target.checked) {
				// If the checkbox is checked, add the row's encrypted_id and name to the selectedRows and selectedRowsId state respectively
				setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row.id]);
				setSelectedRowsId((prevSelectedRows) => [...prevSelectedRows, row.id]);
			} else {
				// If the checkbox is unchecked, remove the row's encrypted_id and name from the selectedRows and selectedRowsId state respectively
				setSelectedRows((prevSelectedRows) =>
					prevSelectedRows.filter((selectedRow) => selectedRow !== row.id)
				);
				setSelectedRowsId((prevSelectedRows) =>
					prevSelectedRows.filter((selectedRow) => selectedRow !== row.id)
				);
			}
		},
		[selectedRows]
	);
	/**
	 * Handles the selection of all rows in a table.
	 *
	 * @param {Object} e - The event object triggered by the checkbox.
	 */
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			setSelectAll(true);

			// Update all rows to be selected
			const updatedRows = data.data.map((row) => {
				return { ...row, selected: true };
			});
			setRows(updatedRows);

			// Update selected row IDs
			const updatedRowsIds = data.data.map((row) => {
				return row.id;
			});
			setSelectAllRows((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsIds,
			]);
		} else {
			setSelectAll(false);

			// Update all rows to be unselected
			const updatedRows = data.data.map((row) => {
				return { ...row, selected: false };
			});
			setRows(updatedRows);

			// Clear selected row IDs
			setSelectAllRows([]);
		}
	};
	console.log("data", rows);

	return (
		<>
			<div>
				<div className="rightbar pr-11">
					<div className="section-title ">
						<h2>Dashboard</h2>
						<h4 class="mb-4">Performance Summary</h4>
					</div>

					<Row>
						<Col lg={3} sm={12}>
							<Form>
								<div className="form-group">
									<Select
										options={choosetype}
										defaultValue={{ label: "This Month", value: 355 }}
									/>
								</div>
							</Form>
						</Col>
					</Row>

					<Row>
						<Col lg={6} sm={12}>
							<div className="d-sm-flex flex-sm-wrap justify-content-between coordinator-dashboard">
								<Card>
									<Card.Img variant="top" src={productionscore} />
									<Card.Body>
										<Card.Title>Production Score</Card.Title>
										<Card.Text>75.9</Card.Text>
									</Card.Body>
								</Card>

								<Card>
									<Card.Img variant="top" src={efficiencyscore} />
									<Card.Body>
										<Card.Title>Efficiency Score</Card.Title>
										<Card.Text>1.02%</Card.Text>
									</Card.Body>
								</Card>

								<Card>
									<Card.Img variant="top" src={errorrate} />

									<Card.Body>
										<Card.Title>Error Rate</Card.Title>
										<Card.Text>85.2</Card.Text>
									</Card.Body>
								</Card>

								<Card>
									<Card.Img variant="top" src={imagecount} />
									<Card.Body>
										<Card.Title>Image Count</Card.Title>
										<Card.Text>1247</Card.Text>
									</Card.Body>
								</Card>

								<Card>
									<Card.Img variant="top" src={errorcount} />
									<Card.Body>
										<Card.Title>Error Count</Card.Title>

										<Card.Text>54</Card.Text>
									</Card.Body>
								</Card>
								<Card border="light" className="detailscard">
									<Card.Body>
										<Card.Text>
											<p>View Details</p>
										</Card.Text>
									</Card.Body>
								</Card>
							</div>
						</Col>
						<Col lg={6} sm={12} className="pl-0">
							<div class="eficiency-box">
								<img
									src={staffdashboard}
									class="img-fluid mx-auto d-block"
									alt="staffdashboard"
								/>
								<Row className="justify-content-center">
									<Col sm={8}>
										<Form className="mt-4">
											<div className="form-group">
												<Select
													options={EfficiencyScore}
													defaultValue={{
														label: "Efficiency Score",
														value: 355,
													}}
												/>
											</div>
										</Form>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>

					<div className="section-title">
						<h4 class="mb-3 mt-2">Workspace Queue</h4>
					</div>

					<div className="table-responsive table-b overviewtables mb-3">
						{rows !== undefined ? (
							<>
								<div
									className={
										`table-responsive table-b custom-new-table ` + `  `
									}
									style={{ marginTop: "3rem !important" }}
								>
									<Table hover>
										<thead>
											<tr>
												<th>
													{!selectedRows.length && (
														<input
															type="checkbox"
															checked={selectAll}
															onClick={handleSelectAll}
														/>
													)}
												</th>

												<th>Batch No</th>
												<th>Batch Date</th>
												<th>Customer</th>
												<th>Order No</th>
												<th>Type</th>
												<th>Image Count</th>
												<th>Status</th>
											</tr>
										</thead>
										<tbody>
											{data !== undefined &&
												rows.map((item) => {
													const list = (
														<>
															<tr>
																<td className="checkbox-elements--table">
																	<input
																		type="checkbox"
																		checked={
																			selectedRows.length
																				? selectedRows.find(
																						(selectedRow) =>
																							selectedRow.id === item
																				  )
																				: item.selected === true
																		}
																		onChange={(e) => handleSelectRow(e, item)}
																	/>
																</td>
																<td>{item.batch_number}</td>
																<td>
																	{new Date(
																		item.created_at
																	).toLocaleDateString()}
																</td>
																<td>{item.customer.full_name}</td>
																<td>{item.order_id}</td>
																<td>{item.type}</td>
																<td>{item.images_count}</td>
																<td>
																	<span
																		className={
																			item.status === "Pending"
																				? "order-status"
																				: item.status === "Successfull"
																				? "order--success"
																				: ""
																		}
																	>
																		{item.status}
																	</span>
																</td>
															</tr>
														</>
													);
													return list;
												})}
										</tbody>
									</Table>
								</div>

								<Row className="mt-4">
									<Col
										md={{ span: 4, offset: 8 }}
										className="flex flex-md-row-reverse "
									>
										<Pagination>
											<Pagination.Prev onClick={() => PreviousPagination()} />
											{PaginationItems()}
											<Pagination.Next onClick={() => NextPagination()} />
										</Pagination>
									</Col>
								</Row>
							</>
						) : (
							<Loader />
						)}
					</div>

					<Row className="mb-5 text-right">
						<Col md={{ span: 2, offset: 9 }}>
							{<p className="viewbntn">View all</p>}
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default StaffCoordinatoreditorDashboard;
