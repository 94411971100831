import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from '../../components/global/Sidebar/Sidebar'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import pdf from '../../assets/Images/pdf.svg'
import menudot from '../../assets/Images/menu-dot.svg'






const InvoiceView = () => {


  const invoices = [{
    id: '1',
    Description: "Background Removal",
    Quantity: '500',
    Rate: '$5.00',
    Amount: '$2,5000.00'
  }
    ,
  {
    id: '1',
    Description: "Retouching",
    Quantity: '500',
    Rate: '$5.00',
    Amount: '$2,5000.00'
  },
  {
    id: '1',
    Description: "Resizing",
    Quantity: '500',
    Rate: '$5.00',
    Amount: '$2,5000.00'
  },
  {
    id: '1',
    Description: "Cropping",
    Quantity: '500',
    Rate: '$5.00',
    Amount: '$2,5000.00'
  },
  {
    id: '1',
    Description: "Ghost Mannequin",
    Quantity: '500',
    Rate: '$5.00',
    Amount: '$2,5000.00'
  }





  ]

  const columns = [{
    dataField: 'Description',
    text: 'Description',
    number: '1',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }, {
    dataField: 'Quantity',
    text: 'Quantity',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Rate',
    text: 'Rate',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Amount',
    text: 'Amount',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }
  ];

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];


  // <BootstrapTable
  //   keyField="id"
  //   data={invoices}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />




  return (
    <>


    <div className="container-fluid page-body-wrapper">
      <Sidebar />
      <div className="main-panel">
        <div className="rightbar pr-11 overviewstab">
        <div className="section-title">
                          <h2>Customers Details - Nike,Inc.</h2>
                      </div>
          <div className="detailstab">
            <Tabs
              defaultActiveKey="Invoices"
              id="justify-tab-example"
              className="stafftab"
              justify
            >
              <Tab eventKey="Overview" title="Overview">

              </Tab>
              <Tab eventKey="Orders" title="Orders">

              </Tab>
              <Tab eventKey="Chats" title="Chats">

              </Tab>
              <Tab eventKey="Settings" title="Settings">

              </Tab>
              <Tab eventKey="Invoices" title="Invoices">

              </Tab>
            </Tabs>

            <div className="doticon">
              <img src={menudot} className="img-fluid" alt="mindreach images" />
            </div>
          </div>

          <div className="d-sm-flex align-items-center mt-3">
            <div className="">
              <h4>Invoice ONV-000001</h4>
            </div>
            <div className="ml-3">
              <img src={pdf} className="img-fluid" alt="mindreach images" />
            </div>
          </div>



          <ul className="invoicedetails mt-3 mb-4">
            <li><span>Invoice Date:</span> 12 August 2022</li>

            <li><span>Payment Terms:</span> 30 Days</li>

            <li><span>Invoice Due Date:</span> 12 September 2022</li>
          </ul>


          <div className="table-responsive table-b view-invoicetable">
            <BootstrapTable keyField='id' data={invoices} columns={columns} />

          </div>

          <Row>
            <Col sm={{ span: 3, offset: 9 }}>
              <div className="subbox mt-4">
                <ul>
                  <li><span className="headli">Subtotal:</span> $4859.00</li>
                  <li><span className="headli">Total:</span> $4859.00</li>
                  <li><span className="headli">Payment Made:</span> <span className="red">$4859.00</span></li>
                  <li className="balncedue">Balance Due: <b>$0.00</b></li>
                </ul>
              </div>
            </Col>
          </Row>




        </div>
      </div>
    </div>








  </>
  )
}

export default InvoiceView