import React from 'react'
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom'



const EditorOrderDetailsidebar = () => {
  return (
    <>

      <div className="ordercard">
        <Card className="activecard">
          <Card.Header>
            <div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
              <div className="">
                <p className="ordertitle">Order ID: 00001<br />
                  Nike, Inc.</p>
              </div>
              <div className="">
                <p className="orderdate">3 Sep 2022</p>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="order-details">
            <p>Image Count: 10</p>
            <p>Status: <span className="orange">Processing</span></p>
            </div>
            <Link to="/"><p className="viewguide">View Style Guide</p></Link>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
              <div className="">
                <p className="ordertitle">Order ID: 00001<br />
                Under Armour Corp.</p>
              </div>
              <div className="">
                <p className="orderdate">3 Sep 2022</p>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="order-details">
            <p>Image Count: 10</p>
            <p>Status: <span className="red">Pending</span></p>
            </div>
           
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
            <div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
              <div className="">
                <p className="ordertitle">Order ID: 00001<br />
                Reebok International</p>
              </div>
              <div className="">
                <p className="orderdate">3 Sep 2022</p>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="order-details">
            <p>Image Count: 10</p>
            <p>Status: <span className="green">Completed</span></p>
            </div>
           
          </Card.Body>
        </Card>


        <Card>
          <Card.Header>
            <div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
              <div className="">
                <p className="ordertitle">Order ID: 00001<br />
                  Nike, Inc.</p>
              </div>
              <div className="">
                <p className="orderdate">3 Sep 2022</p>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="order-details">
            <p>Image Count: 10</p>
            <p>Status: <span className="orange">Processing</span></p>
            </div>
            <Link to="/"><p className="viewguide">View Style Guide</p></Link>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
              <div className="">
                <p className="ordertitle">Order ID: 00001<br />
                Under Armour Corp.</p>
              </div>
              <div className="">
                <p className="orderdate">3 Sep 2022</p>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="order-details">
            <p>Image Count: 10</p>
            <p>Status: <span className="red">Pending</span></p>
            </div>
           
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
            <div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
              <div className="">
                <p className="ordertitle">Order ID: 00001<br />
                Reebok International</p>
              </div>
              <div className="">
                <p className="orderdate">3 Sep 2022</p>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="order-details">
            <p>Image Count: 10</p>
            <p>Status: <span className="green">Completed</span></p>
            </div>
           
          </Card.Body>
        </Card>
      </div>


    </>
  )
}

export default EditorOrderDetailsidebar