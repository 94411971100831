import React from 'react'
import Sidebar from '../../../../components/global/Sidebar/Sidebar'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Orders from './Orders'
import Customeroverview from './Customeroverview';
import Customersettings from './Customersettings';
import menudot from '../../../../assets/Images/menu-dot.svg'
const QcSupervisorOrderHistory = () => {
    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="rightbar pr-11 overviewstab supervisor-overviewtab customrightbar">
                        <div className="section-title">
                            <h2>Customers Details - Nike,Inc.</h2>
                        </div>
                        <div className="detailstab">
                            <Tabs
                                defaultActiveKey="Overview"
                                id="justify-tab-example"
                                className="stafftab coordintortab"
                                justify
                            >
                                <Tab eventKey="Overview" title="Overview">
                                    <Customeroverview />
                                </Tab>
                                <Tab eventKey="Orders" title="Orders">
                                    <Orders />
                                </Tab>

                                <Tab eventKey="Settings" title="Settings">
                                    <Customersettings />
                                </Tab>

                            </Tabs>
                            <div className="doticon">
                                <img src={menudot} className="img-fluid" alt="mindreach images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default QcSupervisorOrderHistory