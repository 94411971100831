import React, { useEffect, useState } from "react";
//Importing the bootstrap components
import { Button, Row, Col, Form, Tab, Tabs } from "react-bootstrap";
//Importing the images
import CanceIcon from "./../../../../assets/Images/actionsIcons/cancel_Final.svg";
import cross from "./../../../../assets/Images/cross-3.svg";
//Importing the react select components
import Select from "react-select";
//Importing the next js Image components
// import Image from "next/image";
//Importing the loader components
import Loader from "./../../../../components/Loader";

//Importing the loader components
import ToastAlert from "../../../../components/ToastAlert";

// Importing API related files and routes

import { client } from "./../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../../data/filepath";
import {
  CO_ORDINATOR_VIEW_EDIT_GROUP,
  CO_ORDINATOR_UPDATE_EDIT_GROUP,
} from "../../../../config/apiRoutes.api";
// import {
// 	CUSTOMER_IMAGES_EDIT_GROUP,
// 	CUSTOMER_VIEW_EDIT_GROUP,
// } from "../../../../../config/apiRoutes.api";
// import CancelIcon from "./../../../assets/Images/actionsIcons/cancel_Final.svg";

const customSelectStyles = {
	menuList: (provided) => {
		return {
			...provided,
			"& :first-child": {
				fontWeight: "bold",
			},
			fontSize: "13px",
		};
	},
};
const RightSidebarMultiSelect = (props) => {
  let {
    image_details_view,
    editTypes,
    setMultipleImagesId,
    selectImageName,
    multiple_images_id,
    token,
    id,
    idSelected,
    setClickSelect,
    customer_id,
    setSelectAll,
    fetchCheckoutPrice,
    setting_profile
  } = props;
  // Create a FormData object for request payload
  const bodyFormData = new FormData();

  // Create an HTTP client with the token
  const request = client(token);

  //Declarations of state
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [groupSelect, setGroupSelect] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [selected, setSelected] = useState(false);
  const [selected1, setSelected1] = useState(false);
  const [selected2, setSelected2] = useState(false);
  const [selected3, setSelected3] = useState(false);
	const [customizedSettings, customizedSettingsEnabled] = useState(false);
	const [previousSelectedValue, setPreviousSelectedValue] = useState("");
  const [edit_select, setEditorType] = useState("");
  const [edit_id, setEditId] = useState([]);
  const [edit_type_toggle, setToggleEditType] = useState(false);
  //To update the select group
  const [group_name_select, setEditGroupNameSelect] = useState("New Settings");
  // To set the state for add
  const [background_removal_color_type, setBackgroundRemovalColorType] =
    useState("");
  const [background_removal_color_code, setBackgroundRemovalColorCode] =
    useState("");
  const [background_adjustment_color_code, setBackgroundAdjustmentColorCode] =
    useState("");
  const [background_adjustment_color_type, setBackgroundAdjustmentColorType] =
    useState("");
  const [view_edit_group, setViewEditGroup] = useState({});
  const [group_name, setGroupName] = useState("");
  const [loader, setLoader] = useState(false); //Loader State
  // const [groupSelect, setGroupSelect] = useState(false);
  const [new_edit_group_name, setNewEditGroupName] = useState("");

  const [resizing_file_format, setResizingFileFormat] = useState("");
  const [resizing_height, setResizingHeight] = useState("");
  const [resizing_width, setResizingWidth] = useState("");
  const [resizing_dpi, setResizingDpi] = useState("");
  const [resizing_dpi_type, setResizingDpiType] = useState("");
  const [resizing_color_mode, setResizingColorMode] = useState("");
  const [resizing_alignment, setResizingAlignment] = useState("");
  const [resizing_margin_format, setResizingMarginFormat] = useState("");
  const [resizing_margin_top, setResizingMarginTop] = useState("");
  const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
  const [resizing_margin_right, setResizingMarginRight] = useState("");
  const [resizing_margin_left, setResizingMarginLeft] = useState("");
  const [edit_group_name, setEditGroupName] = useState("");
  const [edit_type_ids, setEditTypeId] = useState("");
  const [image_file_format, setImageFileFormat] = useState("");
  const [image_height, setImageHeight] = useState("");
  const [image_width, setImageWidth] = useState("");
  const [image_dpi, setImageDpi] = useState("");
  const [image_dpi_type, setImageDpiType] = useState("");
  const [image_color_mode, setImageColorMode] = useState("");
  const [image_alignment, setImageAlignment] = useState("");
  const [image_margin_format, setImageMarginFormat] = useState("");
  const [image_margin_top, setImageMarginTop] = useState("");
  const [image_margin_bottom, setImageMarginBottom] = useState("");
  const [image_margin_right, setImageMarginRight] = useState("");
  const [image_margin_left, setImageMarginLeft] = useState("");
  const [group, setGroup] = useState([]);

  /**
   * Transform an array of customer edit types into an array of objects with label, value, and additional properties.
   * @param {Array} customerEditTypes - Array of customer edit types.
   * @returns {Array} - Transformed array of objects with label, value, and additional properties.
   */
  const selectLabel = (customerEditTypes) =>
    customerEditTypes.map(({ group_name: label, id: value, ...rest }) => ({
      label,
      value,
      ...rest,
    })); // change the key of array as label and value return a new array of object like [{value:"",label:""}]

  function LocalImageLoader({ src, width, quality }) {
    return <Loader />;
  }
  const file = [
    { label: "JPEG", value: 355 },
    { label: "PNG", value: 54 },
    { label: "JPG", value: 54 },
  ];
  const selectBackgrondRemoval = [
    { label: "Custom", value: 355 },
    { label: "Transparent", value: 54 },
  ];
  const selectedAmount = [
    { label: "1", value: 355 },
    { label: "2", value: 54 },
    { label: "3", value: 54 },
  ];
  const dpi = [
    { label: "JPEG", value: 355 },
    { label: "PNG", value: 54 },
    { label: "JPG", value: 54 },
  ];

  const ColorMode = [
    { label: "Original", value: 355 },
    { label: "Edited", value: 54 },
  ];
  const Align = [
    { label: "center", value: 355 },
    { label: "start", value: 54 },
    { label: "end", value: 55 },
  ];

  const Margin = [
    { label: "pixel", value: 355 },
    { label: "em", value: 54 },
    { label: "rem", value: 55 },
    { label: "%", value: 56 },
  ];
  /**
   * Handle the selection of checkboxes and update the `editId` state accordingly.
   * @param {ChangeEvent} changeEvent - The change event object.
   */
  const handleAddCheckboxSelect = (changeEvent) => {
    changeEvent.persist();
    setEditId((prevState) => {
      const editItem = changeEvent.target.value;

      if (prevState.includes(editItem)) {
        // If the selected editItem is already in the prevState array,
        // remove it from the array and return the updated array.
        return prevState.filter((el) => el !== editItem);
      }
      // If the selected editItem is not in the prevState array,
      // add it to the array and return the updated array.
      return [...prevState, editItem];
    });
  };

  /**
	 * Handles to check the option value has changed and reset the state for all the checkboxes.
	 * @param {Number} id - The id to determine the checkbox dependent field which to reset.
	 * @returns {void}
	 */
	const resetFields = (id) => {
		if (id === 1) {
			setBackgroundRemovalColorType("");
			setBackgroundRemovalColorCode("");
		} else if (id === 2) {
			setBackgroundAdjustmentColorCode("");
			setBackgroundAdjustmentColorType("");
		} else if (id === 3) {
			setResizingFileFormat("");
			setResizingHeight("");
			setResizingWidth("");
			setResizingDpi("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setResizingMarginTop("");
			setResizingMarginBottom("");
			setResizingMarginRight("");
			setResizingMarginLeft("");
		}
	};
  
  /**
   * Handle the checkbox state and perform actions based on the given id.
   * @param {number} id - The id to determine the checkbox action.
	 * @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
   */
  const handleBackGroundRemovalCheckbox = (id,checked = false) => {
    if (id === 1) {
      setSelected(checked);
      setChecked((val) => !val);
    }
    if (id === 2) {
      setSelected1(checked);
      setChecked1((val) => !val);
    }
    if (id === 3) {
      setSelected2(checked);
      setChecked2((val) => !val);
    }
    if (id === 15) {
      setSelected3(checked);
      setChecked3((val) => !val);
    }
    resetFields(id);
  };

  /**
  * Handles to get all the checked values of checkbox by class name.
  * @returns {void}
  */
  const getCheckedValues = () => {
    const checkedValues = document.querySelectorAll(".et_options");
    const checkedValue = [];
    checkedValues.forEach((element) => {
      if (element.checked) {
        checkedValue.push(element.value);
      }
    });
    return checkedValue.length > 0 ? checkedValue.join(",") : "";
  };

  /**
   * Handles the update of an image edit group by sending a POST request to the server.
   * @returns {void}
   */
  const handleUpdateImageEditGroup = () => {
    if (token) {
      // setLoader(true);
      // Append form data with the necessary parameters
			const editTypeId = getCheckedValues();
      bodyFormData.append("customer_id", customer_id);
      bodyFormData.append("image_ids", idSelected);
      bodyFormData.append("is_overright", 1);
			if (edit_select > 0) {
         bodyFormData.append("edit_group_id", edit_select);
      } else {
        bodyFormData.append(
					"extra_edit_type_ids",
					group_name_select === "New Settings" || group_name_select === "Customized Settings"
						? editTypeId
						: ""
				);
      }
      bodyFormData.append(
        "background_removal_color_type",
        background_removal_color_type
      );
      bodyFormData.append(
        "background_removal_color_code",
        background_removal_color_code
      );
      bodyFormData.append(
        "background_adjustment_color_type",
        background_adjustment_color_type
      );
      bodyFormData.append(
        "background_adjustment_color_code",
        background_adjustment_color_code
      );
      bodyFormData.append("resizing_height", resizing_height);
      bodyFormData.append("resizing_width", resizing_width);
			/* We might require this code again in future keep it commented */
      // bodyFormData.append("resizing_file_format", resizing_file_format);
      // bodyFormData.append("resizing_dpi", resizing_dpi);
      // bodyFormData.append("resizing_dpi_type", resizing_dpi_type);
      // bodyFormData.append("resizing_color_mode", resizing_color_mode);
      // bodyFormData.append("resizing_alignment", resizing_alignment);
      // bodyFormData.append("resizing_margin_format", resizing_margin_format);
      // bodyFormData.append("resizing_margin_top", resizing_margin_top);
      // bodyFormData.append("resizing_margin_bottom", resizing_margin_bottom);
      // bodyFormData.append("resizing_margin_right", resizing_margin_right);
      // bodyFormData.append("resizing_margin_left", resizing_margin_left);
      bodyFormData.append("image_file_format", image_file_format);
      bodyFormData.append("image_height", image_height);
      bodyFormData.append("image_width", image_width);
      bodyFormData.append("image_dpi", image_dpi);
      bodyFormData.append("image_dpi_type", image_dpi_type);
      bodyFormData.append("image_color_mode", image_color_mode);
      bodyFormData.append("image_alignment", image_alignment);
      bodyFormData.append("image_margin_format", image_margin_format);
      bodyFormData.append("image_margin_top", image_margin_top);
      bodyFormData.append("image_margin_bottom", image_margin_bottom);
      bodyFormData.append("image_margin_right", image_margin_right);
      bodyFormData.append("image_margin_left", image_margin_left);

      request
        .post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
        .then((response) => {
          ToastAlert({
            title: response.data.status ? "Success" : "Error",
            message: response.data.status ? "Sccussfully Updated" : "Error",
            autoClose: 2000,
            messageType: response.data.status ? "success" : "error",
          });
          setLoader(false);
          setMultipleImagesId([]);
          setClickSelect(false);
          setSelectAll(false);
          // fetchCheckoutPrice();
          // window.location.reload();
        })
        .catch((err) => {
          let message =
            typeof err.response !== "undefined"
              ? err.response.data.message
              : err.message;
          //   setError(message); // We need this code in future
          // Show error message
          ToastAlert({
            title: "Error",
            message: "Error",
            autoClose: 2000,
            messageType: "error",
          });
        });
    }
  };
  const handleUpdateEditGroup = () => {
    if (token) {
      // setLoader(true);
      // Append form data with the necessary parameters
			const editTypeId = getCheckedValues();
      bodyFormData.append("customer_id", customer_id);
      bodyFormData.append("image_ids", idSelected);

      bodyFormData.append("is_overright", 1);
			if (edit_select > 0) {
				bodyFormData.append("edit_group_id", edit_select);
			} else {
        bodyFormData.append(
					"extra_edit_type_ids",
					group_name_select === "New Settings" || group_name_select === "Customized Settings"
						? editTypeId
						: ""
				);
      }

      bodyFormData.append("is_overright", 0);
      bodyFormData.append(
        "background_removal_color_type",
        background_removal_color_type
      );
      bodyFormData.append(
        "background_removal_color_code",
        background_removal_color_code
      );
      bodyFormData.append(
        "background_adjustment_color_type",
        background_adjustment_color_type
      );
      bodyFormData.append(
        "background_adjustment_color_code",
        background_adjustment_color_code
      );
      bodyFormData.append("resizing_height", resizing_height);
      bodyFormData.append("resizing_width", resizing_width);
			/* We might require this code again in future keep it commented */
      // bodyFormData.append("resizing_file_format", resizing_file_format);
      // bodyFormData.append("resizing_dpi", resizing_dpi);
      // bodyFormData.append("resizing_dpi_type", resizing_dpi_type);
      // bodyFormData.append("resizing_color_mode", resizing_color_mode);
      // bodyFormData.append("resizing_alignment", resizing_alignment);
      // bodyFormData.append("resizing_margin_format", resizing_margin_format);
      // bodyFormData.append("resizing_margin_top", resizing_margin_top);
      // bodyFormData.append("resizing_margin_bottom", resizing_margin_bottom);
      // bodyFormData.append("resizing_margin_right", resizing_margin_right);
      // bodyFormData.append("resizing_margin_left", resizing_margin_left);
      bodyFormData.append("image_file_format", image_file_format);
      bodyFormData.append("image_height", image_height);
      bodyFormData.append("image_width", image_width);
      bodyFormData.append("image_dpi", image_dpi);
      bodyFormData.append("image_dpi_type", image_dpi_type);
      bodyFormData.append("image_color_mode", image_color_mode);
      bodyFormData.append("image_alignment", image_alignment);
      bodyFormData.append("image_margin_format", image_margin_format);
      bodyFormData.append("image_margin_top", image_margin_top);
      bodyFormData.append("image_margin_bottom", image_margin_bottom);
      bodyFormData.append("image_margin_right", image_margin_right);
      bodyFormData.append("image_margin_left", image_margin_left);

      request
        .post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
        .then((response) => {
          //Set the toast alert
          ToastAlert({
            title: response.data.status ? "Success" : "Error",
            message: response.data.status ? "Settings saved" : "Error",
            autoClose: 2000,
            messageType: response.data.status ? "success" : "error",
          });
          setLoader(false);
          setMultipleImagesId([]);
          setClickSelect(false);
          setSelectAll(false);
          // fetchCheckoutPrice();
          // window.location.reload();
        })
        .catch((err) => {
          let message =
            typeof err.response !== "undefined"
              ? err.response.data.message
              : err.message;
          //   setError(message); // We need this code in future
          ToastAlert({
            title: "Error",
            message: "Error",
            autoClose: 2000,
            messageType: "error",
          });
        });
    }
  };
  useEffect(() => {
    // Create a new array by combining existing group values and the new object
    const updatedGroup = [
      { id: "New Settings", group_name: "New Settings" },
      ...props.editGroups
    ];
    setGroup(updatedGroup);
  }, [props.editGroups]);
  useEffect(() => {
    // Fetch data from the API endpoint
    if (edit_select > 0  && token  && idSelected.indexOf(',') < 0) {
      request
        .get(apiURL + CO_ORDINATOR_VIEW_EDIT_GROUP + `/${edit_select}`)
        .then((response) => {
          customizedSettingsEnabled(false);
					setEditGroupNameSelect("");
          //Set View  for edit group from the response
          let data = response.data.edit_group;
          setViewEditGroup(response.data);
          setBackgroundRemovalColorCode(
            response.data.edit_group.background_settings[0] &&  response.data.edit_group.background_settings[0].edit_type_id === 1? 
            response.data.edit_group.background_settings[0].color_code :""
          );
          setBackgroundAdjustmentColorCode(
            response.data.edit_group.background_settings[1] && response.data.edit_group.background_settings[1].edit_type_id === 2 ? 
            response.data.edit_group.background_settings[1].color_code :""
          );
          setBackgroundRemovalColorType(
            response.data.edit_group.background_settings[0] !== undefined  && response.data.edit_group.background_settings[0].edit_type_id === 1 
              ? response.data.edit_group.background_settings[0].color_type
              : ""
          );
          setBackgroundAdjustmentColorType(
            response.data.edit_group.background_settings[1] !== undefined && response.data.edit_group.background_settings[1].edit_type_id === 2
              ? response.data.edit_group.background_settings[1].color_type
              : ""
          );
				 	{/* We might require this code again in future keep it commented */}
          setResizingFileFormat(
            response.data.edit_group.resizing_settings[0].file_format !==
              undefined
              ? response.data.edit_group.resizing_settings[0].file_format
              : ""
          );
          setResizingDpiType(
            response.data.edit_group.resizing_settings[0] !== undefined
              ? response.data.edit_group.resizing_settings[0].dpi_type
              : ""
          );
          setResizingColorMode(
            response.data.edit_group.resizing_settings[0] !== undefined
              ? response.data.edit_group.resizing_settings[0].color_mode
              : ""
          );
          setResizingAlignment(
            response.data.edit_group.resizing_settings[0] !== undefined
              ? response.data.edit_group.resizing_settings[0].alignment
              : ""
          );
          setResizingMarginFormat(
            response.data.edit_group.resizing_settings[0] !== undefined
              ? response.data.edit_group.resizing_settings[0].margin_format
              : ""
          );
          setResizingHeight(
            response.data.edit_group.resizing_settings[0] &&
              response.data.edit_group.resizing_settings[0].height
              ? response.data.edit_group.resizing_settings[0].height
              : ""
          );
          setResizingWidth(
            response.data.edit_group.resizing_settings[0] &&
              response.data.edit_group.resizing_settings[0].width
              ? response.data.edit_group.resizing_settings[0].width
              : ""
          );
          setImageFileFormat(
            response.data.edit_group.setting.file_format !== undefined
              ? response.data.edit_group.setting.file_format
              : ""
          );
          setImageHeight(response.data.edit_group.setting.height !== null
            ? response.data.edit_group.setting.height
            : "");
          setImageWidth(response.data.edit_group.setting.width !== null
              ? response.data.edit_group.setting.width
              : "");
          setImageDpi(response.data.edit_group.setting.dpi ? response.data.edit_group.setting.dpi : "");
          setImageDpiType(
            response.data.edit_group.setting.dpi_type !== null
              ? response.data.edit_group.setting.dpi_type
              : ""
          );
          setImageColorMode(
            response.data.edit_group.setting !== null
              ? response.data.edit_group.setting.color_mode
              : ""
          );
          setImageAlignment(
            response.data.edit_group.setting !== null
              ? response.data.edit_group.setting.alignment
              : ""
          );
          setImageMarginFormat(
            response.data.edit_group.setting !== null
              ? response.data.edit_group.setting.margin_format
              : ""
          );

          setImageMarginTop(
            response.data.edit_group.setting.margin_top ? response.data.edit_group.setting.margin_top : ""
          );
          setImageMarginBottom(
            response.data.edit_group.setting.margin_bottom ? response.data.edit_group.setting.margin_bottom : ""
          );
          setImageMarginRight(
            response.data.edit_group.setting.margin_right ? response.data.edit_group.setting.margin_right : ""
          );
          setImageMarginLeft(
            response.data.edit_group.setting.margin_left ? response.data.edit_group.setting.margin_left : ""
          );
          if (response.data.edit_group.background_settings.length) {
            setSelected(true);
            setSelected1(true);
          }
          if (response.data.edit_group.resizing_settings.length) {
            setSelected2(true);
          }
        })
        .catch((err) => {
          let message =
            typeof err.response !== "undefined"
              ? err.response.data.message
              : err.message;
          setError(message);
        });
    } else {
			if (!customizedSettings) {
        setSelected(false);
				setSelected1(false);
				setSelected2(false);
				setSelected3(false);
				setViewEditGroup({});
				setBackgroundRemovalColorCode("");
				setBackgroundAdjustmentColorCode("");
				setBackgroundRemovalColorType("");
				setBackgroundAdjustmentColorType("");
				setResizingFileFormat("");
				setResizingDpiType("");
				setResizingColorMode("");
				setResizingAlignment("");
				setResizingMarginFormat("");
				setImageFileFormat("");
				setImageDpiType("");
				// setImageColorMode(
				// 	data.edit_group.setting !== null
				// 		? data.edit_group.setting.color_mode
				// 		: "Select color mode"
				// );
				// setImageAlignment(
				// 	[]
				// );
				// setImageMarginFormat(
				// 	[]
				// );
			}
      const et_head = document.getElementById("et_head");
			if(et_head){
				et_head.scrollIntoView();
			}
		}
  }, [edit_select]);

  /**
	 * Handles to check the option value has changed and reset the state for all the checkboxes.
	 * @returns {void}
	 */

	// useEffect(() => {
	// 	setSelected(false);
	// 	setSelected1(false);
	// 	setSelected2(false);
	// 	setSelected3(false);
	// }, [edit_select]);

  /**
   * Create an array of checked values for the edit_types in view_edit_group
   **/
    let updateChecked =
    view_edit_group !== undefined &&
    view_edit_group.edit_group !== undefined && view_edit_group.edit_group  &&
    view_edit_group.edit_group.edit_types !== undefined &&
    view_edit_group.edit_group.edit_types.map((items, index) => {
      return items.id;
    });

  /**
	 * Handles to set the state.
	 * @param {Boolean} checked - The checked/unchecked after action of checkbox clicked.
	 */
	const toggleFieldEditing = (checked = false) => {
		customizedSettingsEnabled(checked);
		if (checked) {
      if (edit_select > 0) {
				setPreviousSelectedValue(edit_select);
			}
			const updatedGroup = [
        { id: "Customized Settings", group_name: "Customized Settings" },
        { id: "New Settings", group_name: "New Settings" },
        ...props.editGroups,
      ];
      setGroup(updatedGroup);
      setEditGroupNameSelect("Customized Settings");
      setEditorType("Customized Settings");
		} else {
			setEditGroupNameSelect("");
			setEditorType(previousSelectedValue);
			setImageFileFormat("");
			setImageHeight("");
			setImageWidth("");
			setImageDpi("");
			setImageDpiType("");
			setImageColorMode("");
			setImageAlignment("");
			setImageMarginFormat("");
			setImageMarginTop("");
			setImageMarginBottom("");
			setImageMarginRight("");
			setImageMarginLeft("");
		}
	};

  /**
	* Handles profile change event
	* @param {Event} e - The changed event object.
	*/
	const handleProfileChange = (e) => {
		setEditGroupNameSelect(e.label);
		setEditorType(e.value);
		setGroupSelect((val) => !val);
    customizedSettingsEnabled(false);
		if(group[0] && group[0].group_name === "Customized Settings" && e.label === "New Settings"){
			const updatedGroup = [
				{ id: "New Settings", group_name: "New Settings" },
				...props.editGroups,
			];
			setGroup(updatedGroup);
		} 
	}

  /**
	 * Handles to return the checkbox field editbale state is set or not.
	 */
	const checkisEditableField = () => {
		return !customizedSettings;
	};

  /**
	* Handles to return the append the form data.
	* @param {Object} formData - The formData Object.
	* @param {Object} data - The data which we wwants to append.
	*/
  function appendToFormData(formData, data) {
    for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
     }
  }

  return (
    <div>
      <div className="sidebar-image--name d-flex coordinator">
        <div className="sidebar--select">
          <div
            className="d-flex"
            style={{
              marginBottom: "18px",
							marginTop: "-19.8px",
            }}
          >
            <h2
              className="side-bar-single-select--h2 "
              style={{ 
                maxWidth:"22em",
                width:"auto",
                marginLeft:"auto"
              }}
            >
              {multiple_images_id.length} items selected
            </h2>
            <div
              className="sidebar-select--header"
              style={{ marginTop:"-12px",marginLeft :"31%" }}
              onClick={() => {
                setMultipleImagesId([]);
                setClickSelect(false);
              }}
            >
              <img
                src={CanceIcon}
                className="img-fluid"
                alt="mindreach images"
              />
            </div>
          </div> 
          <div className="form-group edit-profile-setting-container--single" style={{
            border:"none"
            }}>
						<hr style={{ width: "100%" }} />
            <Row
              className="align-items-center edit-setting-sidebar  row"
            >
              <Form.Label className="col-sm-3 ">Settings Profile</Form.Label>
              <Col sm={9}>
                <Select
                  options={selectLabel(group)}
                  onChange={(e) => {
                     handleProfileChange(e);
                  }}
                  placeholder="Select Profile"
                  value={selectLabel(group).find((option) => {
										if (edit_select !== "" && edit_select !== null) {
											return option.value === edit_select;
										} else {
											return null;
										}
									})}
                  defaultValue={{
										label: "New Settings",
										id: "New Settings",
									}}
									styles={customSelectStyles}
                />
              </Col>
            </Row>
          </div>
					<hr style={{ width: "100%" }} />
          <div className="sidebar-specifications">
            {group_name_select === "New Settings"  && !customizedSettings ? (
              <div className="sidebar-select--editType vh-100">
                {/* Form groups for Edit Type*/}
                <div className="sub-head" id="et_head">Edit Type</div>

                {editTypes.map((item) => {
                  return (
                    <Form.Group key={item.id}>
                      {" "}
                      <Row className="form-group  flex-grow-1" sm={9}>
                        <Col sm={1}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              handleBackGroundRemovalCheckbox(item.id,e.target.checked);
                              handleAddCheckboxSelect(e);
                            }}
                            value={item.id}
                            id={`et_${item.id}`}
														className="et_options"
                          />
                        </Col>
                        <Form.Label
                          className="col-sm-4 form-label form-check-label"
                          style={{ 
                            width: "auto",
                            marginTop: "1.6px",
                            cursor: "pointer",
                          }}
                          for={`et_${item.id}`}
                        >
                          {item.edit_type}
                        </Form.Label>
                      </Row>
                      {item.id === 1 && selected &&  (
                        selected ? (
                          <Row
                            className="align-items-center"
                            style={{
                              marginLeft: "auto",
                              marginTop: "0",
                              marginBottom: "20px",
                            }}
                          >
                            <Col sm={5} className="ml_4_percent">
                              <Select
                                options={selectBackgrondRemoval}
                                onChange={(e) =>
                                  setBackgroundRemovalColorType(e.label)
                                }
                              />
                            </Col>
                            <Col sm={3}>
                              <Form.Control
                                type="text"
                                placeholder="#ffff"
                                autoFocus
                                onChange={(e) =>
                                  setBackgroundRemovalColorCode(e.target.value)
                                }
                                autoComplete="off"
                              />
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )
                      )}
                      {item.id === 2 && selected1 && (
                          <Row
                            className="align-items-center"
                            style={{
                              marginLeft: "auto",
                              marginTop: "0",
                              marginBottom: "20px",
                            }}
                          >
                            <Col sm={5} className="ml_4_percent">
                              <Select
                                options={selectBackgrondRemoval}
                                onChange={(e) =>
                                  setBackgroundAdjustmentColorType(e.label)
                                }
                              />
                            </Col>
                            <Col sm={3}>
                              <Form.Control
                                type="text"
                                placeholder="#ffff"
                                autoFocus
                                onChange={(e) =>
                                  setBackgroundAdjustmentColorCode(
                                    e.target.value
                                  )
                                }
                                value={background_adjustment_color_code}
                                autoComplete="off"
                              />
                            </Col>
                          </Row>
                      )}
                      {item.id === 3 && selected2 && (
                          <Row
                            className="align-items-center"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "0",
                              marginBottom: "20px",
                              width: " 94%",
                            }}
                          >
                            {/* We might require this code again in future keep it commented */}
                            {/* <Form.Group className="mg-bottom16">
																<Row className="form-group  flex-grow-1" sm={9}>
																	<Form.Label className="col-sm-6 form-label form-check-label ml-2">
																		File Format
																	</Form.Label>
																</Row>
															</Form.Group>
                            <Form.Group style={{ margin: "2px" }}>
                              <div className="form-group">
                                <Row className="align-items-center">
                                   <Col sm={6} className="ml-1">
                                      <Select
                                        options={file}
                                        onChange={(e) =>
                                          setResizingFileFormat(e.label)
                                        }
                                      />
                                  </Col>
                                </Row>
                              </div>
                            </Form.Group> */}

                            <Form.Group style={{ margin: "2px" }}>
                              <div className="form-group">
                                <Row className="align-items-center">
                                  <Col sm={12}>
                                    <div className="d-sm-flex align-items-center col-gap-10">
                                      <div className="d-sm-flex col-gap-10 align-items-center">
                                          <Form.Control
																						type="text"
																						placeholder="Height"
																						autoFocus
																						onChange={(e) =>
																							setResizingHeight(e.target.value)
																						}
																						autoComplete="off"
																					/>
                                      </div>
                                      <div className="d-sm-flex align-items-center">
                                          <img
																						style={{ width: "28px" }}
																						src={cross}
																						className="img-fluid"
																						alt="mindreach images"
																					/>
                                      </div>
                                      <div className="d-sm-flex align-items-center col-gap-10">
                                          <Form.Control
																						type="text"
																						placeholder="Width"
																						autoFocus
																						onChange={(e) =>
																							setResizingWidth(e.target.value)
																						}
																						autoComplete="off"
																					/>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Form.Group>
                            {/* We might require this code again in future keep it commented */}
                            {/* <Form.Group className="mg-bottom16">
																<Row className="form-group  flex-grow-1" sm={9}>
																	<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																		DPI
																	</Form.Label>
																</Row>
														</Form.Group>                
                            <Form.Group style={{ margin: "2px" }}>
                              <div className="form-group">
                                <Row className="align-items-center">
                                  <Col sm={12} className="ml-1">
                                    <div className="d-sm-flex col-gap-20">
                                      <div className="w-75">
                                        <Form.Control
                                          type="text"
                                          placeholder="2000"
                                          autoFocus
                                          onChange={(e) =>
                                            setResizingDpi(e.target.value)
                                          }
                                          autoComplete="off"
                                        />
                                      </div>
                                      <div className="flex-fill w-75">
                                        <Select
                                          options={dpi}
                                          onChange={(e) =>
                                            setResizingDpiType(e.label)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Form.Group>
                            <Form.Group className="mg-bottom16">
																<Row className="form-group  flex-grow-1" sm={9}>
																	<Form.Label className="col-sm-6 form-label form-check-label ml-1">
																		Color Mode
																	</Form.Label>
																</Row>
														</Form.Group>
                            <Form.Group style={{ margin: "2px" }}>
                              <div className="form-group">
                                <Row className="align-items-center">
                                  <Col sm={9} className="ml-1">
                                    <Select
                                      options={ColorMode}
                                      onChange={(e) =>
                                        setResizingColorMode(e.label)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Form.Group>
                            <Form.Group className="mg-bottom16">
																<Row className="form-group  flex-grow-1" sm={9}>
																	<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																		Align
																	</Form.Label>
																</Row>
														</Form.Group>
                            <Form.Group style={{ margin: "2px" }}>
                              <div className="form-group">
                                <Row className="align-items-center">
                                  <Col sm={9} className="ml-1">
                                    <Select
                                      options={Align}
                                      onChange={(e) =>
                                        setResizingAlignment(e.label)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Form.Group>
                            <Form.Group className="mg-bottom16">
																<Row className="form-group  flex-grow-1" sm={9}>
																	<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																		Margins
																	</Form.Label>
																</Row>
														</Form.Group>
                            <Form.Group style={{ margin: "2px" }}>
                              <div className="form-group">
                                <Row className="align-items-center">
                                   <Col sm={9} className="ml-1">
                                    <Select
                                      options={Margin}
                                      onChange={(e) =>
                                        setResizingMarginFormat(e.label)
                                      }
                                    />
                                  </Col>
                                  <Col
                                    sm={7}
                                    lg={7}
                                    style={{
                                      display: "flex",
                                      marginLeft: "2px",
                                    }}
                                  >
                                    <div className="d-sm-flex col-gap-20 mt-2 ">
                                      <div className="d-flex">
                                        <ul className="margin-list d-flex">
                                          <li>
                                            <Form.Control
                                              type="text"
                                              placeholder="T"
                                              autoFocus
                                              onChange={(e) =>
                                                setResizingMarginTop(
                                                  e.target.value
                                                )
                                              }
                                              autoComplete="off"
                                            />
                                          </li>
                                          <li>
                                            <Form.Control
                                              type="text"
                                              placeholder="B"
                                              autoFocus
                                              onChange={(e) =>
                                                setResizingMarginBottom(
                                                  e.target.value
                                                )
                                              }
                                              autoComplete="off"
                                            />
                                          </li>
                                          <li>
                                            <Form.Control
                                              type="text"
                                              placeholder="L"
                                              autoFocus
                                              onChange={(e) =>
                                                setResizingMarginLeft(
                                                  e.target.value
                                                )
                                              }
                                              autoComplete="off"
                                            />
                                          </li>
                                          <li>
                                            <Form.Control
                                              type="text"
                                              placeholder="R"
                                              autoFocus
                                              onChange={(e) =>
                                                setResizingMarginRight(
                                                  e.target.value
                                                )
                                              }
                                              autoComplete="off"
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col sm={6}></Col>
                                </Row>
                              </div>
                            </Form.Group> */}
                          </Row>
                      )}
                    </Form.Group>
                  );
                })}
                {/* Form groups for output specifications */}
                <div className="sub-head">Output Specifications</div>
                <Form.Group className="mg-bottom15">
										<Row className="form-group  flex-grow-1" sm={9}>
											<Col sm={1}></Col>
											<Form.Label className="col-sm-4 form-label form-check-label">
												File Format
											</Form.Label>
										</Row>
								</Form.Group>
                <Form.Group style={{ margin: "2px" }}>
										<div className="form-group">
											<Row className="align-items-center">
                        <Form.Label className="col-sm-1"></Form.Label>
												<Col sm={6} className="pl-2">
													<Select
														options={file}
														onChange={(e) => setImageFileFormat(e.label)}
													/>
												</Col>
											</Row>
										</div>
								</Form.Group>
                <Form.Group className="mg-bottom15">
										<Row className="form-group  flex-grow-1" sm={9}>
											<Col sm={1}></Col>
											<Form.Label className="col-sm-4 form-label form-check-label">
												Size
											</Form.Label>
										</Row>
								</Form.Group>
                <Form.Group style={{ margin: "2px" }}>
                  <div className="form-group">
                    <Row className="align-items-center">
                      <Form.Label className="col-sm-1"></Form.Label>
                      <Col sm={10} style={{ marginLeft: "-3px" }}>
                        <div className="d-sm-flex align-items-center col-gap-10">
                          <div className="d-sm-flex col-gap-10 align-items-center">
                              <Form.Control
                                type="text"
                                placeholder="2000"
                                autoFocus
                                onChange={(e) => setImageHeight(e.target.value)}
                                autoComplete="off"
                              />
                          </div>
                          <div className="d-sm-flex align-items-center">
                              <img
																style={{ width: "18px" }}
																src={cross}
																alt="mindreach images"
															/>
                          </div>
                          <div className="d-sm-flex align-items-center col-gap-10">
                            <Form.Control
                              type="text"
                              placeholder="2000"
                              autoFocus
                              onChange={(e) => setImageWidth(e.target.value)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
                <Form.Group className="mg-bottom15">
										<Row className="form-group  flex-grow-1" sm={9}>
											<Col sm={1}></Col>
											<Form.Label className="col-sm-4 form-label form-check-label">
												DPI
											</Form.Label>
										</Row>
								</Form.Group>
                <Form.Group  style={{ margin: "2px" }}>
                  <div className="form-group">
                    <Row className="align-items-center">
                      <Form.Label className="col-sm-1"></Form.Label>
                      <Col sm={10}>
                        <div className="d-sm-flex col-gap-20">
                          <Form.Control
                            type="text"
                            placeholder="2000"
                            autoFocus
                            onChange={(e) => setImageDpi(e.target.value)}
                            autoComplete="off"
                            className="w-50"
                          />

                          <div className="flex-fill">
                            <Select
                              options={dpi}
                              onChange={(e) => setImageDpiType(e.label)}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
                <Form.Group className="mg-bottom15">
										<Row className="form-group  flex-grow-1" sm={9}>
											<Col sm={1}></Col>
											<Form.Label className="col-sm-4 form-label form-check-label">
												Color Mode
											</Form.Label>
										</Row>
								</Form.Group>

                <Form.Group style={{ margin: "2px" }}>
                  <div className="form-group">
                    <Row className="align-items-center">
                      <Form.Label className="col-sm-1"></Form.Label>
                      <Col sm={9}>
                        <Select
                          options={ColorMode}
                          onChange={(e) => setImageColorMode(e.label)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
                <Form.Group className="mg-bottom15">
										<Row className="form-group  flex-grow-1" sm={9}>
											<Col sm={1}></Col>
											<Form.Label className="col-sm-4 form-label form-check-label">
												Align
											</Form.Label>
										</Row>
								 </Form.Group>
                <Form.Group style={{ margin: "2px" }}>
                  <div className="form-group">
                    <Row className="align-items-center">
                      <Form.Label className="col-sm-1"></Form.Label>
                      <Col sm={9}>
                        <Select
                          options={Align}
                          onChange={(e) => setImageAlignment(e.label)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
                <Form.Group className="mg-bottom15">
										<Row className="form-group  flex-grow-1" sm={9}>
											<Col sm={1}></Col>
											<Form.Label className="col-sm-4 form-label form-check-label">
												Margins
											</Form.Label>
										</Row>
								</Form.Group>
                <Form.Group style={{ margin: "2px" }}>
                  <div className="form-group">
                    <Row className="align-items-center">
                      <Form.Label className="col-sm-1"></Form.Label>
                      <Col sm={9}>
                        <Select
                          options={Margin}
                          onChange={(e) => setResizingMarginFormat(e.label)}
                        />
                      </Col>
                      <Col
                        sm={7}
                        lg={7}
                        style={{
                          display: "flex",
                          marginLeft: "1.9rem",
                        }}
                      >
                        <div className="d-sm-flex col-gap-20 mt-2 ">
                          <div className="d-flex">
                            <ul className="margin-list d-flex">
                              <li>
                                <Form.Control
                                  type="text"
                                  placeholder="T"
                                  autoFocus
                                  onChange={(e) =>
                                    setImageMarginTop(e.target.value)
                                  }
                                  autoComplete="off"
                                />
                              </li>
                              <li>
                                <Form.Control
                                  type="text"
                                  placeholder="B"
                                  autoFocus
                                  onChange={(e) =>
                                    setImageMarginBottom(e.target.value)
                                  }
                                  autoComplete="off"
                                />
                              </li>
                              <li>
                                <Form.Control
                                  type="text"
                                  placeholder="L"
                                  autoFocus
                                  onChange={(e) =>
                                    setImageMarginLeft(e.target.value)
                                  }
                                  autoComplete="off"
                                />
                              </li>
                              <li>
                                <Form.Control
                                  type="text"
                                  placeholder="R"
                                  autoFocus
                                  onChange={(e) =>
                                    setImageMarginRight(e.target.value)
                                  }
                                  autoComplete="off"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Col>

                      <Col sm={6}></Col>
                    </Row>
                  </div>
                </Form.Group>
                <Form.Group style={{ margin: "2px" }}>
                  <div className="form-group">
                    <Row className="align-items-center">
                      <div className="text-center">
                        <Button
                          variant=""
                          className="puple btns ml-6"
                          style={{ width: "auto" }}
                          onClick={() => handleUpdateEditGroup()}
                        >
                          <span>Overwrite Existing</span>
                        </Button>
                        <Button
                          variant="primary ml-2"
                          className="btns"
                          onClick={() => handleUpdateImageEditGroup()}
                        >
                          {loader ? (
                            <div>
                              <Loader />
                              Loading
                            </div>
                          ) : (
                            "Add To"
                          )}
                        </Button>
                      </div>
                      <Col sm={6}></Col>
                    </Row>
                  </div>
                </Form.Group>
              </div>
            ) : (
              <div className="sidebar-select--editType vh-100 editSettings">
             {((view_edit_group !== null &&
										view_edit_group !== undefined &&
										view_edit_group.edit_group) ||
										(view_edit_group !== undefined &&
											view_edit_group.edit_group === null &&
											setting_profile)) && (
                      <>
                        {view_edit_group !== null &&
                          view_edit_group !== undefined &&
                          view_edit_group.edit_group !== null &&
                          view_edit_group.edit_group !== undefined && view_edit_group.edit_group && 
                          view_edit_group.edit_group.edit_types !== null &&
                          view_edit_group.edit_group.edit_types !== undefined && (
                            <Form.Group>
                              <Row className="form-group  flex-grow-1" sm={9}>
                                <Form.Label className="col-sm-5"></Form.Label>
                                <Form.Label
                                  className="col-sm-5 text-right"
                                  for={"editFields"}
                                  style={{ cursor: "pointer" }}
                                >
                                  Customize Settings
                                </Form.Label>
                                <Col
                                  sm={1}
                                  className="float-right"
                                  style={{ marginLeft: "-7px", marginTop: "1px" }}
                                >
                                  <input
                                    type="checkbox"
                                    id={"editFields"}
                                    onChange={(e) =>
                                      toggleFieldEditing(e.target.checked)
                                    }
                                    checked={customizedSettings}
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                          )}
                        <div className="sub-head">Edit Type</div>
                      </>
                    )}
                {view_edit_group !== undefined &&
                  view_edit_group.edit_group !== undefined && view_edit_group.edit_group  &&
                  view_edit_group.edit_group.edit_types !== undefined &&
                  editTypes.map((item, index) => {
                    return (
                      <>
                        <Form.Group>
                          {" "}
                          <Row className="form-group  flex-grow-1" sm={9}>
                            <Col sm={1}>
                              <input
                                type="checkbox"
                                value={item.id}
                                onChange={(e) => {
                                  if (customizedSettings) {
                                    handleBackGroundRemovalCheckbox(
                                      item.id,
                                      e.target.checked,
                                      "Edit"
                                    );
                                    handleAddCheckboxSelect(e);
                                  }
                                }}
                                checked={
                                  item.id === 1
                                  ? selected
                                  : item.id === 2
                                  ? selected1
                                  : item.id === 3
                                  ? selected2
                                  : selected3
                                }
																id={`et_${item.id}`}
                                className="et_options"
																disabled={checkisEditableField()}
                              />
                            </Col>
                            <Form.Label
                              className="col-sm-4 form-label form-check-label"
                              style={{ width: "auto" ,marginTop:"2.1px", cursor: "pointer" }}
															for={`et_${item.id}`}
                            >
                              {item.edit_type}
                            </Form.Label>
                          </Row>
                          {updateChecked[index] === 1 && selected ? (
                            <Row
                              className="align-items-center"
                              style={{
                                // marginLeft: "44px",
                                marginTop: "-2px",
                                marginBottom: "20px",
                                width: "100%",
                              }}
                            >
                              <Form.Label className="col-sm-1"></Form.Label>
                              <Col sm={5}>
                                <Select
                                  options={selectBackgrondRemoval}
                                  onChange={(e) =>
                                    setBackgroundRemovalColorType(e.label)
                                  }
                                  value={{
                                    label: background_removal_color_type, // Set the label property to the desired value
                                    value: background_removal_color_type, // Set the value property to the corresponding value
                                  }}
                                  isDisabled={checkisEditableField()}
                                />
                              </Col>
                              <Col sm={4}>
                                <Form.Control
                                  type="text"
                                  placeholder="#ffff"
                                  autoFocus
                                  onChange={(e) =>
                                    setBackgroundRemovalColorCode(
                                      e.target.value
                                    )
                                  }
                                  value={background_removal_color_code}
                                  disabled={checkisEditableField()}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {updateChecked[index] === 2 && selected1 ? (
                            <Row
                              className="align-items-center"
                              style={{
                                // marginLeft: "44px",
                                marginTop: "-2px",
                                marginBottom: "20px",
                                width: "100%",
                              }}
                            >
                              <Form.Label className="col-sm-1"></Form.Label>
                              <Col sm={5}>
                                <Select
                                  options={selectBackgrondRemoval}
                                  onChange={(e) =>
                                    setBackgroundAdjustmentColorType(e.label)
                                  }
                                  value={{
                                    label: background_adjustment_color_type, // Set the label property to the desired value
                                    value: background_adjustment_color_type, // Set the value property to the corresponding value
                                  }}
																	isDisabled={checkisEditableField()}
                                />
                              </Col>
                              <Col sm={4}>
                                <Form.Control
                                  type="text"
                                  placeholder="#ffff"
                                  autoFocus
                                  onChange={(e) =>
                                    setBackgroundAdjustmentColorCode(
                                      e.target.value
                                    )
                                  }
                                  value={background_adjustment_color_code}
																	disabled={checkisEditableField()}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {updateChecked[index] === 3 && selected2 ? (
                            <Row
                              className="align-items-center"
                              style={{
                                // marginLeft: "40px",
                                marginTop: "-2px",
                                marginBottom: "20px",
                                width: "100%",
                              }}
                            >
				                     {/* We might require this code again in future keep it commented  */}
                             {/* <Form.Group className="mg-bottom15">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																			File Format
																		</Form.Label>
																	</Row>
															</Form.Group>
                              <Form.Group
                                style={{ margin: "2px" }}
                              >
                                <div className="form-group">
                                  <Row className="align-items-center">
                                    <Form.Label className="col-sm-1"></Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        options={file}
                                        // onChange={(e) =>
                                        // 	setResizingFileFormat(e.label)
                                        // }
                                        // defaultValue={{
                                        // 	label: `${resizing_file_format}`,
                                        // 	id: "21",
                                        // }}
                                        value={{
                                          label: resizing_file_format, // Set the label property to the desired value
                                          value: resizing_file_format, // Set the value property to the corresponding value
                                        }}
																				isDisabled={checkisEditableField()}
                                       />
                                    </Col>
                                  </Row>
                                </div>
                              </Form.Group> */}

                              <Form.Group  style={{ margin: "2px" }}>
                                <div className="form-group">
                                  <Row className="align-items-center">
                                    <Form.Label className="col-sm-1"></Form.Label>
                                    <Col sm={9}>
                                      <div className="d-sm-flex align-items-center col-gap-10">
                                        <div className="d-sm-flex col-gap-10 align-items-center">
                                          <Form.Control
                                            type="text"
                                            placeholder="Height"
                                            autoFocus
                                            onChange={(e) =>
                                              setResizingHeight(e.target.value)
                                            }
                                            value={resizing_height}
                                            disabled={checkisEditableField()}
                                          />
                                        </div>
                                        <div className="d-sm-flex align-items-center">
                                          <img
																							style={{ width: "18px" }}
																							src={cross}
																							alt="mindreach images"
																					/>
                                        </div>
                                        <div className="d-sm-flex align-items-center col-gap-10">
                                          <Form.Control
                                            type="text"
                                            placeholder="Width"
                                            autoFocus
                                            onChange={(e) =>
                                              setResizingWidth(e.target.value)
                                            }
                                            value={resizing_width}
                                            disabled={checkisEditableField()}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Form.Group>
				                     {/* We might require this code again in future keep it commented  */}
                              {/* <Form.Group className="mg-bottom15">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																			DPI
																		</Form.Label>
																	</Row>
															</Form.Group>
                              <Form.Group style={{ margin: "2px" }}>
                                <div className="form-group">
                                  <Row className="align-items-center">
                                    <Form.Label className="col-sm-1"></Form.Label>
                                    <Col sm={9}>
                                      <div className="d-sm-flex col-gap-20">
                                        <div className="w-75">
                                          <Form.Control
                                            type="text"
                                            placeholder="2000"
                                            autoFocus
                                            onChange={(e) =>
                                              setResizingDpi(e.target.value)
                                            }
                                            value={resizing_dpi}
																						disabled={checkisEditableField()}
                                          />
                                        </div>
                                        <div className="flex-fill w-75">
                                          <Select
                                            options={dpi}
                                            onChange={(e) =>
                                              setResizingDpiType(e.label)
                                            }
                                            value={{
                                              label: resizing_dpi_type, // Set the label property to the desired value
                                              value: resizing_dpi_type, // Set the value property to the corresponding value
                                            }}
																						isDisabled={checkisEditableField()}
                                         />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Form.Group>
                              <Form.Group className="mg-bottom15">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																			Color Mode
																		</Form.Label>
																	</Row>
															</Form.Group>              
                              <Form.Group
                                style={{ margin: "2px" }}
                              >
                                <div className="form-group">
                                  <Row className="align-items-center">
                                    <Form.Label className="col-sm-1"></Form.Label>
                                    <Col sm={9}>
                                      <Select
                                        options={ColorMode}
                                        onChange={(e) =>
                                          setResizingColorMode(e.label)
                                        }
                                        value={{
                                          label: resizing_color_mode, // Set the label property to the desired value
                                          value: resizing_color_mode, // Set the value property to the corresponding value
                                        }}
																				isDisabled={checkisEditableField()}
                                     />
                                    </Col>
                                  </Row>
                                </div>
                              </Form.Group>
                              <Form.Group className="mg-bottom15">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label ml-1">
																			Align
																		</Form.Label>
																	</Row>
															</Form.Group>          
                              <Form.Group
                                style={{ margin: "2px" }}
                              >
                                <div className="form-group">
                                  <Row className="align-items-center">
                                    <Form.Label className="col-sm-1"></Form.Label>
                                    <Col sm={9}>
                                      <Select
                                        options={Align}
                                        onChange={(e) => setImageAlignment(e.label)}
                                        value={{
                                          label:  image_alignment, // Set the label property to the desired value
                                          value:  image_alignment, // Set the value property to the corresponding value
                                        }}
																				isDisabled={checkisEditableField()}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Form.Group>
                              <Form.Group className="mg-bottom15">
																	<Row
																		className="form-group  flex-grow-1"
																		sm={9}
																	>
																		<Col sm={1}></Col>
																		<Form.Label className="col-sm-4 form-label form-check-label ml-1">
                                       Margins
																		</Form.Label>
																	</Row>
															</Form.Group>           
                              <Form.Group style={{ margin: "2px" }}>
                                <div className="form-group">
                                  <Row className="align-items-center">
                                    <Form.Label className="col-sm-1"></Form.Label>
                                    <Col sm={9}>
                                      <Select
                                          options={Margin}
                                          onChange={(e) =>
                                            setImageMarginFormat(e.label)
                                          }
                                          value={{
                                            label: image_margin_format, // Set the label property to the desired value
                                            value: image_margin_format, // Set the value property to the corresponding value
                                          }}
                                          isDisabled={checkisEditableField()}
                                        />
                                    </Col>
                                    <Col
                                      sm={7}
                                      lg={7}
                                      style={{
                                        display: "flex",
                                        marginLeft: "28px",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <div className="d-sm-flex col-gap-20 mt-2 ">
                                        <div className="d-flex">
                                          <ul className="margin-list d-flex">
                                            <li className={`${
                                                checkisEditableField()
                                                  ? "disabled"
                                                  : ""
                                              }`}>
                                              <Form.Control
                                                type="text"
                                                placeholder="T"
                                                autoFocus
                                                onChange={(e) =>
                                                  setResizingMarginTop(
                                                    e.target.value
                                                  )
                                                }
                                                value={image_margin_top}
                                                disabled={checkisEditableField()}
                                              />
                                            </li>
                                            <li className={`${
                                                checkisEditableField()
                                                  ? "disabled"
                                                  : ""
                                              }`}>
                                              <Form.Control
                                                type="text"
                                                placeholder="B"
                                                autoFocus
                                                onChange={(e) =>
                                                  setResizingMarginBottom(
                                                    e.target.value
                                                  )
                                                }
                                                value={image_margin_bottom}
                                                disabled={checkisEditableField()}
                                              />
                                            </li>
                                            <li className={`${
                                                checkisEditableField()
                                                  ? "disabled"
                                                  : ""
                                              }`}>
                                              <Form.Control
                                                type="text"
                                                placeholder="L"
                                                autoFocus
                                                onChange={(e) =>
                                                  setImageMarginLeft(
                                                    e.target.value
                                                  )
                                                }
                                                value={  image_margin_left }
                                                disabled={checkisEditableField()}
                                              />
                                            </li>
                                            <li className={`${
                                                checkisEditableField()
                                                  ? "disabled"
                                                  : ""
                                              }`}>
                                              <Form.Control
                                                type="text"
                                                placeholder="R"
                                                autoFocus
                                                onChange={(e) =>
                                                  setImageMarginRight(
                                                    e.target.value
                                                  )
                                                }
                                                value={image_margin_right}
                                                disabled={checkisEditableField()}
                                              />
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm={6}></Col>
                                  </Row>
                                </div>
														  </Form.Group> */}
                            </Row>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </>
                    );
                  })}
                {/* Form groups for output specifications */}

                {view_edit_group !== undefined &&
                  view_edit_group.edit_group !== undefined && view_edit_group.edit_group && 
                  view_edit_group.edit_group.resizing_settings !== undefined &&
                  view_edit_group.edit_group.resizing_settings.map(
                    (items, index) => {
                      return (
                        <>
                          <div className="sub-head">Output Specifications</div>
                          <Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	File Format
																</Form.Label>
															</Row>
													</Form.Group>
                          <Form.Group  style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-1"></Form.Label>
                                <Col sm={6} className="pl-2">
                                   <Select
																			options={file}
																			value={{
                                        label:
                                          image_file_format !== ""
                                            ? image_file_format
                                            : items.file_format, // Set the label property to the desired value
                                        value:
                                          image_file_format !== ""
                                            ? image_file_format
                                            : items.file_format, // Set the value property to the corresponding value
                                      }}
																			isDisabled={checkisEditableField()}
																	 />
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>
                          <Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Size
																</Form.Label>
															</Row>
													</Form.Group>            
                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-1"></Form.Label>
                                <Col sm={9}>
                                  <div className="d-sm-flex align-items-center col-gap-10">
                                    <div className="d-sm-flex col-gap-10 align-items-center">
                                        <Form.Control
																					type="text"
																					placeholder="Height"
                                          onChange={(e) =>
                                            setImageHeight(e.target.value)
                                          }
																					disabled={checkisEditableField()}
																					value={
                                            image_height !== ""
                                              ? image_height
                                              : items.height
                                          }
																				/>
                                    </div>
                                    <div className="d-sm-flex align-items-center">
                                       <img
                                          style={{ width: "18px" }}
                                          src={cross}
                                          alt="mindreach images"
																				/>
                                    </div>
                                    <div className="d-sm-flex align-items-center col-gap-10">
                                        <Form.Control
																					type="text"
																					placeholder="Width"
                                          onChange={(e) =>
                                            setImageWidth(e.target.value)
                                          }
																					disabled={checkisEditableField()}
																					value={
                                            image_width !== ""
                                              ? image_width
                                              : items.width
                                          }
																				/>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>
                          <Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	DPI
																</Form.Label>
															</Row>
													</Form.Group>
                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-1"></Form.Label>
                                <Col sm={10}>
                                  <div className="d-sm-flex col-gap-20">
                                      <Form.Control
																				type="text"
																				placeholder="2000"
																				autoComplete="off"
																				className="w-50"
																				value={
                                          image_dpi !== "" ? image_dpi : items.dpi
                                        }
                                        onChange={(e) =>
                                          setImageDpi(e.target.value)
                                        }
																				disabled={checkisEditableField()}
																			/>
                                    <div className="flex-fill">
                                        <Select
																					options={dpi}
                                          onChange={(e) =>
                                            setImageDpiType(e.label)
                                          }
																					value={{
																						label: image_dpi_type !== ""
																							? image_dpi_type
																							: items.dpi_type, // Set the label property to the desired value
																						value: image_dpi_type !== ""
																							? image_dpi_type
																							: items.dpi_type, // Set the value property to the corresponding value
																					}}
																					isDisabled={checkisEditableField()}
																				/>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>
                          <Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Color Mode
																</Form.Label>
															</Row>
													</Form.Group>                 
                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-1"></Form.Label>
                                <Col sm={9}>
                                    <Select
																			options={ColorMode}
																			onChange={(e) => setImageColorMode(e.label)}
																	  	value={{
																			label:
																				image_color_mode !== ""
																					? image_color_mode
																					: items.color_mode, // Set the label property to the desired value
																			value:
																				image_color_mode !== ""
																					? image_color_mode
																					: items.color_mode, // Set the value property to the corresponding value
																		}}
																			isDisabled={checkisEditableField()}
																		/>
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>
                          <Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Align
																</Form.Label>
															</Row>
													</Form.Group>
                          <Form.Group style={{ margin: "2px" }}>
                            <div className="form-group">
                              <Row className="align-items-center">
                                <Form.Label className="col-sm-1"></Form.Label>
                                <Col sm={9}>
                                    <Select
																			options={Align}
																	  	onChange={(e) => setImageAlignment(e.label)}
																			value={{
																				label: image_alignment !== ""
																					? image_alignment
																					: items.alignment, // Set the label property to the desired value
																				value: image_alignment !== ""
																					? image_alignment
																					: items.alignment, // Set the value property to the corresponding value
																			}}
																			isDisabled={checkisEditableField()}
																		/>
                                </Col>
                              </Row>
                            </div>
                          </Form.Group>
                          <Form.Group className="mg-bottom15">
															<Row className="form-group  flex-grow-1" sm={9}>
																<Col sm={1}></Col>
																<Form.Label className="col-sm-4 form-label form-check-label">
																	Margins
																</Form.Label>
															</Row>
														</Form.Group>
														<Form.Group style={{ margin: "2px" }}>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-1"></Form.Label>
																	<Col sm={9}>
																		<Select
																			options={Margin}
																			onChange={(e) =>
                                        setImageMarginFormat(e.label)
                                      }
                                      value={{
                                        label:
                                          image_margin_format !== ""
                                            ? image_margin_format
                                            : items.margin_format, // Set the label property to the desired value
                                        value:
                                          image_margin_format !== ""
                                            ? image_margin_format
                                            : items.margin_format, // Set the value property to the corresponding value
                                      }}
																			isDisabled={checkisEditableField()}
																		/>
																	</Col>
																	<Col
																		sm={7}
																		lg={7}
																		style={{
																			display: "flex",
																			marginLeft: "29px",
																			marginTop: "12px",
																		}}
																	>
																		<div className="d-sm-flex col-gap-20 mt-2 ">
																			<div className="d-flex">
																				<ul className="margin-list d-flex">
																					<li className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}>
																						<Form.Control
																							type="text"
																							placeholder="T"
																							value={image_margin_top !== ""
                                              ? image_margin_top
                                              : items.margin_top}
																							disabled={checkisEditableField()}
                                              onChange={(e) =>
                                                setImageMarginTop(e.target.value)
                                              }
																						/>
																					</li>
																					<li className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}>
																						<Form.Control
																							type="text"
																							placeholder="B"
																							value={image_margin_bottom !== ""
                                              ? image_margin_bottom
                                              : items.margin_bottom}
																							disabled={checkisEditableField()}
                                              onChange={(e) =>
                                                setImageMarginBottom(
                                                  e.target.value
                                                )
                                              }
																						/>
																					</li>
																					<li className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}>
																						<Form.Control
																							type="text"
																							placeholder="L"
																							value={image_margin_left !== ""
                                              ? image_margin_left
                                              : items.margin_left}
																							disabled={checkisEditableField()}
                                              onChange={(e) =>
                                                setImageMarginLeft(e.target.value)
                                              }
																						/>
																					</li>
																					<li className={`${
																							checkisEditableField()
																								? "disabled"
																								: ""
																						}`}>
																						<Form.Control
																							type="text"
																							placeholder="R"
																							value={image_margin_right !== ""
                                              ? image_margin_right
                                              : items.margin_right}
																							disabled={checkisEditableField()}
                                              onChange={(e) =>
                                                setImageMarginRight(
                                                  e.target.value
                                                )
                                              }
																						/>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</Col>
																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
                        </>
                      );
                    }
                  )}
                  {
                    (edit_select > 0 || customizedSettings) && (
                        <>
                         <div className="text-center margin-18">
                          <Button
                            variant=""
                            className="puple btns ml-6"
                            style={{ width: "auto" }}
                            onClick={() => handleUpdateEditGroup()}
                          >
                            <span>Overwrite Existing</span>
                          </Button>
                          <Button
                            variant="primary"
                            className="btns ml-2"
                            onClick={() => handleUpdateImageEditGroup()}
                          >
                            {loader ? (
                              <div>
                                <Loader />
                                Loading
                              </div>
                            ) : (
                              "Add To"
                            )}
                          </Button>
                        </div>
                        <Col sm={6}></Col>
                        </>
                    )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RightSidebarMultiSelect;
