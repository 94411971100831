import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination, Form, Row, Col, Table, Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// Importing components and images
import Select from "react-select";

import Loader from "../../../components/Loader";
import NoDataFound from "../../../components/NoDataFound";

import EditModal from "./Components/Modal/Edit";
import EditTypeModal from "./Components/Modal";

import user from "./../../../assets/Images/calender-shifts.svg";
import plus from "./../../../assets/Images/plus.svg";
import tickIcons from "./../../../assets/Images/tick-icon.svg";

import Delete from "./../../../assets/Images/delete.svg";
import duplicate from "./../../../assets/Images/actionsIcons/Duplicate _Final.svg";
import CancelIcon from "./../../../assets/Images/actionsIcons/cancel_Final.svg";
import MoreIcon from "./../../../assets/Images/actionsIcons/more-Final.svg";
//Importing Status Modal
import MyVerticallyCenteredModal from "../../../components/Modal";
import DeleteModalConfirmation from "../../../components/Modal/DeleteModal";
import ActiveModalConfirmation from "../../../components/Modal/ActivateModal";
import DeactiveModalConfirmation from "../../../components/Modal/DeactivateModal";
import DuplicateModalConfirmation from "../../../components/Modal/DuplicateModal";
// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import {
	EDIT_TYPES_LISTS,
	DELETE_EDIT_TYPE_BY_ID,
	DELETE_EDIT_TYPE_BULK,
	ACTIVATE_BULK_EDIT_TYPE,
	DEACTIVATE_BULK_EDIT_TYPE,
	DUPLICATE_BULK_EDIT_TYPE,
} from "../../../config/apiRoutes.api";

const isForEnterprices = [
	{ label: "All", value: "All" },
	{ label: "Yes", value: "1" },
	{ label: "No", value: "0" },
];

const EditTypesLists = () => {
	// Retrieve the token from sessionStorage
	const token = sessionStorage.getItem("token");

	// Create a request instance with the token
	const request = client(token);

	// Create a new FormData instance
	const bodyFormData = new FormData();

	//Declarations of state variables
	const [editTypeData, setEditTypeData] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [is_for_enterprise, setIsForEnterprice] = useState("");
	const [search, setSearch] = useState("");
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowId, setSelectedRowsId] = useState([]);
	const [selectedRowName, setSelectedRowsName] = useState([]);
	const [show, setShow] = useState(false);
	const [deleteShowByID, setDeleteShowBYID] = useState(false);
	const [editType_id, setEditTypeId] = useState("");
	const [editType, setEditType] = useState("");
	const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);
	const [selectAllRow, setSelectAllRows] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [rows, setRows] = useState([]);

	const [dropDown, setDropDown] = useState(false);

	//Edit Type Modal
	const [edit_type_modal, setEditTypeModal] = useState(false);
	const [edit_type_activate_modal, setActivateModal] = useState(false);
	const [edit_type_activate_modal_single, setActivateModalSingle] =
		useState(false);
	const [edit_type_deactivate_modal, setDeactivate] = useState(false);
	const [edit_type_deactivate_modal_single, setDeactivateModalSingle] =
		useState(false);
	const [edit_type_duplicate, setDuplicate] = useState(false);
	const [edit_type_duplicate_All, setDuplicateAll] = useState(false);
	const [modalShow, setModalShow] = React.useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [status, setStatus] = useState("");
	const [modalShowEdit, setModalShowEdit] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [edit_id, setEditId] = useState("");

	const selectLabel = (isForEnterprices) =>
		isForEnterprices.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of is for entrerprice array as label and value return a new array of object like [{value:"",label:""}]

	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

	useEffect(() => {
		if (token) {
			request
				.get(apiURL + EDIT_TYPES_LISTS + `?page=${pageNumber}`)
				.then((response) => {
					setEditTypeData(response.data);
					setRows(response.data[0].editTypes.data);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumber]);
	useEffect(() => {
		if (token && search !== "" && is_for_enterprise !== "") {
			request
				.get(
					apiURL +
						EDIT_TYPES_LISTS +
						`?is_for_enterprise=${is_for_enterprise}` +
						`&search_key=${search}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					setEditTypeData(response.data);
					setRows(response.data[0].editTypes.data);
				})
				.catch((err) => console.log(err));
		}
	}, [search, is_for_enterprise, pageNumber]);
	useEffect(() => {
		if (token && is_for_enterprise !== "") {
			request
				.get(
					apiURL +
						EDIT_TYPES_LISTS +
						`?is_for_enterprise=${is_for_enterprise}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					setEditTypeData(response.data);
					setRows(response.data[0].editTypes.data);
				})
				.catch((err) => console.log(err));
		}
	}, [is_for_enterprise, pageNumber]);
	useEffect(() => {
		if (token && is_for_enterprise === "All") {
			request
				.get(apiURL + EDIT_TYPES_LISTS + `?page=${pageNumber}`)
				.then((response) => {
					setEditTypeData(response.data);
					setRows(response.data[0].editTypes.data);
				})
				.catch((err) => console.log(err));
		}
	}, [is_for_enterprise, pageNumber]);

	useEffect(() => {
		if (token && search !== "") {
			request
				.get(
					apiURL +
						EDIT_TYPES_LISTS +
						`?search_key=${search}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					setEditTypeData(response.data);
					setRows(response.data[0].editTypes.data);
				})
				.catch((err) => console.log(err));
		}
	}, [search, pageNumber]);

	//Paginations
	// Component for rendering pagination items
	const PaginationItems = () => {
		const { total, per_page, current_page } = editTypeData[0].editTypes;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};
	// Component for handling pagination next button
	const NextPagination = () => {
		// Increment the pageNumber if it's not the last page
		if (
			pageNumber !==
			Math.ceil(
				editTypeData[0].editTypes.total / editTypeData[0].editTypes.per_page
			)
		) {
			setPageNumber(pageNumber + 1);
		}
	};
	// Component for handling pagination previous button
	const PreviousPagination = () => {
		// Decrement the pageNumber if it's not the first page
		if (
			pageNumber !==
			Math.ceil(
				editTypeData[0].editTypes.total / editTypeData[0].editTypes.per_page
			)
		) {
			setPageNumber(pageNumber - 1);
		}
		//  else {
		return setPageNumber(pageNumber - 1);
		// }
	};
	/**
	 * Handles the selection of an individual row.
	 * @param {Event} e - The event object triggered by the checkbox selection.
	 * @param {Object} row - The row object representing the selected row.
	 */
	const handleSelectRow = React.useCallback(
		(e, row) => {
			if (e.target.checked) {
				console.log("e.target.checked", e.target.checked, row);
				// Add the row ID and edit type to the selectedRows and selectedRowsId arrays
				setSelectedRows((prevSelectedRows) => [
					...prevSelectedRows,
					row.encrypted_id,
				]);
				setSelectedRowsId((prevSelectedRows) => [
					...prevSelectedRows,
					row.edit_type,
				]);
			} else {
				// Remove the row ID and edit type from the selectedRows and selectedRowsId arrays
				setSelectedRows((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.encrypted_id
					)
				);
				setSelectedRowsId((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.edit_type
					)
				);
			}
		},
		[selectedRows]
	);
	/**
	 * Handles the selection of all rows.
	 * @param {Event} e - The event object triggered by the checkbox selection.
	 */
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			setSelectAll(true);
			const updatedRows = editTypeData[0].editTypes.data.map((row) => {
				return { ...row, selected: true };
			});
			setRows(updatedRows);
			const updatedRowsIds = editTypeData[0].editTypes.data.map((row) => {
				return row.encrypted_id;
			});
			const updatedRowsName = editTypeData[0].editTypes.data.map((row) => {
				return row.edit_type;
			});
			setSelectAllRows((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsIds,
			]);
			setSelectedRowsName((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsName,
			]);
		} else {
			setSelectAll(false);
			const updatedRows = editTypeData[0].editTypes.data.map((row) => {
				return { ...row, selected: false };
			});
			setRows(updatedRows);
			const updatedRowsIds = editTypeData[0].editTypes.data.map((row) => {
				return row.encrypted_id;
			});
			setSelectAllRows([]);
		}
	};
	/**
      1.Clears the selection of all rows.
    */
	const handleClearSelectAll = () => {
		setSelectAll(false);
		const updatedRows = editTypeData[0].editTypes.data.map((row) => {
			return { ...row, selected: false };
		});
		setRows(updatedRows);
		const updatedRowsIds = editTypeData[0].editTypes.data.map((row) => {
			return row.encrypted_id;
		});
		setSelectAllRows([]);
	};
	/**
		1.Handles the bulk deletion of selected rows.
	*/
	const handleBulkDelete = () => {
		if (token) {
			bodyFormData.append("edit_type_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + DELETE_EDIT_TYPE_BULK, bodyFormData)
				.then((response) => {
					console.log(response);
					setShow(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk activation of selected rows.
	*/
	const handleBulkActivate = () => {
		if (token) {
			bodyFormData.append("edit_type_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + ACTIVATE_BULK_EDIT_TYPE, bodyFormData)
				.then((response) => {
					console.log(response);
					setActivateModal(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk activation of all rows.
	*/
	const handleBulkActivateAll = () => {
		if (token) {
			bodyFormData.append("edit_type_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + ACTIVATE_BULK_EDIT_TYPE, bodyFormData)
				.then((response) => {
					console.log(response);
					setShowModalDeleteAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk deactivation of edit types.
		2.If the token is available, it appends the edit_type_ids to the bodyFormData,
		3.sends a POST request to the specified API endpoint for deactivating bulk edit types,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/

	const handleBulkDeactivate = () => {
		if (token) {
			bodyFormData.append("edit_type_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + DEACTIVATE_BULK_EDIT_TYPE, bodyFormData)
				.then((response) => {
					console.log(response);
					setDeactivateModalSingle(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk deactivation of all edit types.
		2.If the token is available, it appends the edit_type_ids to the bodyFormData,
		sends a POST request to the specified API endpoint for deactivating bulk edit types,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/
	const handleBulkDeactivateAll = () => {
		if (token) {
			bodyFormData.append("edit_type_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + DEACTIVATE_BULK_EDIT_TYPE, bodyFormData)
				.then((response) => {
					console.log(response);
					setDeactivate(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk deletion of all edit types.
		2.If the token is available, it appends the edit_type_ids to the bodyFormData,
		sends a POST request to the specified API endpoint for deleting bulk edit types,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/
	const handleBulkDeleteAll = () => {
		if (token) {
			bodyFormData.append("edit_type_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + DELETE_EDIT_TYPE_BULK, bodyFormData)
				.then((response) => {
					console.log(response);
					setShowModalDeleteAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk duplicate of edit types.
		2.If the token is available, it appends the edit_type_ids to the bodyFormData,
		3.sends a POST request to the specified API endpoint for deactivating bulk edit types,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/
	const handleBulkDuplicate = () => {
		if (token) {
			bodyFormData.append("edit_type_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + DUPLICATE_BULK_EDIT_TYPE, bodyFormData)
				.then((response) => {
					console.log(response);
					setDuplicate(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk duplicate of all edit types.
		2.If the token is available, it appends the edit_type_ids to the bodyFormData,
		sends a POST request to the specified API endpoint for duplicate bulk edit types,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/
	const handleBulkDuplicateAll = () => {
		if (token) {
			bodyFormData.append("edit_type_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + DUPLICATE_BULK_EDIT_TYPE, bodyFormData)
				.then((response) => {
					console.log(response);
					setDuplicateAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Sets the show flag to true.
		@param {function} setShow - The function to set the show flag.
	*/
	const handleBulkConfirmation = (setShow) => {
		setShow(true);
	};
	/**
		1.Deletes a row by ID.
		@param {number} id - The ID of the row to be deleted.
	*/
	const handleDelete = (id) => {
		if (token !== null) {
			request
				.get(apiURL + DELETE_EDIT_TYPE_BY_ID + `/${id}`)
				.then((response) => {
					console.log(response);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		Sets the deleteShowBYID flag to true and updates the editType and editTypeId.
		@param {number} id - The ID of the row to be deleted.
		@param {string} name - The name of the row to be deleted.
	*/
	const handleDeleteConfirmation = (id, name) => {
		setDeleteShowBYID(true);
		setEditType(name);
		setEditTypeId(id);
	};

	if (!editTypeData.length) return <Loader />;
	else {
		return (
			<>
				<div className="rightbar pr-11">
					<div className="section-title ">
						<h2>Edit Types</h2>
						{/* {error !== "" && <Alert variant={"danger"} messages={error} />} */}
					</div>
					<Row></Row>

					<div className="d-sm-flex flex-sm-wrap col-gap-30-sm mb-3 ">
						<div className="customer-search">
							<Form>
								{selectedRows.length !== 0 && (
									<Row className="align-items-center">
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select">
													<>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Delete")}
														>
															<div
																onClick={() => handleBulkConfirmation(setShow)}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</OverlayTrigger>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",

																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																}}
																onClick={() =>
																	handleBulkConfirmation(setDuplicate)
																}
																className="img-fluid--icons"
															>
																<img src={duplicate} alt="mindreach images" />
															</div>
														</OverlayTrigger>

														<div class="dropdown">
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(
																			setActivateModalSingle
																		)
																	}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(
																			setDeactivateModalSingle
																		)
																	}
																>
																	Make as Inactive
																</a>
															</div>
														</div>

														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 224px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{selectedRows.length} Edit Type(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 386px",
																cursor: "pointer",
																position: "absolute",

																padding: "6px 6px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={() => setSelectedRows([])}
														>
															<img src={CancelIcon} alt="mindreach images" />
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{selectAllRow.length !== 0 && (
									<Row className="align-items-center">
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select">
													<>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Delete")}
														>
															<div
																onClick={() =>
																	handleBulkConfirmation(setShowModalDeleteAll)
																}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</OverlayTrigger>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",

																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																}}
																className="img-fluid--icons"
																onClick={() =>
																	handleBulkConfirmation(setDuplicateAll)
																}
															>
																<img src={duplicate} alt="mindreach images" />
															</div>
														</OverlayTrigger>

														{/* Dropdown*/}

														<div class="dropdown">
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setActivateModal)
																	}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setDeactivate)
																	}
																>
																	Make as Inactive
																</a>
															</div>
														</div>

														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 227px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{rows.length} Edit Type(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 386px",
																cursor: "pointer",
																position: "absolute",

																padding: "6px 9px 9px 8px",
															}}
															className="img-fluid--icons"
															onClick={handleClearSelectAll}
														>
															<img src={CancelIcon} alt="mindreach images" />
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}

								{!selectedRows.length && !selectAllRow.length && (
									<Row className="align-items-center">
										<Col lg={2} sm={2} md={2}>
											<div className="form-group">
												<Select
													onChange={(e) => setIsForEnterprice(e.value)}
													options={selectLabel(isForEnterprices)}
													placeholder="Promoted"
													isSearchable
												/>
											</div>
										</Col>
										<Col lg={3} sm={3} md={3}>
											<div className="form-group">
												<Form.Control
													type="search"
													name="search"
													placeholder="Search"
													aria-label="Search"
													className="me-2 search-icon"
													onChange={(e) => setSearch(e.target.value)}
												/>
											</div>
										</Col>
										<Col lg={2} sm={2} md={2}>
											<div className="form-group">
												<p className="customer-count">
													<span>
														<img
															src={user}
															className="img-fluid"
															alt="mindreach images"
														/>
													</span>
													{editTypeData[0].editTypes.total} Edit Type(s)
												</p>
											</div>
										</Col>
									</Row>
								)}
							</Form>
						</div>
						<div>
							<Button
								variant="primary"
								onClick={() => {
									setEditTypeModal(true);
								}}
								// onClick={() => navigate(EDIT_TYPE_ADD_PAGE)}
							>
								<span>
									<img src={plus} className="img-fluid mr-2" alt="plus icon" />
								</span>
								New Edit Type
							</Button>{" "}
						</div>
					</div>

					{editTypeData !== undefined ? (
						<>
							<div
								className={
									`table-responsive table-b custom-new-table` +
									`  ` +
									`${
										selectedRows.length || selectAllRow.length
											? `mt-5--table`
											: ""
									}`
								}
							>
								<Table hover>
									{editTypeData[0].editTypes.data !== undefined && (
										<thead>
											<tr>
												<th>
													{!selectedRows.length && (
														<input
															type="checkbox"
															checked={selectAll}
															onClick={handleSelectAll}
															// className="checkbox-all-elements--table "
														/>
													)}
												</th>
												<th>Name</th>
												<th>Description</th>
												<th>Price</th>
												<th>Promoted Service</th>
												<th></th>
											</tr>
										</thead>
									)}
									<tbody>
										{editTypeData[0].editTypes.data !== undefined ? (
											rows.map((item) => {
												const list = (
													<>
														<tr>
															<td className="checkbox-elements--table">
																<input
																	type="checkbox"
																	// className="checkbox_round"
																	checked={
																		selectedRows.length
																			? selectedRows.find(
																					(selectedRow) =>
																						selectedRow.id === item
																			  )
																			: item.selected === true
																	}
																	onChange={(e) => handleSelectRow(e, item)}
																/>
															</td>
															<td
																onClick={() => {
																	setEditModal(true);
																	// console.log("clicked",item.encrypted_id)
																	setEditId(item.encrypted_id);
																}}
															>
																{item.edit_type}
															</td>
															<td
																onClick={() => {
																	setEditModal(true);
																	setEditId(item.encrypted_id);
																}}
															>
																{item.description}
															</td>
															<td
																onClick={() => {
																	setEditModal(true);
																	setEditId(item.encrypted_id);
																}}
															>
																${item.price}
															</td>
															<td
																onClick={() => {
																	setEditModal(true);
																	setEditId(item.encrypted_id);
																}}
															>
																{item.is_for_enterprise == "0" ? (
																	""
																) : (
																	<div style={{ marginLeft: " 14%" }}>
																		<img
																			src={tickIcons}
																			alt="mindreach images"
																		/>
																	</div>
																)}
															</td>
															<td className="edittd">
																<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
																	{item.is_static === "1" ? (
																		""
																	) : (
																		<div
																			className=""
																			onClick={() =>
																				handleDeleteConfirmation(
																					item.encrypted_id,
																					item.edit_type
																				)
																			}
																		>
																			<img
																				src={Delete}
																				className="img-fluid"
																				alt="Delete"
																			/>
																		</div>
																	)}
																</div>
															</td>
														</tr>
													</>
												);
												return list;
											})
										) : (
											<NoDataFound />
										)}
									</tbody>
								</Table>
							</div>
							<Row className="mt-4">
								<Col
									md={{ span: 4, offset: 8 }}
									className="flex flex-md-row-reverse "
								>
									<Pagination>
										<Pagination.Prev onClick={() => PreviousPagination()} />
										{PaginationItems()}
										<Pagination.Next onClick={() => NextPagination()} />
									</Pagination>
								</Col>
							</Row>
						</>
					) : (
						<Loader />
					)}
				</div>
				<DeleteModalConfirmation
					show={deleteShowByID}
					onHide={() => {
						setDeleteShowBYID(false);
					}}
					branch={"editType"}
					name={editType}
					title={"Delete Edit Type"}
					handleConfirm={() => handleDelete(editType_id)}
				/>
				<DeleteModalConfirmation
					show={show}
					onHide={() => {
						setShow(false);
					}}
					branch={"editType"}
					name={selectedRowId.toString()}
					title={"Delete Edit Type"}
					handleConfirm={() => handleBulkDelete()}
				/>
				<DeleteModalConfirmation
					show={showModalDeleteAll}
					onHide={() => {
						setShowModalDeleteAll(false);
					}}
					branch={"branch"}
					name={selectedRowName.toString()}
					title={"Delete branch"}
					handleConfirm={() => handleBulkDeleteAll()}
				/>
				<EditTypeModal
					show={edit_type_modal}
					onHide={() => {
						setEditTypeModal(false);
					}}
					heading={"Add Standard Edit Type"}
					setEditTypeModal={setEditTypeModal}
					setStatus={setStatus}
					setModalShow={setModalShow}
					setSubmitStatusMessage={setSubmitStatusMessage}
					setModalShowEdit={setModalShowEdit}
				/>
				<EditModal
					show={editModal}
					onHide={() => {
						setEditModal(false);
					}}
					heading={"Edit Standard Edit Type"}
					setEditTypeModal={setEditModal}
					setStatus={setStatus}
					setModalShow={setModalShow}
					setSubmitStatusMessage={setSubmitStatusMessage}
					setModalShowEdit={setModalShowEdit}
					edit_id={edit_id}
				/>
				<MyVerticallyCenteredModal
					show={modalShowEdit}
					onHide={() => {
						setModalShowEdit(false);
						window.location.reload();
					}}
					heading={status}
					message={submitStatusMessage}
					className="modal-body-body--success"
				/>
				<ActiveModalConfirmation
					show={edit_type_activate_modal_single}
					onHide={() => {
						setActivateModalSingle(false);
					}}
					branch={"editType"}
					name={selectedRowId.toString()}
					title={"Activate Edit Type"}
					handleConfirm={() => handleBulkActivate()}
				/>
				<ActiveModalConfirmation
					show={edit_type_activate_modal}
					onHide={() => {
						setActivateModal(false);
					}}
					branch={"editType"}
					name={selectedRowName.toString()}
					title={"Activate Edit Type"}
					handleConfirm={() => handleBulkActivateAll()}
				/>
				<DeactiveModalConfirmation
					show={edit_type_deactivate_modal_single}
					onHide={() => {
						setDeactivateModalSingle(false);
					}}
					branch={"editType"}
					name={selectedRowId.toString()}
					title={"Activate Edit Type"}
					handleConfirm={() => handleBulkDeactivate()}
				/>
				<DeactiveModalConfirmation
					show={edit_type_deactivate_modal}
					onHide={() => {
						setDeactivate(false);
					}}
					branch={"editType"}
					name={selectedRowName.toString()}
					title={"Activate Edit Type"}
					handleConfirm={() => handleBulkDeactivateAll()}
				/>
				<DuplicateModalConfirmation
					show={edit_type_duplicate}
					onHide={() => {
						setDuplicate(false);
					}}
					branch={"editType"}
					name={selectedRowId.toString()}
					title={"Duplicate Edit Type"}
					handleConfirm={() => handleBulkDuplicate()}
				/>
				<DuplicateModalConfirmation
					show={edit_type_duplicate_All}
					onHide={() => {
						setDuplicateAll(false);
					}}
					branch={"editType"}
					name={selectedRowName.toString()}
					title={"Duplicate Edit Type"}
					handleConfirm={() => handleBulkDuplicateAll()}
				/>
			</>
		);
	}
};
export default EditTypesLists;
