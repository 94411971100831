import React, { useState } from "react";
import Loader from "./../../components/Loader";
import folder from "../../assets/Images/folder.svg";
import folderblue from "../../assets/Images/folder-blue.svg";
// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
	GET_ALL_COORDINATORS,
	ADMIN_GET_COORDINATORS_DETAILS,
} from "../../config/apiRoutes.api";

const RightSidebar = ({
	folders,
	id,
	setImages,
	mainImageCount,
	mainImageSettingsCount,
	setItemList,
	imagesMainFolder,
	folderMainFolder,
	// setFolderListOrder,
	// folder,
	setFolder,
	setParentFolderDetails,
	// folder_files_sub,
	// setFolderListSub,
	list_display,
	setDisplay,
	setFolderList,
	folder_list,
	folder_name,
	setFolderName,
	folder_list_menu,
	setFolderListMenu,
	loader_sub_menu,
	setLoaderSubMenu,
	clickSelect,
	setClickSelect,
	hide_list_menu,
	setHideListMenu,
	batchId,
	token,
	selectOptions,
	list_sub_display,
	setDisplaySub,
	// folder_list_menu
}) => {
	// console.log("folders", folder_list);
	// Get the user token
	// let token = getUserInfo();

	// Create an HTTP client with the token
	const request = client(token);
	// const [list_display, setDisplay] = useState(false);

	const [folder_files_sub, setFolderListSub] = useState([]);
	const [total_folder_set, setFolderTotalSet] = useState([]);

	// const [folder_selectName, setFolderName] = useState("");
	const [folder_selectNameSub, setFolderNameSub] = useState("");
	const [error, setError] = useState("");
	console.log("imagesMainFolder", imagesMainFolder);
	const handleChangeMainFolder = () => {
		console.log("clicked the main folder");

		setImages(imagesMainFolder);
		setFolderList(folderMainFolder);
		setParentFolderDetails(null);
		setFolderName("");
		setFolderListMenu([]);
		setClickSelect(false);
		setHideListMenu(false);
		setDisplaySub(false);
	};
	const handleChangeFolder = (item, batchId) => {
		console.log("folder called", item);
		// setFolderName(item);
		setDisplaySub(false);
		setDisplay(true);
		setLoaderSubMenu(true);
		// setHideListMenu((val) => !val);
		if (!!!hide_list_menu) {
			if (token) {
				request
					.get(
						apiURL +
							ADMIN_GET_COORDINATORS_DETAILS +
							`/${selectOptions}` +
							`?batch_number=${batchId}` +
							`&folder_path=${item}`
					)
					.then((response) => {
						console.log("folder child", response.data.data);
						// setFolderList(folderMainFolder);
						// setFolderList(response.data.data.folders);
						setFolderListMenu(response.data.data.folders);
						// setParentFolderDetails(null);
						// setFolderName("");
						setDisplaySub(false);
						// setFolderListMenu([]);
						setClickSelect(false);

						// setImages(response.data.data.images);
						// setParentFolderDetails(null);
						// setFolderName("");
						// setFolderListMenu([]);
						// setClickSelect(false);

						setLoaderSubMenu(false);
						setClickSelect(false);

						setHideListMenu(true);
					})
					.catch((err) => {
						let message =
							typeof err.response !== "undefined"
								? err.response.data.message
								: err.message;
						setError(message);
					});
			} else {
				if (token) {
					request
						.get(
							apiURL +
								ADMIN_GET_COORDINATORS_DETAILS +
								`/${selectOptions}` +
								`?batch_number=${batchId}` +
								`&folder_path=${item}`
						)
						.then((response) => {
							console.log("folder child", response.data.data);
							// setFolderList(folderMainFolder);
							setFolderList(response.data.data.folders);
							setFolderListMenu(response.data.data.folders);
							setParentFolderDetails(response.data.data.folders);
							setFolderName("");
							// setDisplaySub(false);
							// setFolderListMenu([]);
							setClickSelect(false);
							setDisplaySub(true);

							// setImages(response.data.data.images);
							// setParentFolderDetails(null);
							// setFolderName("");
							// setFolderListMenu([]);
							// setClickSelect(false);

							setLoaderSubMenu(false);
							setClickSelect(false);
							// setHideListMenu(true);
						})
						.catch((err) => {
							let message =
								typeof err.response !== "undefined"
									? err.response.data.message
									: err.message;
							setError(message);
						});
				}
			}
		}
	};
	const handleChange = (item) => {
		console.log("folder called", item);
		setFolderName(item);
		setDisplay(true);
		setLoaderSubMenu(true);

		if (token) {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${selectOptions}` +
						`?batch_number=${batchId}` +
						`&folder_path=${item}`
				)
				.then((response) => {
					console.log("folder child", response.data.data);
					setFolderList(response.data.data.folders);

					setFolderListMenu(response.data.data.folders);

					setImages(response.data.data.images);
					setDisplaySub(true);

					setLoaderSubMenu(false);
					setClickSelect(false);
					// setHideListMenu(true);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleChangeSubFolder = (items, item) => {
		console.log("folder sub", items);
		//
		// setDisplaySub(true);
		setLoaderSubMenu(true);
		if (token) {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${selectOptions}` +
						`?batch_number=${batchId}` +
						`&folder_path=${item}/${items}`
				)
				.then((response) => {
					console.log("response", response);
					setFolderList(response.data.data.folders);

					setImages(response.data.data.images);
					setFolderName(items);

					setFolderTotalSet([folder_list, folder_list_menu]);
					setLoaderSubMenu(false);
					setClickSelect(false);
					setDisplaySub(true);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	// Helper function to check if a folder is selected
	const isFolderSelected = (folderPath, folder_name) =>
		folderPath === folder_name;
	// Define a separate function to handle the list item click\
	// useEffect(() => {
	// 	if (folder_name !== "") {
	// 		setFolderName("MainFolder")
	// 	}
	// }, [folder_name]);
	return (
		<>
			<div className="editororderdetails">
				<ul className="treeviewlist custom-viewlist">
					<li>
						<li
							onClick={handleChangeMainFolder}
							className={folder_name === "" ? "order-folder--hover" : ""}
						>
							<i className="fa fa-angle-down" aria-hidden="true"></i>
							<span>
								<img
									src={folder_name === "" ? folderblue : folder}
									className="img-fluid"
									alt="mindreach images"
								/>
							</span>
							<span className="catgory-name">
								Main Folder
								<span className="order-folder--count">
									({mainImageSettingsCount}/{mainImageCount})
								</span>
							</span>
						</li>
						{folders.map((item) => (
							<ul className="first-treeview">
								<li
									className={
										(folders.length !== 0 || folder_selectNameSub !== "") &&
										isFolderSelected(item.path, folder_name)
											? "order-folder--hover" // Apply the class only when the dropdown is open
											: ""
									}
								>
									<i
										onClick={() => {
											setHideListMenu((val) => !val);
											// setDisplaySub((val) => !val);
											handleChangeFolder(item.path, batchId);
										}}
										className={
											hide_list_menu ? "fa fa-angle-down" : "fa fa-angle-right"
										}
										aria-hidden="true"
									></i>
									<span
										onClick={() => {
											handleChange(item.path);
										}}
									>
										<img
											src={
												(folders.length !== 0 || folder_selectNameSub !== "") &&
												isFolderSelected(item.path, folder_name)
													? folderblue
													: folder
											}
											className="img-fluid"
											alt="mindreach images"
										/>
									</span>
									<span
										className="catgory-name"
										onClick={() => {
											handleChange(item.path);
										}}
									>
										{item.path}
										<span className="order-folder--count">
											({item.settings_count}/{item.image_count})
										</span>
									</span>
								</li>

								{hide_list_menu &&
									folder_list_menu.map((items) => {
										return (
											<>
												{item.path !== items.path && (
													<ul className="second-treeview">
														<li
															className={
																(folder_list_menu.length !== 0 ||
																	folder_selectNameSub !== "") &&
																isFolderSelected(items.path, folder_name)
																	? "order-folder--hover"
																	: ""
															}
															onClick={() =>
																handleChangeSubFolder(items.path, item.path)
															}
														>
															<i
																className="fa fa-angle-right"
																aria-hidden="true"
															></i>
															<span>
																<img
																	src={
																		(folder_list_menu.length !== 0 ||
																			folder_selectNameSub !== "") &&
																		isFolderSelected(items.path, folder_name)
																			? folderblue
																			: folder
																	}
																	className="img-fluid"
																	alt="mindreach images"
																/>
															</span>
															<span className="catgory-name">
																{items.path}
																<span className="order-folder--count">
																	({items.settings_count}/{items.image_count})
																</span>
															</span>
														</li>
													</ul>
												)}
												{!!list_display &&
													items === folder_selectNameSub &&
													folder_files_sub.map((items_sub) => {
														return (
															<>
																<ul className="third-treeview">
																	<li>
																		<i
																			className="fa fa-angle-right"
																			aria-hidden="true"
																		></i>
																		<span>
																			<img
																				src={folder}
																				className="img-fluid"
																				alt="mindreach images"
																			/>
																		</span>
																		<span className="catgory-name">
																			{items_sub.path.path}
																			<span className="order-folder--count">
																				({items_sub.path.imageSettingsCount}/
																				{items_sub.path.imageCount})
																			</span>
																		</span>
																	</li>
																</ul>
															</>
														);
													})}
											</>
										);
									})}
							</ul>
						))}
					</li>
				</ul>
			</div>
		</>
	);
};

export default RightSidebar;
