import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	Button,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";

// Importing components and images
import Loader from "../../../components/Loader";
import DeleteModalConfirmation from "../../../components/Modal/DeleteModal";
import MyVerticallyCenteredModal from "../../../components/Modal";
import DuplicateModalConfirmation from "../../../components/Modal/DuplicateModal";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import plus from "../../../assets/Images/plus.svg";
import Delete from "../../../assets/Images/delete.svg";

import duplicate from "../../../assets/Images/actionsIcons/Duplicate _Final.svg";
import CancelIcon from "../../../assets/Images/actionsIcons/cancel_Final.svg";
import MoreIcon from "../../../assets/Images/actionsIcons/more-Final.svg";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import {
	ADMIN_GET_ENTERPRISE_FIELDS,
	DELETE_ENTERPRISE_FIELD,
	DELETE_BULK_ENTERPRISE_FIELD,
	DUPLICATE_BULK_ENTERPRISE_FIELD,
} from "../../../config/apiRoutes.api";
import {
	EDIT_ENTERPRISE_FIELD,
	ADD_ENTERPRISE_FIELD,
	PREVIEW_ENTERPRISE_FIELD,
} from "../../../config/routes";
import { clone } from "underscore";
const ViewEnterpriseFields = () => {
	// Retrieve the token from the session storage
	const token = sessionStorage.getItem("token");
	// Create a request instance using the token
	const request = client(token);
	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();
	// Create a new instance of FormData
	const bodyFormData = new FormData();
	//Declarations of state variable
	const [fieldsData, setEnterpriseFieldsData] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [show, setShow] = useState(false);
	const [multiSelectShow, setMultiSelectShow] = useState(false);

	const [fieldNameSeleted, setFieldNameSelected] = useState("");
	const [fieldNameSeletedId, setFieldNameSelectedId] = useState("");
	const [rows, setRows] = useState([]);
	const [deleteMessage, setDeleteMessage] = useState(false);
	const [deleteHeading, setDeleteHeading] = useState("Error");
	const [fieldDuplicate, setDuplicate] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);

	const [fieldMultipleNameSeleted, setMultipleFieldNameSelected] = useState("");
	const [fieldMultipleNameSeletedId, setMultipleFieldNameSelectedId] =
		useState("");

	/**
	 * Renders a Tooltip component with the specified text as its content.
	 * @param {string} text - The text to be displayed in the Tooltip.
	 * @returns {JSX.Element} The Tooltip component.
	 */
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

	/**
	 * Handles the confirmation for deleting a Field.
	 *
	 * @param {string} id - The ID of the fields to be deleted.
	 * @param {string} name - The name of the fields to be deleted.
	 */
	const handleDeleteConfirmation = (id, name) => {
		// Show the delete confirmation modal
		setShow(true);
		// Set the selected fields name and ID
		setFieldNameSelected(name);
		setFieldNameSelectedId(id);
	};

	/**
	 * Fetches the fields data from the API when the pageNumber changes or the token is available.
	 */
	useEffect(() => {
		// Check if a token is available
		if (token) {
			fetchFields();
		}
	}, [pageNumber]);

	/**
	 * Handles the confirmation for deleting a Field.
	 *
	 */
	const fetchFields = () => {
		setEnterpriseFieldsData([]);
		setSelectedRows([]);
		setMultipleFieldNameSelected("");
		setMultipleFieldNameSelectedId("");
		// Make an HTTP GET request to fetch the fields data with the specified page number
		request
			.get(apiURL + ADMIN_GET_ENTERPRISE_FIELDS + `?page=${pageNumber}`)
			.then((response) => {
				// Log the response to the console
				console.log(" response =>", response.data.formFields);

				// Update the fieldsData state with the response data
				setEnterpriseFieldsData(response.data);

				// Update the rows state with the fields data
				setRows(response.data.formFields.data);
			})
			.catch((err) => console.log(err));
	};

	/**
	 * Renders the pagination items for navigating between pages.
	 * It generates the pagination items based on the total number of fields and the active page.
	 * @returns {JSX.Element[]} Array of pagination items.
	 */
	const PaginationItems = () => {
		const { total, per_page, current_page } = fieldsData.formFields;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};

	/**
	 * Handles the pagination to the next page of fields
	 * It updates the page number state to the next page if it's not the last page.
	 * @returns {void}
	 */
	const NextPagination = () => {
		// Check if the current page number is not the last page
		if (
			pageNumber !==
			Math.ceil(fieldsData.formFields.total / fieldsData.formFields.per_page)
		) {
			// Update the page number state to the next page
			setPageNumber(pageNumber + 1);
		}
	};

	/**
	 * Handles the pagination to the previous page of fields
	 * It updates the page number state to the previous page if it's not the first page.
	 * @returns {void}
	 */
	const PreviousPagination = () => {
		// Check if the current page number is not the first page
		if (
			pageNumber !==
			Math.ceil(fieldsData.formFields.total / fieldsData.formFields.per_page)
		) {
			// Update the page number state to the previous page
			setPageNumber(pageNumber - 1);
		}
		return setPageNumber(pageNumber - 1);
	};

	/**
	 * Handles the deletion of a fields by ID.
	 * @param {string} id - The ID of the fields to be deleted.
	 */
	const handleDeleteField = (id) => {
		// Check if a token is available
		if (token) {
			// Make a GET request to delete the fields by ID
			request
				.get(apiURL + DELETE_ENTERPRISE_FIELD + `/${id}`)
				.then((response) => {
					console.log(" response.data =>", response.data);
					// Reset the states
					if (response.data.status === true) {
						setDeleteHeading("Success");
						if (pageNumber !== 1) {
							setPageNumber(1);
						} else {
							fetchFields();
						}
					} else {
						setDeleteHeading("Error");
					}
					setShow(false);
					setDeleteMessage(response.data.message);
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk deletion of the selected fields by ID.
	 * @param {string} id - The ID of the fields to be deleted.
	 */
	const handleBulkDeleteField = () => {
		// Check if a token is available
		if (token) {
			console.log("Deleted IDs =>", fieldMultipleNameSeletedId.join(","));
			// Make a GET request to delete the fields by ID
			request
				.get(
					apiURL +
						DELETE_BULK_ENTERPRISE_FIELD +
						`?field_ids=${fieldMultipleNameSeletedId.join(",")}`
				)
				.then((response) => {
					console.log(" response.data =>", response.data);
					// Reset the states
					if (response.data.status === true) {
						setDeleteHeading("Success");
						if (pageNumber !== 1) {
							setPageNumber(1);
						} else {
							fetchFields();
						}
					} else {
						setDeleteHeading("Error");
					}
					setMultiSelectShow(false);
					setDeleteMessage(response.data.message);
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles to hide the  for bulk actions.
	 *
	 * @param {function} setShow - The function to set the visibility of the confirmation modal.
	 */

	const reload = () => {
		setShow(false);
		setMultiSelectShow(false);
	};

	/**
	 * Handles the confirmation for bulk actions.
	 *
	 * @param {function} setShow - The function to set the visibility of the confirmation modal.
	 */
	const handleBulkConfirmation = (setShow) => {
		// Show the confirmation modal
		setShow(true);
	};

	/**
	 * Handles the function to retun ids.
	 * It handles and accepts object array and return ids,
	 *  * @param {Array} obj - Object array.
	 * @returns {Array}
	 */

	const getSelelectedIds = (obj) => {
		return obj.map((item) => {
			return item.id;
		});
	};

	/**
	 * Handles the function to retun name.
	 * It handles and accepts object array and return name,
	 *  * @param {Array} obj - Object array.
	 *  * @param {string} id - id of string.
	 * @returns {Array}
	 */

	const filteredNames = (obj, id) => {
		let ary = obj.map((item) => {
			if (item.encrypted_id != id && item) {
				return item.name;
			}
		});
		return ary.filter(function (element) {
			return element !== undefined;
		});
	};

	/**
	 * Handles the select all checkbox.
	 * It select all checkbox then it will select all the checkboxes.
	 * @returns {void}
	 */

	const handleSelectAll = (e) => {
		if (e.target.checked) {
			let ids = findIds(rows);
			let names = findNames(rows);
			setSelectedRows(ids);
		} else {
			setSelectedRows([]);
			setMultipleFieldNameSelected("");
		}
	};

	/**
	 * Handles the single checkbox check / uncheck event.
	 * It handles single checkbox check / uncheck event
	 *  * @param {e} e - element Object.
	 *  * @param {Array} row - current row clicked by user.
	 * @returns {void}
	 */

	const handleSelectRow = (e, row) => {
		if (e.target.checked) {
			let tempSelectedRows = [...selectedRows];
			tempSelectedRows.push({
				id: row.encrypted_id,
				name: row.label,
			});
			let tempSelectedNames = [...selectedRows];
			tempSelectedNames.push(row.label);
			setSelectedRows(tempSelectedRows);
		} else {
			setSelectedRows((prevSelectedRows) =>
				prevSelectedRows.filter(
					(selectedRow) => selectedRow.id !== row.encrypted_id
				)
			);
		}
	};

	useEffect(() => {
		console.log("Updated selectedRows =>", selectedRows);
		if (selectedRows.length > 0) {
			let ids = findIds(selectedRows);
			let names = findNames(selectedRows);
			console.log("names ==>", names);
			setMultipleFieldNameSelected(
				names ? names.join(",").replaceAll(",,", ",") : ""
			);
			setMultipleFieldNameSelectedId(getSelelectedIds(selectedRows));
		} else {
			setMultipleFieldNameSelected("");
			setMultipleFieldNameSelectedId("");
		}
	}, [selectedRows]);

	/**
	 * Handles the function to retun id and name.
	 * It handles and accepts object array and return id and name,
	 *  * @param {Array} obj - Object array.
	 * @returns {array}
	 */

	const findIds = (obj) => {
		return obj.map((item) => {
			return {
				id: item.encrypted_id,
				name: item.label,
			};
		});
	};

	/**
	 * Handles the function to retun name.
	 * It handles and accepts object array and return name,
	 *  * @param {Array} obj - Object array.
	 * @returns {Array}
	 */

	const findNames = (obj) => {
		return obj.map((item) => {
			return item.label ? item.label : item.name;
		});
	};

	/**
	 * Return input label based field type.
	 * It return input label based field type.
	 * @param {String} input - Holds .
	 */

	const showInputLabel = (input) => {
		switch (input) {
			case "select":
				return "Dropdown";
			case "radio":
				return "Multiple Choice";
			case "checkbox":
				return "Checkbox";
			case "document":
				return "File Upload";
			default:
				return "Short Answer";
		}
	};

	/**
	 * Handles the bulk duplication of selected Field.
	 *
	 * This function is called when the bulk duplicate button is clicked.
	 * It performs the duplication of the selected Field by sending a POST request to the corresponding API endpoint.
	 * The Field IDs of the selected Field are included in the request payload as an array of strings.
	 * After the duplication is complete, the function logs the response, updates the state to reflect the duplication status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectedRows`, `request`, `apiURL`, `setDuplicate` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDuplicate = () => {
		if (token) {
			// Send a GET request to duplicate the selected Field
			request
				.get(
					apiURL +
						DUPLICATE_BULK_ENTERPRISE_FIELD +
						`?field_ids=${fieldMultipleNameSeletedId.join(",")}`
				)
				.then((response) => {
					// Log the response from the API
					console.log("Duplicate success response ===>", response);
					setDuplicate(false);
					if (response.data.status === true) {
						setDeleteHeading("Success");
						// Reload the state to show Duplicated data
						if (pageNumber !== 1) {
							setPageNumber(1);
						} else {
							fetchFields();
						}
					} else {
						setDeleteHeading("Error");
					}
					setDeleteMessage(response.data.message);
				})
				.catch((err) => console.log(err));
		}
	};

	console.log("fieldMultipleNameSeleted =>", fieldMultipleNameSeleted);
	console.log("fieldMultipleNameSeletedId =>", fieldMultipleNameSeletedId);
	if (!!fieldsData.length) return <Loader />;
	else {
		// Render the actual content once fieldsData is available
		return (
			<>
				<div>
					<div className="rightbar pr-11">
						<div className="section-title">
							<h2>Enterprise Signup Questions</h2>
						</div>
						<div
							className="flex-sm-wrap col-gap-30-sm mt-2-branch"
							style={{ height: "37px" }}
						>
							<div className="customer-search">
								{selectedRows.length > 0 && (
									<Row
										className="align-items-center "
										style={{ display: "inline-block" }}
									>
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select--customer">
													<>
														<div>
															<div
																onClick={() =>
																	handleBulkConfirmation(setMultiSelectShow)
																}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</div>

														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",
																	// margin: "9px",
																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																	// transition:
																	// 	"background-color 0.2s ease-in-out",
																}}
																className="img-fluid--icons"
																onClick={() =>
																	handleBulkConfirmation(setDuplicate)
																}
															>
																<img src={duplicate} alt="mindreach images" />
															</div>
														</OverlayTrigger>

														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 135px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{selectedRows.length} Field
																{selectedRows.length > 1 ? "s" : ""} Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 270px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 6px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={() => setSelectedRows([])}
														>
															<img src={CancelIcon} alt="mindreach images" />
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}

								<Button
									variant="primary"
									onClick={() => navigate(ADD_ENTERPRISE_FIELD)}
									className="pull-right"
									style={{ margin: "0px 9px  0px 0px" }}
								>
									&nbsp;&nbsp;
									<span>
										<img src={plus} className="img-fluid mr-2" />
									</span>
									New &nbsp;&nbsp;
								</Button>

								<Button
									variant="primary"
									onClick={() => navigate(PREVIEW_ENTERPRISE_FIELD)}
									className="pull-right"
									style={{ margin: "0px 9px  0px 0px" }}
								>
									<span></span>
									&nbsp;&nbsp; Preview &nbsp;&nbsp;
								</Button>
							</div>
							<div></div>
						</div>

						{fieldsData.formFields !== undefined ? (
							<>
								<div
									className={`table-responsive table-b custom-new-table `}
									style={{ marginTop: "3rem !important" }}
								>
									<Table hover>
										<thead>
											<tr>
												<th>
													<input
														type="checkbox"
														style={{ cursor: "pointer" }}
														checked={
															selectedRows.length === rows.length &&
															selectedRows.length > 0
														}
														onClick={handleSelectAll}
													/>
												</th>
												<th>Questions</th>
												<th>Type</th>
												<th>Required</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{fieldsData.formFields !== undefined &&
												rows.map((item) => {
													const list = (
														<>
															<tr key={`field_${item.id}`}>
																<td className="checkbox-elements--table">
																	<input
																		type="checkbox"
																		name="check"
																		style={{ cursor: "pointer" }}
																		checked={
																			selectedRows.length
																				? selectedRows.find(
																						(selectedRow) =>
																							selectedRow.id ===
																							item.encrypted_id
																				  )
																				: false
																		}
																		onChange={(e) => handleSelectRow(e, item)}
																	/>
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_ENTERPRISE_FIELD}/${item.encrypted_id}`
																		)
																	}
																>
																	{item.label}
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_ENTERPRISE_FIELD}/${item.encrypted_id}`
																		)
																	}
																>
																	{showInputLabel(item.field_type)}
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_ENTERPRISE_FIELD}/${item.encrypted_id}`
																		)
																	}
																>
																	{Number(item.field_required) === 1
																		? "Yes"
																		: "No"}
																</td>
																<td className="edittd">
																	<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
																		<div
																			className=""
																			onClick={() =>
																				handleDeleteConfirmation(
																					item.encrypted_id,
																					item.label
																				)
																			}
																		>
																			<img
																				src={Delete}
																				className="img-fluid"
																				alt="Delete"
																				title="Delete"
																			/>
																		</div>
																		<div
																			className=""
																			onClick={() => {
																				setDuplicate(true);
																				setMultipleFieldNameSelected(
																					item.label
																				);
																				setMultipleFieldNameSelectedId([
																					item.encrypted_id,
																				]);
																			}}
																		>
																			<img
																				src={duplicate}
																				className="img-fluid"
																				alt="Duplicate"
																				title="Duplicate"
																			/>
																		</div>
																	</div>
																</td>
															</tr>
														</>
													);
													return list;
												})}
										</tbody>
									</Table>
								</div>

								<Row className="mt-4">
									<Col
										md={{ span: 4, offset: 8 }}
										className="flex flex-md-row-reverse "
									>
										<Pagination>
											<Pagination.Prev onClick={() => PreviousPagination()} />
											{PaginationItems()}
											<Pagination.Next onClick={() => NextPagination()} />
										</Pagination>
									</Col>
								</Row>
							</>
						) : (
							<Loader />
						)}
					</div>
				</div>
				<DeleteModalConfirmation
					show={show}
					onHide={() => {
						reload();
					}}
					branch={"field"}
					name={fieldNameSeleted}
					title={"Delete Field"}
					handleConfirm={() => handleDeleteField(fieldNameSeletedId)}
				/>

				<DeleteModalConfirmation
					show={multiSelectShow}
					onHide={() => {
						reload();
					}}
					branch={"field" + (selectedRows.length > 1 ? "s" : "")}
					name={fieldMultipleNameSeleted}
					title={"Delete Field" + (selectedRows.length > 1 ? "s" : "")}
					handleConfirm={() => handleBulkDeleteField()}
				/>

				<DuplicateModalConfirmation
					show={fieldDuplicate}
					onHide={() => {
						setDuplicate(false);
						// setMultipleFieldNameSelected("");
						// setMultipleFieldNameSelectedId("");
					}}
					branch={"field" + (selectedRows.length > 1 ? "s" : "")}
					name={fieldMultipleNameSeleted}
					title={"Duplicate Field" + (selectedRows.length > 1 ? "s" : "")}
					handleConfirm={() => handleBulkDuplicate()}
				/>

				<MyVerticallyCenteredModal
					show={deleteMessage ? true : false}
					onHide={() => {
						setDeleteMessage("");
						setFieldNameSelected("");
						setFieldNameSelectedId("");
					}}
					heading={deleteHeading}
					message={deleteMessage}
					className="modal-body-body--success"
				/>
			</>
		);
	}
};

export default ViewEnterpriseFields;
