import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// import workspace from '../../../css/workspace.css';
// import Qcworkspace from '../../../css/qc-workspace.css';
import Qcimageedit from '../../assets/Images/nike-logo.png';

const QcEditDetails = () => {
    return (
        <>
            <div className="qc-edit-canvas-img">
                <img src={Qcimageedit} alt="" className="img-fluid" alt="mindreach images" />
                <p>92834735.jpg</p>
                <a href="#">View Style Guide</a>
            </div>
            <div className="qc-edit-details">
                <h4>Edit Type</h4>
                <p className='pl-0'>Resizing</p>
                <p className='pl-0'>Cropping</p>
                <p className='pl-0'>Background Removal</p>
            </div> 
            <div className="qc-edit-details">
                <h4>Outout Specification</h4>
                <p><span>Format:</span> JPG</p>
                <p><span>Size:</span> Acpect Ratio 2:1</p>
                <p><span>DPI:</span> NA</p>
                <p><span>Color Mode:</span> Original</p>
                <p><span>Alignment:</span> Center</p>
                <p><span>Margins:</span> 5px 5px 5px 5px</p>
            </div>
            <div className="qc-comment-form mt-3">
                <h4>Comment</h4>
                <Form>
                    <Row>
                        <Col sm={12}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Comment"
                                className="me-2"
                            />
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mt-2">
                            <Button variant="primary" className="btns">Comment</Button>{' '}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className='qc-comment mt-3'>
                    <h4>Stephen Hodge <span className='pl-2'>3 Sep 2022</span></h4>
                    <p>Please do not forget to remove the dust at the bottom</p>
                </div>
            </div> 
        </>
    )
}

export default QcEditDetails