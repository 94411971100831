import React, { useState, useEffect, useRef, useCallback } from "react";

//Importing the bootstrap components
import { Button, Row, Col, Form, Tab, Tabs } from "react-bootstrap";
//Importing the react select components
import Select from "react-select";
import Marker from "./Marker";
import Loader from "../../../../../components/Loader";

//Importing the images

//Importing the images
import PreviousIcon from "./../../../../../assets/Images/coordinator_actions_icon/prev-icons.svg";
import EditImageOriginal from "./../../../../../assets/Images/coordinator_actions_icon/Edit_Original.svg";
import NextIcon from "./../../../../../assets/Images/coordinator_actions_icon/next-icons.svg";
import duplicate from "./../../../../../assets/Images/coordinator_actions_icon/Doc_Duplicate.svg";

import ChatIcons from "./../../../../../assets/Images/coordinator_actions_icon/Chat.svg";
import BlueChatIcon from "./../../../../../assets/Images/coordinator_actions_icon/BlueChatIcon.svg";

import CancelIcons from "../../../../../assets/Images/coordinator_actions_icon/cancel_image.svg";
import Send from "../../../../../assets/Images/coordinator_actions_icon/Send.svg";
import ArrowDown from "./../../../../../assets/Images/coordinator_actions_icon/White_Arrow_Down.svg";

// Importing API related files and routes

import { client } from "../../../../../data/API/GobalClientAxiosRequest";
import { imageURL, apiURL } from "../../../../../data/filepath";

import {
	CUSTOMER_IMAGES_EDIT_GROUP,
	CUSTOMER_VIEW_EDIT_GROUP,
	CUSTOMER_SEND_MESSAGES,
	CUSTOMER_COMMENTS_MESSAGES,
	ADMIN_GET_IMAGES_SETTINGS,
	CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID,
} from "../../../../../config/apiRoutes.api";

const Modal = ({
	showModal,
	setShowModal,
	image_details_view,
	setImageDetailsView,
	editGroups,
	editTypes,
	customer_id,
	setMultipleImagesId,
	selectImageName,
	token,
	id,
	idSelected,
	setClickSelect,
	setSelectAll,
	customer_status,
	images,
	modalRef,
	setting_profile,
	handleFetchProfileSettings,
	setFolderListName,
	setFolderSelect,
	setFoldersPath,
	setDuplicateImageModal,
	setDuplicateAmount,
	setAmount,
	setImageID,
	imageID,
}) => {
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);
	const modalRefImage = useRef(null);

	//Declarations of state
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State

	const [edit_select, setEditorType] = useState("");

	const [currentAnnotation, setCurrentAnnotation] = useState(null);
	const [annotations, setAnnotations] = useState([]);
	const [edit_type_toggle, setToggleEditType] = useState(false);
	//To update the select group
	const [group_name_select, setEditGroupNameSelect] = useState("");

	const [toggleMessage, setToggleMessage] = useState(false);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [renameModalShow, setRenameModalShow] = useState(false);
	const [renameTextDuplicate, setRenameTextDuplicate] = useState("");
	const [renameId, setRenameId] = useState("");
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [hoveredItems, setHoveredItems] = useState(false);
	const [hoveredItemId, setHoveredItemId] = useState(null);
	const [showCommentList, setCommentsList] = useState(false);
	const [annotationsComments, setAnnotationsComments] = useState([]);

	const [group, setGroup] = useState([]);
	const [message, setMessage] = useState("");
	const [comments, setComments] = useState([]);
	const [view_image_settings, setViewImageSettings] = useState([]);
	const [viewDuplicateSettingsById, setViewDuplicateSettings] = useState({});
	const [viewDuplicateModal, setViewDuplicateModal] = useState(false);
	const [duplicateImageId, setDuplicateImageId] = useState("");
	const [fileFormatDuplicate, setFileFormatDuplicate] = useState("");
	const [dpiTypeDuplicate, setDpiTypeDuplicate] = useState("");
	const [colorModeDuplicate, setColorModeDuplicate] = useState("");
	const [alignModeDuplicate, setAlignModeDuplicate] = useState("");
	const [marginFormatDuplicate, setMarginFormatDuplicate] = useState("");
	const [heightDuplicate, setHeightDuplicate] = useState("");
	const [widthDuplicate, setWidthDuplicate] = useState("");
	const [dpiDuplicate, setDpiDuplicate] = useState("");
	const [marginTopDuplicate, setMarginTopDuplicate] = useState("");
	const [marginBottomDuplicate, setMarginBottomDuplicate] = useState("");
	const [marginRightDuplicate, setMarginRightDuplicate] = useState("");
	const [marginLeftDuplicate, setMarginLeftDuplicate] = useState("");

	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];
	const selectBackgrondRemoval = [
		{ label: "Custom", value: 355 },
		{ label: "Transparent", value: 54 },
	];
	const selectedAmount = [
		{ label: "1", value: 355 },
		{ label: "2", value: 54 },
		{ label: "3", value: 54 },
	];
	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];
	const customStyles = {
		placeholder: (provided) => ({
			// Custom styles for the placeholder text
			...provided,
			color: "#212529", // Change the placeholder text color to red
		}),
	};
	function getSelectComponent(options, setState, file) {
		return (
			<Select
				options={file}
				placeholder={options}
				styles={customStyles}
				onChange={(e) => setState(e.label)}
				isDisabled={true}
			/>
		);
	}

	useEffect(() => {
		if (token && duplicateImageId) {
			request
				.get(
					apiURL +
						CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID +
						`/${duplicateImageId}`
				)
				.then((response) => {
					//Set View  for edit group from the response
					console.log("setting", response);
					setViewDuplicateSettings(response.data.setting);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	}, [duplicateImageId, setViewDuplicateSettings]);

	function LocalImageLoader({ src, width, quality }) {
		return <Loader />;
	}
	const onClickDuplicateImage = (id, imageName, imageExtensions) => {
		setRenameId(id);
		// setImageName(imageName);
		// setImageExtensions(imageExtensions);
		setRenameModalShow((val) => !val);
	};
	const svgCode = (count) => {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white"/>
		<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10ZM9 4.25V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H4.25L9 4.25Z" fill="white"/>
		<!-- Use a <style> element to set the font size -->
			<style>
			  text {
				font-size: 9px;
				font-weight:500;
			  }
			</style>
			<!-- Adjust the y attribute to add margin on the top -->
			<text x="6" y="18" fill="green">${count}</text>
	  </svg>`;
	};
	const handleMouseEnter = (itemId) => {
		// Set the ID of the item to be hovered
		setHoveredItemId(itemId);
	};

	const handleMouseLeave = () => {
		// Clear the hovered item when leaving the element
		setHoveredItemId(null);
	};

	function LocalImageLoader({ src, width, quality }) {
		return <Loader />;
	}

	const toggleModal = () => {
		setShowModal(!showModal);
	};
	const fetchComments = () => {
		if (token) {
			request
				.get(apiURL + CUSTOMER_COMMENTS_MESSAGES + `/${image_details_view.id}`)
				.then((response) => {
					//Set the success from the response

					setComments(response.data.data);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const fetchImageDetailsSettings = (id) => {
		if (token) {
			request
				.get(apiURL + ADMIN_GET_IMAGES_SETTINGS + `/${id}`)
				.then((response) => {
					//Set the success from the response
					setViewImageSettings(response.data);
					setLoader(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		if (image_details_view.id) {
			fetchImageDetailsSettings(image_details_view.id);
			fetchComments();
		}
	}, [image_details_view]);
	useEffect(() => {
		if (showModal) {
			setLoader(true);
		}
	}, [showModal]);

	//handled next item
	function getNextPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: -1;

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the beginning.
			startIndex = 0;
		} else {
			startIndex += 1;
		}

		const endIndex = startIndex + itemsPerPage;
		const nextPage = data.slice(startIndex, endIndex);
		// console.log(
		//   " startIndex ",
		//   startIndex,
		//   ", endIndex ",
		//   endIndex,
		//   ", nextPage ",
		//   nextPage
		// );

		// Determine the next ID or wrap around to the first ID if necessary.
		let nextPageId = null;

		if (nextPage.length > 0) {
			nextPageId = data[startIndex].id;
		} else if (data.length > 0) {
			nextPageId = data[0].id;
		}

		return { nextPage, nextPageId };
	}
	function getPreviousPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: -1;
		setCommentsList(false);

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the last item.
			startIndex = data.length - itemsPerPage;
		} else {
			if (startIndex < 1) {
				startIndex = 0;
			} else {
				startIndex -= 1;
			}
		}

		const endIndex = startIndex + itemsPerPage;
		const previousPage = data.slice(startIndex, endIndex);

		// Determine the previous ID or wrap around to the last ID if necessary.
		let previousPageId = null;

		if (previousPage.length > 0) {
			previousPageId = previousPage[0].id;
		} else if (data.length > 0) {
			previousPageId = data[startIndex].id;
		}

		return { previousPage, previousPageId };
	}

	function showNextPage() {
		const { nextPage, nextPageId } = getNextPage(
			images,
			imageID,
			images.length
		);
		setCommentsList(false);

		if (nextPage.length > 0) {
			// Display the nextPage data
			console.log(nextPage);

			// Update the current page ID
			setImageID(nextPageId);
			const filterImages =
				images !== undefined &&
				images.filter((obj) => obj.id === nextPageId)[0];
			setImageDetailsView(filterImages);
		} else {
			// No more data to show
			console.log("No more data to display.");
		}
	}
	function showPreviousPage() {
		// if (images && images.length > 0) {
		const { previousPage, previousPageId } = getPreviousPage(
			images,
			imageID,
			images.length
		);

		setCommentsList(false);

		if (previousPage.length > 0) {
			// Display the previousPage data
			console.log(previousPage);

			// Update the current page ID
			setImageID(previousPageId);
			const filterImages =
				images !== undefined &&
				images.filter((obj) => obj.id === previousPageId)[0];
			setImageDetailsView(filterImages);
		} else {
			// No more data to show
			console.log("No more data to display.");
		}
	}

	// Listen for the "keydown" event
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.keyCode === 27) {
				// Escape key is pressed
				// Update state variables here
				localStorage.setItem("clickSelect", false);
				setShowModal(false);
				setClickSelect(false);
				document.body.classList.remove("modal-open");
			}
		};

		// Add event listener when the component mounts
		window.addEventListener("keydown", handleKeyDown);

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [modalRefImage, images]); // Empty dependency array means this effect runs once, like componentDidMount
	// Function to close the modal
	const closeModal = useCallback(() => {
		// Update state variables here
		localStorage.setItem("clickSelect", false);
		document.body.classList.remove("modal-open");
		setShowModal(false);
		setClickSelect(false);
	}, [modalRefImage, images]);

	const handleOverlayClick = (e) => {
		if (e.target.classList.contains("modal-container--image")) {
			closeModal();
		} else if (e.target.classList.contains("close-icons-order--image")) {
			closeModal();
		}
	};
	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
		setViewDuplicateModal(false);
	};

	return (
		<div style={{ zIndex: 9999999999999 }}>
			{showModal && (
				<div className="modal-overlay--order">
					<>
						<div
							className="close-icons-order--image exclude-click"
							onClick={handleOverlayClick}
							ref={modalRefImage}
						>
							<div style={{ marginBottom: "10px" }}>
								<div
									className={`dropdown-duplicate-image--modal  ${
										isDropdownOpen ? "open" : ""
									}`}
								>
									<div
										className="dropdown-button--duplicate"
										onClick={toggleDropdown}
									>
										<span>
											<div
												dangerouslySetInnerHTML={{
													__html: svgCode(
														setting_profile !== null &&
															setting_profile !== undefined &&
															setting_profile.duplication_settings !==
																undefined &&
															setting_profile.duplication_settings !== null &&
															setting_profile.duplication_settings.length !==
																undefined
															? parseInt(
																	setting_profile.duplication_settings.length
																		.toString()
																		.substr(0, 2)
															  )
															: "0"
													),
												}}
												style={{ marginLeft: "8px" }}
											/>
										</span>
										<span className="order-text--outlined">
											{setting_profile.batch_number}
										</span>
										<span
											className="order-text--outlined"
											style={{ marginLeft: "42px", width: "auto" }}
										>
											{setting_profile.path}
										</span>
										<span style={{ marginLeft: "auto" }}>
											<div
												style={{ marginRight: "18px" }}
												onClick={() =>
													onClickDuplicateImage(
														setting_profile.id,
														setting_profile.path.substring(
															0,
															setting_profile.path.lastIndexOf(".")
														)
													)
												}
											></div>
										</span>

										<span style={{ marginRight: "18px" }}>
											{setting_profile !== undefined &&
												setting_profile.duplication_settings !== undefined &&
												setting_profile.duplication_settings.length !== 0 && (
													<img src={ArrowDown} alt="Arrow Down" />
												)}
										</span>
									</div>

									{isDropdownOpen &&
										setting_profile.duplication_settings.length !== 0 && (
											<div className="dropdown-content-duplicate-image--modal">
												{setting_profile !== undefined &&
													setting_profile.duplication_settings !== undefined &&
													setting_profile.duplication_settings.map(
														(_items, index) => {
															return (
																<span
																	key={_items.id}
																	onMouseEnter={() =>
																		handleMouseEnter(_items.id)
																	}
																	onMouseLeave={handleMouseLeave}
																	onClick={() => {
																		setDuplicateImageId(_items.id);
																		setViewDuplicateModal(true);
																		setDropdownOpen(false);
																	}}
																>
																	<h2
																		className="dropdown-duplicate-image--text"
																		onClick={() =>
																			onClickDuplicateImage(
																				_items.id,
																				_items.image_name.substring(
																					0,
																					_items.image_name.lastIndexOf(".")
																				)
																			)
																		}
																	>
																		{_items.image_name}
																	</h2>
																	<div className="d-flex"></div>
																</span>
															);
														}
													)}
											</div>
										)}
								</div>
							</div>

							<div
								className="modal-tab"
								onClick={() => {
									setShowModal(false);
									setCommentsList(false);
									setImageDetailsView({});
									document.body.classList.remove("modal-open");
								}}
							>
								<img
									src={CancelIcons}
									className="img-fluid close"
									alt="mindreach images"
								/>
							</div>
						</div>
						<div className="modal-container--image">
							<div
								className="modal-image-icons"
								onClick={() => {
									setToggleMessage((val) => !val);
									setCommentsList((val) => !val);
									if (!showCommentList) {
										setAnnotationsComments([]);
									}
								}}
								style={{ background: showCommentList ? "#f5f5f5" : "#fff" }}
							>
								<img
									src={showCommentList ? BlueChatIcon : ChatIcons}
									className="img-fluid image--comment"
									alt="mindreach images"
								/>
							</div>
							<div className="modal--image">
								<div className="prev--icons" onClick={() => showPreviousPage()}>
									<img
										src={PreviousIcon}
										className="img-fluid"
										alt="mindreach images"
									/>
								</div>
								<div
									// style={{
									//   width: "90%",
									//   marginBottom: "0px",
									//   height: "37rem",
									//   overflow: "scroll",
									//   // backgroundColor: "hsl(0, 0%, 90%)",
									// }}
									className="modal-container"
								>
									{image_details_view.image_path !== undefined ? (
										<Marker
											imageURL={`${imageURL}/${image_details_view.customer.email}/${image_details_view.batch_number}/${image_details_view.order.order_number}/images/${image_details_view.image_path}`}
											currentAnnotation={currentAnnotation}
											setCurrentAnnotation={setCurrentAnnotation}
											// handleTextChange={handleTextChange}
											annotations={annotations}
											setAnnotations={setAnnotations}
											setToggleMessage={setToggleMessage}
											toggleMessage={toggleMessage}
											idSelected={image_details_view.id}
											token={token}
											setCommentsList={setCommentsList}
											showCommentList={showCommentList}
											setAnnotationsComments={setAnnotationsComments}
											annotationsComments={annotationsComments}
										/>
									) : (
										<Loader />
									)}
								</div>
								<div className="next--icons" onClick={() => showNextPage()}>
									<img
										src={NextIcon}
										className="img-fluid"
										alt="mindreach images"
									/>
								</div>
							</div>
							<div className="modal-image--order">
								<div>
									<div className="sidebar-image--name d-flex">
										<div style={{ width: "100%", marginLeft: "23px" }}>
											<div
												className="d-flex"
												style={{
													// flexDirection: "row-reverse",
													marginBottom: "18px",
													marginTop: "16px",
												}}
											>
												<h2
													className="side-bar-single-select--h2"
													style={{ marginLeft: "83px" }}
												>
													{selectImageName}
												</h2>
												<div
													style={{ display: "flex", marginLeft: "auto" }}
													onClick={() => {
														setMultipleImagesId([]);
														setClickSelect(false);
													}}
												>
													<img
														src={CancelIcons}
														className="img-fluid"
														alt="mindreach images"
													/>
												</div>
											</div>
											{/* <div className="form-group edit-profile-setting-container--single">
												<Row
													className="align-items-center edit-setting--select "
													style={{ position: "absolute" }}
												>
													<Form.Label className="col-sm-12 settings-profile--text">
														Settings Profile
													</Form.Label>
												</Row>
											</div> */}
											<div className="form-group edit-profile-setting-container--single">
												<Row
													className="align-items-center edit-setting--select "
													style={{ position: "absolute", width: "28%" }}
												>
													<Form.Label className="col-sm-3 ">
														Settings Profile
													</Form.Label>

													<Col sm={9}>
														<Form.Label>
															{view_image_settings &&
															view_image_settings.image &&
															view_image_settings.image.edit_group ? (
																<Form.Control
																	type="text"
																	value={
																		view_image_settings.image.edit_group
																			.group_name
																	}
																	autoFocus
																	disabled
																	autoComplete="off"
																/>
															) : (
																<span>Group Name is not defined</span>
															)}
														</Form.Label>
													</Col>
												</Row>
											</div>

											{viewDuplicateModal ? (
												<div
													style={{
														// marginLeft: "5%",
														height: "29.5rem",
														overflow: "scroll",
														width: "100%",
													}}
												>
													{viewDuplicateSettingsById !== undefined && (
														<>
															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			File Format
																		</Form.Label>
																		<Col sm={6}>
																			{getSelectComponent(
																				viewDuplicateSettingsById.file_format,
																				setFileFormatDuplicate,
																				file
																			)}
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			Size
																		</Form.Label>
																		<Col sm={9}>
																			<div className="d-sm-flex align-items-center col-gap-10">
																				<div className="d-sm-flex col-gap-10 align-items-center">
																					<span className="text-secondary f-sz-13">
																						{" "}
																						H:{" "}
																					</span>
																					<div className="flex-col">
																						<Form.Control
																							type="text"
																							placeholder=""
																							autoFocus
																							onChange={(e) =>
																								setHeightDuplicate(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																							defaultValue={
																								viewDuplicateSettingsById.height
																							}
																							disabled={true}
																						/>
																					</div>
																				</div>
																				<div className="d-sm-flex align-items-center"></div>
																				<div className="d-sm-flex align-items-center col-gap-10">
																					<span className="text-secondary f-sz-13">
																						{" "}
																						W:{" "}
																					</span>
																					<div className="flex-col">
																						<Form.Control
																							type="text"
																							placeholder="2000"
																							autoFocus
																							onChange={(e) =>
																								setWidthDuplicate(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																							defaultValue={
																								viewDuplicateSettingsById.width
																							}
																							disabled={true}
																						/>
																					</div>
																				</div>
																			</div>
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			DPI
																		</Form.Label>
																		<Col sm={9}>
																			<div className="d-sm-flex col-gap-20">
																				<div className="">
																					<Form.Control
																						type="text"
																						placeholder="2000"
																						autoFocus
																						onChange={(e) =>
																							setDpiDuplicate(e.target.value)
																						}
																						autoComplete="off"
																						defaultValue={
																							viewDuplicateSettingsById.dpi
																						}
																						disabled={true}
																					/>
																				</div>
																				<div className="flex-fill">
																					{getSelectComponent(
																						viewDuplicateSettingsById.dpi_type,
																						setDpiTypeDuplicate,
																						dpi
																					)}
																				</div>
																			</div>
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			Color Mode
																		</Form.Label>
																		<Col sm={9}>
																			{getSelectComponent(
																				viewDuplicateSettingsById.color_mode,
																				setColorModeDuplicate,
																				ColorMode
																			)}
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			Align
																		</Form.Label>
																		<Col sm={9}>
																			{getSelectComponent(
																				viewDuplicateSettingsById.alignment,
																				setAlignModeDuplicate,
																				Align
																			)}
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			Margins
																		</Form.Label>
																		<Col sm={9} style={{ display: "contents" }}>
																			<div className="d-sm-flex col-gap-20 flex-col">
																				<div className="">
																					{getSelectComponent(
																						viewDuplicateSettingsById.margin_format,
																						setMarginFormatDuplicate,
																						Margin
																					)}
																				</div>
																				<div className="">
																					<ul className="margin-list">
																						<li>
																							<Form.Control
																								type="text"
																								placeholder="T"
																								autoFocus
																								onChange={(e) =>
																									setMarginTopDuplicate(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																								defaultValue={
																									viewDuplicateSettingsById.margin_top
																								}
																								disabled={true}
																							/>
																						</li>
																						<li>
																							<Form.Control
																								type="text"
																								placeholder="B"
																								autoFocus
																								onChange={(e) =>
																									setMarginBottomDuplicate(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																								defaultValue={
																									viewDuplicateSettingsById.margin_bottom
																								}
																								disabled={true}
																							/>
																						</li>
																						<li>
																							<Form.Control
																								type="text"
																								placeholder="R"
																								autoFocus
																								onChange={(e) =>
																									setMarginRightDuplicate(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																								defaultValue={
																									viewDuplicateSettingsById.margin_right
																								}
																								disabled={true}
																							/>
																						</li>
																						<li>
																							<Form.Control
																								type="text"
																								placeholder="L"
																								autoFocus
																								onChange={(e) =>
																									setMarginLeftDuplicate(
																										e.target.value
																									)
																								}
																								autoComplete="off"
																								defaultValue={
																									viewDuplicateSettingsById.margin_left
																								}
																								disabled={true}
																							/>
																						</li>
																					</ul>
																				</div>
																			</div>
																		</Col>

																		<Col sm={6}></Col>
																	</Row>
																</div>
															</Form.Group>
														</>
													)}
												</div>
											) : (
												<>
													<div>
														<div
															style={{
																height: "29.5rem",
																overflow: "scroll",
																width: "100%",
															}}
														>
															{/* Form groups for Edit Type*/}
															<div className="sub-head">Edit Type</div>
															{view_image_settings &&
															view_image_settings.image &&
															view_image_settings.image.edit_group &&
															view_image_settings.image.edit_group
																.edit_types ? (
																view_image_settings.image.edit_group.edit_types.map(
																	(item) => {
																		return (
																			<Form.Group
																				className=""
																				controlId="exampleForm.ControlInput1"
																			>
																				{" "}
																				<Row
																					className="form-group  flex-grow-1"
																					sm={9}
																				>
																					<Col sm={1}>
																						<input
																							type="checkbox"
																							checked={item.id}
																						/>
																					</Col>
																					<Form.Label
																						className="col-sm-4 form-label form-check-label"
																						style={{ width: "auto" }}
																					>
																						{item.edit_type}
																					</Form.Label>
																				</Row>
																				{item.id === 1 ? (
																					item.id ? (
																						<Row
																							className="align-items-center"
																							style={{
																								marginLeft: "auto",
																								marginTop: "0",
																								marginBottom: "20px",
																							}}
																						>
																							<Form.Label className="col-sm-3">
																								Background Color
																							</Form.Label>
																							<Col sm={4}>
																								<Form.Control
																									type="text"
																									value={
																										view_image_settings.image
																											.edit_group
																											.background_settings !==
																											undefined &&
																										view_image_settings.image
																											.edit_group
																											.background_settings[0]
																											.color_type
																									}
																									autoFocus
																									disabled
																									autoComplete="off"
																								/>
																							</Col>
																							<Col sm={3}>
																								<Form.Control
																									type="text"
																									value={
																										view_image_settings.image
																											.edit_group
																											.background_settings !==
																											undefined &&
																										view_image_settings.image
																											.edit_group
																											.background_settings[0]
																											.color_code
																									}
																									autoFocus
																									disabled
																									autoComplete="off"
																								/>
																							</Col>
																						</Row>
																					) : (
																						""
																					)
																				) : (
																					""
																				)}
																				{item.id === 2 ? (
																					item.id ? (
																						<Row
																							className="align-items-center"
																							style={{
																								marginLeft: "auto",
																								marginTop: "0",
																								marginBottom: "20px",
																							}}
																						>
																							<Form.Label className="col-sm-3">
																								Background Color
																							</Form.Label>
																							<Col sm={4}>
																								<Form.Control
																									type="text"
																									value={
																										view_image_settings.image
																											.edit_group
																											.background_settings !==
																											undefined &&
																										view_image_settings.image
																											.edit_group
																											.background_settings[1]
																											.color_type
																									}
																									autoFocus
																									disabled
																									autoComplete="off"
																								/>
																							</Col>
																							<Col sm={3}>
																								<Form.Control
																									type="text"
																									value={
																										view_image_settings.image
																											.edit_group
																											.background_settings !==
																											undefined &&
																										view_image_settings.image
																											.edit_group
																											.background_settings[1]
																											.color_code
																									}
																									autoFocus
																									disabled
																									autoComplete="off"
																								/>
																							</Col>
																						</Row>
																					) : (
																						""
																					)
																				) : (
																					""
																				)}
																				{item.id === 3
																					? item.id
																						? view_image_settings.image !==
																								undefined &&
																						  view_image_settings.image.edit_group.resizing_settings.map(
																								(item_list) => {
																									return (
																										<Row
																											className="align-items-center"
																											style={{
																												marginLeft: "auto",
																												marginRight: "auto",
																												marginTop: "0",
																												marginBottom: "20px",
																												width: " 94%",
																											}}
																										>
																											<Form.Group
																												style={{
																													margin: "2px",
																												}}
																												controlId="exampleForm.ControlTextarea1"
																											>
																												<div className="form-group">
																													<Row className="align-items-center">
																														<Form.Label className="col-sm-3">
																															File Format
																														</Form.Label>
																														<Col sm={6}>
																															<Form.Control
																																type="text"
																																value={
																																	item_list.file_format
																																}
																																autoFocus
																																disabled
																																autoComplete="off"
																															/>
																														</Col>
																													</Row>
																												</div>
																											</Form.Group>

																											<Form.Group
																												style={{
																													margin: "2px",
																												}}
																												controlId="exampleForm.ControlTextarea1"
																											>
																												<div className="form-group">
																													<Row className="align-items-center">
																														<Form.Label className="col-sm-3">
																															Size
																														</Form.Label>
																														<Col sm={9}>
																															<div className="d-sm-flex align-items-center col-gap-10">
																																<div className="d-sm-flex col-gap-10 align-items-center">
																																	<span className="text-secondary f-sz-13">
																																		{" "}
																																		H:{" "}
																																	</span>
																																	<Form.Control
																																		type="text"
																																		value={
																																			item_list.height
																																		}
																																		autoFocus
																																		disabled
																																		autoComplete="off"
																																	/>
																																</div>
																																<div className="d-sm-flex align-items-center"></div>
																																<div className="d-sm-flex align-items-center col-gap-10">
																																	<span className="text-secondary f-sz-13">
																																		{" "}
																																		W:{" "}
																																	</span>
																																	<Form.Control
																																		type="text"
																																		value={
																																			item_list.width
																																		}
																																		autoFocus
																																		disabled
																																		autoComplete="off"
																																	/>
																																</div>
																															</div>
																														</Col>
																													</Row>
																												</div>
																											</Form.Group>

																											<Form.Group
																												style={{
																													margin: "2px",
																												}}
																												controlId="exampleForm.ControlTextarea1"
																											>
																												<div className="form-group">
																													<Row className="align-items-center">
																														<Form.Label className="col-sm-3">
																															DPI
																														</Form.Label>
																														<Col sm={9}>
																															<div className="d-sm-flex col-gap-20">
																																<div className="">
																																	<Form.Control
																																		type="text"
																																		value={
																																			item_list.dpi
																																		}
																																		autoFocus
																																		disabled
																																		autoComplete="off"
																																	/>
																																</div>
																																<div className="flex-fill">
																																	<Form.Control
																																		type="text"
																																		value={
																																			item_list.dpi_type
																																		}
																																		autoFocus
																																		disabled
																																		autoComplete="off"
																																	/>
																																</div>
																															</div>
																														</Col>
																													</Row>
																												</div>
																											</Form.Group>

																											<Form.Group
																												style={{
																													margin: "2px",
																												}}
																												controlId="exampleForm.ControlTextarea1"
																											>
																												<div className="form-group">
																													<Row className="align-items-center">
																														<Form.Label className="col-sm-3">
																															Color Mode
																														</Form.Label>
																														<Col sm={9}>
																															<Form.Control
																																type="text"
																																value={
																																	item_list.color_mode
																																}
																																autoFocus
																																disabled
																																autoComplete="off"
																															/>
																														</Col>
																													</Row>
																												</div>
																											</Form.Group>

																											<Form.Group
																												style={{
																													margin: "2px",
																												}}
																												controlId="exampleForm.ControlTextarea1"
																											>
																												<div className="form-group">
																													<Row className="align-items-center">
																														<Form.Label className="col-sm-3">
																															Align
																														</Form.Label>
																														<Col sm={9}>
																															<Form.Control
																																type="text"
																																value={
																																	item_list.alignment
																																}
																																autoFocus
																																disabled
																																autoComplete="off"
																															/>
																														</Col>
																													</Row>
																												</div>
																											</Form.Group>

																											<Form.Group
																												style={{
																													margin: "2px",
																												}}
																												controlId="exampleForm.ControlTextarea1"
																											>
																												<div className="form-group">
																													<Row className="align-items-center">
																														<Form.Label className="col-sm-3">
																															Margins
																														</Form.Label>
																														<Col sm={9}>
																															<Form.Control
																																type="text"
																																value={
																																	item_list.margin_format
																																}
																																autoFocus
																																disabled
																																autoComplete="off"
																															/>
																														</Col>
																														<Col
																															sm={7}
																															lg={7}
																															style={{
																																display: "flex",
																																marginLeft:
																																	"5rem",
																															}}
																														>
																															<div className="d-sm-flex col-gap-20 mt-2 ">
																																<div className="d-flex">
																																	<ul className="margin-list d-flex">
																																		<li>
																																			<Form.Control
																																				type="text"
																																				value={
																																					item_list.margin_top
																																				}
																																				autoFocus
																																				disabled
																																				autoComplete="off"
																																			/>
																																		</li>
																																		<li>
																																			<Form.Control
																																				type="text"
																																				value={
																																					item_list.margin_bottom
																																				}
																																				autoFocus
																																				disabled
																																				autoComplete="off"
																																			/>
																																		</li>
																																		<li>
																																			<Form.Control
																																				type="text"
																																				value={
																																					item_list.margin_left
																																				}
																																				autoFocus
																																				disabled
																																				autoComplete="off"
																																			/>
																																		</li>
																																		<li>
																																			<Form.Control
																																				type="text"
																																				value={
																																					item_list.margin_right
																																				}
																																				autoFocus
																																				disabled
																																				autoComplete="off"
																																			/>
																																		</li>
																																	</ul>
																																</div>
																															</div>
																														</Col>

																														<Col sm={6}></Col>
																													</Row>
																												</div>
																											</Form.Group>
																										</Row>
																									);
																								}
																						  )
																						: ""
																					: ""}
																			</Form.Group>
																		);
																	}
																)
															) : (
																<span>Edit Types are not defined</span>
															)}

															{/* Form groups for output specifications */}
															<div className="sub-head">
																Output Specifications
															</div>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			File Format
																		</Form.Label>
																		<Col sm={6}>
																			<Form.Label className="col-sm-3">
																				{image_details_view.file_format}
																			</Form.Label>
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			Size
																		</Form.Label>
																		<Col sm={9}>
																			<div className="d-sm-flex align-items-center col-gap-10">
																				<div className="d-sm-flex col-gap-10 align-items-center">
																					<span className="text-secondary f-sz-13">
																						{" "}
																						H:{" "}
																					</span>
																					<span className="text-secondary f-sz-13">
																						{image_details_view.height}
																					</span>
																				</div>
																				<div className="d-sm-flex align-items-center"></div>
																				<div className="d-sm-flex align-items-center col-gap-10">
																					<span className="text-secondary f-sz-13">
																						{" "}
																						W:{" "}
																					</span>
																					<span className="text-secondary f-sz-13">
																						{image_details_view.width}
																					</span>
																				</div>
																			</div>
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			DPI
																		</Form.Label>
																		<Col sm={9}>
																			<div className="d-sm-flex col-gap-20">
																				<div className="">
																					<Form.Label className="col-sm-3">
																						{image_details_view.dpi}
																					</Form.Label>
																				</div>
																				<div className="flex-fill">
																					<Form.Label className="col-sm-3">
																						{image_details_view.dpi_type}
																					</Form.Label>
																				</div>
																			</div>
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			Color Mode
																		</Form.Label>
																		<Col sm={9}>
																			<Form.Label className="col-sm-3">
																				{image_details_view.color_mode}
																			</Form.Label>
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			Align
																		</Form.Label>
																		<Col sm={9}>
																			<Form.Label className="col-sm-3">
																				{image_details_view.alignment}
																			</Form.Label>
																		</Col>
																	</Row>
																</div>
															</Form.Group>

															<Form.Group
																style={{ margin: "2px" }}
																controlId="exampleForm.ControlTextarea1"
															>
																<div className="form-group">
																	<Row className="align-items-center">
																		<Form.Label className="col-sm-3">
																			Margins
																		</Form.Label>
																		<Col sm={9}>
																			<div className="d-sm-flex col-gap-20">
																				<div className="image-details-margin--type ">
																					<span className="text-secondary f-sz-13">
																						{image_details_view.margin_format}
																					</span>
																				</div>
																				<div className="">
																					<ul className="margin-list">
																						<li>
																							<Form.Label className="col-sm-3">
																								{image_details_view.margin_top}
																							</Form.Label>
																						</li>
																						<li>
																							<Form.Label className="col-sm-3">
																								{
																									image_details_view.margin_bottom
																								}
																							</Form.Label>
																						</li>
																						<li>
																							<Form.Label className="col-sm-3">
																								{
																									image_details_view.margin_right
																								}
																							</Form.Label>
																						</li>
																						<li>
																							<Form.Label className="col-sm-3">
																								{image_details_view.margin_left}
																							</Form.Label>
																						</li>
																					</ul>
																				</div>
																			</div>
																		</Col>

																		<Col sm={6}></Col>
																	</Row>
																</div>
															</Form.Group>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				</div>
			)}
		</div>
	);
	// }
};

export default Modal;
