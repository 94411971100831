import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Sidebar from "../../../components/global/Sidebar/Sidebar";
import user from "../../../assets/Images/users.svg";
import plus from "../../../assets/Images/plus.svg";
import Select from "react-select";
import Card from "react-bootstrap/Card";
import pending from "../../../assets/Images/overdue.svg";
import editing from "../../../assets/Images/amount.svg";
import revision from "../../../assets/Images/balance.svg";
import total from "../../../assets/Images/image-count.svg";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import Delete from "../../../assets/Images/delete.svg";
import Editt from "../../../assets/Images/edit1.svg";

const EnterPriseCustomerInvoice = () => {
  const choosetype = [
    { label: "This Year", value: 355 },
    { label: "This Year", value: 54 },
  ];
  const overdue = [
    { label: "Overdue", value: 355 },
    { label: "Overdue", value: 54 },
  ];

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="rightbar pr-11">
            <div className="section-title">
              <h2>Invoices</h2>
            </div>
            <div className="d-sm-flex flex-sm-wrap col-gap-30-sm mb-3">
              <div className="customer-search">
                <Form>
                  <Row className="align-items-center">
                    <Col lg={2} sm={6}>
                      <div className="form-group">
                        <Select
                          options={choosetype}
                          defaultValue={{ label: "This Year", value: 355 }}
                        />
                      </div>
                    </Col>
                    <Col lg={2} sm={6}>
                      <div className="form-group">
                        <Select
                          options={overdue}
                          defaultValue={{ label: "Overdue", value: 355 }}
                        />
                      </div>
                    </Col>
                    <Col lg={4} sm={6}>
                      <div className="form-group">
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className="me-2"
                          aria-label="Search"
                        />{" "}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className="d-sm-flex invoicecard mb-4">
              <Card>
                <Card.Img variant="top" src={total} />
                <Card.Body>
                  <Card.Title>Total Invoice Count</Card.Title>
                  <Card.Text>12</Card.Text>
                </Card.Body>
              </Card>

              <Card>
                <Card.Img variant="top" src={pending} />
                <Card.Body>
                  <Card.Title>Total Overdue Invoice Count</Card.Title>
                  <Card.Text>5</Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={editing} />
                <Card.Body>
                  <Card.Title>Total Amount</Card.Title>
                  <Card.Text>$350,000</Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={revision} />
                <Card.Body>
                  <Card.Title>Total Balance Due</Card.Title>
                  <Card.Text>$35,000</Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="table-responsive table-b custom-new-table mb-5">
              <Table hover>
                <thead>
                  <tr>
                    <th>Invoice No.</th>
                    <th>Issue Date</th>
                    <th>Due Date</th>
                    <th>Amount</th>
                    <th>Balance Due</th>
                    <th>Status</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>INV-0003242</td>
                    <td>19 Aug 2022 </td>
                    <td>19 Aug 2022 </td>
                    <td>$500.00 </td>
                    <td>$500.00 </td>
                    <td>Due in 13 days </td>
                    <td className="text-primary">
                      <u>Pay Now</u>
                    </td>
                    <td className="edittd">
                      <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                        <div className="">
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip
                                className="in"
                                id="tooltip-top"
                                {...props}
                              >
                                Edit
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <img
                              src={Editt}
                              className="img-fluid"
                              alt="Delete"
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="">
                          <img
                            src={Delete}
                            className="img-fluid"
                            alt="Delete"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>INV-0003242</td>
                    <td>19 Aug 2022 </td>
                    <td>19 Aug 2022 </td>
                    <td>$500.00 </td>
                    <td>$500.00 </td>
                    <td>Due in 13 days </td>
                    <td className="text-primary">
                      <u>Pay Now</u>
                    </td>
                    <td className="edittd">
                      <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                        <div className="">
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip
                                className="in"
                                id="tooltip-top"
                                {...props}
                              >
                                Edit
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <img
                              src={Editt}
                              className="img-fluid"
                              alt="Delete"
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="">
                          <img
                            src={Delete}
                            className="img-fluid"
                            alt="Delete"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>INV-0003242</td>
                    <td>19 Aug 2022 </td>
                    <td>19 Aug 2022 </td>
                    <td>$500.00 </td>
                    <td>$500.00 </td>
                    <td className="text-warning">Partially Paid</td>
                    <td className="text-primary">
                      <u>Pay Now</u>
                    </td>
                    <td className="edittd">
                      <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                        <div className="">
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip
                                className="in"
                                id="tooltip-top"
                                {...props}
                              >
                                Edit
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <img
                              src={Editt}
                              className="img-fluid"
                              alt="Delete"
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="">
                          <img
                            src={Delete}
                            className="img-fluid"
                            alt="Delete"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>INV-0003242</td>
                    <td>19 Aug 2022 </td>

                    <td>19 Aug 2022 </td>
                    <td>$500.00 </td>
                    <td>$500.00 </td>
                    <td className="text-success">Paid</td>
                    <td></td>
                    <td className="edittd">
                      <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                        <div className="">
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip
                                className="in"
                                id="tooltip-top"
                                {...props}
                              >
                                Edit
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <img
                              src={Editt}
                              className="img-fluid"
                              alt="Delete"
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="">
                          <img
                            src={Delete}
                            className="img-fluid"
                            alt="Delete"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>INV-0003242</td>
                    <td>19 Aug 2022 </td>

                    <td>19 Aug 2022 </td>
                    <td>$500.00 </td>
                    <td>$500.00 </td>
                    <td className="text-danger">Overdue by 10 days</td>
                    <td className="text-primary">
                      <u>Pay Now</u>
                    </td>
                    <td className="edittd">
                      <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                        <div className="">
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip
                                className="in"
                                id="tooltip-top"
                                {...props}
                              >
                                Edit
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <img
                              src={Editt}
                              className="img-fluid"
                              alt="Delete"
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="">
                          <img
                            src={Delete}
                            className="img-fluid"
                            alt="Delete"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>INV-0003242</td>
                    <td>19 Aug 2022 </td>

                    <td>19 Aug 2022 </td>
                    <td>$500.00 </td>
                    <td>$500.00 </td>
                    <td className="text-success">Paid</td>
                    <td></td>
                    <td className="edittd">
                      <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                        <div className="">
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip
                                className="in"
                                id="tooltip-top"
                                {...props}
                              >
                                Edit
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <img
                              src={Editt}
                              className="img-fluid"
                              alt="Delete"
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="">
                          <img
                            src={Delete}
                            className="img-fluid"
                            alt="Delete"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterPriseCustomerInvoice;
