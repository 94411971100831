import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination, Form, Row, Col, Table, Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// Importing components and images
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import user from "../../assets/Images/calender-shifts.svg";
import plus from "../../assets/Images/plus.svg";

import Delete from "./../../assets/Images/delete.svg";
import duplicate from "./../../assets/Images/actionsIcons/Duplicate _Final.svg";
import CancelIcon from "./../../assets/Images/actionsIcons/cancel_Final.svg";
import MoreIcon from "./../../assets/Images/actionsIcons/more-Final.svg";

import Select from "react-select";

import Alert from "../../components/Alert";
import DeleteModalConfirmation from "../../components/Modal/DeleteModal";
import ActivateModalConfirmation from "../../components/Modal/ActivateModal";
import DeActivateModalConfirmation from "../../components/Modal/DeactivateModal";
import DuplicateModalConfirmation from "../../components/Modal/DuplicateModal";

// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
	WORK_SHIFTS_LISTS,
	BRANCHES_LIST,
	DELETE_WORK_SHIFT_BY_ID,
	DELETE_BULK_WORK_SHIFTS,
	ACTIVATE_WORK_SHIFT_BY_ID,
	DEACTIVATE_WORK_SHIFT_BY_ID,
	ACTIVATE_BULK_WORK_SHIFT,
	DEACTIVATE_BULK_WORK_SHIFT,
	DUPLICATE_BULK_WORK_SHIFT,
} from "./../../config/apiRoutes.api";
import { WORK_SHIFT_EDIT_PAGE, WORK_SHIFT_ADD_PAGE } from "../../config/routes";
const ListPage = () => {
	// Retrieve the token from the session storage
	const token = sessionStorage.getItem("token");

	// Create a request instance using the token
	const request = client(token);

	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();

	// Create a new instance of FormData
	const bodyFormData = new FormData();
	//Declarations of state variable
	const [shiftsData, setShifts] = useState([]);
	const [branches, setBranches] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [brancheName, setBranchesName] = useState(0);
	const [shiftsSearch, setShiftsSearchData] = useState("");
	const [error, setError] = useState("");
	const [show, setShow] = useState(false);
	const [shiftNameSeleted, setShiftNameSelected] = useState("");
	const [shiftSeletedId, setShiftSelectedId] = useState("");
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowId, setSelectedRowsId] = useState([]);
	const [selectedRowName, setSelectedRowsName] = useState([]);
	const [selectAllRow, setSelectAllRows] = useState([]);
	const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);

	const [work_shift_activate, setActivate] = useState(false);
	const [work_shift_activate_All, setActivateAll] = useState(false);
	const [work_shift_deactivate, setDeactivate] = useState(false);
	const [work_shift_deactivate_All, setDeactivateAll] = useState(false);
	const [work_shift_duplicate, setDuplicate] = useState(false);
	const [work_shift_duplicate_All, setDuplicateAll] = useState(false);
	const [activate_id, setActivateById] = useState(false);
	const [deactivate_id, setDeactivateById] = useState(false);
	const [status_name, setStatusName] = useState("");
	const [id, setId] = useState("");

	const [selectAll, setSelectAll] = useState(false);
	const [rows, setRows] = useState([]);
	/**
	 * Generates the pagination items for rendering.
	 *
	 * This function generates an array of pagination items based on the total number of shifts, the number of shifts per page,
	 * and the current page number. Each item represents a page number and is rendered as a Pagination.Item component.
	 * The active item is determined based on whether the page number matches the current page number.
	 * Clicking on an item updates the page number using the setPageNumber function.
	 *
	 * Note: The state variable `pageNumber` is assumed to be defined and accessible within the component.
	 * The shiftsData variable is assumed to be an array with a nested object containing shifts data.
	 */
	const PaginationItems = () => {
		const { total, per_page, current_page } = shiftsData[0].shifts;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};

	/**
	 * Handles the next pagination action.
	 *
	 * This function is called when the "Next" button is clicked to navigate to the next page.
	 * It updates the page number based on the current page number and the total number of pages available.
	 * If the current page number is not the last page, it increments the page number by 1.
	 *
	 * Note: The state variable `pageNumber` is assumed to be defined and accessible within the component.
	 */
	const NextPagination = () => {
		// Check if the current page number is not the last page
		if (
			pageNumber !==
			Math.ceil(shiftsData[0].shifts.total / shiftsData[0].shifts.per_page)
		) {
			// Increment the page number by 1
			setPageNumber(pageNumber + 1);
		}
	};

	/**
	 * Handles the previous pagination action.
	 *
	 * This function is called when the "Previous" button is clicked to navigate to the previous page.
	 * It updates the page number based on the current page number and the total number of pages available.
	 * If the current page number is not the first page, it decrements the page number by 1.
	 *
	 * Note: The state variable `pageNumber` is assumed to be defined and accessible within the component.
	 */
	const PreviousPagination = () => {
		// Check if the current page number is not the first page
		if (
			pageNumber !==
			Math.ceil(shiftsData[0].shifts.total / shiftsData[0].shifts.per_page)
		) {
			// Decrement the page number by 1
			setPageNumber(pageNumber - 1);
		} else {
			// Decrement the page number by 1
			setPageNumber(pageNumber - 1);
		}
	};

	/**
	 * Renders a Tooltip component with the specified text as its content.
	 * @param {string} text - The text to be displayed in the Tooltip.
	 * @returns {JSX.Element} The Tooltip component.
	 */
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

	/**
	 * Handles the selection of all rows.
	 *
	 * This function is called when the "Select All" checkbox is clicked.
	 * If the checkbox is checked, it selects all rows by updating the state variables and maps the selected rows' IDs and names.
	 * If the checkbox is unchecked, it deselects all rows by updating the state variables.
	 *
	 * @param {Event} e - The event object triggered by the checkbox.
	 */
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			// Checkbox is checked, select all rows

			// Update the state variable to indicate that all rows are selected
			setSelectAll(true);

			// Create a new array of updated rows with the selected flag set to true
			const updatedRows = shiftsData[0].shifts.data.map((row) => {
				return { ...row, selected: true };
			});
			// Update the state variable with the updated rows
			setRows(updatedRows);

			// Create arrays of selected rows' IDs and names
			const updatedRowsIds = shiftsData[0].shifts.data.map((row) => {
				return row.encrypted_id;
			});
			const updatedRowsName = shiftsData[0].shifts.data.map((row) => {
				return row.shift_name;
			});
			// Update the state variables with the selected rows' IDs and names
			setSelectAllRows((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsIds,
			]);
			setSelectedRowsName((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsName,
			]);
		} else {
			// Checkbox is unchecked, deselect all rows

			// Update the state variable to indicate that no rows are selected
			setSelectAll(false);

			// Create a new array of updated rows with the selected flag set to false
			const updatedRows = shiftsData[0].shifts.data.map((row) => {
				return { ...row, selected: false };
			});
			// Update the state variable with the updated rows
			setRows(updatedRows);

			// Clear the state variable for selected rows' IDs
			setSelectAllRows([]);
		}
	};

	/**
	 * Handles the deletion of a branch by ID.
	 *
	 * This function sends an HTTP GET request to the API endpoint for deleting a branch by its ID.
	 * It checks if a token is available, and if so, sends the request.
	 * Upon success, the function logs the response to the console, reloads the page, and may display an alert message.
	 *
	 * @param {string} id - The ID of the branch to be deleted.
	 */
	const handleDeleteBranch = (id) => {
		// Check if a token is available
		if (token) {
			// Send an HTTP GET request to delete the branch by ID
			request
				.get(apiURL + DELETE_WORK_SHIFT_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles the display of delete confirmation dialog.
	 *
	 * This function is called when a delete confirmation is required for a specific shift.
	 * It sets the state variables to show the delete confirmation dialog, and sets the shift name and ID that need to be deleted.
	 *
	 * @param {string} id - The ID of the shift to be deleted.
	 * @param {string} name - The name of the shift to be deleted.
	 */
	const handleDeleteConfirmation = (id, name) => {
		// Set the state variables to show the delete confirmation dialog
		setShow(true);
		// Set the shift name that needs to be deleted
		setShiftNameSelected(name);
		// Set the shift ID that needs to be deleted
		setShiftSelectedId(id);
	};

	const selectLabel = (branches) =>
		branches.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	useEffect(() => {
		if (token && brancheName === 0) {
			if (shiftsSearch === "") {
				request
					.get(apiURL + WORK_SHIFTS_LISTS + `?page=${pageNumber}`)
					.then((response) => {
						console.log(response);
						setShifts(response.data);
						setRows(response.data[0].shifts.data);
					})
					.catch((err) => setError(err.message));
			} else {
				request
					.get(apiURL + WORK_SHIFTS_LISTS + `?search_key=${shiftsSearch} `)
					.then((response) => {
						console.log(response);
						setShifts(response.data);
						setRows(response.data[0].shifts.data);
					})
					.catch((err) => setError(err.message));
			}
		}
	}, [pageNumber, shiftsSearch]);
	useEffect(() => {
		if (token && brancheName !== 0 && shiftsSearch === "") {
			request
				.get(
					apiURL +
						WORK_SHIFTS_LISTS +
						`?branch_id=${brancheName}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log(response);
					setShifts(response.data);
					setRows(response.data[0].shifts.data);
				})
				.catch((err) => setError(err.message));
		}
	}, [brancheName, pageNumber, shiftsSearch]);
	useEffect(() => {
		if (token && shiftsSearch !== "" && brancheName !== 0) {
			request
				.get(
					apiURL +
						WORK_SHIFTS_LISTS +
						`?branch_id=${brancheName}` +
						`&search_key=${shiftsSearch}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log(response);
					setShifts(response.data);
					setRows(response.data[0].shifts.data);
				})
				.catch((err) => setError(err.message));
		}
	}, [pageNumber, shiftsSearch, brancheName]);
	useEffect(() => {
		if (token && brancheName === "All") {
			request
				.get(apiURL + WORK_SHIFTS_LISTS + `?page=${pageNumber}`)
				.then((response) => {
					console.log(response);
					setShifts(response.data);
					setRows(response.data[0].shifts.data);
				})
				.catch((err) => setError(err.message));
		}
	}, [brancheName, pageNumber]);

	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_LIST)
				.then((response) => {
					console.log(response);
					// setBranches(response.data[0].branches);
					setBranches([
						{ name: "All", id: "All" },
						...response.data[0].branches,
					]);
				})
				.catch((err) => {
					setError(err.message);
				});
		}
	}, []);
	/**
	 * Handles the selection of a row.
	 *
	 * This function is used as a callback for handling the selection of a row in a table.
	 * It takes an event and the selected row as parameters. If the checkbox is checked, the function adds the selected row's ID and name to the selectedRows and selectedRowsId state arrays.
	 * If the checkbox is unchecked, the function removes the selected row's ID and name from the arrays.
	 *
	 * @param {Event} e - The event object triggered by the checkbox.
	 * @param {Object} row - The selected row object.
	 */
	const handleSelectRow = React.useCallback(
		(e, row) => {
			if (e.target.checked) {
				console.log("e.target.checked", e.target.checked);
				setSelectedRows((prevSelectedRows) => [
					...prevSelectedRows,
					row.encrypted_id,
				]);
				setSelectedRowsId((prevSelectedRows) => [
					...prevSelectedRows,
					row.shift_name,
				]);
			} else {
				setSelectedRows((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.encrypted_id
					)
				);
				setSelectedRowsId((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.shift_name
					)
				);
			}
		},
		[selectedRows]
	);
	/**
	 * Handles the bulk deletion of selected work shifts.
	 *
	 * This function sends an HTTP POST request to the API endpoint for deleting multiple work shifts in bulk.
	 * It appends the selected shift IDs to the request body, sends the request, and upon success, updates the state and reloads the page.
	 *
	 * @throws {Error} If the HTTP request fails or encounters an error.
	 */
	const handleBulkDelete = () => {
		// Check if a token is available
		if (token) {
			// Append the selected shift IDs to the request body as a JSON string
			bodyFormData.append("shift_ids", JSON.stringify(selectedRows));
			// Send an HTTP POST request to delete multiple work shifts in bulk
			request
				.post(apiURL + DELETE_BULK_WORK_SHIFTS, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Update the state to close the modal (show) for bulk delete
					setShow(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles the bulk deletion of all selected work shifts.
	 *
	 * This function sends an HTTP POST request to the API endpoint for deleting multiple work shifts in bulk.
	 * It appends the selected shift IDs to the request body, sends the request, and upon success, updates the state and reloads the page.
	 *
	 * @throws {Error} If the HTTP request fails or encounters an error.
	 */
	const handleBulkDeleteAll = () => {
		// Check if a token is available
		if (token) {
			// Append the selected shift IDs to the request body as a JSON string
			bodyFormData.append("shift_ids", JSON.stringify(selectAllRow[0]));
			// Send an HTTP POST request to delete multiple work shifts in bulk
			request
				.post(apiURL + DELETE_BULK_WORK_SHIFTS, bodyFormData)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Update the state to close the delete confirmation modal
					setShowModalDeleteAll(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles the bulk duplication of selected work shifts.
	 *
	 * This function is called when the bulk duplicate button is clicked.
	 * It performs the duplication of the selected work shifts by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected work shifts are included in the request payload as an array of strings.
	 * After the duplication is complete, the function logs the response, updates the state to reflect the duplication status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectedRows`, `request`, `apiURL`, `setDuplicate` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDuplicate = () => {
		if (token) {
			// Append the shift IDs to the FormData object
			bodyFormData.append("shift_ids", JSON.stringify(selectedRows));

			// Send a POST request to duplicate the selected work shifts
			request
				.post(apiURL + DUPLICATE_BULK_WORK_SHIFT, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the duplication status
					setDuplicate(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk duplication of all selected work shifts.
	 *
	 * This function is called when the bulk duplicate button is clicked.
	 * It performs the duplication of all selected work shifts by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected work shifts are included in the request payload as an array of strings.
	 * After the duplication is complete, the function logs the response, updates the state to reflect the duplication status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectAllRow`, `request`, `apiURL`, `setDuplicateAll` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDuplicateAll = () => {
		if (token) {
			// Append the shift IDs to the FormData object
			bodyFormData.append("shift_ids", JSON.stringify(selectAllRow[0]));

			// Send a POST request to duplicate bulk work shifts
			request
				.post(apiURL + DUPLICATE_BULK_WORK_SHIFT, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the duplication status
					setDuplicateAll(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
		1.Sets the show flag to true.
		@param {function} setShow - The function to set the show flag.
	*/
	const handleBulkConfirmation = (setShow) => {
		setShow(true);
	};
	/**
	 * Handles the clearing of the "Select All" functionality.
	 *
	 * This function clears the "Select All" state and updates the rows and selected rows to their default values.
	 * It sets the "selectAll" state to false, sets each row's "selected" property to false, and clears the selected rows array.
	 */
	const handleClearSelectAll = () => {
		// Clear the "selectAll" state
		setSelectAll(false);
		// Update each row's "selected" property to false
		const updatedRows = shiftsData[0].shifts.data.map((row) => {
			return { ...row, selected: false };
		});
		setRows(updatedRows);
		// Clear the selected rows array
		const updatedRowsIds = shiftsData[0].shifts.data.map((row) => {
			return row.encrypted_id;
		});
		setSelectAllRows([]);
	};
	/**
	 * Activates a work shift by ID.
	 *
	 * This function makes an HTTP GET request to the API endpoint to activate a work shift with the specified ID.
	 * If a valid token is available, the request is sent, and upon success, the function updates the state and reloads the page.
	 *
	 * @throws {Error} If the HTTP request fails or encounters an error.
	 */
	const handleActivateById = () => {
		// Check if a token is available
		if (token) {
			// Make an HTTP GET request to activate work shift by ID
			request
				.get(apiURL + ACTIVATE_WORK_SHIFT_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Update the state to indicate successful activation
					setActivateById(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Deactivates a work shift by ID.
	 *
	 * This function makes an HTTP GET request to the API endpoint to deactivate a work shift with the specified ID.
	 * If a valid token is available, the request is sent, and upon success, the function updates the state and reloads the page.
	 *
	 * @throws {Error} If the HTTP request fails or encounters an error.
	 */
	const handleDeactivateById = () => {
		// Check if a token is available
		if (token) {
			// Make an HTTP GET request to deactivate work shift by ID
			request
				.get(apiURL + DEACTIVATE_WORK_SHIFT_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Update the state to indicate successful deactivation
					setDeactivateById(false);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
	 * Handles the activation or deactivation of a work shift.
	 *
	 * This function updates the necessary state variables based on the provided status.
	 * It sets the ID and status name, and depending on the status value, it either sets the 'deactivateById' state to true or the 'activateById' state to true.
	 *
	 * @param {string} status - The status of the work shift ("1" for deactivation, any other value for activation).
	 * @param {string} id - The ID of the work shift.
	 * @param {string} name - The name of the work shift.
	 */
	const handleActivateOrDeactive = (status, id, name) => {
		// Set the ID and status name
		setId(id);
		setStatusName(name);
		// Check the status value and update the corresponding state
		if (status === "1") {
			// Set the 'deactivateById' state to true for deactivation
			setDeactivateById(true);
		} else {
			// Set the 'activateById' state to true for activation
			setActivateById(true);
		}
	};
	/**
	 * Handles the bulk activation of selected work shifts.
	 *
	 * This function is called when the bulk activate button is clicked.
	 * It performs the activation of the selected work shifts by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected work shifts are included in the request payload as an array of strings.
	 * After the activation is complete, the function logs the response, updates the state to reflect the activation status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectedRows`, `request`, `apiURL`, `setActivate` are assumed to be defined and accessible within the component.
	 */
	const handleBulkActivate = () => {
		if (token) {
			// Append the shift IDs to the FormData object
			bodyFormData.append("shift_ids", JSON.stringify(selectedRows));

			// Send a POST request to activate the selected work shifts
			request
				.post(apiURL + ACTIVATE_BULK_WORK_SHIFT, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the activation status
					setActivate(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk activation of all selected work shifts.
	 *
	 * This function is called when the bulk activate button is clicked.
	 * It performs the activation of all selected work shifts by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected work shifts are included in the request payload as an array of strings.
	 * After the activation is complete, the function logs the response, updates the state to reflect the activation status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectAllRow`, `request`, `apiURL`, `setActivateAll` are assumed to be defined and accessible within the component.
	 */
	const handleBulkActivateAll = () => {
		if (token) {
			// Append the shift IDs to the FormData object
			bodyFormData.append("shift_ids", JSON.stringify(selectAllRow[0]));

			// Send a POST request to activate bulk work shifts
			request
				.post(apiURL + ACTIVATE_BULK_WORK_SHIFT, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the activation status
					setActivateAll(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk deactivation of selected work shifts.
	 *
	 * This function is called when the bulk deactivate button is clicked.
	 * It performs the deactivation of the selected work shifts by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected work shifts are included in the request payload as an array of strings.
	 * After the deactivation is complete, the function logs the response, updates the state to reflect the deactivation status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectedRows`, `request`, `apiURL`, `setDeactivate` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDeactivate = () => {
		if (token) {
			// Append the shift IDs to the FormData object
			bodyFormData.append("shift_ids", JSON.stringify(selectedRows));

			// Send a POST request to deactivate the selected work shifts
			request
				.post(apiURL + DEACTIVATE_BULK_WORK_SHIFT, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the deactivation status
					setDeactivate(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Handles the bulk deactivation of all selected work shifts.
	 *
	 * This function is called when the bulk deactivate button is clicked.
	 * It performs the deactivation of all selected work shifts by sending a POST request to the corresponding API endpoint.
	 * The shift IDs of the selected work shifts are included in the request payload as an array of strings.
	 * After the deactivation is complete, the function logs the response, updates the state to reflect the deactivation status,
	 * and reloads the window to refresh the data.
	 *
	 * Note: The state variables `token`, `bodyFormData`, `selectAllRow`, `request`, `apiURL`, `setDeactivateAll` are assumed to be defined and accessible within the component.
	 */
	const handleBulkDeactivateAll = () => {
		if (token) {
			// Append the shift IDs to the FormData object
			bodyFormData.append("shift_ids", JSON.stringify(selectAllRow[0]));

			// Send a POST request to deactivate bulk work shifts
			request
				.post(apiURL + DEACTIVATE_BULK_WORK_SHIFT, bodyFormData)
				.then((response) => {
					// Log the response from the API
					console.log(response);

					// Update the state to reflect the deactivation status
					setDeactivateAll(false);

					// Reload the window to refresh the data
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	/**
	 * Render Loader if shiftsData is empty.
	 *
	 * This condition checks if the shiftsData array is empty. If it is, it returns a Loader component to indicate that the data is being loaded.
	 *
	 * @returns {JSX.Element} Loader component to show while data is being loaded.
	 */
	if (!shiftsData.length) return <Loader />;
	else {
		return (
			<>
				<div className="rightbar pr-11">
					<div className="section-title">
						<h2>Work Shifts</h2>
						{error !== "" && <Alert variant={"danger"} messages={error} />}
					</div>
					<div className="d-sm-flex flex-sm-wrap col-gap-30-sm mb-3">
						<div className="customer-search">
							<Form>
								{selectedRows.length !== 0 && (
									<Row className="align-items-center">
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select">
													<>
														<div>
															<div
																onClick={() => handleBulkConfirmation(setShow)}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
																// className="image-icons-holiday"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</div>

														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",
																	// margin: "9px",
																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																	// transition:
																	// 	"background-color 0.2s ease-in-out",
																}}
																className="img-fluid--icons"
																onClick={() =>
																	handleBulkConfirmation(setDuplicate)
																}
															>
																<img
																	src={duplicate}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
																{/* </div> */}
															</div>
														</OverlayTrigger>

														{/* Dropdown*/}

														<div class="dropdown">
															<div
																// onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setActivate)
																	}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setDeactivate)
																	}
																>
																	Make as Inactive
																</a>
															</div>
														</div>

														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 224px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{selectedRows.length} Work Shift(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 386px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 6px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={() => setSelectedRows([])}
														>
															<img
																src={CancelIcon}
																// className="img-fluid--icons"
																alt="mindreach images"
															/>
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{selectAllRow.length !== 0 && (
									<Row className="align-items-center">
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select">
													<>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Delete")}
														>
															<div
																onClick={() =>
																	handleBulkConfirmation(setShowModalDeleteAll)
																}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</OverlayTrigger>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",
																	// margin: "9px",
																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																	// transition:
																	// 	"background-color 0.2s ease-in-out",
																}}
																className="img-fluid--icons"
																onClick={() =>
																	handleBulkConfirmation(setDuplicateAll)
																}
															>
																<img src={duplicate} alt="mindreach images" />
															</div>
														</OverlayTrigger>

														{/* Dropdown*/}

														<div class="dropdown">
															<div
																// onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setActivateAll)
																	}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setDeactivateAll)
																	}
																>
																	Make as Inactive
																</a>
															</div>
														</div>
														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 227px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{rows.length} Work Shift(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 386px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 9px 9px 8px",
															}}
															className="img-fluid--icons"
															onClick={handleClearSelectAll}
														>
															<img src={CancelIcon} alt="mindreach images" />
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}

								{!selectedRows.length && !selectAllRow.length && (
									<Row className="align-items-center">
										<Col lg={2} sm={6}>
											<div className="form-group">
												<Select
													onChange={(e) => {
														e !== null && setBranchesName(e.value);
													}}
													options={selectLabel(branches)}
													placeholder="Select Branch"
													isSearchable
												/>
											</div>
										</Col>
										<Col lg={5} sm={6}>
											<div className="form-group">
												<Form.Control
													type="search"
													placeholder="Search"
													className="me-2 search-icon"
													aria-label="Search"
													onChange={(e) => setShiftsSearchData(e.target.value)}
												/>{" "}
											</div>
										</Col>
										<Col lg={3} sm={6}>
											<div className="form-group">
												<p className="customer-count">
													<span>
														<img
															src={user}
															className="img-fluid"
															alt="mindreach images"
														/>
													</span>
													{shiftsData[0].shifts.total} Work Shift(s)
												</p>
											</div>
										</Col>
									</Row>
								)}
							</Form>
						</div>

						<div>
							<Button
								variant="primary"
								onClick={() => navigate(WORK_SHIFT_ADD_PAGE)}
							>
								<span>
									<img src={plus} className="img-fluid mr-2" />
								</span>
								New Work Shift
							</Button>{" "}
						</div>
					</div>

					<>
						<div
							className={
								`table-responsive table-b custom-new-table` +
								`  ` +
								`${
									selectedRows.length || selectAllRow.length
										? `mt-5--table`
										: ""
								}`
							}
						>
							<Table hover>
								<thead>
									{typeof rows !== "undefined" && rows.length !== 0 && (
										<tr>
											<th>
												{!selectedRows.length && (
													<input
														type="checkbox"
														checked={selectAll}
														onClick={handleSelectAll}
													/>
												)}
											</th>
											<th>Workkshift</th>
											<th>Branch</th>
											<th>Schedule</th>
											<th>Status</th>
											<th></th>
										</tr>
									)}
								</thead>
								<tbody>
									{shiftsData[0].shifts !== undefined &&
										rows.map((item) => {
											const list = (
												<>
													<tr>
														<td className="checkbox-elements--table">
															<input
																type="checkbox"
																checked={
																	selectedRows.length
																		? selectedRows.find(
																				(selectedRow) => selectedRow.id === item
																		  )
																		: item.selected === true
																}
																onChange={(e) => handleSelectRow(e, item)}
															/>
														</td>
														<td
															onClick={() =>
																navigate(
																	`${WORK_SHIFT_EDIT_PAGE}/${item.encrypted_id}`
																)
															}
														>
															{item.shift_name}
														</td>
														<td
															onClick={() =>
																navigate(
																	`${WORK_SHIFT_EDIT_PAGE}/${item.encrypted_id}`
																)
															}
														>
															{item.branch_name}
														</td>
														<td
															onClick={() =>
																navigate(
																	`${WORK_SHIFT_EDIT_PAGE}/${item.encrypted_id}`
																)
															}
														>
															{item.schedule}
														</td>
														<td
															onClick={() =>
																handleActivateOrDeactive(
																	item.is_active,
																	item.encrypted_id,
																	item.shift_name
																)
															}
														>
															{item.is_active === "1" ? (
																<div className="active-staff">Active</div>
															) : (
																<div>Inactive</div>
															)}
														</td>
														<td className="edittd">
															<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
																<div
																	className=""
																	onClick={() =>
																		handleDeleteConfirmation(
																			item.encrypted_id,
																			item.shift_name
																		)
																	}
																>
																	<img
																		src={Delete}
																		className="img-fluid"
																		alt="Delete"
																	/>
																</div>
															</div>
														</td>
													</tr>
												</>
											);
											return list;
										})}
									{typeof rows !== "undefined" && rows.length === 0 && (
										<NoDataFound />
									)}
								</tbody>
							</Table>
						</div>

						<Row className="mt-4">
							<Col
								md={{ span: 4, offset: 8 }}
								className="flex flex-md-row-reverse "
							>
								<Pagination>
									<Pagination.Prev onClick={() => PreviousPagination()} />
									{PaginationItems()}
									<Pagination.Next onClick={() => NextPagination()} />
								</Pagination>
							</Col>
						</Row>
					</>

					{typeof shiftsData[0].shifts.data === undefined &&
						shiftsData[0].shifts.data.length === 0 && <NoDataFound />}
					<DeleteModalConfirmation
						show={show}
						onHide={() => {
							setShow(false);
						}}
						branch={"shift"}
						name={shiftNameSeleted}
						title={"Delete Work Shift"}
						handleConfirm={() => handleDeleteBranch(shiftSeletedId)}
					/>
					<DeleteModalConfirmation
						show={show}
						onHide={() => {
							setShow(false);
						}}
						branch={"shift"}
						name={selectedRowId.toString()}
						title={"Delete Shift"}
						handleConfirm={() => handleBulkDelete()}
					/>
					<DeleteModalConfirmation
						show={showModalDeleteAll}
						onHide={() => {
							setShowModalDeleteAll(false);
						}}
						branch={"shift"}
						name={selectedRowName.toString()}
						title={"Delete Shift"}
						handleConfirm={() => handleBulkDeleteAll()}
					/>
					<ActivateModalConfirmation
						show={work_shift_activate}
						onHide={() => {
							setActivate(false);
						}}
						branch={selectedRowId.length === 1 ? "Work Shift" : "Work Shifts"}
						name={selectedRowId.toString()}
						title={"Activate Work Shifts"}
						handleConfirm={() => handleBulkActivate()}
					/>
					<ActivateModalConfirmation
						show={work_shift_activate_All}
						onHide={() => {
							setActivateAll(false);
						}}
						branch={selectedRowName.length === 1 ? "Work Shift" : "Work Shifts"}
						name={selectedRowName.toString()}
						title={"Activate Work Shifts"}
						handleConfirm={() => handleBulkActivateAll()}
					/>
					<DeActivateModalConfirmation
						show={work_shift_deactivate}
						onHide={() => {
							setDeactivate(false);
						}}
						branch={selectedRowId.length === 1 ? "Work Shift" : "Work Shifts"}
						name={selectedRowId.toString()}
						title={"Deactivate Work Shifts"}
						handleConfirm={() => handleBulkDeactivate()}
					/>
					<DeActivateModalConfirmation
						show={work_shift_deactivate_All}
						onHide={() => {
							setDeactivateAll(false);
						}}
						branch={selectedRowName.length === 1 ? "Work Shift" : "Work Shifts"}
						name={selectedRowName.toString()}
						title={"Deactivate Work Shifts"}
						handleConfirm={() => handleBulkDeactivateAll()}
					/>
					{/*************Activate or deactivate by id */}
					<ActivateModalConfirmation
						show={activate_id}
						onHide={() => {
							setActivateById(false);
						}}
						branch={"Work Shift"}
						name={status_name}
						title={"Activate Work Shift"}
						handleConfirm={() => handleActivateById()}
					/>
					<DeActivateModalConfirmation
						show={deactivate_id}
						onHide={() => {
							setDeactivateById(false);
						}}
						branch={"Work Shift"}
						name={status_name}
						title={"Deactivate Work Shift"}
						handleConfirm={() => handleDeactivateById()}
					/>
					<DuplicateModalConfirmation
						show={work_shift_duplicate}
						onHide={() => {
							setDuplicate(false);
						}}
						branch={"Work Shift"}
						name={selectedRowId.toString()}
						title={"Duplicate Work Shift"}
						handleConfirm={() => handleBulkDuplicate()}
					/>
					<DuplicateModalConfirmation
						show={work_shift_duplicate_All}
						onHide={() => {
							setDuplicateAll(false);
						}}
						branch={"Work Shifts"}
						name={selectedRowName.toString()}
						title={"Duplicate Work Shifts"}
						handleConfirm={() => handleBulkDuplicateAll()}
					/>
				</div>
			</>
		);
	}
};
export default ListPage;
