import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";

const EditorOrderDetailsidebar = (props) => {
  /**
   * Handles to change the date format.
   * @param {Date} dateString - An string of staff objects.
   */
  const getFormattedDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getUTCDate();
    const month = dateObject.getUTCMonth() + 1; // Months are 0-indexed
    const year = dateObject.getUTCFullYear();

    const formattedDate = `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${year}`;
    return formattedDate;
  };
  /**
   * Handles to generate the list of batches
   */
  const showList = () => {
    return (
      props.data &&
      props.data.map((data, index) => {
        return (
          <>
            <Card
              className={data.id === props.activeIndex ? "activecard" : ""}
              key={index}
              onClick={() => {
                props.resetActiveIndex(data.id, data.batch_number);
              }}
            >
              {/* <Card.Header>
                <div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
                  <div className="">
                    <p className="ordertitle">
                      <b>{data.customer.full_name}</b>
                      <span style={{ color: "#5A616B", marginLeft: "5px" }}>
                        Batch ID:
                        {data.batch_number}
                      </span>
                    </p>
                    <p className="ordertitle mt-2">
                      Order No.: {` ${data.order_id}`}
                      <span style={{ color: "#5A616B", marginLeft: "1rem" }}>
                        {getFormattedDate(data.created_at)}
                      </span>
                    </p>

                    <p className="ordertitle mt-2">{data.order.batch_number}</p>
                  </div>
                  <div className="">
                    <p className="orderdate"></p>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="order-details">
                  <p>Image Count: {data.count}</p>
                  <p>
                    Status: <span className="orange">{data.status}</span>
                  </p>
                </div>
                {data.status === "Processing" && (
                  <Link to="/">
                    <p className="viewguide">View Style Guide</p>
                  </Link>
                )}
              </Card.Body> */}
              <Card.Header>
                <div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
                  <div className="">
                    <p className="ordertitle">Batch ID: {data.batch_number}</p>
                    <p className="ordertitle">
                      Order ID: {data.order_id}
                      <br />
                      {data.customer.full_name}
                    </p>
                  </div>
                  <div className="">
                    <p className="orderdate">
                      {data.updated_at !== null &&
                        getFormattedDate(data.updated_at)}
                    </p>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="order-details">
                  <p>Image Count: {data.count}</p>
                  <p>
                    Status: <span className="orange">{data.status}</span>
                  </p>
                </div>
                {/* <Link to="/">
									<p className="viewguide">View Style Guide</p>
								</Link> */}
              </Card.Body>
            </Card>
          </>
        );
      })
    );
  };
  // check if to show the loader
  if (props.show) return <Loader />;
  return (
    <>
      <div className="ordercard">{showList()}</div>
    </>
  );
};

export default EditorOrderDetailsidebar;
