import * as React from "react";
import { useForm, Controller } from "react-hook-form";

import PhoneInput, {
	parsePhoneNumber,
	getCountryCallingCode,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";

const Phone = ({ onChange, className , value="" , defaultCountry = "ID" }) => {
	const [phoneCountryCode, phoneCountryCodeSetter] = React.useState(defaultCountry);

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const onSubmit = (data) => console.log(data);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="cellphone"
					rules={{
						validate: {
							isValid: (value) => {
								if (value) {
									const callingCode = getCountryCallingCode(phoneCountryCode);
									if (!new RegExp(`^\\+${callingCode}$`).test(value)) {
										return !!parsePhoneNumber(value);
									}
								}
								return true;
							},
						},
					}}
					control={control}
					render={({ field }) => (
						<PhoneInput
							id="phoneInput"
							{...field}
							onCountryChange={(v) => phoneCountryCodeSetter(v)}
							limitMaxLength={true}
							international={true}
							defaultCountry={defaultCountry}
							onChange={onChange}
							className={className}
							value={value}
						/>
					)}
				/>
			</form>
		</>
	);
};
export default Phone;
