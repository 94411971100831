import React from "react";

// Importing components and images
import Role from "./Role/Role";
import User from "./User/User";

// Group imports by type and use blank lines
import { Tab, Tabs } from "react-bootstrap";

const Staff = () => {
	return (
		<>
			<div className="rightbar pr-11">
				<div className="section-title">
					<h2>Staffs</h2>
				</div>
				<div className="d-flex justify-content-end col-gap12 align-items-center">
					<div>
						<i className="fa fa-ellipsis-h grey" aria-hidden="true"></i>
					</div>
				</div>
				<Tabs
					defaultActiveKey="User"
					id="justify-tab-example"
					className="stafftab"
					justify
				>
					<Tab eventKey="User" title="User">
						<User />
					</Tab>
					<Tab eventKey="Role" title="Role">
						<Role />
					</Tab>
				</Tabs>
			</div>
		</>
	);
};

export default Staff;
