import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import plus from '../../../../assets/Images/plus.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { components } from "react-select";
// import makeAnimated from "react-select/animated";
// import cross from '../../../Images/cross.png';





const AddEditytypesGroups = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (
        <>

            <Button variant="primary"
            // onClick={handleShow}
            >
                <span>
                    <img src={plus} className="img-fluid mr-2" /></span>Add New
            </Button>{' '}

            <Modal show={show}
                size="lg" className="addtypegroup"
                centered
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Group Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col sm={7}>
                                <Form.Group className="" controlId="exampleForm.ControlInput1">
                                    <div className="form-group flex-grow-1">
                                        <Form.Label>Group Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Model Images - Amazon"
                                            autoFocus
                                        />
                                    </div>
                                </Form.Group>
                                <h5 className="mt-3 ">Choose Specifications</h5>
                                <Form.Group
                                    className=""
                                    controlId="exampleForm.ControlTextarea1"
                                >

                                    <div className="form-group">
                                        <Row className="align-items-center">

                                            <Form.Label className='col-sm-5'>File Format</Form.Label>
                                            <Col sm={7}>
                                                <Form.Select>
                                                    <option>JPEG</option>
                                                    <option>PNG</option>
                                                </Form.Select></Col>

                                        </Row>
                                    </div>

                                </Form.Group>

                                <Form.Group
                                    className=""
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <div className="form-group">
                                        <Row className="align-items-center">

                                            <Form.Label className='col-sm-5'>Size</Form.Label>
                                            <Col sm={7}>
                                                <Form.Select>
                                                    <option>Height x Width</option>

                                                </Form.Select></Col>

                                        </Row>
                                    </div>

                                </Form.Group>

                                <Form.Group
                                    className=""
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <div className="form-group">
                                        <Row className="align-items-center">

                                            <Form.Label className='col-sm-5'>DPI</Form.Label>
                                            <Col sm={7}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    autoFocus
                                                />
                                            </Col>

                                        </Row>
                                    </div>

                                </Form.Group>

                                <Form.Group
                                    className=""
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <div className="form-group">
                                        <Row className="align-items-center">

                                            <Form.Label className='col-sm-5'>Color Mode</Form.Label>
                                            <Col sm={7}>
                                                <Form.Select>
                                                    <option>RBG</option>
                                                    <option>HEXA</option>

                                                </Form.Select></Col>

                                        </Row>
                                    </div>

                                </Form.Group>

                                <Form.Group
                                    className=""
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <div className="form-group">
                                        <Row className="align-items-center">

                                            <Form.Label className='col-sm-5'>Align</Form.Label>
                                            <Col sm={7}>
                                                <Form.Select>
                                                    <option>Center</option>
                                                    <option>Left</option>

                                                </Form.Select></Col>

                                        </Row>
                                    </div>

                                </Form.Group>

                                <Form.Group
                                    className=""
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <div className="form-group">
                                        <Row className="align-items-center">

                                            <Form.Label className='col-sm-5'>Margins</Form.Label>
                                            <Col sm={7}>
                                                <ul className="margin-list">
                                                    <li>T</li>
                                                    <li>B</li>
                                                    <li>L</li>
                                                    <li>R</li>
                                                </ul>

                                            </Col>

                                            <Col sm={6}>

                                            </Col>

                                        </Row>
                                    </div>

                                </Form.Group>
                            </Col>


                            <Col sm={5}>

                                <Form.Group
                                    className=""
                                    controlId="exampleForm.ControlTextarea1"
                                >


                                    <Form.Label>Choose Edit Types</Form.Label>

                                    <Form.Select>
                                        <option>Background Removal</option>
                                        <option>Retouching</option>
                                        <option>Resizing</option>
                                        <option>Cropping</option>
                                        <option>Color Adjustment</option>

                                    </Form.Select>

                                </Form.Group>

                            </Col>
                        </Row>



                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-sm-flex justify-content-end">
                        <div>
                            <Button variant="primary" className="btns" onClick={handleClose}>
                                Add
                            </Button> </div>
                    </div>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default AddEditytypesGroups