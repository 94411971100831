/**
 * The base URL of the API endpoint.
 * @type {string}
 **/
export const apiURL = "https://api.pippeline.com/api"; // Prod

// export const apiURL = "https://cnp1372-api.developer24x7.com/api"; // dev
/**
 * The base URL for image uploads.
 * @type {string}
 **/
export const imageURL = "https://api.pippeline.com/storage/uploads"; //Prod

// export const imageURL = "https://cnp1372-api.developer24x7.com/storage/uploads"; // dev
