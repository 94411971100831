import React, { useEffect, useState } from "react";
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	Dropdown,
	Button,
} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import total from "../../assets/Images/total.svg";
import customertotalerrorcount from "../../assets/Images/customer-total-error-count.svg";
import customertotalerrorrate from "../../assets/Images/customer-total-error-rate.svg";
import averageturnaroundtime from "../../assets/Images/average-turnaround-time.svg";
import revenue from "../../assets/Images/revenue.svg";
import totalrevenue from "../../assets/Images/total-revenue.svg";
import Select from "react-select";

import NoDataFound from "../../components/NoDataFound";

import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";

import { VIEW_CUSTOMER_ORDER_HISTORY } from "./../../config/apiRoutes.api";

const Orders = ({ id, setCompanyNameDetails }) => {
	const token = sessionStorage.getItem("token");
	const request = client(token);

	const [customerId, setCustomerId] = useState("");
	const [customerOrderHistories, setCustomerOrderHistories] = useState([]);
	const [customerOrderHistoryRows, setCustomerOrderHistoryRows] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalImage, setTotalImage] = useState(1);
	const [totalTurnaroundTime, setTotalTurnaroundTime] = useState(1);
	const [totalError, setTotalError] = useState(1);
	const [totalErrorRate, setTotalErrorRate] = useState(1);
	const [totalRevenue, setTotalRevenue] = useState(1);
	const [totalPVSCost, setTotalPVSCost] = useState(1);

	useEffect(() => {
		if (token !== null) {
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_ORDER_HISTORY +
						`/${id}` +
						`?page=${pageNumber}`
				)
				.then((response) => {
					console.log("res", response);
					setCustomerOrderHistories(response.data.customerOrderHistory);
					setCustomerOrderHistoryRows(response.data.customerOrderHistory.data);
					setTotalImage(response.data.total_images);
					setTotalTurnaroundTime(response.data.turnaround_time);
					setTotalError(response.data.total_error);
					setTotalErrorRate(response.data.error_rate);
					setTotalRevenue(response.data.revenue);
					setTotalPVSCost(response.data.pvs_cost);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumber]);

	//Paginations
	const PaginationItems = () => {
		const { total, per_page, current_page } = customerOrderHistories;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};
	const handleNextPagination = () => {
		if (
			pageNumber !==
			Math.ceil(customerOrderHistories.total / customerOrderHistories.per_page)
		) {
			setPageNumber(pageNumber + 1);
		}
	};
	const handlePreviousPagination = () => {
		if (
			pageNumber !==
			Math.ceil(customerOrderHistories.total / customerOrderHistories.per_page)
		) {
			setPageNumber(pageNumber - 1);
		}
		setPageNumber(pageNumber - 1);
	}; //end of Paginations

	const chooseday = [
		{ label: "Today", value: 355 },
		{ label: "Today", value: 54 },
		{ label: "Today", value: 58 },
	];

	const invoices = [
		{
			id: "1",
			BatchName: "0000054",
			CreatedOn: "19 Aug 2022 12:01",
			ImageCount: "125",
			ErrorCount: "5",
			ErrorRate: "0.40%",
			TurnaroundTime: "04:04:39",
			Revenue: "$750",
			PVSCost: "100",
			Status: <p style={{ color: "#F1A100" }}>Processing</p>,
		},
		{
			id: "1",
			BatchName: "0000054",
			CreatedOn: "19 Aug 2022 12:01",
			ImageCount: "125",
			ErrorCount: "5",
			ErrorRate: "0.40%",
			TurnaroundTime: "04:04:39",
			Revenue: "$750",
			PVSCost: "100",
			Status: <p style={{ color: "#F1A100" }}>Processing</p>,
		},
		{
			id: "1",
			BatchName: "0000054",
			CreatedOn: "19 Aug 2022 12:01",
			ImageCount: "125",
			ErrorCount: "5",
			ErrorRate: "0.40%",
			TurnaroundTime: "04:04:39",
			Revenue: "$750",
			PVSCost: "100",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			id: "1",
			BatchName: "0000054",
			CreatedOn: "19 Aug 2022 12:01",
			ImageCount: "125",
			ErrorCount: "5",
			ErrorRate: "0.40%",
			TurnaroundTime: "04:04:39",
			Revenue: "$750",
			PVSCost: "100",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			id: "1",
			BatchName: "0000054",
			CreatedOn: "19 Aug 2022 12:01",
			ImageCount: "125",
			ErrorCount: "5",
			ErrorRate: "0.40%",
			TurnaroundTime: "04:04:39",
			Revenue: "$750",
			PVSCost: "100",
			Status: <p style={{ color: "#008E1A" }}>Completed</p>,
		},
		{
			id: "1",
			BatchName: "0000054",
			CreatedOn: "19 Aug 2022 12:01",
			ImageCount: "125",
			ErrorCount: "5",
			ErrorRate: "0.40%",
			TurnaroundTime: "04:04:39",
			Revenue: "$750",
			PVSCost: "100",
			Status: <p style={{ color: "#008E1A" }}>Completed</p>,
		},
	];
	const columns = [
		{
			dataField: "BatchName",
			text: "Batch Name",
			number: "1",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "CreatedOn",
			text: "Created On",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "ImageCount",
			text: "Image Count",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "ErrorCount",
			text: "Error Count",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "ErrorRate",
			text: "Error Rate",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "TurnaroundTime",
			text: "Turnaround Time",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},

		{
			dataField: "Revenue",
			text: "Revenue",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "PVSCost",
			text: "PVS Cost",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Status",
			text: "Status",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
	];

	const defaultSorted = [
		{
			dataField: "name",
			order: "desc",
		},
	];

	// <BootstrapTable
	//     keyField="id"
	//     data={invoices}
	//     columns={columns}
	//     striped
	//     hover
	//     condensed
	//     defaultSortDirection="asc"
	// />

	return (
		<>
			<div className="customer-search">
				<Form>
					<Row className="align-items-center">
						<Col lg={2} sm={6}>
							<div className="form-group">
								<Select
									options={chooseday}
									defaultValue={{ label: "Today", value: 355 }}
								/>
							</div>
						</Col>
						<Col lg={5} sm={6}>
							<div className="form-group">
								<Form.Control
									type="search"
									placeholder="Search Batch Name"
									className="me-2"
									aria-label="Search"
								/>{" "}
							</div>
						</Col>
					</Row>
				</Form>
			</div>

			<div className="d-sm-flex ordertab  mb-4">
				<Card className="bg-card">
					{/* <Card.Img variant="top" src={customertotalimagecount} /> */}
					<Card.ImgOverlay>
						<Card.Img variant="top" src={total} />
						<Card.Body>
							<Card.Title>Total Image Count</Card.Title>
							<Card.Text>{totalImage}</Card.Text>
						</Card.Body>
					</Card.ImgOverlay>
				</Card>

				<Card>
					<Card.Img variant="top" src={customertotalerrorcount} />
					<Card.Body>
						<Card.Title>Total Error Count</Card.Title>
						<Card.Text>{totalError}</Card.Text>
					</Card.Body>
				</Card>
				<Card>
					<Card.Img variant="top" src={customertotalerrorrate} />
					<Card.Body>
						<Card.Title>Total Error Rate</Card.Title>
						<Card.Text>{totalErrorRate}%</Card.Text>
					</Card.Body>
				</Card>
				<Card>
					<Card.Img variant="top" src={averageturnaroundtime} />
					<Card.Body>
						<Card.Title>Average Turnaround Time</Card.Title>
						<Card.Text>{totalTurnaroundTime}</Card.Text>
					</Card.Body>
				</Card>

				<Card>
					<Card.Img variant="top" src={totalrevenue} />
					<Card.Body>
						<Card.Title>Total Revenue</Card.Title>
						<Card.Text>${totalRevenue}</Card.Text>
					</Card.Body>
				</Card>
				<Card>
					<Card.Img variant="top" src={revenue} />
					<Card.Body>
						<Card.Title>Total PVS Cost</Card.Title>
						<Card.Text>{totalPVSCost}</Card.Text>
					</Card.Body>
				</Card>
			</div>

			<div
				className={`table-responsive overviewtables table-b`}
				style={{ marginTop: "3rem !important" }}
			>
				<Table hover>
					<thead>
						<tr>
							{typeof customerOrderHistories.data !== "undefined" &&
								customerOrderHistories.data.length !== 0 && (
									<>
										<th>Batch Name</th>
										<th>Created On</th>
										<th>Image Count</th>
										<th>Error Count</th>
										<th>Error Rate</th>
										<th>Turnaround Time</th>
										<th>Revenue</th>
										<th>PVS Cost</th>
										<th>Status</th>
									</>
								)}
						</tr>
					</thead>
					<tbody>
						{customerOrderHistories.data !== undefined &&
							customerOrderHistoryRows.map((item, index) => {
								const list = (
									<>
										<tr key={index}>
											<td>{item.batch_number}</td>
											<td>{new Date(item.created_at).toLocaleDateString()}</td>
											<td>{item._of_images}</td>
											<td>{item._of_errors}</td>
											<td>{item.error_rate}</td>
											<td>{item.turnaround_time}</td>
											<td>{item.total_revenue}</td>
											<td>{item.mindreach_PVS_cost}</td>

											<td>{item.status}</td>
										</tr>
									</>
								);
								return list;
							})}
						{typeof customerOrderHistories.data !== "undefined" &&
							customerOrderHistories.data.length === 0 && <NoDataFound />}
					</tbody>
				</Table>
			</div>
			<Row className="mt-4">
				<Col md={{ span: 4, offset: 8 }}>
					{
						<Pagination>
							<Pagination.Prev onClick={() => handlePreviousPagination()} />
							{PaginationItems()}
							<Pagination.Next onClick={() => handleNextPagination()} />
						</Pagination>
					}
				</Col>
			</Row>
		</>
	);
};

export default Orders;
