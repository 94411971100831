import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Form from 'react-bootstrap/Form';
import user from '../../assets/Images/users.svg';
import AddassignTeam from './AddassignTeam'




const AssignedTeam = () => {
    const AssignedTeam = [{
        id: '1',
        name: "Kobe Bryant",
        Role: 'Coordinator',
        Shift: 'Day',      
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    
      },
      {
        id: '1',
        name: "Kobe Bryant",
        Role: 'Coordinator',
        Shift: 'Day',      
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    
      },
      {
        id: '1',
        name: "John Doe",
        Role: 'Coordinator',
        Shift: 'Day',      
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    
      },
      {
        id: '1',
        name: "Weekend",
        Role: 'Editor',
        Shift: 'Night',      
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    
      },
      {
        id: '1',
        name: "Michael Jordan",
        Role: 'Editor',
        Shift: 'Weekend',      
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    
      },
      {
        id: '1',
        name: "Aaron Redgers",
        Role: 'QC',
        Shift: 'Holiday',      
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    
      }
    ]
    const columns = [{
        dataField: 'name',
        text: 'Name',
        number: '1',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '145px', textAlign: 'left' };
        }
    
      }, {
        dataField: 'Role',
        text: 'Role',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '145px', textAlign: 'left' };
        }
      },
      {
        dataField: 'Shift',
        text: 'Shift',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '145px', textAlign: 'left' };
        }
      },
      {
        dataField: 'Status',
        text: 'Status',
        headerStyle: (colum, colIndex) => {
          return { width: '145px', textAlign: 'left' };
        }
    
      }    
    
      ];
    
      const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
      }];
    
    
      // <BootstrapTable
      //   keyField="id"
      //   data={AssignedTeam}
      //   columns={columns}
      //   striped
      //   hover
      //   condensed
      //   defaultSortDirection="asc"
      // />


  return (
    <>

<div className="d-sm-flex mb-3">
              <div className="customer-search">
                <Form>
                  <Row className="align-items-center">
                    <Col xs={2}>

                      <Form.Select>
                        <option>Editors</option>
                        <option>Editors</option>
                        <option>Large</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Control
                        type="search"
                        placeholder="Search Staff"
                        className="me-2"
                        aria-label="Search"
                      />
                    </Col>
                    <Col>
                      <p className="customer-count"><span><img src={user} className="img-fluid" alt="mindreach images" /></span>20 User(s)</p>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div>
             <AddassignTeam/>
              </div>
            </div>  


            <div className="table-responsive table-b">
              <BootstrapTable keyField='id' data={AssignedTeam} columns={columns} />

            </div>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 8 }}>
                {
                  <Pagination>
                    <Pagination.Prev />
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Item>{6}</Pagination.Item>
                    <Pagination.Next />
                  </Pagination>
                }
              </Col>
            </Row>


    </>
  )
}

export default AssignedTeam