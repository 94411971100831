import React, { useState } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";

import { apiURL } from "../../../../../data/filepath";
import { client } from "../../../../../data/API/GobalClientAxiosRequest";
import { UPDATE_CUSTOMER_DISTRIBUTION_BATCH } from "../../../../../config/apiRoutes.api";

/**
 * Reusable modal component.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.heading - The title or heading of the modal.
 * @param {string} props.message - The content or message of the modal.
 * @returns {JSX.Element} - The rendered modal component.
 */
const DistributionBatchModal = (props) => {
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	//Declarations of state variable
	/* const [coordinatorBatchImageNo, setCoordinatorBatchImageNo] = useState("");
	const [editorBatchImageNo, setEditorBatchImageNo] = useState("");
	const [qcBatchImageNo, setQcBatchImageNo] = useState(0); */
	const [status, setStatus] = useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		if (token) {
			const request = client(token);

			bodyFormData.append("batch_coordinator_image_no", props.coordinatorBatchImageNo);
			bodyFormData.append("batch_editor_image_no", props.editorBatchImageNo);
			bodyFormData.append("batch_qc_image_no", props.qcBatchImageNo);
			request
				.post(apiURL + UPDATE_CUSTOMER_DISTRIBUTION_BATCH+ `/${props.id}`, bodyFormData)
				.then((response) => {
					console.log("add", response);
					props.setStatus("Success");
					props.setDistributionBatchModal(false);
					props.setSubmitStatusMessage(response.data.message);
					props.setModalShowEdit(true);
				})
				.catch((err) => {
					setStatus("Error");
					props.setSubmitStatusMessage(err);
				});
		}
	};
	return (
		<Modal
			{...props}
			// backdrop="static"
			// keyboard={false}

			size="lg"
			// aria-labelledby="contained-modal-title-vcenter"
			aria-labelledby="example-modal-sizes-title-lg"
			contentClassName="modal-content-edit--type "
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title
					id="example-modal-sizes-title-lg"
					className="section-title fonts"
					style={{ marginLeft: "10px" }}
				>
					{props.heading}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className="section-title fonts"
				style={{ marginLeft: "10px" }}
			>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Coordinator Batch Size</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="number"
								placeholder="10"
								autoFocus
								value={props.coordinatorBatchImageNo}
								onChange={(e) => props.setCoordinatorBatchImageNo(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">Editor Batch Size</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="number"
								placeholder="10"
								value={props.editorBatchImageNo}
								onChange={(e) => props.setEditorBatchImageNo(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
				<div className="form-group mt-3 mb-4">
					<Row>
						<Form.Label className="col-sm-3">User Name</Form.Label>
						<Col sm={11}>
							<Form.Control
								type="number"
								placeholder="10"
								value={props.qcBatchImageNo}
								onChange={(e) => props.setQcBatchImageNo(e.target.value)}
							/>
						</Col>
					</Row>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="form-group">
					<div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
						<div className="">
							<Button
								variant=""
								className="puple btns mr-3"
								type="submit"
								onClick={props.onHide}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								className="btns"
								type="submit"
								onClick={(e) => handleSubmit(e)}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default DistributionBatchModal;
