import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";

import Loader from "../../components/Loader";
import folder from "../../assets/Images/folder-coordinator.svg";
import folderblue from "../../assets/Images/folder-blue.svg";
// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
	GET_ALL_COORDINATORS,
	ADMIN_GET_COORDINATORS_DETAILS,
	CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID,
} from "../../config/apiRoutes.api";

import { VIEW_IMAGE_COORDINATOR_WORKSPACE } from "../../config/routes";

// import { currentUserId } from "../../utils/Coordinator/getCurrentUserId";

const RightSidebar = ({
	folders,
	loader,
	id,
	setImages,
	mainImageCount,
	mainImageSettingsCount,
	setItemList,
	imagesMainFolder,
	folderMainFolder,
	// setFolderListOrder,
	// folder,
	setFolder,
	setParentFolderDetails,
	// folder_files_sub,
	// setFolderListSub,
	list_display,
	setDisplay,
	setFolderList,
	folder_list,
	folder_name,
	setFolderName,
	folder_list_menu,
	setFolderListMenu,
	loader_sub_menu,
	setLoaderSubMenu,
	clickSelect,
	setClickSelect,
	hide_list_menu,
	setHideListMenu,
	batchId,
	token,
	selectOptions,
	list_sub_display,
	setDisplaySub,
	setDefaultFolder,
	fetchFolder,
	setSubFolderOpen,
	currentUserId,
	defaultFolder,
	batch_id_query,
	folder_id_query,
	mainFolderId,
	// folder_list_menu
}) => {
	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();
	// const currentUserId = getCurrentUserId();
	let folderIndex = sessionStorage.getItem("folderIndex");
	let folders_path_set = sessionStorage.getItem("folder");
	let folder_path_id = sessionStorage.getItem("folder_path_id");
	let main_folder_click = sessionStorage.getItem("main");
	let main_folder_id =
		sessionStorage.getItem("main_folder") !== null &&
		sessionStorage.getItem("main_folder");

	let folder_path =
		sessionStorage.getItem("folder_path") !== null &&
		sessionStorage.getItem("folder_path");

	let folder_sub_path =
		sessionStorage.getItem("folder_path_sub") !== null &&
		sessionStorage.getItem("folder_path_sub");

	let folder_list_Sub =
		sessionStorage.getItem("folder_list_1") !== undefined &&
		sessionStorage.getItem("folder_list_1");
	const batch_id_selected =
		sessionStorage.getItem("batch_id") !== undefined &&
		sessionStorage.getItem("batch_id");
	const request = client(token);
	// const [list_display, setDisplay] = useState(false);

	const [folder_files_sub, setFolderListSub] = useState([]);
	const [total_folder_set, setFolderTotalSet] = useState([]);

	// const [folder_selectName, setFolderName] = useState("");
	const [folder_selectNameSub, setFolderNameSub] = useState("");
	const [error, setError] = useState("");
	const handleChangeMainFolder = () => {
		setImages(imagesMainFolder);
		setFolderList(folderMainFolder);
		// setParentFolderDetails(null);
		// setFolderName("");
		sessionStorage.setItem("main", 1);
		sessionStorage.setItem("folderIndex", 0);
		sessionStorage.setItem("folder_path_id", main_folder_id);
		sessionStorage.setItem("folder_path_sub_left", "");
		const isUserIdValid = currentUserId !== false;
		const isBatchIdValid = batchId !== false;
		const isMainFolderId = main_folder_id !== "" ? true : false;
		if (isUserIdValid && isBatchIdValid && isMainFolderId) {
			const url = `${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${currentUserId}/${batchId}/${main_folder_id}`;
			navigate(url);
		}
	};
	const handleChangeFolder = (item, batchId) => {
		// setFolderName(item);
		// setDisplaySub(false);
		// setDisplay(true);
		// setLoaderSubMenu(true);
		// setHideListMenu((val) => !val);
		// if (!!!hide_list_menu) {
		// 	if (token) {
		// 		request
		// 			.get(
		// 				apiURL +
		// 					ADMIN_GET_COORDINATORS_DETAILS +
		// 					`/${currentUserId}` +
		// 					`?batch_number=${batchId}` +
		// 					`&folder_path=${item}`
		// 			)
		// 			.then((response) => {
		// 				// setFolderList(folderMainFolder);
		// 				setFolderList(response.data.data.folders);
		// 				setFolderListMenu(response.data.data.folders);
		// 				setDisplaySub(false);
		// 				// setFolderListMenu([]);
		// 				setClickSelect(false);
		// 				// setImages(response.data.data.images);
		// 				// setFolderName(item);
		// 				// setFolderListMenu([]);
		// 				// setClickSelect(false);
		// 				setLoaderSubMenu(false);
		// 				setClickSelect(false);
		// 				setHideListMenu(true);
		// 			})
		// 			.catch((err) => {
		// 				let message =
		// 					typeof err.response !== "undefined"
		// 						? err.response.data.message
		// 						: err.message;
		// 				setError(message);
		// 			});
		// 	} else {
		// 		if (token) {
		// 			request
		// 				.get(
		// 					apiURL +
		// 						ADMIN_GET_COORDINATORS_DETAILS +
		// 						`/${selectOptions}` +
		// 						`?batch_number=${batchId}` +
		// 						`&folder_path=${item}`
		// 				)
		// 				.then((response) => {
		// 					// setFolderList(folderMainFolder);
		// 					setFolderList(response.data.data.folders);
		// 					setFolderListMenu(response.data.data.folders);
		// 					// setParentFolderDetails(null);
		// 					// setFolderName(item);
		// 					// setDisplaySub(false);
		// 					// setFolderListMenu([]);
		// 					setClickSelect(false);
		// 					setDisplaySub(false);
		// 					setImages(response.data.data.images);
		// 					// setParentFolderDetails(null);
		// 					// setFolderName("");
		// 					// setFolderListMenu([]);
		// 					// setClickSelect(false);
		// 					setLoaderSubMenu(false);
		// 					setClickSelect(false);
		// 					setHideListMenu(false);
		// 				})
		// 				.catch((err) => {
		// 					let message =
		// 						typeof err.response !== "undefined"
		// 							? err.response.data.message
		// 							: err.message;
		// 					setError(message);
		// 				});
		// 		}
		// 	}
		// }
	};
	const handleChange = (item, path, batch_Id) => {
		// // setDisplay(true);
		// setLoaderSubMenu(true);
		setLoaderSubMenu(true);
		// setDisplaySub((val) => !val);
		sessionStorage.setItem("main", 0);
		sessionStorage.setItem("folderIndex", 1);

		sessionStorage.setItem("folder_path", path);
		sessionStorage.setItem("folder_path_id", item);
		sessionStorage.setItem("folder_path_sub", "");
		sessionStorage.setItem("folder_path_sub_left", "");
		if (item !== folder_id_query) {
			// navigate(
			// 	`${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${currentUserId}/${batchId}/${item}}`
			// );
			const isUserIdValid = currentUserId !== false;
			const isBatchIdValid = batchId !== false;
			if (isUserIdValid && isBatchIdValid) {
				const url = `${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${currentUserId}/${batchId}/${item}`;
				navigate(url);
			}
		}

		if (token) {
			// request;
			// .get(
			// 	apiURL +
			// 		ADMIN_GET_COORDINATORS_DETAILS +
			// 		`/${currentUserId}` +
			// 		`?batch_number=${batchId}` +
			// 		`&folder_path=${item}`
			// )
			request
				.get(
					apiURL +
						CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
						`/${currentUserId}` +
						`/${batch_id_selected}` +
						`/${item}`
				)
				.then((response) => {
					setFolderList(response.data.data.folders);

					setFolderName(path);
					setItemList(response.data.data.images);
					setImages(response.data.data.images);
					// setHideListMenu(true);
					// setFoldersPath([]);
					// setParentFolderDetails(null);
					// setFolderList(response.data.data.folders);
					// setFolderListMenu(response.data.data.folders);
					// setFolderName(item);
					// setDefaultFolder(item);
					// fetchFolder();
					// setItemList(response.data.data.images);
					// setImages(response.data.data.images);
					// setFoldersPath([]);
					// setEditGroups(response.data.data.editGroups);
					// setEditTypes(response.data.data.editTypes);

					// setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					// setDisplay(true);

					// setDisplaySub(true);
					// // setMultipleImagesId([]);
					// setClickSelect(false);
					// setHideListMenu(true);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleChangeSubFolder = useCallback(
		(items, item, path, batch_Id) => {
			// //
			// // setDisplaySub(true);
			// setLoaderSubMenu(true);
			setLoaderSubMenu(true);
			sessionStorage.setItem("main", 0);
			sessionStorage.setItem("folder_path_sub_left", item);
			sessionStorage.setItem("folder_path", path);
			sessionStorage.setItem("folder_path_id", items);
			sessionStorage.setItem("folder_path_sub", items);
			console.log("folder_path_sub check", item);
			if (items !== folder_id_query) {
				// navigate(
				// 	`${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${currentUserId}/${batch_id_selected}/${items}}`
				// );
				const isUserIdValid = currentUserId !== false;
				const isBatchIdValid = batchId !== false;
				if (isUserIdValid && isBatchIdValid) {
					const url = `${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${currentUserId}/${batchId}/${items}`;
					navigate(url);
				}
			}
			if (token) {
				request
					.get(
						apiURL +
							CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
							`/${currentUserId}` +
							`/${batch_id_selected}` +
							`/${item}`
					)
					.then((response) => {
						setFolderList(response.data.data.folders);
						// setFolderListMenu(response.data.data.folders);

						setImages(response.data.data.images);
						// setFolderName(items);
						setParentFolderDetails(null);

						setFolderTotalSet([folder_list, folder_list_menu]);
						setLoaderSubMenu(false);
						setClickSelect(false);
						setDisplaySub(true);
					})
					.catch((err) => {
						let message =
							typeof err.response !== "undefined"
								? err.response.data.message
								: err.message;
						setError(message);
					});
			}
		},
		[folder_list_menu]
	);
	// Helper function to check if a folder is selected
	const isFolderSelected = useCallback(
		(folderPath, selectedFolderPath, path) => {
			return folderPath === selectedFolderPath;
		},
		[folder_sub_path, folder_path_id]
	);
	useEffect(() => {
		if (folder_path) {
			sessionStorage.setItem("folder_path", folder_path);
		}
	}, [folder_path]);
	// console.log(
	// 	"right side folder ",
	// 	// JSON.parse(folder_list_Sub) !== null &&
	// 	// 	Array.isArray(JSON.parse(folder_list_Sub)) &&
	// 	JSON.parse(folders_path_set)[0].path,
	// 	JSON.parse(folder_list_Sub)
	// )
	useEffect(() => {
		let parsedFolderList;

		try {
			parsedFolderList = JSON.parse(folder_list_Sub);
		} catch (e) {
			console.error("Failed to parse folder_list_Sub:", e);
			return;
		}

		if (Array.isArray(parsedFolderList) && parsedFolderList.length > 0) {
			setFolderListMenu((prev) => {
				// Only update state if the new value is different from the current state
				if (!isEqual(prev, parsedFolderList)) {
					return parsedFolderList;
				}
				return prev; // No update needed
			});
		}
	}, [folder_list_Sub !== null, folder_id_query !== null, list_sub_display]);

	console.log("folder_path_id", main_folder_click);
	return (
		<>
			<div className="editororderdetails coordinator">
				<ul className="treeviewlist custom-viewlist">
					<li>
						<li
							onClick={handleChangeMainFolder}
							className={
								Number(main_folder_click) === 1 ? "order-folder--hover" : ""
							}
						>
							<i className="fa fa-angle-down" aria-hidden="true"></i>
							<span>
								<img
									src={Number(main_folder_click) === 1 ? folderblue : folder}
									style={{
										width: Number(main_folder_click) === 1 ? "24px" : "29px",
									}}
									className="img-fluid"
									alt="mindreach images"
								/>
							</span>
							<span className="catgory-name">
								Main Folder
								<span className="order-folder--count">
									({mainImageSettingsCount}/{mainImageCount})
								</span>
							</span>
						</li>
						{/* {loader && (
							<ul className="first-treeview">
								<li>
									<Loader />
								</li>
							</ul>
						)} */}
						{JSON.parse(folders_path_set) !== null &&
							JSON.parse(folders_path_set).map((item, index) => {
								const folderIdQuery = String(folder_id_query); // or Number(folder_id_query)
								const encryptedId = String(item.folder_details.encrypted_id); // or Number(item.folder_details.encrypted_id)

								console.log(
									"folder_id_query called",
									folder_name,
									// encryptedId !== "" &&
									// 	folderIdQuery !== "" &&
									!sessionStorage.getItem("folder_path_sub_left")
								);
								return (
									<ul className="first-treeview">
										<li
											// key={index}
											// className={
											// 	(folders.length || folder_selectNameSub) &&
											// 	isFolderSelected(item.path, folder_name)
											// 		? "folder__marker_blue"
											// 		: ""
											// }
											className={
												!sessionStorage.getItem("folder_path_sub_left") &&
												folder_path_id === folder_id_query &&
												Number(main_folder_click) === 0
													? "order-folder--hover"
													: ""
											}
										>
											<i
												onClick={() => {
													// setHideListMenu((val) => !val);
													// handleChangeFolder(item.path, batchId);
													handleChange(
														item.folder_details.encrypted_id,
														item.path
													);
													sessionStorage.setItem("folderIndex", index);
												}}
												className={
													list_sub_display
														? "fa fa-angle-down"
														: "fa fa-angle-right"
												}
												aria-hidden="true"
											></i>
											<span
												onClick={() => {
													handleChange(
														item.folder_details.encrypted_id,
														item.path
													);
													sessionStorage.setItem("folderIndex", index);
												}}
											>
												<img
													src={
														// folder_path_id === item.folder_details.encrypted_id
														!sessionStorage.getItem("folder_path_sub_left") &&
														folder_path_id === folder_id_query &&
														Number(main_folder_click) === 0
															? folderblue
															: folder
													}
													style={{
														width:
															// folder_path_id ===
															// item.folder_details.encrypted_id
															!sessionStorage.getItem("folder_path_sub_left") &&
															Number(main_folder_click) === 0
																? "24px"
																: "29px",
													}}
													className="img-fluid"
													alt="mindreach images"
												/>
											</span>
											<span
												className="catgory-name"
												onClick={() => {
													handleChange(
														item.folder_details.encrypted_id,
														item.path
													);
													sessionStorage.setItem("folderIndex", index);
													setDisplaySub(true);
													// setHideListMenu(true);
												}}
											>
												{item.path}
												<span className="order-folder--count">
													({item.settings_count}/{item.image_count})
												</span>
											</span>
										</li>

										{folder_list_menu.map((items, idKey) => {
											const selected =
												sessionStorage.getItem("folder_path_sub_left") ===
													items.path ||
												(sessionStorage.getItem("folder_path_sub_left") ===
													"" &&
													"" &&
													isFolderSelected(
														items.folder_details.encrypted_id,
														// id[1]
														folder_sub_path
													));
											console.log(" folder selected ", items);
											return (
												<>
													{
														<ul
															className={
																// (folder_list_menu.length ||
																// 	folder_selectNameSub) &&
																// isFolderSelected(items.path, folder_name)
																// 	? "folder__marker_blue second-treeview"
																// 	: "second-treeview"
																selected && folder_path_id === folder_id_query
																	? //
																	  "folder__marker_blue second-treeview"
																	: "second-treeview"
															}
															key={index}
														>
															<li
																onClick={() => {
																	handleChangeSubFolder(
																		// items.path,
																		// item.path,
																		// batchId
																		items.folder_details.encrypted_id,
																		items.path,
																		item.path
																	);
																	setHideListMenu(true);
																}}
															>
																<i
																	className="fa fa-angle-right"
																	aria-hidden="true"
																></i>
																<span>
																	<img
																		// src={
																		// 	(folder_list_menu.length ||
																		// 		folder_selectNameSub) &&
																		// 	isFolderSelected(
																		// 		items.path,
																		// 		folder_name
																		// 	)
																		// 		? folderblue
																		// 		: folder
																		// }
																		src={
																			selected &&
																			folder_path_id === folder_id_query
																				? folderblue
																				: folder
																		}
																		style={{
																			width: isFolderSelected(
																				items.folder_details.encrypted_id,
																				folder_sub_path
																			)
																				? "24px"
																				: "29px",
																		}}
																		className="img-fluid"
																		alt="mindreach images"
																	/>
																</span>
																<span className="catgory-name">
																	{items.path}
																	<span className="order-folder--count">
																		({items.settings_count}/{items.image_count})
																	</span>
																</span>
															</li>
														</ul>
													}
													{!!list_display &&
														items === folder_selectNameSub &&
														folder_files_sub.map((items_sub) => {
															return (
																<>
																	<ul className="third-treeview">
																		<li>
																			<i
																				className="fa fa-angle-right"
																				aria-hidden="true"
																			></i>
																			<span>
																				<img
																					src={folder}
																					style={{ width: "28px" }}
																					className="img-fluid"
																					alt="mindreach images"
																				/>
																			</span>
																			<span className="catgory-name">
																				{items_sub.path.path}
																				<span className="order-folder--count">
																					({items_sub.path.imageSettingsCount}/
																					{items_sub.path.imageCount})
																				</span>
																			</span>
																		</li>
																	</ul>
																</>
															);
														})}
												</>
											);
										})}
									</ul>
								);
							})}
					</li>
				</ul>
			</div>
		</>
	);
};

export default RightSidebar;
