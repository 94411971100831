import React from "react";
import Spinner from "react-bootstrap/Spinner";

/**
 * Component for displaying a loading spinner.
 * @returns {JSX.Element} - The rendered Loader component.
 */
const Loader = ({ className }) => {
  return (
    <div
      style={{ zIndex: 9999999999 }}
      className={className !== undefined ? className : `loader`}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default Loader;
