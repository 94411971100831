import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import plus from '../../../../assets/Images/plus.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const AddassignTeam = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>


            <Button variant="primary"
            // onClick={handleShow}
            >
                <span>
                    <img src={plus} className="img-fluid mr-2" /></span>Add New
            </Button>{' '}

            <Modal show={show}
                className="addtypegroup"
                size="lg"
                centered
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Assigned Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={8}>
                            <Form>

                                <Form.Group className="" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Filter By</Form.Label>
                                    <div className="d-sm-flex col-gap-20">
                                        <div className="form-group flex-grow-1">
                                            <Form.Select>
                                                <option>Coordinator</option>
                                                <option>Coordinator</option>
                                            </Form.Select>
                                        </div>
                                        <div className="form-group flex-grow-1">
                                            <Form.Select>
                                                <option>Night Shift</option>
                                                <option>Night Shift</option>
                                            </Form.Select></div>
                                    </div>


                                </Form.Group>

                                <Form.Group className="mt-2" controlId="exampleForm.ControlInput1">
                                    <div className="form-group flex-grow-1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Select>
                                            <option>Kobe Bryant</option>
                                            <option>Kobe Bryant</option>
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                            </Form>


                            <ul className="add-team-list mt-4">
                                <li>Staff ID: </li>
                                <li>00217</li>

                            </ul>

                            <ul className="add-team-list">

                                <li>Role: </li>
                                <li>Coordinator</li>

                            </ul>
                            <ul className="add-team-list">

                                <li>Shit:</li>
                                <li>Night Shift </li>

                            </ul>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-sm-flex justify-content-end">
                        <div>
                            <Button variant="primary" className="btns" onClick={handleClose}>
                                Add
                            </Button> </div>
                    </div>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default AddassignTeam