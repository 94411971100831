import React from 'react'
import Form from 'react-bootstrap/Form';
import user2 from '../../assets/Images/user1.svg'
import user4 from '../../assets/Images/user1.svg'
import user5 from '../../assets/Images/user1.svg'
import user6 from '../../assets/Images/user1.svg'

const UserChatSearchsidebar = () => {
    return (
        <>

            <div className="usersearch">
                <h4 className="mb-3 mt-3">Users</h4>

                <Form>

                    <Form.Control
                        type="search"
                        placeholder="Search user"
                        className="me-2"
                        aria-label="Search"
                    />

                </Form>

                <div className="namelist mt-3 supervisor-list">
                    <ul>
                        <li><div className="profilepic">
                            <img className="img-fluid" alt="mindreach images" src={user4} alt="" />
                        </div>
                            <div className="online-icon">
                                <span>Kobe Bryant</span>
                                <i className="fa fa-circle"></i>
                            </div>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user2} alt="" />
                        </div>
                            <div className="online-icon">
                                <span>Kobe Bryant</span>
                                <i className="fa fa-circle"></i>
                            </div>
                        </li>
                        <li><div className="profilepic">
                            <img className="img-fluid" alt="mindreach images" src={user5} alt="" />
                        </div>
                            <div className="online-icon">
                                <span>Ja Morant</span>
                                <i className="fa fa-circle"></i>
                            </div>

                        </li>
                        <li><div className="profilepic">
                            <img className="img-fluid" alt="mindreach images" src={user6} alt="" />
                        </div>
                            <div className="online-icon">
                                <span>Kevin Durant</span>
                                <i className="fa fa-circle"></i>
                            </div>

                        </li>

                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user4} alt="" />
                        </div>
                            <div className="online-icon">
                                <span>Kobe Bryant</span>
                                <i className="fa fa-circle"></i>
                            </div>

                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user2} alt="" />
                        </div>
                            <div className="online-icon">
                                <span>Stephen Curry</span>
                                <i className="fa fa-circle"></i>
                            </div>

                        </li>
                        <li><div className="profilepic">
                            <img className="img-fluid" alt="mindreach images" src={user5} alt="" />
                        </div>
                            <span>Ja Morant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user6} alt="" />
                        </div>
                            <span>Kevin Durant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user4} alt="" />
                        </div>
                            <span>Kobe Bryant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user2} alt="" />
                        </div>
                            <span>Stephen Curry</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user5} alt="" />
                        </div>
                            <span>Ja Morant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user6} alt="" />
                        </div>
                            <span>Kevin Durant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user4} alt="" />
                        </div>
                            <span>Kobe Bryant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user2} alt="" />
                        </div>
                            <span>Stephen Curry</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user5} alt="" />
                        </div>
                            <span>Ja Morant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user6} alt="" />
                        </div>
                            <span>Kevin Durant</span>
                        </li>
                    </ul>
                </div>
            </div>


        </>
    )
}

export default UserChatSearchsidebar