import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Right from '../../../../assets/Images/right.svg';

const ProgressBar = ({role}) => {
    console.log("role",role)
    return (
        <>
           
                
                    <div className="section-title">
                        <h2>{role==="received"?"":role ==="order"? "Confirm Order":"Image Settings"}</h2>
                    </div>
                
               
                    <div className="signup-1 signup-2 signup-3 signup-4 text-center">
                        <div class="progress-bar-wrapper">
                            <ul class="progress-bars">
                                <li className="progressvisited">
                                    <div className="progressnumber">
                                        <img src={Right} className="img-fluid" alt="upload" /></div>
                                    Upload  
                                </li>
                                <li className={role !=="image"?"progressvisited":"progressactive"}>
                                    <div className="progressnumber">
                                    {role !=="image"?
                                    <img src={Right} className="img-fluid" alt="upload" />
                                    :"2"}
                                        </div>
                                    Settings</li>
                                <li className={role ==="order"?"progressactive":role ==="received"?"progressvisited":""}>
                                    <div className="progressnumber">
                                    {role ==="order"?
                                    "3"
                                    :role ==="received"?<img src={Right} className="img-fluid" alt="upload" />:"3"}
                                    </div>
                                Confirmation</li>
                            </ul>
                        </div>
                    </div>
               
        </>
    )
}

export default ProgressBar