import React, { useState } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Alert from "../../../../components/AlertCommon";

import { apiURL } from "./../../../../data/filepath";
import { client } from "./../../../../data/API/GobalClientAxiosRequest";
import { ADMIN_REASSIGN_EDITOR_IMAGES } from "./../../../../config/apiRoutes.api";
import { useEffect } from "react";

/**
 * Reusable modal component.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.heading - The title or heading of the modal.
 * @param {string} props.message - The content or message of the modal.
 * @returns {JSX.Element} - The rendered modal component.
 */
const ReAssignModal = (props) => {
  const bodyFormData = new FormData();
  const token = sessionStorage.getItem("token");
  const request = client(token);
  //Declarations of state variable
  const [staffError, setstaffError] = useState("");
  const [staff, setstaff] = useState("");
  const [disabled, setDisabled] = useState(false);
  /**
   * Handles to check form fields values.
   */
  const validateForm = () => {
    let is_error = true;
    if (!staff) {
      is_error = false;
      setstaffError("Required field");
    } else {
      setstaffError("");
    }
    return is_error;
  };

  /**
   * Handles to submit the form.
   * @param {Event} event - An event object of submit form.
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      console.log(" success");
      setDisabled(true);
      // Append form data to the bodyFormData object
      bodyFormData.append("editor_id", staff);
      props.selectedImagesID &&
        props.selectedImagesID.forEach((imgId) => {
          bodyFormData.append("image_id[]", imgId);
        });
      request
        .post(apiURL + ADMIN_REASSIGN_EDITOR_IMAGES, bodyFormData)
        .then((response) => {
          setDisabled(false);
          props.setshowReassign(false);
          props.setModalShow(true);
          //Console the  response
          if (response.data.status) {
            props.setResponseHeading("Success");
            props.setResponseMessage(response.data.message);
            props.reloadEditorRequest(props.selectedStaff);
            props.setSelectAll(false);
            props.resetSelectedImages();
            props.reassignSubmit(true);
            localStorage.getItem("ctrlClicked", true);
          } else {
            props.setResponseHeading("Error");
            props.setResponseMessage(response.data.message);
          }
          console.log("response", response.data.status);
        })
        .catch((err) => {
          let message =
            typeof err.response !== "undefined"
              ? err.response.data.message
              : err.message;
          setDisabled(false);
        });
    }
  };

  /**
   * Handles to reset the states.
   */
  useEffect(() => {
    setstaffError("");
    setstaff("");
  }, [props.show]);

  /**
   * Transforms an array of staff objects into a new format suitable for rendering in a select dropdown.
   * @param {Array} options - An array of staff objects.
   * @returns {Array} - An array of objects with 'label' and 'value' properties.
   */
  const selectLabel = (options) =>
    options.map(({ full_name: label, id: value, ...rest }) => ({
      label,
      value,
      ...rest,
    })); // change the key of staff array as label and value return a new array of object like [{value:"",label:""}]
  return (
    <Modal
      style={{ zIndex: 9999999999 }}
      {...props}
      size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      aria-labelledby="example-modal-sizes-title-lg"
      contentClassName="modal-content-edit--type "
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="example-modal-sizes-title-lg"
          className="section-title fonts"
          style={{ marginLeft: "10px" }}
        >
          {props.heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="section-title fonts sp_form"
        style={{ marginLeft: "10px" }}
      >
        <div className="form-group mt-3 mb-4">
          <Row>
            <Form.Label className="col-sm-3">Staff</Form.Label>
            <Col sm={11}>
              <Select
                options={selectLabel(props.options)}
                placeholder={"Please select"}
                onChange={(e) => {
                  if (e.value) {
                    setstaff(e.value);
                    setstaffError("");
                  } else {
                    setstaff("");
                    setstaffError("Required field");
                  }
                }}
              />
              {staffError && (
                <Alert variant={"danger"} messages={staffError} className="" />
              )}
            </Col>
          </Row>
        </div>

        {/* <div className="form-group mt-3 mb-4">
          <Row>
            <Form.Label className="col-sm-3">Staff</Form.Label>
            <Col sm={11}>
              <Form.Control
                as="textarea"
                placeholder="Comment"
                style={{ height: "148px" }}
                autoFocus
                autoComplete="off"
              />
            </Col>
          </Row>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <div className="form-group">
          <div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
            <div className="">
              <Button
                variant=""
                className="puple btns mr-3"
                type="submit"
                onClick={props.onHide}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="btns"
                type="submit"
                onClick={(e) => handleSubmit(e)}
                disabled={disabled}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReAssignModal;
