import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';
import pending from '../../../assets/Images/pending.svg'
import editing from '../../../assets/Images/editing.svg'
import revision from '../../../assets/Images/revision.svg'
import total from '../../../assets/Images/total.svg'
import bgtotal from '../../../assets/Images/bg-total.svg'
import profilenew from '../../../assets/Images/profile-new.svg';
import Button from 'react-bootstrap/Button';
import iconedit from '../../../assets/Images/icon-edit.svg';
import Table from 'react-bootstrap/Table';
// import "../../../css/style.css";
// import '../../../css/responsive.css'
import { Link } from 'react-router-dom';



const Overview = () => {

  const products = [{
    id: '1',
    BatchNo: "0000054",
    BatchDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000054 ',
    Type: 'Redo',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '2',
    BatchNo: "0000054",
    BatchDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000054 ',
    Type: 'Redo',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '3',
    BatchNo: "0000054",
    BatchDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000054 ',
    Type: 'Redo',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '4',
    BatchNo: "0000054",
    BatchDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000054 ',
    Type: 'Redo',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: '5',
    BatchNo: "0000054",
    BatchDate: '19 Aug 2022',
    Customer: 'Nike, Inc.',
    OrderNo: '0000054 ',
    Type: 'Redo',
    TotalPipeline: '750',
    Pending: '543',
    Coordinating: '137',
    Revision: '10',
    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  }
  ]

  const columns = [{
    dataField: 'BatchNo',
    text: 'Batch No.',
    number: '1',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }, {
    dataField: 'BatchDate',
    text: 'Batch Date',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Customer',
    text: 'Customer',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'OrderNo',
    text: 'Order No.',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Type',
    text: 'Type',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'TotalPipeline',
    text: 'Total in Pipeline',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Pending',
    text: 'Pending',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Coordinating',
    text: 'Coordinating',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Revision',
    text: 'Revision',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Status',
    text: 'Status',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }

  ];

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];


  // <BootstrapTable
  //   keyField="id"
  //   data={products}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />
  return (
    <>

      <Row>
        <Col lg={3} sm={5} md={3} className="custom-scroll-bar">
          <div className="customerdetailtable">
            <div className="uploadimg mt-4">
              <img src={profilenew} className="img-fluid d-block mx-auto" />
              <div className="edit">
                <i className="fa fa-edit"></i>
              </div>
            </div>

            <div className="profile-table mb-4 mt-4">
              <ul className="profile-details-list">

                <li>
                  <div className="titletext">
                    Staff ID:
                  </div>
                  <div className="titlecontent">
                    3
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Staff
                  </div>
                  <div className="titlecontent">
                    Kobe Bryant
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Address
                  </div>
                  <div className="titlecontent">
                    123 Mahogony Dr. Komplex Greenville Jakarta, DKI Jakarta Indonesia
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Phone #
                  </div>
                  <div className="titlecontent">
                    90982347981
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Email
                  </div>
                  <div className="titlecontent">
                    amarnath@capitalnumbers.com
                  </div>
                </li>
                <li>
                  <div className="titletext">
                    Status
                  </div>
                  <div className="titlecontent">
                    Inactive
                  </div>
                </li>
              </ul>
            </div>
            <div className="text-right">

              <Link to="/edit-overview">
                <Button variant="primary btn-edit"><span>
                  <img src={iconedit} /></span>
                  Edit</Button>{' '}
              </Link>

            </div>
          </div>
        </Col>
        <Col lg={9} sm={7} md={9} className=" staffoverview custom-scroll-bar">
          <h4 className="mb-4 mt-4">Current Production Pipeline</h4>


          <div className="d-sm-flex flex-sm-wrap overviewtab mb-4">

            <Card className="bg-card ">
              <Card.Img src={bgtotal} alt="Card image" />

              <Card.ImgOverlay>

                <Card.Img variant="top" src={total} />
                <Card.Body>
                  <Card.Title>Total in Pipeline</Card.Title>
                  <Card.Text>
                    10,999
                  </Card.Text>
                </Card.Body>
              </Card.ImgOverlay>
            </Card>

            <Card>
              <Card.Img variant="top" src={pending} />
              <Card.Body>
                <Card.Title>Pending</Card.Title>
                <Card.Text>
                  6,000
                </Card.Text>

              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={editing} />
              <Card.Body>
                <Card.Title>Editing</Card.Title>
                <Card.Text>
                  2,313
                </Card.Text>

              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={revision} />
              <Card.Body>
                <Card.Title>Revision</Card.Title>
                <Card.Text>
                  198
                </Card.Text>

              </Card.Body>
            </Card>
          </div>


          <div className="table-responsive table-b overviewtables ">
            <BootstrapTable keyField='id' data={products} columns={columns} />

          </div>
          <Row className="mt-4">
            <Col md={{ span: 4, offset: 8 }}>
              {
                <Pagination>
                  <Pagination.Prev />
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item>{4}</Pagination.Item>
                  <Pagination.Item>{5}</Pagination.Item>
                  <Pagination.Item>{6}</Pagination.Item>
                  <Pagination.Next />
                </Pagination>
              }
            </Col>
          </Row>



        </Col>
      </Row>



    </>
  )
}

export default Overview