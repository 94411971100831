import React from 'react'
import Table from 'react-bootstrap/Table';
import Delete from '../../../../assets/Images/delete.svg'
import Clone from '../../../../assets/Images/clone.svg'

import Editt from '../../../../assets/Images/editt.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import plus from '../../../../assets/Images/plus.svg';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';



const RoleTable = () => {

    return (
        <>
            <div className="d-sm-flex flex-sm-wrap justify-content-end col-gap-30-sm">
                <div>
                <Link to="/enterprise-add-role">
                    <Button variant="primary">
                        <span><img src={plus} className="img-fluid mr-2" /></span>
                        New Role</Button>{' '}
                        </Link>
                </div>
            </div>


            <div className="table-responsive table-b custom-new-table mt-4">

                <Table hover>
                    <thead>
                        <tr>
                            <th>Role Name</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Admin</td>
                            <td>The specific role description will be placed/defined here</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>

                                    </div>

                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Clone
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Clone} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>

                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Admin</td>
                            <td>The specific role description will be placed/defined here</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>
                                    </div>
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Clone
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Clone} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>

                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Admin</td>
                            <td>The specific role description will be placed/defined here</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>
                                    </div>
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top"{...props}>
                                                    Clone
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Clone} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>

                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>


                    </tbody>
                </Table>
            </div>

        </>
    )
}

export default RoleTable