import React, { useState, useEffect, useCallback, useRef } from "react";
import ImageModal from "./../Components/ImageModal";
import WarningSVG from "./../../../assets/Images/QCIcons/Warning.svg";
import RejectSVG from "./../../../assets/Images/QCIcons/Reject.svg";
import CancelIcon from "./../../../assets/Images/coordinator_actions_icon/cancel_Final.svg";
import CrossIcon from "./../../../assets/Images/QCIcons/cross.svg";
import CheckIcon from "./../../../assets/Images/coordinator_actions_icon/checkMark.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import EditorOrderDetails from "./QCOrderDetails";
import BatchDetails from "./BatchDetails";
import Autocomplete from "./../Components/AutoComplete";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import {
	QC_VIEW_IMAGES,
	QC_VIEW,
	QC_BULK_APPROVED,
	QC_BULK_REJECTS,
	CO_ORDINATOR_VIEW_PROFILE_SETTINGS,
	QC_TIME_LOG_WORKSPACE,
} from "../../../config/apiRoutes.api";
import Loader from "../../../components/Loader";

const EditorWorkspace = () => {
	const handleDateChange = (date, setSelectedDate) => {
		setSelectedDate(date);
	};
	const date = [
		{ label: "Custom", value: "custom" },
		{ label: "Today", value: "today" },
		{ label: "Current Month", value: "month" },
		{ label: "Current Year", value: "year" },
		{ label: "All", value: "" },
	];
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Retrieve the token from session storage
	const token = sessionStorage.getItem("token");
	// Create a request instance using the token
	const request = client(token);

	const wrapper = useRef(null);
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

	//Declarations of state

	const [error, setError] = useState("");
	const [isShow, setIsShow] = useState(false);
	const [image_details_view, setImageDetailsView] = useState({});
	const [image_details_view_id, setImageDetailsViewId] = useState("");
	const [showModal, setShowModal] = useState(false); // Image Modal
	const [batchImages, setBatchImages] = useState([]);
	const [batchData, setBatchData] = useState([]);
	const [image_details_modal, setImageDetailsModal] = useState(false);
	const [search, setSearch] = useState("");
	const [batchId, setBatchId] = useState("");
	const [editGroups, setEditGroups] = useState([]); // To set the edit groups
	const [editTypes, setEditTypes] = useState([]); // To set the edit types
	const [multiple_images_id, setMultipleImagesId] = useState([]);
	const [clickSelect, setClickSelect] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [qcId, setQCId] = useState("");
	const [selectImageName, setSelectedImageName] = useState("");
	const [imageID, setImageID] = useState("");
	const [list_sub_display, setDisplaySub] = useState(false);
	const [originalImageId, setOriginalImageId] = useState("");
	const [selectedOption, setSelectedOption] = useState("");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDate2, setSelectedDate2] = useState(new Date());
	const [selectOptions, setSelectOptions] = useState("");
	const [currentDownloadId, setCurrentDownloadId] = useState(0);
	const [setting_profile, setSettingsProfile] = useState([]);
	const [showBatchLoader, setBatchLoader] = useState(true);
	const [activeBatch, setActiveBatch] = useState(0);
	const [activeBatchNumber, setActiveBatchNumber] = useState(0);
	const [ctrlClickedSelect, setCtrlClickedSelect] = useState(false);
	//Timer logic
	const [timer, setTimer] = useState(null);
	const [elapsedTime, setElapsedTime] = useState(0);
	const [startTime, setStartTime] = useState(null);

	// const [selectAll, setSelectAll] = useState(false);

	const [showBatchImagesLoader, setBatchImagesLoader] = useState(false);

	const roleJson = sessionStorage.getItem("role");
	const role = roleJson ? JSON.parse(roleJson) : null;
	const LoginUserId = role !== null && role.pivot ? role.pivot.user_id : "";

	/**
	 * Handles to get the images list based on batch id , search,date filter.
	 * @param {Number} e - An batch id.
	 * @returns {Array} - An array of objects having batch data .
	 */
	const getQCDetails = () => {
		if (token) {
			request
				.get(apiURL + QC_VIEW)
				.then((response) => {
					setQCId(LoginUserId);
				})
				.catch((err) => {
					console.log("err inside batch ", err);
				});
		}
	};

	let idSelected =
		multiple_images_id !== undefined
			? multiple_images_id
					.filter((id) => !isNaN(id)) // Filter out non-numeric values
					.map((id) => parseInt(id)) // Convert to integers
					.join(",")
			: "";

	const startTimer = () => {
		setTimer(
			setInterval(() => {
				// Increment elapsed time every second
				setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
			}, 1000)
		);
	};

	const stopTimer = () => {
		clearInterval(timer);
	};
	const formatTimeInSeconds = (timeInSeconds) => {
		return timeInSeconds;
	}; // return the time in seconds
	const formatStartTime = (startTime) => {
		const date = new Date(startTime);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const day = date.getDate().toString().padStart(2, "0");
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		const seconds = date.getSeconds().toString().padStart(2, "0");

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};
	const handleCustomerLogged = (idSelected, batchId) => {
		if (token) {
			// Continue with API request
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("batch_number", batchId);
			bodyFormData.append("start_time", formatStartTime(startTime));
			bodyFormData.append("time_spend", formatTimeInSeconds(elapsedTime));

			request
				.post(apiURL + QC_TIME_LOG_WORKSPACE, bodyFormData)
				.then((response) => {
					// Set the success from the response
					// setStatus("Successfully Group updated");
					// setLoader(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleBulkAction = (apiEndpoint) => {
		handleCustomerLogged(idSelected);
		stopTimer(); // Stop the timer for the previous card
		setElapsedTime(0); // Reset elapsed time
		startTimer(); // Start a new timer for the clicked card
		setStartTime(Date.now()); // Capture the start time
		if (token) {
			bodyFormData.append("qc_id", qcId);
			bodyFormData.append("image_ids", idSelected);
			request
				.post(apiURL + apiEndpoint, bodyFormData)
				.then((response) => {
					getEditorImages();
				})
				.catch((err) => {
					console.log("err inside batch ", err);
				});
		}
	};

	const getQCBulkApproved = () => {
		handleBulkAction(QC_BULK_APPROVED);
	};

	const getQCBulkReject = () => {
		handleBulkAction(QC_BULK_REJECTS);
	};

	const getEditorImages = () => {
		setBatchImagesLoader(true);

		if (token && qcId !== "") {
			let apiUrl = apiURL + QC_VIEW_IMAGES + `/${qcId}`;
			if (batchId) {
				apiUrl += `?batch_number=${batchId}`;
			} else if (search) {
				apiUrl += `?search_key=${search}`;
			} else if (selectedOption != "" && !selectedOption.value === "custom") {
				apiUrl += `?date=${selectedOption.value}`;
			} else if (selectedOption.value === "custom") {
				apiUrl +=
					`?from_date=${moment(selectedDate).format("YYYY-MM-DD")}` +
					`&to_date=${moment(selectedDate2).format("YYYY-MM-DD")}`;
			}

			request
				.get(apiUrl)
				.then((response) => {
					setBatchImagesLoader(false);
					console.log("Data =", response.data);
					setBatchImages(response.data.data.images);
					setBatchData(response.data.data.batchNumbers);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setActiveBatch(response.data.data.batchNumbers[0].id);
					setActiveBatchNumber(response.data.data.batchNumbers[0].batch_number);
				})
				.catch((err) => {
					console.log("err inside batch", err);
					setBatchImagesLoader(false);
				});
		}
	};
	console.log(":elsped time", elapsedTime);
	useEffect(() => {
		getQCDetails();
		setStartTime(Date.now()); // Capture the start time
		startTimer(); // Start a new timer for the clicked card
	}, []);
	useEffect(() => {
		getEditorImages();
	}, [qcId, batchId, search, selectedOption, selectedDate, selectedDate2]);
	const handleClickImageDetails = (items, id) => {
		setImageDetailsViewId(id);
		setImageDetailsView(items);
		setShowModal(true);
		// setImageDetailsModal(true);
	};
	const handleBatchId = (id) => {
		setBatchId(id);
		setMultipleImagesId([]);
		setClickSelect(false);
	};
	const selectAllImages = () => {
		const allImageIds =
			batchImages !== undefined &&
			batchImages.data !== undefined &&
			batchImages.data.map((item) => String(item.id));

		setMultipleImagesId(allImageIds);
	};
	useEffect(() => {
		const handleKeyDown = (event) => {
			// Check if the Ctrl (for Windows/Linux) or Cmd (for macOS) key is pressed
			const isCtrlKey = event.ctrlKey || event.metaKey;

			// Check if the "A" key is pressed
			if (isCtrlKey && event.key === "a" && !ctrlClickedSelect) {
				// Prevent the default browser behavior (e.g., selecting all text)
				event.preventDefault();

				// Toggle the selectAll state
				setSelectAll((prevSelectAll) => !prevSelectAll);
			}
		};

		// Attach the event listener when the component mounts
		document.addEventListener("keydown", handleKeyDown);

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [!ctrlClickedSelect]); // Empty dependency array means this effect runs once when the component mounts

	useEffect(() => {
		if (selectAll) {
			selectAllImages();
		} else {
			// setMultipleImagesId([]);
			setClickSelect(false);
		}
	}, [selectAll]);
	const handleFetchProfileSettings = (imageID) => {
		if (token && imageID !== "") {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_PROFILE_SETTINGS + `/${imageID}`)
				.then((response) => {
					setSettingsProfile(response.data.image);
					// setEditorType(response.data.image.edit_group_id);
					// console.log(response.data.image);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					// setError(message);
				});
		}
	};
	useEffect(() => {
		handleFetchProfileSettings(imageID);
	}, [imageID]);

	/**
	 * Handles to change the active index
	 */
	const handleResetActiveIndex = useCallback((item, id, batch_number) => {
		setBatchId(item.id);
		setMultipleImagesId([]);
		setClickSelect(false);
		setActiveBatch(id);
		setActiveBatchNumber(batch_number);
	}, []);

	if (batchImages.length === 0) return <Loader />;
	else {
		return (
			<>
				<div className="container-fluid page-body-wrapper">
					{/* <Sidebar /> */}
					<div className="main-panel editor_special">
						<div className="rightbar">
							<div className="section-title">
								<h2>QC Workspace</h2>
							</div>

							<div className="workbody">
								<Form className="mb-3">
									<Row className="align-items-center">
										<Col lg={6} sm={12}>
											<div className="d-sm-flex flex-sm-wrap col-gap-14 align-items-center">
												<div className="flex-fill">
													<div className="form-group">
														<Select
															value={selectedOption}
															onChange={(e) => {
																setSelectedOption(e);
																setSelectAll(false);
															}}
															options={date}
															onClick={() => {
																setSelectOptions("");
															}}
														/>
														{selectedOption !== null &&
															selectedOption.value === "custom" && (
																<div className="d-flex mt-2 date-picker-qc--container ">
																	<div>
																		<Form.Control
																			type="date"
																			value={moment(selectedDate).format(
																				"YYYY-MM-DD"
																			)}
																			onChange={(e) =>
																				handleDateChange(
																					new Date(e.target.value),
																					setSelectedDate
																				)
																			}
																		/>
																	</div>

																	<div style={{ marginTop: "5px" }}>
																		<Form.Control
																			type="date"
																			value={moment(selectedDate2).format(
																				"YYYY-MM-DD"
																			)}
																			onChange={(e) =>
																				handleDateChange(
																					new Date(e.target.value),
																					setSelectedDate2
																				)
																			}
																		/>
																	</div>
																</div>
															)}
													</div>
												</div>
												<div className="flex-grow-1 ">
													<div className="form-group">
														<Autocomplete
															suggestions={batchData}
															setSearch={setSearch}
															setImageDetailsView={setImageDetailsView}
															setImageDetailsViewId={setImageDetailsViewId}
															setImageDetailsModal={setImageDetailsModal}
															isShow={isShow}
															setIsShow={setIsShow}
															setCtrlClickedSelect={setCtrlClickedSelect}
														/>
													</div>
												</div>
											</div>
										</Col>
										<Col lg={3} sm={3}>
											<Form.Group
												className="ml-4"
												controlId="formBasicCheckbox"
											>
												<div className="d-sm-flex  align-items-center">
													<div className="ml-2 exclude-click">
														<div className="form-group d-flex">
															<Button
																variant=""
																className="btn-image-order--qc"
																onClick={() => getQCBulkApproved()}
																disabled={
																	multiple_images_id.length > 0 ? false : true
																}
															>
																<span className="accept-tick--btn">
																	<img
																		src={CheckIcon}
																		className="img-fluid"
																		alt="mindreach images"
																	/>
																	<span className="text-qc--btn ">
																		Approved
																	</span>
																</span>
															</Button>{" "}
															<Button
																variant=""
																className="btn-image-order--qc"
																style={{ marginLeft: "18px" }}
																onClick={() => getQCBulkReject()}
																disabled={
																	multiple_images_id.length > 0 ? false : true
																}
															>
																{/* <span className="accept-tick--btn">
																	<img
																		src={CrossIcon}
																		className="img-fluid"
																		alt="mindreach images"
																	/>
																	<span className="text-qc--btn ">Reject</span>
																</span> */}
																<span className="accept-tick--btn">
																	<img
																		src={CrossIcon}
																		className="img-fluid"
																		alt="mindreach images"
																	/>
																	<span className="text-qc--btn ">Reject</span>
																</span>
															</Button>{" "}
															<div
																className={
																	multiple_images_id.length > 0
																		? `margin--warning`
																		: `margin--warning--disabled`
																}
															>
																<img
																	src={WarningSVG}
																	className="img-fluid"
																	alt="mindreach images"
																/>
															</div>
														</div>
													</div>
													{/* )} */}
													<div className="form-group ml-4">
														<Button
															variant="primary"
															className="btns"
															type="submit"
															disabled={true}
															//   onClick={(e) => handleSubmit(e)}
														>
															Sumit
														</Button>
													</div>
												</div>
											</Form.Group>
										</Col>
										<Col sm={2}></Col>
									</Row>
								</Form>
								<div className="container">
									<Row className="custom-row-workspace ">
										<>
											<Col lg={2} sm={2} className="customcol-2 pl-0">
												<div className="editororderdetail-new">
													<BatchDetails
														batchData={batchData}
														handleBatchId={handleBatchId}
														setBatchID={setBatchId}
														show={showBatchLoader}
														setSelectedImagesID={setImageID}
														resetActiveIndex={handleResetActiveIndex}
														activeIndex={activeBatch}
														timer={timer}
														setTimer={setTimer}
														elapsedTime={elapsedTime}
														setElapsedTime={setElapsedTime}
														startTimer={startTimer}
														stopTimer={stopTimer}
														startTime={startTime}
														setStartTime={setStartTime}
														handleCustomerLogged={handleCustomerLogged}
													/>
												</div>
											</Col>
											<Col lg={9} sm={10} className="editor_ws_image pr-0">
												<EditorOrderDetails
													images={batchImages.data}
													show={showBatchImagesLoader}
													handleClickImageDetails={handleClickImageDetails}
													batchData={batchData}
													editGroups={editGroups}
													editTypes={editTypes}
													token={token}
													selectImageName={selectImageName}
													multiple_images_id={multiple_images_id}
													setMultipleImagesId={setMultipleImagesId}
													setSelectAll={setSelectAll}
													wrapper={wrapper}
													setClickSelect={setClickSelect}
													setImageID={setImageID}
													imageID={imageID}
													setSelectedImageName={setSelectedImageName}
													setOriginalImageId={setOriginalImageId}
													setDisplaySub={setDisplaySub}
													qcId={qcId}
													getEditorImages={getEditorImages}
													handleFetchProfileSettings={
														handleFetchProfileSettings
													}
													setting_profile={setting_profile}
													setCtrlClickedSelect={setCtrlClickedSelect}
													timer={timer}
													setTimer={setTimer}
													elapsedTime={elapsedTime}
													setElapsedTime={setElapsedTime}
													startTimer={startTimer}
													stopTimer={stopTimer}
													startTime={startTime}
													setStartTime={setStartTime}
													handleCustomerLogged={handleCustomerLogged}
												/>
											</Col>
										</>
									</Row>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
};

export default EditorWorkspace;
