import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import DistributionBatchtable from './DistributionBatchtable'
import EditTypes from './EditTypes'
import EdittypesGroups from './EdittypesGroups'
import AddEditytypesGroups from './AddEditytypesGroups';
import AssignedTeam from './AssignedTeam'
import AddEdittype from './AddEdittype';
import Editt from '../../../../assets/Images/editt.svg';
import Delete from '../../../../assets/Images/delete.svg'


const Customersettings = () => {

    const ftp = [{
        id: '1',
        Host: "12.54.98.1",
        UserName: 'Username1',
        Port: '26',
        Password: '------',
        Status: <p style={{ color: '#125ECB' }}>Active</p>,
        '': <><img src={Editt} className="img-fluid ftp-table-img" alt="edit" /><img src={Delete} className="img-fluid ftp-table-img" alt="Delete" /></>



    }


    ]

    const columns = [{
        dataField: 'Host',
        text: 'Host',
        number: '1',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    }, {
        dataField: 'UserName',
        text: 'User Name',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'Port',
        text: 'Port',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'Password',
        text: 'Password',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'Status',
        text: 'Status',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: '',
        text: '',
        headerStyle: (colum, colIndex) => {
            return { width: '75px', textAlign: 'left', padding: '0 !important' };
        }
    }
    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    // <BootstrapTable
    //     keyField="id"
    //     data={ftp}
    //     columns={columns}
    //     striped
    //     hover
    //     condensed
    //     defaultSortDirection="asc"
    // />


    return (
        <>
            <h4 className="mb-4">FTP Accounts</h4>
            <div className="table-responsive table-b mb-4">
                <BootstrapTable keyField='id' data={ftp} columns={columns} />
            </div>
            <h4 className="mb-4">Distribution Batch Settings</h4>

            <DistributionBatchtable />

            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                <div className="">
                    <h4>Edit Types</h4>
                </div>
                {/* <div className="">
                    <AddEdittype />
                </div> */}
            </div>



            <EditTypes />

            {/* <div className="d-sm-flex justify-content-between align-items-center mb-4">
                <div className="">
                    <h4>Edit Type Groups</h4>
                </div>
                <div className="">
                    <AddEditytypesGroups />
                </div>
            </div>

            <EdittypesGroups /> */}


            <h4 className="mb-4">Assigned Team</h4>
            <AssignedTeam />
        </>
    )
}




export default Customersettings