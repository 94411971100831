import React from "react";

/**
 * Authentication context for managing the authentication state.
 * @type {React.Context}
 */
export const AuthContext = React.createContext();

/**
 * Initial state for the authentication context.
 * @type {object}
 * @property {boolean} isAuthenticated - Indicates if the user is authenticated.
 * @property {string|null} message - The authentication message.
 * @property {string|null} access_token - The access token.
 */
export const initialState = {
	isAuthenticated: false,
	message: null,
	access_token: null,
};

/**
 * Reducer function for managing authentication state.
 * @param {object} state - The current state.
 * @param {object} action - The action object.
 * @returns {object} - The updated state.
 */
export const reducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			sessionStorage.setItem("user", JSON.stringify(action.payload.message));
			sessionStorage.setItem("customer", JSON.stringify(action.payload.user));
			sessionStorage.setItem(
				"cutomer_type",
				JSON.stringify(action.payload.cutomer_type)
			);
			sessionStorage.setItem(
				"token",
				JSON.stringify(action.payload.access_token)
			);
			return {
				...state,
				isAuthenticated: true,
				message: action.payload.message,
				access_token: action.payload.access_token,
			};
		case "LOGOUT":
			sessionStorage.clear();
			localStorage.clear();
			return {
				...state,
				isAuthenticated: false,
				message: null,
			};
		default:
			return state;
	}
};
