import React from "react";
import Sidebar from "../../../components/global/Sidebar/Sidebar";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Delete from "../../../assets/Images/delete.svg";

import Table from "react-bootstrap/Table";

const AddRole = () => {
  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel ">
          <div className="rightbar pr-11">
            <div className="section-title">
              <h2>Add Role</h2>
            </div>

            <Row>
              <Col sm={9} lg={5}>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <div className="form-group">
                      <Row>
                        <Form.Label className="col-sm-3">Role Name</Form.Label>
                        <Col sm={9}>
                          <Form.Control type="email" placeholder="" autoFocus />
                        </Col>
                      </Row>
                    </div>
                    <div className="form-group">
                      <Row>
                        <Form.Label className="col-sm-3">
                          Drescription
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            as="textarea"
                            placeholder=""
                            style={{ height: "90px" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            <div className="section-titel mt-4 mb-3">
              <h4>Admin</h4>
            </div>

            <div className="table-responsive table-b custom-new-table">
              <Table hover>
                <thead>
                  <tr className="text-center">
                    <th></th>
                    <th>Full Access</th>
                    <th>View</th>
                    <th>Create</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Account Settings</td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>User/Role Settings</td>
                    <td className="text-center">
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="section-titel mt-4 mb-3">
              <h4>Orders</h4>
            </div>

            <div className="table-responsive table-b custom-new-table">
              <Table hover>
                <thead>
                  <tr className="text-center">
                    <th></th>
                    <th>Full Access</th>
                    <th>View</th>
                    <th>Create</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Orders</td>
                    <td className="text-center">
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>View Prics</td>
                    <td className="text-center">
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="section-titel mt-4 mb-3">
              <h4>Reports</h4>
            </div>

            <div className="table-responsive table-b custom-new-table">
              <Table hover>
                <thead>
                  <tr className="text-center">
                    <th></th>
                    <th>Full Access</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Performance Report</td>
                    <td className="text-center">
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>{" "}
                    </td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="section-titel mt-4 mb-3">
              <h4>Invoices</h4>
            </div>

            <div className="table-responsive table-b custom-new-table">
              <Table hover>
                <thead>
                  <tr className="text-center">
                    <th></th>
                    <th>Full Access</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Invoices & Prices</td>
                    <td className="text-center">
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>{" "}
                    </td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="section-titel mt-4 mb-3">
              <h4>Chat</h4>
            </div>

            <div className="table-responsive table-b custom-new-table">
              <Table hover>
                <thead>
                  <tr className="text-center">
                    <th></th>
                    <th>Full Access</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Internal Chat</td>
                    <td className="text-center">
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>{" "}
                    </td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                  </tr>
                  <tr>
                    <td>Mindreach Chat</td>
                    <td className="text-center">
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          feedback=""
                          feedbackType="invalid"
                        />
                      </Form.Group>{" "}
                    </td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRole;
