import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Pagination,
  Form,
  Row,
  Col,
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Select from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Importing components and images
import Loader from "../../../components/Loader";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import axios from "axios";
import { saveAs } from "file-saver";

import {
  ADMIN_VIEW_ENTERPRISE_REQUEST_DETAILS,
  ADMIN_DOWNLOAD_ENTERPRISE_DOCUMENT,
} from "../../../config/apiRoutes.api";
import { VIEW_ENTERPRISE_REQUESTS } from "../../../config/routes";
const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: [],
};

const ViewEnterpriseRequestDetails = () => {
  // Retrieve the token from the session storage
  const token = sessionStorage.getItem("token");
  // Create a request instance using the token
  const request = client(token);
  const bodyFormData = new FormData();
  // Get the navigation function from the useNavigate hook
  const navigate = useNavigate();
  //Declarations of state variable
  const [fieldsData, setEnterpriseData] = useState([]);
  const [rows, setRows] = useState([]);
  const [message, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { id } = useParams();

  /**
   * Fetches the fields data from the API when the pageNumber changes or the token is available.
   */
  useEffect(() => {
    // Check if a token is available
    if (token) {
      fetchRequestDetails();
    }
  }, []);

  /**
   * Handles the confirmation for deleting a Field.
   *
   */
  const fetchRequestDetails = () => {
    // Make an HTTP GET request to fetch the fields data with the specified page number
    request
      .get(apiURL + ADMIN_VIEW_ENTERPRISE_REQUEST_DETAILS + `/${id}`)
      .then((response) => {
        // Log the response to the console
        console.log(" response =", response.data.data.formFields);

        // Update the fieldsData state with the response data
        setEnterpriseData(response.data);
        setRows(response.data.data.formFields);
      })
      .catch((err) => console.log(err));
  };

  /**
   * Handles the function to retun id and name.
   * It handles and accepts object array and return id and name,
   *  * @param {Array} obj - Object array.
   * @returns {array}
   */

  const findIds = (obj) => {
    return obj.map((item) => {
      return item.id;
    });
  };

  /**
   * Generate input based on  field array.
   * It generates the form input field type passed.
   * @param {Array} obj - The Hold the array of object.
   */

  const generateInputField = (obj) => {
    switch (obj.field_type) {
      case "select":
        return (
          <Select
            options={generateSelectValues(obj.values || [])}
            placeholder={""}
            onChange={(e) => {
              console.log(e.value);
            }}
            className="w-50"
          />
        );
      case "radio":
        return generateInputRadio(obj);
      case "checkbox":
        return generateInputCheckbox(obj);
      case "document":
        return (
          <input
            type="file"
            id="file"
            onChange={(e) => {}}
            style={{ fontSize: "small" }}
          />
        );
      default:
        return (
          <Form.Control
            as="textarea"
            autoFocus={true}
            autoComplete="off"
            style={{ height: "100px" }}
          />
        );
    }
  };

  /**
   * Generate multiple checkbox input based on  field array.
   * It generates the form  multiple checkbox input field type passed.
   * @param {Array} obj - The Hold the array of object.
   */

  const generateInputCheckbox = (obj) => {
    return obj.values.map((item, indx) => {
      return (
        // <Col sm={5} key={"rand_" + indx}>
        <div
          className="form-group2 "
          key={"rand_" + indx}
          style={{ marginBottom: "5px" }}
        >
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            onChange={(e) => {}}
            id={`multi_${item.id}`}
            placeholder={item.field_placeholder}
          />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Form.Label
            className="align-text-top"
            htmlFor={`multi_${item.id}`}
            style={{
              cursor: "pointer",
              marginTop: "1px",
              fontWeight: "400",
            }}
          >
            {item.field_value_text}
          </Form.Label>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        // </Col>
      );
    });
  };

  /**
   * Generate multiple radio input based on  field array.
   * It generates the form  multiple radio input field type passed.
   * @param {Array} obj - The Hold the array of object.
   */

  const generateInputRadio = (obj) => {
    return obj.values.map((item, indx) => {
      return (
        <div
          className="form-group2"
          key={"rand_" + indx}
          style={{ marginBottom: "5px" }}
        >
          <span>
            <input
              style={{ cursor: "pointer" }}
              type="radio"
              name={generateID(obj.field_label)}
              className=""
              onChange={(e) => {}}
              id={`${item.id}`}
              placeholder={item.field_placeholder}
            />
          </span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Form.Label
            className="align-top align-text-top"
            htmlFor={`${item.id}`}
            style={{
              cursor: "pointer",
              marginTop: "1px",
              fontWeight: "400",
            }}
          >
            {item.field_value_text}
          </Form.Label>
        </div>
      );
    });
  };

  /**
   * Generate input id based on  field array.
   * It generates the form input field type passed.
   */

  const generateID = (input) => {
    return input ? input.replace(/ /g, "_").toLowerCase() : "";
  };

  /**
   * Generate input label based on field name.
   * It generates the form input label based on field name.
   */

  const generateLabel = (input) => {
    return input
      ? input.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()).replace(/_/g, " ")
      : "";
  };

  /**
   * Generate select options based on  field array.
   * It generates the form select options based on array input array passed.
   * @param {Array} obj - The Hold the array of object.
   *
   */

  const generateSelectValues = (obj) => {
    let ary_select = [];
    obj.forEach((obj) => {
      ary_select.push({
        label: obj.field_value_text,
        value: obj.field_value,
      });
    });
    return ary_select;
  };

  /**
   * Function to be called after download click.
   *  * @param {Event} event -  Event Object.
   * @param {number} company_id -  company_id id.
   * @param {number} field_id -  field id.
   *
   */
  const downloadDocument = (event, company_id, field_id) => {
    event.preventDefault();
    let name = event.target.innerHTML;

    const headers = {
      Authorization: token
        ? "Bearer " + `${token.slice(1, token.length - 1)}`
        : "",
    };

    axios({
      url:
        apiURL +
        ADMIN_DOWNLOAD_ENTERPRISE_DOCUMENT +
        `/${field_id}/${company_id}`,
      method: "GET",
      headers: headers,
      responseType: "blob", // Important: Ensure the API response is Blob
    })
      .then((response) => {
        console.log("response ", response);
        const fileName = `${name}`;
        saveAs(response.data, fileName);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  console.log("fieldsData =>", fieldsData.data);
  if (rows.length == 0) return <Loader />;
  else {
    // Render the actual content once fieldsData is available
    return (
      <>
        <div>
          <div className="rightbar pr-11">
            <div className="section-title">
              <h2 style={{ display: "inline-block" }}>
                Nike Enterprise Signup Form Answer
              </h2>
              <Button
                variant=""
                className="puple btns mr-3 pull-right"
                type="submit"
                onClick={() => navigate(VIEW_ENTERPRISE_REQUESTS)}
              >
                Cancel
              </Button>
            </div>
            <Row>
              <Col
                sm={10}
                lg={6}
                style={
                  {
                    // overflowY: "auto",
                    // height: "600px",
                  }
                }
              >
                <Form className="sp_form">
                  <Form.Group className="mb-3 ">
                    {rows.map((item, index) => (
                      <div
                        style={{
                          margin: "18px",
                          padding: "7px",
                        }}
                      >
                        <Row>
                          <Form.Label
                            className="col-sm-12"
                            style={{
                              padding: "20px",
                            }}
                          >
                            {item.field_label
                              ? item.field_label
                              : generateLabel(item.label)}
                          </Form.Label>

                          <Col
                            sm={10}
                            style={{
                              marginLeft: "2%",
                            }}
                          >
                            <div
                              className="w-100 form-control"
                              style={{
                                backgroundColor: "#f8f6f7",
                                paddingTop: "11px",
                                height: "43px",
                              }}
                            >
                              {fieldsData.data.enterprise_customer.hasOwnProperty(
                                item.field_name
                              ) ? (
                                item.field_type === "document" ? (
                                  <>
                                    <a
                                      download="AwesomeImage.png"
                                      href={`${apiURL.replace(
                                        "/api",
                                        ""
                                      )}/storage/uploads/become_enterprice/${
                                        fieldsData.data.enterprise_customer
                                          .email
                                      }/documents/${
                                        fieldsData.data.enterprise_customer[
                                          item.field_name
                                        ]
                                      }`}
                                      onClick={(e) => {
                                        downloadDocument(
                                          e,
                                          fieldsData.data.enterprise_customer
                                            .company_id,
                                          item.id
                                        );
                                      }}
                                    >
                                      {
                                        fieldsData.data.enterprise_customer[
                                          item.field_name
                                        ]
                                      }
                                    </a>
                                  </>
                                ) : (
                                  fieldsData.data.enterprise_customer[
                                    item.field_name
                                  ].replaceAll("@@@", ",")
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}

                    <Row>
                      <Form.Label
                        className="col-sm-12"
                        style={{
                          cursor: "all-scroll",
                          padding: "20px",
                        }}
                      ></Form.Label>

                      <Col
                        sm={10}
                        style={{
                          cursor: "all-scroll",
                          marginLeft: "2%",
                        }}
                      >
                        <Button
                          variant=""
                          className="puple btns mr-3"
                          type="submit"
                          onClick={() => navigate(VIEW_ENTERPRISE_REQUESTS)}
                        >
                          Back
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
};

export default ViewEnterpriseRequestDetails;
