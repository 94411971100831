import React, { useState } from 'react'
import Sidebar from '../../../../components/global/Sidebar/Sidebar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RoleTable from './RoleTable';
import UserTable from './UserTable';

import Button from 'react-bootstrap/esm/Button';
import plus from '../../../../assets/Images/plus-blue.svg';




const SettingTable = () => {

  const [button, setButton] = useState("user");


  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="rightbar pr-11 overviewstab">
            <div className="setting-tab">
              <Tabs
                defaultActiveKey="User"
                id="justify-tab-example"
                className="stafftab"
                justify
              >
                <Tab eventKey="User" onClick={() => setButton("user")} title="User">
                 <UserTable/>
                </Tab>
                <Tab eventKey="Role" onClick={() => setButton("role")} title="Role">
                  <RoleTable />
                </Tab>
              </Tabs>


             

              {/* 
              <div onClick={() => setButton("user")}>
                User
              </div>

              <div onClick={() => setButton("role")}>
                Role
              </div>

              {button ==="role" &&  <RoleTable/>} */}



            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingTable