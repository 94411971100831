import React from 'react'
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import Placeholder from 'react-bootstrap/Placeholder';
// import '../../../../css/style.css'
// import '../../../../css/responsive.css'
// import '../../../../css/dashboardtable.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';



const Newdashboard = () => {
  const products = [{
    id: '1',
    name: "Reebok International",
    price: 'INV-000001',
    date: '19 Aug 2022',
    DueDate: '19 Aug 2022',
    Amount: '$500.00',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#125ECB' }}>Due in 13 days</p>,
    color: "green",
    clickToSelect: true

  }, {
    id: 2,
    name: 'Nick, Inc.',
    price: 'INV-000001',
    date: '19 Aug 2022',
    DueDate: '19 Aug 2022',
    Amount: '$500.00',
    BalanceDue: '$0.00',


    Status: <p style={{ color: '#125ECB' }}>Due in 13 days</p>
  },
  {
    id: 3,
    name: "Adidas",
    price: 'INV-000001',
    date: '19 Aug 2022',
    DueDate: '19 Aug 2022',
    Amount: '$500.00',
    BalanceDue: '$0.00',


    Status: <p style={{ color: '#F1A100' }}>Processing</p>
  },
  {
    id: 4,
    name: "Coca Cola",
    price: 'INV-000001',
    date: '19 Aug 2022',
    DueDate: '19 Aug 2022',
    Amount: '$500.00',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#C71515' }}>Overdue by 10 days</p>
  },
  {
    id: 5,
    name: "Coca Cola",
    price: 'INV-000001',
    date: '19 Aug 2022',
    DueDate: '19 Aug 2022',
    Amount: '$500.00',
    BalanceDue: '$0.00',
    Status: <p style={{ color: '#C71515' }}>Overdue by 10 days</p>
  },
  {
    id: 6,
    name: "Adidas",
    price: 'INV-000001',
    date: '19 Aug 2022',
    DueDate: '19 Aug 2022',
    Amount: '$500.00',
    BalanceDue: '$0.00',
    
    Status: <p style={{ color: '#C71515' }}>Overdue by 10 days</p>
  }
  ]




  const columns = [{
    dataField: 'name',
    text: 'Customer',
    number: '1',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }

  }, {
    dataField: 'price',
    text: 'Invoice No.',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'date',
    text: 'Issue Date',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'DueDate',
    text: 'Due Date',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  }
    ,
  {
    dataField: 'Amount',
    text: 'Amount',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'BalanceDue',
    text: 'Balance Due',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Status',
    text: 'Status',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }

  }


  ];

  // const defaultSorted = [{
  //   dataField: 'name',
  //   order: 'desc'
  // }];
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: (row, rowIndex) =>
    (rowIndex > 1 ? 'row-index-bigger-than-2101' : 'row-index-small-than-2101')
  };


  // <BootstrapTable
  //   keyField="id"
  //   data={products}
  //   columns={columns}
  //   selectRow={ selectRow }
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />

  return (
    <>
     
      
            <div className="section-title">
              <h4>Unpaid Invoices</h4>
            </div>
            <div className="table-responsive table-b">
              <BootstrapTable keyField='id' data={products} columns={columns} />

            </div>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 8 }}>
                {
                  <Pagination>
                    <Pagination.Prev />
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Item>{6}</Pagination.Item>
                    <Pagination.Next />
                  </Pagination>
                }
              </Col>
            </Row>      
      
    </>
  )
}

export default Newdashboard