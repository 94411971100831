import React from "react";

const NoDataFound = () => {
  const emptyDataMessage = () => {
    return "No Data to Display";
  };
  return (
    <>
      <div className="no-data--found">
        <div className="empty-state__content">
          <div className="empty-state__message">{emptyDataMessage()}</div>
          <div className="empty-state__help">
            Add a new record by simpley clicking the button on top right side.
          </div>
        </div>
      </div>
    </>
  );
};
export default NoDataFound;
