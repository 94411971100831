import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import DeleteModalConfirmation from "../../../components/Modal/DeleteModal";
import Delete from "./../../../assets/Images/delete.svg";

import { apiURL } from "./../../../data/filepath";
import { client } from "./../../../data/API/GobalClientAxiosRequest";
import {
	BRANCHES_LIST,
	BRANCHES_SHIFT_BYBRANCHID,
	VIEW_HOLIDAY,
	UPDATE_HOLIDAY_BY_ID,
	DELETE_HOLIDAY_BY_ID,
} from "./../../../config/apiRoutes.api";
import { HOIDAY_LIST_PAGE } from "../../../config/routes";

import MyVerticallyCenteredModal from "./../../../components/Modal";

const EditHoliday = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [branches, setBranchesData] = useState([]);
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const [modalShow, setModalShow] = React.useState(false);
	const [modalShowTimeZone, setModalShowTimeZone] = useState(false);
	const [show, setShow] = useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [shift, setShift] = useState([]);
	const [selectBranch, setBranch] = useState(0);
	const [selectShiftId, setSelectShiftLabel] = useState(0);
	const [status, setStatus] = useState("");
	const [date, setDate] = useState("");
	const [end_date, setEndDate] = useState("");
	const [holiday_name, setHoliday] = useState("");
	const [holiday_id, setHolidaySelectedId] = useState("");
	/**
	 * fiterSet- This function find the selected branch together.
	 * @param {array} x - The array of collection to search.
	 * @param {id} y - The id of selected element.
	 */
	const fiterSet = (array, id) => {
		return array.filter((itemInArray) => itemInArray.id === id);
	};
	const selectLabel = (branches) =>
		branches.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	const selectShiftLabel = (shift) =>
		shift.map(({ shift_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of shift array as label and value return a new array of object like [{value:"",label:""}]
	const handleSelectedDeleted = (id, name) => {
		setShow(true);
		setHoliday(name);
		setHolidaySelectedId(id);
	};
	const handleDeleteHoliday = (id) => {
		if (token !== null) {
			request
				.get(apiURL + DELETE_HOLIDAY_BY_ID + `/${id}`)
				.then((response) => {
					console.log(response);
					navigate(HOIDAY_LIST_PAGE);
				})
				.catch((err) => console.log(err));
		}
	};
	const handleSubmit = (event) => {
		try {
			event.preventDefault();
			if (token) {
				const request = client(token);
				bodyFormData.append("holiday_name", name);
				bodyFormData.append("branch_id", selectBranch);
				bodyFormData.append("description", description);
				bodyFormData.append("shift_id", selectShiftId);
				bodyFormData.append("from_date", date);
				bodyFormData.append("end_date", end_date);
				request
					.post(apiURL + UPDATE_HOLIDAY_BY_ID + `/${id}`, bodyFormData)
					.then((response) => {
						console.log("add", response);
						setStatus("Success");
						setSubmitStatusMessage(response.data.message);
						setModalShow(true);
					})
					.catch((err) => {
						setStatus("Error");
						setSubmitStatusMessage(err);
					});
			}
		} catch (err) {
			setStatus("Error");
			setSubmitStatusMessage(err);
		}
	};
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_LIST)
				.then((response) => {
					console.log(response);
					setBranchesData(response.data[0].branches);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_SHIFT_BYBRANCHID + `/${selectBranch}`)
				.then((response) => {
					console.log("shift", response);
					setShift(response.data.shifts);
				})
				.catch((err) => console.log(err));
		}
	}, [selectBranch]);
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + VIEW_HOLIDAY + `/${id}`)
				.then((response) => {
					console.log("response call", response.data);
					setName(response.data[0].holiday.holiday_name);
					setHolidaySelectedId(response.data[0].holiday.encrypted_id);
					setDescription(response.data[0].holiday.description);
					setBranch(response.data[0].holiday.branch_id);
					setSelectShiftLabel(response.data[0].holiday.shift_id);
					setDate(response.data[0].holiday.from_date);
					setEndDate(response.data[0].holiday.end_date);
				})
				.catch((err) => console.log(err));
		}
	}, [id]);
	useEffect(() => {
		if (selectBranch !== 0 && selectLabel(fiterSet(branches, selectBranch))) {
			setSubmitStatusMessage(
				selectLabel(fiterSet(branches, selectBranch))[0] !== undefined &&
					selectLabel(fiterSet(branches, selectBranch))[0].time_zone
			);
		}
	}, [selectBranch, branches]);
	console.log("call the branch", selectLabel(fiterSet(branches, selectBranch)));
	return (
		<div>
			<div className="rightbar pr-11">
				<div className="section-title">
					<h2>Edit/View Holiday</h2>
				</div>
				<Row>
					<Col sm={9} lg={5}>
						<Form>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Name</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="text"
												placeholder=""
												autoFocus
												autoComplete="off"
												onChange={(e) => setName(e.target.value)}
												value={name}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group ">
									<Row>
										<Form.Label className="col-sm-3">Description </Form.Label>
										<Col sm={9}>
											<Form.Control
												as="textarea"
												placeholder="Write Description..."
												style={{ height: "148px" }}
												onChange={(e) => setDescription(e.target.value)}
												value={description}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Branch</Form.Label>
										<Col sm={9}>
											<Select
												onChange={(e) => {
													setBranch(e.value);
													setSubmitStatusMessage(e.time_zone);
													setModalShowTimeZone(true);
													setStatus("Time Zone");
												}}
												options={selectLabel(branches)}
												value={selectLabel(fiterSet(branches, selectBranch))}
												maxMenuHeight="120px"
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Timezone</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="text"
												placeholder=""
												autoFocus
												value={submitStatusMessage}
												disabled
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Shift(s)</Form.Label>
										<Col sm={9}>
											<Select
												onChange={(e) => setSelectShiftLabel(e.value)}
												options={selectShiftLabel(shift)}
												value={selectShiftLabel(fiterSet(shift, selectShiftId))}
												maxMenuHeight="120px"
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">From Date</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="date"
												placeholder=""
												autoFocus
												onChange={(e) => setDate(e.target.value)}
												value={date}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">End Date</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="date"
												placeholder=""
												autoFocus
												onChange={(e) => setEndDate(e.target.value)}
												value={end_date}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<div className="d-sm-flex justify-content-between btn-cls">
										<div
											className=""
											onClick={() => handleSelectedDeleted(id, name)}
										>
											<Button
												variant="outline-primary"
												className="btn-light-border"
											>
												<img
													src={Delete}
													className="img-fluid"
													alt="mindreach images"
												/>
											</Button>{" "}
										</div>

										<div className="">
											<Button
												variant=""
												className="puple btns mr-3"
												type="submit"
												onClick={() => {
													setBranch(0);
													setSubmitStatusMessage("");
													navigate(HOIDAY_LIST_PAGE);
												}}
											>
												Cancel
											</Button>
											<Button
												variant="primary"
												className="btns"
												type="submit"
												onClick={(e) => handleSubmit(e)}
											>
												Save
											</Button>
										</div>
									</div>
								</div>
							</Form.Group>
						</Form>
					</Col>
				</Row>
			</div>
			<MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
					navigate(HOIDAY_LIST_PAGE);
				}}
				heading={status}
				message={submitStatusMessage}
				className="modal-body-body--success"
			/>
			<DeleteModalConfirmation
				show={show}
				onHide={() => {
					setShow(false);
				}}
				branch={"Holiday"}
				name={holiday_name}
				title={"Delete Holiday"}
				handleConfirm={() => handleDeleteHoliday(id)}
			/>
			{/* <MyVerticallyCenteredModal
				show={modalShowTimeZone}
				onHide={() => {
					setModalShowTimeZone(false);
				}}
				heading={status}
				message={submitStatusMessage}
				className="modal-body-body--success"
			/> */}
		</div>
	);
};
export default EditHoliday;
