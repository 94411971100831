import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Delete from '../../../assets/Images/delete.svg'
import Table from 'react-bootstrap/Table';





const AddUser = () => {
    const choosetype = [
        { label: "Select Role", value: 355 },
        { label: "Select Role", value: 54 }
       
      ];

    return (

        
        <>

            <div className="container-fluid page-body-wrapper">

                <Sidebar />
                <div className="main-panel ">
                    <div className="rightbar pr-11">
                        <div className="section-title">
                            <h2>Add User</h2>
                        </div>
                        <Row>
                            <Col sm={9} lg={5}>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">

                                       
                                        <div className="form-group">
                                            <Row>

                                                <Form.Label className='col-sm-3'>Name</Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder=""
                                                        autoFocus
                                                    /></Col>

                                            </Row>
                                        </div>
                                        <div className="form-group">
                                            <Row>

                                                <Form.Label className='col-sm-3'>Email</Form.Label>
                                                <Col sm={9}>
                                                <Form.Control
                                                        type="email"
                                                        placeholder=""
                                                        autoFocus
                                                    /></Col>

                                            </Row>
                                        </div>

                                        <div className="form-group">
                                            <Row>

                                                <Form.Label className='col-sm-3'>Role</Form.Label>
                                                <Col sm={9}>
                                                <Select options={choosetype} defaultValue={{ label: "Select Role", value: 355 }} /></Col>

                                            </Row>
                                        </div>

                                        <div className="d-sm-flex justify-content-end btn-cls">
                                        <div className="mr-3">                                               
                                               <Button  variant="" className="puple btns" type="submit">
                                                  Cancel
                                               </Button>
                                              
                                           </div>
                                        <div className="">
                                        <Button variant="primary" className="btns" type="submit">
                                                        Save
                                                    </Button>   </div>
                                               
                                            </div>
                                    </Form.Group>
                                </Form>

                            </Col>
                        </Row>

                      

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddUser