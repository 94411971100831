import React from "react";

/**
 * Error message form validation component to generate the errors on form.
 * @param {String} message - The Prefix message comes before the Error field names.
 * @param {String} error - The error of the form fild names.
 * @returns {JSX.Element} - The rendered error message.
 */

const FormValidationError = ({ message ="* Missing required field:", error ="" , className = "" }) => {
	return (
		<>
			{
				error && error !== "" && (
				<div className={`duplicate-settings-error-box${className ? (" "+className) :""}`}>
					{`${message +" "+error}`}
				</div>
			)}
		</>
	)
}
export default FormValidationError;
