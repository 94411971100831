import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Delete from "./../../assets/Images/delete.svg";
import DeleteModalConfirmation from "../../components/Modal/DeleteModal";
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
  UPDATE_BRANCHE,
  VIEW_BRANCHE,
  DELETE_BRANCHE,
} from "./../../config/apiRoutes.api";
import { VIEW_BRANCHE_PAGE } from "../../config/routes";
import GeoCodeApi from "../../components/GeoCodeLocationSearch";

const EditBranch = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [timeZone, setTimeZone] = useState("");

  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [branchNameSeleted, setBranchNameSelected] = useState("");
  const [branchNameSeletedId, setBranchNameSelectedId] = useState("");
  const bodyFormData = new FormData();
  const token = sessionStorage.getItem("token");
  const request = client(token);

  const handleSubmit = (event) => {
    if (token) {
      event.preventDefault();
      bodyFormData.append("name", name);
      bodyFormData.append("location", location);
      bodyFormData.append("description", description);
      bodyFormData.append("time_zone", timeZone);
      request
        .post(apiURL + UPDATE_BRANCHE + `/${id}`, bodyFormData)
        .then((response) => {
          console.log(response);
          navigate(VIEW_BRANCHE_PAGE);
        })
        .catch((err) => console.log(err));
    }
  };
  const handleSelectedDeleted = (id, name) => {
    setShow(true);
    setBranchNameSelected(name);
    setBranchNameSelectedId(id);
  };
  const handleDeleteBranch = (id) => {
    if (token) {
      request
        .get(apiURL + DELETE_BRANCHE + `/${id}`)
        .then((response) => {
          console.log(response);
          navigate(VIEW_BRANCHE_PAGE);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    if (token !== null) {
      request
        .get(apiURL + VIEW_BRANCHE + `/${id}`)
        .then((response) => {
          setName(response.data[0].branch.name);
          setLocation(response.data[0].branch.location);
          setTimeZone(response.data[0].branch.time_zone);
          setDescription(response.data[0].branch.description);
          setBranchNameSelectedId(response.data[0].branch.encrypted_id);
          setBranchNameSelected(response.data[0].branch.name);
        })
        .catch((err) => console.log(err));
    }
  }, []);
  return (
    <>
      <div className="rightbar pr-11">
        <div className="section-title">
          <h2>View/Edit Branch</h2>
        </div>
        <Row>
          <Col sm={9} lg={5}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="form-group">
                  <Row>
                    <Form.Label className="col-sm-3">Name</Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Form.Label className="col-sm-3">Location</Form.Label>
                    <Col sm={9}>
                      <GeoCodeApi
                        location={location}
                        setLocation={setLocation}
                        setTimeZone={setTimeZone}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <Row>
                    <Form.Label className="col-sm-3">Timezone</Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoFocus
                        value={timeZone}
                        disabled
                        // onChange={(e) => setTimeZone(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <Row>
                    <Form.Label className="col-sm-3">Description </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        as="textarea"
                        placeholder="Write Description..."
                        value={description}
                        style={{ height: "148px" }}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <div className="d-sm-flex justify-content-between btn-cls">
                    <div
                      className=""
                      onClick={() =>
                        handleSelectedDeleted(
                          branchNameSeletedId,
                          branchNameSeleted
                        )
                      }
                    >
                      <Button
                        variant="outline-primary"
                        className="btn-light-border"
                      >
                        <img
                          src={Delete}
                          className="img-fluid"
                          alt="mindreach images"
                        />
                      </Button>{" "}
                    </div>

                    <div className="">
                      <Button
                        variant=""
                        className="puple btns mr-3"
                        type="submit"
                        onClick={() => navigate(VIEW_BRANCHE_PAGE)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="btns"
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <DeleteModalConfirmation
          show={show}
          onHide={() => {
            setShow(false);
          }}
          branch={"branch"}
          name={branchNameSeleted}
          title={"Delete branch"}
          handleConfirm={() => handleDeleteBranch(branchNameSeletedId)}
        />
      </div>
    </>
  );
};

export default EditBranch;
