import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";

/**
 * Component for displaying alert messages.
 * @param {string} messages - The alert message to be displayed.
 * @param {string} variant - The variant of the alert (e.g., "primary", "success", "danger").
 * @returns {JSX.Element} - The rendered AlertMessage component.
 */
const AlertMessage = ({ variant, messages, className }) => {
	const [show, setShow] = useState(true);
	// if (show) {
	// 	return (
	// 		<Alert
	// 			key={variant}
	// 			variant={variant}
	// 			className={className}
	// 			onClose={() => setShow(false)}
	// 			dismissible
	// 		>
	// 			<strong>{messages}</strong>
	// 		</Alert>
	// 	);
	// }
	if (show) {
		return (
			<Alert variant="danger" dismissible>
				<Alert.Heading>Oh snap! You got an error!</Alert.Heading>
				<span aria-hidden="true" onClose={() => setShow(false)}>
					×
				</span>
				<p>{messages}</p>
			</Alert>
		);
	}
	return null;
};

export default AlertMessage;
