import React from "react";
import Sidebar from "../../../components/global/Sidebar/Sidebar";
import Card from "react-bootstrap/Card";
import pending from "../../../assets/Images/pending.svg";
import coordinating from "../../../assets/Images/coordinating.svg";
import revision from "../../../assets/Images/revision.svg";
import total from "../../../assets/Images/total.svg";
import bgtotal from "../../../assets/Images/bg-total.svg";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";

const SupervisorDashboardCoordinator = () => {
	const products = [
		{
			Customer: "Nike, Inc.",
			OrderNo: "0000076",
			OrderDate: "19 Aug 2022 12 to 1",
			BatchNo: "000053",
			Staff: "Kobe Bryant",
			Pending: "543",
			Coordinating: "137",
			Revision: "10",
			Status: <p style={{ color: "#eebe14" }}>Processing</p>,
		},
		{
			Customer: "Nike, Inc.",
			OrderNo: "0000076",
			OrderDate: "19 Aug 2022 12 to 1",
			BatchNo: "000053",
			Staff: "Kobe Bryant",
			Pending: "543",
			Coordinating: "137",
			Revision: "10",
			Status: <p style={{ color: "#eebe14" }}>Processing</p>,
		},
		{
			Customer: "Nike, Inc.",
			OrderNo: "0000076",
			OrderDate: "19 Aug 2022 12 to 1",
			BatchNo: "000053",
			Staff: "Kobe Bryant",
			Pending: "750",
			Coordinating: "0",
			Revision: "0",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			Customer: "Under Armour Corp.",
			OrderNo: "0000076",
			OrderDate: "19 Aug 2022 12 to 1",
			BatchNo: "000053",
			Staff: "Kobe Bryant",
			Pending: "750",
			Coordinating: "0",
			Revision: "0",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			Customer: "Under Armour Corp.",
			OrderNo: "0000076",
			OrderDate: "19 Aug 2022 12 to 1",
			BatchNo: "000053",
			Staff: "Kobe Bryant",
			Pending: "750",
			Coordinating: "0",
			Revision: "0",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			Customer: "Under Armour Corp.",
			OrderNo: "0000076",
			OrderDate: "19 Aug 2022 12 to 1",
			BatchNo: "000053",
			Staff: "Kobe Bryant",
			Pending: "750",
			Coordinating: "0",
			Revision: "0",
			Status: <p style={{ color: "#8e1e1d" }}>Pending</p>,
		},
	];

	const columns = [
		{
			dataField: "Customer",
			text: "Customer",
			number: "1",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "OrderNo",
			text: "Order No",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "OrderDate",
			text: "Order Date",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "BatchNo",
			text: "Batch No.",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Staff",
			text: "Staff",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Pending",
			text: "Pending",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Coordinating",
			text: "Coordinating",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Revision",
			text: "Revision",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Status",
			text: "Status",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
	];

	const defaultSorted = [
		{
			dataField: "name",
			order: "desc",
		},
	];

	// <BootstrapTable
	//     keyField="id"
	//     data={products}
	//     columns={columns}
	//     striped
	//     hover
	//     condensed
	//     defaultSortDirection="asc"
	// />
	return (
		<>
			<div>
				<div className="rightbar pr-11">
					<div className="section-title">
						<h2>Dashboard</h2>
						<h4>Current Production Pipeline</h4>
					</div>

					<div className="mb-4">
						<div className="d-sm-flex pipeline w-60 supervisor-dash-card">
							<Card className="bg-card card-right-border">
								<Card.Img src={bgtotal} alt="Card image" />

								<Card.ImgOverlay>
									<Card.Img variant="top" src={total} />
									<Card.Body>
										<Card.Title>Total in Pipeline</Card.Title>
										<Card.Text>10,999</Card.Text>
									</Card.Body>
								</Card.ImgOverlay>
							</Card>

							<Card>
								<Card.Img variant="top" src={pending} />
								<Card.Body>
									<Card.Title>Pending</Card.Title>
									<Card.Text>6,000</Card.Text>
								</Card.Body>
							</Card>

							<Card>
								<Card.Img variant="top" src={coordinating} />
								<Card.Body>
									<Card.Title>Coordinating</Card.Title>
									<Card.Text>1,197</Card.Text>
								</Card.Body>
							</Card>

							<Card>
								<Card.Img variant="top" src={revision} />
								<Card.Body>
									<Card.Title>Revision</Card.Title>
									<Card.Text>198</Card.Text>
								</Card.Body>
							</Card>
						</div>
					</div>
					<div className="table-responsive table-b supervisor-dash-table">
						<BootstrapTable keyField="id" data={products} columns={columns} />
					</div>
					<Row className="mt-4">
						<Col md={{ span: 4, offset: 8 }}>
							{
								<Pagination>
									<Pagination.Prev />
									<Pagination.Item active>{1}</Pagination.Item>
									<Pagination.Item>{2}</Pagination.Item>
									<Pagination.Item>{3}</Pagination.Item>
									<Pagination.Item>{4}</Pagination.Item>
									<Pagination.Item>{5}</Pagination.Item>
									<Pagination.Item>{6}</Pagination.Item>
									<Pagination.Next />
								</Pagination>
							}
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default SupervisorDashboardCoordinator;
