import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BatchHistory from './BatchHistory';
import Settings from './Settings';
import Performance from './Performance'
import Overview from './Overview';
import menudot from '../../../assets/Images/menu-dot.svg'



const SupervisorStaffDetails = () => {




    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="rightbar pr-11 overviewstab custom-overview customrightbar ">
                        <div className="section-title">
                            <h2>Staff Details - Kobe Bryant </h2>
                        </div>
                        <div className="detailstab">
                            <Tabs
                                defaultActiveKey="Overview"
                                id="justify-tab-example"
                                className="stafftab"
                                justify
                            >

                                <Tab eventKey="Overview" title="Overview">
                                    <Overview />
                                </Tab>
                                <Tab eventKey="BatchHistory" title="Batch History">
                                    <BatchHistory />
                                </Tab>
                                <Tab eventKey="Performance" title="Performance">
                                    <Performance />
                                </Tab>
                                <Tab eventKey="Settings" title="Settings">
                                    <Settings />
                                </Tab>
                            </Tabs>
                            <div className="doticon">
                                <img src={menudot} className="img-fluid" alt="mindreach images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default SupervisorStaffDetails