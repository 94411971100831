import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// import workspace from '../../../css/workspace.css'
import EditorOrderDetailsidebar from './EditorOrderDetailsidebar'
import EditorOrderDetails from './EditorOrderDetails';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';



const SupervisorEditorWorkspace = () => {
    const choosestaff = [
        { label: "Kobe Bryant", value: 355 },
        { label: "ArgeKobe Bryantntina", value: 54 },
        { label: "Kobe Bryant", value: 43 },
        { label: "ArgeKobe Bryantntina", value: 61 },
        { label: "Kobe Bryant", value: 965 },
        { label: "ArgeKobe Bryantntina", value: 46 },
        { label: "Kobe Bryant", value: 58 }
    ];

    const selectactions = [
        { label: "Action", value: 355 },
        { label: "Action2", value: 54 }
    ];

    const selectdays = [
        { label: "Today", value: 355 },
        { label: "Custom", value: 54 }
    ];

    return (
        <>

            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="rightbar">
                        <div className="section-title">
                            <h2>Editor Workspace - Kobe Bryant</h2>
                        </div>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Form className="workspaceform">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <div className="col-lg-6 txt-left">
                                            <div className="d-sm-flex align-itemd-center  col-gap-14 align-items-center">
                                                <div className="form-group"><Form.Label className='label-div'>Choose Staff</Form.Label>
                                                </div>

                                                <div className="form-group flex-grow-1">
                                                    {/* <Form.Select aria-label="Default select example">
                                                    <option>Kobe Bryant</option>
                                                    <option value="1">Kobe Bryant</option>
                                                    <option value="2">Kobe Bryant</option>
                                                    <option value="3">Kobe Bryant</option>
                                                </Form.Select> */}
                                                    <Select options={choosestaff} />
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <div className="workbody custom-workspace-body">
                            <Form className="mb-3">
                                <Row className="align-items-center">
                                    <Col lg={2} sm={12}>


                                        <div className="form-group">
                                            <Select options={selectdays} defaultValue={{ label: "Today", value: 355 }} />
                                        </div>



                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <div className="form-group">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search Order / Image"
                                                className="me-2 search-icon"
                                                aria-label="Search"
                                            /> </div>

                                    </Col>
                                    <Col lg={4} sm={12}>
                                        <Form.Group className="ml-4" controlId="formBasicCheckbox">
                                            <div className="d-sm-flex col-gap-20 align-items-center custom-flex">
                                                <div className="form-group">
                                                    <Form.Check type="checkbox" label="Select All" />
                                                </div>
                                                <div className="flex-grow-1 ">
                                                    <div className="form-group">
                                                        <Select options={selectactions}
                                                            defaultValue={{ label: "Action", value: 355 }} /> </div>
                                                </div>
                                                <div className="form-group">
                                                    <Button variant="primary" className="btns">Apply</Button>{' '}
                                                </div>
                                            </div>


                                        </Form.Group>
                                    </Col>
                                    {/* <Col sm={2}>
                                    <Form.Select>
                                        <option>Actions</option>
                                        <option>Actions</option>
                                    </Form.Select>
                                </Col> */}
                                    <Col sm={2}>

                                    </Col>
                                </Row>
                            </Form>


                            <Row>
                                <Col sm={3}>
                                    <div className="editororderdetail">
                                        <EditorOrderDetailsidebar />
                                    </div>
                                </Col>
                                <Col sm={9}>
                                    <EditorOrderDetails />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default SupervisorEditorWorkspace