import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "../../components/Modal";
import TimePicker from '../../components/TimePicker'; // import the time picker js from the custom component
import PlusIcon from "../../assets/Images/settings/plus-icon.svg";
import MinusIcon from "../../assets/Images/settings/minus-icons.svg";
import { uuid } from "uuidv4";
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import { ADD_WORK_SHIFTS, BRANCHES_LIST } from "./../../config/apiRoutes.api";
import { WORK_SHIFTS_LISTS_PAGE } from "../../config/routes";
const AddWorkShift = () => {
	const navigate = useNavigate();
	const [rowCol1, setRowCol1] = useState([
		{
			shift_start_day: "Monday",
			shift_start_time: "08:00",
			shift_end_day: "Monday",
			shift_end_time: "18:00",
		},
	]);
	const [status, setStatus] = useState("");
	const [row, setRow] = useState([]);
	const [branches, setBranchesData] = useState([]);
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [brancheName, setBranchesName] = useState(0);
	const [modalShow, setModalShow] = useState(false);
	const [modalShowTimeZone, setModalShowTimeZone] = useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [addResponse, setAddResponse] = useState("");
	const [inputFields, setInputFields] = useState([
		{
			shift_start_day: "",
			shift_start_time: "",
			shift_end_day: "",
			shift_end_time: "",
		},
		{
			shift_start_day: "",
			shift_start_time: "",
			shift_end_day: "",
			shift_end_time: "",
		},
		{
			shift_start_day: "",
			shift_start_time: "",
			shift_end_day: "",
			shift_end_time: "",
		},
	]);
	const startDate = [
		{ label: "Monday", value: 1 },
		{ label: "Tuesday", value: 2 },
		{ label: "Wednesday", value: 3 },
		{ label: "Thursday", value: 4 },
		{ label: "Friday", value: 5 },
		{ label: "Saturday", value: 6 },
	];

	const startTime = [
		{ label: "12:00 AM", value: 1 },
		{ label: "12:15 AM", value: 2 },
		{ label: "12:30 AM", value: 3 },
		{ label: "12:45 AM", value: 4 },
		{ label: "01:00 AM", value: 5 },
		{ label: "01:15 AM", value: 6 },
		{ label: "01:30 AM", value: 7 },
		{ label: "01:45 AM", value: 8 },
		{ label: "02:00 AM", value: 9 },
		{ label: "02:15 AM", value: 10 },
		{ label: "02:30 AM", value: 11 },
		{ label: "02:45 AM", value: 12 },
		{ label: "03:00 AM", value: 13 },
		{ label: "03:15 AM", value: 14 },
		{ label: "03:30 AM", value: 15 },
		{ label: "03:45 AM", value: 16 },
		{ label: "04:00 AM", value: 17 },
		{ label: "04:15 AM", value: 18 },
		{ label: "04:30 AM", value: 19 },
		{ label: "04:45 AM", value: 20 },
		{ label: "05:00 AM", value: 21 },
		{ label: "05:15 AM", value: 22 },
		{ label: "05:30 AM", value: 23 },
		{ label: "05:45 AM", value: 24 },
		{ label: "06:00 AM", value: 25 },
		{ label: "06:15 AM", value: 26 },
		{ label: "06:30 AM", value: 27 },
		{ label: "06:45 AM", value: 28 },
		{ label: "07:00 AM", value: 29 },
		{ label: "07:15 AM", value: 30 },
		{ label: "07:30 AM", value: 31 },
		{ label: "07:45 AM", value: 32 },
		{ label: "08:00 AM", value: 33 },
		{ label: "08:15 AM", value: 34 },
		{ label: "08:30 AM", value: 35 },
		{ label: "08:45 AM", value: 36 },
		{ label: "09:00 AM", value: 37 },
		{ label: "09:15 AM", value: 38 },
		{ label: "09:30 AM", value: 39 },
		{ label: "09:45 AM", value: 40 },
		{ label: "10:00 AM", value: 41 },
		{ label: "10:15 AM", value: 42 },
		{ label: "10:30 AM", value: 43 },
		{ label: "10:45 AM", value: 44 },
		{ label: "11:00 AM", value: 45 },
		{ label: "11:15 AM", value: 46 },
		{ label: "11:30 AM", value: 47 },
		{ label: "11:45 AM", value: 48 },
		{ label: "12.00 PM", value: 49 },
		{ label: "12:15 PM", value: 50 },
		{ label: "12:30 PM", value: 51 },
		{ label: "12:45 PM", value: 52 },
		{ label: "13:00 PM", value: 53 },
		{ label: "13:15 PM", value: 54 },
		{ label: "13:30 PM", value: 55 },
		{ label: "13:45 PM", value: 56 },
		{ label: "14:00 PM", value: 57 },
		{ label: "14:15 PM", value: 58 },
		{ label: "14:30 PM", value: 59 },
		{ label: "14:45 PM", value: 60 },
		{ label: "15:00 PM", value: 61 },
		{ label: "15:15 PM", value: 62 },
		{ label: "15:30 PM", value: 63 },
		{ label: "15:45 PM", value: 64 },
		{ label: "16:00 PM", value: 65 },
		{ label: "16:15 PM", value: 66 },
		{ label: "16:30 PM", value: 67 },
		{ label: "16:45 PM", value: 68 },
		{ label: "17:00 PM", value: 69 },
		{ label: "17:15 PM", value: 70 },
		{ label: "17:30 PM", value: 71 },
		{ label: "17:45 PM", value: 72 },
		{ label: "18:00 PM", value: 73 },
		{ label: "18:15 PM", value: 74 },
		{ label: "18:30 PM", value: 75 },
		{ label: "18:45 PM", value: 76 },
		{ label: "19:00 PM", value: 77 },
		{ label: "19:15 PM", value: 78 },
		{ label: "19:30 PM", value: 79 },
		{ label: "19:45 PM", value: 80 },
		{ label: "20:00 PM", value: 81 },
		{ label: "20:15 PM", value: 82 },
		{ label: "20:30 PM", value: 83 },
		{ label: "20:45 PM", value: 84 },
		{ label: "21:00 PM", value: 85 },
		{ label: "21:15 PM", value: 86 },
		{ label: "21:30 PM", value: 87 },
		{ label: "21:45 PM", value: 88 },
		{ label: "22:00 PM", value: 89 },
		{ label: "22:15 PM", value: 90 },
		{ label: "22:30 PM", value: 91 },
		{ label: "22:45 PM", value: 92 },
		{ label: "23:00 PM", value: 93 },
		{ label: "23:15 PM", value: 94 },
		{ label: "23:30 PM", value: 95 },
		{ label: "23:45 PM", value: 96 },
	];
	
	const handleAddRow = () => {
		if (row.length <= 1) {
			setRow((prevState) => [
				...prevState,
				{
					id: uuid(),
					shift_start_day: "",
					shift_start_time: "",
					shift_end_day: "",
					shift_end_time: "",
				},
			]);
		}
	}; // add an object with specific uuid
	const removeItem = (removeId) => {
		setRow((prevState) => prevState.filter(({ id }) => id !== removeId));
	}; // remove the object from previous array of object based on id
	useEffect(() => {
		if (token !== null) {
			request
				.get(apiURL + BRANCHES_LIST)
				.then((response) => {
					console.log(response);
					setBranchesData(response.data[0].branches);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	/**
	 * Transforms an string into first letter.
	 * @param {String} string - An string of text passed.
	 * @returns {String} - An string with the first letter in caps.
	 */
	
	const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).replace("_"," ");

	const selectLabel = (branches) =>
		branches.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	
	/**
	 * Set the state for fields .
	 * @param {String} field - An field name of input.
	 * @returns {Number} index - An index where we are updating the time picker from the list.
	 * @returns {String} value - value of input field.
	 */

	const handleTimeChange = (field,index, value) =>{
		const inputFieldArray = [...inputFields];
		const tempFieldArray = {...inputFieldArray[index]};
		tempFieldArray[field] =   value;
		inputFieldArray[index] = tempFieldArray;
		setInputFields(inputFieldArray);
	}
	
	const filter = (data) => {
		if (Array.isArray(data)) {
			const temp = data.reduce((r, v) => {
				v = filter(v);
				if (v !== "") r.push(v);
				return r;
			}, []);
			return temp.length ? temp : "";
		}
		if (data && typeof data === "object") {
			const temp = Object.entries(data).reduce((r, [k, v]) => {
				v = filter(v);
				if (v !== "") r.push([k, v]);
				return r;
			}, []);
			return temp.length ? Object.fromEntries(temp) : "";
		}
		return data;
	}; // get only the properties with values unequal to ''.

	const handleSubmit = (event) => {
		if (token !== null) {
			const request = client(token);
			// event.preventDefault();
			bodyFormData.append("shift_name", name);
			bodyFormData.append("branch_id", brancheName);
			bodyFormData.append("description", description);
			bodyFormData.append("moreFields", JSON.stringify(filter(inputFields)));
			request
				.post(apiURL + ADD_WORK_SHIFTS, bodyFormData)
				.then((response) => {
					console.log("add", response);
					let errorMessage = "";
					setModalShow(true);
					if(response.data && response.data.success === false) {
						const errorResponse = Object.entries(response.data.data)
						errorMessage+= "Form validation error:\n\n\n";
						for (const [key, value] of errorResponse) {
							errorMessage+=`${capitalizeFirstLetter(key)}, `;
						}
						setAddResponse(errorMessage.replace(/, \s*$/, "") + (errorMessage.indexOf("Error occured") >=0 ? "" :" are required"));
						setStatus("Error");
					} else {
						setStatus("Success");
						setAddResponse(response.data.message);
					}
				})
				.catch((err) => console.log(err));
		}
	};

	return (
		<>
			<div>
				<div className="rightbar pr-11">
					<div className="section-title">
						<h2>Add Work Shift</h2>
					</div>
					<Row>
						<Col sm={9} lg={6}>
							<Form>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Name</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="text"
													placeholder=""
													autoFocus
													onChange={(e) => setName(e.target.value)}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Description </Form.Label>
											<Col sm={9}>
												<Form.Control
													as="textarea"
													placeholder="Write Description..."
													style={{ height: "120px" }}
													onChange={(e) => setDescription(e.target.value)}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Branch</Form.Label>
											<Col sm={9}>
												<Select
													onChange={(e) => {
														setBranchesName(e.value);
														setSubmitStatusMessage(e.time_zone);
														setModalShowTimeZone(true);
													}}
													options={selectLabel(branches)}
													placeholder={"Select Branch"}
													maxMenuHeight="120px"
													// defaultValue={{ label: "Select Branch", value: 355 }}
												/>
											</Col>
										</Row>
									</div>
									<div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Timezone</Form.Label>
											<Col sm={9}>
												<Form.Control
													type="text"
													placeholder=""
													autoFocus
													value={submitStatusMessage}
													disabled
												/>
											</Col>
										</Row>
									</div>
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col sm={9} lg={9}>
							<Form>
								<Form.Group className="mb-4" controlId="formBasicEmail">
									<div
										className="section-title add-work--title"
										style={{ marginTop: "40px" }}
									>
										<Form.Label>Schedule</Form.Label>
									</div>
									{rowCol1.map((items, id) => {
										return (
											<Row className="align-items-center">
												<Col xs={2} className="add-work--content">
													<Form.Label className="label-div ">
														Start Day
													</Form.Label>
													<Select
														onChange={(e) => {
															handleTimeChange("shift_start_day",id, e.label)
														}}
														options={selectLabel(startDate)}
														placeholder="Start Day"
														name="shift_start_day"
													/>
												</Col>
												<Col xs={2} className="add-work--content time">
													<Form.Label className="label-div ml-2">
														Start Time
													</Form.Label>
													{/* <Select
														onChange={(e) => {
															// console.log("id", e);
															// setShiftStartTime(e.label);
															// handleFormChange(id, e);
														}}
														options={selectLabel(startTime)}
														placeholder="Start Time"
														// defaultValue={{ label: "8:00 AM", value: 1 }}
														name="shift_start_time"
													/> */}
													<TimePicker 
														onChange={handleTimeChange} 
														value={inputFields[id].shift_start_time}
														id={id}
														inputName={"shift_start_time"}
													/>
												</Col>
												<Col xs={2} className="add-work--content ">
													<Form.Label className="label-div ml-2">
														End Day
													</Form.Label>
													<Select
														options={selectLabel(startDate)}
														onChange={(e) => {
															handleTimeChange("shift_end_day",id, e.label)
														}}
														placeholder="End Day"
														// defaultValue={{ label: "Monday", value: 1 }}
														name="shift_end_day"
													/>
												</Col>
												<Col xs={2} className="add-work--content time">
													<Form.Label className="label-div ml-2">
														End Time
													</Form.Label>
													{/* <Select
														onChange={(e) => {
															// console.log("id", e);
															// setShiftEndTime(e.label);
															// handleFormChange(id, e);
														}}
														options={selectLabel(startTime)}
														placeholder="End Time"
														// defaultValue={{ label: "8.00 PM", value: 1 }}
														name="shift_end_time"
													/> */}
													<TimePicker 
														onChange={handleTimeChange} 
														value={inputFields[id].shift_end_time}
														id={id}
														inputName={"shift_end_time"}
													/>
												</Col>
												<Col xs={2}>
													<div className="d-flex">
														<div
															className="sm-1 mt-4 mr-2"
															onClick={() => handleAddRow()}
															style={{
																marginTop: "27px"
															}}
														>
															<img src={PlusIcon} alt=""></img>
														</div>
														<div
															className="sm-1 mt-4"
															onClick={() =>
																row && row[0] !== undefined
																	? removeItem(row[0].id)
																	: setRow([])
															}
															style={{
																marginTop: "27px"
															}}
														>
															<img src={MinusIcon} alt=""></img>
														</div>
													</div>
												</Col>
											</Row>
										);
									})}
									{row.map((items, id) => {
										return (
											<>
												<Row className="align-items-center" style={{margin: "19px -13px 6px -13px"}}>
													<Col
														xs={2}
														className="add-work--content "
														key={items.id}
													>
														<Select
															onChange={(e) => {
																handleTimeChange("shift_start_day",id + 1, e.label);
															}}
															options={selectLabel(startDate)}
															placeholder="Start Day"
															value={
																selectLabel(startDate).find((option) => {
																	if (
																		inputFields[id + 1] &&
																		inputFields[id + 1].shift_start_day !== null
																		// && is_profile_selected
																	) {
																		return option.value === inputFields[id + 1].shift_start_day;
																	} else {
																		return null;
																	}
																})
															}
														/>
													</Col>
													<Col xs={2} className="add-work--content time">
														<TimePicker 
															onChange={handleTimeChange} 
															value={inputFields[id + 1].shift_start_time}
															id={id + 1}
															inputName={"shift_start_time"}
														/>
													</Col>
													<Col xs={2} className="add-work--content ">
														<Select
															onChange={(e) => {
																handleTimeChange("shift_end_day",id + 1, e.label);
															}}
															options={selectLabel(startDate)}
															placeholder="End Day"
															// defaultValue={{ label: "Monday", value: 1 }}
														/>
													</Col>
													<Col xs={2} className="add-work--content time">
														<TimePicker 
															onChange={handleTimeChange} 
															value={inputFields[id + 1].shift_end_time}
															id={id + 1}
															inputName={"shift_end_time"}
														/>
													</Col>
													<Col xs={2}>
														<div className="d-flex">
															<div
																className="sm-1 mt-0 mr-2"
																onClick={() => handleAddRow()}
															>
																<img src={PlusIcon} alt=""></img>
															</div>
															<div
																className="sm-1 mt-0"
																onClick={() => removeItem(items.id)}
															>
																<img src={MinusIcon} alt=""></img>
															</div>
														</div>
													</Col>
												</Row>
											</>
										);
									})}
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col sm={9} lg={6}>
							<Form>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<div className="d-sm-flex justify-content-between flex-row--reverse">
										<div className="">
											<Button
												variant=""
												className="puple btns mr-3"
												type="submit"
												onClick={() => navigate(WORK_SHIFTS_LISTS_PAGE)}
											>
												Cancel
											</Button>
											<Button
												variant="primary"
												className="btns"
												onClick={(e) => handleSubmit(e)}
											>
												Save
											</Button>
										</div>
									</div>
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row style={{height : "120px"}}>
						
					</Row>
				</div>
			</div>
			<MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
					if(status === "Success") {
						navigate(WORK_SHIFTS_LISTS_PAGE);
					}
				}}
				heading={status}
				message={addResponse}
				className="modal-body-body--success"
				modalHeight={"modalHeight"}
				errorMessage={status === "Error" ? status :""}
			/>
		</>
	);
};
export default AddWorkShift;
