import React from 'react'
import Button from 'react-bootstrap/Button';
import plus from '../../../assets/Images/plus.svg';






const AddEdittype = () => {
  return (
    <>


      <Button variant="primary"
      ><span>
          <img src={plus} className="img-fluid mr-2" /></span>Add New
      </Button>{' '}

      {/* <Modal show={show}
        size="lg" className="addtypegroup"
        centered
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Group Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Group Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Model Images - Amazon"
                    autoFocus
                  />
                </Form.Group>
                <h5 className="mt-3 mb-3">Choose Specifications</h5>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="form-group">
                    <Row className="align-items-center">

                      <Form.Label className='col-sm-5'>File Format</Form.Label>
                      <Col sm={7}>
                        <Form.Select>
                          <option>JPEG</option>
                          <option>PNG</option>
                        </Form.Select></Col>

                    </Row>
                  </div>

                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="form-group">
                    <Row className="align-items-center">

                      <Form.Label className='col-sm-5'>Size</Form.Label>
                      <Col sm={7}>
                        <Form.Select>
                          <option>Height x Width</option>

                        </Form.Select></Col>

                    </Row>
                  </div>

                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="form-group">
                    <Row className="align-items-center">

                      <Form.Label className='col-sm-5'>DPI</Form.Label>
                      <Col sm={7}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          autoFocus
                        />
                      </Col>

                    </Row>
                  </div>

                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="form-group">
                    <Row className="align-items-center">

                      <Form.Label className='col-sm-5'>Color Mode</Form.Label>
                      <Col sm={7}>
                        <Form.Select>
                          <option>RBG</option>
                          <option>HEXA</option>

                        </Form.Select></Col>

                    </Row>
                  </div>

                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="form-group">
                    <Row className="align-items-center">

                      <Form.Label className='col-sm-5'>Align</Form.Label>
                      <Col sm={7}>
                        <Form.Select>
                          <option>Center</option>
                          <option>Left</option>

                        </Form.Select></Col>

                    </Row>
                  </div>

                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="form-group">
                    <Row className="align-items-center">

                      <Form.Label className='col-sm-5'>Margins</Form.Label>
                      <Col sm={7}>
                        <ul className="margin-list">
                          <li>T</li>
                          <li>B</li>
                          <li>L</li>
                          <li>R</li>
                        </ul>

                      </Col>

                      <Col sm={6}>

                      </Col>

                    </Row>
                  </div>

                </Form.Group>
              </Col>


              <Col sm={6}>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >


                  <Form.Label>Choose Edit Types</Form.Label>

                  <Form.Select>
                    <option>Background Removal</option>
                    <option>Retouching</option>
                    <option>Resizing</option>
                    <option>Cropping</option>
                    <option>Color Adjustment</option>

                  </Form.Select>

                </Form.Group>

              </Col>
            </Row>



          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-sm-flex justify-content-end">
            <div>
              <Button variant="primary" onClick={handleClose}>
                Add
              </Button> </div>
          </div>
        </Modal.Footer>
      </Modal> */}


    </>
  )
}

export default AddEdittype