import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import plus from '../../../assets/Images/plus.svg';
import { Link } from 'react-router-dom';


const NewUser = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);






  return (
    <>
      <Link to="/supervisor-staff-new-user">
        <Button variant="primary" ><span>
          <img src={plus} className="img-fluid mr-2" /></span>
          New User</Button>{' '}
      </Link>

    </>
  )
}

export default NewUser