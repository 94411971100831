// Function to get the user's role from session storage
export function getUserRole() {
	const roleJson = sessionStorage.getItem("role");
	return roleJson ? JSON.parse(roleJson) : null;
}

// Function to check if the user has a specific role for a given route
export function hasUserRole(route, targetRole) {
	const role = getUserRole();
	const currentUserRoles = [role !== null ? role.role : "login"];
	return currentUserRoles.includes(targetRole);
}
