import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

/**
 * Button component for navigation with an image and text.
 * @param {string} url - The URL to navigate to.
 * @param {string} text - The text to display on the button.
 * @param {string} img - The URL of the image to display on the button.
 * @param {Function} onClick - The function to be executed when the button is clicked.
 * @param {string} variant - The variant of the button (e.g., "primary", "secondary").
 * @returns {JSX.Element} - The rendered button component.
 */
const AddButton = ({ url, text, img, onClick, variant }) => {
	return (
		<>
			<Link to={url}>
				<Button variant={variant} onClick={onClick}>
					<span>
						<img src={img} className="img-fluid mr-2" alt="" />
					</span>
					{text}
				</Button>{" "}
			</Link>
		</>
	);
};

export default AddButton;
