import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Importing Bootstrap Components
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	Button,
	Dropdown,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// Importing components
import Select from "react-select";
import "react-tiny-fab/dist/styles.css";
import Loader from "../../../components/Loader";
import NoDataFound from "./../../../components/NoDataFound";
import DeleteModalConfirmation from "./../../../components/Modal/DeleteModal";
import ActivateModalConfirmation from "../../../components/Modal/ActivateModal";
import DeActivateModalConfirmation from "../../../components/Modal/DeactivateModal";
import DuplicateModalConfirmation from "../../../components/Modal/DuplicateModal";

//Importing Images
import user from "./../../../assets/Images/calender-shifts.svg";
import plus from "./../../../assets/Images/plus.svg";
import Delete from "./../../../assets/Images/delete.svg";
import duplicate from "./../../../assets/Images/actionsIcons/Duplicate _Final.svg";
import CancelIcon from "./../../../assets/Images/actionsIcons/cancel_Final.svg";
import MoreIcon from "./../../../assets/Images/actionsIcons/more-Final.svg";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import {
	BRANCHES_LIST,
	BRANCHES_SHIFT_BYBRANCHID,
	SEARCH_HOLIDAY,
	DELETE_HOLIDAY_BY_ID,
	DELETE_HOLIDAYs_BULK,
	ACTIVATE_BULK_HOLIDAY,
	DEACTIVATE_BULK_HOLIDAY,
	DUPLICATE_BULK_HOLIDAY,
	ACTIVATE_HOLIDAY_BY_ID,
	DEACTIVATE_HOLIDAY_BY_ID,
	HOLIDAY_YEAR_LISTS,
} from "../../../config/apiRoutes.api";

import { ADD_HOLIDAY_PAGE, EDIT_HOLIDAY_PAGE } from "../../../config/routes";
const HolidaysLists = () => {
	// Retrieve the token from sessionStorage
	const token = sessionStorage.getItem("token");

	// Create a request instance with the token
	const request = client(token);

	// Create a new FormData instance
	const bodyFormData = new FormData();

	// Create a instance for navigate in the page
	const navigate = useNavigate();

	// Declaration of the state variable
	const [holidayData, setHoliDayData] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [holiday_year, setHolidayYear] = useState("");
	const [shift, setShift] = useState([]);
	const [selectBranch, setBranch] = useState(0);
	const [selectShiftId, setSelectShiftLabel] = useState(0);
	const [branches, setBranchesData] = useState([]);
	const [year, setYear] = useState([]);
	const [search, setSearch] = useState("");
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowId, setSelectedRowsId] = useState([]);
	const [selectedRowName, setSelectedRowsName] = useState([]);
	const [show, setShow] = useState(false);
	const [deleteShowByID, setDeleteShowBYID] = useState(false);
	const [holiday_id, setHoliDayId] = useState("");
	const [holiday, setHoliDay] = useState("");
	const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);
	const [selectAllRow, setSelectAllRows] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [rows, setRows] = useState([]);
	const [floatingButton, setFloatingButton] = useState(false);
	const [dropDown, setDropDown] = useState(false);
	const [dropDown_Two, setDropDownTwo] = useState(false);

	//State for confirmation status Modal
	const [holiday_activate, setActivate] = useState(false);
	const [holiday_activate_All, setActivateAll] = useState(false);
	const [holiday_deactivate, setDeactivate] = useState(false);
	const [holiday_deactivate_All, setDeactivateAll] = useState(false);
	const [holiday_duplicate, setDuplicate] = useState(false);
	const [holiday_duplicate_All, setDuplicateAll] = useState(false);
	const [activate_id, setActivateById] = useState(false);
	const [deactivate_id, setDeactivateById] = useState(false);
	const [status_name, setStatusName] = useState("");
	const [id, setId] = useState("");

	/**
	 * Renders a Tooltip component with the specified text as its content.
	 * @param {string} text - The text to be displayed in the Tooltip.
	 * @returns {JSX.Element} The Tooltip component.
	 */
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

	useEffect(() => {
		if (token) {
			request
				.get(apiURL + SEARCH_HOLIDAY + `?page=${pageNumber}`)
				.then((response) => {
					console.log(response);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [!search, pageNumber]);
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_SHIFT_BYBRANCHID + `/${selectBranch}`)
				.then((response) => {
					console.log("shift", response);
					setShift([{ shift_name: "All", id: 0 }, ...response.data.shifts]);
				})
				.catch((err) => console.log(err));
		}
	}, [selectBranch]);
	console.log("shifts", shift);
	useEffect(() => {
		if (
			token &&
			search !== "" &&
			holiday_year !== 0 &&
			selectBranch !== 0 &&
			selectShiftId !== 0
		) {
			request
				.get(
					apiURL +
						SEARCH_HOLIDAY +
						`?year=${holiday_year}` +
						`&branch_id=${selectBranch}` +
						`&shift_id=${selectShiftId}` +
						`&search_key=${search}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log("call the search", response.data);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [search, holiday_year, selectBranch, selectShiftId, pageNumber]);
	useEffect(() => {
		if (token && holiday_year !== 0) {
			request
				.get(
					apiURL +
						SEARCH_HOLIDAY +
						`?year=${holiday_year}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log("call the search", response.data);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [holiday_year, pageNumber]);
	useEffect(() => {
		if (token && holiday_year === "All") {
			request
				.get(apiURL + SEARCH_HOLIDAY + `?page=${pageNumber}`)
				.then((response) => {
					console.log("call the search", response.data);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [holiday_year, pageNumber]);
	useEffect(() => {
		if (token && selectBranch !== 0 && holiday_year !== 0) {
			request
				.get(
					apiURL +
						SEARCH_HOLIDAY +
						`?year=${holiday_year}` +
						`&branch_id=${selectBranch}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log("call the search", response.data);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [holiday_year, selectBranch, pageNumber]);
	useEffect(() => {
		if (token && search !== "") {
			request
				.get(
					apiURL +
						SEARCH_HOLIDAY +
						`?search_key=${search}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log("call the search", response.data);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [search, pageNumber]);
	useEffect(() => {
		if (token && selectBranch === 0) {
			request
				.get(apiURL + SEARCH_HOLIDAY + `?page=${pageNumber}`)
				.then((response) => {
					console.log("call the search", response.data);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [selectBranch, pageNumber]);
	useEffect(() => {
		if (token && selectBranch !== 0 && selectShiftId === 0) {
			request
				.get(
					apiURL +
						SEARCH_HOLIDAY +
						`?branch_id=${selectBranch}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log("call the search", response.data);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [selectShiftId, selectBranch, pageNumber]);
	useEffect(() => {
		if (
			token &&
			selectBranch !== 0 &&
			holiday_year !== 0 &&
			selectShiftId !== 0
		) {
			request
				.get(
					apiURL +
						SEARCH_HOLIDAY +
						`?year=${holiday_year}` +
						`&branch_id=${selectBranch}` +
						`&shift_id=${selectShiftId}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log("call the search", response.data);
					setHoliDayData(response.data);
					setRows(response.data[0].holidays.data);
				})
				.catch((err) => console.log(err));
		}
	}, [holiday_year, selectBranch, selectShiftId, pageNumber]);
	useEffect(() => {
		if (token !== null) {
			request
				.get(apiURL + BRANCHES_LIST)
				.then((response) => {
					console.log(response);
					setBranchesData([
						{ name: "All", id: 0 },
						...response.data[0].branches,
					]);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token !== null) {
			request
				.get(apiURL + HOLIDAY_YEAR_LISTS)
				.then((response) => {
					console.log("year", response);
					setYear([{ label: "All", value: "" }, ...response.data[0].holidays]);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	const selectLabel = (branches) =>
		branches.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	const selectShiftLabel = (shift) =>
		shift.map(({ shift_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of shift array as label and value return a new array of object like [{value:"",label:""}]
	const PaginationItems = () => {
		const { total, per_page, current_page } = holidayData[0].holidays;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};
	const NextPagination = () => {
		if (
			pageNumber !==
			Math.ceil(
				holidayData[0].holidays.total / holidayData[0].holidays.per_page
			)
		) {
			setPageNumber(pageNumber + 1);
		}
	};
	const PreviousPagination = () => {
		if (
			pageNumber !==
			Math.ceil(
				holidayData[0].holidays.total / holidayData[0].holidays.per_page
			)
		) {
			setPageNumber(pageNumber - 1);
		}

		return setPageNumber(pageNumber - 1);
	};

	const handleSelectRow = React.useCallback(
		(e, row) => {
			if (e.target.checked) {
				console.log("e.target.checked", e.target.checked);
				setSelectedRows((prevSelectedRows) => [
					...prevSelectedRows,
					row.encrypted_id,
				]);
				setSelectedRowsId((prevSelectedRows) => [
					...prevSelectedRows,
					row.holiday_name,
				]);
			} else {
				setSelectedRows((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.encrypted_id
					)
				);
				setSelectedRowsId((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.holiday_name
					)
				);
			}
		},
		[selectedRows]
	);
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			setSelectAll(true);
			const updatedRows = holidayData[0].holidays.data.map((row) => {
				return { ...row, selected: true };
			});
			setRows(updatedRows);
			const updatedRowsIds = holidayData[0].holidays.data.map((row) => {
				return row.encrypted_id;
			});
			const updatedRowsName = holidayData[0].holidays.data.map((row) => {
				return row.holiday_name;
			});
			setSelectAllRows((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsIds,
			]);
			setSelectedRowsName((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsName,
			]);
		} else {
			setSelectAll(false);
			const updatedRows = holidayData[0].holidays.data.map((row) => {
				return { ...row, selected: false };
			});
			setRows(updatedRows);
			const updatedRowsIds = holidayData[0].holidays.data.map((row) => {
				return row.encrypted_id;
			});
			setSelectAllRows([]);
		}
	};
	const handleClearSelectAll = () => {
		setSelectAll(false);
		const updatedRows = holidayData[0].holidays.data.map((row) => {
			return { ...row, selected: false };
		});
		setRows(updatedRows);
		const updatedRowsIds = holidayData[0].holidays.data.map((row) => {
			return row.encrypted_id;
		});
		setSelectAllRows([]);
	};
	const handleBulkDelete = () => {
		if (token) {
			bodyFormData.append("holiday_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + DELETE_HOLIDAYs_BULK, bodyFormData)
				.then((response) => {
					console.log(response);
					setShow(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	const handleBulkDeleteAll = () => {
		if (token) {
			bodyFormData.append("holiday_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + DELETE_HOLIDAYs_BULK, bodyFormData)
				.then((response) => {
					console.log(response);
					setShowModalDeleteAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk activation of selected rows.
	*/
	const handleBulkActivate = () => {
		if (token) {
			bodyFormData.append("holiday_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + ACTIVATE_BULK_HOLIDAY, bodyFormData)
				.then((response) => {
					console.log(response);
					setActivate(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk activation of all rows.
	*/
	const handleBulkActivateAll = () => {
		if (token) {
			bodyFormData.append("holiday_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + ACTIVATE_BULK_HOLIDAY, bodyFormData)
				.then((response) => {
					console.log(response);
					setActivateAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk deactivation of holiday.
		2.If the token is available, it appends the holiday_ids to the bodyFormData,
		3.sends a POST request to the specified API endpoint for deactivating bulk holiday,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/

	const handleBulkDeactivate = () => {
		if (token) {
			bodyFormData.append("holiday_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + DEACTIVATE_BULK_HOLIDAY, bodyFormData)
				.then((response) => {
					console.log(response);
					setDeactivate(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk deactivation of all holiday.
		2.If the token is available, it appends the holiday_ids to the bodyFormData,
		sends a POST request to the specified API endpoint for deactivating bulk holiday,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/
	const handleBulkDeactivateAll = () => {
		if (token) {
			bodyFormData.append("holiday_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + DEACTIVATE_BULK_HOLIDAY, bodyFormData)
				.then((response) => {
					console.log(response);
					setDeactivateAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk duplicate of holiday.
		2.If the token is available, it appends the holiday_ids to the bodyFormData,
		3.sends a POST request to the specified API endpoint for deactivating bulk holiday,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/
	const handleBulkDuplicate = () => {
		if (token) {
			bodyFormData.append("holiday_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + DUPLICATE_BULK_HOLIDAY, bodyFormData)
				.then((response) => {
					console.log(response);
					setDuplicate(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk duplicate of all holiday.
		2.If the token is available, it appends the holiday_ids to the bodyFormData,
		sends a POST request to the specified API endpoint for duplicate bulk holiday,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/
	const handleBulkDuplicateAll = () => {
		if (token) {
			bodyFormData.append("holiday_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + DUPLICATE_BULK_HOLIDAY, bodyFormData)
				.then((response) => {
					console.log(response);
					setDuplicateAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Sets the show flag to true.
		@param {function} setShow - The function to set the show flag.
	*/
	const handleBulkConfirmation = (setShow) => {
		setShow(true);
	};
	const handleDelete = (id) => {
		if (token !== null) {
			request
				.get(apiURL + DELETE_HOLIDAY_BY_ID + `/${id}`)
				.then((response) => {
					console.log(response);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	const handleDeleteConfirmation = (id, name) => {
		setDeleteShowBYID(true);
		setHoliDay(name);
		setHoliDayId(id);
	};
	const handleActivateById = () => {
		if (token) {
			request
				.get(apiURL + ACTIVATE_HOLIDAY_BY_ID + `/${id}`)
				.then((response) => {
					console.log(response);
					setActivateById(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	const handleDeactivateById = () => {
		if (token) {
			request
				.get(apiURL + DEACTIVATE_HOLIDAY_BY_ID + `/${id}`)
				.then((response) => {
					console.log(response);
					setDeactivateById(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	const handleActivateOrDeactive = (status, id, name) => {
		setId(id);
		setStatusName(name);
		if (status === "1") {
			setDeactivateById(true);
		} else {
			setActivateById(true);
		}
	};

	if (!holidayData.length) return <Loader />;
	else {
		return (
			<>
				<div className="rightbar pr-11">
					<div className="section-title ">
						<h2>Holidays</h2>
						{/* {error !== "" && <Alert variant={"danger"} messages={error} />} */}
					</div>
					<Row></Row>

					<div className="d-sm-flex flex-sm-wrap col-gap-30-sm mb-3 ">
						<div className="customer-search">
							<Form>
								{selectedRows.length !== 0 && (
									<Row className="align-items-center">
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select">
													<>
														<div>
															<div
																onClick={() => handleBulkConfirmation(setShow)}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
																// className="image-icons-holiday"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</div>

														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",
																	// margin: "9px",
																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																	// transition:
																	// 	"background-color 0.2s ease-in-out",
																}}
																className="img-fluid--icons"
																onClick={() =>
																	handleBulkConfirmation(setDuplicate)
																}
															>
																<img
																	src={duplicate}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
																{/* </div> */}
															</div>
														</OverlayTrigger>

														{/* <Dropdown>
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															{dropDown && (
																<div className="dropdown">
																	<div className="dropdown-list">
																		<ul>
																			<li class="menunews">
																				<a>
																					<span>Make as Inactive</span>
																				</a>
																				<a>
																					<span>Make as Active</span>
																				</a>
																			</li>
																		</ul>
																	</div>
																</div>
															)}
														</Dropdown> */}
														{/* Dropdown*/}

														<div class="dropdown">
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setActivate)
																	}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setDeactivate)
																	}
																>
																	Make as Inactive
																</a>
															</div>
														</div>

														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 224px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{selectedRows.length} Holiday(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 386px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 6px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={() => setSelectedRows([])}
														>
															<img
																src={CancelIcon}
																// className="img-fluid--icons"
																alt="mindreach images"
															/>
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{selectAllRow.length !== 0 && (
									<Row className="align-items-center">
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select">
													<>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Delete")}
														>
															<div
																onClick={() =>
																	handleBulkConfirmation(setShowModalDeleteAll)
																}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</OverlayTrigger>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",
																	// margin: "9px",
																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																	// transition:
																	// 	"background-color 0.2s ease-in-out",
																}}
																className="img-fluid--icons"
																onClick={() =>
																	handleBulkConfirmation(setDuplicateAll)
																}
															>
																<img src={duplicate} alt="mindreach images" />
															</div>
														</OverlayTrigger>

														{/* Dropdown*/}

														<div class="dropdown">
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setActivateAll)
																	}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setDeactivateAll)
																	}
																>
																	Make as Inactive
																</a>
															</div>
														</div>
														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 227px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{rows.length} Holiday(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 386px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 9px 9px 8px",
															}}
															className="img-fluid--icons"
															onClick={handleClearSelectAll}
														>
															<img src={CancelIcon} alt="mindreach images" />
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}

								{!selectedRows.length && !selectAllRow.length && (
									<Row className="align-items-center">
										<Col lg={2} sm={2} md={2}>
											<div className="form-group">
												<Select
													onChange={(e) => setHolidayYear(e.label)}
													options={year}
													placeholder="Year"
													isSearchable
												/>
											</div>
										</Col>
										<Col lg={2} sm={2} md={2}>
											<div className="form-group">
												<Select
													onChange={(e) => setBranch(e.value)}
													options={selectLabel(branches)}
													placeholder="Select branch"
													isSearchable
												/>
											</div>
										</Col>
										<Col lg={2} sm={2} md={2}>
											<div className="form-group">
												<Select
													onChange={(e) => setSelectShiftLabel(e.value)}
													options={selectShiftLabel(shift)}
													placeholder="Select Shift"
													isSearchable
												/>
											</div>
										</Col>
										<Col lg={3} sm={3} md={3}>
											<div className="form-group">
												<Form.Control
													type="search"
													name="search"
													placeholder="Search"
													aria-label="Search"
													className="me-2 search-icon"
													onChange={(e) => setSearch(e.target.value)}
												/>
											</div>
										</Col>
										<Col lg={2} sm={2} md={2}>
											<div className="form-group">
												<p className="customer-count">
													<span>
														<img
															src={user}
															className="img-fluid"
															alt="mindreach images"
														/>
													</span>
													{holidayData[0].holidays.total} Holiday(s)
												</p>
											</div>
										</Col>
									</Row>
								)}
							</Form>
						</div>
						<div>
							<Button
								variant="primary"
								onClick={() => navigate(ADD_HOLIDAY_PAGE)}
							>
								<span>
									<img src={plus} className="img-fluid mr-2" alt="plus icon" />
								</span>
								New Holiday
							</Button>{" "}
						</div>
					</div>

					{holidayData[0].holidays.data !== undefined &&
						holidayData[0].holidays.data !== 0 && (
							<>
								<div
									className={
										`table-responsive table-b custom-new-table` +
										`  ` +
										`${
											selectedRows.length || selectAllRow.length
												? `mt-5--table`
												: ""
										}`
									}
								>
									<Table hover>
										{holidayData[0].holidays.data !== undefined && (
											<thead>
												{typeof rows !== "undefined" && rows.length !== 0 && (
													<tr>
														<th>
															{!selectedRows.length && (
																<input
																	type="checkbox"
																	checked={selectAll}
																	onClick={handleSelectAll}
																/>
															)}
														</th>
														<th>Year</th>
														<th>Holiday</th>
														<th>Branch</th>
														<th>Workkshift</th>
														<th>From Date</th>
														<th>End Date</th>
														<th>Status</th>
														<th></th>
													</tr>
												)}
											</thead>
										)}
										<tbody>
											{holidayData[0].holidays.data !== undefined &&
												rows.map((item) => {
													const list = (
														<>
															<tr>
																<td className="checkbox-elements--table">
																	<input
																		type="checkbox"
																		checked={
																			selectedRows.length
																				? selectedRows.find(
																						(selectedRow) =>
																							selectedRow.id === item
																				  )
																				: item.selected === true
																		}
																		onChange={(e) => handleSelectRow(e, item)}
																	/>
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_HOLIDAY_PAGE}/${item.encrypted_id}`
																		)
																	}
																>
																	{item.year === undefined ? "2023" : item.year}
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_HOLIDAY_PAGE}/${item.encrypted_id}`
																		)
																	}
																>
																	{item.holiday_name}
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_HOLIDAY_PAGE}/${item.encrypted_id}`
																		)
																	}
																>
																	{item.brnach_name}
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_HOLIDAY_PAGE}/${item.encrypted_id}`
																		)
																	}
																>
																	{item.shift_name}
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_HOLIDAY_PAGE}/${item.encrypted_id}`
																		)
																	}
																>
																	{new Date(
																		item.from_date
																	).toLocaleDateString()}
																</td>
																<td
																	onClick={() =>
																		navigate(
																			`${EDIT_HOLIDAY_PAGE}/${item.encrypted_id}`
																		)
																	}
																>
																	{item.end_date !== null &&
																		new Date(
																			item.end_date
																		).toLocaleDateString()}
																</td>
																<td
																	onClick={() =>
																		handleActivateOrDeactive(
																			item.is_active,
																			item.encrypted_id,
																			item.holiday_name
																		)
																	}
																>
																	{item.is_active === "1" ? (
																		<div className="active-staff">Active</div>
																	) : (
																		<div>Inactive</div>
																	)}
																</td>
																<td className="edittd">
																	<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
																		<div
																			className=""
																			onClick={() =>
																				handleDeleteConfirmation(
																					item.encrypted_id,
																					item.holiday_name
																				)
																			}
																		>
																			<img
																				src={Delete}
																				className="img-fluid"
																				alt="Delete"
																			/>
																		</div>
																	</div>
																</td>
															</tr>
														</>
													);
													return list;
												})}
											{typeof rows !== "undefined" && rows.length === 0 && (
												<NoDataFound />
											)}
										</tbody>
									</Table>
								</div>
								<Row className="mt-4">
									<Col
										md={{ span: 4, offset: 8 }}
										className="flex flex-md-row-reverse "
									>
										<Pagination>
											<Pagination.Prev onClick={() => PreviousPagination()} />
											{PaginationItems()}
											<Pagination.Next onClick={() => NextPagination()} />
										</Pagination>
									</Col>
								</Row>
							</>
						)}
					{typeof holidayData[0].holidays.data === undefined &&
						holidayData[0].holidays.data.length === 0 && <NoDataFound />}
				</div>
				<DeleteModalConfirmation
					show={deleteShowByID}
					onHide={() => {
						setDeleteShowBYID(false);
					}}
					branch={"holiday"}
					name={holiday}
					title={"Delete Holiday"}
					handleConfirm={() => handleDelete(holiday_id)}
				/>
				<DeleteModalConfirmation
					show={show}
					onHide={() => {
						setShow(false);
					}}
					branch={"holiday"}
					name={selectedRowId.toString()}
					title={"Delete Holiday"}
					handleConfirm={() => handleBulkDelete()}
				/>
				<DeleteModalConfirmation
					show={showModalDeleteAll}
					onHide={() => {
						setShowModalDeleteAll(false);
					}}
					branch={"branch"}
					name={selectedRowName.toString()}
					title={"Delete branch"}
					handleConfirm={() => handleBulkDeleteAll()}
				/>
				<ActivateModalConfirmation
					show={holiday_activate}
					onHide={() => {
						setActivate(false);
					}}
					branch={selectedRowId.length === 1 ? "holiday" : "holidays"}
					name={selectedRowId.toString()}
					title={"Activate Holidays"}
					handleConfirm={() => handleBulkActivate()}
				/>
				<ActivateModalConfirmation
					show={holiday_activate_All}
					onHide={() => {
						setActivateAll(false);
					}}
					branch={selectedRowName.length === 1 ? "holiday" : "holidays"}
					name={selectedRowName.toString()}
					title={"Activate Holidays"}
					handleConfirm={() => handleBulkActivateAll()}
				/>
				<DeActivateModalConfirmation
					show={holiday_deactivate}
					onHide={() => {
						setDeactivate(false);
					}}
					branch={selectedRowId.length === 1 ? "holiday" : "holidays"}
					name={selectedRowId.toString()}
					title={"Deactivate holiday"}
					handleConfirm={() => handleBulkDeactivate()}
				/>
				<DeActivateModalConfirmation
					show={holiday_deactivate_All}
					onHide={() => {
						setDeactivateAll(false);
					}}
					branch={selectedRowName.length === 1 ? "holiday" : "holidays"}
					name={selectedRowName.toString()}
					title={"Deactivate holiday"}
					handleConfirm={() => handleBulkDeactivateAll()}
				/>
				<DuplicateModalConfirmation
					show={holiday_duplicate}
					onHide={() => {
						setDuplicate(false);
					}}
					branch={"holidays"}
					name={selectedRowId.toString()}
					title={"Duplicate Holidays"}
					handleConfirm={() => handleBulkDuplicate()}
				/>
				<DuplicateModalConfirmation
					show={holiday_duplicate_All}
					onHide={() => {
						setDuplicateAll(false);
					}}
					branch={"holidays"}
					name={selectedRowName.toString()}
					title={"Duplicate Holidays"}
					handleConfirm={() => handleBulkDuplicateAll()}
				/>
				{/*************Activate or deactivate by id */}
				<ActivateModalConfirmation
					show={activate_id}
					onHide={() => {
						setActivateById(false);
					}}
					branch={"Holiday"}
					name={status_name}
					title={"Activate Holidays"}
					handleConfirm={() => handleActivateById()}
				/>
				<DeActivateModalConfirmation
					show={deactivate_id}
					onHide={() => {
						setDeactivateById(false);
					}}
					branch={"Holiday"}
					name={status_name}
					title={"Deactivate holiday"}
					handleConfirm={() => handleDeactivateById()}
				/>
			</>
		);
	}
};
export default HolidaysLists;
