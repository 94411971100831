import React from 'react'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'react-bootstrap/Pagination';
import total from '../../../../assets/Images/total.svg'
import customertotalerrorcount from '../../../../assets/Images/customer-total-error-count.svg'
import customertotalerrorrate from '../../../../assets/Images/customer-total-error-rate.svg'
import averageturnaroundtime from '../../../../assets/Images/average-turnaround-time.svg'
import revenue from '../../../../assets/Images/revenue.svg'
import totalrevenue from '../../../../assets/Images/total-revenue.svg'
import pvsearned from '../../../../assets/Images/pvs-earned.svg'

import Select from 'react-select';

const Orders = () => {

    const chooseday = [
        { label: "Today", value: 355 },
        { label: "Today", value: 54 },
        { label: "Today", value: 58 }
    ];

    const invoices = [{
        id: '1',
        OrderNo: "0000054",
        CreatedOn: '19 Aug 2022 12:01',
        ImageCount: '125',
        ErrorCount: '5',
        ErrorRate: '0.40%',
        TurnaroundTime: '04:04:39',
        PVSCost: '100',
        Status: <p style={{ color: '#F1A100' }}>Processing</p>

    },
    {
        id: '1',
        OrderNo: "0000054",
        CreatedOn: '19 Aug 2022 12:01',
        ImageCount: '125',
        ErrorCount: '5',
        ErrorRate: '0.40%',
        TurnaroundTime: '04:04:39',
        PVSCost: '100',
        Status: <p style={{ color: '#F1A100' }}>Processing</p>

    },
    {
        id: '1',
        OrderNo: "0000054",
        CreatedOn: '19 Aug 2022 12:01',
        ImageCount: '125',
        ErrorCount: '5',
        ErrorRate: '0.40%',
        TurnaroundTime: '04:04:39',
        PVSCost: '100',
        Status: <p style={{ color: '#C71515' }}>Pending</p>

    },
    {
        id: '1',
        OrderNo: "0000054",
        CreatedOn: '19 Aug 2022 12:01',
        ImageCount: '125',
        ErrorCount: '5',
        ErrorRate: '0.40%',
        TurnaroundTime: '04:04:39',
        PVSCost: '100',
        Status: <p style={{ color: '#C71515' }}>Pending</p>

    },
    {
        id: '1',
        OrderNo: "0000054",
        CreatedOn: '19 Aug 2022 12:01',
        ImageCount: '125',
        ErrorCount: '5',
        ErrorRate: '0.40%',
        TurnaroundTime: '04:04:39',
        PVSCost: '100',
        Status: <p style={{ color: '#008E1A' }}>Completed</p>

    },
    {
        id: '1',
        OrderNo: "0000054",
        CreatedOn: '19 Aug 2022 12:01',
        ImageCount: '125',
        ErrorCount: '5',
        ErrorRate: '0.40%',
        TurnaroundTime: '04:04:39',
        PVSCost: '100',
        Status: <p style={{ color: '#008E1A' }}>Completed</p>

    }
    ]
    const columns = [{
        dataField: 'OrderNo',
        text: 'Order No.',
        number: '1',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    }, {
        dataField: 'CreatedOn',
        text: 'Created On',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ImageCount',
        text: 'Image Count',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ErrorCount',
        text: 'Error Count',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'ErrorRate',
        text: 'Error Rate',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'TurnaroundTime',
        text: 'Turnaround Time',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },

    {
        dataField: 'Status',
        text: 'Status',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'PVSCost',
        text: 'PVS Cost',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    }

    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    // <BootstrapTable
    //     keyField="id"
    //     data={invoices}
    //     columns={columns}
    //     striped
    //     hover
    //     condensed
    //     defaultSortDirection="asc"
    // />

    return (
        <>


            <div className="customer-search">
                <Form>
                    <Row className="align-items-center">
                        <Col lg={2} sm={6}>
                            <div className="form-group">
                                <Select options={chooseday} defaultValue={{ label: "Today", value: 355 }} />
                            </div></Col>
                        <Col lg={5} sm={6}>
                            <div className="form-group">
                                <Form.Control
                                    type="search"
                                    placeholder="Search Order No."
                                    className="me-2 search-icon"
                                    aria-label="Search"
                                />  </div>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="d-sm-flex ordertab  mb-4">
                <Card className="bg-card">
                    {/* <Card.Img variant="top" src={customertotalimagecount} /> */}
                    <Card.ImgOverlay>
                        <Card.Img variant="top" src={total} />
                        <Card.Body>
                            <Card.Title>Total Image Count</Card.Title>
                            <Card.Text>
                                4,801
                            </Card.Text>
                        </Card.Body>
                    </Card.ImgOverlay>

                </Card>

                <Card>
                    <Card.Img variant="top" src={customertotalerrorcount} />
                    <Card.Body>
                        <Card.Title>Total Error Count</Card.Title>
                        <Card.Text>
                            14
                        </Card.Text>

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={customertotalerrorrate} />
                    <Card.Body>
                        <Card.Title>Total Error Rate</Card.Title>
                        <Card.Text>
                            1.29%
                        </Card.Text>

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={averageturnaroundtime} />
                    <Card.Body>
                        <Card.Title>Average Turnaround Time</Card.Title>
                        <Card.Text>
                            07:03:12
                        </Card.Text>

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={pvsearned} />
                    <Card.Body>
                        <Card.Title>Total PVS Cost</Card.Title>
                        <Card.Text>
                            9,183,198
                        </Card.Text>

                    </Card.Body>
                </Card>


            </div>

            <div className="table-responsive table-b">
                <BootstrapTable keyField='id' data={invoices} columns={columns} />

            </div>
            <Row className="mt-4">
                <Col md={{ span: 4, offset: 8 }}>
                    {
                        <Pagination>
                            <Pagination.Prev />
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Item>{5}</Pagination.Item>
                            <Pagination.Item>{6}</Pagination.Item>
                            <Pagination.Next />
                        </Pagination>
                    }
                </Col>
            </Row>


        </>
    )
}

export default Orders