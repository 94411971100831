import React, { useCallback, useEffect, useState } from "react";
import { Button, Row, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";
//Importing the react select components
import Select from "react-select";
import Loader from "../../../../../components/Loader";

import { client } from "./../../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../../../data/filepath";
import {
	CO_ORDINATOR_VIEW_EDIT_GROUP,
	CO_ORDINATOR_UPDATE_EDIT_GROUP,
	CO_ORDINATOR_DUPLICATE_IMAGES,
} from "../../../../../config/apiRoutes.api";

const DuplicateSettings = ({
	setting_profile,
	selectImageName,
	token,
	currentUserId,
	edit_select,
	setEditorType,
	editTypes,
	editGroups,
	idSelected,
	customer_id,
	setMultipleImagesId,
	setClickSelect,
	setSelectAll,
	title,
	onHide,
	show,
	handleFetchProfileSettings,
	duplicate_amount,
	copyAmounts,
	setDuplicateAmount,
	setDuplicateSettings,
	viewDuplicateSettings,
	setViewDuplicateSettings,
	...props
}) => {
	/**
	 * Transform an array of customer edit types into an array of objects with label, value, and additional properties.
	 * @param {Array} customerEditTypes - Array of customer edit types.
	 * @returns {Array} - Transformed array of objects with label, value, and additional properties.
	 */
	const selectLabel = (customerEditTypes) =>
		customerEditTypes.map(({ group_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of array as label and value return a new array of object like [{value:"",label:""}]
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);

	//Declarations of const
	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];
	const selectBackgrondRemoval = [
		{ label: "Custom", value: 355 },
		{ label: "Transparent", value: 54 },
	];
	const selectedAmount = [
		{ label: "1", value: 355 },
		{ label: "2", value: 54 },
		{ label: "3", value: 54 },
	];
	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];
	// const initial_data = [
	// 	{
	// 		id: "",
	// 		file_format: "",
	// 		height: "",
	// 		width: "",
	// 		dpi: "",
	// 		dpi_type: "",
	// 		color_mode: "",
	// 		alignment: "",
	// 		margin_format: "",
	// 		margin_top: "",
	// 		margin_bottom: "",
	// 		margin_right: "",
	// 		margin_left: "",
	// 	},
	// ];

	//Declarations of state
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State
	const [groupSelect, setGroupSelect] = useState(false);
	const [checked, setChecked] = useState(false);
	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [checked3, setChecked3] = useState(false);
	const [selected, setSelected] = useState(false);
	const [selected1, setSelected1] = useState(false);
	const [selected2, setSelected2] = useState(false);
	const [selected3, setSelected3] = useState(false);
	const [amount, setAmount] = useState([]);

	const [edit_id, setEditId] = useState([]);
	const [edit_type_toggle, setToggleEditType] = useState(false);
	//To update the select group
	const [group_name_select, setEditGroupNameSelect] = useState("");
	// To set the state for add
	const [
		background_removal_color_type,
		setBackgroundRemovalColorType,
	] = useState("");
	const [
		background_removal_color_code,
		setBackgroundRemovalColorCode,
	] = useState("");
	const [
		background_adjustment_color_code,
		setBackgroundAdjustmentColorCode,
	] = useState("");
	const [
		background_adjustment_color_type,
		setBackgroundAdjustmentColorType,
	] = useState("");
	const [view_edit_group, setViewEditGroup] = useState({});
	const [group_name, setGroupName] = useState("");
	const [new_edit_group_name, setNewEditGroupName] = useState("");
	// const [edit_select, setEditorType] = useState("");
	// const [edit_group_name_select, setEditGroupNameSelect] = useState("");
	const [resizing_file_format, setResizingFileFormat] = useState("");
	const [resizing_height, setResizingHeight] = useState("");
	const [resizing_width, setResizingWidth] = useState("");
	const [resizing_dpi, setResizingDpi] = useState("");
	const [resizing_dpi_type, setResizingDpiType] = useState("");
	const [resizing_color_mode, setResizingColorMode] = useState("");
	const [resizing_alignment, setResizingAlignment] = useState("");
	const [resizing_margin_format, setResizingMarginFormat] = useState("");
	const [resizing_margin_top, setResizingMarginTop] = useState("");
	const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
	const [resizing_margin_right, setResizingMarginRight] = useState("");
	const [resizing_margin_left, setResizingMarginLeft] = useState("");
	const [edit_group_name, setEditGroupName] = useState("");
	const [edit_type_ids, setEditTypeId] = useState("");
	const [image_file_format, setImageFileFormat] = useState("");
	const [image_height, setImageHeight] = useState("");
	const [image_width, setImageWidth] = useState("");
	const [image_dpi, setImageDpi] = useState("");
	const [image_dpi_type, setImageDpiType] = useState("");
	const [image_color_mode, setImageColorMode] = useState("");
	const [image_alignment, setImageAlignment] = useState("");
	const [image_margin_format, setImageMarginFormat] = useState("");
	const [image_margin_top, setImageMarginTop] = useState("");
	const [image_margin_bottom, setImageMarginBottom] = useState("");
	const [image_margin_right, setImageMarginRight] = useState("");
	const [image_margin_left, setImageMarginLeft] = useState("");
	const [group, setGroup] = useState([]);
	const [key, setKey] = useState(0); // Initialize active tab to '1'
	const [activeTab, setActiveTab] = useState(1); // Initialize activeTab state to 0
	const [newImageEditType, setNewImageEditType] = useState([]);
	const [errors, setErrors] = useState({});
	const [imageNamePath, setImageNamePath] = useState("");
	const [fileFormat, setFileFormat] = useState([]); // Initialize as an empty array

	/**
	 * Handle the selection of checkboxes and update the `editId` state accordingly.
	 * @param {ChangeEvent} changeEvent - The change event object.
	 */
	const handleAddCheckboxSelect = (changeEvent) => {
		console.log("id", changeEvent);
		changeEvent.persist();
		setEditId((prevState) => {
			const editItem = changeEvent.target.value;
			console.log("changeEvent", changeEvent.target.value);

			if (prevState.includes(editItem)) {
				// If the selected editItem is already in the prevState array,
				// remove it from the array and return the updated array.
				return prevState.filter((el) => el !== editItem);
			}
			// If the selected editItem is not in the prevState array,
			// add it to the array and return the updated array.
			return [...prevState, editItem];
		});
	};
	/* Handle the checkbox state and perform actions based on the given id.
	 * @param {number} id - The id to determine the checkbox action.
	 */
	const handleBackGroundRemovalCheckbox = (id) => {
		if (id === 1) {
			setSelected((val) => !val);
			setChecked((val) => !val);
		}
		if (id === 2) {
			setSelected1((val) => !val);
			setChecked1((val) => !val);
		}
		if (id === 3) {
			setSelected2((val) => !val);
			setChecked2((val) => !val);
		}
		if (id === 15) {
			setSelected3((val) => !val);
			setChecked3((val) => !val);
		}
	};
	/**
	 * Create an array of checked values for the edit_types in view_edit_group
	 */
	function getEditTypesIds(view_edit_group) {
		if (
			view_edit_group &&
			view_edit_group.edit_group &&
			view_edit_group.edit_group.edit_types &&
			Array.isArray(view_edit_group.edit_group.edit_types)
		) {
			return view_edit_group.edit_group.edit_types.map((items, index) => {
				return items.id;
			});
		} else {
			// Handle the case where any of the properties is null or not an array
			// You can return a default value or handle the error appropriately.
			return []; // Returning an empty array as a default value
		}
	}
	/**
	 * Handles the update of an image edit group by sending a POST request to the server.
	 * @returns {void}
	 */
	const handleUpdateImageEditGroup = () => {
		if (token) {
			setLoader(true);
			// Append form data with the necessary parameters
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append("image_ids", idSelected);

			bodyFormData.append("is_overright", 1);
			bodyFormData.append("edit_group_id", edit_select);
			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			bodyFormData.append("resizing_file_format", resizing_file_format);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);
			bodyFormData.append("resizing_dpi", resizing_dpi);
			bodyFormData.append("resizing_dpi_type", resizing_dpi_type);
			bodyFormData.append("resizing_color_mode", resizing_color_mode);
			bodyFormData.append("resizing_alignment", resizing_alignment);
			bodyFormData.append("resizing_margin_format", resizing_margin_format);
			bodyFormData.append("resizing_margin_top", resizing_margin_top);
			bodyFormData.append("resizing_margin_bottom", resizing_margin_bottom);
			bodyFormData.append("resizing_margin_right", resizing_margin_right);
			bodyFormData.append("resizing_margin_left", resizing_margin_left);
			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response

					setStatus("Successfully Group updated");
					console.log("response", response);
					setLoader(false);
					setMultipleImagesId([]);
					setClickSelect(false);
					setSelectAll(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleUpdateEditGroup = () => {
		if (token) {
			setLoader(true);
			// Append form data with the necessary parameters
			bodyFormData.append("customer_id", customer_id);
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("is_overright", 1);

			bodyFormData.append(
				"background_removal_color_type",
				background_removal_color_type
			);
			bodyFormData.append(
				"background_removal_color_code",
				background_removal_color_code
			);
			bodyFormData.append(
				"background_adjustment_color_type",
				background_adjustment_color_type
			);
			bodyFormData.append(
				"background_adjustment_color_code",
				background_adjustment_color_code
			);
			bodyFormData.append("resizing_file_format", resizing_file_format);
			bodyFormData.append("resizing_height", resizing_height);
			bodyFormData.append("resizing_width", resizing_width);
			bodyFormData.append("resizing_dpi", resizing_dpi);
			bodyFormData.append("resizing_dpi_type", resizing_dpi_type);
			bodyFormData.append("resizing_color_mode", resizing_color_mode);
			bodyFormData.append("resizing_alignment", resizing_alignment);
			bodyFormData.append("resizing_margin_format", resizing_margin_format);
			bodyFormData.append("resizing_margin_top", resizing_margin_top);
			bodyFormData.append("resizing_margin_bottom", resizing_margin_bottom);
			bodyFormData.append("resizing_margin_right", resizing_margin_right);
			bodyFormData.append("resizing_margin_left", resizing_margin_left);
			bodyFormData.append("image_file_format", image_file_format);
			bodyFormData.append("image_height", image_height);
			bodyFormData.append("image_width", image_width);
			bodyFormData.append("image_dpi", image_dpi);
			bodyFormData.append("image_dpi_type", image_dpi_type);
			bodyFormData.append("image_color_mode", image_color_mode);
			bodyFormData.append("image_alignment", image_alignment);
			bodyFormData.append("image_margin_format", image_margin_format);
			bodyFormData.append("image_margin_top", image_margin_top);
			bodyFormData.append("image_margin_bottom", image_margin_bottom);
			bodyFormData.append("image_margin_right", image_margin_right);
			bodyFormData.append("image_margin_left", image_margin_left);

			request
				.post(apiURL + CO_ORDINATOR_UPDATE_EDIT_GROUP, bodyFormData)
				.then((response) => {
					//Set the success from the response

					setStatus("Successfully Group updated");
					console.log("response", response);
					setLoader(false);
					setMultipleImagesId([]);
					setClickSelect(false);
					setSelectAll(false);
					// fetchCheckoutPrice();
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		// Create a new array by combining existing group values and the new object
		const updatedGroup = [
			...editGroups,
			{ id: "", group_name: "New Settings" },
		];
		setGroup(updatedGroup);
		setSelected(false);
		setSelected1(false);
		setSelected3(false);
	}, [editGroups]);
	useEffect(() => {
		// Fetch data from the API endpoint
		if (token && edit_select !== 0) {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_EDIT_GROUP + `/${edit_select}`)
				.then((response) => {
					//Set View  for edit group from the response
					let data = response.data;
					console.log("call the edit type", data);
					setViewEditGroup(data);
					setBackgroundRemovalColorCode(
						data.edit_group.background_settings[0].color_code
					);
					setBackgroundAdjustmentColorCode(
						data.edit_group.background_settings[1].color_code
					);
					setBackgroundRemovalColorType(
						data.edit_group.background_settings[0] !== undefined
							? data.edit_group.background_settings[0].color_type
							: "Select Color Type"
					);
					setBackgroundAdjustmentColorType(
						data.edit_group.background_settings[1] !== undefined
							? data.edit_group.background_settings[1].color_type
							: "Select color type"
					);
					setResizingFileFormat(
						data.edit_group.resizing_settings[0].file_format !== undefined
							? data.edit_group.resizing_settings[0].file_format
							: "Select File Format"
					);
					setResizingDpiType(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].dpi_type
							: "Select File Format"
					);
					setResizingColorMode(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].color_mode
							: "Select File Format"
					);
					setResizingAlignment(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].alignment
							: "Select File Format"
					);
					setResizingMarginFormat(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].margin_format
							: "Select File Format"
					);
					setImageFileFormat(
						data.edit_group.setting.file_format !== undefined
							? data.edit_group.setting.file_format
							: "Select file format"
					);
					setImageDpiType(
						data.edit_group.setting.dpi_type !== null
							? data.edit_group.setting.dpi_type
							: "Select Dpi type"
					);
					setImageColorMode(
						data.edit_group.setting !== null
							? data.edit_group.setting.color_mode
							: "Select color mode"
					);
					setImageAlignment(
						data.edit_group.setting !== null
							? data.edit_group.setting.alignment
							: "Select Align"
					);
					setImageMarginFormat(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_format
							: "Margin"
					);
					if (data.edit_group.background_settings.length) {
						setSelected(true);
						setSelected1(true);
					}
					if (data.edit_group.resizing_settings.length) {
						setSelected2(true);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		} else {
			setViewEditGroup({});
			setBackgroundRemovalColorCode("");
			setBackgroundAdjustmentColorCode("");
			setBackgroundRemovalColorType("");
			setBackgroundAdjustmentColorType("");
			setResizingFileFormat("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setImageFileFormat("");
			setImageDpiType("");
			// setImageColorMode(
			// 	data.edit_group.setting !== null
			// 		? data.edit_group.setting.color_mode
			// 		: "Select color mode"
			// );
			// setImageAlignment(
			// 	[]
			// );
			// setImageMarginFormat(
			// 	[]
			// );
		}
	}, [edit_select, token]);
	// Usage
	let updateChecked = getEditTypesIds(setting_profile);
	useEffect(() => {
		setAmount(duplicate_amount);
		// setDuplicateAmount(viewDuplicateSettings);
	}, []);
	const getFilteredSet = (propertyName, dataArray) => {
		return (
			dataArray !== undefined &&
			dataArray
				.map((items) => items[propertyName])
				.filter((value) => value !== undefined)
		);
	};
	const idSet = getFilteredSet("id", viewDuplicateSettings);
	const fileFormatSet = getFilteredSet("file_format", viewDuplicateSettings);
	const heightSet = getFilteredSet("height", viewDuplicateSettings);
	const widthSet = getFilteredSet("width", viewDuplicateSettings);
	const dpiSet = getFilteredSet("dpi", viewDuplicateSettings);
	const dpiTypeSet = getFilteredSet("dpi_type", viewDuplicateSettings);
	const colorModeSet = getFilteredSet("color_mode", viewDuplicateSettings);
	const alignmentSet = getFilteredSet("alignment", viewDuplicateSettings);
	const marginFormatSet = getFilteredSet(
		"margin_format",
		viewDuplicateSettings
	);
	const marginTopSet = getFilteredSet("margin_top", viewDuplicateSettings);
	const marginBottomSet = getFilteredSet(
		"margin_bottom",
		viewDuplicateSettings
	);
	const marginRightSet = getFilteredSet("margin_right", viewDuplicateSettings);
	const marginLeftSet = getFilteredSet("margin_left", viewDuplicateSettings);
	// Function to validate individual fields
	const validateField = (field, value) => {
		let error = "";

		if (!value) {
			error = `${field} is required`;
		}

		return error;
	};
	// Function to validate the form fields

	const validate = () => {
		const fieldValidationRules = {
			id: "Id required",
			file_format: "File Format is required",
			height: "Height is required",
			width: "Width is required",
			dpi: "Dpi is required",
			dpi_type_Value: "Dpi type is required",
			color_mode_Value: "Color Mode is required",
			alignment_Value: "Alignment is required",
			margin_format: "Margin format is required",
			margin_top: "Margin Top is required",
			margin_bottom: "Margin Bottom is required",
			margin_right: "Margin Right is required",
			margin_left: "Margin Left is required",
		};

		let newErrors = {};

		// Combine margin errors into a single error message
		const marginFields = [
			"margin_top",
			"margin_bottom",
			"margin_right",
			"margin_left",
		];
		if (
			marginFields.some(
				(field) =>
					newImageEditType[field] !== undefined &&
					newImageEditType[field].length !== amount.length
			)
		) {
			newErrors.margin = "All margin fields are required";
		}

		// Validate and set errors for individual fields
		for (const field in fieldValidationRules) {
			if (
				newImageEditType[field] !== undefined &&
				newImageEditType[field].length !== amount.length
			) {
				newErrors[field] = fieldValidationRules[field];
			}
		}

		// Check if there are any validation errors
		const missingFields = Object.keys(fieldValidationRules).filter(
			(field) =>
				newImageEditType[field] === undefined ||
				newImageEditType[field].length !== amount.length
		);

		if (missingFields.length > 0) {
			newErrors.all = `Please fill the required details for ${missingFields
				.map((field) => field.replace(/_/g, " "))
				.join(", ")}`;
		}

		return newErrors;
	};

	useEffect(() => {
		const newState = {
			id: idSet,
			file_format: fileFormatSet,
			height: heightSet,
			width: widthSet,
			dpi: dpiSet,
			dpi_type_Value: dpiTypeSet,
			color_mode_Value: colorModeSet,
			alignment_Value: alignmentSet,
			margin_format: marginFormatSet,
			margin_top: marginTopSet,
			margin_bottom: marginBottomSet,
			margin_right: marginRightSet,
			margin_left: marginLeftSet,
		};

		// Update all state variables at once

		setNewImageEditType(newState);
		console.log("viewDuplicateSettings", amount);
	}, [viewDuplicateSettings]);

	// useState(() => {
	// 	const initialAmount = viewDuplicateSettings.map((data) => {
	// 		const newState = {
	// 			file_format: data.file_format,
	// 			height: data.height,
	// 			width: data.width,
	// 			dpi: data.dpi,
	// 			dpi_type_Value: data.dpi_type,
	// 			color_mode_Value: data.color_mode,
	// 			alignment_Value: data.alignment,
	// 			margin_format: data.margin_format,
	// 			margin_top: data.margin_top,
	// 			margin_bottom: data.margin_bottom,
	// 			margin_right: data.margin_right,
	// 			margin_left: data.margin_left,
	// 		};
	// 		return newState;
	// 	});

	// 	// Assuming setNewImageEditType is the state updater function
	// 	setNewImageEditType(initialAmount);
	// }, [amount]);
	const handleFormSubmit = () => {
		if (!token) {
			return; // Exit early if there's no token
		}

		const newErrors = {};

		if (key < amount.length) {
			setKey(Number(activeTab) + Number(key));
		}

		const formatAndAppendValue = (formData, property, value) => {
			if (value !== undefined) {
				const formattedValue =
					value !== undefined
						? value
								.filter((id) => id) // Filter out non-numeric values
								.map((id) => id)
								.join(",")
						: "";
				formData.append(property, formattedValue);
			}
		};
		// Validate each field and update errors
		const validateAndSetError = (field, value) => {
			if (!value) {
				newErrors[field] = `${field} is required`;
			} else {
				delete newErrors[field];
			}
		};

		// Perform field validations
		validateAndSetError("file_format", newImageEditType.file_format);
		validateAndSetError("height", newImageEditType.height);
		validateAndSetError("width", newImageEditType.width);
		validateAndSetError("dpi", newImageEditType.dpi);
		validateAndSetError("dpi_type", newImageEditType.dpi_type_Value);
		validateAndSetError("color_mode", newImageEditType.color_mode_Value);
		validateAndSetError("alignment", newImageEditType.alignment_Value);
		validateAndSetError("margin_format", newImageEditType.margin_format);
		validateAndSetError("margin_top", newImageEditType.margin_top);
		validateAndSetError("margin_bottom", newImageEditType.margin_bottom);
		validateAndSetError("margin_right", newImageEditType.margin_right);
		validateAndSetError("margin_left", newImageEditType.margin_left);

		// Check if there are any validation errors
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		// Clear any previous errors if there are no validation issues
		setErrors({});

		// Continue with API request
		bodyFormData.append("image_ids", setting_profile.id);
		bodyFormData.append("customer_id", customer_id);
		bodyFormData.append("image_name", imageNamePath);

		formatAndAppendValue(
			bodyFormData,
			"image_duplication_id",
			newImageEditType.id
		);

		// Format and append values to bodyFormData
		formatAndAppendValue(
			bodyFormData,
			"file_format",
			newImageEditType.file_format
		);
		formatAndAppendValue(bodyFormData, "height", newImageEditType.height);
		formatAndAppendValue(bodyFormData, "width", newImageEditType.width);
		formatAndAppendValue(bodyFormData, "dpi", newImageEditType.dpi);
		formatAndAppendValue(
			bodyFormData,
			"dpi_type",
			newImageEditType.dpi_type_Value
		);
		formatAndAppendValue(
			bodyFormData,
			"color_mode",
			newImageEditType.color_mode_Value
		);
		formatAndAppendValue(
			bodyFormData,
			"alignment",
			newImageEditType.alignment_Value
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_format",
			newImageEditType.margin_format
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_top",
			newImageEditType.margin_top
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_bottom",
			newImageEditType.margin_bottom
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_right",
			newImageEditType.margin_right
		);
		formatAndAppendValue(
			bodyFormData,
			"margin_left",
			newImageEditType.margin_left
		);

		// Send an API request
		if (
			newImageEditType.margin_left !== undefined &&
			newImageEditType.margin_left.length === amount.length
		) {
			request
				.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGES, bodyFormData)
				.then((response) => {
					// Handle success
					console.log("response", response);
					onHide();
					handleFetchProfileSettings(setting_profile.id);
				})
				.catch((err) => {
					// Handle errors
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		try {
			const image_name =
				copyAmounts !== undefined ? copyAmounts.join(", ") : "";

			setImageNamePath(image_name);
		} catch (error) {
			console.error("Error in useEffect:", error);
		}
	}, [copyAmounts]);
	// onChange handler for the File Format field

	console.log("call", newImageEditType, duplicate_amount);
	return (
		<>
			<Modal
				{...props}
				show={show}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter box exclude-click"
				centered
			>
				<Modal.Header onHide={onHide} closeButton>
					<Modal.Title
						id="contained-modal-title-vcenter"
						className="section-title fonts"
					>
						{title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="section-title fonts" style={{ width: "40vw" }}>
					<div className="form-group d-flex">
						<div className="detailstab">
							<Tabs
								defaultActiveKey={0} // Set "Original" as the default tab
								id="justify-tab-example"
								className="stafftab"
								justify
								activeKey={key}
								onSelect={(k) => setKey(k)}
							>
								<Tab eventKey={0} title="Original">
									<div className="sidebar-image--name d-flex">
										<div style={{ width: "35rem" }}>
											<div className="form-group edit-profile-setting-container--single">
												<Row
													className="align-items-center edit-setting--select "
													style={{ position: "absolute", width: "22.5rem" }}
												>
													<Form.Label className="col-sm-3 ">
														Settings Profile
													</Form.Label>
													<Col sm={9}>
														<Select
															options={selectLabel(group)}
															onChange={(e) => {
																setEditGroupNameSelect(e.label);
																setEditorType(e.value);
																setGroupSelect((val) => !val);
															}}
															placeholder="Select Profile"
															value={selectLabel(group).find((option) => {
																if (
																	edit_select !== "" &&
																	edit_select !== null
																) {
																	return option.value === edit_select;
																} else {
																	return null;
																}
															})} // Set the selected value by ID
														/>
													</Col>
												</Row>
											</div>
											<div>
												{group_name_select === "New Settings" ? (
													<div
														style={{
															height: "29.5rem",
															overflow: "scroll",
															width: "100%",
														}}
													>
														{/* Form groups for Edit Type*/}
														<div className="sub-head">Edit Type</div>

														{editTypes !== undefined &&
															editTypes.map((item) => {
																console.log("id item", item.id);
																return (
																	<Form.Group
																		className=""
																		controlId="exampleForm.ControlInput1"
																	>
																		{" "}
																		<Row
																			className="form-group  flex-grow-1"
																			sm={9}
																		>
																			<Col sm={1}>
																				<input
																					type="checkbox"
																					onChange={(e) => {
																						handleBackGroundRemovalCheckbox(
																							item.id
																						);
																						handleAddCheckboxSelect(e);
																					}}
																					value={item.id}
																				/>
																			</Col>
																			<Form.Label
																				className="col-sm-4 form-label form-check-label"
																				style={{ width: "auto" }}
																			>
																				{item.edit_type}
																			</Form.Label>
																		</Row>
																		{item.id === 1 ? (
																			selected ? (
																				<Row
																					className="align-items-center"
																					style={{
																						marginLeft: "auto",
																						marginTop: "0",
																						marginBottom: "20px",
																					}}
																				>
																					<Form.Label className="col-sm-3">
																						Background Color
																					</Form.Label>
																					<Col sm={4}>
																						<Select
																							options={selectBackgrondRemoval}
																							onChange={(e) =>
																								setBackgroundRemovalColorType(
																									e.label
																								)
																							}
																						/>
																					</Col>
																					<Col sm={3}>
																						<Form.Control
																							type="text"
																							placeholder="#ffff"
																							autoFocus
																							onChange={(e) =>
																								setBackgroundRemovalColorCode(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</Col>
																				</Row>
																			) : (
																				""
																			)
																		) : (
																			""
																		)}
																		{item.id === 2 ? (
																			selected1 ? (
																				<Row
																					className="align-items-center"
																					style={{
																						marginLeft: "auto",
																						marginTop: "0",
																						marginBottom: "20px",
																					}}
																				>
																					<Form.Label className="col-sm-3">
																						Background Color
																					</Form.Label>
																					<Col sm={4}>
																						<Select
																							options={selectBackgrondRemoval}
																							onChange={(e) =>
																								setBackgroundAdjustmentColorType(
																									e.label
																								)
																							}
																						/>
																					</Col>
																					<Col sm={3}>
																						<Form.Control
																							type="text"
																							placeholder="#ffff"
																							autoFocus
																							onChange={(e) =>
																								setBackgroundAdjustmentColorCode(
																									e.target.value
																								)
																							}
																							value={
																								background_adjustment_color_code
																							}
																							autoComplete="off"
																						/>
																					</Col>
																				</Row>
																			) : (
																				""
																			)
																		) : (
																			""
																		)}
																		{item.id === 3 ? (
																			selected2 ? (
																				<Row
																					className="align-items-center"
																					style={{
																						marginLeft: "auto",
																						marginRight: "auto",
																						marginTop: "0",
																						marginBottom: "20px",
																						width: " 94%",
																					}}
																				>
																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									File Format
																								</Form.Label>
																								<Col sm={6}>
																									<Select
																										options={file}
																										onChange={(e) =>
																											setResizingFileFormat(
																												e.label
																											)
																										}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Size
																								</Form.Label>
																								<Col sm={9}>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<div className="d-sm-flex col-gap-10 align-items-center">
																											<span className="text-secondary f-sz-13">
																												{" "}
																												H:{" "}
																											</span>
																											<Form.Control
																												type="text"
																												placeholder="2000"
																												autoFocus
																												onChange={(e) =>
																													setResizingHeight(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</div>
																										<div className="d-sm-flex align-items-center">
																											{/* <Image src={Cross} className="img-fluid" alt="cross" /> */}
																										</div>
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<span className="text-secondary f-sz-13">
																												{" "}
																												W:{" "}
																											</span>
																											<Form.Control
																												type="text"
																												placeholder="2000"
																												autoFocus
																												onChange={(e) =>
																													setResizingWidth(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</div>
																									</div>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									DPI
																								</Form.Label>
																								<Col sm={9}>
																									<div className="d-sm-flex col-gap-20">
																										<div className="">
																											<Form.Control
																												type="text"
																												placeholder="2000"
																												autoFocus
																												onChange={(e) =>
																													setResizingDpi(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</div>
																										<div className="flex-fill">
																											<Select
																												options={dpi}
																												onChange={(e) =>
																													setResizingDpiType(
																														e.label
																													)
																												}
																											/>
																										</div>
																									</div>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Color Mode
																								</Form.Label>
																								<Col sm={9}>
																									<Select
																										options={ColorMode}
																										onChange={(e) =>
																											setResizingColorMode(
																												e.label
																											)
																										}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Align
																								</Form.Label>
																								<Col sm={9}>
																									<Select
																										options={Align}
																										onChange={(e) =>
																											setResizingAlignment(
																												e.label
																											)
																										}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label
																									className="col-sm-3"
																									// style={{ marginTop: "-17%" }}
																								>
																									Margins
																								</Form.Label>
																								<Col sm={9}>
																									{/* <div className="d-flex"> */}
																									<Select
																										options={Margin}
																										onChange={(e) =>
																											setResizingMarginFormat(
																												e.label
																											)
																										}
																									/>
																									{/* </div> */}
																								</Col>
																								<Col
																									sm={7}
																									lg={7}
																									style={{
																										display: "flex",
																										marginLeft: "5rem",
																									}}
																								>
																									<div className="d-sm-flex col-gap-20 mt-2 ">
																										{/* <Col sm={9}> */}

																										{/* </Col>
																		<Col sm={4}> */}
																										<div className="d-flex">
																											<ul className="margin-list d-flex">
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="T"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginTop(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="B"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginBottom(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="L"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginLeft(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="R"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginRight(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																													/>
																												</li>
																											</ul>
																										</div>
																										{/* </Col> */}
																									</div>
																								</Col>

																								<Col sm={6}></Col>
																							</Row>
																						</div>
																					</Form.Group>
																				</Row>
																			) : (
																				""
																			)
																		) : (
																			""
																		)}
																	</Form.Group>
																);
															})}
														{/* Form groups for output specifications */}
														<div className="sub-head">
															Output Specifications
														</div>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		File Format
																	</Form.Label>
																	<Col sm={6}>
																		<Select
																			options={file}
																			onChange={(e) =>
																				setImageFileFormat(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		Size
																	</Form.Label>
																	<Col sm={9}>
																		<div className="d-sm-flex align-items-center col-gap-10">
																			<div className="d-sm-flex col-gap-10 align-items-center">
																				<span className="text-secondary f-sz-13">
																					{" "}
																					H:{" "}
																				</span>

																				<Form.Control
																					type="text"
																					placeholder="2000"
																					autoFocus
																					onChange={(e) =>
																						setImageHeight(e.target.value)
																					}
																					autoComplete="off"
																				/>
																			</div>
																			<div className="d-sm-flex align-items-center"></div>
																			<div className="d-sm-flex align-items-center col-gap-10">
																				<span className="text-secondary f-sz-13">
																					{" "}
																					W:{" "}
																				</span>
																				<Form.Control
																					type="text"
																					placeholder="2000"
																					autoFocus
																					onChange={(e) =>
																						setImageWidth(e.target.value)
																					}
																					autoComplete="off"
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		DPI
																	</Form.Label>
																	<Col sm={9}>
																		<div className="d-sm-flex col-gap-20">
																			<Form.Control
																				type="text"
																				placeholder="2000"
																				autoFocus
																				onChange={(e) =>
																					setImageDpi(e.target.value)
																				}
																				autoComplete="off"
																			/>

																			<div className="flex-fill">
																				<Select
																					options={dpi}
																					onChange={(e) =>
																						setImageDpiType(e.label)
																					}
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		Color Mode
																	</Form.Label>
																	<Col sm={9}>
																		<Select
																			options={ColorMode}
																			onChange={(e) =>
																				setImageColorMode(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		Align
																	</Form.Label>
																	<Col sm={9}>
																		<Select
																			options={Align}
																			onChange={(e) =>
																				setImageAlignment(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		Margins
																	</Form.Label>
																	<Col sm={9}>
																		<Select
																			options={Margin}
																			onChange={(e) =>
																				setResizingMarginFormat(e.label)
																			}
																		/>
																		{/* </div> */}
																	</Col>
																	<Col
																		sm={7}
																		lg={7}
																		style={{
																			display: "flex",
																			marginLeft: "5rem",
																		}}
																	>
																		<div className="d-sm-flex col-gap-20 mt-2 ">
																			<div className="d-flex">
																				<ul className="margin-list d-flex">
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="T"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginTop(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="B"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginBottom(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="L"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginLeft(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="R"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginRight(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</Col>

																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<div className="global-gap--btn sidebar-single--btn">
																		<Button
																			variant="primary"
																			className="btns"
																			onClick={() => handleUpdateEditGroup()}
																		>
																			{loader ? (
																				<div
																					style={{ backgroundColor: "wheat" }}
																				>
																					<Loader />
																				</div>
																			) : (
																				"Save"
																			)}
																		</Button>
																	</div>
																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
													</div>
												) : (
													<div
														style={{
															// marginLeft: "5%",
															height: "18.5rem",
															overflow: "scroll",
															width: "100%",
														}}
													>
														{view_edit_group !== null &&
															view_edit_group !== undefined &&
															view_edit_group.edit_group !== null &&
															view_edit_group.edit_group !== undefined &&
															view_edit_group.edit_group.edit_types !== null &&
															view_edit_group.edit_group.edit_types !==
																undefined && (
																<div className="sub-head">Edit Type</div>
															)}
														{/* /***need to check**/}
														{view_edit_group !== null &&
															view_edit_group !== undefined &&
															view_edit_group.edit_group !== null &&
															view_edit_group.edit_group !== undefined &&
															view_edit_group.edit_group.edit_types !== null &&
															view_edit_group.edit_group.edit_types !==
																undefined &&
															view_edit_group.edit_group.edit_types.map(
																(item, index) => {
																	console.log("edit type", item);
																	return (
																		<>
																			<Form.Group
																				className=""
																				controlId="exampleForm.ControlInput1"
																			>
																				{" "}
																				<Row
																					className="form-group  flex-grow-1"
																					sm={9}
																				>
																					<Col sm={1}>
																						<input
																							type="checkbox"
																							value={item.id}
																							// value={!!checked ? 0 : 1}
																							onChange={(e) => {
																								handleBackGroundRemovalCheckbox(
																									item.id
																								);
																								handleAddCheckboxSelect(e);
																							}}
																							checked={
																								updateChecked[index] === 1
																									? true
																									: updateChecked[index] === 2
																									? true
																									: updateChecked[index] === 3
																									? true
																									: updateChecked[index] === 15
																									? true
																									: false
																							}
																						/>
																					</Col>
																					<Form.Label
																						className="col-sm-4 form-label form-check-label"
																						style={{ width: "auto" }}
																					>
																						{item.edit_type}
																					</Form.Label>
																				</Row>
																				{updateChecked[index] === 1 ? (
																					<Row
																						className="align-items-center"
																						style={{
																							// marginLeft: "44px",
																							marginTop: "-2px",
																							marginBottom: "20px",
																							width: "100%",
																						}}
																					>
																						<Form.Label className="col-sm-3">
																							Background Color
																						</Form.Label>
																						<Col sm={5}>
																							<Select
																								options={selectBackgrondRemoval}
																								// onChange={(e) =>
																								// 	setBackgroundRemovalColorTypeUpdate(e.label)
																								// }
																								defaultValue={{
																									label: `${background_removal_color_type}`,
																									id: "21",
																								}}
																								value={{
																									label: background_removal_color_type, // Set the label property to the desired value
																									value: background_removal_color_type, // Set the value property to the corresponding value
																								}}
																							/>
																						</Col>
																						<Col sm={4}>
																							<Form.Control
																								type="text"
																								placeholder="#ffff"
																								autoFocus
																								// onChange={(e) =>
																								// 	setBackgroundRemovalColorCodeUpdate(
																								// 		e.target.value
																								// 	)
																								// }
																								value={
																									background_removal_color_code
																								}
																							/>
																						</Col>
																					</Row>
																				) : (
																					""
																				)}
																				{updateChecked[index] === 2 ? (
																					<Row
																						className="align-items-center"
																						style={{
																							// marginLeft: "44px",
																							marginTop: "-2px",
																							marginBottom: "20px",
																							width: "100%",
																						}}
																					>
																						<Form.Label className="col-sm-3">
																							Background Color
																						</Form.Label>
																						<Col sm={5}>
																							<Select
																								options={selectBackgrondRemoval}
																								// onChange={(e) =>
																								// 	setBackgroundAdjustmentColorTypeUpdate(
																								// 		e.label
																								// 	)
																								// }
																								// defaultValue={{
																								// 	label: `${background_adjustment_color_type}`,
																								// 	id: "21",
																								// }}
																								value={{
																									label: background_adjustment_color_type, // Set the label property to the desired value
																									value: background_adjustment_color_type, // Set the value property to the corresponding value
																								}}
																							/>
																						</Col>
																						<Col sm={4}>
																							<Form.Control
																								type="text"
																								placeholder="#ffff"
																								autoFocus
																								// onChange={(e) =>
																								// 	setBackgroundAdjustmentColorCodeUpdate(
																								// 		e.target.value
																								// 	)
																								// }
																								value={
																									background_adjustment_color_code
																								}
																							/>
																						</Col>
																					</Row>
																				) : (
																					""
																				)}
																				{updateChecked[index] === 3 ? (
																					<Row
																						className="align-items-center"
																						style={{
																							// marginLeft: "40px",
																							marginTop: "-2px",
																							marginBottom: "20px",
																							width: "100%",
																						}}
																					>
																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										File Format
																									</Form.Label>
																									<Col sm={6}>
																										<Select
																											options={file}
																											// onChange={(e) =>
																											// 	setResizingFileFormat(e.label)
																											// }
																											// defaultValue={{
																											// 	label: `${resizing_file_format}`,
																											// 	id: "21",
																											// }}
																											value={{
																												label: resizing_file_format, // Set the label property to the desired value
																												value: resizing_file_format, // Set the value property to the corresponding value
																											}}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										Size
																									</Form.Label>
																									<Col sm={9}>
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<div className="d-sm-flex col-gap-10 align-items-center">
																												<span className="text-secondary f-sz-13">
																													{" "}
																													H:{" "}
																												</span>
																												<Form.Control
																													type="text"
																													placeholder="2000"
																													autoFocus
																													onChange={(e) =>
																														setResizingHeight(
																															e.target.value
																														)
																													}
																													value={
																														resizing_height
																													}
																												/>
																											</div>
																											<div className="d-sm-flex align-items-center"></div>
																											<div className="d-sm-flex align-items-center col-gap-10">
																												<span className="text-secondary f-sz-13">
																													{" "}
																													W:{" "}
																												</span>
																												<Form.Control
																													type="text"
																													placeholder="2000"
																													autoFocus
																													onChange={(e) =>
																														setResizingWidth(
																															e.target.value
																														)
																													}
																													value={resizing_width}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										DPI
																									</Form.Label>
																									<Col sm={9}>
																										<div className="d-sm-flex col-gap-20">
																											<div className="">
																												<Form.Control
																													type="text"
																													placeholder="2000"
																													autoFocus
																													onChange={(e) =>
																														setResizingDpi(
																															e.target.value
																														)
																													}
																													value={resizing_dpi}
																												/>
																											</div>
																											<div className="flex-fill">
																												<Select
																													options={dpi}
																													onChange={(e) =>
																														setResizingDpiType(
																															e.label
																														)
																													}
																													// defaultValue={{
																													// 	label: `${resizing_dpi_type}`,
																													// 	id: "21",
																													// }}
																													value={{
																														label: resizing_dpi_type, // Set the label property to the desired value
																														value: resizing_dpi_type, // Set the value property to the corresponding value
																													}}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										Color Mode
																									</Form.Label>
																									<Col sm={9}>
																										<Select
																											options={ColorMode}
																											onChange={(e) =>
																												setResizingColorMode(
																													e.label
																												)
																											}
																											// defaultValue={{
																											// 	label: `${resizing_color_mode}`,
																											// 	id: "21",
																											// }}
																											value={{
																												label: resizing_color_mode, // Set the label property to the desired value
																												value: resizing_color_mode, // Set the value property to the corresponding value
																											}}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										Align
																									</Form.Label>
																									<Col sm={9}>
																										<Select
																											options={Align}
																											onChange={(e) =>
																												setResizingAlignment(
																													e.label
																												)
																											}
																											// defaultValue={{
																											// 	label: `${resizing_alignment}`,
																											// 	id: "21",
																											// }}
																											value={{
																												label: resizing_alignment, // Set the label property to the desired value
																												value: resizing_alignment, // Set the value property to the corresponding value
																											}}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label
																										className="col-sm-3"
																										style={{
																											marginTop: "-53px",
																										}}
																									>
																										Margins
																									</Form.Label>
																									<Col sm={9}>
																										<div className="d-sm-flex col-gap-20 flex-xxl-column">
																											<div
																												style={{
																													width: "100%",
																													marginBottom: "23px",
																												}}
																											>
																												<Select
																													options={Margin}
																													onChange={(e) =>
																														setResizingMarginFormat(
																															e.label
																														)
																													}
																													defaultValue={{
																														label: `${resizing_margin_format}`,
																														id: "21",
																													}}
																													value={{
																														label: resizing_margin_format, // Set the label property to the desired value
																														value: resizing_margin_format, // Set the value property to the corresponding value
																													}}
																												/>
																											</div>

																											<ul className="margin-list">
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="T"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginTop(
																																e.target.value
																															)
																														}
																														value={
																															resizing_margin_top
																														}
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="B"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginBottom(
																																e.target.value
																															)
																														}
																														value={
																															resizing_margin_bottom
																														}
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="L"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginLeft(
																																e.target.value
																															)
																														}
																														value={
																															resizing_margin_left
																														}
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="R"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginRight(
																																e.target.value
																															)
																														}
																														value={
																															resizing_margin_right
																														}
																													/>
																												</li>
																											</ul>
																										</div>
																									</Col>

																									<Col sm={6}></Col>
																								</Row>
																							</div>
																						</Form.Group>
																					</Row>
																				) : (
																					""
																				)}
																			</Form.Group>
																		</>
																	);
																}
															)}
														{/* Form groups for output specifications */}

														{view_edit_group !== null &&
															view_edit_group !== undefined &&
															view_edit_group.edit_group !== null &&
															view_edit_group.edit_group !== undefined &&
															view_edit_group.edit_group.resizing_settings !==
																null &&
															view_edit_group.edit_group.resizing_settings !==
																undefined &&
															view_edit_group.edit_group.resizing_settings.map(
																(items, index) => {
																	return (
																		<div>
																			<div className="sub-head">
																				Output Specifications
																			</div>
																			<Form.Group
																				style={{ margin: "2px" }}
																				controlId="exampleForm.ControlTextarea1"
																			>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Form.Label className="col-sm-3">
																							File Format
																						</Form.Label>
																						<Col sm={6}>
																							<Form.Label className="col-sm-3">
																								{items.file_format}
																							</Form.Label>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>

																			<Form.Group
																				style={{ margin: "2px" }}
																				controlId="exampleForm.ControlTextarea1"
																			>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Form.Label className="col-sm-3">
																							Size
																						</Form.Label>
																						<Col sm={9}>
																							<div className="d-sm-flex align-items-center col-gap-10">
																								<div className="d-sm-flex col-gap-10 align-items-center">
																									<span className="text-secondary f-sz-13">
																										{" "}
																										H:{" "}
																									</span>
																									<span className="text-secondary f-sz-13">
																										{items.height}{" "}
																									</span>
																								</div>
																								<div className="d-sm-flex align-items-center"></div>
																								<div className="d-sm-flex align-items-center col-gap-10">
																									<span className="text-secondary f-sz-13">
																										{" "}
																										W:{" "}
																									</span>
																									<span className="text-secondary f-sz-13">
																										{items.width}
																									</span>
																								</div>
																							</div>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>

																			<Form.Group
																				style={{ margin: "2px" }}
																				controlId="exampleForm.ControlTextarea1"
																			>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Form.Label className="col-sm-3">
																							DPI
																						</Form.Label>
																						<Col sm={9}>
																							<div className="d-sm-flex col-gap-20">
																								<div className="">
																									<Form.Label className="col-sm-3">
																										{items.dpi}
																									</Form.Label>
																								</div>
																								<div className="flex-fill">
																									<Form.Label className="col-sm-3">
																										{items.dpi_type}
																									</Form.Label>{" "}
																								</div>
																							</div>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>

																			<Form.Group
																				style={{ margin: "2px" }}
																				controlId="exampleForm.ControlTextarea1"
																			>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Form.Label className="col-sm-3">
																							Color Mode
																						</Form.Label>
																						<Col sm={9}>
																							<Form.Label className="col-sm-3">
																								{items.color_mode}
																							</Form.Label>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>

																			<Form.Group
																				style={{ margin: "2px" }}
																				controlId="exampleForm.ControlTextarea1"
																			>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Form.Label className="col-sm-3">
																							Align
																						</Form.Label>
																						<Col sm={9}>
																							<Form.Label className="col-sm-3">
																								{items.alignment}
																							</Form.Label>
																						</Col>
																					</Row>
																				</div>
																			</Form.Group>

																			<Form.Group
																				style={{ margin: "2px" }}
																				controlId="exampleForm.ControlTextarea1"
																			>
																				<div className="form-group">
																					<Row className="align-items-center">
																						<Form.Label className="col-sm-3">
																							Margins
																						</Form.Label>
																						<Col sm={9}>
																							<div className="d-sm-flex col-gap-20">
																								<div className="image-details-margin--type ">
																									<span className="text-secondary f-sz-13">
																										{items.margin_format}
																									</span>
																								</div>
																								<div className="">
																									<ul className="margin-list">
																										<li>
																											<Form.Label className="col-sm-3">
																												{items.margin_top}
																											</Form.Label>
																										</li>
																										<li>
																											<Form.Label className="col-sm-3">
																												{items.margin_bottom}
																											</Form.Label>
																										</li>
																										<li>
																											<Form.Label className="col-sm-3">
																												{items.margin_right}
																											</Form.Label>
																										</li>
																										<li>
																											<Form.Label className="col-sm-3">
																												{items.margin_left}
																											</Form.Label>
																										</li>
																									</ul>
																								</div>
																							</div>
																						</Col>

																						<Col sm={6}></Col>
																					</Row>
																				</div>
																			</Form.Group>
																		</div>
																	);
																}
															)}
														{/* <div className="global-gap--btn sidebar-single--btn">
															<Button
																variant="primary"
																className="btns"
																onClick={() => handleUpdateImageEditGroup()}
															>
																{loader ? (
																	<div>
																		<Loader />
																		Loading
																	</div>
																) : (
																	"Save"
																)}
															</Button>
														</div> */}
														<Col sm={6}></Col>
													</div>
												)}
											</div>
										</div>
									</div>
								</Tab>
								{duplicate_amount !== undefined &&
									viewDuplicateSettings.map((data, index) => {
										const tabKey = index + 1;
										// setActiveTab(tabKey);
										return (
											<Tab
												eventKey={index + 1}
												title={`Duplicate${index +
													1} ( ${data.image_name.substring(
													0,
													data.image_name.lastIndexOf(".")
												)}) `}
											>
												<div style={{ width: "35rem", marginLeft: "10px" }}>
													<div className="sub-head">
														Duplicate{index + 1} &nbsp;(&nbsp;
														{data.image_name.substring(
															0,
															data.image_name.lastIndexOf(".")
														)}
														)
													</div>

													<Form.Group
														style={{ margin: "2px" }}
														controlId="exampleForm.ControlTextarea1"
													>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-3">
																	File Format
																</Form.Label>
																<Col sm={6}>
																	<Select
																		options={file}
																		placeholder="Select file format"
																		value={{
																			label: data.file_format, // Use the current value from data
																			value: index,
																		}}
																		onChange={(e) => {
																			const error = validateField(
																				"File Format",
																				e.label
																			);
																			setErrors((prevErrors) => ({
																				...prevErrors,
																				[`file_format_${index}`]: error,
																			}));

																			if (!error) {
																				data.file_format = e.label;
																				setViewDuplicateSettings([
																					...viewDuplicateSettings,
																				]);
																			}
																		}}
																	/>
																</Col>
															</Row>
														</div>
													</Form.Group>

													<Form.Group
														style={{ margin: "2px" }}
														controlId="exampleForm.ControlTextarea1"
													>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-3">
																	Size
																</Form.Label>
																<Col sm={9}>
																	<div className="d-sm-flex align-items-center col-gap-10">
																		<div className="d-sm-flex col-gap-10 align-items-center">
																			<span className="text-secondary f-sz-13">
																				{" "}
																				H:{" "}
																			</span>
																			<div className="flex-col">
																				<Form.Control
																					type="text"
																					placeholder=""
																					autoFocus
																					onChange={(e) => {
																						data.height = e.target.value;
																						setViewDuplicateSettings([
																							...viewDuplicateSettings,
																						]);
																					}}
																					autoComplete="off"
																					defaultValue={data.height}
																				/>
																				{/* {errors.height && (
																<div className="error">{errors.height}</div>
															)} */}
																			</div>
																		</div>
																		<div className="d-sm-flex align-items-center"></div>
																		<div className="d-sm-flex align-items-center col-gap-10">
																			<span className="text-secondary f-sz-13">
																				{" "}
																				W:{" "}
																			</span>
																			<div className="flex-col">
																				<Form.Control
																					type="text"
																					placeholder="2000"
																					autoFocus
																					onChange={(e) => {
																						data.width = e.target.value;
																						setViewDuplicateSettings([
																							...viewDuplicateSettings,
																						]);
																					}}
																					autoComplete="off"
																					defaultValue={data.width}
																				/>
																				{/* {errors.width && (
																<div className="error">{errors.width}</div>
															)} */}
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>
														</div>
													</Form.Group>

													<Form.Group
														style={{ margin: "2px" }}
														controlId="exampleForm.ControlTextarea1"
													>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-3">
																	DPI
																</Form.Label>
																<Col sm={9}>
																	<div className="d-sm-flex col-gap-20">
																		<div className="">
																			<Form.Control
																				type="text"
																				placeholder="2000"
																				autoFocus
																				onChange={(e) => {
																					data.dpi = e.target.value;
																					setViewDuplicateSettings([
																						...viewDuplicateSettings,
																					]);
																				}}
																				autoComplete="off"
																				defaultValue={data.dpi}
																			/>
																			{/* {errors.dpi && (
															<div className="error">{errors.dpi}</div>
														)} */}
																		</div>
																		<div className="flex-fill">
																			<Select
																				options={dpi}
																				placeholder="Select Image Dpi Type"
																				onChange={(e) => {
																					data.dpi_type = e.label;
																					setViewDuplicateSettings([
																						...viewDuplicateSettings,
																					]);
																				}}
																				defaultValue={[
																					{
																						label: `${data.dpi_type}`,
																						value: index,
																					},
																				]}
																			/>
																			{/* {errors.dpi_type && (
															<div className="error">{errors.dpi_type}</div>
														)} */}
																		</div>
																	</div>
																</Col>
															</Row>
														</div>
													</Form.Group>

													<Form.Group
														style={{ margin: "2px" }}
														controlId="exampleForm.ControlTextarea1"
													>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-3">
																	Color Mode
																</Form.Label>
																<Col sm={9}>
																	<Select
																		options={ColorMode}
																		placeholder="Select Image Color Mode"
																		onChange={(e) => {
																			data.color_mode = e.label;
																			setViewDuplicateSettings([
																				...viewDuplicateSettings,
																			]);
																		}}
																		defaultValue={[
																			{
																				label: `${data.color_mode}`,
																				value: index,
																			},
																		]}
																	/>
																	{/* {errors.color_mode_Value && (
													<div className="error">{errors.color_mode_Value}</div>
												)} */}
																</Col>
															</Row>
														</div>
													</Form.Group>

													<Form.Group
														style={{ margin: "2px" }}
														controlId="exampleForm.ControlTextarea1"
													>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-3">
																	Align
																</Form.Label>
																<Col sm={9}>
																	<Select
																		options={Align}
																		placeholder="Align"
																		onChange={(e) => {
																			data.alignment = e.label;
																			setViewDuplicateSettings([
																				...viewDuplicateSettings,
																			]);
																		}}
																		defaultValue={[
																			{
																				label: `${data.alignment}`,
																				value: index,
																			},
																		]}
																	/>
																	{/* {errors.alignment_Value && (
													<div className="error">{errors.alignment_Value}</div>
												)} */}
																</Col>
															</Row>
														</div>
													</Form.Group>

													<Form.Group
														style={{ margin: "2px" }}
														controlId="exampleForm.ControlTextarea1"
													>
														<div className="form-group">
															<Row className="align-items-center">
																<Form.Label className="col-sm-3">
																	Margins
																</Form.Label>
																<Col sm={9}>
																	<div className="d-sm-flex col-gap-20 flex-col">
																		<div className="">
																			<Select
																				options={Margin}
																				placeholder="Margin"
																				onChange={(e) => {
																					data.margin_format = e.label;
																					setViewDuplicateSettings([
																						...viewDuplicateSettings,
																					]);
																				}}
																				defaultValue={[
																					{
																						label: `${data.margin_format}`,
																						value: index,
																					},
																				]}
																			/>
																			{/* {errors.margin_format && (
															<div className="error">
																{errors.margin_format}
															</div>
														)} */}
																		</div>
																		<div className="">
																			<ul className="margin-list">
																				<li>
																					<Form.Control
																						type="text"
																						placeholder="T"
																						autoFocus
																						onChange={(e) => {
																							data.margin_top = e.target.value;
																							setViewDuplicateSettings([
																								...viewDuplicateSettings,
																							]);
																						}}
																						autoComplete="off"
																						defaultValue={data.margin_top}
																					/>
																				</li>
																				<li>
																					<Form.Control
																						type="text"
																						placeholder="B"
																						autoFocus
																						onChange={(e) => {
																							data.margin_bottom =
																								e.target.value;
																							setViewDuplicateSettings([
																								...viewDuplicateSettings,
																							]);
																						}}
																						autoComplete="off"
																						defaultValue={data.margin_bottom}
																					/>
																				</li>
																				<li>
																					<Form.Control
																						type="text"
																						placeholder="R"
																						autoFocus
																						onChange={(e) => {
																							data.margin_right =
																								e.target.value;
																							setViewDuplicateSettings([
																								...viewDuplicateSettings,
																							]);
																						}}
																						autoComplete="off"
																						defaultValue={data.margin_right}
																					/>
																				</li>
																				<li>
																					<Form.Control
																						type="text"
																						placeholder="L"
																						autoFocus
																						onChange={(e) => {
																							data.margin_left = e.target.value;
																							setViewDuplicateSettings([
																								...viewDuplicateSettings,
																							]);
																						}}
																						autoComplete="off"
																						defaultValue={data.margin_left}
																					/>
																				</li>
																			</ul>
																			{/* {errors.margin && (
															<div className="error">{errors.margin}</div>
														)} */}
																		</div>
																	</div>
																</Col>

																<Col sm={6}></Col>
															</Row>
														</div>
													</Form.Group>
												</div>
											</Tab>
										);
									})}
							</Tabs>
						</div>
					</div>
				</Modal.Body>

				{Number(key) !== 0 && (
					<Modal.Footer style={{ display: "flex", justifyContent: "end" }}>
						<Button variant="" className="puple btns mr-2" onClick={onHide}>
							Cancel
						</Button>
						<Button
							variant="primary"
							className="btns"
							onClick={() => handleFormSubmit()}
						>
							Save
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
};

export default DuplicateSettings;
