import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import '../../../../css/style.css';
// import '../../../../css/responsive.css'
// import '../../../../css/pipelines.css'
import Card from 'react-bootstrap/Card';
import pending from '../../../assets/Images/pending.svg'
import coordinating from '../../../assets/Images/coordinating.svg'
import editing from '../../../assets/Images/editing.svg'
import quality from '../../../assets/Images/quality.svg'
import revision from '../../../assets/Images/revision.svg'
import total from '../../../assets/Images/total.svg'
import bgtotal from '../../../assets/Images/bg-total.svg'

const Pipelines = () => {
  return (
    <>
      <div className="section-title">
        <h2>Dashboard</h2>
        <h4>Current Production Pipeline</h4>
      </div>

      <div className="mb-4">

        <div className="d-sm-flex pipeline">

          <Card className="bg-card">
            <Card.Img src={bgtotal} alt="Card image" />

            <Card.ImgOverlay>

              <Card.Img variant="top" src={total} />
              <Card.Body>
                <Card.Title>Total in Pipeline</Card.Title>
                <Card.Text>
                  10,999
                </Card.Text>
              </Card.Body>
            </Card.ImgOverlay>
          </Card>

          <Card>
            <Card.Img variant="top" src={pending} />
            <Card.Body>
              <Card.Title>Pending</Card.Title>
              <Card.Text>
                6,000
              </Card.Text>

            </Card.Body>
          </Card>

          <Card>
            <Card.Img variant="top" src={coordinating} />
            <Card.Body>
              <Card.Title>Coordinating</Card.Title>
              <Card.Text>
                1,197
              </Card.Text>

            </Card.Body>
          </Card>

          <Card>
            <Card.Img variant="top" src={editing} />
            <Card.Body>
              <Card.Title>Editing</Card.Title>
              <Card.Text>
                2,313
              </Card.Text>

            </Card.Body>
          </Card>

          <Card>
            <Card.Img variant="top" src={quality} />
            <Card.Body>
              <Card.Title>Quality Control</Card.Title>
              <Card.Text>
                1,291
              </Card.Text>

            </Card.Body>
          </Card>

          <Card>
            <Card.Img variant="top" src={revision} />
            <Card.Body>
              <Card.Title>Revision</Card.Title>
              <Card.Text>
                198
              </Card.Text>

            </Card.Body>
          </Card>
        </div>














      </div>

    </>
  )
}

export default Pipelines