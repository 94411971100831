import React, { useRef, useEffect, useState } from "react";

export function useOutsideAlerter(
  ref,
  setMultipleImagesId,
  setFolderNameFirst,
  setFolderListName,
  setFolderSelect,
  setFoldersPath,
  setClickSelect,
  images,
  folder_list,
  // setReassignedModal,
  setSelectAll,
  setMergedImageModal,
  setDuplicateImageModal,
  setDuplicateAmount,
  setAmount,
  setDragAndSelectEnabled,
  setSelectedIndexes
) {
  // const [isExcluded, setIsExcluded] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      // if (ref.current && !ref.current.contains(event.target) && images.length) {
      if (ref.current || ref) {
        // Exclude specific div elements from triggering the action
        const excludedDivs = document.querySelectorAll(".exclude-click");
        let isExcluded = false;

        excludedDivs.forEach((div) => {
          if (div.contains(event.target)) {
            // setIsExcluded(true);
            isExcluded = true;
          }
        });
        if (!isExcluded) {
          setMultipleImagesId([]);
          // setFolderNameFirst([]);
          setFolderListName([]);
          setFolderSelect([]);
          setFoldersPath([]);
          setClickSelect(false);
          setMergedImageModal(false);
          setDuplicateImageModal(false);
          setDuplicateAmount([]);
          setAmount(0);
          // setReassignedModal(false);
          setSelectAll(false);
          setSelectedIndexes([]);
          setDragAndSelectEnabled(true);
          // localStorage.setItem("clickSelect", false);
        }
      } else {
        setMultipleImagesId([]);
        // setFolderNameFirst([]);
        setFolderListName([]);
        setFolderSelect([]);
        setFoldersPath([]);
        setClickSelect(false);
        setMergedImageModal(false);
        setDuplicateImageModal(false);
        setDuplicateAmount([]);
        setAmount(0);
        // setReassignedModal(false);
        setSelectAll(false);
        setSelectedIndexes([]);
        setDragAndSelectEnabled(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    ref,
    images,
    folder_list,
    setMultipleImagesId,
    setFoldersPath,
    setFolderListName,
    setClickSelect,
    setSelectAll,
    setDuplicateAmount,
    setAmount,
  ]);
}
