import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Invoices from "./Invoices";
import Orders from "./Orders";
import BatchHistory from "./BatchHistory";
import Performance from "./Performance";
import Customeroverview from "./Customeroverview";
import Customersettings from "./Staffsettings";
import CustomerChat from "./StaffChat";
import menudot from "../../assets/Images/menu-dot.svg";

const CustomerDetails = () => {
	const { id } = useParams();
	const [company_name, setCompanyNameDetails] = useState("");
	return (
		<>
			<div className="rightbar pr-11 overviewstab customrightbar">
				<div className="section-title">
					<h2>Staff Details - {company_name}</h2>
				</div>
				<div className="detailstab">
					<Tabs
						defaultActiveKey="Overview"
						id="justify-tab-example"
						className="stafftab"
						justify
					>
						<Tab eventKey="Overview" title="Overview">
							<Customeroverview
								id={id}
								setCompanyNameDetails={setCompanyNameDetails}
							/>
						</Tab>
						<Tab eventKey="BatchHistory" title="Batch History">
							<BatchHistory id={id} />
						</Tab>
						<Tab eventKey="Performance" title="Performance">
							<Performance />
						</Tab>
					</Tabs>
					<div className="doticon">
						<img src={menudot} className="img-fluid" alt="mindreach images" />
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomerDetails;
