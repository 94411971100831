import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

/**
 * Confirmation modal for deleting an item.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.title - The title of the modal.
 * @param {string} props.message - The message of the modal.
 * @param {function} props.handleConfirm - Callback function to handle upload confirmation.
 * @returns {JSX.Element} - The rendered upload confirmation modal component.
 */
const ConfirmBulkUploadModal = (props) => {
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal")) {
      // Check if the click occurred on an element with the "modal" class
      props.onHide();
    }
  };
  useEffect(() => {
    // Add event listener to detect clicks outside the modal
    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="my-modal exclude-click"
        style={{ zIndex: 9999999999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="section-title fonts"
          >
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={`section-title fonts  mt-3${
            props.uploaderrorMessage && props.uploaderrorMessage.length > 0
              ? " bulk_upload_error"
              : ""
          }`}
          style={{ width: "30vw" }}
        >
          {props.uploaderrorMessage &&
            props.uploaderrorMessage.map((error) => {
              return <p className="err">{`${error}.`}</p>;
            })}
          {props.message && <p className="error mt-2">{props.message}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="puple btns mr-2" onClick={props.onHide}>
            Cancel
          </Button>
          {props.uploaderrorMessage && props.uploaderrorMessage.length > 0 && (
            <Button
              variant="primary"
              className="btns"
              onClick={props.handleConfirm}
            >
              Continue
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmBulkUploadModal;
