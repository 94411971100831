import React from 'react'
import RightSidebar from './Components/RightSidebar';
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import ConfirmBox from './Components/ConfirmBox';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrderDetailscontent from './OrderDetailscontent';






const OrderDetails = () => {
    const selectactions = [
        { label: "Actions", value: 355 },
        { label: "Actions", value: 54 }
    ];


    const selectdays = [
        { label: "Today", value: 355 },
        { label: "Custom", value: 54 }
    ];



    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel ">
                    <div className="rightbar">
                        <div className=" pr-11">
                            <div className="d-sm-flex heads flex-sm-wrap align-items-baseline col-gap-20">
                                <div className="section-title">
                                    <h2>Order3 0008345</h2>
                                    <p className="date">Order Date:  22 Aug 2022</p>
                                </div>
                                <div className="model-title">

                                    Model - Swimsuits
                                </div>
                            </div>
                            <Row className="align-items-center">
                                <Col lg={2} sm={12} className="custom-status custom-rightcol">
                                    <div className="">
                                        <p>Status: <span className="text-warning">In-Progress</span></p>
                                    </div>
                                </Col>
                                <Col lg={10} sm={12} className="custom-leftcol">
                                    <div className="d-sm-flex mt-1 flex-sm-wrap align-items-start justify-content-between mb-3 col-gap-20">
                                        <div className="">
                                            <p>Total Images: <b className="black">3,901</b></p>
                                        </div>


                                        <div className="">
                                            <p>In Progress: <b className="black">3,901</b></p>
                                        </div>
                                        <div className="">
                                            <p>Review Ready: <b className="black">1,002</b></p>
                                        </div>
                                        <div className="">
                                            <p>Revision Request: <b className="black">102</b></p>
                                        </div>
                                        <div className="">
                                            <p>Image Approved: <b className="black"> 2,549</b></p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>


                        {/* <div className="d-sm-flex mt-1 flex-sm-wrap align-items-start justify-content-between mb-3 col-gap-20">
                            <div className="">
                                <p>Status: <span className="text-warning">In-Progress</span></p>
                            </div>
                            <div className="">
                                <p>Total Images: <b className="black">3,901</b></p>
                            </div>


                            <div className="">
                                <p>In Progress: <b className="black">3,901</b></p>
                            </div>
                            <div className="">
                                <p>Review Ready: <b className="black">1,002</b></p>
                            </div>
                            <div className="">
                                <p>Revision Request: <b className="black">102</b></p>
                            </div>
                            <div className="">
                                <p>Image Approved: <b className="black"> 2,549</b></p>
                            </div>
                        </div> */}

                        <div className="workbody">
                            <Form>
                                <Row className="align-items-center">

                                    <Col lg={2} sm={12} className="custom-status custom-rightcol">
                                        <div className="d-sm-flex flex-sm-wrap col-gap-14 align-items-center">

                                            <div className="flex-grow-1 ">
                                                <div className="form-group">
                                                    <Select options={selectactions}
                                                        defaultValue={{ label: "InProgress", value: 355 }} />
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <div className="d-sm-flex flex-sm-wrap col-gap-14 align-items-center">

                                            <div className="flex-grow-1 ">
                                                <div className="form-group">
                                                    <Form.Control
                                                        type="search"
                                                        placeholder="Search Order / Image"
                                                        className="me-2"
                                                        aria-label="Search"
                                                    /> </div>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={6} sm={12}>
                                        <Form.Group className="ml-4" controlId="formBasicCheckbox">
                                            <div className="d-sm-flex col-gap-20 align-items-center">
                                                <div className="form-group">
                                                    <Form.Check type="checkbox" label="Select" />
                                                </div>
                                                <div className="">
                                                    <div className="form-group">
                                                        <Select options={selectactions}
                                                            defaultValue={{ label: "Actions", value: 355 }} />
                                                    </div>
                                                    {/* <Form.Select>
                                                        <option>Actions</option>
                                                        <option>Actions</option>
                                                    </Form.Select> */}

                                                </div>
                                                <div className="form-group">

                                                    <Button variant="primary" className="btns">Apply</Button>{' '}
                                                </div>
                                            </div>


                                        </Form.Group>
                                    </Col>
                                    {/* <Col sm={2}>
                                        <Form.Select>
                                            <option>Actions</option>
                                            <option>Actions</option>
                                        </Form.Select>
                                    </Col> */}
                                    <Col sm={2}>

                                    </Col>
                                </Row>
                                <Row className="align-items-center orderdetails-alignment">
                                    <Col lg={2} sm={12} className="custom-rightcol">
                                        <div className="rightsidebar">
                                            <RightSidebar />
                                        </div>

                                    </Col>
                                    <Col lg={10} sm={12} className="custom-leftcol">
                                        <OrderDetailscontent
                                        />

                                    </Col>

                                </Row>
                            </Form>

                            {/* <div className="d-sm-flex">
                                <div className="rightsidebar">
                                    <RightSidebar />
                                </div>
                                <div className="midpart">
                                    <OrderDetailscontent
                                    />
                                </div>
                            </div> */}



                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetails