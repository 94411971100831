import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
// import '../../../css/style.css'
// import '../../../css/responsive.css'
// import '../../../css/customers.css'
// import '../../../css/dashboardtable.css';
// import '../../../css/staff.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import user from '../../../assets/Images/users.svg';
import Select from 'react-select';
import NewUser from '../Staff/NewUser';
import Editt from '../../../assets/Images/editt.svg';
import Delete from '../../../assets/Images/delete.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';





const User = () => {

  const choosestaff = [
    { label: "All Shifts", value: 355 },
    { label: "Day Shifts", value: 54 }

  ];

  const products = [{
    id: '1',
    name: "Reebok International",
    Email: 'kbryant@mindreach.com',
    ActiveDate: '22 Aug 2022',
    Role: 'Editor',
    Shift: 'Plus',
    Status: <p style={{ color: '#125ECB' }}>Active</p>,
    '': <>   <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip className="in" id="tooltip-top" {...props}>
          Edit
        </Tooltip>
      )}
      placement="bottom"
    ><img src={Editt} className="img-fluid ftp-table-img hover-ftp  hover-blue" alt="Delete" />
    </OverlayTrigger><img src={Delete} className="img-fluid ftp-table-img hover-ftp" alt="Delete" /></>

  }, {
    id: 2,
    name: 'Nick, Inc.',
    Email: 'kbryant@mindreach.com',
    ActiveDate: '22 Aug 2022',
    Role: 'Coordinator',
    Shift: 'Standrad',
    '': <>   <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip className="in" id="tooltip-top" {...props}>
          Edit
        </Tooltip>
      )}
      placement="bottom"
    ><img src={Editt} className="img-fluid ftp-table-img hover-ftp  hover-blue" alt="Delete" />
    </OverlayTrigger><img src={Delete} className="img-fluid ftp-table-img hover-ftp" alt="Delete" /></>,
    Status: <p style={{ color: '#125ECB' }}>Active</p>
  },
  {
    id: 3,
    name: "Adidas",
    Email: 'kbryant@mindreach.com',
    ActiveDate: '22 Aug 2022',
    Role: 'Editor',
    Shift: 'Plus',
    '': <>   <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip className="in" id="tooltip-top" {...props}>
          Edit
        </Tooltip>
      )}
      placement="bottom"
    ><img src={Editt} className="img-fluid ftp-table-img hover-ftp  hover-blue" alt="Delete" />
    </OverlayTrigger><img src={Delete} className="img-fluid ftp-table-img hover-ftp" alt="Delete" /></>,
    Status: <p style={{ color: '#125ECB' }}>Active</p>
  },
  {
    id: 4,
    name: "Coca Cola",
    Email: 'kbryant@mindreach.com',
    ActiveDate: '22 Aug 2022',
    Role: 'Coordinator',
    Shift: 'Standrad',
    '': <>   <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip className="in" id="tooltip-top" {...props}>
          Edit
        </Tooltip>
      )}
      placement="bottom"
    ><img src={Editt} className="img-fluid ftp-table-img hover-ftp  hover-blue" alt="Delete" />
    </OverlayTrigger><img src={Delete} className="img-fluid ftp-table-img hover-ftp" alt="Delete" /></>,
    Status: <p style={{ color: '#125ECB' }}>Active</p>
  },
  {
    id: 5,
    name: "Coca Cola",
    Email: 'kbryant@mindreach.com',
    ActiveDate: '22 Aug 2022',
    Role: 'Editor',
    Shift: 'Plus',
    '': <>   <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip className="in" id="tooltip-top" {...props}>
          Edit
        </Tooltip>
      )}
      placement="bottom"
    ><img src={Editt} className="img-fluid ftp-table-img hover-ftp  hover-blue" alt="Delete" />
    </OverlayTrigger><img src={Delete} className="img-fluid ftp-table-img hover-ftp" alt="Delete" /></>,
    Status: <p style={{ color: '#125ECB' }}>Active</p>
  },
  {
    id: 6,
    name: "Adidas",
    Email: 'kbryant@mindreach.com',
    ActiveDate: '22 Aug 2022',
    Role: 'Coordinator',
    Shift: 'Standrad',
    '': <>   <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip className="in" id="tooltip-top" {...props}>
          Edit
        </Tooltip>
      )}
      placement="bottom"
    ><img src={Editt} className="img-fluid ftp-table-img hover-ftp  hover-blue" alt="Delete" />
    </OverlayTrigger><img src={Delete} className="img-fluid ftp-table-img hover-ftp" alt="Delete" /></>,
    Status: <p style={{ color: '#125ECB' }}>Active</p>
  },
  {
    id: 7,
    name: "",
    Email: 'kbryant@mindreach.com',
    ActiveDate: '22 Aug 2022',
    Role: 'Coordinator',
    Shift: 'Standrad',
    '': <>   <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip className="in" id="tooltip-top" {...props}>
          Edit
        </Tooltip>
      )}
      placement="bottom"
    ><img src={Editt} className="img-fluid ftp-table-img hover-ftp  hover-blue" alt="Delete" />
    </OverlayTrigger><img src={Delete} className="img-fluid ftp-table-img hover-ftp" alt="Delete" /></>,
    Status: <p style={{ color: '#125ECB' }}>Active</p>
  }
  ]




  const columns = [{
    dataField: 'name',
    text: 'Name',
    number: '1',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }

  }, {
    dataField: 'Email',
    text: 'Email',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'ActiveDate',
    text: 'Active Date',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Role',
    text: 'Role',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Shift',
    text: 'Shift',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }
  },
  {
    dataField: 'Status',
    text: 'Status',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }

  },
  {
    dataField: 'Edit',
    text: '',
    headerStyle: (colum, colIndex) => {
      return { width: '145px', textAlign: 'left' };
    }

  },
  {
    dataField: '',
    text: '',
    headerStyle: (colum, colIndex) => {
      return { width: '75px', textAlign: 'left', padding: '0 !important' };
    }
  }


  ];

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];


  // <BootstrapTable
  //   keyField="id"
  //   data={products}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />
  return (
    <>
      <div className="d-sm-flex form-group">
        <div className="customer-search">
          <Form>
            <Row className="align-items-center">
              <Col xs={2} md={3} lg={2}>
                <Select options={choosestaff} defaultValue={{ label: "All Shifts", value: 355 }} />
              </Col>
              <Col>
                <Form.Control
                  type="search"
                  placeholder="Search Staff"
                  className="me-2 search-icon"
                  aria-label="Search"
                />
              </Col>
              <Col>
                <p className="customer-count"><span><img src={user} className="img-fluid" alt="mindreach images" /></span>20 User(s)</p>
              </Col>
              <Col className='text-right custom-btn'>
                <NewUser />
              </Col>
            </Row>
          </Form>
        </div>


      </div>
      <div className="table-responsive table-b supervisor-staff">
        <BootstrapTable keyField='id' data={products} columns={columns} />
      </div>

      <Row className="mt-4">
        <Col md={{ span: 4, offset: 8 }}>
          {
            <Pagination>
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Item>{5}</Pagination.Item>
              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
          }
        </Col>
      </Row>
    </>
  )
}

export default User