import axios from "axios";

/**
 * Create an Axios client with default options.
 * @param {string} token - The authorization token.
 * @returns {object} - An object with methods for making HTTP requests.
 */
export const client = (token) => {
	/**
	 * Default options for Axios requests.
	 * @type {object}
	 * @property {object} headers - Default request headers.
	 * @property {string} headers.Authorization - Authorization header value with the token.
	 * @property {string} headers.Content-Type - Default content type header value.
	 */
	const defaultOptions = {
		headers: {
			Authorization: token
				? "Bearer " + `${token.slice(1, token.length - 1)}`
				: "",
			Accept: "application/json",
			"Content-Type": "multipart/form-data;",
		},
	};

	return {
		/**
		 * Send a GET request.
		 * @param {string} url - The URL for the GET request.
		 * @param {object} options - Additional request options.
		 * @returns {Promise} - A Promise that resolves to the response data.
		 */
		get: (url, options = {}) =>
			axios.get(url, { ...defaultOptions, ...options }),

		/**
		 * Send a POST request.
		 * @param {string} url - The URL for the POST request.
		 * @param {object} data - The data to send with the request.
		 * @param {object} options - Additional request options.
		 * @returns {Promise} - A Promise that resolves to the response data.
		 */
		post: (url, data, options = {}) =>
			axios.post(url, data, { ...defaultOptions, ...options }),

		/**
		 * Send a PUT request.
		 * @param {string} url - The URL for the PUT request.
		 * @param {object} data - The data to send with the request.
		 * @param {object} options - Additional request options.
		 * @returns {Promise} - A Promise that resolves to the response data.
		 */
		put: (url, data, options = {}) =>
			axios.put(url, data, { ...defaultOptions, ...options }),

		/**
		 * Send a DELETE request.
		 * @param {string} url - The URL for the DELETE request.
		 * @param {object} options - Additional request options.
		 * @returns {Promise} - A Promise that resolves to the response data.
		 */
		delete: (url, options = {}) =>
			axios.delete(url, { ...defaultOptions, ...options }),
	};
};

// client need to declared a vaiable in the component

// Vaiable_Name.get(PAGES_URL);
