import React, { useEffect, useState } from "react";

//Importing the bootstrap components
import { Button, Row, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";

//Importing the react select components
import Select from "react-select";

//Importing the images
import CanceIcon from "./../../../../../assets/Images/actionsIcons/cancel_Final.svg";

// Importing API related files and routes
import { client } from "./../../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../../../data/filepath";

import { CO_ORDINATOR_MERGED_IMAGES } from "../../../../../config/apiRoutes.api";

const MergedEditType = (props) => {
	let {
		order_id,
		setDuplicateImageModal,
		setMultipleImagesId,
		setClickSelect,
		token,
		idSelected,
		setMergedImageModal,
		images,
		multiple_images_id,
		setLoader,
        customer_id,
	} = props;

	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);
	//Declaration of state
	const [status, setStatus] = useState("");
	const [error, setError] = useState("");
	const [errors, setErrors] = useState({});
	const [err, displayErr] = useState(false);

	const [original_image_id, setOriginalImageName] = useState("");
	const [merged_images_id, setMergedImages] = useState([]);
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
	const [divCount, setDivCount] = useState(2); // Initialize with 2 divs
	const [selectedImages, setSelectedImages] = useState([]); // To store selected image IDs

	const handleAddMore = () => {
		setDivCount((prevCount) => prevCount + 1);
	};

	const filteredData =
		images !== undefined &&
		images.filter((item) => {
			const shouldInclude =
				multiple_images_id !== undefined &&
				multiple_images_id.includes(item.id);
			if (!shouldInclude) {
				return images;
			}
			return shouldInclude;
		});
	// const filteredData = images;

	const selectLabel = (data) =>
		data.map(({ image_path: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		}));
	// const imagesOptionsAfterParentSelect = (parentObject, original_image_id) => {
	// 	return parentObject.filter((item) => item.id !== original_image_id);
	// };

	const handleCheckboxChange = (index) => {
		displayErr(false);
		if (!selectedImages.includes(index)) {
			setSelectedImages([...selectedImages, index]);
		} else {
			setSelectedImages(selectedImages.filter((item) => item !== index));
		}
	};

	const handleSelectChange = (e, index) => {
		displayErr(true);
		const selectedId = e.value;
		if (!merged_images_id.includes(selectedId)) {
			setMergedImages((previousIds) => [...previousIds, selectedId.toString()]);
		}
	};

	const generateDivs = () => {
		const divs = [];

		for (let i = 0; i < divCount; i++) {
			divs.push(
				<div className="merged-image-text--container mt-2 d-flex" key={i}>
					<Col sm={8}>
						<Select
							options={selectLabel(filteredData)}
							onChange={(e) => handleSelectChange(e, i)}
						/>
					</Col>
					<input
						type="checkbox"
						className="mr-20px"
						onChange={() => handleCheckboxChange(i)}
						checked={selectedImages.includes(i)}
					/>
				</div>
			);
		}

		return divs;
	};

	const formatAndAppendValue = (formData, property, value) => {
		if (value !== undefined) {
			const formattedValue = value
				.slice(0, value.length)
				.toString()
				.split(/(?=(?:\d{3})+(?:\.|$))/g)
				.join(",")
				.replace(/"/g, "");
			formData.append(property, formattedValue);
		}
	};
	const handleSubmitMergedImage = () => {
		if (token && merged_images_id[selectedImages] !== undefined) {
			// Continue with API request
			bodyFormData.append(
				"original_image_id",
				merged_images_id[selectedImages]
			);

			// Format and append values to bodyFormData
			formatAndAppendValue(bodyFormData, "image_ids", merged_images_id);
			formatAndAppendValue(bodyFormData, "customer_id", customer_id);

			request
				.post(apiURL + CO_ORDINATOR_MERGED_IMAGES, bodyFormData)
				.then((response) => {
					// Set the success from the response
					setStatus("Successfully Group updated");
					console.log("response", response);
					setLoader(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleClear = () => {
		setMultipleImagesId([]);
		// setDuplicateImageModal(false);
		setClickSelect(false);
		setMergedImageModal(false);
	};
	// Calculate conditions for disabling the "Add" button
	const isAddButtonDisabled = divCount === images.length;
	console.log("merged id", merged_images_id[selectedImages]);

	return (
		<div className="sidebar-image--duplicate d-flex">
			<div style={{ width: "100%", marginLeft: "10px", marginRight: "10px" }}>
				<div
					className="d-flex"
					style={{
						// flexDirection: "row-reverse",
						marginBottom: "18px",
						marginTop: "16px",
					}}
				>
					<div
						style={{ display: "flex", marginLeft: "auto" }}
						onClick={() => {
							setMultipleImagesId([]);
							// setDuplicateImageModal(false);
							setClickSelect(false);
							setMergedImageModal(false);
						}}
					>
						<img src={CanceIcon} className="img-fluid" alt="mindreach images" />
					</div>
				</div>
				<div>
					<div className="merged-image-text--container">
						<h2 className="merged-image--text">Image to Merge</h2>
						<h2 className="merged-image-keep-original--text mr-2">
							Keep Orig.
						</h2>
					</div>

					{generateDivs()}

					<h2
						className={`add-merged-image--text ${
							isAddButtonDisabled ? "disabled" : ""
						}`}
						onClick={isAddButtonDisabled ? null : handleAddMore}
					>
						Click to Add More
					</h2>
					<div></div>
				</div>
				<div className="global-gap--btn sidebar-single--btn mt-2">
					<Button
						variant="primary"
						className="btns"
						onClick={() => handleSubmitMergedImage()}
					>
						Submit
					</Button>
					<h2
						className="text-secondary f-sz-13"
						style={{
							color: "green!important",
							width: "auto",
							margin: "10px",
						}}
					>
						{status !== "" && status}
						{status === "Successfully Group updated" && handleClear()}
						{err && <div className="error">Please Select a original image</div>}
					</h2>
				</div>
			</div>
		</div>
	);
};
export default MergedEditType;
