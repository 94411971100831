import React from 'react';

import QcThumbImg from '../../../assets/Images/qc-edit-thumb.png';
import QcBigImg from '../../../assets/Images/qc-canvas-img-big.png';


const QcEditCanvas = () => {
    return (
        <>
            {/* <RemoveScrollBar /> */}
            <div className="qc-canvas-main-block">

                <div className="qc-canvas-big">
                    <div className="qc-canvas-thumb">
                        <ul className='mb-0'>
                            <li>
                                <span>Edited</span>
                                <a href='#'><img src={QcThumbImg} className="img-fluid active" /></a>
                            </li>
                            <li>
                                <span>Original</span>
                                <a href='#'><img src={QcThumbImg} className="img-fluid" alt="mindreach" /></a>
                            </li>

                        </ul>
                    </div>
                    <img src={QcBigImg} className="img-fluid qc-big-img" alt="mindreach images" />
                </div>
            </div>
        </>
    )
}

export default QcEditCanvas