import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";

import { apiURL } from "../../../data/filepath";
import { client } from "../../../data/API/GobalClientAxiosRequest";
import {
	ADD_EDIT_TYPE,
} from "../../../config/apiRoutes.api";
import { EDIT_TYPES_LISTS_PAGE } from "../../../config/routes";

import MyVerticallyCenteredModal from "../../../components/Modal";

const isForEnterprices = [
	{ label: "Yes", value: 1 },
	{ label: "No", value: 0 },
];
const isMultiples = [
	{ label: "Yes", value: 1 },
	{ label: "No", value: 0 },
];

const AddEditType = () => {
	const navigate = useNavigate();
	const bodyFormData = new FormData();
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const [modalShow, setModalShow] = React.useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState("");
	const [editorPoint, setEditorPoint] = useState("");
	const [qcPoint, setQcPoint] = useState("");
	const [selectIsForEnterprice, setIsForEnterprice] = useState(0);
	const [selectIsMultiple, setIsMultipleLabel] = useState(0);
	const [status, setStatus] = useState("");
	const selectLabel = (isForEnterprices) =>
		isForEnterprices.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	const selectIsMultipleLabel = (isMultiples) =>
		isMultiples.map(({ shift_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of shift array as label and value return a new array of object like [{value:"",label:""}]

	const handleSubmit = (event) => {
		event.preventDefault();
		if (token) {
			const request = client(token);

			bodyFormData.append("edit_type", name);
			bodyFormData.append("description", description);
			bodyFormData.append("price", price);
			bodyFormData.append("is_for_enterprise", selectIsForEnterprice);
			bodyFormData.append("is_multiple", selectIsMultiple);
			bodyFormData.append("editor_point", editorPoint);
			bodyFormData.append("qc_point", qcPoint);
			request
				.post(apiURL + ADD_EDIT_TYPE, bodyFormData)
				.then((response) => {
					console.log("add", response);
					setStatus("Success");
					setSubmitStatusMessage(response.data.message);
					setModalShow(true);
				})
				.catch((err) => {
					setStatus("Error");
					setSubmitStatusMessage(err);
				});
		}
	};
	return (
		<div>
			<div className="rightbar pr-11">
				<div className="section-title">
					<h2>Add Edit Type</h2>
				</div>
				<Row>
					<Col sm={9} lg={5}>
						<Form>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Name</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="text"
												placeholder=""
												autoFocus
												autoComplete="off"
												onChange={(e) => setName(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group ">
									<Row>
										<Form.Label className="col-sm-3">Description </Form.Label>
										<Col sm={9}>
											<Form.Control
												as="textarea"
												placeholder="Write Description..."
												style={{ height: "148px" }}
												onChange={(e) => setDescription(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Price</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="text"
												placeholder=""
												autoComplete="off"
												onChange={(e) => setPrice(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Is For Enterprice</Form.Label>
										<Col sm={9}>
											<Select
												onChange={(e) => {
													setIsForEnterprice(e.value);
												}}
												options={selectLabel(isForEnterprices)}
												maxMenuHeight="120px"
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Is Mulitple Image</Form.Label>
										<Col sm={9}>
											<Select
												onChange={(e) => setIsMultipleLabel(e.value)}
												options={selectIsMultipleLabel(isMultiples)}
												maxMenuHeight="120px"
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">Editor Point</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="text"
												placeholder=""
												autoComplete="off"
												onChange={(e) => setEditorPoint(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<Row>
										<Form.Label className="col-sm-3">QC Point</Form.Label>
										<Col sm={9}>
											<Form.Control
												type="text"
												placeholder=""
												autoComplete="off"
												onChange={(e) => setQcPoint(e.target.value)}
											/>
										</Col>
									</Row>
								</div>
								<div className="form-group">
									<div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
										<div className="">
											<Button
												variant=""
												className="puple btns mr-3"
												type="submit"
												onClick={() => navigate(EDIT_TYPES_LISTS_PAGE)}
											>
												Cancel
											</Button>
											<Button
												variant="primary"
												className="btns"
												type="submit"
												onClick={(e) => handleSubmit(e)}
											>
												Save
											</Button>
										</div>
									</div>
								</div>
							</Form.Group>
						</Form>
					</Col>
				</Row>
			</div>
			<MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
					navigate(EDIT_TYPES_LISTS_PAGE);
				}}
				heading={status}
				message={submitStatusMessage}
				className="modal-body-body--success"
			/>
		</div>
	);
};
export default AddEditType;
