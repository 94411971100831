import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


const ConfirmBox = () => {
    return (
        <>

            <div className="confirm-box">
                <div className="d-sm-flex flex-sm-wrap justify-content-end confirm-box-price align-items-center">

                    <div className="total-text">
                        <p>Total Set Images: <span>
                            3,000 of 3,479 Images</span></p>
                    </div>
                    <div className="total-text">
                        <p>Total Edit Types: <span>6,431</span></p>
                    </div>

                    <div className="total-text">
                        <p>Total: <span className="pricelist">$3,213.84</span></p>
                    </div>

                    <div className="">
                        <Button variant="primary" className="btns" type="submit">
                            Proceed to Confirm
                        </Button>
                    </div>

                    <div className="total-text-link">
                        Continue Later
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmBox