import React from 'react'
import Form from 'react-bootstrap/Form';
import user1 from '../../../assets/Images/user1.svg'
import group1 from '../../../assets/Images/group1.svg'
import group2 from '../../../assets/Images/group2.svg'
import AddUserGroupChat from "./AddUserGroupChat"
const UserGroupChatSearchsidebar = () => {
    return (
        <>

            <div className="usersearch">
                <div className="d-sm-flex mt-3 mb-3 justify-content-between align-item-center supervisor-group-heading">
                    <div className="">
                        <h4 className="">Group Chat</h4>
                    </div>
                    <div className="">
                        <AddUserGroupChat />
                    </div>
                </div>


                <Form>

                    <Form.Control
                        type="search"
                        placeholder="Search group"
                        className="me-2"
                        aria-label="Search"
                    />

                </Form>

                <div className="namelist mt-3 supervisor-list">
                    <ul>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group1} />
                        </div>
                            <span>Project A Discussion</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group2} />
                        </div>
                            <span>Sampling for Project B</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group1} />
                        </div>
                            <span>Ja Morant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user1} />
                        </div>
                            <span>Kevin Durant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group1} />
                        </div>
                            <span>Project A Discussion</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group2} />
                        </div>
                            <span>Sampling for Project B</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group1} />
                        </div>
                            <span>Ja Morant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user1} />
                        </div>
                            <span>Kevin Durant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group1} />
                        </div>
                            <span>Project A Discussion</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group2} />
                        </div>
                            <span>Sampling for Project B</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group1} />
                        </div>
                            <span>Ja Morant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user1} />
                        </div>
                            <span>Kevin Durant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group1} />
                        </div>
                            <span>Project A Discussion</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group2} />
                        </div>
                            <span>Sampling for Project B</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={group1} />
                        </div>
                            <span>Ja Morant</span>
                        </li>
                        <li><div className="profilepic"><img className="img-fluid" alt="mindreach images" src={user1} />
                        </div>
                            <span>Kevin Durant</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default UserGroupChatSearchsidebar