import React from 'react';
import Sidebar from '../../components/global/Sidebar/Sidebar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// import workspace from '../../../css/workspace.css';
// import Qcworkspace from '../../../css/qc-workspace.css';
import QcEditCanvas from '../Workspace/QcEditCanvas';
import QcEditDetails from '../Workspace/QcEditDetails';

const QcWorkspace = () => {
    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="rightbar pl-0 pr-0 pb-0">
                        <div className="section-title qc-main-pad">
                            <h2>QC Canvas</h2>
                        </div>
                        <div className="workbody qc-top-space pb-0 pr-0">
                            <Row className="qc-canvas-main">
                                <Col sm={9} className="pr-0 mobile-edit-canvas">
                                    <div className="qc-edit-canvas">
                                        <QcEditCanvas/>
                                    </div>
                                </Col>
                                <Col sm={3} className="pr-0 mobile-canvas-info">
                                    <div className="qc-edit-canvas-right-sidebar">
                                        <div className='qc-pageitem'>
                                            <a href='#' className='qc-prev-next qc-prev'></a>
                                            <p><span className='total-number-page'>1-14</span> of <span className='total-page'>14</span></p>
                                            <a href='#' className='qc-prev-next qc-next'></a>
                                        </div>
                                        <div className="qc-edit-canvas-right">
                                            <QcEditDetails/>   
                                        </div>                                                                             
                                    </div>
                                </Col>
                            </Row>                            

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QcWorkspace