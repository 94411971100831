import React from "react";
import Sidebar from "../../../components/global/Sidebar/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Delete from "../../../assets/Images/delete.svg";

const Ftp = () => {
  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel ">
          <div className="rightbar pr-11">
            <div className="section-title">
              <h2>Create Order</h2>
              <h4 className="mt-5 mb-4">FTP Upload Information</h4>
              <p>
                Once this order has been submitted, images should be uploaded to
                the FTP account and credentials below:
              </p>
            </div>

            <Row>
              <Col lg={8} sm={12}>
                <div className="ftp-box">
                  <ul className="ftp-list">
                    <li>
                      IP: <span>101.13.294.12</span>
                    </li>
                    <li>
                      Port: <span>3</span>
                    </li>
                    <li>
                      Username: <span>nike-admin</span>
                    </li>
                    <li>
                      Password: <span>********</span>
                    </li>
                  </ul>
                </div>
                <p className="mb-5">
                  Once Images have fully been uploaded, order will be directly
                  submitted to Mindreach. If a connections is lost during
                  upload, you have 1 hour to restart the upload before our
                  system will take only the images that wew uploaded and submit
                  them to Mindreach as part of this order.
                </p>
                <div className="d-sm-flex justify-content-between btn-cls">
                  <div className="">
                    <Button
                      variant="outline-primary"
                      className="btn-light-border"
                    >
                      <img
                        src={Delete}
                        className="img-fluid"
                        alt="mindreach images"
                      />
                    </Button>{" "}
                  </div>

                  <div className="">
                    <Button
                      variant=""
                      className="puple btns mr-3"
                      type="submit"
                    >
                      Save Order
                    </Button>
                    <Button variant="primary" className="btns" type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ftp;
