import React, { useState, useEffect, useCallback } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import Select from "react-select";

import { apiURL } from "../../../../../../data/filepath";
import { client } from "../../../../../../data/API/GobalClientAxiosRequest";
import {
    ADD_TEAM_MEMBER,
    GET_TEAM_ROLE_LIST,
    BRANCHES_LIST,
    BRANCHES_SHIFT_BYBRANCHID,
    GET_TEAM_LIST,
} from "../../../../../../config/apiRoutes.api";

/**
 * Reusable modal component.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines if the modal is visible or hidden.
 * @param {function} props.onHide - Callback function to handle modal close event.
 * @param {string} props.heading - The title or heading of the modal.
 * @param {string} props.message - The content or message of the modal.
 * @returns {JSX.Element} - The rendered modal component.
 */
const AssignTeamModal = (props) => {
    const bodyFormData = new FormData();
    const token = sessionStorage.getItem("token");
    const request = client(token);
    //Declarations of state variable
    const [teamMembers, setTeamMembersData] = useState([]);
    const [selectTeamMember, setTeamMember] = useState('');
    const [selectTeamMemberId, setTeamMemberId] = useState(0);
    const [roles, setRolesData] = useState([]);
    const [selectRole, setRole] = useState('');
    const [selectRoleLabel, setRoleLabel] = useState('');
    const [branches, setBranchesData] = useState([]);
    const [selectBranch, setBranch] = useState(0);
    const [selectBranchLabel, setBranchLabel] = useState('');
    const [shifts, setShiftsData] = useState([]);
    const [selectShift, setShift] = useState(0);
    const [selectShiftLabel, setShiftLabel] = useState('');
    const [submitStatusMessage, setSubmitStatusMessage] = useState("");
    const [host, setHost] = useState("");
    const [port, setPort] = useState("");
    const [userName, setUserName] = useState(0);
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");

    const selectTeamMembersLabel = (teamMembers) =>
        teamMembers.map(({ full_name: label, id: value, ...rest }) => ({
            label,
            value,
            ...rest,
        })
    ); // change the key of teamMembers array as label and value return a new array of object like [{value:"",label:""}]
    const selectRolesLabel = (roles) =>
        roles.map(({ role: label, user_type: value, ...rest }) => ({
            label,
            value,
            ...rest,
        })
    ); // change the key of roles array as label and value return a new array of object like [{value:"",label:""}]
    const selectBranchesLabel = (branches) =>
        branches.map(({ name: label, id: value, ...rest }) => ({
            label,
            value,
            ...rest,
        })
    ); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
    const selectShiftsLabel = (shifts) =>
        shifts.map(({ shift_name: label, id: value, ...rest }) => ({
            label,
            value,
            ...rest,
        })
    ); // change the key of shifts array as label and value return a new array of object like [{value:"",label:""}]

    const handleSubmit = (event) => {
        event.preventDefault();
        if (token) {
            const request = client(token);

            bodyFormData.append("staff_id", selectTeamMember);
            request
                .post(apiURL + ADD_TEAM_MEMBER+ `/${props.id}`, bodyFormData)
                .then((response) => {
                    console.log("add", response);
                    props.setStatus("Success");
                    props.setAssignTeamAddModal(false);
                    props.setSubmitStatusMessage(response.data[0].message);
                    props.setModalShowEdit(true);

                    props.handleTeamMemberApiFetchOnSubmit();
                })
                .catch((err) => {
                    setStatus("Error");
                    props.setSubmitStatusMessage(err);
                });
        }
    };
    useEffect(() => {
        if (token) {
            request
                .get(apiURL + GET_TEAM_ROLE_LIST)
                .then((response) => {
                    console.log(response);
                    setRolesData(response.data[0].roles);
                })
                .catch((err) => console.log(err));
        }
    }, []);
    useEffect(() => {
        if (token) {
            request
                .get(apiURL + BRANCHES_LIST)
                .then((response) => {
                    console.log(response);
                    setBranchesData(response.data[0].branches);
                })
                .catch((err) => console.log(err));
        }
    }, []);
    useEffect(() => {
        if (token) {
            request
                .get(apiURL + BRANCHES_SHIFT_BYBRANCHID + `/${selectBranch}`)
                .then((response) => {
                    console.log("shift", response);
                    setShiftsData(response.data.shifts);
                })
                .catch((err) => console.log(err));
        }
    }, [selectBranch]);
    useEffect(() => {
        if (token && selectRole != '' && selectBranch != 0 && selectShift != 0) {
            request
                .get(apiURL + GET_TEAM_LIST + `?user_type=${selectRole}&branch_id=${selectBranch}&shift_id=${selectShift}`)
                .then((response) => {
                    console.log("Member", response);
                    if(response.data[0].team_members != null) {
                        setTeamMembersData(response.data[0].team_members);
                    } else {
                        setTeamMembersData([]);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [selectBranch, selectRole, selectShift]);
    return (
        <Modal
            {...props}
            // backdrop="static"
            // keyboard={false}

            size="lg"
            // aria-labelledby="contained-modal-title-vcenter"
            aria-labelledby="example-modal-sizes-title-lg"
            contentClassName="modal-content-edit--type "
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="example-modal-sizes-title-lg"
                    className="section-title fonts"
                    style={{ marginLeft: "10px" }}
                >
                    {props.heading}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="section-title fonts"
                style={{ marginLeft: "10px" }}
            >
                <div className="form-group mt-3 mb-4">
                    <Row>
                        <Form.Group className="" controlId="exampleForm.ControlInput1">
                            <Form.Label>Filter By</Form.Label>
                            <div className="d-sm-flex col-gap-20">
                                <div className="form-group flex-grow-1">
                                <Select
                                    onChange={(e) => {
                                        setRole(e.value);
                                        setRoleLabel(e.label);
                                    }}
                                    options={selectRolesLabel(roles)}
                                    placeholder={"Select Roles"}
                                    maxMenuHeight="120px"
                                />
                                </div>
                                <div className="form-group flex-grow-1">
                                <Select
                                    onChange={(e) => {
                                        setBranch(e.value);
                                        setSubmitStatusMessage(e.time_zone);
                                        setBranchLabel(e.label);
                                    }}
                                    options={selectBranchesLabel(branches)}
                                    placeholder={"Select Branch"}
                                    maxMenuHeight="120px"
                                />
                                </div>
                                <div className="form-group flex-grow-1">
                                <Select
                                    onChange={(e) => {
                                        setShift(e.value);
                                        setShiftLabel(e.label);
                                    }}
                                    options={selectShiftsLabel(shifts)}
                                    placeholder={"Select Shifts"}
                                    maxMenuHeight="120px"
                                />
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                </div>
                <div className="form-group mt-3 mb-4">
                    <Row>
                        <Form.Label className="col-sm-3">Name</Form.Label>
                        <Col sm={11}>
                            <Select
                                onChange={(e) => {
                                    setTeamMember(e.value);
                                }}
                                options={selectTeamMembersLabel(teamMembers)}
                                placeholder={"Select Team Member"}
                                maxMenuHeight="120px"
                            />
                        </Col>
                    </Row>
                </div>
                <ul className="add-team-list mt-4">
                    <li>Staff ID: </li>
                    <li>{selectTeamMember}</li>
                </ul>

                <ul className="add-team-list">
                    <li>Role: </li>
                    <li>{selectRoleLabel}</li>
                </ul>
                <ul className="add-team-list">
                    <li>Branch:</li>
                    <li>{selectBranchLabel} </li>
                </ul>
                <ul className="add-team-list">
                    <li>Shit:</li>
                    <li>{selectShiftLabel} </li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group">
                    <div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
                        <div className="">
                            <Button
                                variant=""
                                className="puple btns mr-3"
                                type="submit"
                                onClick={props.onHide}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="btns"
                                type="submit"
                                onClick={(e) => handleSubmit(e)}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignTeamModal;
