import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import Alert from "../../../components/Alert";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import MyVerticallyCenteredModal from "../../../components/Modal";
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import { ADD_CUSTOMER } from "../../../config/apiRoutes.api";
import { VIEW_CUSTOMER_PAGE, CUSTOMERS_DETAILS } from "../../../config/routes";

const AddCustomer = () => {
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState("");
  const [name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyWesite, setCompanyWebsite] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerType, setCustomerType] = useState("Monday");
  const [modalShow, setModalShow] = React.useState(false);
  const [addResponse, setAddResponse] = useState("");
  const bodyFormData = new FormData();
  const token = sessionStorage.getItem("token");
  const customerTypes = [
    { label: "Non Enterprice", value: 'non-enterprice' },
    { label: "Enterprice", value: 'enterprice' }

  ];
  /**
   * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
   * @param {Array} customerTypes - An array of branch objects.
   * @returns {Array} - An array of objects with 'label' and 'value' properties.
   */
  const selectLabel = (customerTypes) =>
  customerTypes.map(({ role: label, id: value, ...rest }) => ({
    label,
    value,
    ...rest,
  })); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]

  const validation = (email) => {
		const result = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return result.test(String(email).toLowerCase());
	}; //validated email using regex

  const handleSubmit = (event) => {
    if (token) {
      const request = client(token);
      event.preventDefault();
      bodyFormData.append("full_name", name);
      bodyFormData.append("email", email);
      bodyFormData.append("company_name", companyName);
      bodyFormData.append("company_address", companyAddress);
      bodyFormData.append("company_website", companyWesite);
      bodyFormData.append("phone_number", phoneNumber);
      bodyFormData.append("customer_type", customerType);
      request
        .post(apiURL + ADD_CUSTOMER, bodyFormData)
        .then((response) => {
          console.log(response);
          setAddResponse(response.data.message);
          setCustomerId(response.data.customer_id);
          setModalShow(true);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      <div>
        <div className="rightbar pr-11">
          <div className="section-title">
            <h2>Add Customer</h2>
          </div>
          <Row>
            <Col sm={9} lg={5}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="form-group">
                    <Row>
                      <Form.Label className="col-sm-3">Full Name</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          autoFocus
                          autocomplete="off"
                          name="name"
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Form.Label className="col-sm-3">Email</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="email"
                          placeholder=""
                          autocomplete="off"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {validation(email) === false && email !== "" && (
													<Alert
														variant={"danger"}
														messages={"Not a valid email address"}
														className="email-validated"
													/>
												)}
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Form.Label className="col-sm-3">Company Name</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          autocomplete="off"
                          name="company_name"
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Form.Label className="col-sm-3">Company Address</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          as="textarea"
                          placeholder=""
                          style={{ height: "148px" }}
                          onChange={(e) => setCompanyAddress(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Form.Label className="col-sm-3">Company Website</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          autocomplete="off"
                          name="company_website"
                          onChange={(e) => setCompanyWebsite(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
										<Row>
											<Form.Label className="col-sm-3">Phone number</Form.Label>
											<Col sm={9}>
												<PhoneNumberInput
													onChange={(e) => setPhoneNumber(e)}
                          name="phone_number"
													className="form-control"
												/>
											</Col>
										</Row>
									</div>
                  <div className="form-group">
                    <Row className="align-items-center">
                      <Form.Label className="col-sm-3">Customer Type</Form.Label>
                      <Col sm={9}>
                        <Select
                          options={selectLabel(customerTypes)}
                          placeholder="Select User Type"
                          onChange={(e) => setCustomerType(e.value)}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group">
                    <div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
                      <div className="">
                        <Button
                          variant=""
                          className="puple btns mr-3"
                          type="submit"
                          onClick={() => navigate(VIEW_CUSTOMER_PAGE)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="btns"
                          type="submit"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          navigate(CUSTOMERS_DETAILS + `/${customerId}`);
        }}
        heading={"Success"}
        message={addResponse}
        className="modal-body-body--success"
      />
    </>
  );
};

export default AddCustomer;
