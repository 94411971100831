import React, { useState, useEffect, useRef } from "react";
import { PanViewer } from "react-image-pan-zoom-rotate";
import Form from "react-bootstrap/Form";
import DeleteModalConfirmation from "../../../../../../components/Modal/DeleteModal";
import MyVerticallyCenteredModal from "../../../../../../components/Modal";
import ImageLoader from "../../../../../../components/ImageLoader";
import Draggable, { DraggableCore } from "react-draggable";
import MoreIcon from "./../../../../../../assets/Images/coordinator_actions_icon/more-Final.svg";
import cross from "./../../../../../../assets/Images/cross-3.svg";
import send_message from "./../../../../../../assets/Images/send-message.svg";
import send_message_blue from "./../../../../../../assets/Images/send-messge-blue.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import MessageMarkerIcon from "../../../../../../assets/Images/coordinator_actions_icon/messageMarkerIcon.svg";
import SendMessageIcons from "../../../../../../assets/Images/coordinator_actions_icon/SendIcon.svg";

import { client } from "../../../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../../../../data/filepath";

import {
	ADMIN_GET_COMMENTS_TO_VIEW_COORDINATOR_IMAGE,
	ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
	ADMIN_DELETE_COMMENTS_COORDINATOR_IMAGE,
} from "../../../../../../config/apiRoutes.api";
var is_dragging = false;
// Default max height for comment box
let standard_top_position = 560;

const ImageAnnotation = ({
	imageURL,
	currentAnnotation,
	setCurrentAnnotation,
	annotations,
	setAnnotations,
	// handleTextChange,
	setToggleMessage,
	toggleMessage,
	idSelected,
	token,
	setCommentsList,
	showCommentList,
	annotationsComments,
	setAnnotationsComments,
}) => {
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);
	const [dx, setDx] = useState(0);
	const [dy, setDy] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [panning, setPanning] = useState(false);
	const [panStart, setPanStart] = useState({ x: 0, y: 0 });
	const imageRefZoom = useRef(null);

	const [rotation, setRotation] = useState(0);
	const [flip, setFlip] = useState(false);
	const [dragging, setDragging] = useState(false);
	const [isDragging, setIsDragging] = useState(false);
	const [draggedElement, setdraggedElement] = useState(false);
	const [defaultPosition, setDefaultPosition] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState(-1);

	const roleJson = sessionStorage.getItem("role");
	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserId = [role !== null ? role.id : "login"];
	const LoginUserId = role !== null && role.pivot ? role.pivot.user_id : "";
	const [error, setError] = useState("");
	const [x_axis, setXAxis] = useState("");
	const [y_axis, setYAxis] = useState("");
	const [comment, setComments] = useState("");
	const [editCommentValue, setEditCommentValue] = useState("");
	// const [annotationsComments, setAnnotationsComments] = useState([]);
	const [dropDown, setDropDown] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const inputRef = useRef(null);
	const imageRef = useRef();
	const styleType = "px";
	// const styleType = "%";
	const [show, setShow] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [deleteResponse, setDeleteResponse] = useState("");
	const [deleteHeading, setDeleteHeading] = useState("");
	const [deleteCommentType, setDeleteCommentType] = useState("Delete Comment");
	const [deleteCommentTypeDetails, setDeleteCommentTypeDetails] =
		useState("comment");
	const [editComment, setEditcomment] = useState([]);
	const [activeCommentIndex, setActiveCommentIndex] = useState(-1);

	/**
	 * Handles to return the number from the string.
	 * @param {String} string.
	 * @returns {} returns the numbers from string.
	 */

	const getNumbers = (string) => {
		let numb = string.match(/\d/g);
		return Number(numb.join(""));
	};

	/**
	 * Handles to parent element top css position.
	 * @param {Number} index.
	 * @returns {} return the top css.
	 */

	const getElementTopPosition = (index) => {
		const element = document.getElementById(`parent_comment_holder_${index}`);
		if (element) {
			return getNumbers(element.style.top);
		}
		return 0;
	};

	/**
	 * Handles to set max height.
	 * @param {Number} index.
	 * @returns {} return the max height.
	 */
	const setCommentBoxMaxHeight = (index) => {
		setTimeout(() => {
			let top = standard_top_position;
			const element_top = getElementTopPosition(index);
			const matrixCSS = getMatrix(
				document.getElementById(`parent_comment_holder_${index}`)
			);
			if (matrixCSS && matrixCSS.length > 1) {
				if (matrixCSS[1] > -1) {
					top = top - element_top - matrixCSS[1];
				} else {
					top = top - element_top + Math.abs(matrixCSS[1]);
				}
				document.getElementById(
					`comments_${index}`
				).style.maxHeight = `${top}px`;
			}
		}, 10);
	};

	/**
	 * Handles to get max height.
	 * @param {Number} index.
	 * @returns {} return the max height.
	 */
	const getCommentBoxMaxHeight = (index) => {
		return standard_top_position - getElementTopPosition(index);
	};

	// Function to handle spacebar key press

	useEffect(() => {
		const handleSpaceBarPress = (event) => {
			if (
				event.keyCode === 32 &&
				!event.target.classList.contains("form-control")
			) {
				// 32 is the keycode for the spacebar
				const moveDistance = 10; // Adjust this value as needed
				setDx(dx + moveDistance);
			}
		};

		document.addEventListener("keydown", handleSpaceBarPress);

		return () => {
			document.removeEventListener("keydown", handleSpaceBarPress);
		};
	}, [dx]);
	const handleWheel = (e) => {
		if (e.altKey) {
			// Zoom in/out when Option (Alt) key is held while scrolling
			e.preventDefault();
			setZoom((prevZoom) => prevZoom + (e.deltaY > 0 ? -0.1 : 0.1));
		}
	};

	const handleMouseDown = (e) => {
		if (e.key === " ") {
			// Start panning when the Space key is pressed
			setPanning(true);
			setPanStart({ x: e.clientX, y: e.clientY });
		}
	};

	const handleMouseUp = () => {
		// Stop panning when the mouse button is released
		setPanning(false);
	};

	const handleMouseMove = (e) => {
		if (panning) {
			// Adjust the panning position
			const dx = e.clientX - panStart.x;
			const dy = e.clientY - panStart.y;
			// Update the pan position (You'll need to update dx and dy in your state)
			// pandx(dx);
			// pandy(dy);
			setPanStart({ x: e.clientX, y: e.clientY });
		}
	};

	useEffect(() => {
		// // Add event listeners when the component mounts
		// window.addEventListener("wheel", handleWheel, { passive: false });
		// document.addEventListener("keydown", handleMouseDown);
		// document.addEventListener("keyup", handleMouseUp);
		// window.addEventListener("mousemove", handleMouseMove);
		// return () => {
		//   // Clean up event listeners when the component unmounts
		//   window.removeEventListener("wheel", handleWheel);
		//   document.removeEventListener("keydown", handleMouseDown);
		//   document.removeEventListener("keyup", handleMouseUp);
		//   window.removeEventListener("mousemove", handleMouseMove);
		// };
	}, []);

	const handleImageClick = (event, inputText) => {
		setActiveCommentIndex(-1);
		const x = event.nativeEvent.offsetX;
		const y = event.nativeEvent.offsetY;
		setXAxis(x);
		setYAxis(y);
		setComments(inputText);
		setDefaultPosition(x);
		// // Create a new annotation object with the input text
		const newAnnotation = {
			x,
			y,
			text: inputText, // Use the inputText provided as the annotation text
			image_id: `${idSelected}`,
			user_id: `${currentUserId}`,
		};
		// Hide all the add comment boxes
		showHideElement("addcomment", "none");

		// Hide all the comment boxes
		showHideElement("showcomments", "none");
		// Reset Zindex for all the parent comment boxes
		document.querySelectorAll(".parent_comments").forEach((element) => {
			element.style.zIndex = 0;
		});

		// Hide all the hover comment boxes
		showHideElement("hover-show-comments", "none");

		// Show all first letter  boxes
		showHideElement("callout", "block");

		setToggleMessage(true);
		// // Add the new annotation to the annotations array
		setAnnotations([...annotations, newAnnotation]);
	};

	/**
	 * Handles all the elements based on selector class.
	 * @param {String} selector class.
	 * @param {String} display hide/show.
	 * @returns {JSX.Element} Hide/Show all the elements based on display and selector.
	 */
	const showHideElement = (selector, display) => {
		document.querySelectorAll(`.${selector}`).forEach((element) => {
			element.style.display = display;
		});
	};

	const handleTextChange = (updatedText) => {
		setComments(updatedText);
	};

	const fetchComments = (setdrag = false) => {
		if (token) {
			request
				.get(
					apiURL +
						ADMIN_GET_COMMENTS_TO_VIEW_COORDINATOR_IMAGE +
						`/${idSelected}`
				)
				.then((response) => {
					// Set the success from the response
					setAnnotationsComments([]);
					setAnnotationsComments(response.data.comments);
					if (setdrag) {
						let existingComments = [...annotationsComments];
						let commentIndex = existingComments.findIndex(function (item) {
							return item.comment_id == setdrag;
						});
						if (commentIndex >= 0) {
							let element = document.getElementById(
								`parent_comment_holder_${commentIndex}`
							);
							element.setAttribute("dragged", "completed");
						}
						setdraggedElement(true);
					}
					if (deleteIndex >= 0) {
						setTimeout(() => {
							showComments(deleteIndex);
						}, 20);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};

	const hideAllAddCommentBox = () => {
		// Hide all the add comment boxes
		let elements = document.querySelectorAll(".addcomment");
		for (let i = 0; i < elements.length; i++) {
			elements[i].style.display = "none";
		}
	};

	/**
	 * Handles to update the  the comment x_axis and y_axis.
	 * @param {Number} comment_id .
	 * @returns {JSX.Element} Submit the form and post the data with API.
	 */
	const updateXYCoordinates = (comment_id, comment_val, x_axis, y_axis) => {
		if (
			token &&
			comment_id > 0 &&
			comment_val &&
			Math.abs(x_axis) >= 0 &&
			Math.abs(y_axis) >= 0
		) {
			bodyFormData.append("image_id", idSelected);
			bodyFormData.append("x_axis", x_axis);
			bodyFormData.append("y_axis", y_axis);
			bodyFormData.append("comment", comment_val);
			bodyFormData.append("perant_id", Number(0));
			bodyFormData.append("comment_id", comment_id);
			request
				.post(
					apiURL + ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
					bodyFormData
				)
				.then((response) => {
					setXAxis("");
					setYAxis("");
					setComments("");
					//Set the success from the response
					// fetchComments();
					// setCommentsList(true);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};

	const submitCommentForm = (
		e,
		action = "edit",
		parent_id = Number(0),
		edit_comment_id = Number(0),
		annotation = {},
		index = 0
	) => {
		let comment_val = "";
		if (action === "edit") {
			comment_val =
				edit_comment_id > 0 &&
				document.getElementById(`edit_comment_${edit_comment_id}`)
					? document.getElementById(`edit_comment_${edit_comment_id}`).value
					: comment;
		} else {
			comment_val = comment;
		}

		e.preventDefault();
		let x_axs = !x_axis ? annotation.x_axis : x_axis;
		let y_axs = !y_axis ? annotation.y_axis : y_axis;

		if (token && idSelected && x_axs >= 0 && y_axs >= 0 && comment_val) {
			setDeleteIndex(-1);
			if (action === "edit") {
				if (document.getElementById(`edit_comment_img_${edit_comment_id}`)) {
					document.getElementById(
						`edit_comment_img_${edit_comment_id}`
					).style.display = "none";
					if (document.getElementById(`spinner_comment_${edit_comment_id}`)) {
						document.getElementById(
							`spinner_comment_${edit_comment_id}`
						).style.display = "block";
					}
				} else {
					if (document.getElementById(`src_reply_comment_${parent_id}`)) {
						document.getElementById(
							`src_reply_comment_${parent_id}`
						).style.display = "none";
						if (document.getElementById(`spinner_reply_comment_${parent_id}`)) {
							document.getElementById(
								`spinner_reply_comment_${parent_id}`
							).style.display = "block";
						}
					}
				}
			} else {
				let id = Number(e.target.id.toString().replace("src_add_comment_", ""));
				if (document.getElementById(`src_add_comment_${id}`)) {
					document.getElementById(`src_add_comment_${id}`).style.display =
						"none";
				}
				if (document.getElementById(`spinner_add_comment_${id}`)) {
					document.getElementById(`spinner_add_comment_${id}`).style.display =
						"block";
				}
				// x_axs -= 8;
				// y_axs -= 19;
			}

			// setCommentsList(false);
			bodyFormData.append("image_id", idSelected);
			bodyFormData.append("x_axis", x_axs);
			bodyFormData.append("y_axis", y_axs);
			bodyFormData.append("comment", comment_val);
			bodyFormData.append("perant_id", parent_id);
			bodyFormData.append("comment_id", edit_comment_id);

			request
				.post(
					apiURL + ADMIN_UPDATE_COMMENTS_TO_POST_COORDINATOR_IMAGE,
					bodyFormData
				)
				.then((response) => {
					setXAxis("");
					setYAxis("");
					setComments("");
					//Set the success from the response
					fetchComments(parent_id);
					setCommentsList(true);
					if (action === "add") {
						// Hide all the add comment boxes
						let elements = document.querySelectorAll(".addcomment");
						for (let i = 0; i < elements.length; i++) {
							elements[i].style.display = "none";
						}
					} else {
						if (edit_comment_id > 0) {
							setTimeout(() => {
								var existingComments = [...editComment];
								var commentIndex = existingComments.findIndex(
									(p) => p.comment_id == edit_comment_id
								);
								existingComments.splice(commentIndex, 1);
								setEditcomment(existingComments);
							}, 700);
							setTimeout(() => {
								showComments(index);
							}, 800);
						} else {
							setTimeout(() => {
								if (document.getElementById(`src_reply_comment_${parent_id}`)) {
									document.getElementById(
										`src_reply_comment_${parent_id}`
									).style.display = "block";
									if (
										document.getElementById(
											`spinner_reply_comment_${parent_id}`
										)
									) {
										document.getElementById(
											`spinner_reply_comment_${parent_id}`
										).style.display = "none";
									}
									if (document.getElementById(`comments_${index}`)) {
										document.getElementById(`comments_${index}`).style.display =
											"block";
									}
								}
							}, 1000);
						}
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		if (token && showCommentList) {
			fetchComments();
		}
		setAnnotationsComments([]);
		setAnnotations([]);
		setActiveCommentIndex(-1);
		// // setdraggedElement(false);
	}, [showCommentList]);

	useEffect(() => {
		setAnnotationsComments([]);
		setAnnotations([]);
		setActiveCommentIndex(-1);
	}, [idSelected]);

	useEffect(() => {
		// const handleClickOutside = (event) => {
		//   if (
		//     inputRef.current &&
		//     !inputRef.current.contains(event.target) &&
		//     annotations.length === 1
		//   ) {
		//     // Clicked outside the input box, so clear the annotations
		//     setAnnotations([]);
		//     setToggleMessage(false);
		//   }
		// };
		// // Add a click event listener to the document
		// document.addEventListener("click", handleClickOutside);
		// return () => {
		//   // Remove the event listener when the component unmounts
		//   document.removeEventListener("click", handleClickOutside);
		// };
	}, [setAnnotations, annotations, setToggleMessage]);

	/**
	 * Handles to return the first letter of the word.
	 * @param {String} sentence - The string of name.
	 * @returns {} return first letter of name.
	 */
	function getFirstLetter(sentence) {
		// Split the sentence into words
		const words = sentence.trim().split(/\s+/);
		if (words.length > 0) {
			// Get the first word
			const firstWord = words[0];
			// Get the first character of the first word
			const firstLetter = firstWord.charAt(0);
			return firstLetter;
		} else {
			// Handle the case where the input sentence is empty
			return null;
		}
	}

	/**
	 * Handles to generate the date time with am/pm format.
	 * @param {Date} isoDateString - The date  of comment.
	 * @returns {Date} Returns the formatted date with am/pm.
	 */

	const formatDateAMPM = (isoDateString) => {
		const dateObject = new Date(isoDateString);
		let hours = dateObject.getHours();
		let minutes = dateObject.getMinutes();
		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1; // Months are 0-indexed
		const year = dateObject.getUTCFullYear();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let strTime = hours + ":" + minutes + ampm;
		const formattedDate = `${day}/${month}/${year} ${strTime}`;
		return formattedDate;
	};

	/**
	 * Handles to show the div based on click hover.
	 * @param {Id} id - The event id  of comment.
	 * @returns {} Returns the div based on id.
	 */
	const showComments = (id) => {
		if (dragging === true) {
			return;
		}
		setComments("");
		// Hide all the parent comment boxes
		let hover_elements = document.querySelectorAll(".hover-show-comments");
		for (let i = 0; i < hover_elements.length; i++) {
			hover_elements[i].style.display = "none";
		}
		// Hide all the parent comment boxes
		let parent_elements = document.querySelectorAll(".parent_comments");
		for (let i = 0; i < parent_elements.length; i++) {
			parent_elements[i].style.zIndex = 0;
		}
		// Hide all the comment boxes
		let elements = document.querySelectorAll(".showcomments");
		for (let i = 0; i < elements.length; i++) {
			elements[i].style.display = "none";
		}
		// Hide all first letter  boxes
		let calloutElements = document.querySelectorAll(".callout");
		for (let i = 0; i < calloutElements.length; i++) {
			calloutElements[i].style.display = "block";
		}
		// Hide all the add comment boxes
		let addElements = document.querySelectorAll(".addcomment");
		for (let i = 0; i < addElements.length; i++) {
			addElements[i].style.display = "none";
		}
		hideCommentsTint(id);
		setEditcomment([]);
		document.getElementById(`parent_comment_holder_${id}`).style.zIndex = 1;
		document.getElementById(`comment_name_${id}`).style.display = "block";
		document.getElementById(`comments_${id}`).style.display = "block";
		setTimeout(() => {
			document.getElementById(`comments_hover_${id}`).style.display = "none";
		}, 10);
	};

	/**
	 * Handles to show the div based on mouse hover.
	 * @param {Id} id - The event id  of comment.
	 * @returns {} Returns the div based on id.
	 */
	const showCommentsTint = (id) => {
		// Hide all the parent comment boxes
		if (document.getElementById(`comments_${id}`).style.display !== "block") {
			let parent_elements = document.querySelectorAll(".parent_comments");
			for (let i = 0; i < parent_elements.length; i++) {
				parent_elements[i].style.display = "block";
				parent_elements[i].style.zIndex = 0;
				document.getElementById(`comment_name_${i}`).style.display = "block";
				document.getElementById(`comment_name_${i}`).style.zIndex = -1;
				// document.getElementById(`comments_${i}`).style.display = "none";
			}
			// Hide all the hover comment boxes
			let hover_elements = document.querySelectorAll(".hover-show-comments");
			for (let i = 0; i < hover_elements.length; i++) {
				hover_elements[i].style.display = "none";
				parent_elements[i].style.zIndex = 0;
			}
			if (
				document.getElementById(`parent_comment_holder_${activeCommentIndex}`)
			) {
				document.getElementById(
					`parent_comment_holder_${activeCommentIndex}`
				).style.zIndex = 11;
			}
			setTimeout(() => {
				document.getElementById(
					`parent_comment_holder_${id}`
				).style.zIndex = 111;
				if (document.getElementById(`comment_name_${id}`)) {
					document.getElementById(`comment_name_${id}`).style.zIndex = -1;
				}
				document.getElementById(`comments_hover_${id}`).style.zIndex = 9999999;
				document.getElementById(`comments_hover_${id}`).style.display = "block";
			}, 10);
		}
	};

	/**
	 * Handles to show hide all the comments based on mouse out.
	 * @returns {} Hide all the the div based on class.
	 */
	const hideCommentsTint = (id) => {
		// Hide all the hover comment boxes
		let hover_elements = document.querySelectorAll(".hover-show-comments");
		for (let i = 0; i < hover_elements.length; i++) {
			hover_elements[i].style.display = "none";
			hover_elements[i].style.zIndex = 0;
		}
	};
	/**
	 * Handles to hide the div based on id.
	 * @param {Id} id - The event id  of comment.
	 * @returns {} Hides the comment box based on id.
	 */
	const hideCommentBox = (id) => {
		document.getElementById(`comments_${id}`).style.display = "none";
		let elements = document.querySelectorAll(".callout");
		for (let i = 0; i < elements.length; i++) {
			elements[i].style.display = "block";
		}
	};

	/**
	 * Handles to hide the div based on id.
	 * @param {Id} id - The event id  of comment.
	 * @returns {} Hides the comment box based on id.
	 */
	const hideAddCommentBox = (id) => {
		document.getElementById(id).style.display = "none";
	};

	/**
	 * Handle the remove unwanted space from the string from the start.
	 * @param {String} str - String of user input.
	 * @returns {String} - Transformed removed space from the begining.
	 */
	const ltrim = (str) => {
		if (!str) return str;
		return str.replace(/^\s+/g, "");
	};

	/**
	 * Handle the show the comment message icon.
	 * @param {Number} comment_id - comment id.
	 * @returns {String} - returns the send message icon based on message.
	 */
	const getMessageIcon = () => {
		return !comment ? send_message : send_message_blue;
	};

	/**
	 * Handle the change of show confirm box.
	 *  @param {Number} id - Set the status based on id.
	 * @returns {Boolean} - return and set the status of state to show/hide the confirm box.
	 */

	const handleDeleteConfirmation = (id) => {
		setDeleteId(id);
		setShow(true);
	};

	const handleCommentDelete = () => {
		if (deleteId > 0) {
			request
				.get(apiURL + ADMIN_DELETE_COMMENTS_COORDINATOR_IMAGE + `/${deleteId}`)
				.then((response) => {
					setModalShow(true);
					// print the response
					if (response.data.status) {
						fetchComments();
						setDeleteId("");
						setShow(false);
						setDeleteHeading("Success");
					} else {
						setDeleteHeading("Error");
					}
					setDeleteResponse(response.data.message);
				})
				.catch((err) => {
					console.log(err);
					setDeleteResponse("Oops! Something went wrong!");
				});
		}
	};

	/**
	 * Handle the to set/unset the value in the array.
	 * @param {Number} comment_id - comment id.
	 * @param {String} comment - comment string.
	 * @returns {Object} - Returns Set/Unset number state object.
	 */

	const setCommentId = (comment_id, comment) => {
		const isExist = checkCommentExist(comment_id);
		if (isExist) {
			let existingComments = editComment;
			let index = editComment.findIndex((p) => p.comment_id == comment_id);
			existingComments[index].value = comment;
			setEditcomment(existingComments);
		} else {
			setEditcomment([
				...editComment,
				{
					comment_id: comment_id,
					value: comment,
				},
			]);
		}
		setTimeout(() => {
			let exising_value = document.getElementById(
				`edit_comment_${comment_id}`
			).value;
			document.getElementById(`edit_comment_${comment_id}`).value =
				exising_value.trimStart()
					? exising_value
					: comment.trimStart()
					? comment
					: "";
			let message_icon = document
				.getElementById(`edit_comment_${comment_id}`)
				.value.trimStart()
				? send_message_blue
				: send_message;
			document.getElementById(`edit_comment_img_${comment_id}`).src =
				message_icon;
		}, 20);
	};

	/**
	 * Handle the to get the x/y coordinates from element.
	 * @param {Object} element - element to be passed.
	 * @returns {Object} - Returns x/y coordinates from element.
	 */
	const getMatrix = (element) => {
		if (!element) {
			return;
		}
		var transform = window
			.getComputedStyle(element, null)
			.getPropertyValue("-webkit-transform");
		var results = transform.match(
			/matrix(?:(3d)\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))(?:, (-{0,1}\d+)), -{0,1}\d+\)|\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}.+))(?:, (-{0,1}.+))\))/
		);

		if (!results) return [0, 0, 0];
		if (results[1] == "3d") return results.slice(2, 5);

		results.push(0);
		return results.slice(5, 8); // returns the [X,Y,Z,1] values
	};

	/**
	 * Handle the function to check array value exist or not .
	 * @param {Number} comment_id - comment id.
	 * @returns {Object} - return object based on condition.
	 */

	const checkCommentExist = (comment_id) => {
		return editComment.find((o) => o.comment_id === comment_id);
	};

	/**
	 * Handle the function to swt the edit comment.
	 * @param {Object} e - event object.
	 * @param {Object} annotation - annotation object.
	 * @returns {Object} - return object based on condition.
	 */
	const setEditValue = (e, annotation) => {
		setXAxis(annotation.x_axis);
		setYAxis(annotation.y_axis);
		setCommentId(
			annotation.comment_id,
			e.target.value.trimStart() ? e.target.value : ""
		);
		handleTextChange("");
		if (e.target.value.trimStart()) {
			setEditCommentValue(ltrim(e.target.value));
		} else {
			setEditCommentValue("");
		}
	};

	const updateComment = (e) => {
		setEditCommentValue("");
		if (e.target.value.trimStart()) {
			handleTextChange(ltrim(e.target.value));
		} else {
			handleTextChange("");
		}
	};

	/**
	 * Handles to hide the particulat comment window.
	 * @param {Id} Number - The comment id.
	 * @returns {} Hide the comment window.
	 */

	const hideEditCommentBox = (comment_id) => {
		let existingComments = [...editComment];
		let commentIndex = existingComments.findIndex(
			(p) => p.comment_id == comment_id
		);
		existingComments.splice(commentIndex, 1);
		setEditcomment(existingComments);
	};

	/**
	 * Handles the event on drag start.
	 * @param {Object} event - The event object of dragged element.
	 * @param {Number} index - The index of the element.
	 * @returns {} Get the x and y offset at the drag start.
	 */

	const onStarteventHandler = (e, data) => {
		e.stopPropagation();
		e.preventDefault();
		console.log("Clicked Element =", e.target.classList);
		if (e.target.classList.contains("form-control")) {
			e.target.focus();
		}
	};

	const ondraggEventHandler = (e, data) => {
		e.preventDefault();
		setDragging(true);
		setdraggedElement(false);
		is_dragging = true;
		console.log("Dragging...");
		if (data.node.id.includes("parent_comment_holder_")) {
			let element = document.getElementById(data.node.id);
			if (element.hasAttribute("dragged")) {
				element.removeAttribute("dragged");
			}
			// Adjust the Comment box height as per dragg
			setCommentBoxMaxHeight(
				data.node.id.replace("parent_comment_holder_", "")
			);
		} else {
			setdraggedElement(false);
		}
		if (e.type === "mousemove" || e.type === "touchmove") {
			setIsDragging(true);
		}
		let element = document.getElementById(data.node.id);
		let x = Number(element.style.left.replace("px", ""));
		let y = Number(element.style.top.replace("px", ""));
		if (Number(data.x) >= 0) {
			x = x + Number(data.x);
		} else {
			x -= Math.abs(Number(data.x));
		}

		if (Number(data.y) >= 0) {
			y = Number(y) + Number(data.y);
		} else {
			y -= Math.abs(Number(data.y));
		}
		setXAxis(x);
		setYAxis(y);
	};

	/**
	 * Handles the event on drag complete.
	 * @param {Object} event - The event object of dragged element.
	 * @param {Object} data - The data of the element.
	 * @returns {} Get the x and y offset at the drag complete.
	 */
	const onDraggComplete = (e, data) => {
		e.preventDefault();
		if (e.type === "mouseup" || e.type === "touchend") {
			setTimeout(() => {
				setIsDragging(false);
			}, 100);
		}
		console.log({ e, data });
		let element = document.getElementById(data.node.id);
		let x = Number(element.style.left.replace("px", ""));
		let y = Number(element.style.top.replace("px", ""));
		if (Number(data.x) >= 0) {
			x = x + Number(data.x);
		} else {
			x -= Math.abs(Number(data.x));
		}

		if (Number(data.y) >= 0) {
			y = Number(y) + Number(data.y);
		} else {
			y -= Math.abs(Number(data.y));
		}
		setXAxis(x);
		setYAxis(y);
	};

	/**
	 * Handles the event on drag complete.
	 * @param {Object} event - The event object of dragged element.
	 * @param {Object} data - The data of the element.
	 * @returns {} Get the x and y offset at the drag complete.
	 */
	const StopEditCompleteEventHandler = (e, data) => {
		if (dragging === false) {
			// e.preventDefault();
			return;
		}
		e.preventDefault();

		if (is_dragging) {
			// e.preventDefault();
			console.log({ e, data });
			// console.log("data in over => ", data);
			setDragging(false);

			if (e.type === "mouseup" || e.type === "touchend") {
				setTimeout(() => {
					setIsDragging(false);
				}, 100);
			}
			let element = document.getElementById(data.node.id);
			let element_id = Number(element.id.replace("parent_comment_holder_", ""));
			// Adjust the Comment box height as per dragg
			setCommentBoxMaxHeight(element_id);
			let comment_id = element.getAttribute("db-id");
			let x = Number(element.style.left.replace("px", ""));
			let y = Number(element.style.top.replace("px", ""));
			// if (
			//   document.getElementById(`comments_${element_id}`).style.display !==
			//   "block"
			// ) {
			if (!e.target.classList.contains("img-fluid-image-details--view")) {
				if (Number(data.x) >= 0) {
					x = x + Number(data.x);
				} else {
					x -= Math.abs(Number(data.x));
				}
				if (Number(data.y) >= 0) {
					y = Number(y) + Number(data.y);
				} else {
					y -= Math.abs(Number(data.y));
				}
				let existingComments = [...annotationsComments];
				let commentIndex = existingComments.findIndex(function (item) {
					return item.comment_id == comment_id;
				});
				if (commentIndex !== -1) {
					showCommentsTint(element_id);
					updateXYCoordinates(
						comment_id,
						existingComments[commentIndex].comment,
						x,
						y
					);
				}
			} else {
				setdraggedElement(true);
			}
			is_dragging = false;
		}

		// }
	};

	/**
	 * Handles to left margin.
	 * @param {Object} object.
	 * @returns {} return margin based on string length.
	 */
	const getThreadMargin = (object) => {
		let left = 0;
		const commentLength = object.comment_by.length;
		if (commentLength < 7) {
			left = 18;
		} else if (commentLength >= 7 && commentLength <= 10) {
			left = 16;
		} else if (commentLength >= 11 && commentLength <= 14) {
			left = 5;
		} else if (commentLength > 13 && commentLength < 20) {
			left = 5;
		} else {
			left = 2;
		}
		return left + "%";
	};

	/**
	 * Handles to left margin.
	 * @param {Object} object.
	 * @returns {} return margin based on string length.
	 */
	const getMargin = (object) => {
		let left = 11;
		const commentLength = object.comment_by.length;
		if (object.user_id === LoginUserId) {
			if (commentLength < 7) {
				left = 27;
			} else if (commentLength >= 7 && commentLength <= 10) {
				left = 25;
			} else if (commentLength >= 11 && commentLength <= 14) {
				left = 15;
			} else if (commentLength > 14 && commentLength < 17) {
				left = 11;
			} else if (commentLength > 17 && commentLength < 20) {
				left = 10;
			} else {
				left = 8;
			}
		}
		return left + "%";
	};

	const generateMarkup = (annotation, index) => {
		return (
			<div
				className="parent_comments"
				id={`parent_comment_holder_${index}`}
				db-id={annotation.comment_id}
				key={annotation.x_axis + annotation.y_axis}
				style={{
					position: "absolute",
					left: annotation.x_axis + styleType,
					top: annotation.y_axis + styleType,
					cursor: "pointer",
					zIndex: 1,
				}}
			>
				<div
					className={
						"callout" +
						(activeCommentIndex === index ? " active" : "") +
						(annotation.user_id !== LoginUserId ? " other_comment" : "")
					}
					onMouseEnter={(e) => showCommentsTint(index)}
					id={`comment_name_${index}`}
					style={{ zIndex: 1 }}
				>
					<div
						className={
							"firstletter " +
							(annotation.user_id === LoginUserId
								? "mycomment"
								: "other_comment")
						}
					>
						{getFirstLetter(annotation.comment_by)}
					</div>
					{/* <div className="triangle-down-right"></div> */}
					<div
						className={
							activeCommentIndex === index
								? annotation.user_id === LoginUserId
									? "my"
									: "other"
								: ""
						}
						style={{ marginTop: "-26px", marginLeft: "-30px" }}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							version="1.1"
							class="svg-triangle"
							width="25"
							height="25"
						>
							<polygon
								points="0,21 30,5 30,20"
								style={{
									fill: "#fff",
									stroke:
										annotation.user_id === LoginUserId ? "#6082b6" : "#639c2b",
									"stroke-dasharray": "1 0 12.8 0",
									"stroke-width": activeCommentIndex === index ? 1.7 : 0.9,
								}}
							/>
						</svg>
					</div>
				</div>
				<div
					className={"showcomments"}
					style={{ maxHeight: getCommentBoxMaxHeight(index) }}
					id={`comments_${index}`}
					onClick={(e) => {
						// e.preventDefault();
						// e.stopPropagation();
					}}
					onMouseDown={(e) => {
						// e.stopPropagation(); // to disable the comments list drag effect
						e.preventDefault();
					}}
				>
					<div>
						<div>
							<div
								className={
									"inner_first_letter skip " +
									(annotation.user_id === LoginUserId
										? "mycomment"
										: "other_comment")
								}
							>
								{getFirstLetter(annotation.comment_by)}
							</div>
							<span className="commented_by">
								<b>{annotation.comment_by}</b>
							</span>

							<div
								className="dd"
								style={{
									display: "inline-block",
									zIndex: "9",
									position: "relative",
									top: "15px",
									right: "18px",
									float: "right",
									// marginLeft: getThreadMargin(annotation),
									// Please ignore this line we need this function in future else will remove it
								}}
								key={"dd_" + index}
							>
								{annotation.user_id === LoginUserId && (
									<div
										onClick={() => setDropDown((val) => !val)}
										className="img-fluid--icons skip"
										style={{
											justifyContent: "center",
											cursor: "pointer",
											position: "absolute",
										}}
										id="dropdownMenuButton"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<img
											src={MoreIcon}
											alt="mindreach images"
											className="add_more_options skip"
										/>
									</div>
								)}
								<>
									<img
										style={{
											marginLeft:
												annotation.user_id === LoginUserId ? "18px" : "33px",
										}}
										src={cross}
										alt="mindreach images"
										className="add_close_comment skip"
										onClick={() => {
											hideCommentBox(index);
											setActiveCommentIndex(-1);
										}}
									/>
								</>
								{annotation.user_id === LoginUserId && (
									<div
										className="dropdown-menu skip"
										aria-labelledby="dropdownMenuButton"
									>
										<a
											className="dropdown-item skip"
											style={{
												color: "black",
												fontSize: "0.8rem",
												padding: "0.25rem 1.5rem",
											}}
											onClick={() => {
												setCommentId(annotation.comment_id, annotation.comment);
											}}
										>
											Edit Comment
										</a>
										<a
											className="dropdown-item skip"
											style={{
												color: "black",
												fontSize: "0.8rem",
												padding: "0.25rem 1.5rem",
											}}
											onClick={() => {
												handleDeleteConfirmation(annotation.comment_id);
												setDeleteCommentType("Delete Thread");
												setDeleteCommentTypeDetails("thread");
												setDeleteIndex(-1);
											}}
										>
											Delete Thread
										</a>
									</div>
								)}
							</div>
						</div>
						<div className="comment_date">
							{formatDateAMPM(annotation.commented_at)}
						</div>
						{!checkCommentExist(annotation.comment_id) ? (
							<div
								className="comment_details mt-3"
								id={`comment_${annotation.comment_id}`}
							>
								{annotation.comment}
							</div>
						) : (
							<form
								className="comment_edit mt-3"
								onSubmit={(e) => {
									submitCommentForm(
										e,
										"edit",
										Number(0),
										annotation.comment_id,
										annotation,
										index
									);
								}}
							>
								<Form.Control
									type="text"
									autoComplete="off"
									placeholder="Edit Comment"
									autoFocus
									onChange={(e) => {
										setEditValue(e, annotation);
									}}
									id={`edit_comment_${annotation.comment_id}`}
								/>
								<img
									id={`edit_comment_img_${annotation.comment_id}`}
									src={
										checkCommentExist(annotation.comment_id) &&
										checkCommentExist(annotation.comment_id).value
											? send_message_blue
											: send_message
									}
									className="trigger skip"
									onClick={(e) => {
										submitCommentForm(
											e,
											"edit",
											Number(0),
											annotation.comment_id,
											annotation,
											index
										);
									}}
									style={{ display: "block" }}
								/>
								<ImageLoader id={`spinner_comment_${annotation.comment_id}`} />
								<img
									style={{}}
									src={cross}
									alt="mindreach images"
									className="edit_close_comment skip"
									onClick={(e) => {
										hideEditCommentBox(annotation.comment_id);
									}}
								/>
							</form>
						)}
						{annotation.reply &&
							annotation.reply.length > 0 &&
							annotation.reply.map((replyObj) => {
								return (
									<>
										<div>
											<div
												className={
													"inner_first_letter " +
													(replyObj.user_id === LoginUserId ? "mycomment" : "")
												}
											>
												{getFirstLetter(replyObj.comment_by)}
											</div>
											<span className="commented_by">
												<b>{replyObj.comment_by}</b>
											</span>
											{replyObj.user_id === LoginUserId && (
												<>
													<div
														className=" dd"
														style={{
															position: "initital",
															display: "inline-block",
															marginLeft: getMargin(replyObj),

															// (replyObj.user_id === LoginUserId ? 17 : 13) +
															// "%",
														}}
														key={"dd_" + index}
													>
														<div
															onClick={() => setDropDown((val) => !val)}
															className="img-fluid--icons skip"
															style={{
																justifyContent: "center",
																cursor: "pointer",
																position: "initial",
																height: "36px",
															}}
															id="dropdownMenuButton"
															data-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
														>
															<img
																src={MoreIcon}
																alt="mindreach images"
																className="add_more_options skip"
																style={{ marginLeft: "4px" }}
															/>
														</div>

														<div
															className="dropdown-menu skip"
															aria-labelledby="dropdownMenuButton"
														>
															<a
																className="dropdown-item skip"
																style={{
																	color: "black",
																	fontSize: "0.8rem",
																	padding: "0.25rem 1.5rem",
																}}
																onClick={() => {
																	setCommentId(
																		replyObj.comment_id,
																		replyObj.comment
																	);
																}}
															>
																Edit
															</a>
															<a
																className="dropdown-item"
																style={{
																	color: "black",
																	fontSize: "0.8rem",
																	padding: "0.25rem 1.5rem",
																}}
																onClick={() => {
																	handleDeleteConfirmation(replyObj.comment_id);
																	setDeleteCommentType("Delete Comment");
																	setDeleteCommentTypeDetails("comment");
																	setDeleteIndex(index);
																}}
															>
																Delete
															</a>
														</div>
													</div>
												</>
											)}
										</div>
										<div className="comment_date">
											{formatDateAMPM(replyObj.commented_at)}
										</div>
										{!checkCommentExist(replyObj.comment_id) ? (
											<div
												className="comment_details mt-3"
												id={`comment_${replyObj.comment_id}`}
											>
												{replyObj.comment}
											</div>
										) : (
											<form
												className="comment_edit mt-3"
												onSubmit={(e) => {
													submitCommentForm(
														e,
														"edit",
														annotation.comment_id,
														replyObj.comment_id,
														replyObj,
														index
													);
												}}
											>
												<Form.Control
													type="text"
													autoComplete="off"
													placeholder="Edit Comment"
													autoFocus
													onChange={(e) => {
														setEditValue(e, replyObj);
													}}
													id={`edit_comment_${replyObj.comment_id}`}
												/>
												<img
													id={`edit_comment_img_${replyObj.comment_id}`}
													src={
														checkCommentExist(replyObj.comment_id) &&
														checkCommentExist(replyObj.comment_id).value
															? send_message_blue
															: send_message
													}
													className="trigger skip"
													onClick={(e) => {
														submitCommentForm(
															e,
															"edit",
															annotation.comment_id,
															replyObj.comment_id,
															replyObj,
															index
														);
													}}
												/>
												<ImageLoader
													id={`spinner_comment_${replyObj.comment_id}`}
												/>
												<img
													style={{}}
													src={cross}
													alt="mindreach images"
													className="edit_close_comment"
													onClick={(e) => {
														hideEditCommentBox(replyObj.comment_id);
													}}
												/>
											</form>
										)}
									</>
								);
							})}
						<div className="comment_add" style={{ marginTop: "15px" }}>
							<form
								onSubmit={(e) => {
									submitCommentForm(
										e,
										"edit",
										annotation.comment_id,
										Number(0),
										annotation,
										index
									);
								}}
							>
								<Form.Control
									type="text"
									autoComplete="off"
									placeholder="Reply"
									style={{ width: "90%" }}
									value={comment}
									autoFocus
									onChange={(e) => {
										updateComment(e);
										setXAxis(annotation.x_axis);
										setYAxis(annotation.y_axis);
									}}
									onClick={(e) => {
										e.currentTarget.focus();
										// e.stopPropagation();
									}}
									className="skip"
								/>
								<img
									id={`src_reply_comment_${annotation.comment_id}`}
									src={getMessageIcon()}
									className="trigger skip"
									onClick={(e) => {
										submitCommentForm(
											e,
											"edit",
											annotation.comment_id,
											Number(0),
											annotation,
											index
										);
									}}
								/>
								<ImageLoader
									id={`spinner_reply_comment_${annotation.comment_id}`}
								/>
							</form>
						</div>
						{/* <div className="comment-triangle-down-right"></div> */}
					</div>
				</div>
				<div
					className={"hover-show-comments"}
					id={`comments_hover_${index}`}
					onMouseLeave={() => hideCommentsTint(index)}
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						if (!isDragging) {
							setActiveCommentIndex(index);
							setdraggedElement(false);
							setDragging(false);
							showComments(index);
							return;
						}
						return;
					}}
					onMouseDown={(e) => {
						e.preventDefault();
					}}
				>
					<div>
						<div style={{ paddingTop: "2px" }}>
							<div
								className={
									"inner_first_letter" +
									(annotation.user_id === LoginUserId ? " mycomment" : "")
								}
								style={{ marginLeft: "12px" }}
							>
								{getFirstLetter(annotation.comment_by)}
							</div>
							<span className="commented_by" style={{ fontSize: "14px" }}>
								<b>{annotation.comment_by}</b>
							</span>
							<p className="comm_date" style={{}}>
								{" "}
								{formatDateAMPM(annotation.commented_at)}
							</p>
						</div>
						<div
							className="comment_details mt-2"
							style={{ marginLeft: "3.1rem", paddingBottom: "10px" }}
						>
							{annotation.comment}
						</div>
					</div>
				</div>
			</div>
		);
	};
	// console.log("annotations", annotations, idSelected);
	return (
		<div
			style={{
				zIndex: 998,
			}}
		>
			<div
				style={{
					position: "relative",
					display: "inline-block",
					maxWidth: "100%",
					maxHeight: "100%",
				}}
				id={"image_holder"}
			>
				<PanViewer
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
					}}
					zoom={zoom}
					// setZoom={handleWheel}
					pandx={dx}
					pandy={dy}
					key={dx}
				>
					<img
						variant="top"
						layout="fill"
						objectFit="contain"
						className="img-fluid-image-details--view"
						src={imageURL}
						alt="Annotated Image"
						onClick={showCommentList ? handleImageClick : ""}
						style={{ maxWidth: "100%" }}
						ref={imageRefZoom}
						onMouseDown={handleMouseDown}
						onMouseUp={handleMouseUp}
						onMouseMove={handleMouseMove}
					/>
				</PanViewer>
				{annotations !== undefined &&
					toggleMessage === true &&
					annotations.map((annotation, index) => (
						<>
							<Draggable
								key={"add_c" + annotation.x + annotation.y}
								onDrag={ondraggEventHandler}
								onStart={onStarteventHandler}
								onStop={onDraggComplete}
								bounds="parent"
							>
								<div
									key={annotation.x + annotation.y}
									style={{
										position: "absolute",
										left: annotation.x + styleType,
										top: annotation.y + styleType,
										cursor: "pointer",
										zIndex: 1,
									}}
									id={`add_comment_parent_${index}`}
									className={`add_comment_parent`}
								>
									{" "}
									<div className="">
										<div
											id={`addcomment_${index}`}
											className={
												"addcomment" +
												(defaultPosition > 640 ? " move_right" : "")
											}
											style={{
												display:
													annotations.length === index + 1 ? "block" : "none",
											}}
										>
											<div>
												<div style={{ paddingTop: "17px" }}>
													<div className=""></div>
													<span
														className="commented_by"
														style={{ marginLeft: "22px", fontSize: "15px" }}
													>
														<b>Add Comment</b>
													</span>

													<div
														className=" dd"
														style={{
															position: "initital",
															display: "inline-block",
															marginLeft: "23%",
														}}
														key={"dd_" + index}
													>
														<>
															<img
																src={cross}
																alt="mindreach images"
																className="add_close_comment"
																onClick={() => {
																	hideAddCommentBox(`addcomment_${index}`);
																}}
															/>
														</>
														<div
															className="dropdown-menu"
															aria-labelledby="dropdownMenuButton"
														>
															<a
																className="dropdown-item"
																style={{
																	color: "black",
																	fontSize: "0.8rem",
																	padding: "0.25rem 1.5rem",
																}}
															>
																Delete
															</a>
														</div>
													</div>
												</div>

												<div className="comment_details mt-3"></div>
												<div className="comment_add mt-4">
													<form
														onSubmit={(e) => {
															submitCommentForm(e, "add");
														}}
														id={index}
													>
														<Form.Control
															type="text"
															autoComplete="off"
															placeholder="Reply"
															style={{ width: "90%" }}
															value={comment}
															autoFocus
															onChange={(e) => {
																updateComment(e);
															}}
															className="skip"
														/>
														<img
															id={`src_add_comment_${index}`}
															src={getMessageIcon()}
															className="trigger "
															onClick={(e) => {
																submitCommentForm(e, "add");
															}}
														/>
														<ImageLoader id={`spinner_add_comment_${index}`} />
													</form>
												</div>
											</div>
											{defaultPosition < 640 ? (
												<div className="addcomment-triangle-down-left"></div>
											) : (
												<div className="addcomment-triangle-down-right"></div>
											)}
										</div>
									</div>
								</div>
							</Draggable>
						</>
					))}

				{annotationsComments !== undefined && showCommentList === true ? (
					<>
						{annotationsComments.map((annotation, index) => (
							<>
								{console.log(
									" annotation.user_id =",
									annotation.user_id,
									", LoginUserId=",
									LoginUserId
								)}
								<Draggable
									allowAnyClick={true}
									// offsetParent="image_holder"
									disabled={annotation.user_id !== LoginUserId}
									// handle=`comments_hover_` + index
									onDrag={ondraggEventHandler}
									onStart={onStarteventHandler}
									onStop={StopEditCompleteEventHandler}
									// bounds="parent"
									position={
										draggedElement ||
										(document.getElementById(
											`parent_comment_holder_${index}`
										) &&
											document
												.getElementById(`parent_comment_holder_${index}`)
												.hasAttribute("dragged"))
											? { x: 0, y: 0 }
											: undefined
									}
								>
									{generateMarkup(annotation, index)}
								</Draggable>
							</>
						))}
					</>
				) : (
					<>{/* <Loader /> */}</>
				)}
			</div>
			<DeleteModalConfirmation
				show={show}
				onHide={() => {
					setShow(false);
				}}
				branch={deleteCommentTypeDetails}
				name={""}
				title={deleteCommentType}
				handleConfirm={() => handleCommentDelete()}
			/>
			<MyVerticallyCenteredModal
				show={modalShow}
				onHide={() => {
					setModalShow(false);
				}}
				heading={deleteHeading}
				message={deleteResponse}
				className="modal-body-body--success"
			/>
		</div>
	);
};

export default ImageAnnotation;
