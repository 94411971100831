import React from 'react'
import user1 from '../../assets/Images/user1.svg'
import user7 from '../../assets/Images/user1.svg'

import InputGroup from 'react-bootstrap/InputGroup';
import message from '../../assets/Images/message.svg'
import Form from 'react-bootstrap/Form';






const CustomerEditorChat = () => {
  return (
    <>

    
    



    <ul className="list-unstyled chatlist">
      <li className="media">
      <div className="profilepic mr-3">
                                <img src={user1} alt="Generic placeholder image" />
                            </div>
        <div className="media-body">
            <div className="d-sm-flex col-gap-20 align-items-center">
                <div className="">
                    <h5 className="mt-0 mb-1">Stephen Curry</h5>
                </div>
                <div className="">
                    <p className="time">Tuesday 25/8/2022,  12:24 PM</p>
                </div>
            </div>
          
          <p className="chatdetails">Hi Stephen, just wanted to see if you had any updated on the project. Any information you can provide me would be great!</p>
        </div>
      </li>
      <li className="media ">
      <div className="profilepic mr-3">
                                <img src={user7} alt="Generic placeholder image" />
                            </div>
        <div className="media-body">
            <div className="d-sm-flex col-gap-20 align-items-center">
                <div className="">
                    <h5 className="mt-0 mb-1">Stephen Curry</h5>
                </div>
                <div className="">
                    <p className="time">Tuesday 25/8/2022,  12:24 PM</p>
                </div>
            </div>
          
          <p className="chatdetails">Hi Kobe, let me go through our data and see where we are with the project. 
          I‘ll get back to you in an hour.</p>
        </div>
      </li>
      <li className="media">
      <div className="profilepic mr-3">
                                <img src={user1} alt="Generic placeholder image" />
                            </div>
        <div className="media-body">
            <div className="d-sm-flex col-gap-20 align-items-center">
                <div className="">
                    <h5 className="mt-0 mb-1">Stephen Curry</h5>
                </div>
                <div className="">
                    <p className="time">Tuesday 25/8/2022,  12:24 PM</p>
                </div>
            </div>
          
          <p className="chatdetails">Great. Look forward to your next update.</p>
        </div>
      </li>
    
    
    
      <li className="media">
      <div className="profilepic mr-3">
                                <img src={user7} alt="Generic placeholder image" />
                            </div>
        <div className="media-body">
            <div className="d-sm-flex col-gap-20 align-items-center">
                <div className="">
                    <h5 className="mt-0 mb-1">Stephen Curry</h5>
                </div>
                <div className="">
                    <p className="time">Tuesday 25/8/2022,  12:24 PM</p>
                </div>
            </div>
          
          <p className="chatdetails">Hi Stephen, just wanted to see if you had any updated on the project. Any information you can provide me would be great!</p>
        </div>
      </li>
      <li className="media ">
      <div className="profilepic mr-3">
                                <img src={user1} alt="Generic placeholder image" />
                            </div>
        <div className="media-body">
            <div className="d-sm-flex col-gap-20 align-items-center">
                <div className="">
                    <h5 className="mt-0 mb-1">Stephen Curry</h5>
                </div>
                <div className="">
                    <p className="time">Tuesday 25/8/2022,  12:24 PM</p>
                </div>
            </div>
          
          <p className="chatdetails">After looking at the data, we are about 75% complete and we should be done by the end of the day. Is there anything else you needed help with?</p>
        </div>
      </li>
      <li className="media  ">
      <div className="profilepic mr-3">
                                <img src={user7} alt="Generic placeholder image" />
                            </div>
        <div className="media-body">
            <div className="d-sm-flex col-gap-20 align-items-center">
                <div className="">
                    <h5 className="mt-0 mb-1">Stephen Curry</h5>
                </div>
                <div className="">
                    <p className="time">Tuesday 25/8/2022,  12:24 PM</p>
                </div>
            </div>
          
          <p className="chatdetails">You are very welcome. Have a nice day!</p>
        </div>
      </li>
    </ul>
    <InputGroup className="mb-3 chatmessage">
            <Form.Control
              placeholder="Message editing team"
              aria-label="Message editing team"
              aria-describedby="basic-addon2"
            />
            <InputGroup.Text id="basic-addon2"><img src={message} className="img-fluid" alt="mindreach images"/></InputGroup.Text>
          </InputGroup>
        
        
        </>
  )
}

export default CustomerEditorChat