import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import Placeholder from "react-bootstrap/Placeholder";
// import '../../../../css/style.css'
// import '../../../../css/responsive.css'
// import '../../../../css/dashboardtable.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import Pipelines from "../Pipelines/Pipelines";
import Sidebar from "../../../components/global/Sidebar/Sidebar";
import Newdashboard from "../NewDashboard/Newdashboard";

const DashboardTables = () => {
	const products = [
		{
			id: "1",
			name: "Reebok International",
			price: "0000054",
			date: "19 Aug 2022 12:01",
			total: "750",
			pending: "543",
			coordinating: "137",
			Editing: "105",
			quality: "175",
			Revision: "10",
			Status: <p style={{ color: "#F1A100" }}>Processing</p>,
		},
		{
			id: 2,
			name: "Nick, Inc.",
			price: "0000055",
			date: "19 Aug 2022 12:01",
			total: "750",
			pending: "543",
			coordinating: "137",
			Editing: "105",
			quality: "175",
			Revision: "10",
			Status: <p style={{ color: "#F1A100" }}>Processing</p>,
		},
		{
			id: 3,
			name: "Adidas",
			price: "0000056",
			date: "19 Aug 2022 12:01",
			total: "750",
			pending: "543",
			coordinating: "137",
			Editing: "105",
			quality: "175",
			Revision: "10",
			Status: <p style={{ color: "#F1A100" }}>Processing</p>,
		},
		{
			id: 4,
			name: "Coca Cola",
			price: "0000057",
			date: "19 Aug 2022 12:01",
			total: "750",
			pending: "543",
			coordinating: "137",
			Editing: "105",
			quality: "175",
			Revision: "10",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			id: 5,
			name: "Coca Cola",
			price: "0000058",
			date: "19 Aug 2022 12:01",
			total: "750",
			pending: "543",
			coordinating: "137",
			Editing: "105",
			quality: "175",
			Revision: "10",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			id: 6,
			name: "Adidas",
			price: "0000059",
			date: "19 Aug 2022 12:01",
			total: "750",
			pending: "543",
			coordinating: "137",
			Editing: "105",
			quality: "175",
			Revision: "10",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
	];

	const columns = [
		{
			dataField: "name",
			text: "Customer",
			sort: true,
			number: "1",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "price",
			text: "Order No.",
			sort: true,
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "date",
			text: "Order Date",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "total",
			text: "Total",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "pending",
			text: "Pending",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "coordinating",
			text: "Coordinating",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Editing",
			text: "Editing",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "quality",
			text: "Quality Control",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Revision",
			text: "Revision",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Status",
			text: "Status",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
	];

	const defaultSorted = [
		{
			dataField: "name",
			order: "desc",
		},
	];

	// <BootstrapTable
	//   keyField="id"
	//   data={products}
	//   columns={columns}
	//   striped
	//   hover
	//   condensed
	//   defaultSortDirection="asc"
	// />

	return (
		<>
			<div className="rightbar pr-11">
				<Pipelines />
				<div className="table-responsive table-b">
					<BootstrapTable keyField="id" data={products} columns={columns} />
				</div>
				<Row className="mt-4">
					<Col md={{ span: 4, offset: 8 }}>
						{
							<Pagination>
								<Pagination.Prev />
								<Pagination.Item active>{1}</Pagination.Item>
								<Pagination.Item>{2}</Pagination.Item>
								<Pagination.Item>{3}</Pagination.Item>
								<Pagination.Item>{4}</Pagination.Item>
								<Pagination.Item>{5}</Pagination.Item>
								<Pagination.Item>{6}</Pagination.Item>
								<Pagination.Next />
							</Pagination>
						}
					</Col>
				</Row>
				<Newdashboard />
			</div>
		</>
	);
};
// const DashboardTables = () => {
//   return (
//         <>
//         <div className="radius">
//         <Table responsive hover>
//           <thead>
//             <tr>
//               <th>Customer</th>
//               <th>Order No</th>
//               <th>Order Date</th>
//               <th>Total in Pipeline</th>
//               <th>Pending</th>
//               <th>Coordinating</th>
//               <th>Editing</th>
//               <th>Quality Control</th>
//               <th>Revision</th>
//               <th>Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Reebok International</td>
//               <td>0000054</td>
//               <td>19 Aug 2022 12:01</td>
//               <td>750</td>
//               <td>543</td>
//               <td>137</td>
//               <td>105</td>
//               <td>175</td>
//               <td>10</td>
//               <td className="text-warning">Processing</td>
//             </tr>
//             <tr>
//               <td>Nick, Inc.</td>
//               <td>0000054</td>
//               <td>19 Aug 2022 12:01</td>
//               <td>750</td>
//               <td>543</td>
//               <td>137</td>
//               <td>105</td>
//               <td>175</td>
//               <td>10</td>
//               <td className="text-warning">Processing</td>
//             </tr>
//             <tr>
//               <td>Adidas</td>
//               <td>0000054</td>
//               <td>19 Aug 2022 12:01</td>
//               <td>750</td>
//               <td>543</td>
//               <td>137</td>
//               <td>105</td>
//               <td>175</td>
//               <td>10</td>
//               <td className="text-danger">Pending</td>
//             </tr>
//             <tr>
//               <td>Coca Cola</td>
//               <td>0000054</td>
//               <td>19 Aug 2022 12:01</td>
//               <td>750</td>
//               <td>543</td>
//               <td>137</td>
//               <td>105</td>
//               <td>175</td>
//               <td>10</td>
//               <td className="text-danger">Pending</td>
//             </tr>
//             <tr>
//               <td>Coca Cola</td>
//               <td>0000054</td>
//               <td>19 Aug 2022 12:01</td>
//               <td>750</td>
//               <td>543</td>
//               <td>137</td>
//               <td>105</td>
//               <td>175</td>
//               <td>10</td>
//               <td className="text-danger">Pending</td>
//             </tr>
//             <tr>
//               <td>Nick, Inc.</td>
//               <td>0000054</td>
//               <td>19 Aug 2022 12:01</td>
//               <td>750</td>
//               <td>543</td>
//               <td>137</td>
//               <td>105</td>
//               <td>175</td>
//               <td>10</td>
//               <td className="text-danger">Pending</td>
//             </tr>

//           </tbody>
//         </Table>
//         </div>

//         <Row className="mt-4">
//           <Col md={{ span: 4, offset: 8 }}>
//             {
//               <Pagination>
//                 <Pagination.Prev />
//                 <Pagination.Item active>{1}</Pagination.Item>
//                 <Pagination.Item>{2}</Pagination.Item>
//                 <Pagination.Item>{3}</Pagination.Item>
//                 <Pagination.Item>{4}</Pagination.Item>
//                 <Pagination.Item>{5}</Pagination.Item>
//                 <Pagination.Item>{6}</Pagination.Item>
//                 <Pagination.Next />
//               </Pagination>
//             }
//           </Col>
//         </Row>
//       </>
//     )
//   }
export default DashboardTables;
