import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
import profile from '../../../assets/Images/profile.svg';
import Button from 'react-bootstrap/Button';
import Invite from '../../../assets/Images/invite.svg';
import Select from 'react-select';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Delete from '../../../assets/Images/delete.svg';


const SupervisorStaffnewuser = () => {

    const role = [
        { label: "Edior", value: 355 },
        { label: "Edior2", value: 54 },
        { label: "Edior3", value: 58 }
    ];

    const Shiftone = [
        { label: "Day Shift - Weekdays", value: 355 },
        { label: "Day Shift - Weekdays2", value: 54 },
        { label: "Day Shift - Weekdays3", value: 58 }
    ];

    const Shifttwo = [
        { label: "Weekend Shift - Weekdays", value: 355 },
        { label: "Weekend Shift - Weekdays2", value: 54 },
        { label: "Weekend Shift - Weekdays3", value: 58 }
    ];



    return (
        <>

            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="rightbar pr-11">

                        <div className="section-title">
                            <h2>Staffs - Coordinator</h2>
                            <h3 className='sub-heading'>Add User</h3>
                        </div>

                        <Form className="mb-5">
                            <Row>
                                <Col sm={12} md={9} lg={9} xl={5}>

                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">

                                            <div className="form-group">
                                                <Row>

                                                    <Form.Label className='col-sm-3'>Image</Form.Label>
                                                    <Col sm={9}>
                                                        <div className="uploadimg">
                                                            <img src={profile} className="img-fluid d-block mx-auto" />
                                                            <div className="edit">
                                                                <i className="fa fa-edit"></i>
                                                            </div>
                                                        </div></Col>

                                                </Row>
                                            </div>
                                            <div className="form-group">
                                                <Row>

                                                    <Form.Label className='col-sm-3'>Name</Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Michael Jordan"
                                                            autoFocus
                                                        /></Col>

                                                </Row>
                                            </div>
                                            <div className="form-group">
                                                <Row>

                                                    <Form.Label className='col-sm-3'>Address</Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control type="text"
                                                            placeholder="Add address" /></Col>

                                                </Row>
                                            </div>

                                            <div className="form-group">
                                                <Row>

                                                    <Form.Label className='col-sm-3'>Phone number</Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control type="email" placeholder="Add phone number" /></Col>

                                                </Row>
                                            </div>

                                            <div className="form-group">
                                                <Row>

                                                    <Form.Label className='col-sm-3'>Email</Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control type="email" placeholder="Add email" /></Col>

                                                </Row>
                                            </div>
                                            <div className="form-group">
                                                <Row>

                                                    <Form.Label className='col-sm-3'>Branch</Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control type="email" placeholder="Add Branch" /></Col>

                                                </Row>
                                            </div>



                                            <div className="form-group">
                                                <Row>
                                                    <Form.Label className='col-sm-3'>Role</Form.Label>
                                                    <Col sm={9}>
                                                        <Select options={role} defaultValue={{ label: "Standard", value: 355 }} />
                                                    </Col>

                                                </Row>
                                            </div>
                                            <div className="form-group">
                                                <Row>
                                                    <Form.Label className='col-sm-3'>Shift(s)</Form.Label>
                                                    <Col sm={9}>
                                                        <Select options={Shiftone} defaultValue={{ label: "Day Shift - Weekdays", value: 355 }} />

                                                        <Select className="mt-3" options={Shifttwo} defaultValue={{ label: "Weekend Shift - Weekdays", value: 355 }} />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="form-group">
                                                <Row>
                                                    <Col>
                                                        <p className="addfield">Add shift</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form.Group>
                                        <div className="d-sm-flex justify-content-between custom-flex-btn">
                                            <div className="">
                                                <Button variant="" className="btn-light-border grey" type="submit">
                                                    <img src={Delete} className="" img-fluid />
                                                </Button>
                                            </div>
                                            <div className="">
                                                <Button variant="" className="puple btns mr-3" type="submit">
                                                    Cancel
                                                </Button>
                                                <Button variant="primary" className="btns" type="submit">
                                                    <span><img src={Invite} className="img-fluid" alt="mindreach images" /></span> Invite
                                                </Button>
                                            </div>
                                        </div>

                                    </Form>

                                </Col>



                            </Row>

                        </Form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SupervisorStaffnewuser