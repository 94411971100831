import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const EditorOrderDetailsidebar = ({
	batchData,
	batchNumber,
	handleBatchId,
	setBatchID,
	setOrderNumber,
	setFolderName,
	timer,
	setTimer,
	elapsedTime,
	setElapsedTime,
	startTimer,
	stopTimer,
	startTime,
	setStartTime,
	handleCustomerLogged,
	...props
}) => {
	console.log("batchNumber", batchNumber);
	const [currentCardIndex, setCurrentCardIndex] = useState(null);

	function formatDateFromISO(isoDateString) {
		const dateObject = new Date(isoDateString);
		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1; // Months are 0-indexed
		const year = dateObject.getUTCFullYear();

		const formattedDate = `${day.toString().padStart(2, "0")}-${month
			.toString()
			.padStart(2, "0")}-${year}`;
		return formattedDate;
	}

	return (
		<>
			<div className="ordercard">
				{batchData !== undefined &&
					batchData.map((item, index) => (
						<Card
							className={item.id === props.activeIndex ? "activecard" : ""}
							key={index}
							onClick={() => {
								props.resetActiveIndex(item, item.id, item.batch_number);
							}}
						>
							<Card.Header>
								<div className="d-sm-flex flex-sm-wrap justify-content-between align-items-center">
									<div className="">
										<p className="ordertitle">Batch ID: {item.batch_number}</p>
										<p className="ordertitle">
											Order ID: {item.order_id}
											<br />
											{item.customer.full_name}
										</p>
									</div>
									<div className="">
										<p className="orderdate">
											{item.updated_at !== null &&
												formatDateFromISO(item.updated_at)}
										</p>
									</div>
								</div>
							</Card.Header>
							<Card.Body>
								<div className="order-details">
									<p>Image Count: {item.count}</p>
									<p>
										Status: <span className="orange">{item.status}</span>
									</p>
								</div>
								<Link to="/">
									<p className="viewguide">View Style Guide</p>
								</Link>
							</Card.Body>
						</Card>
					))}
			</div>
		</>
	);
};

export default EditorOrderDetailsidebar;
