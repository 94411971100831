import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import plus from '../../../../assets/Images/plus.svg';
import Delete from '../../../../assets/Images/delete.svg'
import Editt from '../../../../assets/Images/editt.svg'
import Pagination from 'react-bootstrap/Pagination';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import user from '../../../../assets/Images/users.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {Link} from 'react-router-dom';




const RoleTable = () => {

    //     <Tooltip id="button-tooltip" {}>
    //     Simple tooltip
    //   </Tooltip>

    const choosetype = [
        { label: "Staff", value: 355 },
        { label: "Staff", value: 54 }

    ];



    return (
        <>



            <div className="d-sm-flex flex-sm-wrap col-gap-30-sm">
                <div className="customer-search">
                    <Form className="">
                        <Row className="align-items-center">
                            <Col lg={2} sm={6}>
                                <div className="form-group ">
                                    <Select options={choosetype} defaultValue={{ label: "Staff", value: 355 }} />
                                </div>
                            </Col>
                            <Col lg={3} sm={6}>
                                <div className="form-group">
                                    <Form.Control
                                        type="search"
                                        placeholder="Search user"
                                        className="me-2"
                                        aria-label="Search"
                                    /> </div>
                            </Col>
                            <Col lg={3} sm={6}>
                                <div className="form-group">
                                    <p className="customer-count"><span><img src={user} className="img-fluid" alt="mindreach images" /></span>20 User(s)</p>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div>
                <Link to="/enterprise-add-user">
                    <Button variant="primary">
                        <span><img src={plus} className="img-fluid mr-2" /></span>
                        New User</Button>{' '} </Link>
                </div>
            </div>


            <div className="table-responsive table-b custom-new-table">

                <Table hover>
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Active Date</th>
                            <th>Role</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Kobe Bryant</td>
                            <td>kbryant@mindreach.com </td>
                            <td>22 Aug 2022</td>
                            <td>Editor</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>

                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Kobe Bryant</td>
                            <td>kbryant@mindreach.com </td>
                            <td>22 Aug 2022</td>
                            <td>Editor</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>
                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Kobe Bryant</td>
                            <td>kbryant@mindreach.com </td>
                            <td>22 Aug 2022</td>
                            <td>Editor</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>
                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Kobe Bryant</td>
                            <td>kbryant@mindreach.com </td>
                            <td>22 Aug 2022</td>
                            <td>Editor</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>
                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Kobe Bryant</td>
                            <td>kbryant@mindreach.com </td>
                            <td>22 Aug 2022</td>
                            <td>Editor</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>
                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Kobe Bryant</td>
                            <td>kbryant@mindreach.com </td>
                            <td>22 Aug 2022</td>
                            <td>Editor</td>
                            <td className="edittd">
                                <div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
                                    <div className="">
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                                <Tooltip className="in" id="tooltip-top" {...props}>
                                                    Edit
                                                </Tooltip>
                                            )}
                                            placement="bottom"
                                        ><img src={Editt} className="img-fluid" alt="Delete" />
                                        </OverlayTrigger>
                                    </div>
                                    <div className="">
                                        <img src={Delete} className="img-fluid" alt="Delete" />
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <Row className="mt-4">
                <Col md={{ span: 4, offset: 8 }}>
                    {
                        <Pagination>
                            <Pagination.Prev />
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Item>{5}</Pagination.Item>
                            <Pagination.Item>{6}</Pagination.Item>
                            <Pagination.Next />
                        </Pagination>
                    }
                </Col>
            </Row>

        </>
    )
}

export default RoleTable