import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  Form,
  Row,
  Col,
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

// Importing components and images
import Loader from "../../../components/Loader";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

import { ADMIN_GET_ENTERPRISE_REQUESTS } from "../../../config/apiRoutes.api";
import { VIEW_ENTERPRISE_REQUEST_DETAILS } from "../../../config/routes";
import { clone } from "underscore";
const ViewEnterpriseRequests = () => {
  // Retrieve the token from the session storage
  const token = sessionStorage.getItem("token");
  // Create a request instance using the token
  const request = client(token);
  // Get the navigation function from the useNavigate hook
  const navigate = useNavigate();
  // Create a new instance of FormData
  const bodyFormData = new FormData();
  //Declarations of state variable
  const [requestData, setEnterpriseData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [rows, setRows] = useState([]);

  /**
   * Fetches the enterprise request data from the API when the pageNumber changes or the token is available.
   */
  useEffect(() => {
    // Check if a token is available
    if (token) {
      fetchRequests();
    }
  }, [pageNumber]);

  /**
   * Handles the fetch  enterprise request data for.
   *
   */
  const fetchRequests = () => {
    setEnterpriseData([]);
    // Make an HTTP GET request to fetch the enterprise request data with the specified page number
    request
      .get(apiURL + ADMIN_GET_ENTERPRISE_REQUESTS + `?page=${pageNumber}`)
      .then((response) => {
        // Log the response to the console
        console.log(" response =>", response.data);

        // Update the requestData state with the response data
        setEnterpriseData(response.data);

        // // Update the rows state with the enterprise request data
        setRows(response.data.enterprices.data);
      })
      .catch((err) => console.log(err));
  };

  /**
   * Renders the pagination items for navigating between pages.
   * It generates the pagination items based on the total number of enterprise request and the active page.
   * @returns {JSX.Element[]} Array of pagination items.
   */
  const PaginationItems = () => {
    // Get the number of enterprise request per page
    let active = requestData.enterprices.per_page;

    // Initialize an empty array to store the pagination items
    let items = [];

    // Generate pagination items based on the total number of enterprise request and the active page
    for (
      let number = 1;
      number <= Math.ceil(requestData.enterprices.total / active);
      number++
    ) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === requestData.enterprices.current_page}
          onClick={() => setPageNumber(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Return the array of pagination items
    return items;
  };

  /**
   * Handles the pagination to the next page of enterprise requests
   * It updates the page number state to the next page if it's not the last page.
   * @returns {void}
   */
  const NextPagination = () => {
    // Check if the current page number is not the last page
    if (
      pageNumber !==
      Math.ceil(
        requestData.enterprices.total / requestData.enterprices.per_page
      )
    ) {
      // Update the page number state to the next page
      setPageNumber(pageNumber + 1);
    }
  };

  /**
   * Handles the pagination to the previous page of enterprise requests
   * It updates the page number state to the previous page if it's not the first page.
   * @returns {void}
   */
  const PreviousPagination = () => {
    // Check if the current page number is not the first page
    if (
      pageNumber !==
      Math.ceil(
        requestData.enterprices.total / requestData.enterprices.per_page
      )
    ) {
      // Update the page number state to the previous page
      setPageNumber(pageNumber - 1);
    }
    return setPageNumber(pageNumber - 1);
  };

  /**
   * Handles the single checkbox check / uncheck event.
   * It handles single checkbox check / uncheck event
   *  * @param {e} e - element Object.
   *  * @param {Array} row - current row clicked by user.
   * @returns {void}
   */

  console.log(" requestData =>", !!requestData.length);
  if (!!requestData.length) return <Loader />;
  // Render the actual content once requestData is available
  return (
    <>
      <div>
        <div className="rightbar pr-11">
          <div className="section-title">
            <h2>Nike Enterprise Signup Form Answers</h2>
          </div>
          <div
            className="flex-sm-wrap col-gap-30-sm mt-2-branch"
            style={{ height: "37px" }}
          >
            <div className="customer-search"></div>
            <div></div>
          </div>

          {requestData.enterprices !== undefined ? (
            <>
              <div
                className={`table-responsive table-b custom-new-table `}
                style={{ marginTop: "3rem !important" }}
              >
                <Table hover>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Address</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((item) => {
                      return (
                        <>
                          <tr key={`${item.encrypted_customer_id}`}>
                            <td
                              onClick={() =>
                                navigate(
                                  `${VIEW_ENTERPRISE_REQUEST_DETAILS}/${item.encrypted_customer_id}`
                                )
                              }
                            >
                              {item.company_name}
                            </td>
                            <td
                              onClick={() =>
                                navigate(
                                  `${VIEW_ENTERPRISE_REQUEST_DETAILS}/${item.encrypted_customer_id}`
                                )
                              }
                            >
                              {item.address}
                            </td>
                            <td
                              onClick={() =>
                                navigate(
                                  `${VIEW_ENTERPRISE_REQUEST_DETAILS}/${item.encrypted_customer_id}`
                                )
                              }
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <Row className="mt-4">
                <Col
                  md={{ span: 4, offset: 8 }}
                  className="flex flex-md-row-reverse "
                >
                  <Pagination>
                    <Pagination.Prev onClick={() => PreviousPagination()} />
                    {PaginationItems()}
                    <Pagination.Next onClick={() => NextPagination()} />
                  </Pagination>
                </Col>
              </Row>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default ViewEnterpriseRequests;
