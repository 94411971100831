import React, { useEffect, useState } from "react";
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	Dropdown,
	Button,
} from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import Pagination from "react-bootstrap/Pagination";
import pending from "../../assets/Images/pending.svg";
import editing from "../../assets/Images/editing.svg";
import revision from "../../assets/Images/revision.svg";
import total from "../../assets/Images/total.svg";
import bgtotal from "../../assets/Images/bg-total.svg";
import coordinating from "../../assets/Images/coordinating.svg";
import quality from "../../assets/Images/quality.svg";

import nike from "../../assets/Images/nike.svg";
// import Button from "react-bootstrap/Button";

import NoDataFound from "../../components/NoDataFound";

import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";

import { Link } from "react-router-dom";
import { EDIT_CUSTOMER_PAGE } from "./../../config/routes";
import {
	VIEW_CUSTOMER,
	VIEW_CUSTOMER_ORDER_OVERVIEW,
} from "./../../config/apiRoutes.api";

const Customeroverview = ({ id, setCompanyNameDetails }) => {
	const token = sessionStorage.getItem("token");
	const request = client(token);

	const [customerId, setCustomerId] = useState("");
	const [name, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [companyAddress, setCompanyAddress] = useState("");
	const [companyWesite, setCompanyWebsite] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [is_active, setIsActive] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [customerType, setCustomerType] = useState("");
	const [userName, setUserName] = useState("");
	const [orderOverviews, setOrderOverviews] = useState([]);
	const [orderOverviewRows, setOrderOverviewRows] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalInPipeline, setTotalInPipeline] = useState(1);
	const [totalPending, setTotalPending] = useState(1);
	const [totalCoordinating, setTotalCoordinating] = useState(1);
	const [totalEditing, setTotalEditing] = useState(1);
	const [totalQc, setTotalQc] = useState(1);
	const [totalRevision, setTotalRevision] = useState(1);
	useEffect(() => {
		if (token !== null) {
			request
				.get(apiURL + VIEW_CUSTOMER + `/${id}`)
				.then((response) => {
					console.log("res", response);
					setCompanyNameDetails(response.data.customer.company_name);
					setIsActive(response.data.customer.is_active);
					setUserName(response.data.customer.company.username);
					setCustomerId(response.data.customer.id);
					setFullName(response.data.customer.full_name);
					setEmail(response.data.customer.email);
					setCompanyName(response.data.customer.company_name);
					setCompanyAddress(response.data.customer.company_address);
					setCompanyWebsite(response.data.customer.company_website);
					setPhoneNumber(response.data.customer.phone);
					setCity(response.data.customer.company.city);
					setCountry(response.data.customer.company.country);
					setZipCode(response.data.customer.company.zip_code);
					setCustomerType(response.data.customer.customer_type);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token !== null) {
			request
				.get(
					apiURL +
						VIEW_CUSTOMER_ORDER_OVERVIEW +
						`/${id}` +
						`?page=${pageNumber}`
				)
				.then((response) => {
					console.log("res", response);
					setOrderOverviews(response.data.customerOrderOverview);
					setOrderOverviewRows(response.data.customerOrderOverview.data);
					setTotalInPipeline(response.data.total_in_pipeline_count);
					setTotalPending(response.data.total_pending_count);
					setTotalCoordinating(response.data.total_coordinating_count);
					setTotalEditing(response.data.total_editing_count);
					setTotalQc(response.data.total_qc_count);
					setTotalRevision(response.data.total_revision_count);
				})
				.catch((err) => console.log(err));
		}
	}, [pageNumber]);
	//Paginations

	const PaginationItems = () => {
		const { total, per_page, current_page } = orderOverviews;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};

	const handleNextPagination = () => {
		if (
			pageNumber !== Math.ceil(orderOverviews.total / orderOverviews.per_page)
		) {
			setPageNumber(pageNumber + 1);
		}
	};
	const handlePreviousPagination = () => {
		if (
			pageNumber !== Math.ceil(orderOverviews.total / orderOverviews.per_page)
		) {
			setPageNumber(pageNumber - 1);
		}
		setPageNumber(pageNumber - 1);
	}; //end of Paginations

	const products = [
		{
			id: "1",
			OrderNo: "0000054",
			OrderDate: "19 Aug 2022",
			TotalPipeline: "750",
			Pending: "543",
			Coordinating: "137",
			Editing: "105",
			QualityControl: "175",
			Revision: "10",
			Status: <p style={{ color: "#F1A100" }}>Processing</p>,
		},
		{
			id: "2",
			OrderNo: "0000054",
			OrderDate: "19 Aug 2022",
			TotalPipeline: "750",
			Pending: "543",
			Coordinating: "137",
			Editing: "105",
			QualityControl: "175",
			Revision: "10",
			Status: <p style={{ color: "#F1A100" }}>Processing</p>,
		},
		{
			id: "3",
			OrderNo: "0000054",
			OrderDate: "19 Aug 2022",
			TotalPipeline: "750",
			Pending: "543",
			Coordinating: "137",
			Editing: "105",
			QualityControl: "175",
			Revision: "10",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			id: "4",
			OrderNo: "0000054",
			OrderDate: "19 Aug 2022",
			TotalPipeline: "750",
			Pending: "543",
			Coordinating: "137",
			Editing: "105",
			QualityControl: "175",
			Revision: "10",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
		{
			id: "5",
			OrderNo: "0000054",
			OrderDate: "19 Aug 2022",
			TotalPipeline: "750",
			Pending: "543",
			Coordinating: "137",
			Editing: "105",
			QualityControl: "175",
			Revision: "10",
			Status: <p style={{ color: "#C71515" }}>Pending</p>,
		},
	];

	const columns = [
		{
			dataField: "OrderNo",
			text: "Order No.",
			number: "1",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "OrderDate",
			text: "Order Date",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "TotalPipeline",
			text: "Total in Pipeline",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Pending",
			text: "Pending",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Coordinating",
			text: "Coordinating",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Editing",
			text: "Editing",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "QualityControl",
			text: "Quality Control",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Revision",
			text: "Revision",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
		{
			dataField: "Status",
			text: "Status",
			headerStyle: (colum, colIndex) => {
				return { width: "145px", textAlign: "left" };
			},
		},
	];

	return (
		<>
			<Row>
				<Col sm={3} className="custom-scroll-bar">
					<div className="customerdetailtable">
						<div className="uploadimg">
							<img src={nike} className="img-fluid d-block mx-auto" />
							<p className="headname">{companyName}</p>
							<p className="idname">{customerId}</p>
							<div className="edit">
								<i className="fa fa-edit"></i>
							</div>
						</div>

						<div className="profile-table mb-4 mt-4">
							<ul className="profile-details-list">
								<li>
									<div className="titletext">Customer ID:</div>
									<div className="titlecontent">{customerId}</div>
								</li>
								<li>
									<div className="titletext">Username:</div>
									<div className="titlecontent">{userName}</div>
								</li>
								<li>
									<div className="titletext">Customer</div>
									<div className="titlecontent">{companyName}</div>
								</li>
								<li>
									<div className="titletext">Address</div>
									<div className="titlecontent">{companyAddress}</div>
								</li>
								<li>
									<div className="titletext">Phone No.</div>
									<div className="titlecontent">{phoneNumber}</div>
								</li>
								<li>
									<div className="titletext">Email</div>
									<div className="titlecontent">{email}</div>
								</li>
								<li>
									<div className="titletext">Status</div>
									<div className="titlecontent">
										{is_active === "1" ? (
											<div className="active-staff">Active</div>
										) : (
											<div>Inactive</div>
										)}
									</div>
								</li>
								<li>
									<div className="titletext">Staff</div>
									<div className="titlecontent">{name}(staff)</div>
								</li>
							</ul>
						</div>
						<div className="text-right">
							<Link to={`${EDIT_CUSTOMER_PAGE}/${id}`}>
								<Button variant="primary">
									<span>
										<i className="fa fa-edit mr-3"></i>
									</span>
									Edit
								</Button>{" "}
							</Link>
						</div>
					</div>
				</Col>
				<Col sm={9} className="custom-scroll-bar ">
					<h4 className="mb-4 mt-4">Current Production Pipeline</h4>
					<div className="d-sm-flex flex-sm-wrap overviewtab mb-4">
						<Card className="bg-card">
							<Card.Img src={bgtotal} alt="Card image" />

							<Card.ImgOverlay>
								<Card.Img variant="top" src={total} />
								<Card.Body>
									<Card.Title>Total in Pipeline</Card.Title>
									<Card.Text>{totalInPipeline}</Card.Text>
								</Card.Body>
							</Card.ImgOverlay>
						</Card>

						<Card>
							<Card.Img variant="top" src={pending} />
							<Card.Body>
								<Card.Title>Pending</Card.Title>
								<Card.Text>{totalPending}</Card.Text>
							</Card.Body>
						</Card>
						<Card>
							<Card.Img variant="top" src={coordinating} />
							<Card.Body>
								<Card.Title>Coordinating</Card.Title>
								<Card.Text>{totalCoordinating}</Card.Text>
							</Card.Body>
						</Card>
						<Card>
							<Card.Img variant="top" src={editing} />
							<Card.Body>
								<Card.Title>Editing</Card.Title>
								<Card.Text>{totalEditing}</Card.Text>
							</Card.Body>
						</Card>

						<Card>
							<Card.Img variant="top" src={quality} />
							<Card.Body>
								<Card.Title>Quality Control</Card.Title>
								<Card.Text>{totalQc}</Card.Text>
							</Card.Body>
						</Card>
						<Card>
							<Card.Img variant="top" src={revision} />
							<Card.Body>
								<Card.Title>Revision</Card.Title>
								<Card.Text>{totalRevision}</Card.Text>
							</Card.Body>
						</Card>
					</div>

					<div
						className={`table-responsive overviewtables table-b`}
						style={{ marginTop: "3rem !important" }}
					>
						<Table hover>
							<thead>
								<tr>
									{typeof orderOverviews.data !== "undefined" &&
										orderOverviews.data.length !== 0 && (
											<>
												<th>Order No.</th>
												<th>Order Date</th>
												<th>Total in Pipeline</th>
												<th>Pending</th>
												<th>Coordinating</th>
												<th>Editing</th>
												<th>Quality Control</th>
												<th>Revision</th>
												<th>Status</th>
											</>
										)}
								</tr>
							</thead>
							<tbody>
								{orderOverviews.data !== undefined &&
									orderOverviewRows.map((item, index) => {
										const list = (
											<>
												<tr key={index}>
													<td>{item.order_number}</td>
													<td>
														{new Date(item.created_at).toLocaleDateString()}
													</td>
													<td>{item.total_in_pipeline}</td>
													<td>{item.pending}</td>
													<td>{item.coordinating}</td>
													<td>{item.editing}</td>
													<td>{item.qc}</td>
													<td>{item.revision}</td>

													<td>{item.status}</td>
												</tr>
											</>
										);
										return list;
									})}
								{typeof orderOverviews.data !== "undefined" &&
									orderOverviews.data.length === 0 && <NoDataFound />}
							</tbody>
						</Table>
					</div>

					<Row className="mt-4">
						<Col md={{ span: 4, offset: 8 }}>
							{
								<Pagination>
									<Pagination.Prev onClick={() => handlePreviousPagination()} />
									{PaginationItems()}
									<Pagination.Next onClick={() => handleNextPagination()} />
								</Pagination>
							}
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default Customeroverview;
