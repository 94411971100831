import React from "react";
import Sidebar from "../../../components/global/Sidebar/Sidebar";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import staffdashboard from "../../../assets/Images/staff-dashboard.jpg";
import Card from "react-bootstrap/Card";
import errorcount from "../../../assets/Images/error-count1.png";
import productionscore from "../../../assets/Images/production-score1.png";
import efficiencyscore from "../../../assets/Images/efficiency-score1.png";
import errorrate from "../../../assets/Images/error-rate1.png";
import imagecount from "../../../assets/Images/image-count1.png";
const SuperVisorCoordinatordashboard = () => {
  const choosetype = [
    { label: "This Month", value: 355 },
    { label: "This Month", value: 54 },
  ];
  const EfficiencyScore = [
    { label: "Efficiency Score", value: 355 },
    { label: "Efficiency Score", value: 54 },
  ];

  const products = [
    {
      BatchNo: "0000081",
      BatchDate: "22 Aug 2022",
      Customer: "Nike, Inc.",
      OrderNo: "0000081",
      Type: "Redo",
      ImageCount: "65",
      Status: <p style={{ color: "#F1A100" }}>Processing</p>,
    },
    {
      BatchNo: "0000081",
      BatchDate: "22 Aug 2022",
      Customer: "Nike, Inc.",
      OrderNo: "0000081",
      Type: "Redo",
      ImageCount: "65",
      Status: <p style={{ color: "#F1A100" }}>Processing</p>,
    },
    {
      BatchNo: "0000081",
      BatchDate: "22 Aug 2022",
      Customer: "Nike, Inc.",
      OrderNo: "0000081",
      Type: "New",
      ImageCount: "65",
      Status: <p style={{ color: "#008E1A" }}>Completed</p>,
    },
    {
      BatchNo: "0000081",
      BatchDate: "22 Aug 2022",
      Customer: "Nike, Inc.",
      OrderNo: "0000081",
      Type: "New",
      ImageCount: "65",
      Status: <p style={{ color: "#008E1A" }}>Completed</p>,
    },
  ];

  const columns = [
    {
      dataField: "BatchNo",
      text: "Batch No",
      number: "1",

      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "BatchDate",
      text: "Batch Date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "Customer",
      text: "Customer",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "OrderNo",
      text: "Order No.",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "Type",
      text: "Type",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "ImageCount",
      text: "Image Count",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
    {
      dataField: "Status",
      text: "Status",
      headerStyle: (colum, colIndex) => {
        return { width: "145px", textAlign: "left" };
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  // <BootstrapTable
  //   keyField="id"
  //   data={products}
  //   columns={columns}
  //   striped
  //   hover
  //   condensed
  //   defaultSortDirection="asc"
  // />;
  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />

        <div className="main-panel ">
          <div className="rightbar pr-11">
            <div className="section-title">
              <h2>Dashboard</h2>
              <h4 class="mb-4 mt-4">Performance Summary</h4>
            </div>

            <Row>
              <Col lg={2} sm={12}>
                <Form>
                  <div className="form-group">
                    <Select
                      options={choosetype}
                      defaultValue={{ label: "This Month", value: 355 }}
                    />
                  </div>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col lg={6} sm={12}>
                <div className="d-sm-flex flex-sm-wrap justify-content-between coordinator-dashboard">
                  <Card>
                    <Card.Img variant="top" src={productionscore} />
                    <Card.Body>
                      <Card.Title>Production Score</Card.Title>
                      <Card.Text>75.9</Card.Text>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Img variant="top" src={efficiencyscore} />
                    <Card.Body>
                      <Card.Title>Efficiency Score</Card.Title>
                      <Card.Text>1.02%</Card.Text>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Img variant="top" src={errorrate} />

                    <Card.Body>
                      <Card.Title>Error Rate</Card.Title>
                      <Card.Text>85.2</Card.Text>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Img variant="top" src={imagecount} />
                    <Card.Body>
                      <Card.Title>Image Count</Card.Title>
                      <Card.Text>1247</Card.Text>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Img variant="top" src={errorcount} />
                    <Card.Body>
                      <Card.Title>Error Count</Card.Title>

                      <Card.Text>54</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card border="light" className="detailscard">
                    <Card.Body>
                      <Card.Text>
                        <p>View Details</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={6} sm={12} className="pl-0">
                <div class="eficiency-box">
                  <img
                    src={staffdashboard}
                    class="img-fluid mx-auto d-block"
                    alt="staffdashboard"
                  />
                  <Row className="justify-content-center">
                    <Col sm={8}>
                      <Form className="mt-4">
                        <div className="form-group">
                          <Select
                            options={EfficiencyScore}
                            defaultValue={{
                              label: "Efficiency Score",
                              value: 355,
                            }}
                          />
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <div className="section-title">
              <h4 class="mb-3 mt-2">Workspace Queue</h4>
            </div>

            <div className="table-responsive table-b overviewtables mb-3">
              <BootstrapTable keyField="id" data={products} columns={columns} />
            </div>

            <Row className="mb-5 text-right">
              <Col md={{ span: 2, offset: 9 }}>
                {<p className="viewbntn">View all</p>}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperVisorCoordinatordashboard;
