import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";

import Card from "react-bootstrap/Card";
import {
	Pagination,
	Form,
	Row,
	Col,
	Table,
	Dropdown,
	Button,
} from "react-bootstrap";

import pending from "../../assets/Images/pending.svg";
import approve from "../../assets/Images/approve.svg";
import editing from "../../assets/Images/editing.svg";
import revision from "../../assets/Images/revision.svg";
import total from "../../assets/Images/total.svg";
import bgtotal from "../../assets/Images/bg-total.svg";
import coordinating from "../../assets/Images/coordinating.svg";
import quality from "../../assets/Images/quality.svg";

import profile from "./../../assets/Images/user/no-profile.png";
import Warning from "./../../assets/Images/QCIcons/Warning.svg";

import nike from "../../assets/Images/nike.svg";

import { hasUserRole } from "../../utils/ValideRoleUser";

import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";

import { Link } from "react-router-dom";
import { EDIT_STAFF_USER_PAGE } from "./../../config/routes";
import {
	VIEW_EDIT_STAFF,
	VIEW_STAFF_DETAILS,
} from "./../../config/apiRoutes.api";
import NoDataFound from "./Components/NoDataFound";
import Loader from "./../../components/Loader";

const Customeroverview = ({ id, setCompanyNameDetails }) => {
	const route = { role: "department-manager" }; // Defined Role for hasUserRole
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const [loader, setLoader] = useState(false);
	const [staffId, setStaffId] = useState("");
	const [image_url, setImageUrl] = useState("");
	const [name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [companyAddress, setCompanyAddress] = useState("");
	const [companyWesite, setCompanyWebsite] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [is_active, setIsActive] = useState("");
	const [viewStaff, setViewStaff] = useState({});
	const [orderDataStaff, setOrderDataStaff] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);

	const [staffData, setStaffData] = useState([]);
	useEffect(() => {
		if (token !== null) {
			setLoader(true);
			request
				.get(apiURL + VIEW_EDIT_STAFF + `/${id}`)
				.then((response) => {
					setLoader(false);
					console.log("res", response.data, response.data.staff.id);
					setImageUrl(response.data.image_url);
					setCompanyNameDetails(
						response.data.staff.first_name +
							"   " +
							response.data.staff.last_name
					);
					setIsActive(response.data.staff.is_active);
					setStaffId(response.data.staff.id);
					setFirstName(response.data.staff.first_name);
					setLastName(response.data.staff.last_name);
					setEmail(response.data.staff.email);
					// setCompanyName(response.data.customer.company_name);
					setCompanyAddress(response.data.staff.address);
					// setCompanyWebsite(response.data.customer.company_website);
					setPhoneNumber(response.data.staff.phone_number);
					// setCity(response.data.customer.company.city);
					// setCountry(response.data.customer.company.country);
					// setZipCode(response.data.customer.company.zip_code);
					// setCustomerType(response.data.customer.customer_type);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (!token) {
			return;
		}

		setLoader(true);
		request
			.get(apiURL + VIEW_STAFF_DETAILS + `/${id}`)
			.then((response) => {
				console.log("res", response.data[0]);
				setStaffData(response.data[0]);
				setLoader(false);
				setViewStaff(response.data[0].staff);
				setOrderDataStaff(response.data[0].orderData);
			})
			.catch((err) => console.log(err));
	}, []);
	const PaginationItems = () => {
		const { total, per_page, current_page } = orderDataStaff;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};

	const handleNextPagination = () => {
		if (
			pageNumber !== Math.ceil(orderDataStaff.total / orderDataStaff.per_page)
		) {
			setPageNumber(pageNumber + 1);
		}
	};
	const handlePreviousPagination = () => {
		if (
			pageNumber !== Math.ceil(orderDataStaff.total / orderDataStaff.per_page)
		) {
			setPageNumber(pageNumber - 1);
		}
		setPageNumber(pageNumber - 1);
	}; //end of Paginations

	return (
		<>
			<Row>
				<Col sm={3} className="custom-scroll-bar">
					<div className="customerdetailtable">
						<div className="uploadimg">
							<img
								src={
									image_url.includes("/profile-image-round.jpg")
										? profile
										: image_url
								}
								className="img-fluid d-block mx-auto"
							/>

							<div className="edit">
								<i className="fa fa-edit"></i>
							</div>
						</div>

						<div className="profile-table mb-4 mt-4">
							<ul className="profile-details-list">
								<li>
									<div className="titletext">Staff ID:</div>
									<div className="titlecontent">{staffId}</div>
								</li>
								<li>
									<div className="titletext">Customer</div>
									<div className="titlecontent">
										{name}
										{last_name}
									</div>
								</li>
								<li>
									<div className="titletext">Address</div>
									<div className="titlecontent">{companyAddress}</div>
								</li>
								<li>
									<div className="titletext">Phone No.</div>
									<div className="titlecontent">{phoneNumber}</div>
								</li>
								<li>
									<div className="titletext">Email</div>
									<div className="titlecontent">{email}</div>
								</li>
								<li>
									<div className="titletext">Status</div>
									<div className="titlecontent">
										{is_active === "1" ? (
											<div className="active-staff">Active</div>
										) : (
											<div>Inactive</div>
										)}
									</div>
								</li>
								<li>
									<div className="titletext">Staff</div>
									<div className="titlecontent">{name}(staff)</div>
								</li>
							</ul>
						</div>
						<div className="text-right">
							<Link to={`${EDIT_STAFF_USER_PAGE}/${id}`}>
								<Button variant="primary">
									<span>
										<i className="fa fa-edit mr-3"></i>
									</span>
									Edit
								</Button>{" "}
							</Link>
						</div>
					</div>
				</Col>
				<Col sm={9} className="custom-scroll-bar ">
					<h4 className="mb-4 mt-4">Current Production Pipeline</h4>
					<div className="d-sm-flex flex-sm-wrap overviewtab mb-4">
						{loader ? (
							<Loader />
						) : (
							<>
								{viewStaff.user_type === "image-coordinator" && (
									<>
										<Card className="bg-card">
											<Card.Img src={bgtotal} alt="Card image" />
											<Card.ImgOverlay>
												<Card.Img variant="top" src={total} />
												<Card.Body>
													<Card.Title>Total in Pipeline</Card.Title>

													<Card.Text>
														{staffData !== undefined &&
															staffData.total_in_queue_count}
													</Card.Text>
												</Card.Body>
											</Card.ImgOverlay>
										</Card>

										<Card>
											<Card.Img variant="top" src={revision} />
											<Card.Body>
												<Card.Title>Revision</Card.Title>
												<Card.Text>
													{staffData !== undefined && staffData.total_revision}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={coordinating} />
											<Card.Body>
												<Card.Title>Coordinating</Card.Title>
												<Card.Text>
													{staffData !== undefined &&
														staffData.total_coordinating_count}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={editing} />
											<Card.Body>
												<Card.Title>Editing</Card.Title>
												<Card.Text>
													{staffData !== undefined &&
														staffData.total_editing_count}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={quality} />
											<Card.Body>
												<Card.Title>Quality Control</Card.Title>
												<Card.Text>
													{staffData !== undefined && staffData.total_qc_count}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={approve} />
											<Card.Body>
												<Card.Title>Completed</Card.Title>
												<Card.Text>
													{staffData !== undefined && staffData.total_completed}
												</Card.Text>
											</Card.Body>
										</Card>
									</>
								)}

								{viewStaff.user_type === "editor" && (
									<>
										<Card className="bg-card">
											<Card.Img src={bgtotal} alt="Card image" />
											<Card.ImgOverlay>
												<Card.Img variant="top" src={total} />
												<Card.Body>
													<Card.Title>Total in Pipeline</Card.Title>

													<Card.Text>
														{staffData !== undefined &&
															staffData.total_in_queue_count}
													</Card.Text>
												</Card.Body>
											</Card.ImgOverlay>
										</Card>

										<Card>
											<Card.Img variant="top" src={revision} />
											<Card.Body>
												<Card.Title>Revision</Card.Title>
												<Card.Text>
													{staffData !== undefined && staffData.total_revision}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={editing} />
											<Card.Body>
												<Card.Title>Editing</Card.Title>
												<Card.Text>
													{staffData !== undefined &&
														staffData.total_editing_count}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={quality} />
											<Card.Body>
												<Card.Title>Quality Control</Card.Title>
												<Card.Text>
													{staffData !== undefined && staffData.total_qc_count}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={approve} />
											<Card.Body>
												<Card.Title>Completed</Card.Title>
												<Card.Text>
													{staffData !== undefined && staffData.total_completed}
												</Card.Text>
											</Card.Body>
										</Card>
									</>
								)}

								{viewStaff.user_type === "qc" && (
									<>
										<Card className="bg-card">
											<Card.Img src={bgtotal} alt="Card image" />
											<Card.ImgOverlay>
												<Card.Img variant="top" src={total} />
												<Card.Body>
													<Card.Title>Total in Pipeline</Card.Title>

													<Card.Text>
														{staffData !== undefined &&
															staffData.total_in_queue_count}
													</Card.Text>
												</Card.Body>
											</Card.ImgOverlay>
										</Card>

										<Card>
											<Card.Img variant="top" src={revision} />
											<Card.Body>
												<Card.Title>Revision</Card.Title>
												<Card.Text>
													{staffData !== undefined && staffData.total_revision}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={quality} />
											<Card.Body>
												<Card.Title>Quality Control</Card.Title>
												<Card.Text>
													{staffData !== undefined && staffData.total_qc_count}
												</Card.Text>
											</Card.Body>
										</Card>
										<Card>
											<Card.Img variant="top" src={approve} />
											<Card.Body>
												<Card.Title>Completed</Card.Title>
												<Card.Text>
													{staffData !== undefined &&
														staffData.total_completed_count}
												</Card.Text>
											</Card.Body>
										</Card>
									</>
								)}
							</>
						)}
					</div>
					<div
						className={`table-responsive overviewtables table-b`}
						style={{ marginTop: "3rem !important" }}
					>
						<Table hover>
							<thead>
								<tr>
									{loader ? (
										<Loader />
									) : (
										<>
											<th>Batch No.</th>
											<th>Batch Date</th>
											<th>Customer</th>
											<th>Order No.</th>
											<th>Type</th>
											<th>Total in Pipeline</th>
											<th>Revision</th>
											{viewStaff.user_type === "image-coordinator" && (
												<th>Coordinating</th>
											)}
											{viewStaff.user_type === "editor" && <th>Editing</th>}
											{viewStaff.user_type === "qc" && <th>Quality Control</th>}
											<th>Status</th>
										</>
									)}
								</tr>
							</thead>
							<tbody>
								{orderDataStaff.data !== undefined &&
									orderDataStaff.data.map((item, index) => {
										const list = (
											<tr>
												<td>{item.batch_number_id}</td>
												<td>
													{new Date(item.created_at).toLocaleDateString()}
												</td>
												<td>{item.customer.full_name}</td>
												<td>{item.order.order_number}</td>
												<td>{item.order.image_upload_type}</td>
												<td>{item.in_queue}</td>
												<td>{item.revision}</td>
												{viewStaff.user_type === "editor" && (
													<td>{item.editting}</td>
												)}
												{viewStaff.user_type === "image-coordinator" && (
													<td>{item.coordinating}</td>
												)}
												{viewStaff.user_type === "qc" && <td>{item.in_qc}</td>}

												<td>
													<span
														className={
															item.order.status === "Pending"
																? "order-status"
																: item.order.status === "Successfull"
																? "order--success"
																: ""
														}
													>
														{item.order.status}
													</span>
												</td>
											</tr>
										);
										return list;
									})}
							</tbody>
						</Table>
						{orderDataStaff.data === undefined && !loader && <NoDataFound />}
					</div>

					<Row className="mt-4">
						<Col md={{ span: 4, offset: 8 }}>
							{
								<Pagination>
									<Pagination.Prev onClick={() => handlePreviousPagination()} />
									{PaginationItems()}
									<Pagination.Next onClick={() => handleNextPagination()} />
								</Pagination>
							}
						</Col>
					</Row>

					{/* <div className="table-responsive overviewtables table-b">
						<BootstrapTable keyField="id" data={products} columns={columns} />
					</div>
					<Row className="mt-4">
						<Col md={{ span: 4, offset: 8 }}>
							{
								<Pagination>
									<Pagination.Prev />
									<Pagination.Item active>{1}</Pagination.Item>
									<Pagination.Item>{2}</Pagination.Item>
									<Pagination.Item>{3}</Pagination.Item>
									<Pagination.Item>{4}</Pagination.Item>
									<Pagination.Item>{5}</Pagination.Item>
									<Pagination.Item>{6}</Pagination.Item>
									<Pagination.Next />
								</Pagination>
							}
						</Col>
					</Row> */}
				</Col>
			</Row>
		</>
	);
};

export default Customeroverview;
