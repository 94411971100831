export function capitalizeEachWord(text) {
	if (!text || typeof text !== "string") {
		return ""; // Return empty string if input is null, undefined, or not a string
	}

	// Remove hyphens and capitalize each word
	return text
		.replace(/\b\w/g, function (char) {
			return char.toUpperCase();
		})
		.replace(/-/g, " ");
}
