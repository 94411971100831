import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import MyVerticallyCenteredModal from "../../components/Modal";
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import { ADMIN_ADD_BRANCHE } from "./../../config/apiRoutes.api";
import GeoCodeApi from "../../components/GeoCodeLocationSearch";
import { VIEW_BRANCHE_PAGE } from "../../config/routes";

const AddBranch = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [description, setDescription] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [addResponse, setAddResponse] = useState("");
  const bodyFormData = new FormData();
  const token = sessionStorage.getItem("token");

  const handleSubmit = (event) => {
    if (token) {
      const request = client(token);
      event.preventDefault();
      bodyFormData.append("name", name);
      bodyFormData.append("location", location);
      bodyFormData.append("description", description);
      bodyFormData.append("time_zone", timeZone);
      request
        .post(apiURL + ADMIN_ADD_BRANCHE, bodyFormData)
        .then((response) => {
          console.log(response);
          setAddResponse(response.data.message);
          setModalShow(true);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      <div>
        <div className="rightbar pr-11">
          <div className="section-title">
            <h2>Add Branch</h2>
          </div>
          <Row>
            <Col sm={9} lg={5}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="form-group">
                    <Row>
                      <Form.Label className="col-sm-3">Name</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          autoFocus
                          autocomplete="off"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Form.Label className="col-sm-3">Location</Form.Label>
                      <Col sm={9}>
                        <GeoCodeApi
                          location={location}
                          setLocation={setLocation}
                          setTimeZone={setTimeZone}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <Row>
                      <Form.Label className="col-sm-3">Timezone</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          autoFocus
                          value={timeZone}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group ">
                    <Row>
                      <Form.Label className="col-sm-3">Description </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          as="textarea"
                          placeholder="Write Description..."
                          style={{ height: "148px" }}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group">
                    <div className="d-sm-flex justify-content-between btn-cls flex-row--reverse">
                      <div className="">
                        <Button
                          variant=""
                          className="puple btns mr-3"
                          type="submit"
                          onClick={() => navigate(VIEW_BRANCHE_PAGE)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="btns"
                          type="submit"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          navigate(VIEW_BRANCHE_PAGE);
        }}
        heading={"Success"}
        message={addResponse}
        className="modal-body-body--success"
      />
    </>
  );
};

export default AddBranch;
