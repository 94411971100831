import React, { useRef, useEffect, useState } from "react";

export function useOutsideAlerter(
	wrapper,
	setMultipleImagesId,

	setClickSelect,

	images,
	setCtrlClickedSelect,
	setSelectedIndexes,
	setDragAndSelectEnabled,
	setClickSelectOutside
) {
	// const [isExcluded, setIsExcluded] = useState(false);
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				wrapper.current &&
				!wrapper.current.contains(event.target) &&
				images.length
			) {
				// Exclude specific div elements from triggering the action
				const excludedDivs = document.querySelectorAll(".exclude-click");
				let isExcluded = false;

				excludedDivs.forEach((div) => {
					if (div.contains(event.target)) {
						// setIsExcluded(true);
						isExcluded = true;
					}
				});

				if (!isExcluded) {
					setMultipleImagesId([]);

					setClickSelect(false);
					setSelectedIndexes([]);
					setDragAndSelectEnabled(true);
					setClickSelectOutside(false);
					setCtrlClickedSelect(false);
				}
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			setCtrlClickedSelect(false);
		};
	}, [wrapper, images, setMultipleImagesId]);
}
