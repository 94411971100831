import React, { useState, useEffect, useRef, useCallback } from "react";

//Importing the bootstrap components
import { Button, Row, Col, Form, Tab, Tabs } from "react-bootstrap";
//Importing the react select components
import Select from "react-select";
import Marker from "./Marker";
import Loader from "./../../../../components/Loader";

//Importing the images
import PreviousIcon from "./../../../../assets/Images/coordinator_actions_icon/prev-icons.svg";
import NextIcon from "./../../../../assets/Images/coordinator_actions_icon/next-icons.svg";

import ChatIcons from "./../../../../assets/Images/coordinator_actions_icon/Chat.svg";
import BlueChatIcon from "./../../../../assets/Images/coordinator_actions_icon/BlueChatIcon.svg";
import CancelIcons from "./../../../../assets/Images/coordinator_actions_icon/cancel_image.svg";
import Send from "./../../../../assets/Images/coordinator_actions_icon/Send.svg";
import ArrowDown from "./../../../../assets/Images/coordinator_actions_icon/White_Arrow_Down.svg";

// Importing API related files and routes

import { client } from "./../../../../data/API/GobalClientAxiosRequest";
import { imageURL, apiURL } from "../../../../data/filepath";

import {
	CO_ORDINATOR_VIEW_PROFILE_SETTINGS,
	CO_ORDINATOR_VIEW_EDIT_GROUP,
	CO_ORDINATOR_UPDATE_EDIT_GROUP,
	CO_ORDINATOR_DUPLICATE_IMAGE_RENAME,
	CO_ORDINATOR_DELETE_DUPLICATE_IMAGE,
	CO_ORDINATOR_ORIGINAL_IMAGE_RENAME,
	ADMIN_GET_IMAGES_SETTINGS,
	CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID,
} from "./../../../../config/apiRoutes.api";

const Modal = ({
	showModal,
	setShowModal,
	image_details_view,
	setImageDetailsView,
	editGroups,
	editTypes,
	customer_id,
	setMultipleImagesId,
	selectImageName,
	token,
	id,
	idSelected,
	setClickSelect,
	setSelectAll,
	customer_status,
	images,
	modalRef,
	setting_profile,
	handleFetchProfileSettings,
	setFolderListName,
	setFolderSelect,
	setFoldersPath,
	setDuplicateImageModal,
	setDuplicateAmount,
	setAmount,
	setImageID,
	imageID,
}) => {
	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);
	const modalRefImage = useRef(null);

	//Declarations of state
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");
	const [loader, setLoader] = useState(false); //Loader State
	const [groupSelect, setGroupSelect] = useState(false);
	const [checked, setChecked] = useState(false);
	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [checked3, setChecked3] = useState(false);
	const [selected, setSelected] = useState(false);
	const [selected1, setSelected1] = useState(false);
	const [selected2, setSelected2] = useState(false);
	const [selected3, setSelected3] = useState(false);
	const [edit_select, setEditorType] = useState("");
	const [edit_id, setEditId] = useState([]);
	const [currentAnnotation, setCurrentAnnotation] = useState(null);
	const [annotations, setAnnotations] = useState([]);
	const [edit_type_toggle, setToggleEditType] = useState(false);
	//To update the select group
	const [group_name_select, setEditGroupNameSelect] = useState("");
	const [imageExtensions, setImageExtensions] = useState("");
	const [imageName, setImageName] = useState("");
	// To set the state for add
	const [background_removal_color_type, setBackgroundRemovalColorType] =
		useState("");
	const [background_removal_color_code, setBackgroundRemovalColorCode] =
		useState("");
	const [background_adjustment_color_code, setBackgroundAdjustmentColorCode] =
		useState("");
	const [background_adjustment_color_type, setBackgroundAdjustmentColorType] =
		useState("");
	const [view_edit_group, setViewEditGroup] = useState({});
	const [group_name, setGroupName] = useState("");
	const [new_edit_group_name, setNewEditGroupName] = useState("");
	const [toggleMessage, setToggleMessage] = useState(false);
	// const [edit_select, setEditorType] = useState("");
	// const [edit_group_name_select, setEditGroupNameSelect] = useState("");
	const [resizing_file_format, setResizingFileFormat] = useState("");
	const [resizing_height, setResizingHeight] = useState("");
	const [resizing_width, setResizingWidth] = useState("");
	const [resizing_dpi, setResizingDpi] = useState("");
	const [resizing_dpi_type, setResizingDpiType] = useState("");
	const [resizing_color_mode, setResizingColorMode] = useState("");
	const [resizing_alignment, setResizingAlignment] = useState("");
	const [resizing_margin_format, setResizingMarginFormat] = useState("");
	const [resizing_margin_top, setResizingMarginTop] = useState("");
	const [resizing_margin_bottom, setResizingMarginBottom] = useState("");
	const [resizing_margin_right, setResizingMarginRight] = useState("");
	const [resizing_margin_left, setResizingMarginLeft] = useState("");
	const [edit_group_name, setEditGroupName] = useState("");
	const [edit_type_ids, setEditTypeId] = useState("");
	const [image_file_format, setImageFileFormat] = useState("");
	const [image_height, setImageHeight] = useState("");
	const [image_width, setImageWidth] = useState("");
	const [image_dpi, setImageDpi] = useState("");
	const [image_dpi_type, setImageDpiType] = useState("");
	const [image_color_mode, setImageColorMode] = useState("");
	const [image_alignment, setImageAlignment] = useState("");
	const [image_margin_format, setImageMarginFormat] = useState("");
	const [image_margin_top, setImageMarginTop] = useState("");
	const [image_margin_bottom, setImageMarginBottom] = useState("");
	const [image_margin_right, setImageMarginRight] = useState("");
	const [image_margin_left, setImageMarginLeft] = useState("");
	const [group, setGroup] = useState([]);
	const [message, setMessage] = useState("");
	const [comments, setComments] = useState([]);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [renameModalShow, setRenameModalShow] = useState(false);
	const [renameTextDuplicate, setRenameTextDuplicate] = useState("");
	const [renameId, setRenameId] = useState("");
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [hoveredItems, setHoveredItems] = useState(false);
	const [hoveredItemId, setHoveredItemId] = useState(null);
	const [showCommentList, setCommentsList] = useState(false);
	const [annotationsComments, setAnnotationsComments] = useState([]);
	const [view_image_settings, setViewImageSettings] = useState([]);

	const [viewDuplicateSettingsById, setViewDuplicateSettings] = useState({});
	const [viewDuplicateModal, setViewDuplicateModal] = useState(false);
	const [duplicateImageId, setDuplicateImageId] = useState("");
	const [fileFormatDuplicate, setFileFormatDuplicate] = useState("");
	const [dpiTypeDuplicate, setDpiTypeDuplicate] = useState("");
	const [colorModeDuplicate, setColorModeDuplicate] = useState("");
	const [alignModeDuplicate, setAlignModeDuplicate] = useState("");
	const [marginFormatDuplicate, setMarginFormatDuplicate] = useState("");
	const [heightDuplicate, setHeightDuplicate] = useState("");
	const [widthDuplicate, setWidthDuplicate] = useState("");
	const [dpiDuplicate, setDpiDuplicate] = useState("");
	const [marginTopDuplicate, setMarginTopDuplicate] = useState("");
	const [marginBottomDuplicate, setMarginBottomDuplicate] = useState("");
	const [marginRightDuplicate, setMarginRightDuplicate] = useState("");
	const [marginLeftDuplicate, setMarginLeftDuplicate] = useState("");

	/**
	 * Transform an array of customer edit types into an array of objects with label, value, and additional properties.
	 * @param {Array} customerEditTypes - Array of customer edit types.
	 * @returns {Array} - Transformed array of objects with label, value, and additional properties.
	 */
	const selectLabel = (customerEditTypes) =>
		customerEditTypes.map(({ group_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of array as label and value return a new array of object like [{value:"",label:""}]

	function LocalImageLoader({ src, width, quality }) {
		return <Loader />;
	}
	const file = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];
	const selectBackgrondRemoval = [
		{ label: "Custom", value: 355 },
		{ label: "Transparent", value: 54 },
	];
	const selectedAmount = [
		{ label: "1", value: 355 },
		{ label: "2", value: 54 },
		{ label: "3", value: 54 },
	];
	const dpi = [
		{ label: "JPEG", value: 355 },
		{ label: "PNG", value: 54 },
		{ label: "JPG", value: 54 },
	];

	const ColorMode = [
		{ label: "Original", value: 355 },
		{ label: "Edited", value: 54 },
	];
	const Align = [
		{ label: "center", value: 355 },
		{ label: "start", value: 54 },
		{ label: "end", value: 55 },
	];

	const Margin = [
		{ label: "pixel", value: 355 },
		{ label: "em", value: 54 },
		{ label: "rem", value: 55 },
		{ label: "%", value: 56 },
	];
	const customStyles = {
		placeholder: (provided) => ({
			// Custom styles for the placeholder text
			...provided,
			color: "#212529", // Change the placeholder text color to red
		}),
	};
	function getSelectComponent(options, setState, file) {
		return (
			<Select
				options={file}
				placeholder={options}
				styles={customStyles}
				onChange={(e) => setState(e.label)}
				isDisabled={true}
			/>
		);
	}

	const handleTextChange = (updatedText) => {
		if (currentAnnotation) {
			setCurrentAnnotation({
				...currentAnnotation,
				text: updatedText,
			});

			// Create a copy of annotations with the updated text
			const updatedAnnotations = annotations.map((annotation) =>
				annotation === currentAnnotation
					? { ...annotation, text: updatedText }
					: annotation
			);

			setAnnotations(updatedAnnotations);
		}
	};
	/**
	 * Handle the selection of checkboxes and update the `editId` state accordingly.
	 * @param {ChangeEvent} changeEvent - The change event object.
	 */
	const handleAddCheckboxSelect = (changeEvent) => {
		console.log("id", changeEvent);
		changeEvent.persist();
		setEditId((prevState) => {
			const editItem = changeEvent.target.value;
			console.log("changeEvent", changeEvent.target.value);

			if (prevState.includes(editItem)) {
				// If the selected editItem is already in the prevState array,
				// remove it from the array and return the updated array.
				return prevState.filter((el) => el !== editItem);
			}
			// If the selected editItem is not in the prevState array,
			// add it to the array and return the updated array.
			return [...prevState, editItem];
		});
	};
	/**
	 * Handle the checkbox state and perform actions based on the given id.
	 * @param {number} id - The id to determine the checkbox action.
	 */
	const handleBackGroundRemovalCheckbox = (id) => {
		if (id === 1) {
			setSelected((val) => !val);
			setChecked((val) => !val);
		}
		if (id === 2) {
			setSelected1((val) => !val);
			setChecked1((val) => !val);
		}
		if (id === 3) {
			setSelected2((val) => !val);
			setChecked2((val) => !val);
		}
		if (id === 15) {
			setSelected3((val) => !val);
			setChecked3((val) => !val);
		}
	};

	useEffect(() => {
		if (editGroups !== undefined) {
			// Create a new array by combining existing group values and the new object
			const updatedGroup = [
				...editGroups,
				{ id: "", group_name: "New Settings" },
			];
			setGroup(updatedGroup);
			setSelected(false);
			setSelected1(false);
			setSelected3(false);
		}
	}, [editGroups]);
	console.log("id seleted", image_details_view);
	useEffect(() => {
		// Fetch data from the API endpoint
		if (edit_select !== 0) {
			request
				.get(apiURL + CO_ORDINATOR_VIEW_EDIT_GROUP + `/${edit_select}`)
				.then((response) => {
					//Set View  for edit group from the response
					let data = response.data;
					console.log("call the edit type", data);
					setViewEditGroup(data);
					setBackgroundRemovalColorCode(
						data.edit_group.background_settings[0].color_code
					);
					setBackgroundAdjustmentColorCode(
						data.edit_group.background_settings[1].color_code
					);
					setBackgroundRemovalColorType(
						data.edit_group.background_settings[0] !== undefined
							? data.edit_group.background_settings[0].color_type
							: "Select Color Type"
					);
					setBackgroundAdjustmentColorType(
						data.edit_group.background_settings[1] !== undefined
							? data.edit_group.background_settings[1].color_type
							: "Select color type"
					);
					setResizingFileFormat(
						data.edit_group.resizing_settings[0].file_format !== undefined
							? data.edit_group.resizing_settings[0].file_format
							: "Select File Format"
					);
					setResizingDpiType(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].dpi_type
							: "Select File Format"
					);
					setResizingColorMode(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].color_mode
							: "Select File Format"
					);
					setResizingAlignment(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].alignment
							: "Select File Format"
					);
					setResizingMarginFormat(
						data.edit_group.resizing_settings[0] !== undefined
							? data.edit_group.resizing_settings[0].margin_format
							: "Select File Format"
					);
					setImageFileFormat(
						data.edit_group.setting.file_format !== undefined
							? data.edit_group.setting.file_format
							: "Select file format"
					);
					setImageDpiType(
						data.edit_group.setting.dpi_type !== null
							? data.edit_group.setting.dpi_type
							: "Select Dpi type"
					);
					setImageColorMode(
						data.edit_group.setting !== null
							? data.edit_group.setting.color_mode
							: "Select color mode"
					);
					setImageAlignment(
						data.edit_group.setting !== null
							? data.edit_group.setting.alignment
							: "Select Align"
					);
					setImageMarginFormat(
						data.edit_group.setting !== null
							? data.edit_group.setting.margin_format
							: "Margin"
					);
					if (data.edit_group.background_settings.length) {
						setSelected(true);
						setSelected1(true);
					}
					if (data.edit_group.resizing_settings.length) {
						setSelected2(true);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		} else {
			setViewEditGroup({});
			setBackgroundRemovalColorCode("");
			setBackgroundAdjustmentColorCode("");
			setBackgroundRemovalColorType("");
			setBackgroundAdjustmentColorType("");
			setResizingFileFormat("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setImageFileFormat("");
			setImageDpiType("");
		}
	}, [edit_select, token]);

	useEffect(() => {
		if (
			image_details_view !== undefined &&
			image_details_view.status !== undefined &&
			// image_details_view.status === "assign_to_editor" &&
			image_details_view.edit_group_id !== null
			// images !== undefined
		) {
			setEditorType(image_details_view.edit_group_id);
		} else {
			setViewEditGroup({});
			setBackgroundRemovalColorCode("");
			setBackgroundAdjustmentColorCode("");
			setBackgroundRemovalColorType("");
			setBackgroundAdjustmentColorType("");
			setResizingFileFormat("");
			setResizingDpiType("");
			setResizingColorMode("");
			setResizingAlignment("");
			setResizingMarginFormat("");
			setImageFileFormat("");
			setImageDpiType("");
		}
	}, [image_details_view, setEditorType, setViewEditGroup]);

	/**
	 * Create an array of checked values for the edit_types in view_edit_group
	 */
	function getEditTypesIds(view_edit_group) {
		if (
			view_edit_group &&
			view_edit_group.edit_group &&
			view_edit_group.edit_group.edit_types &&
			Array.isArray(view_edit_group.edit_group.edit_types)
		) {
			return view_edit_group.edit_group.edit_types.map((items, index) => {
				return items.id;
			});
		} else {
			// Handle the case where any of the properties is null or not an array
			// You can return a default value or handle the error appropriately.
			return []; // Returning an empty array as a default value
		}
	}

	// Usage
	let updateChecked = getEditTypesIds(view_edit_group);

	const handleMouseEnter = (itemId) => {
		// Set the ID of the item to be hovered
		setHoveredItemId(itemId);
	};

	const handleMouseLeave = () => {
		// Clear the hovered item when leaving the element
		setHoveredItemId(null);
	};

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
		setViewDuplicateModal(false);
	};

	function parseDateString(dateString) {
		const dateObj = new Date(dateString);

		const day = dateObj.toLocaleString("en-US", { weekday: "long" });
		const date = dateObj.toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		const time = dateObj.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			timeZoneName: "short",
		});

		return {
			day,
			date,
			time,
		};
	}
	useEffect(() => {
		if (token && duplicateImageId) {
			request
				.get(
					apiURL +
						CO_ORDINATOR_DUPLICATE_SETTINGS_BY_ID +
						`/${duplicateImageId}`
				)
				.then((response) => {
					//Set View  for edit group from the response
					console.log("setting", response);
					setViewDuplicateSettings(response.data.setting);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	}, [duplicateImageId, setViewDuplicateSettings]);
	// Listen for the "keydown" event
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.keyCode === 27) {
				// Escape key is pressed
				// Update state variables here
				localStorage.setItem("clickSelect", false);
				setShowModal(false);
				setClickSelect(false);
				// setSelectAll(false);
				setEditorType(0);
				setViewEditGroup({});
				setBackgroundRemovalColorCode("");
				setBackgroundAdjustmentColorCode("");
				setBackgroundRemovalColorType("");
				setBackgroundAdjustmentColorType("");
				setResizingFileFormat("");
				setResizingDpiType("");
				setResizingColorMode("");
				setResizingAlignment("");
				setResizingMarginFormat("");
				setImageFileFormat("");
				setImageDpiType("");
				setImageDetailsView({});
				// setMultipleImagesId([]);
				// setFolderNameFirst([]);
				setFolderListName([]);

				setFolderSelect([]);

				setFoldersPath([]);
				// setDuplicateImageModal(false);
				setDropdownOpen(false);
				// setDuplicateAmount([]);
				// setAmount(0);
			}
		};

		// Add event listener when the component mounts
		window.addEventListener("keydown", handleKeyDown);

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [modalRefImage, images]); // Empty dependency array means this effect runs once, like componentDidMount
	// Function to close the modal
	const closeModal = useCallback(() => {
		// Update state variables here
		localStorage.setItem("clickSelect", false);
		setShowModal(false);
		setClickSelect(false);
		// setSelectAll(false);
		setEditorType(0);
		setViewEditGroup({});
		setBackgroundRemovalColorCode("");
		setBackgroundAdjustmentColorCode("");
		setBackgroundRemovalColorType("");
		setBackgroundAdjustmentColorType("");
		setResizingFileFormat("");
		setResizingDpiType("");
		setResizingColorMode("");
		setResizingAlignment("");
		setResizingMarginFormat("");
		setImageFileFormat("");
		setImageDpiType("");
		setImageDetailsView({});
		// setMultipleImagesId([]);
		// setFolderNameFirst([]);
		setFolderListName([]);

		setFolderSelect([]);

		setFoldersPath([]);
		// setDuplicateImageModal(false);
		setDropdownOpen(false);
		// setDuplicateAmount([]);
		// setAmount(0);
		document.body.classList.remove("modal-open");
	}, [modalRefImage, images]);

	const handleOverlayClick = (e) => {
		if (e.target.classList.contains("modal-container--image")) {
			closeModal();
		} else if (e.target.classList.contains("close-icons-order--image")) {
			closeModal();
		}
	};
	const onChange = (text) => {
		setRenameTextDuplicate(text !== undefined && text);
		console.log("call the text", text);
	};
	const onSubmitDublicateImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", renameId);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_DUPLICATE_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					handleFetchProfileSettings(setting_profile.id);
					setRenameModalShow(false);
					//Set the success from the response
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const onClickDuplicateImage = (id, imageName, imageExtensions) => {
		setRenameId(id);
		setImageName(imageName);
		setImageExtensions(imageExtensions);
		setRenameModalShow((val) => !val);
	};
	const svgCode = (count) => {
		return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5V0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V3H9L3 9V16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V6H4.5C4.89782 6 5.27936 5.84196 5.56066 5.56066C5.84196 5.27936 6 4.89782 6 4.5ZM5 4.5V0.25L0.25 5H4.5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z" fill="white"/>
		<path d="M10 4V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H4V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20H14.5C14.8978 20 15.2794 19.842 15.5607 19.5607C15.842 19.2794 16 18.8978 16 18.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H10ZM9 4.25V8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H4.25L9 4.25Z" fill="white"/>
		<!-- Use a <style> element to set the font size -->
			<style>
			  text {
				font-size: 9px;
				font-weight:500;
			  }
			</style>
			<!-- Adjust the y attribute to add margin on the top -->
			<text x="6" y="18" fill="green">${count}</text>
	  </svg>`;
	};
	const getImageExtension = (imageName) => {
		const lastDotIndex = imageName.lastIndexOf(".");
		if (lastDotIndex !== -1) {
			const extension = imageName.substring(lastDotIndex + 1);
			return extension;
		}
		return "No extension found";
	};
	const handleDelete = () => {
		if (!token) {
			return null;
		}
		// Make an HTTP POST request to delete the selected duplicate image
		request
			.delete(apiURL + CO_ORDINATOR_DELETE_DUPLICATE_IMAGE + `/${renameId}`)
			.then((response) => {
				// Log the response to the console
				console.log(response);
				handleFetchProfileSettings(setting_profile.id);

				// Close the delete confirmation modal
				setShowModalDelete(false);
			})
			.catch((err) => console.log(err));
	};
	const handleRenameOriginalImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", setting_profile.id);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_ORIGINAL_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					handleFetchProfileSettings(setting_profile.id);
					setRenameModalShow(false);
					//Set the success from the response
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleRenameImage = () => {
		if (renameId === setting_profile.id) {
			return handleRenameOriginalImage();
		} else {
			onSubmitDublicateImage();
		}
	};

	//handled next item
	function getNextPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: -1;

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the beginning.
			startIndex = 0;
		} else {
			startIndex += 1;
		}

		const endIndex = startIndex + itemsPerPage;
		const nextPage = data.slice(startIndex, endIndex);

		// Determine the next ID or wrap around to the first ID if necessary.
		let nextPageId = null;

		if (nextPage.length > 0) {
			nextPageId = nextPage[nextPage.length - 1].id;
		} else if (data.length > 0) {
			nextPageId = data[0].id;
		}

		return { nextPage, nextPageId };
	}
	function getPreviousPage(data, currentId, itemsPerPage) {
		let startIndex = currentId
			? data.findIndex((item) => item.id === currentId)
			: -1;
		setCommentsList(false);

		if (startIndex === -1) {
			// If the current ID is not found or not provided, start from the last item.
			startIndex = data.length - itemsPerPage;
		} else {
			startIndex = Math.max(0, startIndex - itemsPerPage);
		}

		const endIndex = startIndex + itemsPerPage;
		const previousPage = data.slice(startIndex, endIndex);

		// Determine the previous ID or wrap around to the last ID if necessary.
		let previousPageId = null;

		if (previousPage.length > 0) {
			previousPageId = previousPage[0].id;
		} else if (data.length > 0) {
			previousPageId = data[data.length - 1].id;
		}

		return { previousPage, previousPageId };
	}

	function showNextPage() {
		const { nextPage, nextPageId } = getNextPage(
			images,
			imageID,
			images.length
		);
		setCommentsList(false);

		if (nextPage.length > 0) {
			// Display the nextPage data
			console.log(nextPage);

			// Update the current page ID
			setImageID(nextPageId);
			const filterImages =
				images !== undefined &&
				images.filter((obj) => obj.id === nextPageId)[0];
			setImageDetailsView(filterImages);
		} else {
			// No more data to show
			console.log("No more data to display.");
		}
	}
	function showPreviousPage() {
		// if (images && images.length > 0) {
		const { previousPage, previousPageId } = getPreviousPage(
			images,
			imageID,
			images.length
		);

		setCommentsList(false);

		if (previousPage.length > 0) {
			// Display the previousPage data
			console.log(previousPage);

			// Update the current page ID
			setImageID(previousPageId);
			const filterImages =
				images !== undefined &&
				images.filter((obj) => obj.id === previousPageId)[0];
			setImageDetailsView(filterImages);
		} else {
			// No more data to show
			console.log("No more data to display.");
		}
	}
	const fetchImageDetailsSettings = (id) => {
		if (token) {
			request
				.get(apiURL + ADMIN_GET_IMAGES_SETTINGS + `/${id}`)
				.then((response) => {
					//Set the success from the response
					setViewImageSettings(response.data);
					setLoader(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	useEffect(() => {
		if (image_details_view.id) {
			fetchImageDetailsSettings(setting_profile.id);
			// fetchComments();
		}
	}, [image_details_view]);
	console.log("view_image_settings", view_image_settings);
	if (image_details_view.length === null) return <Loader />;
	else {
		return (
			<div>
				{showModal && (
					<div className="modal-overlay--order">
						<div
							className="close-icons-order--image"
							onClick={handleOverlayClick}
							ref={modalRefImage}
						>
							<div style={{ marginBottom: "10px" }}>
								{/* <Button
									variant="outline-secondary"
									className="btn-image-order--outlined"
								>
									<span className="order-text--outlined">
										{image_details_view.batch_number}
									</span>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<span className="order-text-outlined--name">
										{image_details_view.path}
									</span>
								</Button> */}
								<div
									className={`dropdown-duplicate-image--modal  ${
										isDropdownOpen ? "open" : ""
									}`}
								>
									<div
										className="dropdown-button--duplicate"
										onClick={toggleDropdown}
									>
										<span>
											<div
												dangerouslySetInnerHTML={{
													__html: svgCode(
														setting_profile !== null &&
															setting_profile !== undefined &&
															setting_profile.duplication_settings !==
																undefined &&
															setting_profile.duplication_settings !== null &&
															setting_profile.duplication_settings.length !==
																undefined
															? parseInt(
																	setting_profile.duplication_settings.length
																		.toString()
																		.substr(0, 2)
															  )
															: "0"
													),
												}}
												style={{ marginLeft: "8px" }}
											/>
										</span>
										<span className="order-text--outlined">
											{setting_profile.batch_number}
										</span>
										<span
											className="order-text--outlined"
											style={{ marginLeft: "42px" }}
										>
											{setting_profile.path}
										</span>
										<span style={{ marginLeft: "45%" }}>
											<div
												style={{ marginRight: "18px" }}
												onClick={() =>
													onClickDuplicateImage(
														setting_profile.id,
														setting_profile.path.substring(
															0,
															setting_profile.path.lastIndexOf(".")
														)
														// getImageExtension(image_details_view.path)
													)
												}
											>
												{/* <img src={EditImageOriginal} alt="Pencil Icon" />{" "} */}
											</div>
										</span>
										<span>
											<img src={ArrowDown} alt="Arrow Down" />{" "}
										</span>
									</div>
									{isDropdownOpen && (
										<div className="dropdown-content-duplicate-image--modal">
											{setting_profile !== undefined &&
												setting_profile.duplication_settings !== undefined &&
												setting_profile.duplication_settings.map(
													(_items, index) => {
														// const isItemHovered =
														// 	hoveredItems[_items.id] || false;
														return (
															<span
																key={_items.id}
																onMouseEnter={() => handleMouseEnter(_items.id)}
																onMouseLeave={handleMouseLeave}
																onClick={() => {
																	setDuplicateImageId(_items.id);
																	setViewDuplicateModal(true);
																	setDropdownOpen(false);
																}}
															>
																<h2
																	className="dropdown-duplicate-image--text"
																	onClick={() =>
																		onClickDuplicateImage(
																			_items.id,
																			_items.image_name.substring(
																				0,
																				_items.image_name.lastIndexOf(".")
																			)
																			// getImageExtension(image_details_view.path)
																		)
																	}
																>
																	{_items.image_name}
																</h2>
																<div className="d-flex">
																	{/* <div
																		style={{ marginRight: "18px" }}
																		onClick={() =>
																			onClickDuplicateImage(
																				_items.id,
																				_items.image_name.substring(
																					0,
																					_items.image_name.lastIndexOf(".")
																				)
																				// getImageExtension(image_details_view.path)
																			)
																		}
																	>
																		<img
																			src={
																				hoveredItemId === _items.id
																					? BlackEdit
																					: EditImageOriginal
																			}
																			alt="Pencil Icon"
																		/>{" "}
																	</div> */}

																	{/* <div
																		onClick={() => {
																			setShowModalDelete(true);
																			setRenameId(_items.id);
																			setImageName(_items.image_name);
																		}}
																	>
																		<img
																			src={
																				hoveredItemId === _items.id
																					? BlackDelete
																					: DeleteIcon
																			}
																			alt="Mindreach icons"
																		/>
																		
																	</div> */}
																</div>
															</span>
														);
													}
												)}
										</div>
									)}
								</div>
							</div>
							<div style={{ marginLeft: "34rem" }}>
								{/* {group_name_select === "New Settings" && (
									<Button
										variant="outline-secondary"
										className="btn-image-order-save--outlined"
										onClick={() => handleUpdateEditGroup()}
									>
										Save
									</Button>
								)}
								{group_name_select !== "" && group_name_select !== "New Settings" && (
									<Button
										variant="outline-secondary"
										className="btn-image-order-save--outlined"
										onClick={() => handleUpdateImageEditGroup()}
									>
										Save
									</Button>
								)} */}
							</div>
							<div
								className="modal-tab"
								onClick={() => {
									setShowModal(false);
									setEditorType(0);
									setViewEditGroup({});
									setBackgroundRemovalColorCode("");
									setBackgroundAdjustmentColorCode("");
									setBackgroundRemovalColorType("");
									setBackgroundAdjustmentColorType("");
									setResizingFileFormat("");
									setResizingDpiType("");
									setResizingColorMode("");
									setResizingAlignment("");
									setResizingMarginFormat("");
									setImageFileFormat("");
									setImageDpiType("");
									setImageDetailsView({});
									document.body.classList.remove("modal-open");
								}}
							>
								<img
									src={CancelIcons}
									className="img-fluid"
									alt="mindreach images"
								/>
							</div>
						</div>
						<div
							className="modal-container--image"
							onClick={handleOverlayClick}
						>
							<div
								className="modal-image-icons"
								onClick={() => {
									setToggleMessage((val) => !val);
									setCommentsList((val) => !val);
									if (!showCommentList) {
										setAnnotationsComments([]);
									}
								}}
								style={{ background: showCommentList ? "#f5f5f5" : "#fff" }}
							>
								<img
									src={showCommentList ? BlueChatIcon : ChatIcons}
									className="img-fluid image--comment"
									alt="mindreach images"
								/>
							</div>
							<div className="modal--image">
								<div className="prev--icons" onClick={() => showPreviousPage()}>
									<img
										src={PreviousIcon}
										className="img-fluid"
										alt="mindreach images"
									/>
								</div>
								<div
									//   style={{
									//     width: "90%",
									//     marginBottom: "0px",
									//     height: "37rem",
									//     overflow: "scroll",
									//     // backgroundColor: "hsl(0, 0%, 90%)",
									//   }}
									className="modal-container"
								>
									{image_details_view !== undefined &&
									image_details_view.image_path !== undefined &&
									image_details_view.customer !== undefined &&
									image_details_view.customer.email !== undefined ? (
										<Marker
											imageURL={`${imageURL}/${image_details_view.customer.email}/${image_details_view.batch_number}/${image_details_view.order.order_number}/images/${image_details_view.image_path}`}
											currentAnnotation={currentAnnotation}
											setCurrentAnnotation={setCurrentAnnotation}
											handleTextChange={handleTextChange}
											annotations={annotations}
											setAnnotations={setAnnotations}
											setToggleMessage={setToggleMessage}
											toggleMessage={toggleMessage}
											idSelected={image_details_view.id}
											token={token}
											setCommentsList={setCommentsList}
											showCommentList={showCommentList}
											setAnnotationsComments={setAnnotationsComments}
											annotationsComments={annotationsComments}
										/>
									) : (
										<Loader />
									)}
								</div>
								<div className="next--icons" onClick={() => showNextPage()}>
									<img
										src={NextIcon}
										className="img-fluid"
										alt="mindreach images"
									/>
								</div>
							</div>
							<div className="modal-image--order">
								<div>
									<div className="sidebar-image-name--image-modal  d-flex">
										<div style={{ width: "100%", marginLeft: "23px" }}>
											<div
												className="d-flex"
												style={{
													// flexDirection: "row-reverse",
													marginBottom: "18px",
													marginTop: "16px",
												}}
											>
												{/* <h2
													className="side-bar-single-select--h2"
													style={{ marginLeft: "83px" }}
												>
													{selectImageName}
												</h2> */}
												<div
													style={{ display: "flex", marginLeft: "auto" }}
													onClick={() => {
														setMultipleImagesId([]);
														setClickSelect(false);
													}}
												>
													<img
														src={CancelIcons}
														className="img-fluid"
														alt="mindreach images"
													/>
												</div>
											</div>

											<div className="form-group edit-profile-setting-container--single">
												<Row
													className="align-items-center edit-setting--select "
													style={{ position: "absolute" }}
												>
													<Form.Label className="col-sm-3 ">
														Settings Profile
													</Form.Label>
													<Col sm={9}>
														<Select
															options={selectLabel(group)}
															onChange={(e) => {
																setEditGroupNameSelect(e.label);
																setEditorType(e.value);
																setGroupSelect((val) => !val);
															}}
															placeholder="Select Profile"
															value={selectLabel(group).find((option) => {
																if (
																	edit_select !== "" &&
																	edit_select !== null
																) {
																	return option.value === edit_select;
																} else {
																	return null;
																}
															})} // Set the selected value by ID
															isDisabled={true}
														/>
													</Col>
												</Row>
											</div>

											<div>
												{group_name_select === "New Settings" ? (
													<div
														style={{
															height: "29.5rem",
															overflow: "scroll",
															width: "100%",
														}}
													>
														{/* Form groups for Edit Type*/}
														{/* Form groups for Edit Type*/}
														<div className="sub-head">Edit Type</div>

														{editTypes !== undefined &&
															editTypes.map((item) => {
																console.log("id item", item.id);
																return (
																	<Form.Group
																		className=""
																		controlId="exampleForm.ControlInput1"
																	>
																		{" "}
																		<Row
																			className="form-group  flex-grow-1"
																			sm={9}
																		>
																			<Col sm={1}>
																				<input
																					type="checkbox"
																					onChange={(e) => {
																						handleBackGroundRemovalCheckbox(
																							item.id
																						);
																						handleAddCheckboxSelect(e);
																					}}
																					value={item.id}
																				/>
																			</Col>
																			<Form.Label
																				className="col-sm-4 form-label form-check-label"
																				style={{ width: "auto" }}
																			>
																				{item.edit_type}
																			</Form.Label>
																		</Row>
																		{item.id === 1 ? (
																			selected ? (
																				<Row
																					className="align-items-center"
																					style={{
																						marginLeft: "auto",
																						marginTop: "0",
																						marginBottom: "20px",
																					}}
																				>
																					<Form.Label className="col-sm-3">
																						Background Color
																					</Form.Label>
																					<Col sm={4}>
																						<Select
																							options={selectBackgrondRemoval}
																							onChange={(e) =>
																								setBackgroundRemovalColorType(
																									e.label
																								)
																							}
																						/>
																					</Col>
																					<Col sm={3}>
																						<Form.Control
																							type="text"
																							placeholder="#ffff"
																							autoFocus
																							onChange={(e) =>
																								setBackgroundRemovalColorCode(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</Col>
																				</Row>
																			) : (
																				""
																			)
																		) : (
																			""
																		)}
																		{item.id === 2 ? (
																			selected1 ? (
																				<Row
																					className="align-items-center"
																					style={{
																						marginLeft: "auto",
																						marginTop: "0",
																						marginBottom: "20px",
																					}}
																				>
																					<Form.Label className="col-sm-3">
																						Background Color
																					</Form.Label>
																					<Col sm={4}>
																						<Select
																							options={selectBackgrondRemoval}
																							onChange={(e) =>
																								setBackgroundAdjustmentColorType(
																									e.label
																								)
																							}
																						/>
																					</Col>
																					<Col sm={3}>
																						<Form.Control
																							type="text"
																							placeholder="#ffff"
																							autoFocus
																							onChange={(e) =>
																								setBackgroundAdjustmentColorCode(
																									e.target.value
																								)
																							}
																							value={
																								background_adjustment_color_code
																							}
																							autoComplete="off"
																						/>
																					</Col>
																				</Row>
																			) : (
																				""
																			)
																		) : (
																			""
																		)}
																		{item.id === 3 ? (
																			selected2 ? (
																				<Row
																					className="align-items-center"
																					style={{
																						marginLeft: "auto",
																						marginRight: "auto",
																						marginTop: "0",
																						marginBottom: "20px",
																						width: " 94%",
																					}}
																				>
																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									File Format
																								</Form.Label>
																								<Col sm={6}>
																									<Select
																										options={file}
																										onChange={(e) =>
																											setResizingFileFormat(
																												e.label
																											)
																										}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Size
																								</Form.Label>
																								<Col sm={9}>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<div className="d-sm-flex col-gap-10 align-items-center">
																											<span className="text-secondary f-sz-13">
																												{" "}
																												H:{" "}
																											</span>
																											<Form.Control
																												type="text"
																												placeholder="2000"
																												autoFocus
																												onChange={(e) =>
																													setResizingHeight(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</div>
																										<div className="d-sm-flex align-items-center">
																											{/* <Image src={Cross} className="img-fluid" alt="cross" /> */}
																										</div>
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<span className="text-secondary f-sz-13">
																												{" "}
																												W:{" "}
																											</span>
																											<Form.Control
																												type="text"
																												placeholder="2000"
																												autoFocus
																												onChange={(e) =>
																													setResizingWidth(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</div>
																									</div>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									DPI
																								</Form.Label>
																								<Col sm={9}>
																									<div className="d-sm-flex col-gap-20">
																										<div className="">
																											<Form.Control
																												type="text"
																												placeholder="2000"
																												autoFocus
																												onChange={(e) =>
																													setResizingDpi(
																														e.target.value
																													)
																												}
																												autoComplete="off"
																											/>
																										</div>
																										<div className="flex-fill">
																											<Select
																												options={dpi}
																												onChange={(e) =>
																													setResizingDpiType(
																														e.label
																													)
																												}
																											/>
																										</div>
																									</div>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Color Mode
																								</Form.Label>
																								<Col sm={9}>
																									<Select
																										options={ColorMode}
																										onChange={(e) =>
																											setResizingColorMode(
																												e.label
																											)
																										}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Align
																								</Form.Label>
																								<Col sm={9}>
																									<Select
																										options={Align}
																										onChange={(e) =>
																											setResizingAlignment(
																												e.label
																											)
																										}
																									/>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label
																									className="col-sm-3"
																									// style={{ marginTop: "-17%" }}
																								>
																									Margins
																								</Form.Label>
																								<Col sm={9}>
																									{/* <div className="d-flex"> */}
																									<Select
																										options={Margin}
																										onChange={(e) =>
																											setResizingMarginFormat(
																												e.label
																											)
																										}
																									/>
																									{/* </div> */}
																								</Col>
																								<Col
																									sm={7}
																									lg={7}
																									style={{
																										display: "flex",
																										marginLeft: "5rem",
																									}}
																								>
																									<div className="d-sm-flex col-gap-20 mt-2 ">
																										{/* <Col sm={9}> */}

																										{/* </Col>
                  <Col sm={4}> */}
																										<div className="d-flex">
																											<ul className="margin-list d-flex">
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="T"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginTop(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="B"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginBottom(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="L"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginLeft(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="R"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginRight(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																													/>
																												</li>
																											</ul>
																										</div>
																										{/* </Col> */}
																									</div>
																								</Col>

																								<Col sm={6}></Col>
																							</Row>
																						</div>
																					</Form.Group>
																				</Row>
																			) : (
																				""
																			)
																		) : (
																			""
																		)}
																	</Form.Group>
																);
															})}
														{/* Form groups for output specifications */}
														<div className="sub-head">
															Output Specifications
														</div>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		File Format
																	</Form.Label>
																	<Col sm={6}>
																		<Select
																			options={file}
																			onChange={(e) =>
																				setImageFileFormat(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		Size
																	</Form.Label>
																	<Col sm={9}>
																		<div className="d-sm-flex align-items-center col-gap-10">
																			<div className="d-sm-flex col-gap-10 align-items-center">
																				<span className="text-secondary f-sz-13">
																					{" "}
																					H:{" "}
																				</span>

																				<Form.Control
																					type="text"
																					placeholder="2000"
																					autoFocus
																					onChange={(e) =>
																						setImageHeight(e.target.value)
																					}
																					autoComplete="off"
																				/>
																			</div>
																			<div className="d-sm-flex align-items-center"></div>
																			<div className="d-sm-flex align-items-center col-gap-10">
																				<span className="text-secondary f-sz-13">
																					{" "}
																					W:{" "}
																				</span>
																				<Form.Control
																					type="text"
																					placeholder="2000"
																					autoFocus
																					onChange={(e) =>
																						setImageWidth(e.target.value)
																					}
																					autoComplete="off"
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		DPI
																	</Form.Label>
																	<Col sm={9}>
																		<div className="d-sm-flex col-gap-20">
																			<Form.Control
																				type="text"
																				placeholder="2000"
																				autoFocus
																				onChange={(e) =>
																					setImageDpi(e.target.value)
																				}
																				autoComplete="off"
																			/>

																			<div className="flex-fill">
																				<Select
																					options={dpi}
																					onChange={(e) =>
																						setImageDpiType(e.label)
																					}
																				/>
																			</div>
																		</div>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		Color Mode
																	</Form.Label>
																	<Col sm={9}>
																		<Select
																			options={ColorMode}
																			onChange={(e) =>
																				setImageColorMode(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>

														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		Align
																	</Form.Label>
																	<Col sm={9}>
																		<Select
																			options={Align}
																			onChange={(e) =>
																				setImageAlignment(e.label)
																			}
																		/>
																	</Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<Form.Label className="col-sm-3">
																		Margins
																	</Form.Label>
																	<Col sm={9}>
																		<Select
																			options={Margin}
																			onChange={(e) =>
																				setResizingMarginFormat(e.label)
																			}
																		/>
																		{/* </div> */}
																	</Col>
																	<Col
																		sm={7}
																		lg={7}
																		style={{
																			display: "flex",
																			marginLeft: "5rem",
																		}}
																	>
																		<div className="d-sm-flex col-gap-20 mt-2 ">
																			<div className="d-flex">
																				<ul className="margin-list d-flex">
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="T"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginTop(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="B"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginBottom(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="L"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginLeft(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</li>
																					<li>
																						<Form.Control
																							type="text"
																							placeholder="R"
																							autoFocus
																							onChange={(e) =>
																								setImageMarginRight(
																									e.target.value
																								)
																							}
																							autoComplete="off"
																						/>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</Col>

																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
														<Form.Group
															style={{ margin: "2px" }}
															controlId="exampleForm.ControlTextarea1"
														>
															<div className="form-group">
																<Row className="align-items-center">
																	<div className="global-gap--btn sidebar-single--btn">
																		{/* <Button
																			variant="primary"
																			className="btns"
																			onClick={() => handleUpdateEditGroup()}
																		>
																			{loader ? (
																				<div
																					style={{ backgroundColor: "wheat" }}
																				>
																					<Loader />
																				</div>
																			) : (
																				"Save"
																			)}
																		</Button> */}
																	</div>
																	<Col sm={6}></Col>
																</Row>
															</div>
														</Form.Group>
													</div>
												) : (
													<div
														style={{
															// marginLeft: "5%",
															height: "29.5rem",
															overflow: "scroll",
															width: "100%",
														}}
													>
														{view_edit_group !== undefined &&
															view_edit_group.edit_group !== undefined &&
															view_edit_group.edit_group.edit_types !==
																undefined && (
																<div className="sub-head">Edit Type</div>
															)}
														{/* /***need to check**/}

														{view_edit_group !== undefined &&
															view_edit_group.edit_group !== undefined &&
															view_edit_group.edit_group.edit_types !==
																undefined &&
															view_edit_group.edit_group.edit_types.map(
																(item, index) => {
																	console.log("edit type", item);
																	return (
																		<>
																			<Form.Group
																				className=""
																				controlId="exampleForm.ControlInput1"
																			>
																				{" "}
																				<Row
																					className="form-group  flex-grow-1"
																					sm={9}
																				>
																					<Col sm={1}>
																						<input
																							type="checkbox"
																							value={item.id}
																							// value={!!checked ? 0 : 1}
																							onChange={(e) => {
																								handleBackGroundRemovalCheckbox(
																									item.id
																								);
																								handleAddCheckboxSelect(e);
																							}}
																							checked={
																								updateChecked[index] === 1
																									? true
																									: updateChecked[index] === 2
																									? true
																									: updateChecked[index] === 3
																									? true
																									: updateChecked[index] === 15
																									? true
																									: false
																							}
																						/>
																					</Col>
																					<Form.Label
																						className="col-sm-4 form-label form-check-label"
																						style={{ width: "auto" }}
																					>
																						{item.edit_type}
																					</Form.Label>
																				</Row>
																				{updateChecked[index] === 1 ? (
																					<Row
																						className="align-items-center"
																						style={{
																							// marginLeft: "44px",
																							marginTop: "-2px",
																							marginBottom: "20px",
																							width: "100%",
																						}}
																					>
																						<Form.Label className="col-sm-3">
																							Background Color
																						</Form.Label>
																						<Col sm={5}>
																							<Select
																								options={selectBackgrondRemoval}
																								// onChange={(e) =>
																								// 	setBackgroundRemovalColorTypeUpdate(e.label)
																								// }
																								defaultValue={{
																									label: `${background_removal_color_type}`,
																									id: "21",
																								}}
																								value={{
																									label:
																										background_removal_color_type, // Set the label property to the desired value
																									value:
																										background_removal_color_type, // Set the value property to the corresponding value
																								}}
																							/>
																						</Col>
																						<Col sm={4}>
																							<Form.Control
																								type="text"
																								placeholder="#ffff"
																								autoFocus
																								// onChange={(e) =>
																								// 	setBackgroundRemovalColorCodeUpdate(
																								// 		e.target.value
																								// 	)
																								// }
																								value={
																									background_removal_color_code
																								}
																							/>
																						</Col>
																					</Row>
																				) : (
																					""
																				)}
																				{updateChecked[index] === 2 ? (
																					<Row
																						className="align-items-center"
																						style={{
																							// marginLeft: "44px",
																							marginTop: "-2px",
																							marginBottom: "20px",
																							width: "100%",
																						}}
																					>
																						<Form.Label className="col-sm-3">
																							Background Color
																						</Form.Label>
																						<Col sm={5}>
																							<Select
																								options={selectBackgrondRemoval}
																								// onChange={(e) =>
																								// 	setBackgroundAdjustmentColorTypeUpdate(
																								// 		e.label
																								// 	)
																								// }
																								// defaultValue={{
																								// 	label: `${background_adjustment_color_type}`,
																								// 	id: "21",
																								// }}
																								value={{
																									label:
																										background_adjustment_color_type, // Set the label property to the desired value
																									value:
																										background_adjustment_color_type, // Set the value property to the corresponding value
																								}}
																							/>
																						</Col>
																						<Col sm={4}>
																							<Form.Control
																								type="text"
																								placeholder="#ffff"
																								autoFocus
																								// onChange={(e) =>
																								// 	setBackgroundAdjustmentColorCodeUpdate(
																								// 		e.target.value
																								// 	)
																								// }
																								value={
																									background_adjustment_color_code
																								}
																							/>
																						</Col>
																					</Row>
																				) : (
																					""
																				)}
																				{updateChecked[index] === 3 ? (
																					<Row
																						className="align-items-center"
																						style={{
																							// marginLeft: "40px",
																							marginTop: "-2px",
																							marginBottom: "20px",
																							width: "100%",
																						}}
																					>
																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										File Format
																									</Form.Label>
																									<Col sm={6}>
																										<Select
																											options={file}
																											// onChange={(e) =>
																											// 	setResizingFileFormat(e.label)
																											// }
																											// defaultValue={{
																											// 	label: `${resizing_file_format}`,
																											// 	id: "21",
																											// }}
																											value={{
																												label:
																													resizing_file_format, // Set the label property to the desired value
																												value:
																													resizing_file_format, // Set the value property to the corresponding value
																											}}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										Size
																									</Form.Label>
																									<Col sm={9}>
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<div className="d-sm-flex col-gap-10 align-items-center">
																												<span className="text-secondary f-sz-13">
																													{" "}
																													H:{" "}
																												</span>
																												<Form.Control
																													type="text"
																													placeholder="2000"
																													autoFocus
																													onChange={(e) =>
																														setResizingHeight(
																															e.target.value
																														)
																													}
																													value={
																														resizing_height
																													}
																												/>
																											</div>
																											<div className="d-sm-flex align-items-center"></div>
																											<div className="d-sm-flex align-items-center col-gap-10">
																												<span className="text-secondary f-sz-13">
																													{" "}
																													W:{" "}
																												</span>
																												<Form.Control
																													type="text"
																													placeholder="2000"
																													autoFocus
																													onChange={(e) =>
																														setResizingWidth(
																															e.target.value
																														)
																													}
																													value={resizing_width}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										DPI
																									</Form.Label>
																									<Col sm={9}>
																										<div className="d-sm-flex col-gap-20">
																											<div className="width-100">
																												<Form.Control
																													type="text"
																													placeholder="2000"
																													autoFocus
																													onChange={(e) =>
																														setResizingDpi(
																															e.target.value
																														)
																													}
																													value={resizing_dpi}
																												/>
																											</div>
																											<div className="flex-fill">
																												<Select
																													options={dpi}
																													onChange={(e) =>
																														setResizingDpiType(
																															e.label
																														)
																													}
																													// defaultValue={{
																													// 	label: `${resizing_dpi_type}`,
																													// 	id: "21",
																													// }}
																													value={{
																														label:
																															resizing_dpi_type, // Set the label property to the desired value
																														value:
																															resizing_dpi_type, // Set the value property to the corresponding value
																													}}
																												/>
																											</div>
																										</div>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										Color Mode
																									</Form.Label>
																									<Col sm={9}>
																										<Select
																											options={ColorMode}
																											onChange={(e) =>
																												setResizingColorMode(
																													e.label
																												)
																											}
																											// defaultValue={{
																											// 	label: `${resizing_color_mode}`,
																											// 	id: "21",
																											// }}
																											value={{
																												label:
																													resizing_color_mode, // Set the label property to the desired value
																												value:
																													resizing_color_mode, // Set the value property to the corresponding value
																											}}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label className="col-sm-3">
																										Align
																									</Form.Label>
																									<Col sm={9}>
																										<Select
																											options={Align}
																											onChange={(e) =>
																												setResizingAlignment(
																													e.label
																												)
																											}
																											// defaultValue={{
																											// 	label: `${resizing_alignment}`,
																											// 	id: "21",
																											// }}
																											value={{
																												label:
																													resizing_alignment, // Set the label property to the desired value
																												value:
																													resizing_alignment, // Set the value property to the corresponding value
																											}}
																										/>
																									</Col>
																								</Row>
																							</div>
																						</Form.Group>

																						<Form.Group
																							style={{ margin: "2px" }}
																							controlId="exampleForm.ControlTextarea1"
																						>
																							<div className="form-group">
																								<Row className="align-items-center">
																									<Form.Label
																										className="col-sm-3"
																										style={{
																											marginTop: "-53px",
																										}}
																									>
																										Margins
																									</Form.Label>
																									<Col sm={9}>
																										<div className="d-sm-flex col-gap-20 flex-xxl-column">
																											<div
																												style={{
																													width: "100%",
																													marginBottom: "23px",
																												}}
																											>
																												<Select
																													options={Margin}
																													onChange={(e) =>
																														setResizingMarginFormat(
																															e.label
																														)
																													}
																													defaultValue={{
																														label: `${resizing_margin_format}`,
																														id: "21",
																													}}
																													value={{
																														label:
																															resizing_margin_format, // Set the label property to the desired value
																														value:
																															resizing_margin_format, // Set the value property to the corresponding value
																													}}
																												/>
																											</div>

																											<ul className="margin-list">
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="T"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginTop(
																																e.target.value
																															)
																														}
																														value={
																															resizing_margin_top
																														}
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="B"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginBottom(
																																e.target.value
																															)
																														}
																														value={
																															resizing_margin_bottom
																														}
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="L"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginLeft(
																																e.target.value
																															)
																														}
																														value={
																															resizing_margin_left
																														}
																													/>
																												</li>
																												<li>
																													<Form.Control
																														type="text"
																														placeholder="R"
																														autoFocus
																														onChange={(e) =>
																															setResizingMarginRight(
																																e.target.value
																															)
																														}
																														value={
																															resizing_margin_right
																														}
																													/>
																												</li>
																											</ul>
																										</div>
																									</Col>

																									<Col sm={6}></Col>
																								</Row>
																							</div>
																						</Form.Group>
																					</Row>
																				) : (
																					""
																				)}
																			</Form.Group>
																		</>
																	);
																}
															)}
														{/* Form groups for output specifications */}

														{view_edit_group !== undefined &&
															view_edit_group.edit_group !== undefined &&
															view_edit_group.edit_group.resizing_settings !==
																undefined &&
															view_edit_group.edit_group.resizing_settings.map(
																(items, index) => {
																	return (
																		<>
																			<div className="sub-head">
																				Output Specifications
																			</div>
																			{viewDuplicateModal ? (
																				<div
																					style={{
																						// marginLeft: "5%",
																						height: "29.5rem",
																						overflow: "scroll",
																						width: "100%",
																					}}
																				>
																					{viewDuplicateSettingsById !==
																						undefined && (
																						<>
																							<Form.Group
																								style={{ margin: "2px" }}
																								controlId="exampleForm.ControlTextarea1"
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label className="col-sm-3">
																											File Format
																										</Form.Label>
																										<Col sm={6}>
																											{getSelectComponent(
																												viewDuplicateSettingsById.file_format,
																												setFileFormatDuplicate,
																												file
																											)}
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>

																							<Form.Group
																								style={{ margin: "2px" }}
																								controlId="exampleForm.ControlTextarea1"
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label className="col-sm-3">
																											Size
																										</Form.Label>
																										<Col sm={9}>
																											<div className="d-sm-flex align-items-center col-gap-10">
																												<div className="d-sm-flex col-gap-10 align-items-center">
																													<span className="text-secondary f-sz-13">
																														{" "}
																														H:{" "}
																													</span>
																													<div className="flex-col">
																														<Form.Control
																															type="text"
																															placeholder=""
																															autoFocus
																															onChange={(e) =>
																																setHeightDuplicate(
																																	e.target.value
																																)
																															}
																															autoComplete="off"
																															defaultValue={
																																viewDuplicateSettingsById.height
																															}
																															disabled={true}
																														/>
																													</div>
																												</div>
																												<div className="d-sm-flex align-items-center"></div>
																												<div className="d-sm-flex align-items-center col-gap-10">
																													<span className="text-secondary f-sz-13">
																														{" "}
																														W:{" "}
																													</span>
																													<div className="flex-col">
																														<Form.Control
																															type="text"
																															placeholder="2000"
																															autoFocus
																															onChange={(e) =>
																																setWidthDuplicate(
																																	e.target.value
																																)
																															}
																															autoComplete="off"
																															defaultValue={
																																viewDuplicateSettingsById.width
																															}
																															disabled={true}
																														/>
																													</div>
																												</div>
																											</div>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>

																							<Form.Group
																								style={{ margin: "2px" }}
																								controlId="exampleForm.ControlTextarea1"
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label className="col-sm-3">
																											DPI
																										</Form.Label>
																										<Col sm={9}>
																											<div className="d-sm-flex col-gap-20">
																												<div className="">
																													<Form.Control
																														type="text"
																														placeholder="2000"
																														autoFocus
																														onChange={(e) =>
																															setDpiDuplicate(
																																e.target.value
																															)
																														}
																														autoComplete="off"
																														defaultValue={
																															viewDuplicateSettingsById.dpi
																														}
																														disabled={true}
																													/>
																												</div>
																												<div className="flex-fill">
																													{getSelectComponent(
																														viewDuplicateSettingsById.dpi_type,
																														setDpiTypeDuplicate,
																														dpi
																													)}
																												</div>
																											</div>
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>

																							<Form.Group
																								style={{ margin: "2px" }}
																								controlId="exampleForm.ControlTextarea1"
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label className="col-sm-3">
																											Color Mode
																										</Form.Label>
																										<Col sm={9}>
																											{getSelectComponent(
																												viewDuplicateSettingsById.color_mode,
																												setColorModeDuplicate,
																												ColorMode
																											)}
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>

																							<Form.Group
																								style={{ margin: "2px" }}
																								controlId="exampleForm.ControlTextarea1"
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label className="col-sm-3">
																											Align
																										</Form.Label>
																										<Col sm={9}>
																											{getSelectComponent(
																												viewDuplicateSettingsById.alignment,
																												setAlignModeDuplicate,
																												Align
																											)}
																										</Col>
																									</Row>
																								</div>
																							</Form.Group>

																							<Form.Group
																								style={{ margin: "2px" }}
																								controlId="exampleForm.ControlTextarea1"
																							>
																								<div className="form-group">
																									<Row className="align-items-center">
																										<Form.Label className="col-sm-3">
																											Margins
																										</Form.Label>
																										<Col
																											sm={9}
																											style={{
																												display: "contents",
																											}}
																										>
																											<div className="d-sm-flex col-gap-20 flex-col">
																												<div className="">
																													{getSelectComponent(
																														viewDuplicateSettingsById.margin_format,
																														setMarginFormatDuplicate,
																														Margin
																													)}
																												</div>
																												<div className="">
																													<ul className="margin-list">
																														<li>
																															<Form.Control
																																type="text"
																																placeholder="T"
																																autoFocus
																																onChange={(e) =>
																																	setMarginTopDuplicate(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																																defaultValue={
																																	viewDuplicateSettingsById.margin_top
																																}
																																disabled={true}
																															/>
																														</li>
																														<li>
																															<Form.Control
																																type="text"
																																placeholder="B"
																																autoFocus
																																onChange={(e) =>
																																	setMarginBottomDuplicate(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																																defaultValue={
																																	viewDuplicateSettingsById.margin_bottom
																																}
																																disabled={true}
																															/>
																														</li>
																														<li>
																															<Form.Control
																																type="text"
																																placeholder="R"
																																autoFocus
																																onChange={(e) =>
																																	setMarginRightDuplicate(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																																defaultValue={
																																	viewDuplicateSettingsById.margin_right
																																}
																																disabled={true}
																															/>
																														</li>
																														<li>
																															<Form.Control
																																type="text"
																																placeholder="L"
																																autoFocus
																																onChange={(e) =>
																																	setMarginLeftDuplicate(
																																		e.target
																																			.value
																																	)
																																}
																																autoComplete="off"
																																defaultValue={
																																	viewDuplicateSettingsById.margin_left
																																}
																																disabled={true}
																															/>
																														</li>
																													</ul>
																												</div>
																											</div>
																										</Col>

																										<Col sm={6}></Col>
																									</Row>
																								</div>
																							</Form.Group>
																						</>
																					)}
																				</div>
																			) : (
																				<>
																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									File Format
																								</Form.Label>
																								<Col sm={6}>
																									<Form.Label className="col-sm-3">
																										{items.file_format}
																									</Form.Label>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Size
																								</Form.Label>
																								<Col sm={9}>
																									<div className="d-sm-flex align-items-center col-gap-10">
																										<div className="d-sm-flex col-gap-10 align-items-center">
																											<span className="text-secondary f-sz-13">
																												{" "}
																												H:{" "}
																											</span>
																											<span className="text-secondary f-sz-13">
																												{items.height}
																											</span>
																										</div>
																										<div className="d-sm-flex align-items-center"></div>
																										<div className="d-sm-flex align-items-center col-gap-10">
																											<span className="text-secondary f-sz-13">
																												{" "}
																												W:{" "}
																											</span>
																											<span className="text-secondary f-sz-13">
																												{items.width}
																											</span>
																										</div>
																									</div>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									DPI
																								</Form.Label>
																								<Col sm={9}>
																									<div className="d-sm-flex col-gap-20">
																										<div className="">
																											<Form.Label className="col-sm-3">
																												{items.dpi}
																											</Form.Label>
																										</div>
																										<div className="flex-fill">
																											<Form.Label className="col-sm-3">
																												{items.dpi_type}
																											</Form.Label>
																										</div>
																									</div>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Color Mode
																								</Form.Label>
																								<Col sm={9}>
																									<Form.Label className="col-sm-3">
																										{items.color_mode}
																									</Form.Label>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Align
																								</Form.Label>
																								<Col sm={9}>
																									<Form.Label className="col-sm-3">
																										{items.alignment}
																									</Form.Label>
																								</Col>
																							</Row>
																						</div>
																					</Form.Group>

																					<Form.Group
																						style={{ margin: "2px" }}
																						controlId="exampleForm.ControlTextarea1"
																					>
																						<div className="form-group">
																							<Row className="align-items-center">
																								<Form.Label className="col-sm-3">
																									Margins
																								</Form.Label>
																								<Col sm={9}>
																									<div className="d-sm-flex col-gap-20">
																										<div className="image-details-margin--type ">
																											<span className="text-secondary f-sz-13">
																												{items.margin_format}
																											</span>
																										</div>
																										<div className="">
																											<ul className="margin-list">
																												<li>
																													<Form.Label className="col-sm-3">
																														{items.margin_top}
																													</Form.Label>
																												</li>
																												<li>
																													<Form.Label className="col-sm-3">
																														{
																															items.margin_bottom
																														}
																													</Form.Label>
																												</li>
																												<li>
																													<Form.Label className="col-sm-3">
																														{items.margin_right}
																													</Form.Label>
																												</li>
																												<li>
																													<Form.Label className="col-sm-3">
																														{items.margin_left}
																													</Form.Label>
																												</li>
																											</ul>
																										</div>
																									</div>
																								</Col>

																								<Col sm={6}></Col>
																							</Row>
																						</div>
																					</Form.Group>
																				</>
																			)}
																		</>
																	);
																}
															)}
														{image_details_view !== undefined &&
														image_details_view.status !== undefined &&
														image_details_view.status === "assign_to_editor" ? (
															<div></div>
														) : (
															<div className="global-gap--btn sidebar-single--btn">
																{/* <Button
																	variant="primary"
																	className="btns"
																	onClick={() => handleUpdateImageEditGroup()}
																>
																	{loader ? (
																		<div>
																			<Loader />
																			Loading
																		</div>
																	) : (
																		"Save"
																	)}
																</Button> */}
															</div>
														)}

														<Col sm={6}></Col>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
};

export default Modal;
