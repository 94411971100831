import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import Placeholder from 'react-bootstrap/Placeholder';
// import '../../../css/style.css'
// import '../../../css/responsive.css'
// import '../../../css/customers.css'
// import '../../../css/dashboardtable.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import user from '../../../assets/Images/users.svg';
import plus from '../../../assets/Images/plus.svg';
import Select from 'react-select';

import Button from 'react-bootstrap/Button';
const choosetype = [
    { label: "Standard", value: 355 },
    { label: "Medium", value: 54 }

];
const SupervisorCustomerListCo = () => {

    const products = [{
        id: '1',
        name: "Nick, Inc.",
        price: 'kbryant@mindreach.com',
        date: '19 Aug 2022',
        CustomerType: 'Plus',
        Status: <p style={{ color: '#125ECB' }}>Active</p>

    }, {
        id: 2,
        name: 'Reebok ',
        price: 'kbryant@mindreach.com',
        date: '19 Aug 2022',
        CustomerType: 'Standrad',



        Status: <p style={{ color: '#125ECB' }}>Active</p>
    },
    {
        id: 3,
        name: "Under Armour",
        price: 'kbryant@mindreach.com',
        date: '19 Aug 2022',
        CustomerType: 'Plus',
        Status: <p style={{ color: '#125ECB' }}>Active</p>
    },
    {
        id: 4,
        name: "Coca Cola",
        price: 'kbryant@mindreach.com',
        date: '19 Aug 2022',
        CustomerType: 'Standrad',

        Status: <p style={{ color: '#125ECB' }}>Active</p>
    },
    {
        id: 5,
        name: "Coca Cola",
        price: 'kbryant@mindreach.com',
        date: '19 Aug 2022',
        CustomerType: 'Plus',

        Status: <p style={{ color: '#125ECB' }}>Active</p>
    },
    {
        id: 6,
        name: "Adidas",
        price: 'kbryant@mindreach.com',
        date: '19 Aug 2022',
        CustomerType: 'Standrad',

        Status: <p style={{ color: '#125ECB' }}>Active</p>
    }
    ]




    const columns = [{
        dataField: 'name',
        text: 'Name',
        number: '1',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }

    }, {
        dataField: 'price',
        text: 'Email',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'date',
        text: 'Active Date',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'CustomerType',
        text: 'Customer Type',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }
    },
    {
        dataField: 'Status',
        text: 'Status',
        headerStyle: (colum, colIndex) => {
            return { width: '145px', textAlign: 'left' };
        }

    }


    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    // <BootstrapTable
    //     keyField="id"
    //     data={products}
    //     columns={columns}
    //     striped
    //     hover
    //     condensed
    //     defaultSortDirection="asc"
    // />

    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="rightbar pr-11">
                        <div className="section-title">
                            <h2>Customers</h2>
                        </div>
                        <div className="d-sm-flex flex-sm-wrap col-gap-30-sm mb-3">
                            <div className="customer-search">
                                <Form>
                                    <Row className="align-items-center">
                                        <Col lg={2} sm={4}>
                                            <div className="form-group">
                                                <Select options={choosetype} defaultValue={{ label: "Standard", value: 355 }} />
                                            </div>

                                        </Col>
                                        <Col lg={5} sm={4}>
                                            <div className="form-group">
                                                <Form.Control
                                                    type="search"
                                                    placeholder="Search"
                                                    className="me-2 search-icon"
                                                    aria-label="Search Customer"
                                                /> </div>
                                        </Col>
                                        <Col lg={3} sm={4}>
                                            <div className="form-group">
                                                <p className="customer-count"><span><img src={user} className="img-fluid" alt="mindreach images" /></span>20 Customer(s)</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            <div>
                                <Button variant="primary"><span><img src={plus} className="img-fluid mr-2" /></span>New Customer</Button>{' '}
                            </div>
                        </div>


                        <div className="table-responsive table-b supervisor-dash-table ">

                            <BootstrapTable keyField='id' data={products} columns={columns} />


                        </div>
                        <Row className="mt-4">
                            <Col md={{ span: 4, offset: 8 }}>
                                {
                                    <Pagination>
                                        <Pagination.Prev />
                                        <Pagination.Item active>{1}</Pagination.Item>
                                        <Pagination.Item>{2}</Pagination.Item>
                                        <Pagination.Item>{3}</Pagination.Item>
                                        <Pagination.Item>{4}</Pagination.Item>
                                        <Pagination.Item>{5}</Pagination.Item>
                                        <Pagination.Item>{6}</Pagination.Item>
                                        <Pagination.Next />
                                    </Pagination>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupervisorCustomerListCo