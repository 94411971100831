import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";

// Importing API related files and routes
import { client } from "./../../../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../../../data/filepath";
import { CO_ORDINATOR_DUPLICATE_IMAGES } from "./../../../../../config/apiRoutes.api";

const DuplicateModalCount = ({
	setting_profile,
	title,
	handleConfirm,
	setDuplicateSettings,
	setDuplicateImageModal,
	handleChange,
	duplicate_amount,
	customer_id,
	token,
	idSelected,
	handleFetchProfileSettings,
	setCopyAmounts,
	copyAmounts,
	amount,
	setShowupdateSettingsModal,
	viewUpdateSettingsButton,
	...props
}) => {
	console.log("settings profile", setting_profile);

	// Create a FormData object for request payload
	const bodyFormData = new FormData();

	// Create an HTTP client with the token
	const request = client(token);

	//Declartions of state
	const [error, setError] = useState("");

	const [imageNamePath, setImageNamePath] = useState("");

	// Define a function to update the duplicateAmounts array when input changes
	const handleInputChange = (index, value) => {
		const newDuplicateAmounts = [...copyAmounts]; // Create a copy of the array
		newDuplicateAmounts[index] = value; // Update the value at the specified index
		setCopyAmounts(newDuplicateAmounts); // Update the state with the new array
	};
	// Use useEffect to initialize the duplicateAmounts array based on duplicate_amount
	useEffect(() => {
		const initialDuplicateAmounts = duplicate_amount.map(
			(_, index) => `_copy${index + 1}`
		);
		setCopyAmounts(initialDuplicateAmounts);
	}, [duplicate_amount]); // Run this effect whenever duplicate_amount changes
	// useEffect(() => {
	// 	try {
	// 		const image_name =
	// 			duplicateAmounts !== undefined ? duplicateAmounts.join(", ") : "";

	// 		setImageNamePath(image_name);
	// 	} catch (error) {
	// 		console.error("Error in useEffect:", error);
	// 	}
	// }, [duplicateAmounts]);

	const handleSubmitDuplicateImage = () => {
		setDuplicateSettings(true);
		setDuplicateImageModal(false);
	};
	const handleUpdateDuplicateImage = () => {
		setShowupdateSettingsModal(true);
		setDuplicateImageModal(false);
	};

	console.log("duplicateAmounts", imageNamePath);
	return (
		<>
			<Modal
				{...props}
				backdrop="static"
				keyboard={false}
				centered
				contentClassName="my-modal--rename box exclude-click"
			>
				<Modal.Header closeButton>
					<Modal.Title
						id="contained-modal-title-vcenter"
						className="section-title fonts"
					>
						{title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="section-title fonts" style={{ width: "30vw" }}>
					<Col sm={12}>
						<div className="form-group">
							<Row className="align-items-center">
								<Form.Label className="col-sm-3" style={{ width: "86px" }}>
									Number of Duplicate
								</Form.Label>
								<Col sm={6} style={{ marginLeft: "30px" }}>
									<Form.Control
										type="text"
										placeholder="Enter the amount"
										autoFocus
										onChange={handleChange}
										value={amount}
									/>
								</Col>
							</Row>
						</div>

						{duplicate_amount.length > 0 && (
							<div className="form-group">
								<Row className="align-items-center">
									<Form.Label className="col-sm-3" style={{ width: "auto" }}>
										File
									</Form.Label>
									<Col sm={6} style={{ marginLeft: "62px" }}>
										<Form.Label className="col-sm-3" style={{ width: "auto" }}>
											Suffix
										</Form.Label>
									</Col>
								</Row>
								{duplicate_amount.map((data, index) => (
									<Row
										key={index}
										className="align-items-center"
										style={{ marginBottom: "18px" }}
									>
										<Form.Label className="col-sm-3" style={{ width: "auto" }}>
											Duplicate &nbsp;{index + 1}
										</Form.Label>
										<Col sm={6} style={{ marginLeft: "30px" }}>
											<Form.Control
												type="text"
												placeholder="Enter the amount"
												autoFocus={false}
												value={copyAmounts[index]} // Set the default value from the state
												onChange={(e) =>
													handleInputChange(index, e.target.value)
												} // Call the handler on change
												suppressHydrationWarning={false}
											/>
										</Col>
									</Row>
								))}
							</div>
						)}
					</Col>
				</Modal.Body>
				{duplicate_amount.length >= 1 && (
					<Modal.Footer style={{ justifyContent: "end" }}>
						{viewUpdateSettingsButton ? (
							<Button
								variant="primary"
								className="btns"
								onClick={() => handleUpdateDuplicateImage()}
							>
								Edit
							</Button>
						) : (
							<Button
								variant="primary"
								className="btns"
								onClick={() => handleSubmitDuplicateImage()}
							>
								Create
							</Button>
						)}
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
};

export default DuplicateModalCount;
