import React from 'react'
import Card from 'react-bootstrap/Card';
import details1 from '../../../assets/Images/details1.svg'
import details2 from '../../../assets/Images//details2.svg'
// import details3 from '../../../Images/details3.svg'
import details4 from '../../../assets/Images/details4.svg'
import uploaded from '../../../assets/Images//uploaded.svg'
// import psd from '../../../Images/psd.svg'
// import cancel from '../../../Images/cancel.svg'

const CoordinatorOrderDetails = () => {
    return (
        <>
            <div className="editororderdetails editororderdetails-new">
                <div className="detailslist coordibox">
                    <Card>
                        <Card.Img variant="top" src={details1} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                            </div>

                        </Card.Body>
                    </Card>



                    <Card>
                        <Card.Img variant="top" src={details4} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                            </div>
                        </Card.Body>
                    </Card>


                    <Card>
                        <Card.Img variant="top" src={details2} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={details2} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="bothbtn grey-btn">
                                Pending
                            </div>
                        </Card.Body>
                    </Card>


                    <Card>
                        <Card.Img variant="top" src={details4} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                            </div>
                        </Card.Body>
                    </Card>



                    <Card>
                        <Card.Img variant="top" src={details1} />
                        <div className="checkbox"></div>
                        <Card.Body>
                            <Card.Text>
                                983794768.jpg
                            </Card.Text>
                            <div className="bothbtn">
                                <span><img src={uploaded} className="img-fluid" alt="mindreach images" /></span>Selected
                            </div>
                        </Card.Body>
                    </Card>


                </div>

            </div>
        </>
    )
}

export default CoordinatorOrderDetails