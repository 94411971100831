import React from "react";

const NoDataFound = () => {
	const emptyDataMessage = () => {
		return "No Data to Display";
	};
	return (
		<>
			<div className="no-data--found">
				<div className="empty-state__content">
					<div className="empty-state__message">{emptyDataMessage()}</div>
				</div>
			</div>
		</>
	);
};
export default NoDataFound;
