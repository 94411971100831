import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
	Container,
	Row,
	OverlayTrigger,
	Tooltip,
	Col,
	Form,
	Button,
} from "react-bootstrap";

import DateRangePicker from "../../components/DateRangePicker";

import BatchDetailsSidebar from "./BatchDetailsSidebar";
import CoordinatorOrderDetails from "./CoordinatorOrderDetails";
import Autocomplete from "./Components/AutoComplete";
import MergedEditType from "./Components/MergedModal";
import DuplicateImageSidebar from "./Components/DupicateImageModal";
import DuplicateSettings from "./Components/DuplicateSettings";
import DuplicateModalCount from "./Components/DuplicateModalCount";
import StatusModal from "./../../components/Modal/StatusModal";
import SingleRightSelector from "./Components/SingleRightSelector";
import RightSidebarMultiSelect from "./Components/MultipleSelectRightSideBar";

import RenameModal from "./Components/RenameModal";
import DeleteModalConfirmation from "../../components/Modal/DeleteModal";
// import UpdateDuplicateSettingsModal from "./Components/DuplicateSettings/UpdateDuplicateSettings";
import RightSidebar from "./RightSidebar";
import Select from "react-select";
import moment from "moment";
// import the custom components
import ToastAlert from "../../components/ToastAlert";

//Importing the images
import Delete from "./../../assets/Images/delete.svg";
import duplicate from "./../../assets/Images/coordinator_actions_icon/Duplicate _Final.svg";

import CancelIcon from "./../../assets/Images/coordinator_actions_icon/cancel_Final.svg";
import MoreIcon from "./../../assets/Images/coordinator_actions_icon/more-Final.svg";
import LinkIcon from "./../../assets/Images/coordinator_actions_icon/Link.svg";
import DocsIcon from "./../../assets/Images/coordinator_actions_icon/Doc.svg";
import Add from "./../../assets/Images/coordinator_actions_icon/Plus.svg";

// Importing API related files and routes
import { client } from "../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../data/filepath";
import {
	GET_ALL_COORDINATORS,
	ADMIN_GET_COORDINATORS_DETAILS,
	CO_ORDINATOR_LOGGED_TIME,
	CO_ORDINATOR_ASSIGNED_TO_EDITOR,
	CO_ORDINATOR_VIEW_PROFILE_SETTINGS,
	CO_ORDINATOR_DUPLICATE_IMAGE_RENAME,
	CO_ORDINATOR_DELETE_DUPLICATE_IMAGE,
	CO_ORDINATOR_ORIGINAL_IMAGE_RENAME,
	CO_ORDINATOR_FETCH_DUPLICATE_IMAGE,
	CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID,
} from "../../config/apiRoutes.api";
import {
	VIEW_COORDINATOR_WORKSPACE,
	VIEW_IMAGE_COORDINATOR_WORKSPACE,
} from "../../config/routes";

let imgAry = [];

const customSelectStyles = {
	menuList: (base) => ({
		...base,
		fontSize: "14px",
	}),
};

// Check all the status of editor assigned
const editorWorkingStatus = [
	"assign_to_editor",
	"assigned",
	"started",
	"hold",
	"qc",
	"error",
	"completed",
	"finished",
	"reassigned",
	"temporary-reassigned",
	"temporary-completed",
];
const CoordinatorWorkspace = () => {
	const initial_data = [
		{
			id: "",
			file_format: "",
			height: "",
			width: "",
			dpi: "",
			dpi_type: "",
			color_mode: "",
			alignment: "",
			margin_format: "",
			margin_top: "",
			margin_bottom: "",
			margin_right: "",
			margin_left: "",
		},
	];
	const selectdays = [
		{ label: "Today", value: 355 },
		{ label: "Custom", value: 54 },
	];
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
	// Retrieve the token from session storage
	const token = sessionStorage.getItem("token");
	//Role Checking
	const roleJson = sessionStorage.getItem("role");

	let folders_path_set =
		sessionStorage.getItem("folder") !== null &&
		sessionStorage.getItem("folder");
	let folder_path_default =
		sessionStorage.getItem("folder_path") !== null &&
		sessionStorage.getItem("folder_path");

	let folder_sub_path =
		sessionStorage.getItem("folder_path_sub") !== null &&
		sessionStorage.getItem("folder_path_sub");
	let folder_path_id =
		sessionStorage.getItem("folder_path_id") !== null &&
		sessionStorage.getItem("folder_path_id");
	let folderIndex =
		sessionStorage.getItem("folderIndex") !== null &&
		sessionStorage.getItem("folderIndex");
	let folder_list_Sub =
		sessionStorage.getItem("folder_list_1") !== undefined &&
		sessionStorage.getItem("folder_list_1");
	let main_folder_click = sessionStorage.getItem("main");

	const role = roleJson ? JSON.parse(roleJson) : null;
	const currentUserRoles = [role !== null ? role.role : "login"];

	const { id, batch_id_query, folder_id_query } = useParams();
	const currentUserId = id;

	// Get the navigation function from the useNavigate hook
	const navigate = useNavigate();

	// Create a request instance using the token
	const request = client(token);

	// Create a new FormData object for sending data in HTTP requests
	const bodyFormData = new FormData();
	const wrapper = useRef(null);
	//Declarations of state
	const [image_details_view, setImageDetailsView] = useState({});
	const [image_details_view_id, setImageDetailsViewId] = useState("");
	const [image_details_modal, setImageDetailsModal] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [multiple_images_id, setMultipleImagesId] = useState([]);
	const [folders, setFolder] = useState([]);
	const [parentFolderDetails, setParentFolderDetails] = useState(null);
	const [imagesMainFolder, setImageMainFolder] = useState([]);
	const [folderMainFolder, setFolderMainFolder] = useState([]);
	const [folderSubFolder, setFolderSubFolder] = useState([]);
	const [folder_files_sub, setFolderListSub] = useState([]);
	const [list_display, setDisplay] = useState(false);
	const [list_sub_display, setDisplaySub] = useState(false);
	const [folder_list, setFolderList] = useState([]);
	const [folder_name, setFolderName] = useState("");
	const [folder_list_menu, setFolderListMenu] = useState([]);
	const [folder_list_name, setFolderListName] = useState([]);
	const [loader_sub_menu, setLoaderSubMenu] = useState(false);
	const [folders_path, setFoldersPath] = useState([]);
	const [duplicate_image_modal, setDuplicateImageModal] = useState(false);
	const [merged_image_modal, setMergedImageModal] = useState(false);
	const [linked_image_modal, setLinkedImageModal] = useState(false);
	const [loader, setLoader] = useState(false);
	const [clickSelect, setClickSelect] = useState(false);
	const [options, setOptions] = useState([]);
	const [setting_profile, setSettingsProfile] = useState([]);
	const [edit_select, setEditorType] = useState("");

	const [batchNumber, setBatchNumber] = useState([]); // To set the batch number
	const [editGroups, setEditGroups] = useState([]); // To set the edit groups
	const [editTypes, setEditTypes] = useState([]); // To set the edit types
	const [images, setImages] = useState([]); // To set the images
	const [folder_path, setFolderPath] = useState([]); // To set the folder
	const [batchId, setBatchID] = useState(""); // To set the batch id
	const [error, setError] = useState("");
	const [sub_folder_open, setSubFolderOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [selectedOption, setSelectedOption] = useState({
		label: "Today",
		value: "today",
	});
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDate2, setSelectedDate2] = useState(new Date());
	const [date1, setDate1] = useState("");
	const [date2, setDate2] = useState("");
	const [customSelected, setCustomSelected] = useState(false);
	const [isDateSelected, setIsDateSelected] = useState(false);

	const [selectImageName, setSelectedImageName] = useState("");
	// const [reassignedModal, setReassignedModal] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [allImages, setAllImages] = useState([]);
	const [allImagesSelect, setAllImagesSelect] = useState([]);
	const [mainImageCount, setMainImageCount] = useState(0);
	const [mainImageSettingsCount, setMainImageSettingsCount] = useState(0);
	const [order_number, setOrderNumber] = useState("");
	//Timer logic
	const [timer, setTimer] = useState(null);
	const [elapsedTime, setElapsedTime] = useState(0);
	const [startTime, setStartTime] = useState(null);
	const [statusNew, setStatusNew] = useState(false);
	const [statusModal, setStatusModal] = useState(false);
	const [image_editor_response, setImageEditorResponse] = useState("");
	const [duplicate_amount, setDuplicateAmount] = useState(initial_data);
	const [amount, setAmount] = useState("");
	const [duplicate_settings, setDuplicateSettings] = useState(false);
	const [imageID, setImageID] = useState("");
	const [copyAmounts, setCopyAmounts] = useState([]);
	const [renameModalShow, setRenameModalShow] = useState(false);
	const [renameTextDuplicate, setRenameTextDuplicate] = useState("");
	const [imageExtensions, setImageExtensions] = useState("");
	const [imageName, setImageName] = useState("");
	const [renameId, setRenameId] = useState("");
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [originalImageId, setOriginalImageId] = useState("");
	const [updateSettingsModalShow, setShowupdateSettingsModal] = useState(false);
	const [viewDuplicateSettings, setViewDuplicateSettings] = useState([]);
	const [viewUpdateSettingsButton, setUpdateSettingsButtons] = useState(false);
	const [show_delete_by_id, setShowDeleteByID] = useState(false);
	const [hide_list_menu, setHideListMenu] = useState(true);
	const [showBatchLoader, setBatchLoader] = useState(true);
	const [activeBatch, setActiveBatch] = useState(0);
	const [activeBatchNumber, setActiveBatchNumber] = useState(0);
	const [defaultFolder, setDefaultFolder] = useState("");

	const [skipImagetLoad, setSkipImageCall] = useState(true);
	const [selectedImagesID, setSelectedImagesID] = useState([]);
	const [loaderSideBar, setLoaderSideBar] = useState(false);
	const [mainFolderId, setMainFolderId] = useState("");
	const [checked, setChecked] = useState(false);
	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [checked3, setChecked3] = useState(false);
	const [edit_id, setEditId] = useState([]);
	const [newSettingsClick, setNewSettingsClicked] = useState(false);
	const [determineCustomerView, setDeterminedCustomerView] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	// const [selectAll, setSelectAll] = useState(false);

	const [folders_list_sub, setFoldersListSub] = useState([]);
	const [doubleClickCount, setDoubleClickCount] = useState(0);
	const [colors, setColors] = useState([]);
	const [orders, setOrders] = useState([]);
	const [allFolders, setAllFolders] = useState([]);
	const [page, setPage] = useState(1); // Track the current page or offset
	const [folder_id, setFoldersId] = useState("");
	const [lastPage, setLastPage] = useState(2);
	const [folderIdList, setFolderIdList] = useState([]);
	const [idCollections, setIdCollections] = useState([]);
	const [folderIdCollections, setFolderIdCollections] = useState([]);
	const [folderList, setFoldersList] = useState([]);

	const aryDateFilter = [
		{ label: "Custom", value: "custom" },
		{ label: "Today", value: "today" },
		{ label: "Current Month", value: "month" },
		{ label: "Current Year", value: "year" },
		{ label: "All", value: "all" },
	];

	/**
	 * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} options - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (options) =>
		options.map(({ first_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]
	const getFormattedDate = (dateString) => {
		// Create a new Date object from the date string
		const dateObject = new Date(dateString);
		// Format the date as "day Month year" (e.g., "25 July 2023")
		const options = { day: "numeric", month: "long", year: "numeric" };
		const formattedDate = dateObject.toLocaleDateString(undefined, options);
		return formattedDate;
	};
	// Determine if the button should be disabled
	const isButtonDisabled =
		multiple_images_id.length > 0 || folder_list_name.length > 0;
	const handleDateChange = (date, setSelectedDate) => {
		setSelectedDate(date);
	};

	let editTypeId =
		images &&
		Array.isArray(images) &&
		images.map(function (currentObject) {
			return currentObject.id;
		}, {});
	let folderPathSet = folder_list.map(function (currentObject) {
		return currentObject.path;
	}, {});
	let idSelected =
		multiple_images_id !== undefined
			? multiple_images_id
					.filter((id) => !isNaN(id)) // Filter out non-numeric values
					.map((id) => parseInt(id)) // Convert to integers
					.join(",")
			: "";
	let folderPathSelected =
		folderPathSet !== undefined &&
		folderPathSet
			.slice(0, folderPathSet.length)
			.toString()
			.split(/(?=(?:\d{3})+(?:\.|$))/g)
			.join(",")
			.replace(/"/g, "");

	// useEffect(() => {
	// 	const currentUrl = window.location.href;
	// 	const storageKey = "folder_list_1";

	// 	if (!localStorage.getItem(storageKey)) {
	// 		localStorage.setItem(storageKey, "true");
	// 		window.location.reload();
	// 	}
	// }, []);
	const fetchDataImages = () => {
		if (!token) {
			return;
		}

		if (!folder_id_query) {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${id}` +
						`/${batch_id_query}`
				)
				.then((response) => {
					setMultipleImagesId([]);
					sessionStorage.setItem("main", 1);
					sessionStorage.setItem("folder_path_sub_left", "");
					setParentFolderDetails(null);

					setFolderList(response.data.data.folders);
					sessionStorage.setItem(
						"main_folder",
						response.data.data.folder_path_details.encrypted_id
					);
					// sessionStorage.setItem(
					// 	"folder",
					// 	JSON.stringify(response.data.data.folders)
					// );
					sessionStorage.setItem("folderIndex", 0);

					// setItemList(response.data.data.images);
					setImages(response.data.data.images.data);
					setFoldersPath([]);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);

					setDisplaySub((val) => !val);
					setClickSelect(false);
					setHideListMenu(false);
					// setFolderNameFirst([]);
					setFolderListName([]);

					// setFolderSelect([]);

					setFoldersPath([]);
					setEditTypes(response.data.data.editTypes);
					setEditGroups(response.data.data.editGroups);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		} else if (folder_id_query !== undefined) {
			request

				.get(
					apiURL +
						CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
						`/${id}` +
						`/${batch_id_query}` +
						`/${folder_id_query}`
				)
				.then((response) => {
					console.log("response folder", response);
					setFolderList(response.data.data.folders);
					setEditTypes(response.data.data.editTypes);
					setEditGroups(response.data.data.editGroups);
					setMultipleImagesId([]);

					setFoldersPath([]);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};

	const fetchOrderDuplicateSettings = () => {
		if (token) {
			request
				.get(
					apiURL +
						CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
						`/${id}` +
						`/${batch_id_query}` +
						`/${folder_id_query}`
				)
				.then((response) => {
					setFolderList(response.data.data.folders);
					setBatchNumber(response.data.data.batchNumbers);

					setImages(response.data.data.images.data);
					setFoldersPath([]);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);

					setDisplaySub(true);
					setMultipleImagesId([]);
					setClickSelect(false);
					// setHideListMenu(true);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};

	// const fetchFolder = useCallback(() => {
	// 	if (JSON.parse(folders_path_set).length > 0 && activeBatch) {
	// 		request
	// 			.get(
	// 				apiURL +
	// 					ADMIN_GET_COORDINATORS_DETAILS +
	// 					`/${currentUserId}` +
	// 					`?batch_number=${activeBatch}` +
	// 					`&folder_path=${JSON.parse(folders_path_set)[folderIndex].path}`
	// 			)
	// 			.then((response) => {
	// 				if (response.data.data.folders.length > 0) {
	// 					const foldersJsonString = JSON.stringify(
	// 						response.data.data.folders
	// 					);
	// 					console.log("folder path", foldersJsonString);
	// 					sessionStorage.setItem("folder_list_1", foldersJsonString);
	// 					// setHideListMenu(true);
	// 					// setLoaderSideBar(false);
	// 					setClickSelect(false);
	// 					setCustomSelected(false);
	// 					setEditGroups(response.data.data.editGroups);
	// 					setEditTypes(response.data.data.editTypes);
	// 					// sessionStorage.setItem("folder_path", "main");
	// 				} else {
	// 					// Handle the case when the parsed JSON is not an array or is empty
	// 					console.error("Invalid or empty JSON array.");
	// 				}
	// 			})
	// 			.catch((err) => {
	// 				let message =
	// 					typeof err.response !== "undefined"
	// 						? err.response.data.message
	// 						: err.message;
	// 				setError(message);
	// 			});
	// 	}
	// }, [folders_path_set, folderIndex]);

	const fetchFolderOnUpdate = () => {
		request
			.get(
				apiURL +
					ADMIN_GET_COORDINATORS_DETAILS +
					`/${currentUserId}` +
					`?batch_number=${batchId}`
			)
			.then((response) => {
				setParentFolderDetails(null);
				setFolderName("");
				setBatchNumber(response.data.data.batchNumbers);
				const batchNumbers = response.data.data.batchNumbers;
				const filteredBatch = batchNumbers.find(
					(entry) => entry.batch_number === search
				);

				if (filteredBatch) {
					const { id } = filteredBatch;
					setActiveBatch(id);
					setActiveBatchNumber(id);

					setBatchID(id);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setImages(response.data.data.images.data);
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
				}
			})
			.catch((err) => {
				let message =
					typeof err.response !== "undefined"
						? err.response.data.message
						: err.message;
				setError(message);
			});
	};

	// useEffect(() => {
	// 	const parseAndFetchFolders = async () => {
	// 		let foldersPathSetParsed;

	// 		try {
	// 			foldersPathSetParsed = JSON.parse(folders_path_set);
	// 		} catch (e) {
	// 			console.error("Failed to parse folders_path_set:", e);
	// 			return;
	// 		}

	// 		if (
	// 			foldersPathSetParsed &&
	// 			Array.isArray(foldersPathSetParsed) &&
	// 			foldersPathSetParsed.length > 0
	// 		) {
	// 			const folderPath = foldersPathSetParsed[0].path;

	// 			if (folderPath) {
	// 				try {
	// 					const response = await request.get(
	// 						`${apiURL}${ADMIN_GET_COORDINATORS_DETAILS}/${currentUserId}?batch_number=${activeBatch}&folder_path=${folderPath}`
	// 					);
	// 					const { folders } = response.data.data;

	// 					if (
	// 						Array.isArray(folders) &&
	// 						folders.length > 0
	// 						// Number(main_folder_click) === 1
	// 					) {
	// 						const foldersJsonString = JSON.stringify(folders);
	// 						console.log("folder path", foldersJsonString);
	// 						sessionStorage.setItem("folder_list_1", foldersJsonString);
	// 						// sessionStorage.setItem("main", 1);
	// 						setFolderListMenu(folders);
	// 					} else if (
	// 						Array.isArray(folders) &&
	// 						folders.length > 0
	// 						// Number(main_folder_click) === 0
	// 					) {
	// 						const foldersJsonString = JSON.stringify(folders);
	// 						console.log("folder path", foldersJsonString);
	// 						sessionStorage.setItem("folder_list_1", foldersJsonString);
	// 						// sessionStorage.setItem("main", 0);
	// 					} else {
	// 						console.error("Invalid or empty JSON array.");
	// 					}
	// 				} catch (err) {
	// 					const message = err.response
	// 						? err.response.data.message
	// 						: err.message;
	// 					setError(message);
	// 				}
	// 			}
	// 		}
	// 	};

	// 	parseAndFetchFolders();
	// }, [
	// 	folders_path_set,
	// 	apiURL,
	// 	ADMIN_GET_COORDINATORS_DETAILS,
	// 	currentUserId,
	// 	activeBatch,
	// 	setFolderListMenu,
	// 	setError,
	// 	Number(main_folder_click) === 1 || 0,
	// ]);

	const batchClick = (batchId, batch_number, currentUserId) => {
		setLoaderSideBar(true);
		setFoldersId("");
		sessionStorage.setItem("folderIndex", 0);
		sessionStorage.setItem("folder_path_sub_left", "");
		setParentFolderDetails(null);
		sessionStorage.setItem("doubleClickCount", 0);

		sessionStorage.setItem("main", 1);
		sessionStorage.setItem("folderIndex", 0);
		navigate(`${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${id}/${batch_number}`);

		request
			.get(
				apiURL +
					ADMIN_GET_COORDINATORS_DETAILS +
					`/${currentUserId}` +
					`?batch_number=${batch_number}`
			)
			.then((response) => {
				setActiveBatch(batch_number);
				// setFoldersId("");
				// setFolderName(response.data.data.folders[0].path);
				// // sessionStorage.setItem(
				// // 	"folder_path_sub",
				// // 	response.data.data.folders[0].path
				// // );
				// sessionStorage.setItem(
				// 	"folder",
				// 	JSON.stringify(response.data.data.folders)
				// );
				// setFolderList(response.data.data.folders);
				// setFolder(response.data.data.folders);
				// setImages(response.data.data.images.data);

				// // setDefaultFolder(response.data.data.folders[0].path);

				// setLoaderSideBar(false);
				// //
				// setParentFolderDetails(null);
				// setFolderName("");

				// setEditGroups(
				// 	response.data.data.editGroups ? response.data.data.editGroups : []
				// );
				// setEditTypes(
				// 	response.data.data.editTypes ? response.data.data.editTypes : []
				// );
				// setBatchNumber(response.data.data.batchNumbers);

				// // sessionStorage.setItem(
				// // 	"folder",
				// // 	JSON.stringify(response.data.data.folders)
				// // );
				// setImageMainFolder(response.data.data.images);
				// setFolderMainFolder(response.data.data.folders);
				// setMainImageCount(response.data.data.mainImageCount);
				// setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
				// setOrderNumber(response.data.data.batchNumbers[0].order_id);
				// setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
				// setDisplay(false);
				// setMultipleImagesId([]);
				// setClickSelect(false);
				if (!!Array.isArray(response.data.data.folders)) {
					sessionStorage.setItem(
						"folder",
						JSON.stringify(response.data.data.folders)
					);
					setFoldersList(response.data.data.folders);
					setImages(response.data.data.images.data);
					setFolderList(response.data.data.folders);
				} else {
					sessionStorage.setItem("folder", []);
					sessionStorage.setItem("folder_set", []);

					setFolderListSub([]);
				}

				// setDefaultFolder(response.data.data.folders[0].path);

				// setLoaderSideBar(false);
				//
				setParentFolderDetails(null);
				setFolderName("");

				// setEditGroups(
				// 	response.data.data.editGroups ? response.data.data.editGroups : []
				// );
				// setEditTypes(
				// 	response.data.data.editTypes ? response.data.data.editTypes : []
				// );
				// setBatchNumber(response.data.data.batchNumbers);

				// setFolder(response.data.data.folders);
				// sessionStorage.setItem(
				// 	"folder",
				// 	JSON.stringify(response.data.data.folders)
				// );
				// setImageMainFolder(response.data.data.images);
				// setFolderMainFolder(response.data.data.folders);
				// setMainImageCount(response.data.data.mainImageCount);
				// setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
				// setOrderNumber(response.data.data.batchNumbers[0].order_id);
				// setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
				setDisplay(false);
				setMultipleImagesId([]);
				setClickSelect(false);
			})
			.catch((err) => console.log(err));
	};
	useEffect(() => {
		if (token && folder_id_query) {
			request
				.get(
					apiURL +
						CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
						`/${id}` +
						`/${batch_id_query}` +
						`/${folder_id_query}`
				)
				.then((response) => {
					setFolder(response.data.data.folders);
					setAllImages(response.data.data.currentFolderImageIds);
					setAllImagesSelect(response.data.data.allImages);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);

					setActiveBatch(response.data.data.batchNumbers[0].id);
					setMultipleImagesId([]);
					setClickSelect(false);
					setCustomSelected(false);
					setFolderList(response.data.data.folders);

					setParentFolderDetails(null);

					setMultipleImagesId([]);
					setClickSelect(false);
					setDisplaySub(true);
					setFoldersPath([]);

					setBatchNumber(response.data.data.batchNumbers);

					setDisplay(true);

					setImages(response.data.data.images.data);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
				})
				.catch((err) => console.log(err));
		}
	}, [token, folder_id_query]);

	useEffect(() => {
		if (!folder_id_query) {
			setLoaderSideBar(true);

			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?batch_number=${batch_id_query}`
				)
				.then((response) => {
					setFolderName(response.data.data.folders[0].path);

					setDefaultFolder(response.data.data.folders[0].path);
					setLoaderSideBar(false);

					setParentFolderDetails(null);
					setFolderName("");

					setEditGroups(
						response.data.data.editGroups ? response.data.data.editGroups : []
					);
					setEditTypes(
						response.data.data.editTypes ? response.data.data.editTypes : []
					);
					setBatchNumber(response.data.data.batchNumbers);
					// if (Array.isArray(response.data.data.images)) {
					setImages(response.data.data.images);
					// }
					// if (Array.isArray(response.data.data.folders)) {
					setFolderList(response.data.data.folders);
					setFolder(response.data.data.folders);
					// }

					sessionStorage.setItem("folderIndex", 0);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
				})
				.catch((err) => console.log(err));
		}
	}, [batch_id_query, id, !folder_id_query]);
	const fetchFolder = () => {
		if (!folder_id_query && id) {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${id}` +
						`?batch_number=${batch_id_query}`
				)
				.then((response) => {
					sessionStorage.setItem("main", 1);
					sessionStorage.setItem("folder_path_sub_left", "");
					setParentFolderDetails(null);
					// fetchCheckoutPrice();
					setFolderList(response.data.data.folders);
					setOrders(response.data.data.order);
					setDeterminedCustomerView(
						Number(response.data.data.order.order_type)
					);

					sessionStorage.setItem(
						"main_folder",
						response.data.data.folder_path_details.encrypted_id
					);
					// sessionStorage.setItem(
					// 	"folder",
					// 	JSON.stringify(response.data.data.folders)
					// );
					sessionStorage.setItem("folderIndex", 0);

					// setItemList(response.data.data.images);
					setImages(response.data.data.images.data);
					setColors(response.data.data.images);
					setFoldersPath([]);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					// setItemList(response.data.data.images.data);

					setDisplaySub((val) => !val);
					// setMultipleImagesId([]);
					setClickSelect(false);
					setHideListMenu(false);
					// setFolderNameFirst([]);
					setFolderListName([]);

					// setFolderSelect([]);

					setFoldersPath([]);
					setEditTypes(response.data.data.editTypes);
					setEditGroups(response.data.data.editGroups);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		} else if (folder_id_query && id) {
			request

				.get(
					apiURL +
						CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
						`/${id}` +
						`/${batch_id_query}` +
						`/${folder_id_query}`
				)
				.then((response) => {
					console.log("response folder", response);
					setFolderList(response.data.data.folders);
					// sessionStorage.setItem(
					// 	"folder",
					// 	JSON.stringify(response.data.data.folders)
					// );
					setEditTypes(response.data.data.editTypes);
					setOrders(response.data.data.order);
					setEditGroups(response.data.data.editGroups);
					setColors(response.data.data.images);
					// setItemList(response.data.data.images.data);
					setImages(response.data.data.images.data);
					setDeterminedCustomerView(
						Number(response.data.data.order.order_type)
					);
					// fetchCheckoutPrice();
					setFoldersPath([]);
					setMultipleImagesId([]);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const selectAllFoldersCheck = () => {
		const allFoldersPath =
			allFolders !== undefined &&
			Array.isArray(allFolders) &&
			allFolders.map((items) => items.path);
		const allFoldersEncryptedId =
			allFolders !== undefined &&
			Array.isArray(allFolders) &&
			allFolders.map((items) => items.folder_details.encrypted_id);
		setFolderListName(allFoldersPath);
		setFolderIdList(allFoldersEncryptedId);
		setFoldersPath(allFoldersPath);
	};
	// useEffect(() => {
	// 	if (folder_id_query !== undefined) {
	// 		const parseAndFetchFolders = async () => {
	// 			let foldersPathSetParsed;

	// 			try {
	// 				foldersPathSetParsed = JSON.parse(folders_path_set);
	// 			} catch (e) {
	// 				console.error("Failed to parse folders_path_set:", e);
	// 				return;
	// 			}

	// 			if (
	// 				foldersPathSetParsed &&
	// 				Array.isArray(foldersPathSetParsed) &&
	// 				foldersPathSetParsed.length > 0
	// 			) {
	// 				const folderPath = foldersPathSetParsed[0].path;

	// 				if (folderPath) {
	// 					try {
	// 						const response = await request.get(
	// 							// apiURL +
	// 							// 	CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
	// 							// 	`/${id}?folder_path=${
	// 							// 		JSON.parse(folders_path_set)[folderIndex].path
	// 							// 	}`
	// 							apiURL +
	// 								CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
	// 								`/${id}` +
	// 								`/${batch_id_query}` +
	// 								`/${folder_id_query}`
	// 						);
	// 						const { folders } = response.data.data;

	// 						if (Array.isArray(folders) && folders.length > 0) {
	// 							const foldersJsonString = JSON.stringify(folders);
	// 							console.log("folder path", foldersJsonString);
	// 							sessionStorage.setItem("folder_list_1", foldersJsonString);

	// 							setFolderListMenu(folders);
	// 						} else if (Array.isArray(folders) && folders.length > 0) {
	// 							const foldersJsonString = JSON.stringify(folders);
	// 							console.log("folder path", foldersJsonString);
	// 							sessionStorage.setItem("folder_list_1", foldersJsonString);
	// 							setFolderListMenu(folders);
	// 						} else {
	// 							console.error("Invalid or empty JSON array.");
	// 						}
	// 					} catch (err) {
	// 						const message = err.response
	// 							? err.response.data.message
	// 							: err.message;
	// 						setError(message);
	// 					}
	// 				}
	// 			}
	// 		};

	// 		parseAndFetchFolders();
	// 	}
	// }, [
	// 	apiURL,
	// 	CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID,
	// 	folders_path_set,
	// 	folder_id_query,
	// 	setFolderListMenu,
	// ]);
	// useEffect(() => {
	// 	if (id && !folder_id_query) {
	// 		sessionStorage.setItem("main", 1);
	// 	}
	// }, []);
	// useEffect(() => {
	// 	sessionStorage.setItem("folder_set", JSON.stringify(folders_list_sub));
	// }, [folders_list_sub]);
	// useEffect(() => {
	// 	if (token && id && folder_id_query !== undefined) {
	// 		request

	// 			.get(
	// 				apiURL +
	// 					CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
	// 					`/${id}` +
	// 					`/${batch_id_query}` +
	// 					`/${folder_id_query}`
	// 			)
	// 			.then((response) => {
	// 				console.log("response folder", response);
	// 				setFolderList(response.data.data.folders);
	// 				setBatchNumber(response.data.data.batchNumbers);
	// 				setAllFolders(response.data.data.folders);
	// 				setOrders(response.data.data.order);
	// 				setDeterminedCustomerView(
	// 					Number(response.data.data.order.order_type)
	// 				);
	// 				// setItemList(response.data.data.images.data);
	// 				setImages(response.data.data.images.data);
	// 				setEditTypes(response.data.data.editTypes);
	// 				setEditGroups(response.data.data.editGroups);
	// 				// sessionStorage.setItem(
	// 				// 	"folder",
	// 				// 	JSON.stringify(response.data.data.folders)
	// 				// );
	// 				const newFolder = {
	// 					id: folder_id_query,
	// 					name: folderPath,
	// 					otherKey: response.data.data.folders,
	// 				};
	// 				// Add the new folder object to the state, ensuring no duplicates based on name
	// 				setFoldersListSub((prevFoldersListSub) => {
	// 					// Check if a folder with the same name already exists
	// 					const folderExists = prevFoldersListSub.some(
	// 						(folder) => folder.name === newFolder.name
	// 					);

	// 					// If it exists, return the previous state; otherwise, add the new folder
	// 					if (folderExists) {
	// 						return prevFoldersListSub;
	// 					}

	// 					// Combine the existing folders and the new folder
	// 					const updatedFolders = [...prevFoldersListSub, newFolder];

	// 					// Create a Map to deduplicate folders based on their id
	// 					const deduplicatedFolders = updatedFolders.reduce((acc, folder) => {
	// 						acc.set(folder.id, folder); // Use the folder's id as the key
	// 						return acc;
	// 					}, new Map());

	// 					// Convert the Map values back to an array
	// 					return Array.from(deduplicatedFolders.values());
	// 				});

	// 				// setFoldersPath([]);
	// 			})
	// 			.catch((err) => {
	// 				let message =
	// 					typeof err.response !== "undefined"
	// 						? err.response.data.message
	// 						: err.message;
	// 				setError(message);
	// 			});
	// 	}
	// }, [token, id, folder_id_query, batch_id_query]);
	useEffect(() => {
		if (token && search !== "" && id && !folder_id_query) {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?search_key=${search}`
				)

				.then((response) => {
					setParentFolderDetails(null);
					// setFolderName("");
					sessionStorage.setItem("main", 1);
					sessionStorage.setItem(
						"main_folder",
						response.data.data.folder_path_details.encrypted_id
					);
					// setBatchNumber(response.data.data.batchNumbers);
					const batchNumbers = response.data.data.batchNumbers;
					const filteredBatch = batchNumbers.find(
						(entry) => entry.batch_number === search
					);

					if (filteredBatch) {
						const { id } = filteredBatch;
						setActiveBatch(id);
						setActiveBatchNumber(id);

						setBatchID(id);
						setEditGroups(response.data.data.editGroups);
						setEditTypes(response.data.data.editTypes);
						setImages(response.data.data.images.data);
						setFolderList(response.data.data.folders);
						setFolder(response.data.data.folders);
						setImageMainFolder(response.data.data.images);
						setFolderMainFolder(response.data.data.folders);
						setMainImageCount(response.data.data.mainImageCount);
						setMainImageSettingsCount(
							response.data.data.mainImageSettingsCount
						);
						setOrderNumber(response.data.data.batchNumbers[0].order_id);
						setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
						setDisplay(false);
						setMultipleImagesId([]);
						setClickSelect(false);
					}
				})
				.catch((err) => console.log(err));
		} else if (
			token &&
			search === "" &&
			id &&
			batch_id_query &&
			!folder_id_query
		) {
			request
				.get(
					apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${currentUserId}`
					// `?batch_number=${batch_id_query}`
				)
				.then((response) => {
					setParentFolderDetails(null);
					setMainFolderId(response.data.data.folder_path_details.encrypted_id);
					sessionStorage.setItem(
						"main_folder",
						response.data.data.folder_path_details.encrypted_id
					);
					console.log(
						"call the path ",
						response.data.data.folder_path_details.encrypted_id ==
							folder_id_query
					);
					// sessionStorage.setItem("main", 1);
					// setFolderName("");
					setBatchNumber(response.data.data.batchNumbers);
					// setActiveBatch(response.data.data.batchNumbers[0].id);
					setActiveBatchNumber(response.data.data.batchNumbers[0].batch_number);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setImages(response.data.data.images.data);
					setFolderList(response.data.data.folders);
					// sessionStorage.setItem(
					// 	"folder",
					// 	JSON.stringify(response.data.data.folders)
					// );
					sessionStorage.setItem("folderIndex", 0);

					setFolder(response.data.data.folders);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
					setSelectAll(false);
				})
				.catch((err) => console.log(err));
		} else if (
			token &&
			search === "" &&
			id &&
			!batch_id_query &&
			!folder_id_query
		) {
			request
				.get(
					apiURL + ADMIN_GET_COORDINATORS_DETAILS + `/${currentUserId}`
					// `?batch_number=${batch_id_query}`
				)
				.then((response) => {
					setParentFolderDetails(null);
					setMainFolderId(response.data.data.folder_path_details.encrypted_id);
					sessionStorage.setItem(
						"main_folder",
						response.data.data.folder_path_details.encrypted_id
					);
					console.log(
						"call the path ",
						response.data.data.folder_path_details.encrypted_id ==
							folder_id_query
					);
					// sessionStorage.setItem("main", 1);
					// setFolderName("");
					setBatchNumber(response.data.data.batchNumbers);
					// setActiveBatch(response.data.data.batchNumbers[0].id);
					setActiveBatchNumber(response.data.data.batchNumbers[0].batch_number);
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);
					setImages(response.data.data.images.data);
					setFolderList(response.data.data.folders);
					// sessionStorage.setItem(
					// 	"folder",
					// 	JSON.stringify(response.data.data.folders)
					// );
					sessionStorage.setItem("folderIndex", 0);

					setFolder(response.data.data.folders);
					setImageMainFolder(response.data.data.images);
					setFolderMainFolder(response.data.data.folders);
					setMainImageCount(response.data.data.mainImageCount);
					setMainImageSettingsCount(response.data.data.mainImageSettingsCount);
					setOrderNumber(response.data.data.batchNumbers[0].order_id);
					setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
					setDisplay(false);
					setMultipleImagesId([]);
					setClickSelect(false);
					setSelectAll(false);
				})
				.catch((err) => console.log(err));
		}
	}, [!folder_id_query, !batch_id_query, search]);
	// useEffect(() => {
	// 	if ((id, !folder_id_query, !batch_id_query)) {
	// 		sessionStorage.setItem("main", 1);
	// 	}
	// }, []);

	// const selectAllImages = () => {
	// 	const allImageIds =
	// 		images !== undefined &&
	// 		images
	// 			.filter(
	// 				(item) => String(item.id) && !checkEditorWorkProgress(item.status)
	// 			)
	// 			.map((obj) => obj.id);

	// 	const allFolderNames =
	// 		folder_list !== undefined && folder_list.map((items) => items.path);
	// 	// Set the folderListName state to contain all folder names.
	// 	setFolderListName(allFolderNames);

	// 	// Set the foldersPath state to contain all folder names.
	// 	setFoldersPath(allFolderNames);
	// 	setMultipleImagesId(allImageIds);
	// };

	// // Use useEffect to handle "Select All" checkbox changes
	// useEffect(() => {
	// 	if (selectAll) {
	// 		selectAllImages();
	// 	} else {
	// 		setMultipleImagesId([]);
	// 		setFolderListName([]);
	// 		setFoldersPath([]);
	// 		setFolderName("");
	// 		setParentFolderDetails(null);
	// 	}
	// }, [selectAll]);
	function getPath(path) {
		// Example logic to determine the path
		let determinedPath = path; // Example path
		return determinedPath;
	}
	function mainFunction(path_id) {
		let path = getPath(path_id);

		if (path === null) {
			return; //Path is null, exiting function
		}

		let foldersPathSet = sessionStorage.getItem("folders_path_set");
		let folderIndex = parseInt(sessionStorage.getItem("folderIndex"), 10);

		if (foldersPathSet && !isNaN(folderIndex)) {
			try {
				let parsedFolders = JSON.parse(foldersPathSet);

				if (
					parsedFolders[folderIndex] &&
					parsedFolders[folderIndex].path !== undefined
				) {
					if (parsedFolders[folderIndex].path !== path) {
						sessionStorage.setItem("folder_path_sub_left", path);
						sessionStorage.setItem("folder_path_id", "");
					} else {
						console.log("Paths are the same, no update needed");
					}
				} else {
					console.error("Invalid folderIndex or folder path not defined");
				}
			} catch (e) {
				console.error("Error parsing JSON:", foldersPathSet, e);
			}
		} else {
			console.error("Invalid folders_path_set or folderIndex");
		}
	}

	const handleFolderClick = useCallback(
		(item, path, parent_id, folder_id) => {
			if (folder_id !== undefined) {
				setMultipleImagesId([]);
				setDisplaySub((val) => !val);
				setFoldersId(folder_id);

				mainFunction();
				setDoubleClickCount(doubleClickCount + 1);
				sessionStorage.setItem("doubleClickCount", doubleClickCount + 1);
				let count = sessionStorage.getItem("doubleClickCount");

				const parsedFoldersPathSet =
					folders_path_set.length && folders_path_set;

				if (
					parsedFoldersPathSet !== undefined &&
					parsedFoldersPathSet[folderIndex].path !== path
				) {
					sessionStorage.setItem("folder_path_sub_left", path);
					sessionStorage.setItem("folder_path_id", "");
				}
				sessionStorage.setItem("main", 0);

				sessionStorage.setItem("folder_path_id", item);
				sessionStorage.setItem("folder_path_sub_left", path);

				navigate(`${VIEW_IMAGE_COORDINATOR_WORKSPACE}/${id}/${batchId}${item}`);
				if (token) {
					request

						.get(
							apiURL +
								CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
								`/${id}` +
								`/${batch_id_query}` +
								`/${item}`
						)
						.then((response) => {
							setFolderList(response.data.data.folders);
							setBatchNumber(response.data.data.batchNumbers);

							setFolderName(path);

							setImages(response.data.data.images.data);
							setHideListMenu(true);
							setFoldersPath([]);
							const newFolder = {
								id: item,
								name: path,
								parent_id: parent_id,
								otherKey: response.data.data.folders,
								otherKey_1: response.data.data.folders,
							};

							// Add the new folder object to the state, ensuring no duplicates based on name or id
							setFoldersListSub((prevFoldersListSub) => {
								// Check if a folder with the same name or id already exists
								const folderExists = prevFoldersListSub.some(
									(folder) =>
										folder.name === newFolder.name || folder.id === newFolder.id
								);

								// If it exists, return the previous state; otherwise, add the new folder
								if (folderExists) {
									return prevFoldersListSub;
								}

								// Combine the existing folders and the new folder
								const updatedFolders = [...prevFoldersListSub, newFolder];

								// Create a Map to deduplicate folders based on their id
								const deduplicatedFolders = updatedFolders.reduce(
									(acc, folder) => {
										acc.set(folder.id, folder); // Use the folder's id as the key
										return acc;
									},
									new Map()
								);

								// Convert the Map values back to an array
								return Array.from(deduplicatedFolders.values());
							});

							// const newFolder = {
							// 	id: item,
							// 	name: path,
							// 	parent_id: parent_id,
							// 	otherKey: response.data.data.folders,
							// 	otherKey_1: response.data.data.folders,
							// };
							// // Add the new folder object to the state, ensuring no duplicates based on name
							// setFoldersListSub((prevFoldersListSub) => {
							// 	// Check if a folder with the same name already exists
							// 	const folderExists = prevFoldersListSub.some(
							// 		(folder) => folder.name === newFolder.name
							// 	);

							// 	// If it exists, return the previous state; otherwise, add the new folder
							// 	if (folderExists) {
							// 		return prevFoldersListSub;
							// 	}

							// 	// Combine the existing folders and the new folder
							// 	const updatedFolders = [...prevFoldersListSub, newFolder];

							// 	// Create a Map to deduplicate folders based on their id
							// 	const deduplicatedFolders = updatedFolders.reduce(
							// 		(acc, folder) => {
							// 			acc.set(folder.id, folder); // Use the folder's id as the key
							// 			return acc;
							// 		},
							// 		new Map()
							// 	);

							// 	// Convert the Map values back to an array
							// 	return Array.from(deduplicatedFolders.values());
							// });

							// // Add the new folder object to the state
							// setFoldersListSub((prevFoldersListSub) => [
							// 	...prevFoldersListSub,
							// 	// newFolder,
							// ]);
						})
						.catch((err) => {
							let message =
								typeof err.response !== "undefined"
									? err.response.data.message
									: err.message;
							setError(message);
						});
				}
			}
		},
		[folder_id_query, batch_id_query, id]
	);

	// const handleFolderClick = useCallback(
	// 	(item, path) => {
	// 		setMultipleImagesId([]);
	// 		if (JSON.parse(folders_path_set)[folderIndex].path !== path) {
	// 			sessionStorage.setItem("folder_path_sub_left", path);
	// 			sessionStorage.setItem("folder_path_id", "");
	// 		}
	// 		sessionStorage.setItem("main", 0);

	// 		sessionStorage.setItem("folder_path_id", item);
	// 		sessionStorage.setItem("folder_path_sub", item);

	// 		let allImages = document.querySelectorAll(".allimages");
	// 		for (var i = 0; i < allImages.length; i++) {
	// 			allImages[i].style.zIndex = 1;
	// 		}
	// 		if (token) {
	// 			request
	// 				.get(
	// 					apiURL +
	// 						CO_ORDINATOR_VIEW_BASED_BY_FOLDER_ID +
	// 						`/${id}` +
	// 						`/${batch_id_query}` +
	// 						`/${folder_id_query}`
	// 				)
	// 				.then((response) => {
	// 					setFolderList(response.data.data.folders);
	// 					setBatchNumber(response.data.data.batchNumbers);

	// 					setImages(response.data.data.images);
	// 					setFoldersPath([]);
	// 					setEditGroups(response.data.data.editGroups);
	// 					setEditTypes(response.data.data.editTypes);

	// 					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
	// 					setDisplay(true);

	// 					setDisplaySub(true);
	// 					setMultipleImagesId([]);
	// 					setClickSelect(false);
	// 					// setHideListMenu(true);
	// 				})
	// 				.catch((err) => {
	// 					let message =
	// 						typeof err.response !== "undefined"
	// 							? err.response.data.message
	// 							: err.message;
	// 					setError(message);
	// 				});
	// 		}
	// 	},
	// 	[folder_id_query, batch_id_query, id]
	// );
	// useEffect(() => {
	// 	if (folder_id_query !== undefined) {
	// 		sessionStorage.setItem("folder_path_id", folder_id_query);
	// 	}
	// }, [folder_id_query]);

	useEffect(() => {
		let dateSelectionType =
			selectedOption && selectedOption.value ? selectedOption.value : "all";
		let sendRequest = true;
		const fromYear =
			dateSelectionType && dateSelectionType === "custom"
				? moment(selectedDate).format("YYYY-MM-DD")
				: "";
		const toYear =
			dateSelectionType === "custom"
				? moment(selectedDate2).format("YYYY-MM-DD")
				: "";
		if (dateSelectionType === "custom" && toYear !== "") {
			dateSelectionType = fromYear;
			if (!customSelected) {
				sendRequest = false;
			}
		}
		// Check if a token is available
		// Make an HTTP GET request to fetch the branch data with the specified page number
		if (sendRequest) {
			request
				.get(
					apiURL +
						ADMIN_GET_COORDINATORS_DETAILS +
						`/${currentUserId}` +
						`?date=${dateSelectionType}` +
						`&from_date=${fromYear}` +
						`&to_date=${toYear}`
				)
				.then((response) => {
					setParentFolderDetails(null);
					if (Array.isArray(response.data.data.folders)) {
						setFolderList(response.data.data.folders);
					}
					setEditGroups(response.data.data.editGroups);
					setEditTypes(response.data.data.editTypes);

					if (Array.isArray(response.data.data.images.data)) {
						setImages(response.data.data.images.data);
					}

					setSubFolderOpen(true); // Clear subFolderDetails as we are at the parent level
					setDisplay(true);
					setFolderName("");
					setFolderListMenu(response.data.data.folders);
					setMultipleImagesId([]);
					setClickSelect(false);
					setCustomSelected(false);
				})
				.catch((err) => console.log(err));
		}
	}, [
		selectedDate2,
		selectedOption,
		customSelected,
		clickSelect,
		!folder_id_query,
	]);

	let folderPath = folder_list.map(function (currentObject) {
		return currentObject.path;
	}, {});
	const foldersPathSelected =
		multiple_images_id !== undefined &&
		folders_path
			.slice(0, folderPath.length)
			.toString()
			.split(/(?=(?:\d{3})+(?:\.|$))/g)
			.join(",")
			.replace(/"/g, "");
	const startTimer = () => {
		setTimer(
			setInterval(() => {
				// Increment elapsed time every second
				setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
			}, 1000)
		);
	};

	const stopTimer = () => {
		clearInterval(timer);
	};

	const formatTimeInSeconds = (timeInSeconds) => {
		return timeInSeconds;
	}; // return the time in seconds
	const formatStartTime = (startTime) => {
		const date = new Date(startTime);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const day = date.getDate().toString().padStart(2, "0");
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		const seconds = date.getSeconds().toString().padStart(2, "0");

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};
	const handleCustomerLogged = () => {
		if (token) {
			// Continue with API request
			bodyFormData.append("image_ids", idSelected);
			bodyFormData.append("batch_number", batchId);
			bodyFormData.append("start_time", formatStartTime(startTime));
			bodyFormData.append("time_spend", formatTimeInSeconds(elapsedTime));

			request
				.post(apiURL + CO_ORDINATOR_LOGGED_TIME, bodyFormData)
				.then((response) => {
					// Set the success from the response
					// setStatus("Successfully Group updated");
					// setLoader(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleAssignedImagesToEditor = async (idSelected) => {
		if (token) {
			bodyFormData.append("image_ids", idSelected);
			const assign_to_editor = await request
				.post(apiURL + CO_ORDINATOR_ASSIGNED_TO_EDITOR, bodyFormData)
				.then((response) => {
					// Show Toast alert message
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Successfully assigned" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});
					if (response.data.status) {
						request
							.get(
								apiURL +
									ADMIN_GET_COORDINATORS_DETAILS +
									`/${currentUserId}` +
									`?batch_number=${batchId}`
							)
							.then((response) => {
								setParentFolderDetails(null);
								setFolderName("");
								// setEditGroups(response.data.data.editGroups);
								// setEditTypes(response.data.data.editTypes);
								setBatchNumber(response.data.data.batchNumbers);
								setImages(response.data.data.images.data);
								setFolderList(response.data.data.folders);
								setFolder(response.data.data.folders);
								setImageMainFolder(response.data.data.images);
								setFolderMainFolder(response.data.data.folders);
								setMainImageCount(response.data.data.mainImageCount);
								setMainImageSettingsCount(
									response.data.data.mainImageSettingsCount
								);
								setOrderNumber(response.data.data.batchNumbers[0].order_id);
								setSubFolderOpen(false); // Clear subFolderDetails as we are at the parent level
								setDisplay(false);
								setMultipleImagesId([]);
								setClickSelect(false);
							})
							.catch((err) => console.log(err));
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
			return assign_to_editor;
		}
	};

	const handleChangeDuplicateAmount = (e) => {
		setAmount(e.target.value);
		setUpdateSettingsButtons(false);
	}; // add an object with specific amount given
	/************************Auto logout Logic*****/
	const autoLogout = sessionStorage.getItem("auto-logout");
	useEffect(() => {
		if (autoLogout) {
			handleCustomerLogged();
			stopTimer(); // Stop the timer for the previous card
		}
	}, [autoLogout]);
	/***************End of Auto logout */
	useEffect(() => {
		let duplicateAmountArray = [];
		if (amount > 0) {
			for (let i = 0; i < amount; i++) {
				duplicateAmountArray.push({ id: i });
			}
		}
		setDuplicateAmount(duplicateAmountArray);
	}, [amount, setDuplicateAmount]);

	const handleFetchProfileSettings = async (imageID) => {
		if (!token || !imageID) return; // Exit early if conditions aren't met

		const response = await request
			.get(apiURL + CO_ORDINATOR_VIEW_PROFILE_SETTINGS + `/${imageID}`)
			.then((response) => {
				let { data } = response;
				if (data) {
					setSettingsProfile(data.image);
					setEditorType(data.image.edit_group_id);
				}
			})
			.catch((err) => {
				let message =
					typeof err.response !== "undefined"
						? err.response.data.message
						: err.message;
				setError(message);
			});
		return response;
	};
	useEffect(() => {
		if (imageID && multiple_images_id && multiple_images_id.length === 1) {
			handleFetchProfileSettings(imageID);
		}
	}, [imageID, multiple_images_id.length]);
	const onSubmitDublicateImage = async () => {
		if (!token) return; // Exit early if the token is missing

		if (renameTextDuplicate && renameId) {
			try {
				// Prepare customer ID and form data
				const customer_id = filterCustomerId(batchNumber, batch_id_query);
				bodyFormData.append("customer_id", customer_id);
				bodyFormData.append("image_id", renameId);
				bodyFormData.append("name", renameTextDuplicate);

				// Make API request
				const response = await request.post(
					`${apiURL}${CO_ORDINATOR_DUPLICATE_IMAGE_RENAME}`,
					bodyFormData
				);

				// Show toast notification
				const isSuccess = response.data.status;
				ToastAlert({
					title: isSuccess ? "Success" : "Error",
					message: isSuccess ? "Settings saved" : "Error",
					autoClose: 2000,
					messageType: isSuccess ? "success" : "error",
				});

				// Perform actions on success
				if (isSuccess) {
					handleFetchProfileSettings(setting_profile.id);
					setRenameModalShow(false);
				}
			} catch (err) {
				// Handle errors gracefully
				const message =
					err.response.data.message ||
					err.message ||
					"An unexpected error occurred.";
				setError(message);
			}
		}
	};

	const onClickDuplicateImage = (id, imageName) => {
		setRenameId(id);
		setImageName(imageName);
		// setImageExtensions(imageExtensions);
		setRenameModalShow((val) => !val);
		let allImages = document.querySelectorAll(".allimages");
		for (var i = 0; i < allImages.length; i++) {
			allImages[i].style.zIndex = 1;
		}
	};
	const getImageExtension = (imageName) => {
		const lastDotIndex = imageName.lastIndexOf(".");
		if (lastDotIndex !== -1) {
			const extension = imageName.substring(lastDotIndex + 1);
			return extension;
		}
		return "No extension found";
	};
	const handleDelete = () => {
		if (!token) {
			return null;
		}
		// Make an HTTP POST request to delete the selected duplicate image
		request
			.delete(apiURL + CO_ORDINATOR_DELETE_DUPLICATE_IMAGE + `/${renameId}`)
			.then((response) => {
				// Log the response to the console
				handleFetchProfileSettings(setting_profile.id);

				// Close the delete confirmation modal
				setShowModalDelete(false);

				// Show Toast alert message
				ToastAlert({
					title: response.data.status ? "Success" : "Error",
					message: response.data.status ? "Successfully deleted" : "Error",
					autoClose: 2000,
					messageType: response.data.status ? "success" : "error",
				});
			})
			.catch((err) => console.log(err));
	};
	const handleRenameOriginalImage = () => {
		if (token && renameTextDuplicate !== "" && renameId !== "") {
			bodyFormData.append("image_id", originalImageId);
			bodyFormData.append("name", renameTextDuplicate);
			request
				.post(apiURL + CO_ORDINATOR_ORIGINAL_IMAGE_RENAME, bodyFormData)
				.then((response) => {
					// Show Toast alert message
					ToastAlert({
						title: response.data.status ? "Success" : "Error",
						message: response.data.status ? "Renamed successfully" : "Error",
						autoClose: 2000,
						messageType: response.data.status ? "success" : "error",
					});
					//Set the success from the response
					handleFetchProfileSettings(originalImageId);
					setRenameModalShow(false);
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleRenameImage = () => {
		if (renameId === originalImageId) {
			return handleRenameOriginalImage();
		} else {
			onSubmitDublicateImage();
		}
	};

	/**
	 * Handles to check the editor assign status/progress for task status.
	 * @param {String} status - The status String image status.
	 * @returns {} return the matching string or undefined if not matched.
	 */

	const checkEditorWorkProgress = (status) => {
		return editorWorkingStatus.find((item) => item == status);
	};

	/**
	 * Handle the select all images state.
	 * @param {Object} e - event Object.
	 */

	const setSelectAllImages = (e) => {
		e.preventDefault();
		const allImageIds = imgAry.map((item) => item.id);
		setMultipleImagesId(allImageIds);
	};

	/**
	 * Handle the keydown event.
	 * @param {Object} e - event Object.
	 */

	const keydownHandler = (e) => {
		if (
			(e.ctrlKey || e.metaKey) &&
			e.which === 65 &&
			!e.target.classList.contains("skip") &&
			!e.target.classList.contains("form-control")
		) {
			setSelectAllImages(e);
		}
		return false;
	};
	useEffect(() => {
		window.document.addEventListener("keydown", keydownHandler);
		return () => {
			window.document.removeEventListener("keydown", keydownHandler);
		};
	}, []);

	/**
	 * Handle the keydown event and assign the images to imgAry from state.
	 */
	useEffect(() => {
		imgAry = images;
	}, [images]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			// Check if the Ctrl (for Windows/Linux) or Cmd (for macOS) key is pressed
			const isCtrlKey = event.ctrlKey || event.metaKey;

			// Check if the "A" key is pressed
			if (isCtrlKey && event.key === "a") {
				// Prevent the default browser behavior (e.g., selecting all text)
				event.preventDefault();

				// Toggle the selectAll state
				setSelectAll((prevSelectAll) => !prevSelectAll);
			}
		};

		// Attach the event listener when the component mounts
		document.addEventListener("keydown", handleKeyDown);

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []); // Empty dependency array means this effect runs once when the component mounts

	// useEffect(() => {
	// 	if (selectAll) {
	// 		selectAllImages();
	// 	} else {
	// 		// setMultipleImagesId([]);
	// 		setClickSelect(false);
	// 	}
	// }, [selectAll]);
	/**
	 * Handles to change the active index
	 */
	const handleResetActiveIndex = useCallback((item, id, batch_number) => {
		setBatchID(item.id);
		setOrderNumber(item.order_id);
		setActiveBatch(id);
		setActiveBatchNumber(batch_number);
		setSkipImageCall(false);
		// setHideListMenu(true);
		// setLoaderSideBar(true);
	}, []);

	/**
	 * Handles the end date selection callback.
	 * @param {Date} start - The start Date object.
	 * @param {Date} end - The end Date object.
	 * @returns {} set the state.
	 */

	const setDateCallback = (start, end) => {
		handleDateChange(new Date(start.format("YYYY-MM-DD")), setSelectedDate);
		handleDateChange(new Date(end.format("YYYY-MM-DD")), setSelectedDate2);
		setDate1(start.format("YYYY-MM-DD"));
		setDate2(end.format("YYYY-MM-DD"));
		setCustomSelected(true);
		toggleCalendar("date_range", "none", true);
	};

	/**
	 * Handles the show calendar.
	 * @param {id} id - The id of element.
	 * @param {String} display - The show/hide property for element.
	 * @param {Boolean} hideCalendar - Hide/Show the calendar to control.
	 * @returns {} toggle the calendar.
	 */

	const toggleCalendar = (id, display, hideCalendar = false) => {
		const dateRangeElement = document.getElementById(id);
		if (dateRangeElement) {
			dateRangeElement.style.display = display;
			if (display === "block") {
				dateRangeElement.click();
				setIsDateSelected(hideCalendar);
			} else {
				setIsDateSelected(hideCalendar);
			}
			dateRangeElement.style.display = "none";
		}
	};

	useEffect(() => {
		if (
			selectedOption &&
			selectedOption.value &&
			selectedOption.value !== "custom"
		) {
			setIsDateSelected(false);
			setDate1("");
			setDate2("");
			setSelectedDate(new Date());
			setSelectedDate2(new Date());
		}
	}, [selectedOption.value]);
	function filterCustomerId(data, targetId) {
		for (let entry of data) {
			if (Number(entry.batch_number) === Number(targetId)) {
				return entry.customer_id;
			}
		}
		return null;
	}

	const styles = {
		grid: {
			paddingLeft: 0,
			paddingRight: 0,
		},
		row: {
			marginLeft: 0,
			marginRight: 0,
		},
		col: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	};
	console.log("settings_profile", setting_profile);
	return (
		<>
			<div
				className="container-fluid page-body-wrapper"
				style={{ display: "block" }}
			>
				<div>
					<div className="coordinator-negative--margin">
						<div className="co-section-title co-section-title--spacing">
							<h2 className="co-section-title__mb0">Coordinator Workspace</h2>
						</div>

						<div>
							<Form
								style={{
									borderBottom: "1px solid #efefef",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									padding: "4px 4px",
									background: "#fff",
									height: "60.3px",
								}}
							>
								{/* Search and filter form */}

								{folders_path.length !== 0 &&
									folders_path.length &&
									!multiple_images_id.length && (
										<Row className="  exclude-click">
											<Col lg={4} sm={4} xs={4}>
												<div className="exclude-click">
													<div className="row-multiple-select--coordinator">
														<>
															<div>
																<div
																	// onClick={() => handleBulkConfirmation(setShow)}
																	// onClick={() => handleFolderDelete()}
																	className="img-fluid--icons"
																	// className="image-icons-holiday"
																>
																	<img src={Delete} alt="mindreach images" />
																</div>
															</div>

															<OverlayTrigger
																placement="right"
																delay={{ show: 250, hide: 400 }}
																overlay={renderTooltip("Duplicate")}
															>
																<div
																	className="img-fluid--icons"
																	// onClick={() => handleFolderDuplicate()}
																	// onClick={() => handleBulkConfirmation(setDuplicate)}
																>
																	<img
																		src={duplicate}
																		// className="img-fluid--icons"
																		alt="mindreach images"
																	/>
																	{/* </div> */}
																</div>
															</OverlayTrigger>

															{/* Dropdown*/}

															<div class="dropdown">
																<div
																	// onClick={() => setDropDown((val) => !val)}
																	className="img-fluid--icons"
																	id="dropdownMenuButton"
																	data-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false"
																>
																	<img src={MoreIcon} alt="mindreach images" />
																</div>
																<div
																	class="dropdown-menu"
																	aria-labelledby="dropdownMenuButton"
																>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		// onClick={() => handleBulkConfirmation(setActivate)}
																	>
																		Make as Active
																	</a>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		// onClick={() =>
																		// 	handleBulkConfirmation(setDeactivate)
																		// }
																	>
																		Make as Inactive
																	</a>
																</div>
															</div>

															<div>
																<h3 className="row-multiple-select--h3">
																	{" "}
																	{folders_path.length} Folder(s) Selected
																</h3>
															</div>
															<div
																className="img-fluid--icons"
																onClick={() => {
																	setMultipleImagesId([]);
																	// setFolderNameFirst([]);
																	setFolderListName([]);

																	// setFolderSelect([]);

																	setFoldersPath([]);
																	setClickSelect(false);
																	// setReassignedModal(false);
																	setSelectAll(false);
																}}
															>
																<img
																	src={CancelIcon}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
															</div>
														</>
													</div>
												</div>
											</Col>
										</Row>
									)}
								{multiple_images_id.length === 1 && (
									<Row
										className="  exclude-click"
										// style={{ height: `${selectAll ? "5.485" : "6.438"}em` }}
									>
										<Col lg={4} sm={4} xs={4}>
											<div className="exclude-click">
												<div className="row-multiple-select--coordinator">
													<>
														<div>
															<div
																onClick={() => {
																	setShowDeleteByID(true);
																	let allImages =
																		document.querySelectorAll(".allimages");
																	for (var i = 0; i < allImages.length; i++) {
																		allImages[i].style.zIndex = 1;
																	}
																}}
																className="img-fluid--icons"
																// className="image-icons-holiday"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</div>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																className="img-fluid--icons"
																onClick={() => {
																	setDuplicateImageModal(true);
																	let allImages =
																		document.querySelectorAll(".allimages");
																	for (var i = 0; i < allImages.length; i++) {
																		allImages[i].style.zIndex = 1;
																	}
																	setDuplicateAmount([]);
																	setAmount("");
																}}
																// onClick={() => handleBulkConfirmation(setDuplicate)}
															>
																<img
																	src={duplicate}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
																{/* </div> */}
															</div>
														</OverlayTrigger>

														{/* <Dropdown>
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "18px 0px 0px 343px",
																	height: "36px",
																	padding: "6px 27px 11px 11px",
																}}
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															{dropDown && (
																<div className="dropdown">
																	<div className="dropdown-list">
																		<ul>
																			<li class="menunews">
																				<a>
																					<span>Make as Inactive</span>
																				</a>
																				<a>
																					<span>Make as Active</span>
																				</a>
																			</li>
																		</ul>
																	</div>
																</div>
															)}
														</Dropdown> */}
														{/* Dropdown*/}
														<div class="dropdown">
															<div
																// onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	// onClick={() => handleBulkConfirmation(setActivate)}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	// onClick={() =>
																	// 	handleBulkConfirmation(setDeactivate)
																	// }
																>
																	Make as Inactive
																</a>
															</div>
														</div>
														<div>
															<h3 className="row-multiple-select--h3">
																{" "}
																{multiple_images_id.length} File(s) Selected
															</h3>
														</div>
														<div
															className="img-fluid--icons"
															onClick={() => {
																setMultipleImagesId([]);
																setFolderListName([]);
																setFoldersPath([]);
																setClickSelect(false);
																setDuplicateImageModal(false);
																setMergedImageModal(false);
																setLinkedImageModal(false);
																// setSelectAll((val) => !val);
																setSelectAll(false);
															}}
														>
															<img
																src={CancelIcon}
																// className="img-fluid--icons"
																alt="mindreach images"
															/>
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{multiple_images_id.length > 1 && (
									<Row
										className=" mb-3 exclude-click"
										style={{
											height: `${selectAll ? "4.735" : "5.688"}em`,
											paddingTop: "11.8px",
										}}
									>
										<Col lg={4} sm={4} xs={4}>
											<div className="exclude-click">
												<div className="row-multiple-select--coordinator">
													<>
														<div
														//  onClick={() => handleBulkDelete()}
														>
															<div
																// onClick={() => handleBulkConfirmation(setShow)}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	margin: "9px",
																	padding: "6px 0px 9px 9px",
																	zIndex: "5",
																	position: "absolute",
																	cursor: "pointer",
																}}
																className="img-fluid--icons"
																// className="image-icons-holiday"
															>
																<img src={Delete} alt="mindreach images" />
															</div>
														</div>

														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Duplicate")}
														>
															<div
																style={{
																	justifyContent: "center",
																	margin: "9px 0px 0px 79px",
																	cursor: "pointer",
																	position: "absolute",
																	zIndex: "5",
																	// margin: "9px",
																	padding: "6px 0px 9px 9px",
																	cursor: "pointer",
																	// transition:
																	// 	"background-color 0.2s ease-in-out",
																}}
																className="img-fluid--icons"
																onClick={() => {
																	setDuplicateImageModal(true);
																	setMergedImageModal(false);
																	setLinkedImageModal(false);
																	let allImages =
																		document.querySelectorAll(".allimages");
																	for (var i = 0; i < allImages.length; i++) {
																		allImages[i].style.zIndex = 1;
																	}
																}}
															>
																<img
																	src={duplicate}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
																{/* </div> */}
															</div>
														</OverlayTrigger>

														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Merged")}
														>
															<div
																onClick={() => {
																	setDuplicateImageModal(false);
																	setMergedImageModal(true);
																	setLinkedImageModal(false);
																}}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",
																	position: "absolute",
																	margin: "10px 0px 0px 152px",
																	height: "36px",
																	padding: "6px 27px 9px 7px",
																}}
																// className="image-icons-holiday"
															>
																<img src={DocsIcon} alt="mindreach images" />
															</div>
														</OverlayTrigger>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Linked")}
														>
															<div
																onClick={() => {
																	setDuplicateImageModal(false);
																	setMergedImageModal(false);
																	setLinkedImageModal(true);
																}}
																// onClick={() => handleBulkConfirmation(setShow)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",
																	position: "absolute",
																	margin: "10px 0px 0px 228px",
																	height: "36px",
																	padding: "6px 27px 9px 11px",
																}}
															>
																<img src={LinkIcon} alt="mindreach images" />
															</div>
														</OverlayTrigger>

														{/* Dropdown*/}

														<div class="dropdown">
															<div
																// onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",
																	margin: "10px 0px 0px 645px",
																	height: "36px",
																	padding: "6px 27px 9px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	// onClick={() => handleBulkConfirmation(setActivate)}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	// onClick={() =>
																	// 	handleBulkConfirmation(setDeactivate)
																	// }
																>
																	Make as Inactive
																</a>
															</div>
														</div>

														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 378px",
																position: "absolute",
																padding: "6px 0px 9px 9px",
																cursor: "pointer",
															}}
														>
															<h3 className="row-multiple-select--h3">
																{" "}
																{multiple_images_id.length} File(s) Selected
															</h3>
														</div>
														<div
															style={{
																margin: "9px 0px 0px 508px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 6px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={() => {
																setMultipleImagesId([]);
																// setFolderNameFirst([]);
																setFolderListName([]);
																// setFolderSelect([]);
																setFoldersPath([]);
																setClickSelect(false);
																setMergedImageModal(false);
																setLinkedImageModal(false);
																setDuplicateImageModal(false);
															}}
														>
															<img
																src={CancelIcon}
																// className="img-fluid--icons"
																alt="mindreach images"
															/>
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{/* Image settings and sidebar */}
								{!multiple_images_id.length && !folders_path.length && (
									<Container fluid style={styles.grid}>
										<Row className=" coordinator " style={styles.row}>
											<Col sm={12}>
												<div>
													<span className="date-range">
														{selectedOption.value === "custom" && date1 && date2
															? `From ${moment(selectedDate).format(
																	"DD MMM YYYY"
															  )} To ${moment(selectedDate2).format(
																	"DD MMM YYYY"
															  )}`
															: ""}
													</span>
												</div>
											</Col>
											<Col lg={12} sm={12}>
												{/* col-gap-14 */}
												<div
													style={{
														display: "flex",
														gap: "10px",
														alignItems: "center",
													}}
												>
													<div>
														<div
															className="form-group"
															style={{
																paddingBottom: "-1px",
																marginBottom: "5px",
																width: "140px",
															}}
															// styles={ width: "18rem!important" }
														>
															<Select
																value={selectedOption}
																onChange={(e) => {
																	setSelectedOption(e);
																	if (e.value === "custom") {
																		setIsDateSelected(false);
																		setTimeout(() => {
																			toggleCalendar("date_range", "block");
																		}, 100);
																	}
																}}
																options={aryDateFilter}
																className="date_filter"
																styles={customSelectStyles}
															/>
															{currentUserId !== "" &&
																selectedOption.value === "custom" &&
																!isDateSelected && (
																	<div
																		style={{
																			// width: "100%",
																			overflow: "unset",
																			height: "0px",
																		}}
																	>
																		{/* Please follow https://projects.skratchdot.com/react-bootstrap-daterangepicker/?path=/story/daterangepicker--log-events for documentation */}
																		<DateRangePicker
																			dateCallback={setDateCallback}
																		/>
																	</div>
																)}
														</div>
													</div>
													<div className={`coordinator`}>
														<div
															className={`form-group`}
															style={{
																marginTop: `${-1}px`,
																marginBottom: "5px",
															}}
														>
															<Autocomplete
																suggestions={batchNumber}
																setSearch={setSearch}
																setImageDetailsView={setImageDetailsView}
																setImageDetailsViewId={setImageDetailsViewId}
																setImageDetailsModal={setImageDetailsModal}
																isShow={isShow}
																setIsShow={setIsShow}
															/>
														</div>
													</div>
													<div>
														<Form.Group controlId="formBasicCheckbox">
															<div
																className={`${
																	selectedOption.value === "custom" &&
																	!isDateSelected
																		? ``
																		: ``
																}`}
															>
																<div
																	className={`form-group`}
																	style={{ marginBottom: "5px" }}
																>
																	<div className="d-flex">
																		<input
																			type="checkbox"
																			label="Select All"
																			// checked={selectAll}
																			// onClick={() => {
																			// 	if (
																			// 		images.length > 0 ||
																			// 		folder_list.length > 0
																			// 	) {
																			// 		setSelectAll((val) => !val);
																			// 		selectAllImages();
																			// 	}
																			// }}
																			checked={selectAll}
																			onClick={(e) => {
																				const isChecked = e.target.checked;

																				// Function to get all image IDs as strings
																				const getAllImageIds = () =>
																					(Array.isArray(allImages) &&
																						allImages.map((item) =>
																							String(item)
																						)) ||
																					[];

																				if (isChecked) {
																					const allImageIds = getAllImageIds();
																					console.log(
																						"allImages",
																						allImageIds,
																						allImages
																					);

																					// Handle selection logic based on folder list length
																					setSelectAll(true);

																					if (
																						folder_list.length > 0 &&
																						images.length === 0
																					) {
																						selectAllFoldersCheck();
																						setMultipleImagesId([]);
																						// setMultipleImagesId(allImageIds);
																					} else if (
																						folder_list.length === 0 &&
																						images.length > 1
																					) {
																						// selectAllFoldersCheck();
																						// setMultipleImagesId([]);
																						setMultipleImagesId(allImageIds);
																					} else if (
																						folder_list.length > 0 &&
																						images.length > 1
																					) {
																						selectAllFoldersCheck();
																						setMultipleImagesId(allImageIds);
																					} else if (allImageIds.length) {
																						selectAllFoldersCheck();
																						setMultipleImagesId(allImageIds);
																					} else {
																						// Reset selection
																						setSelectAll(false);
																						setMultipleImagesId([]);
																						setFolderIdList([]);
																						setFolderListName([]);
																						setFoldersPath([]);
																					}
																				}
																			}}
																		/>
																		<div className="co-ordinator-super-admin--selectAll flex-grow-1">
																			Select All
																		</div>
																	</div>
																</div>
																<div className={`w-25`}>
																	{/* <div className="form-group ml-2 box exclude-click">
															<Button
																variant="primary"
																className="btns"
																disabled={isButtonDisabled ? false : true}
																onClick={() => setReassignedModal((val) => !val)}
															>
																Reassign
															</Button>{" "}
														</div> */}
																</div>
															</div>
														</Form.Group>
													</div>
												</div>
											</Col>
											{/* Skip this in the code review we might require this in the future purpose*/}
											{/* <Col sm={2}></Col> */}
										</Row>
									</Container>
								)}

								{multiple_images_id.length !== 0 && statusNew && (
									<div>
										<div
											className="ml-2 box exclude-click"
											style={{ paddingRight: "12px" }}
										>
											<Button
												variant="primary"
												className="btns"
												onClick={() => handleAssignedImagesToEditor(idSelected)}
											>
												Assigned to Editor
											</Button>{" "}
										</div>
									</div>
								)}
							</Form>
						</div>

						<div
							className="workbody coordinator form-area form-area--height75"
							style={{ border: "none" }}
						>
							{/* Ignore this commented code we might required this in the future purpose */}

							<div className="row">
								{/* <hr className="coordinator_hr" /> */}
							</div>
							<div>
								<div
									className="new-wordspace-area "
									ref={wrapper}
									style={{ padding: "0px 12px 1px" }}
								>
									<div
										className="custom-row-workspace__batcharea"
										style={{ borderRight: "1px solid #efefef" }}
									>
										<div>
											<div className="editororderdetail">
												<BatchDetailsSidebar
													setLoaderSideBar={setLoaderSideBar}
													batchClick={batchClick}
													currentUserId={currentUserId}
													setFolderListMenu={setFolderListMenu}
													setFolderList={setFolderList}
													batchNumber={batchNumber}
													setBatchID={setBatchID}
													setOrderNumber={setOrderNumber}
													setFolderName={setFolderName}
													timer={timer}
													setTimer={setTimer}
													elapsedTime={elapsedTime}
													setElapsedTime={setElapsedTime}
													startTimer={startTimer}
													stopTimer={stopTimer}
													startTime={startTime}
													setStartTime={setStartTime}
													handleCustomerLogged={handleCustomerLogged}
													show={showBatchLoader}
													setSelectedImagesID={setImageID}
													resetActiveIndex={handleResetActiveIndex}
													activeIndex={activeBatch}
													setHideListMenu={setHideListMenu}
													fetchFolder={fetchFolder}
													setDisplaySub={setDisplaySub}
													setDefaultFolder={setDefaultFolder}
												/>
											</div>
										</div>
									</div>
									<div>
										<CoordinatorOrderDetails
											mainImageCount={mainImageCount}
											mainImageSettingsCount={mainImageSettingsCount}
											idSelected={idSelected}
											selectImageName={selectImageName}
											editTypeId={editTypeId}
											token={token}
											images={images}
											folder_path={folder_path}
											folder_list={folder_list}
											handleFolderClick={handleFolderClick}
											multiple_images_id={multiple_images_id}
											setMultipleImagesId={setMultipleImagesId}
											setFoldersPath={setFoldersPath}
											folders_path={folders_path}
											folder_name={folder_name}
											setFolderListName={setFolderListName}
											// setReassignedModal={setReassignedModal}
											setSelectAll={setSelectAll}
											wrapper={wrapper}
											folderPathSelected={folderPathSelected}
											setMergedImageModal={setMergedImageModal}
											setDuplicateImageModal={setDuplicateImageModal}
											setStatusNew={setStatusNew}
											statusNew={statusNew}
											editTypes={editTypes}
											editGroups={editGroups}
											setClickSelect={setClickSelect}
											customer_id={filterCustomerId(
												batchNumber,
												batch_id_query
											)}
											setEditorType={setEditorType}
											setDuplicateAmount={setDuplicateAmount}
											setAmount={setAmount}
											setImageID={setImageID}
											imageID={imageID}
											setting_profile={setting_profile}
											handleFetchProfileSettings={handleFetchProfileSettings}
											setSelectedImageName={setSelectedImageName}
											setOriginalImageId={setOriginalImageId}
											show_delete_by_id={show_delete_by_id}
											setShowDeleteByID={setShowDeleteByID}
											// fetchOrder={fetchOrder}
											setDisplaySub={setDisplaySub}
											setParentFolderDetails={setParentFolderDetails}
											setFolderName={setFolderName}
											setBatchNumber={setBatchNumber}
											setImages={setImages}
											setFolderList={setFolderList}
											setFolder={setFolder}
											setImageMainFolder={setImageMainFolder}
											setFolderMainFolder={setFolderMainFolder}
											setMainImageCount={setMainImageCount}
											setMainImageSettingsCount={setMainImageSettingsCount}
											setOrderNumber={setOrderNumber}
											setSubFolderOpen={setSubFolderOpen}
											setDisplay={setDisplay}
											setClickSelectParent={setClickSelect}
											setImageEditorResponse={setImageEditorResponse}
											setStatusModal={setStatusModal}
											batchId={activeBatch}
											setDefaultFolder={setDefaultFolder}
											id={id}
											determineCustomerView={determineCustomerView}
											setDeterminedCustomerView={setDeterminedCustomerView}
											allImages={allImages}
											newSettingsClick={newSettingsClick}
											setNewSettingsClicked={setNewSettingsClicked}
											setEditTypes={setEditTypes}
										/>
									</div>
									<div className="folderlist">
										{!merged_image_modal &&
											!duplicate_image_modal &&
											multiple_images_id.length === 0 && (
												<RightSidebar
													mainImageCount={mainImageCount}
													mainImageSettingsCount={mainImageSettingsCount}
													folder_path={folder_path}
													setImages={setImages}
													imagesMainFolder={imagesMainFolder}
													folderMainFolder={folderMainFolder}
													folderSubFolder={folderSubFolder}
													setFolder={setFolder}
													setParentFolderDetails={setParentFolderDetails}
													parentFolderDetails={parentFolderDetails}
													setFolderListSub={setFolderListSub}
													folder_files_sub={folder_files_sub}
													setDisplay={setDisplay}
													list_display={list_display}
													folder_list={folder_list}
													folders={folders}
													setFolderList={setFolderList}
													folder_name={folder_name}
													setFolderName={setFolderName}
													setDisplaySub={setDisplaySub}
													list_sub_display={list_sub_display}
													setFolderListMenu={setFolderListMenu}
													folder_list_menu={folder_list_menu}
													loader_sub_menu={loader_sub_menu}
													setLoaderSubMenu={setLoaderSubMenu}
													clickSelect={clickSelect}
													setClickSelect={setClickSelect}
													// selectOptions={currentUserId}
													token={token}
													id={id}
													setEditGroups={setEditGroups}
													setEditTypes={setEditTypes}
													hide_list_menu={hide_list_menu}
													setHideListMenu={setHideListMenu}
													// setItemList={setItemList}
													setFolderListName={setFolderListName}
													setFoldersPath={setFoldersPath}
													setMultipleImagesId={setMultipleImagesId}
													fetchDataImages={fetchDataImages}
													setDefaultFolder={setDefaultFolder}
													defaultFolder={defaultFolder}
													folder_id_query={id}
													folderIndex={folderIndex}
													fetchFolder={fetchFolder}
													setFoldersListSub={setFoldersListSub}
													folders_list_sub={folders_list_sub}
													setFoldersId={setFoldersId}
													folder_id={folder_id}
													page={page}
													setPage={setPage}
													setHasMore={setHasMore}
													lastPage={lastPage}
													setLastPage={setLastPage}
													setSelectAll={setSelectAll}
													setAllImages={setAllImages}
													folderList={folderList}
													setFoldersList={setFolderList}
												/>
											)}
										{multiple_images_id.length > 1 &&
											merged_image_modal &&
											!duplicate_image_modal && (
												<MergedEditType
													// setDuplicateImageModal={setDuplicateImageModal}
													setMultipleImagesId={setMultipleImagesId}
													setClickSelect={setClickSelect}
													token={token}
													idSelected={idSelected}
													setMergedImageModal={setMergedImageModal}
													images={images}
													multiple_images_id={multiple_images_id}
													setLoader={setLoader}
													loader={loader}
													customer_id={filterCustomerId(
														batchNumber,
														batch_id_query
													)}
												/>
											)}
										{multiple_images_id.length !== 0 &&
											duplicate_image_modal &&
											!merged_image_modal && (
												<DuplicateModalCount
													show={duplicate_image_modal}
													onHide={() => {
														setDuplicateImageModal(false);
													}}
													batch_id_query={batch_id_query}
													setDuplicateSettings={setDuplicateSettings}
													setDuplicateImageModal={setDuplicateImageModal}
													handleChange={handleChangeDuplicateAmount}
													duplicate_amount={duplicate_amount}
													setting_profile={setting_profile}
													token={token}
													customer_id={filterCustomerId(
														batchNumber,
														batch_id_query
													)}
													idSelected={idSelected}
													handleFetchProfileSettings={
														handleFetchProfileSettings
													}
													copyAmounts={copyAmounts}
													setCopyAmounts={setCopyAmounts}
													amount={amount}
													setUpdateSettingsButtons={setUpdateSettingsButtons}
													setAmount={setAmount}
													setShowupdateSettingsModal={
														setShowupdateSettingsModal
													}
													viewUpdateSettingsButton={viewUpdateSettingsButton}
												/>
											)}
										{duplicate_settings &&
											!merged_image_modal &&
											!updateSettingsModalShow && (
												<DuplicateSettings
													show={duplicate_settings}
													onHide={() => {
														setDuplicateSettings(false);
													}}
													duplicate_amount={duplicate_amount}
													setting_profile={setting_profile}
													selectImageName={selectImageName}
													batch_id_query={batch_id_query}
													token={token}
													customer_id={filterCustomerId(
														batchNumber,
														batch_id_query
													)}
													setEditorType={setEditorType}
													edit_select={edit_select}
													editTypes={editTypes}
													editGroups={editGroups}
													setEditGroups={setEditGroups}
													setEditTypes={setEditTypes}
													idSelected={idSelected}
													setMultipleImagesId={setMultipleImagesId}
													setClickSelect={setClickSelect}
													setSelectAll={setSelectAll}
													copyAmounts={copyAmounts}
													setCopyAmounts={setCopyAmounts}
													setDuplicateAmount={setDuplicateAmount}
													setDuplicateSettings={setDuplicateSettings}
													imageID={imageID}
													// fetchOrder={fetchOrder}
													handleFetchProfileSettings={
														handleFetchProfileSettings
													}
													fetchOrderDuplicateSettings={
														fetchOrderDuplicateSettings
													}
												/>
											)}
										{/* {updateSettingsModalShow && !merged_image_modal && (
											<UpdateDuplicateSettingsModal
												show={updateSettingsModalShow}
												onHide={() => {
													setShowupdateSettingsModal(false);
												}}
												duplicate_amount={duplicate_amount}
												setting_profile={setting_profile}
												selectImageName={selectImageName}
												token={token}
												customer_id={currentUserId}
												setEditorType={setEditorType}
												edit_select={edit_select}
												editTypes={editTypes}
												editGroups={editGroups}
												idSelected={idSelected}
												setMultipleImagesId={setMultipleImagesId}
												setClickSelect={setClickSelect}
												setSelectAll={setSelectAll}
												copyAmounts={copyAmounts}
												setCopyAmounts={setCopyAmounts}
												setDuplicateAmount={setDuplicateAmount}
												setDuplicateSettings={setDuplicateSettings}
												viewDuplicateSettings={viewDuplicateSettings}
												setViewDuplicateSettings={setViewDuplicateSettings}
												handleFetchProfileSettings={handleFetchProfileSettings}
											/>
										)} */}

										{multiple_images_id.length === 1 && !merged_image_modal && (
											<SingleRightSelector
												// images={images}
												// editTypes={editTypes}
												// editGroups={editGroups}
												// setMultipleImagesId={setMultipleImagesId}
												// selectImageName={selectImageName}
												// token={token}
												// idSelected={idSelected}
												// setClickSelect={setClickSelect}
												// setSelectAll={setSelectAll}
												// setFolderListName={setFolderListName}
												// setFoldersPath={setFolderPath}
												// setMergedImageModal={setMergedImageModal}
												// setDuplicateImageModal={setDuplicateImageModal}
												// customer_id={filterCustomerId(
												// 	batchNumber,
												// 	batch_id_query
												// )}
												// setEditorType={setEditorType}
												// edit_select={edit_select}
												// setting_profile={setting_profile}
												// setRenameModalShow={setRenameModalShow}
												// renameModalShow={renameModalShow}
												// renameTextDuplicate={renameTextDuplicate}
												// setRenameTextDuplicate={setRenameTextDuplicate}
												// setImageExtensions={setImageExtensions}
												// imageExtensions={imageExtensions}
												// setImageName={setImageName}
												// imageName={imageName}
												// setRenameId={setRenameId}
												// renameId={renameId}
												// onClickDuplicateImage={onClickDuplicateImage}
												// setShowModalDelete={setShowModalDelete}
												// showModalDelete={showModalDelete}
												// getImageExtension={getImageExtension}
												// handleFetchProfileSettings={handleFetchProfileSettings}
												// imageID={imageID}
												// setParentFolderDetails={setParentFolderDetails}
												// setFolderName={setFolderName}
												// setBatchNumber={setBatchNumber}
												// setImages={setImages}
												// setFolderList={setFolderList}
												// setFolder={setFolder}
												// setImageMainFolder={setImageMainFolder}
												// setFolderMainFolder={setFolderMainFolder}
												// setMainImageCount={setMainImageCount}
												// setMainImageSettingsCount={setMainImageSettingsCount}
												// setOrderNumber={setOrderNumber}
												// setSubFolderOpen={setSubFolderOpen}
												// setDisplay={setDisplay}
												// batchId={batchId}
												// setImageID={setImageID}
												// copyAmounts={copyAmounts}
												// setCopyAmounts={setCopyAmounts}
												// setDuplicateAmount={setDuplicateAmount}
												// setDuplicateSettings={setDuplicateSettings}
												// fetchOrderDuplicateSettings={
												// 	fetchOrderDuplicateSettings
												// }
												// fetchOrder={fetchOrder}
												// handleFetchProfileSettings={handleFetchProfileSettings}
												// setFolderSelect={setFolderSelect}
												// fetchCheckoutPrice={fetchCheckoutPrice}
												customer_id={filterCustomerId(
													batchNumber,
													batch_id_query
												)}
												images={images}
												editTypes={editTypes}
												editGroups={editGroups}
												setMultipleImagesId={setMultipleImagesId}
												selectImageName={selectImageName}
												token={token}
												idSelected={idSelected}
												setClickSelect={setClickSelect}
												setSelectAll={setSelectAll}
												setFolderListName={setFolderListName}
												setFoldersPath={setFolderPath}
												setMergedImageModal={setMergedImageModal}
												setDuplicateImageModal={setDuplicateImageModal}
												setEditorType={setEditorType}
												edit_select={edit_select}
												setting_profile={setting_profile}
												setRenameModalShow={setRenameModalShow}
												renameModalShow={renameModalShow}
												renameTextDuplicate={renameTextDuplicate}
												setRenameTextDuplicate={setRenameTextDuplicate}
												setImageExtensions={setImageExtensions}
												imageExtensions={imageExtensions}
												setImageName={setImageName}
												imageName={imageName}
												setRenameId={setRenameId}
												renameId={renameId}
												onClickDuplicateImage={onClickDuplicateImage}
												setShowModalDelete={setShowModalDelete}
												showModalDelete={showModalDelete}
												getImageExtension={getImageExtension}
												handleFetchProfileSettings={handleFetchProfileSettings}
												imageID={imageID}
												setParentFolderDetails={setParentFolderDetails}
												setFolderName={setFolderName}
												setImages={setImages}
												setFolderList={setFolderList}
												setFolder={setFolder}
												setImageMainFolder={setImageMainFolder}
												setFolderMainFolder={setFolderMainFolder}
												setMainImageCount={setMainImageCount}
												setMainImageSettingsCount={setMainImageSettingsCount}
												setDisplay={setDisplay}
												setImageID={setImageID}
												copyAmounts={copyAmounts}
												setCopyAmounts={setCopyAmounts}
												setDuplicateAmount={setDuplicateAmount}
												setDuplicateSettings={setDuplicateSettings}
												setSettingsProfile={setSettingsProfile}
												setEditId={setEditId}
												edit_id={edit_id}
												// fetchDataImages={fetchDataImages}
												fetchFolder={fetchFolder}
												// setDataLoader={setDataLoader}
												determineCustomerView={determineCustomerView}
												checked={checked}
												checked1={checked1}
												checked2={checked2}
												checked3={checked3}
												setChecked={setChecked}
												setChecked1={setChecked1}
												setChecked2={setChecked2}
												setChecked3={setChecked3}
												newSettingsClick={newSettingsClick}
												setNewSettingsClicked={setNewSettingsClicked}
											/>
										)}
										{multiple_images_id.length > 1 &&
											!!!duplicate_image_modal &&
											!merged_image_modal &&
											!linked_image_modal && (
												<RightSidebarMultiSelect
													multiple_images_id={multiple_images_id}
													editTypes={editTypes}
													editGroups={editGroups}
													setMultipleImagesId={setMultipleImagesId}
													selectImageName={selectImageName}
													token={token}
													idSelected={idSelected}
													setClickSelect={setClickSelect}
													customer_id={filterCustomerId(
														batchNumber,
														batch_id_query
													)}
													setSelectAll={setSelectAll}
													setting_profile={setting_profile}
													// fetchCheckoutPrice={fetchCheckoutPrice}
												/>
											)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<StatusModal
					show={statusModal}
					onHide={() => {
						setStatusModal(false);
					}}
					status={image_editor_response}
					title={`${"Assign Image To Editor"}`}
					hideCancel={true}
				/>
				<RenameModal
					show={renameModalShow}
					onHide={() => {
						setRenameModalShow(false);
					}}
					title="Rename"
					text={renameTextDuplicate}
					setRenameTextDuplicate={setRenameTextDuplicate}
					imageName={imageName}
					setImageName={setImageName}
					imageExtensions={imageExtensions}
					handleRename={handleRenameImage}
				/>
				<DeleteModalConfirmation
					show={showModalDelete}
					onHide={() => {
						setShowModalDelete(false);
					}}
					branch={"image"}
					name={imageName}
					title={"Delete Image"}
					handleConfirm={() => handleDelete()}
				/>
			</div>
		</>
	);
};

export default CoordinatorWorkspace;
