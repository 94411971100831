import React from 'react'
import Sidebar from '../../../components/global/Sidebar/Sidebar'
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Delete from '../../../assets/Images/delete.svg'



const Order = () => {

//   This page will go for dashboard design.****************************


    const role = [
        { label: "Editor", value: 355 },
        { label: "Editor 2", value: 54 }
    ];
    const shiftone = [
        { label: "Day Shift - Weekdays", value: 355 },
        { label: "Day Shift - Weekdays 2", value: 54 }

    ];

    const shiftwo = [
        { label: "Weekend Shift - Weekdays", value: 355 },
        { label: "Weekend Shift - Weekdays 2", value: 54 }

    ];
    return (
        <>

            <div className="container-fluid page-body-wrapper">

                <Sidebar />
                <div className="main-panel ">
                    <div className="rightbar pr-11">
                        <div className="section-title">
                            <h2>Create Order</h2>
                        </div>
                        <Row>
                            <Col sm={9} lg={5}>
                                <Form className="">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <div className="form-group">
                                            <Form.Label>Upload Method <span>*</span></Form.Label>
                                            <Select options={role} defaultValue={{ label: "FTP U", value: 355 }} />
                                        </div>
                                        <div className="form-group">
                                            <Form.Label>Internal Batch Name</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" />
                                        </div>
                                        <div className="form-group">
                                            <Form.Label>Editing Requirements & Specification Settings <span>*</span></Form.Label>
                                            <Select options={role} defaultValue={{ label: "Let Mindreach determine this for all the images", value: 355 }} />
                                        </div>

                                        <div className="form-group">
                                            <Form.Label>Notes (optional)</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Write Notes..."
                                                style={{ height: '224px' }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <div className="d-sm-flex justify-content-between btn-cls">
                                                <div className="">                                                   
                                                    <Button variant="outline-primary" className="btn-light-border"><img src={Delete} className="img-fluid" alt="mindreach images" /></Button>{' '}

                                                </div>

                                                <div className="">
                                                <Button  variant="" className="puple btns mr-3" type="submit">
                                                  Cancel
                                               </Button>
                                                    <Button variant="primary" className="btns" type="submit">
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>



                                </Form>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Order