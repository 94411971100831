import React from "react";
import { useNavigate } from "react-router-dom";

// Importing toastify module
import { ToastContainer } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import "./../../../css/style.css";
import "./../../../css/responsive.css";
import "./../../../css/navbar.css";
import logo from "./../../../assets/Images/logo.svg";
import user from "../../../assets/Images/user/no-profile.png";
import notifys from "./../../../assets/Images/notify.svg";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import { BsBoxArrowRight, BsGearFill } from "react-icons/bs";
import { AuthContext } from "../../../context/Login/Auth.context";
import { client } from "./../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";

const MainNav = (token, tokenAccess) => {
  const navigate = useNavigate();
  let roles, data, customer;

  customer = sessionStorage.getItem("customer");
  roles = sessionStorage.getItem("role");
  data = JSON.parse(roles);
  customer = JSON.parse(customer);

  let tokenString = token.tokenAccess ? token.tokenAccess !== null : "";
  let tokenSubString = tokenString;

  const tokenDestroy = sessionStorage.getItem("token");

  const { dispatch } = React.useContext(AuthContext);
  const handleClickSignOut = (e) => {
    if (tokenDestroy !== null) {
      e.preventDefault();
      const request = client(tokenDestroy);
      request
        .post(apiURL + "/logout")
        .then((response) => {
          navigate("/login");
          window.location.reload();
          dispatch({
            type: "LOGOUT",
          });
        })
        .catch((err) => console.log(err));
    }
  };
  function getCustomerFullName(customer, full_name) {
    if (
      customer && // Check if customer is truthy (not null or undefined)
      full_name !== undefined && // Check if customer's full_name is defined
      full_name !== null // Check if customer's full_name is not null
    ) {
      return full_name; // If all conditions are met, return customer's full_name
    } else {
      return null; // Return null if any of the conditions are not met
    }
  }

  return (
    <>
      <ToastContainer
        limit={1}
        theme="colored"
        progressClassName="toastProgress"
        bodyClassName="toastBody"
      />
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Brand href="#home">
          <img
            src={logo}
            alt="logo"
            className="img-fluid"
            // alt="mindreach images"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse aria-controls="responsive-navbar-nav">
          {tokenSubString !== "" && (
            <Nav className="ms-auto">
              <Nav.Link className="notify mt-2">
                <img src={notifys} alt="notifys" className="img-fluid mt-2" />
                <span className="count-symbol bg-danger"></span>
              </Nav.Link>

              <Form
                className="nav-link  d-sm-flex"
                id="basic-nav-dropdown"
                ata-bs-toggle="dropdown"
                data-toggle="collapse"
                data-target="#navbar"
                aria-controls="navbar"
                aria-expanded="true"
              >
                <div className="nav-profile-img">
                  <img src={user} alt="user" />
                </div>
                <div className="nav-profile-text">
                  <p className="user-name">
                    {customer &&
                      customer.full_name !== null &&
                      getCustomerFullName(customer, customer.full_name)}
                  </p>
                  <p className="user-designation">
                    {data && data.role !== null && data.role}
                  </p>
                </div>
                <NavDropdown
                  title="Link"
                  id="profileDropdown"
                  className="nav-link d-sm-flex dropdown-menu.show "
                  style={{ background: "black" }}
                >
                  <NavDropdown.Item
                    style={{ color: "black", marginTop: "0" }}
                    onClick={() => navigate("/edit-overview")}
                  >
                    <div className="navbar-data-toggle--popover">
                      <div>
                        <BsGearFill />
                      </div>
                      <div>Edit Profile</div>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={(e) => handleClickSignOut(e)}
                    style={{ color: "black", marginTop: "0" }}
                  >
                    <div className="navbar-data-toggle--popover">
                      <div>
                        <BsBoxArrowRight />
                      </div>
                      <div>Sign out</div>
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
              </Form>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default MainNav;
