import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination, Form, Row, Col, Table, Dropdown } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Select from "react-select";

// Importing components and images
import Loader from "../../../components/Loader";
import NoDataFound from "../../../components/NoDataFound";
import AddButton from "../../../components/Button";
import user from "../../../assets/Images/users.svg";
// import Delete from "../../../assets/Images/delete.svg";
import Sort from "../../../assets/Images/sort.svg";
import plus from "./../../../assets/Images/plus.svg";

import Delete from "./../../../assets/Images/delete.svg";
import duplicate from "./../../../assets/Images/actionsIcons/Duplicate _Final.svg";
import CancelIcon from "./../../../assets/Images/actionsIcons/cancel_Final.svg";
import MoreIcon from "./../../../assets/Images/actionsIcons/more-Final.svg";
//Importing Status Modal
import ActivateModalConfirmation from "../../../components/Modal/ActivateModal";
import DeActivateModalConfirmation from "../../../components/Modal/DeactivateModal";
import DeleteModalConfirmation from "../../../components/Modal/DeleteModal";
// Importing API related files and routes
import { client } from "../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "../../../data/filepath";
import {
	VIEW_CUSTOMERS,
	ACTIVATE_CUSTOMER_BULK,
	DEACTIVATE_CUSTOMER_BULK,
	ACTIVATE_CUSTOMER_BY_ID,
	DEACTIVATE_CUSTOMER_BY_ID,
	DELETE_CUSTOMER_BY_ID,
	DELETE_BULK_CUSTOMER,
} from "../../../config/apiRoutes.api";
import {
	CUSTOMER_ADD_PAGE,
	EDIT_CUSTOMER_PAGE,
	CUSTOMERS_DETAILS,
} from "./../../../config/routes";

const customerTypes = [
	{ label: "All", value: "All" },
	{ label: "Non Enterprice", value: "non-enterprice" },
	{ label: "Enterprice", value: "enterprice" },
];

/**
 * User component to display user data with pagination and search functionality.
 * @returns {JSX.Element} - The rendered User component.
 */
const Customers = () => {
	const token = sessionStorage.getItem("token");
	const navigate = useNavigate();
	const request = client(token);
	const bodyFormData = new FormData();
	const [customers, setCustomers] = useState([]);
	const [rows, setRows] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [selectCustomerTypes, setSelectCustomerTypes] = useState("");
	const [search, setSearch] = useState("");
	const [dropDown, setDropDown] = useState(false);
	const [dropDown_Two, setDropDownTwo] = useState(false);
	const [show, setShow] = useState(false);
	const [deleteShowByID, setDeleteShowBYID] = useState(false);
	const [customer_id, setCustomerId] = useState("");
	const [customer_name, setCustomerName] = useState("");
	const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);
	const [selectAllRow, setSelectAllRows] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [show_delete_by_id, setShowDeleteByID] = useState(false);
	const [editTypeData, setEditTypeData] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowId, setSelectedRowsId] = useState([]);
	const [selectedRowName, setSelectedRowsName] = useState([]);
	const [customer_activate, setActivate] = useState(false);
	const [customer_activate_All, setActivateAll] = useState(false);
	const [customer_deactive, setDeactivate] = useState(false);
	const [customer_deactive_All, setDeactivateAll] = useState(false);

	const [activate_id, setActivateById] = useState(false);
	const [deactivate_id, setDeactivateById] = useState(false);
	const [status_name, setStatusName] = useState("");
	const [id, setId] = useState("");

	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>; // Returns Tool Tip

	/**
	 * Converts an ISO date string to a specific date format.
	 * @param {string} dateString - The ISO date string to format.
	 * @returns {string} - The formatted date string.
	 */
	const toJSONLocal = (dateString) => {
		const date = new Date(dateString);

		const day = date.getDate();
		const month = date.toLocaleString("default", { month: "long" });
		const year = date.getFullYear();

		const formattedDate = `${day}` + ` ${month}` + ` ${year}`;
		return formattedDate;
	};
	/**
	 * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} customerTypes - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (customerTypes) =>
		customerTypes.map(({ role: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of array as label and value return a new array of object like [{value:"",label:""}]

	// Check the customer type has change value or not
	useEffect(() => {
		if (token && selectCustomerTypes !== "") {
			// Fetching user data based on selected customer type from the API
			request
				.get(
					apiURL +
						VIEW_CUSTOMERS +
						`?customer_type=${selectCustomerTypes}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log(response);
					setCustomers(response.data.customers);
					setRows(response.data.customers.data);
				})
				.catch((err) => console.log(err));
		}
	}, [selectCustomerTypes, pageNumber]);

	// Check the search key has change value or not
	useEffect(() => {
		if (token && search !== "") {
			// Fetching user data based on search from the API
			request
				.get(
					apiURL +
						VIEW_CUSTOMERS +
						`?search_key=${search}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log(response);
					setCustomers(response.data.customers);
					setRows(response.data.customers.data);
				})
				.catch((err) => console.log(err));
		}
	}, [search, pageNumber]);
	useEffect(() => {
		if (token && selectCustomerTypes === "All") {
			// Fetching user data based on search from the API
			request
				.get(apiURL + VIEW_CUSTOMERS + `?page=${pageNumber}`)
				.then((response) => {
					console.log(response);
					setCustomers(response.data.customers);
					setRows(response.data.customers.data);
				})
				.catch((err) => console.log(err));
		}
	}, [selectCustomerTypes, pageNumber]);
	console.log("selectCustomerTypes", selectCustomerTypes);
	// Check the customer type and search have change value or not
	useEffect(() => {
		if (token && search !== "" && selectCustomerTypes !== "") {
			// Fetching user data based on selected roles & search from the API
			request
				.get(
					apiURL +
						VIEW_CUSTOMERS +
						`?customer_type=${selectCustomerTypes}` +
						`&search_key=${search}` +
						`&page=${pageNumber}`
				)
				.then((response) => {
					console.log(response);
					setCustomers(response.data.customers);
					setRows(response.data.customers.data);
				})
				.catch((err) => console.log(err));
		}
	}, [search, selectCustomerTypes, pageNumber]);

	// If new page
	useEffect(() => {
		if (token) {
			// Fetching user data from the API based on params
			request
				.get(apiURL + VIEW_CUSTOMERS + `?page=${pageNumber}`)
				.then((response) => {
					console.log(response);
					setCustomers(response.data.customers);
					setRows(response.data.customers.data);
				})
				.catch((err) => console.log(err));
		}
	}, [!search, pageNumber]);

	//Paginations

	const PaginationItems = () => {
		const { total, per_page, current_page } = customers;
		const totalPages = Math.ceil(total / per_page);
		const maxDisplayedPages = 4;

		let items = [];

		// Calculate the start and end page numbers to display
		let startPage = current_page;
		let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

		// Adjust the start and end page numbers if current page is near the end
		if (current_page + maxDisplayedPages - 1 > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === current_page}
					onClick={() => setPageNumber(number)}
				>
					{number}
				</Pagination.Item>
			);
		}

		return items;
	};
	const handleNextPagination = () => {
		if (pageNumber !== Math.ceil(customers.total / customers.per_page)) {
			setPageNumber(pageNumber + 1);
		}
	};
	const handlePreviousPagination = () => {
		if (pageNumber !== Math.ceil(customers.total / customers.per_page)) {
			setPageNumber(pageNumber - 1);
		}
		setPageNumber(pageNumber - 1);
	}; //end of Paginations
	//Select the delete,active and inactive
	const handleSelectRow = React.useCallback(
		(e, row) => {
			if (e.target.checked) {
				console.log("e.target.checked", e.target.checked);
				setSelectedRows((prevSelectedRows) => [
					...prevSelectedRows,
					row.encrypted_id,
				]);
				setSelectedRowsId((prevSelectedRows) => [
					...prevSelectedRows,
					row.full_name,
				]);
			} else {
				setSelectedRows((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.encrypted_id
					)
				);
				setSelectedRowsId((prevSelectedRows) =>
					prevSelectedRows.filter(
						(selectedRow) => selectedRow !== row.editType_name
					)
				);
			}
		},
		[selectedRows]
	);
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			setSelectAll(true);
			const updatedRows = customers.data.map((row) => {
				return { ...row, selected: true };
			});
			setRows(updatedRows);
			const updatedRowsIds = customers.data.map((row) => {
				return row.encrypted_id;
			});
			const updatedRowsName = customers.data.map((row) => {
				return row.full_name;
			});
			setSelectAllRows((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsIds,
			]);
			setSelectedRowsName((prevSelectedRows) => [
				...prevSelectedRows,
				updatedRowsName,
			]);
		} else {
			setSelectAll(false);
			const updatedRows = customers.data.map((row) => {
				return { ...row, selected: false };
			});
			setRows(updatedRows);
			const updatedRowsIds = customers.data.map((row) => {
				return row.encrypted_id;
			});
			setSelectAllRows([]);
		}
	};
	const handleClearSelectAll = () => {
		setSelectAll(false);
		const updatedRows = customers.data.map((row) => {
			return { ...row, selected: false };
		});
		setRows(updatedRows);
		const updatedRowsIds = customers.data.map((row) => {
			return row.encrypted_id;
		});
		setSelectAllRows([]);
	};
	const handleBulkDelete = () => {
		if (token) {
			bodyFormData.append("customer_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + DELETE_BULK_CUSTOMER, bodyFormData)
				.then((response) => {
					console.log(response);
					setShow(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	const handleBulkDeleteAll = () => {
		if (token) {
			bodyFormData.append("customer_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + DELETE_BULK_CUSTOMER, bodyFormData)
				.then((response) => {
					console.log(response);
					setShowModalDeleteAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk activation of selected rows.
	*/
	const handleBulkActivate = () => {
		if (token) {
			bodyFormData.append("customer_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + ACTIVATE_CUSTOMER_BULK, bodyFormData)
				.then((response) => {
					console.log(response);
					setActivate(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk activation of all rows.
	*/
	const handleBulkActivateAll = () => {
		if (token) {
			bodyFormData.append("customer_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + ACTIVATE_CUSTOMER_BULK, bodyFormData)
				.then((response) => {
					console.log(response);
					setActivateAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk deactivation of customer.
		2.If the token is available, it appends the customer_ids  to the bodyFormData,
		3.sends a POST request to the specified API endpoint for deactivating bulk edit types,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/

	const handleBulkDeactivate = () => {
		if (token) {
			bodyFormData.append("customer_ids", JSON.stringify(selectedRows));
			request
				.post(apiURL + DEACTIVATE_CUSTOMER_BULK, bodyFormData)
				.then((response) => {
					console.log(response);
					setDeactivate(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	/**
		1.Handles the bulk deactivation of all customer.
		2.If the token is available, it appends the customer_ids  to the bodyFormData,
		sends a POST request to the specified API endpoint for deactivating bulk edit types,
		and reloads the window after the request is completed.
		@throws {Error} If the request fails.
	*/
	const handleBulkDeactivateAll = () => {
		if (token) {
			bodyFormData.append("customer_ids", JSON.stringify(selectAllRow[0]));
			request
				.post(apiURL + DEACTIVATE_CUSTOMER_BULK, bodyFormData)
				.then((response) => {
					console.log(response);
					setDeactivateAll(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	const handleBulkConfirmation = (setShow) => {
		setShow(true);
	};
	const handleDelete = (id) => {
		if (token !== null) {
			// request
			// .get(apiURL + DELETE_EDIT_TYPE_BY_ID + `/${id}`)
			// .then((response) => {
			// 	console.log(response);
			// 	window.location.reload();
			// })
			// .catch((err) => console.log(err));
		}
	};
	const handleDeleteConfirmation = (id, name) => {
		setShowDeleteByID(true);
		setCustomerName(name);
		setCustomerId(id);
	};
	const handleActivateById = () => {
		if (token) {
			request
				.get(apiURL + ACTIVATE_CUSTOMER_BY_ID + `/${id}`)
				.then((response) => {
					console.log(response);
					setActivateById(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	const handleDeactivateById = () => {
		if (token) {
			request
				.get(apiURL + DEACTIVATE_CUSTOMER_BY_ID + `/${id}`)
				.then((response) => {
					console.log(response);
					setDeactivateById(false);
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};
	const handleActivateOrDeactive = (status, id, name) => {
		setId(id);
		setStatusName(name);
		if (status === "1") {
			setDeactivateById(true);
		} else {
			setActivateById(true);
		}
	};

	const handleDeleteCustomer = (id) => {
		// Check if a token is available
		if (token) {
			// Send an HTTP GET request to delete the branch by ID
			request
				.get(apiURL + DELETE_CUSTOMER_BY_ID + `/${id}`)
				.then((response) => {
					// Log the response to the console
					console.log(response);
					// Reload the page to reflect the changes
					window.location.reload();
				})
				.catch((err) => console.log(err));
		}
	};

	// Render the loader while data is being fetched
	if (!customers.data) return <Loader />;
	else {
		return (
			<>
				<div className="rightbar pr-11">
					<div className="section-title">
						<h2>Customers</h2>
					</div>
					<Row></Row>

					<div className="d-sm-flex flex-sm-wrap col-gap-30-sm  mb-3">
						<div className="customer-search">
							<Form>
								{selectedRows.length !== 0 && (
									<Row className="align-items-center">
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select--customer">
													<>
														<div>
															<div>
																<div
																	onClick={() =>
																		handleBulkConfirmation(setShow)
																	}
																	style={{
																		justifyContent: "center",
																		marginTop: "9px",
																		cursor: "pointer",
																		// margin: "9px",
																		padding: "6px 0px 11px 9px",
																		position: "absolute",
																	}}
																	className="img-fluid--icons"
																	// className="image-icons-holiday"
																>
																	<img src={Delete} alt="mindreach images" />
																</div>
															</div>

															{/* Dropdown*/}

															<div class="dropdown">
																<div
																	onClick={() => setDropDown((val) => !val)}
																	className="img-fluid--icons"
																	style={{
																		justifyContent: "center",
																		cursor: "pointer",

																		cursor: "pointer",
																		position: "absolute",

																		height: "36px",

																		margin: "50px 0px 0px 179px",

																		padding: "7px 27px 6px 11px",
																	}}
																	id="dropdownMenuButton"
																	data-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false"
																>
																	<img src={MoreIcon} alt="mindreach images" />
																</div>
																<div
																	class="dropdown-menu"
																	aria-labelledby="dropdownMenuButton"
																>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		onClick={() =>
																			handleBulkConfirmation(setActivate)
																		}
																	>
																		Make as Active
																	</a>
																	<a
																		class="dropdown-item"
																		style={{ color: "black" }}
																		onClick={() =>
																			handleBulkConfirmation(setDeactivate)
																		}
																	>
																		Make as Inactive
																	</a>
																</div>
															</div>
															{/* </div> */}
															{/* </OverlayTrigger> */}
															{/*  */}
															<div
																style={{
																	justifyContent: "center",
																	margin: "8px 0px 0px 135px",
																	position: "absolute",
																	padding: "6px 0px 11px 9px",
																	cursor: "pointer",
																}}
															>
																{/* <h3 className="row-multiple-select--h3"> */}
																<h3 className="row-multiple-select--h3">
																	{" "}
																	{selectedRows.length} Customer(s) Selected
																</h3>
															</div>
															<div
																style={{
																	margin: "8px 0px 0px 299px",
																	cursor: "pointer",
																	position: "absolute",
																	// margin: "9px 9px 9px 12px",
																	padding: "6px 9px 9px 9px",
																}}
																className="img-fluid--icons"
																onClick={() => setSelectedRows([])}
															>
																<img
																	src={CancelIcon}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
															</div>
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{selectAllRow.length !== 0 && (
									<Row className="align-items-center">
										<Col lg={4} sm={4} xs={4}>
											<div>
												<div className="row-multiple--select--customer">
													<>
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip("Delete")}
														>
															<div
																onClick={() =>
																	handleBulkConfirmation(setShowModalDeleteAll)
																}
																style={{
																	justifyContent: "center",
																	marginTop: "9px",
																	cursor: "pointer",
																	// margin: "9px",
																	padding: "6px 0px 11px 9px",
																	position: "absolute",
																}}
																className="img-fluid--icons"
															>
																<img
																	src={Delete}
																	// className="img-fluid--icons"
																	alt="mindreach images"
																/>
															</div>
														</OverlayTrigger>

														{/* Dropdown*/}

														<div class="dropdown">
															<div
																onClick={() => setDropDown((val) => !val)}
																className="img-fluid--icons"
																style={{
																	justifyContent: "center",
																	cursor: "pointer",

																	cursor: "pointer",
																	position: "absolute",

																	height: "36px",

																	margin: "18px 0px 0px 179px",

																	padding: "7px 27px 6px 11px",
																}}
																id="dropdownMenuButton"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																<img src={MoreIcon} alt="mindreach images" />
															</div>
															<div
																class="dropdown-menu"
																aria-labelledby="dropdownMenuButton"
															>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setActivateAll)
																	}
																>
																	Make as Active
																</a>
																<a
																	class="dropdown-item"
																	style={{ color: "black" }}
																	onClick={() =>
																		handleBulkConfirmation(setDeactivateAll)
																	}
																>
																	Make as Inactive
																</a>
															</div>
														</div>
														{/* </OverlayTrigger> */}

														{/* <h3 className="row-multiple-select--h3">
															{rows.length} Holiday(s) Selected
														</h3> */}

														{/* <h3 className="row-multiple-select--h3"> */}
														{/* <h3 className="row-multiple-select--h3">
																{" "}
																{rows.length} Holiday(s) Selected
															</h3> */}
														<div
															style={{
																justifyContent: "center",
																margin: "9px 0px 0px 135px",
																position: "absolute",
																padding: "6px 0px 11px 9px",
																cursor: "pointer",
															}}
														>
															{/* <h3 className="row-multiple-select--h3"> */}
															<h3 className="row-multiple-select--h3">
																{" "}
																{rows.length} Customer(s) Selected
															</h3>
														</div>
														<div
															// style={{
															// 	marginTop: "9px",
															// 	cursor: "pointer",
															// }}
															style={{
																margin: "9px 0px 0px 299px",
																cursor: "pointer",
																position: "absolute",
																// margin: "9px 9px 9px 12px",
																padding: "6px 9px 9px 9px",
															}}
															className="img-fluid--icons"
															onClick={handleClearSelectAll}
														>
															<img
																src={CancelIcon}
																// className="img-fluid--icons"
																alt="mindreach images"
															/>
														</div>
													</>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{!selectedRows.length && !selectAllRow.length && (
									<Row className="align-items-center">
										<Col xs={2}>
											<Select
												options={selectLabel(customerTypes)}
												placeholder="Select User Type"
												onChange={(e) => setSelectCustomerTypes(e.value)}
											/>
										</Col>
										<Col>
											<Form.Control
												type="search"
												placeholder="Search"
												className="me-2"
												aria-label="Search"
												onChange={(e) => setSearch(e.target.value)}
											/>
										</Col>
										<Col>
											<p className="customer-count">
												<span>
													<img
														src={user}
														className="img-fluid"
														alt="mindreach images"
													/>
												</span>
												{customers.total} Customer(s)
											</p>
										</Col>
									</Row>
								)}
							</Form>
						</div>
						<div className="">
							<AddButton
								img={plus}
								text="New Customer"
								url={CUSTOMER_ADD_PAGE}
								variant="primary"
							/>
						</div>
					</div>
					<div
						className={
							`table-responsive table-b custom-new-table ` +
							`  ` +
							`${
								selectedRows.length || selectAllRow.length
									? `mt-21px--table`
									: ""
							}`
						}
						style={{ marginTop: "3rem !important" }}
					>
						<Table hover>
							<thead>
								<tr>
									{typeof customers.data !== "undefined" &&
										customers.data.length !== 0 && (
											<>
												<th>
													{!selectedRows.length && (
														<input
															type="checkbox"
															checked={selectAll}
															onClick={handleSelectAll}
															// className="checkbox-all-elements--table "
														/>
													)}
												</th>
												<th>
													Customer{" "}
													<span style={{ marginLeft: "10px" }}>
														<img
															src={Sort}
															alt="sort"
															style={{ width: "16px", height: "16px" }}
														/>
													</span>
												</th>
												<th>Email</th>
												<th>Active Date</th>
												<th>Customer Type</th>
												<th>Status</th>
												<th></th>
											</>
										)}
								</tr>
							</thead>
							<tbody>
								{customers.data !== undefined &&
									rows.map((item, index) => {
										const list = (
											<>
												<tr key={index}>
													<td className="checkbox-elements--table">
														<input
															type="checkbox"
															// className="checkbox_round"
															checked={
																selectedRows.length
																	? selectedRows.find(
																			(selectedRow) => selectedRow.id === item
																	  )
																	: item.selected === true
															}
															onChange={(e) => handleSelectRow(e, item)}
														/>
													</td>
													<td
														onClick={() =>
															navigate(
																`${CUSTOMERS_DETAILS}/${item.encrypted_id}`
															)
														}
													>
														{item.full_name}
													</td>
													<td
														onClick={() =>
															navigate(
																`${CUSTOMERS_DETAILS}/${item.encrypted_id}`
															)
														}
													>
														{item.email}
													</td>
													<td
														onClick={() =>
															navigate(
																`${CUSTOMERS_DETAILS}/${item.encrypted_id}`
															)
														}
													>
														{toJSONLocal(item.created_at)}
													</td>
													<td
														onClick={() =>
															navigate(
																`${CUSTOMERS_DETAILS}/${item.encrypted_id}`
															)
														}
													>
														{item.customer_type}
													</td>

													<td
														onClick={() =>
															handleActivateOrDeactive(
																item.is_active,
																item.encrypted_id,
																item.full_name
															)
														}
													>
														{item.is_active === "1" ? (
															<div className="active-staff">Active</div>
														) : (
															<div>Inactive</div>
														)}
														{console.log(item.is_active)}
													</td>
													<td className="edittd">
														<div className="d-sm-flex justify-content-center cpl-gap-13 align-items-center">
															<div
																className=""
																onClick={() =>
																	handleDeleteConfirmation(
																		item.encrypted_id,
																		item.full_name
																	)
																}
															>
																<img
																	src={Delete}
																	className="img-fluid"
																	alt="Delete"
																/>
															</div>
														</div>
													</td>
												</tr>
											</>
										);
										return list;
									})}
								{typeof customers.data !== "undefined" &&
									customers.data.length === 0 && <NoDataFound />}
							</tbody>
						</Table>
					</div>

					<Row className="mt-4">
						<Col md={{ span: 4, offset: 8 }}>
							{
								<Pagination>
									<Pagination.Prev onClick={() => handlePreviousPagination()} />
									{PaginationItems()}
									<Pagination.Next onClick={() => handleNextPagination()} />
								</Pagination>
							}
						</Col>
					</Row>
				</div>
				<ActivateModalConfirmation
					show={customer_activate}
					onHide={() => {
						setActivate(false);
					}}
					branch={"customer"}
					name={selectedRowId.toString()}
					title={"Activate Customer"}
					handleConfirm={() => handleBulkActivate()}
				/>
				<ActivateModalConfirmation
					show={customer_activate_All}
					onHide={() => {
						setActivateAll(false);
					}}
					branch={"customer"}
					name={selectedRowName.toString()}
					title={"Activate Customer"}
					handleConfirm={() => handleBulkActivateAll()}
				/>
				<DeActivateModalConfirmation
					show={customer_deactive}
					onHide={() => {
						setDeactivate(false);
					}}
					branch={"customer"}
					name={selectedRowId.toString()}
					title={"Deactivate Customer"}
					handleConfirm={() => handleBulkDeactivate()}
				/>
				<DeActivateModalConfirmation
					show={customer_deactive_All}
					onHide={() => {
						setDeactivateAll(false);
					}}
					branch={"customer"}
					name={selectedRowName.toString()}
					title={"Deactivate Customer"}
					handleConfirm={() => handleBulkDeactivateAll()}
				/>
				{/*************Activate or deactivate by id */}
				<ActivateModalConfirmation
					show={activate_id}
					onHide={() => {
						setActivateById(false);
					}}
					branch={"Customer"}
					name={status_name}
					title={"Activate Customer"}
					handleConfirm={() => handleActivateById()}
				/>
				<DeActivateModalConfirmation
					show={deactivate_id}
					onHide={() => {
						setDeactivateById(false);
					}}
					branch={"Customer"}
					name={status_name}
					title={"Deactivate Customer"}
					handleConfirm={() => handleDeactivateById()}
				/>
				{/*************Delete as a bulk or delete by id */}
				<DeleteModalConfirmation
					show={show_delete_by_id}
					onHide={() => {
						setShowDeleteByID(false);
					}}
					branch={"customer"}
					name={customer_name}
					title={"Delete customer"}
					handleConfirm={() => handleDeleteCustomer(customer_id)}
				/>
				<DeleteModalConfirmation
					show={show}
					onHide={() => {
						setShow(false);
					}}
					branch={"customer"}
					name={selectedRowId.toString()}
					title={"Delete Customer"}
					handleConfirm={() => handleBulkDelete()}
				/>
				<DeleteModalConfirmation
					show={showModalDeleteAll}
					onHide={() => {
						setShowModalDeleteAll(false);
					}}
					branch={"customer"}
					name={selectedRowName.toString()}
					title={"Delete Customer"}
					handleConfirm={() => handleBulkDeleteAll()}
				/>
			</>
		);
	}
};

export default Customers;
