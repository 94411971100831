import React, { useState, useEffect,useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { uuid } from "uuidv4";

// Importing components and images
import { Row, Col, Form, Button, Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import MyVerticallyCenteredModal from "./../../../components/Modal";
import Alert from "./../../../components/Alert";
import Loader from "../../../components/Loader";
import FormValidationError from "../../../components/FormValidationError";

import profile from "../.././../assets/Images/user/no-profile.png";
import Invite from "./../../../assets/Images/invite.svg";
import Delete from "./../../../assets/Images/delete.svg";

// Importing API related files and routes
import { client } from "./../../../data/API/GobalClientAxiosRequest";
import { apiURL } from "./../../../data/filepath";
import {
	ADMIN_STAFF_ROLES,
	BRANCHES_LIST,
	BRANCHES_SHIFT_BYBRANCHID,
	UPDATE_STAFF,
	VIEW_EDIT_STAFF,
} from "./../../../config/apiRoutes.api";
import { STAFF_DETAILS_PAGE } from "./../../../config/routes";
import countryPhone from "../../../json/countryPhone.json"; 
const flagURL = "https://purecatamphetamine.github.io/country-flag-icons/3x2/"

const Staffnewuser = () => {
	const [loader, setLoader] = useState(false);
	const token = sessionStorage.getItem("token");
	const request = client(token);
	const navigate = useNavigate();
	const { id } = useParams();
	const bodyFormData = new FormData();
	const [imgfile, uploadimg] = useState([]);
	const [base64String, setBasr64String] = useState(""); // need to remove
	const [fileName, setFile] = useState();
	const [selectRoles, setSelectRoles] = useState("");
	const [roleId, setRoleId] = useState(0);
	const [roles, setRoles] = useState([]);
	const [branches, setBranches] = useState([]);

	const [brancheName, setBranchesName] = useState(0);
	const [shift, setShift] = useState([]);
	const [selectShiftId, setSelectShiftLabel] = useState([]);
	const [selectShiftIdSecond, setSelectShiftLabelSecond] = useState([]);
	const [error, setError] = useState("");
	const [phone, setPhone] = useState("+62");
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address, setAddress] = useState("");
	const [modalShow, setModalShow] = React.useState(false);
	const [submitStatusMessage, setSubmitStatusMessage] = useState("");
	const [status, setStatus] = useState("");
	const [row, setRow] = useState([]);
	const [showPhonenumberdropDown, setPhonenumberdropDown] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState(countryPhone);
	const [countryCode, setCountryCode] = useState("+62");
	const [isFormSubmitted, setFormSubmitted] = useState(false);
	const [errorType, setErrorType] = useState("* Missing required field:");
	const [defaultCountry, setDefaultCountry] = useState("ID");
	

	// Function to get the user's role from session storage
	function getUserRole() {
		const roleJson = sessionStorage.getItem("role");
		return roleJson ? JSON.parse(roleJson) : null;
	}

	// Function to check if the user has a specific role for a given route
	function hasUserRole(route, targetRole) {
		const role = getUserRole();
		const currentUserRoles = [role !== null ? role.role : "login"];
		return currentUserRoles.includes(targetRole);
	}

	// Example usage:
	const route = { role: "department-manager" };

	const selectPhoneRef = useRef();

	const imgFilehandler = (event) => {
		setFile(event.target.files[0]);
		if (event.target.files.length !== null) {
			uploadimg([URL.createObjectURL(event.target.files[0])]);
		}
	};

	const onImageError = (e) => {
		e.target.src = profile;
	};
	const ImageUrl = (imgfile) =>
		imgfile.map((elem) => {
			return (
				<span key={elem}>
					<img
						src={elem.length !== undefined ? elem : profile}
						className="img-fluid d-block mx-auto"
						alt="user"
						onError={onImageError}
					/>
				</span>
			);
		});
	
	 /**
	 * Transforms an string into first letter.
	 * @param {String} string - An string of text passed.
	 * @returns {String} - An string with the first letter in caps.
	 */
	
	const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).replace("_"," ");

	/**
	 * fiterSet- This function find the selected branch together.
	 * @param {array} x - The array of collection to search.
	 * @param {id} y - The id of selected element.
	 */
	const fiterSet = (array, id) => {
		return array.filter((itemInArray) => itemInArray.id === id);
	};
	const fiterSetRoles = (array, id) => {
		return array.filter((item) => {
			if (item.role === id) {
				return item.role;
			}
		});
	};

	/**
	 * Transforms an array of branch objects into a new format suitable for rendering in a select dropdown.
	 * @param {Array} branches - An array of branch objects.
	 * @returns {Array} - An array of objects with 'label' and 'value' properties.
	 */
	const selectLabel = (branches) =>
		branches.map(({ role: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of role array as label and value return a new array of object like [{value:"",label:""}]

	const selectLabelBranches = (branches) =>
		branches.map(({ name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of branches array as label and value return a new array of object like [{value:"",label:""}]
	const selectShiftLabel = (shift) =>
		shift.map(({ shift_name: label, id: value, ...rest }) => ({
			label,
			value,
			...rest,
		})); // change the key of shift array as label and value return a new array of object like [{value:"",label:""}]

	useEffect(() => {
		if (token !== null) {
			setLoader(true);
			request
				.get(apiURL + VIEW_EDIT_STAFF + `/${id}`)
				.then((response) => {
					setLoader(false);
					uploadimg([response.data.image_url]);
					setFirstName(response.data.staff.first_name);
					setLastName(response.data.staff.last_name);
					setAddress(response.data.staff.address);
					setEmail(response.data.staff.email);
					setPhone((response.data.staff.phone_number!=="" && response.data.staff.phone_number!== null  ? (response.data.staff.phone_number.indexOf("+") < 0 ? "+62 " : "+62") :"+62")  + (response.data.staff.phone_number));
					
					if(response.data.staff.phone_number!=="" && response.data.staff.phone_number!== null && response.data.staff.phone_number.indexOf("+") >=0 )  {
						let checkPhoneNumber = [];
						setTimeout(()=>{
							const phoneInput = document.querySelector("#phoneInput");
							if(phoneInput && phoneInput.value) {
								checkPhoneNumber = phoneInput.value.split(" ")
								if(checkPhoneNumber.length>0 && checkPhoneNumber[0] && checkPhoneNumber[0]!==""){
									const resultFilter = phoneNumber.filter(obj=>obj.code === checkPhoneNumber[0].replace("+",""));
									const countryDetails  = resultFilter.length > 0  ? resultFilter[0] : {};
									const countryCodeCheckLength = Object.keys(countryDetails).length;
									if(countryCodeCheckLength >0) {
										setDefaultCountry(countryDetails.value)
										setCountryCode(countryDetails.code)
									}
								}
							}
						},30)
					}
					
					setBranchesName(response.data.staff.branch_id);
					setSelectRoles(response.data.staff.user_type);
					setRoleId(
						response.data.staff.roles && response.data.staff.roles[0] && response.data.staff.roles.length > 0
							? response.data.staff.roles[0].id
							: 0
					);
					setSelectShiftLabel([response.data.staff.shifts && response.data.staff.shifts[0] ? response.data.staff.shifts[0].id : 0]);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token) {
			// Fetching admin user's roles data from the API
			request
				.get(apiURL + ADMIN_STAFF_ROLES)
				.then((response) => {
					setRoles(response.data.data.roles);
				})
				.catch((err) => console.log(err));
		}
	}, []);
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_LIST)
				.then((response) => {
					setBranches(response.data[0].branches);
				})
				.catch((err) => {
					setError(err.message);
				});
		}
	}, []);
	useEffect(() => {
		if (token) {
			request
				.get(apiURL + BRANCHES_SHIFT_BYBRANCHID + `/${brancheName}`)
				.then((response) => {
					setShift(response.data.shifts);
				})
				.catch((err) => console.log(err));
		}
	}, [brancheName]);

	/**
	 * Check and return the condition.
	 * @param {String} string - An string of text passed.
	 * @returns {String} - An string with the first letter in caps.
	 */

	const checkFieldError = (field, fieldName = "",) => {
		if(fieldName==="email") {
			return	((!field) || (validation(field) === false && field !== "")) && (isFormSubmitted);
		} else {
			if(fieldName === "shift") {
				return selectShiftId.length === 0 && isFormSubmitted;
			} else {
				return	!field && isFormSubmitted;
			}
		}
	}


	/**
	 * Check and return the condition.
	 * @param {String} string - An string of text passed.
	 * @returns {String} - An string with the first letter in caps.
	 */

	const checkFieldErrorSubmit = (field, fieldName = "",) => {
		if(fieldName==="email") {
			return	((!field) || (validation(field) === false && field !== ""));
		} else {
			if(fieldName === "shift") {
				return selectShiftId.length === 0;
			} else {
				return	!field;
			}
		}
	}


	const validateForm = () =>{
		let errorMessage ="";
		let error = false;
		let mainHeading ="";
		if(checkFieldErrorSubmit(firstName)){
			errorMessage+="First Name, "
		} 
		if(checkFieldErrorSubmit(lastName)){
			errorMessage+="Last Name, "
		} 
		if(checkFieldErrorSubmit(email,"email")) {
			errorMessage+="Email, "
		} 
		if(checkFieldErrorSubmit(brancheName)) {
			errorMessage+="Branch, "
		} 
		if(checkFieldErrorSubmit(selectRoles)) {
			errorMessage+="Role, "
		} 
		
		if(checkFieldErrorSubmit(selectShiftId,"shift")) {
			errorMessage+="Shift ids"
		} 
		setSubmitStatusMessage(errorMessage.replace(/, \s*$/, ""));
		setStatus("Error");
		return errorMessage === "" ?  false : true
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setFormSubmitted(true);
		if (token && !validateForm()) {
			const request = client(token);
			bodyFormData.append("first_name", firstName);
			bodyFormData.append("last_name", lastName);
			bodyFormData.append("address", address);
			bodyFormData.append("role_id", roleId);
			bodyFormData.append("user_type", selectRoles);
			//
			bodyFormData.append("email", email);
			bodyFormData.append("branch_id", brancheName);
			bodyFormData.append(
				"shift_ids",
				JSON.stringify(selectShiftId).slice(1, -1)
			);
			if(fileName && fileName !== undefined) {
				bodyFormData.append("profile_image", fileName);
			}

			bodyFormData.append("phone_number", phone === "+62" ? "" : phone);
			request
				.post(apiURL + UPDATE_STAFF + `/${id}`, bodyFormData)
				.then((response) => {
					let errorMessage = "";
					if(response.data && response.data.success === false) {
						const errorResponse = Object.entries(response.data.data)
						for (const [key, value] of errorResponse) {
							if(errorResponse.length === 1 && value[0] === "The email has already been taken."){
								errorMessage=` ${email + " has already been taken."}, `;
								setErrorType("Duplicate email error:")
							} else { 
								errorMessage+=`${capitalizeFirstLetter(key)}, `;
							}
						}
						if(checkFieldError(email,"email")){
							errorMessage+=`Email, `;
						}
						if(!brancheName){
							errorMessage+=`Branch, `;
						}
						if(!selectRoles){
							errorMessage+=`Role, `;
						}
						setSubmitStatusMessage(errorMessage.replace(/, \s*$/, ""));
						setStatus("Error");
					} else {
						setErrorType("")
						setError("");
						setStatus("Success");
						setSubmitStatusMessage(response.data.message);
						setModalShow(true);
					}
				})
				.catch((err) => {
					let message =
						typeof err.response !== "undefined"
							? err.response.data.message
							: err.message;
					setError(message);
				});
		}
	};
	const handleShifts = React.useCallback(
		(e) => {
			if (selectShiftId.length < 3) {
				setSelectShiftLabel((prevSelectedRows) => [
					...prevSelectedRows,
					e.value,
				]);
			} else {
				setSelectShiftLabel((prevSelectedRows) =>
					prevSelectedRows.filter((selectedRow) => selectedRow !== e.value)
				);
			}
		},
		[selectShiftId]
	);
	const handleAddRow = () => {
		if (row.length <= 1) {
			setRow((prevState) => [
				...prevState,
				{
					id: uuid(),
				},
			]);
		}
	}; // add an object with specific uuid
	const validation = (email) => {
		const result =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return result.test(String(email).toLowerCase());
	}; //validated email using regex


	useEffect(() => {
		document.querySelector('body').addEventListener('click', function(event) {
			const classListinputBox = ".PhoneInputCountrySelect, .PhoneInputCountryIconImg, .PhoneInput,.PhoneInputCountrySelectArrow";
			const classListBox = ".css-qbdosj-Input, .css-d7l1ni-option, .PhoneInputInput,.css-tj5bde-Svg";
			if (
				event.target.matches(classListinputBox)
			) {
				setPhonenumberdropDown(true);
				if(selectPhoneRef.current){
					selectPhoneRef.current.focus()
				}
			} else {
				if(!event.target.matches(classListBox)) {
					setPhonenumberdropDown(false);
				} 
			}
		});
	}, []);


	/**
	 * set the Country code for selected country.
	 * @param {String} value - An string of country code text.
	 */

	const setCountryPhone = (value) => {
		const previousCountryCode = countryCode;
		const phoneInput = document.querySelector("#phoneInput");
		
		const resultFilter = phoneNumber.filter(obj=>obj.value === value);
		const countryDetails  = resultFilter.length > 0  ? resultFilter[0] : {};
		const checkcountryCodeLength = Object.keys(countryDetails).length;
		setCountryCode("+"+(checkcountryCodeLength > 0  ? (countryDetails.code) :"62"));
		
		if(phoneInput){
			if(phoneInput.value) {
				setPhone("+"+(checkcountryCodeLength > 0  ? (phoneInput.value.replace(" ","").replace("+","").replace(previousCountryCode,countryDetails.code)) :"62"));
			} else {
				setPhone("+"+(checkcountryCodeLength > 0  ? (countryDetails.code) :"62"));
			}
		}
		const flagImage = document.querySelector(".PhoneInputCountryIconImg");
		if(flagImage){
			flagImage.src=`${flagURL+value}.svg`;
		}
		setPhonenumberdropDown(false);
	}

	


	let shiftName = selectShiftLabel(
		fiterSet(shift, Number(selectShiftId[0]))
	)[0];
	const Label = () => {
		if (shiftName !== undefined) {
			let { label } = shiftName;
			return label;
		} else {
			return "Please select Shifts";
		}
	};
	
	if (loader) {
		return <Loader />;
	} else {
		return (
			<>
				<div className="rightbar pr-11">
					<Tabs
						defaultActiveKey="User"
						id="justify-tab-example"
						className="stafftab"
						justify
					>
						<Tab eventKey="User" title="User"></Tab>
					</Tabs>
					<div className="section-title">
						<h2>Edit/View User</h2>
						{error !== "" && <Alert variant={"danger"} messages={error} />}
					</div>

					<Form className="mb-5">
						<Row>
							<Col sm={9} lg={5}>
								<Form>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Image</Form.Label>
												<Col sm={9}>
													<div className="uploadimg">
														{ImageUrl(imgfile)}
														<div className="edit">
															<label htmlFor="icon-button-file">
																<i className="fa fa-edit"></i>
															</label>
															<Form.Control
																id="icon-button-file"
																type="file"
																file={true}
																accept="image/*"
																style={{ visibility: "hidden" }}
																onChange={(e) =>
																	e !== null && imgFilehandler(e)
																}
																webkitRelativePath
															/>
														</div>
													</div>
												</Col>
											</Row>
										</div>
										<div className="form-group">
											<Row>
												<Form.Label className={`col-sm-3 mt-1 ${checkFieldError(firstName) ? "compulsory-field-error" :"compulsory-field"}`}>First Name <span>*</span></Form.Label>
												<Col sm={9}>
													<Form.Control
														type="text"
														placeholder="Michael"
														autoFocus
														onChange={(e) => setFirstName(e.target.value)}
														value={firstName}
													/>
												</Col>
											</Row>
										</div>
										<div className="form-group">
											<Row>
												<Form.Label className={`col-sm-3 ${checkFieldError(lastName) ? "compulsory-field-error" :"compulsory-field"}`}>Last Name <span>*</span></Form.Label>
												<Col sm={9}>
													<Form.Control
														type="text"
														placeholder="Jordan"
														autoFocus
														onChange={(e) => setLastName(e.target.value)}
														value={lastName}
													/>
												</Col>
											</Row>
										</div>
										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">Address</Form.Label>
												<Col sm={9}>
													<Form.Control
														type="text"
														placeholder="Add address"
														onChange={(e) => setAddress(e.target.value)}
														value={address}
													/>
												</Col>
											</Row>
										</div>

										<div className="form-group">
											<Row>
												<Form.Label className="col-sm-3">
													Phone number
												</Form.Label>
												<Col sm={9} className="phoneNumberParent">
													<PhoneNumberInput
														onChange={(e) => {
															setPhone(e);
															setPhonenumberdropDown(false);
														}}
														className="form-control"
														value={phone}
														defaultCountry={defaultCountry}
														
													/>
													{showPhonenumberdropDown && (
														<div className="dynamic-country-list">
															<Select
																openMenuOnFocus={true}
																// defaultMenuIsOpen
																onChange={(e) => {
																	setCountryPhone(e.value)
																}}
																options={phoneNumber}
																placeholder="Select"
																isSearchable
																menuPortalTarget={document.body} menuPosition={'fixed'}
																// menuIsOpen={isMenuOpen}
																ref={selectPhoneRef}
															/>
														</div>
													)}
												</Col>
											</Row>
										</div>

										<div className="form-group">
											<Row>
												<Form.Label className={`col-sm-3 ${checkFieldError(email,"email") ? "compulsory-field-error" :"compulsory-field"}`}>Email <span>*</span></Form.Label>
												<Col sm={9}>
													<Form.Control
														type="email"
														placeholder="Add email"
														onChange={(e) => setEmail(e.target.value)}
														value={email}
													/>
													{/* {validation(email) === false && email !== "" && (
														<Alert
															variant={"danger"}
															messages={"Not a valid email address"}
															className="email-validated"
														/>
													)} */}
												</Col>
											</Row>
										</div>

										<div className="form-group">
											<Row>
												<Form.Label className={`col-sm-3 ${checkFieldError(brancheName) ? "compulsory-field-error" :"compulsory-field"}`}>Branch <span>*</span></Form.Label>
												<Col sm={9}>
													<Select
														onChange={(e) => {
															e !== null && setBranchesName(e.value);
														}}
														options={selectLabelBranches(branches)}
														placeholder="Select Branch"
														isSearchable
														value={selectLabelBranches(
															fiterSet(branches, brancheName)
														)}
														styles={{
															control: (provided) => ({
																...provided,
																height: 52, 
																border: `${checkFieldError(brancheName) ? "1px solid #a92322" :"1px solid #e9e9e9"}`,
																boxShadow: 'none',
																'&:hover': {
																	border: `${checkFieldError(brancheName)	? "1px solid #a92322" :"1px solid #e9e9e9"}`,
																}
															})
														}}
													/>
												</Col>
											</Row>
										</div>
										
										<div className="form-group">
											<Row>
												<Form.Label className={`col-sm-3 ${checkFieldError(selectRoles) ? "compulsory-field-error" :"compulsory-field"}`}>Role <span>*</span></Form.Label>
												<Col sm={9}>
													<Select
														options={selectLabel(roles)}
														placeholder="Select Role"
														onChange={(e) => {
															setSelectRoles(e.label);
															setRoleId(e.value);
														}}
														value={selectLabel(
															fiterSetRoles(roles, selectRoles)
														)}
														styles={{
															control: (provided) => ({
																...provided,
																height: 52, 
																border: `${checkFieldError(selectRoles) ? "1px solid #a92322" :"1px solid #e9e9e9"}`,
																boxShadow: 'none',
																'&:hover': {
																	border: `${checkFieldError(selectRoles)	? "1px solid #a92322" :"1px solid #e9e9e9"}`,
																}
															})
														}}
													/>
												</Col>
											</Row>
										</div>				
										<div className="form-group">
											<Row>
												<Form.Label className={`col-sm-3 ${checkFieldError(selectShiftId,"shift") ? "compulsory-field-error" :"compulsory-field"}`}>Shift(s) <span>*</span></Form.Label>
												<Col sm={9}>
													<Select
														onChange={(e) => handleShifts(e)}
														options={selectShiftLabel(shift)}
														placeholder={[`${Label()}`]}
														isDisabled={
															!selectShiftLabel(shift).length ? true : false
														}
														styles={{
															control: (provided) => ({
																...provided,
																height: 52, 
																border: `${checkFieldError(selectShiftId,"shift") ? "1px solid #a92322" :"1px solid #e9e9e9"}`,
																boxShadow: 'none',
																'&:hover': {
																	border: `${checkFieldError(selectShiftId,"shift")	? "1px solid #a92322" :"1px solid #e9e9e9"}`,
																}
															})
													   }}
													/>
												</Col>
											</Row>
										</div>
										
										{row.map((items) => {
											return (
												<div className="form-group">
													<Row key={items.id}>
														<Form.Label className="col-sm-3"></Form.Label>
														<Col sm={9}>
															<Select
																onChange={(e) => {
																	handleShifts(e);
																}}
																options={selectShiftLabel(shift)}
																placeholder="Select Shift"
																isSearchable
															/>
														</Col>
													</Row>
												</div>
											);
										})}
										<div className="form-group">
											<Row>
												{row.length < 2 && (
													<Col>
														<p className="addfield" onClick={handleAddRow}>
															Click to add more
														</p>
													</Col>
												)}{" "}
											</Row>
										</div>
									</Form.Group>
									{
									submitStatusMessage && isFormSubmitted && status === "Error" && (
										<>
											<br />
											<div className={`d-sm-flex justify-content-between`}>
												<FormValidationError
													error = {submitStatusMessage}
													message = {errorType}
												/>
											</div>
										</>
									)}
									<div className="d-sm-flex justify-content-between1 ">
										<div className={`${submitStatusMessage && isFormSubmitted && status === "Error" ? " mt-4" :""}`}>
											<Button
												variant=""
												className="btn-light-border grey"
												type="submit"
											>
												<img src={Delete} className="" img-fluid />
											</Button>
										</div>
										<div className={`ml-4 ${submitStatusMessage && isFormSubmitted && status === "Error" ? " mt-4" :""}`}>
											<Button
												variant=""
												className="puple btns mr-3"
												onClick={() => navigate(`${STAFF_DETAILS_PAGE}/${id}`)}
											>
												Cancel
											</Button>
											<Button
												variant="primary"
												className="btns"
												onClick={handleSubmit}
											>
												<span>
													<img
														src={Invite}
														className="img-fluid"
														alt="mindreach images"
													/>
												</span>{" "}
												Update{" "}
											</Button>
										</div>
									</div>
								</Form>
							</Col>
						</Row>
					</Form>
					<MyVerticallyCenteredModal
						show={modalShow}
						onHide={() => {
							setModalShow(false);
							if(status === "Success"){
								navigate(`${STAFF_DETAILS_PAGE}/${id}`);
							}
						}}
						heading={status}
						message={submitStatusMessage}
						className="modal-body-body--success"
						errorMessage={status==="Error"? "error":""}
						modalHeight={"modalHeight"}
					/>
				</div>
			</>
		);
	}
};

export default Staffnewuser;
